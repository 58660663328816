import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import { DialogTitleContent } from '../../common/styles/Common.styled';
import colors from '../../common/styles/Common.colors';

export const RemainderDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        maxHeight: '300px',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        //boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        transition: '.4s ease',
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.8',
        },
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          color: `${colors.Buttons.buttonPrimary.color}`,
          boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
        },
      },
      text: {
        padding: '11px 65px',
        fontSize: '16px',
        fontWeight: '600',
      },
      disabled: {},
    },
    MuiDialogTitle: {
      root: {
        padding: '20px 40px',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '30px',
        color: '#63666A',
        textAlign: 'center',
        borderBottom: '1px solid #F2F2F2',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        fontSize: '18px;',
        color: '#63666A',
        textAlign: 'center',
        minHeight: '60px',
        maxHeight: '350px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px',
        justifyContent: 'flex-start',
      },
    },
  },
});
export const DialogTitleRemainder = styled.div`
  font-size: 24px;
  color: #63666a;
  margin: 0;
  font-weight: 600;
`;
export const RemainderDialogTitleContent = styled(DialogTitleContent)`
  min-height: auto;
  align-items: center;
`;
export const DialogRow = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 21px;
  padding: 15px 110px 15px 40px;
  justify-content: space-between;
  background: #fff;
  &:nth-of-type(odd) {
    background: #f7f7f7;
  }
`;
export const DialogRowLeft = styled.div`
    flex-grow: 1
    text-align: left;
    padding-right: 20px;
`;
export const DialogRowRight = styled.div`
  display: flex;
  font-weight: 600;
`;
