import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  IconArchiveStatus,
  IconNameCount,
} from '../../../../common/icons/customIcons';
import { TextTooltipArchive } from '../../../../common/styles/Common.styled';
import { NameCount, VersionBlock } from '../ProductsGrid.styled';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';

export default class StatusCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.original, nextProps.original);
  }

  render() {
    return (
      <NameCount>
        {this.props.original.dto.status === 'archive' && (
          <MuiThemeProvider theme={TextTooltipArchive}>
            <Tooltip
              interactive
              placement="bottom-end"
              title="Товар снят с производства">
              <span>
                <IconArchiveStatus />
              </span>
            </Tooltip>
          </MuiThemeProvider>
        )}

        {!!this.props.original.dto.preVersion && (
          <VersionBlock
            onClick={() => this.props.onVersion(this.props.original.dto)}>
            <IconNameCount />
            <span>{this.props.original.dto.preVersion}</span>
          </VersionBlock>
        )}
      </NameCount>
    );
  }
}

StatusCell.propTypes = {
  onVersion: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};
