import * as React from 'react';
import {
  IconStatusArchive,
  IconStatusCatalog,
  IconStatusCatalogA,
  IconStatusDraft,
  IconStatusError,
  IconStatusModeration,
  IconStatusQueue,
  IconStatusNotsigned,
  // IconStatusReq,
  IconUserDeleted,
  IconUserBlocked,
  IconUserActive,
} from '../icons/customIcons';

export const Statuses = [
  {
    id: 0,
    title: 'Черновик',
    value: 'draft',
    style: { color: '#52535A', icon: <IconStatusDraft /> },
  },
  {
    id: 1,
    title: 'На модерации',
    value: 'moderation',
    style: { color: '#FFA800', icon: <IconStatusModeration /> },
  },
  {
    id: 2,
    title: 'Требует изменений',
    value: 'errors',
    style: { color: '#FF4B4B', icon: <IconStatusError /> },
  },
  {
    id: 3,
    title: 'Ожидает подписания',
    value: 'notsigned',
    style: { color: '#FFA800', icon: <IconStatusNotsigned /> },
  },
  {
    id: 4,
    title: 'Опубликована (с УКЭП)',
    value: 'published',
    style: { color: '#2FA93B', icon: <IconStatusCatalogA /> },
  },
  {
    id: 5,
    title: 'Опубликована (без УКЭП)',
    value: 'published_notsigned',
    style: { color: '#2FA93B', icon: <IconStatusCatalog /> },
  },
  {
    id: 6,
    title: 'В архиве',
    value: 'archive',
    style: { color: '#B8B8B8', icon: <IconStatusArchive /> },
  },
  // {
  //   id: 7,
  //   title: 'Требует обработки',
  //   value: 'require_changes',
  //   style: { color: '#52535A', icon: <IconStatusReq /> },
  // },
  {
    id: 8,
    title: 'В очереди',
    value: 'queue',
    style: { color: '#FFA800', icon: <IconStatusQueue /> },
  },
];

export const UserStatuses = [
  {
    id: 0,
    title: 'Активен',
    value: 'active',
    style: { color: '#2FA93B', icon: <IconUserActive /> },
  },
  {
    id: 1,
    title: 'Заблокирован',
    value: 'blocked',
    style: { color: '#FF4B4B', icon: <IconUserBlocked /> },
  },
  {
    id: 2,
    title: 'Удалён',
    value: 'deleted',
    style: { color: '#868686', icon: <IconUserDeleted /> },
  },
];

export const PackingTypes = [
  { id: 0, title: 'Потребительская', value: 'consumer' },
  { id: 1, title: 'Групповая', value: 'group' },
  { id: 2, title: 'Транспортная', value: 'transport' },
];
