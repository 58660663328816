import { Reducer } from 'redux';
import {
  CLEAR_GLN_DICTIONARY_ACTION,
  LOAD_GLN_DICTIONARY_ACTION,
  LOAD_GLN_DICTIONARY_FAILED_ACTION,
  LOAD_GLN_DICTIONARY_SUCCESSED_ACTION,
  LOAD_PROFILE_STATUS_ACTION,
  LOAD_PROFILE_STATUS_FAILED_ACTION,
  LOAD_PROFILE_STATUS_SUCCEEDED_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION,
  SAVE_OPTIONS_ACTION,
  SAVE_OPTIONS_SUCCEEDED_ACTION,
  SAVE_OPTIONS_FAILED_ACTION,
} from './ProfileStatus.constants';
import { subtractNotNegative } from '../../../../common/utils/utils';

const _initialState = {
  data: [],
  totalObjects: 0,
  loading: 0,
  glnDictionary: [],
  profileStatusWasLoaded: false,
  statisticAndOptions: {},
};

export const profileStatusReducer: Reducer = (
  state = _initialState,
  action,
) => {
  switch (action.type) {
    case LOAD_PROFILE_STATUS_FAILED_ACTION: {
      return {
        ...state,
        data: [],
        totalObjects: 0,
        loading: subtractNotNegative(state.loading),
        profileStatusWasLoaded: true,
      };
    }

    case LOAD_PROFILE_STATUS_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: action.data,
        totalObjects: action.totalObjects,
        loading: subtractNotNegative(state.loading),
        profileStatusWasLoaded: true,
      };
    }

    case LOAD_GLN_DICTIONARY_ACTION:
    case LOAD_PROFILE_STATUS_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
        profileStatusWasLoaded: false,
      };
    }

    case SAVE_OPTIONS_SUCCEEDED_ACTION:
    case LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION:
    case SAVE_OPTIONS_FAILED_ACTION:
    case LOAD_GLN_DICTIONARY_FAILED_ACTION: {
      return { ...state, loading: subtractNotNegative(state.loading) };
    }

    case LOAD_GLN_DICTIONARY_SUCCESSED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        glnDictionary: action.data,
      };
    }

    case CLEAR_GLN_DICTIONARY_ACTION: {
      return { ...state, glnDictionary: [] };
    }

    case LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION: {
      return {
        ...state,
        statisticAndOptions: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case SAVE_OPTIONS_ACTION:
    case LOAD_STATISTIC_AND_OPTIONS_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }
    default:
      return { ...state };
  }
};
