import * as React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MuiThemeProvider } from '@material-ui/core';
import { CustomInput, PhoneInput } from '../../../../../components';
import {
  validatePhoneInput,
  validateEmail,
} from '../../../../../common/utils/Validation';
import {
  GridBlockB,
  GridItem,
  GridItemC,
} from '../../../../profile/Profile.styled';
import {
  GridItemSmall,
  GridItemSmallA,
  GridItemSmallB,
  ProfileAutocomplete,
} from '../../../../../common/styles/Common.styled';
/**
 * TODO:
 * отрефакторить.
 */

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (value, name) => {
    const { handleStateChange } = this.props;
    switch (name) {
      case 'middleName': {
        handleStateChange('contacts', name, _.toString(value), false);
        break;
      }
      case 'department':
      case 'position':
      case 'lastName':
      case 'firstName': {
        // let v = _.find(this.props.countries, {id: value});
        // this.handleChangeInfo(name, value);
        handleStateChange(
          'contacts',
          name,
          _.toString(value),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'email': {
        handleStateChange(
          'contacts',
          name,
          _.toString(value),
          !validateEmail(value),
        );
        break;
      }
      case 'workPhone':
      case 'phone': {
        handleStateChange('contacts', name, value, !validatePhoneInput(value));
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { t, contacts } = this.props;
    let contactObj = {};
    if (contacts.length > 0) {
      contactObj = contacts[0];
    }
    const {
      department,
      lastName,
      firstName,
      middleName,
      position,
      email,
      workPhone,
      phone,
    } = contactObj;
    // const department = defaultTo(find(this.props.department, {id: this.props.data.department}), {text: ''});
    // const position = defaultTo(find(this.props.positionEmployee, {id: this.props.data.position}), {text: ''});

    const workPhoneValid = !!workPhone && validatePhoneInput(workPhone);
    const phoneValid = !!phone && validatePhoneInput(phone);
    const emailValid = validateEmail(email);

    return (
      <div>
        <h1>{t('Ответственный по работе с ГС1')}</h1>
        <GridBlockB>
          <MuiThemeProvider theme={ProfileAutocomplete}>
            <GridItem>
              <CustomInput
                id={`department`}
                name="department"
                required
                valid={!!_.defaultTo(department, '')}
                label={t('Укажите отдел')}
                value={_.defaultTo(department, '')}
                disabled={false}
                onChange={this.handleChange}
                type="text"
              />
            </GridItem>
          </MuiThemeProvider>
          <GridItemSmall>
            <CustomInput
              id={`lastName_contacts`}
              name="lastName"
              required
              valid={!!_.defaultTo(lastName, '')}
              label={t('Фамилия')}
              value={_.defaultTo(lastName, '')}
              disabled={false}
              onChange={this.handleChange}
              type="text"
            />
          </GridItemSmall>
          <GridItemC>
            <CustomInput
              id={`position_contacts`}
              name="position"
              required
              valid={!!_.defaultTo(position, '')}
              label={t('Должность')}
              value={_.defaultTo(position, '')}
              disabled={false}
              onChange={this.handleChange}
              type="text"
            />
          </GridItemC>
          <GridItemSmall>
            <CustomInput
              id={`firstName_contacts`}
              name="firstName"
              required
              valid={!!_.defaultTo(firstName, '')}
              label={t('Имя')}
              value={_.defaultTo(firstName, '')}
              disabled={false}
              onChange={this.handleChange}
              type="text"
            />
          </GridItemSmall>
          <GridItemC>
            <CustomInput
              id={`email_contacts`}
              name="email"
              label="E-mail"
              placeholder="E-mail"
              value={_.defaultTo(email, '')}
              disabled={false}
              onChange={this.handleChange}
              onUpdate={this.handleChange}
              type="email"
              autoComplete="email"
              valid={emailValid}
              error={!!_.defaultTo(email, '') && !emailValid}
              required
            />

            {/* <CustomInput
                            required
                            disabled={isElkUser}
                            id="email"
                            name="email"
                            placeholder="E-mail"
                            label={
                                !emailValid
                                    ? t('Введите корректный E-mail')
                                    : validationErrorEmail ? validationErrors.email : "E-mail"
                            }
                            onChange={(value) => this.handleChange('email', value)}
                            onUpdate={() => { }}
                            shrink={true}
                            value={valueEmail}
                            valid={emailValid && !validationErrorEmail}
                            error={!emailValid || validationErrorEmail}
                            type="email"
                            autoComplete="email"
                        /> */}
          </GridItemC>
          <GridItemSmall>
            <CustomInput
              id={`middleName_contacts`}
              name="middleName"
              label={t('Отчество')}
              value={_.defaultTo(middleName, '')}
              disabled={false}
              onChange={this.handleChange}
              type="text"
            />
          </GridItemSmall>
          <GridItemSmallA>
            <PhoneInput
              id={`workPhone_contacts`}
              maskGS="+9(999)999-99-99"
              required
              name="workPhone"
              phone={_.defaultTo(workPhone, '')}
              placeholder={t('Рабочий телефон')}
              label={t('Рабочий телефон')}
              onChange={this.handleChange}
              onUpdate={this.handleChange}
              phoneValid={workPhoneValid}
              valid={!!_.defaultTo(workPhoneValid, '') && workPhoneValid}
              defaultLabel={t('Рабочий телефон')}
            />
          </GridItemSmallA>
          <GridItemSmallB>
            <PhoneInput
              id={`phone_contacts`}
              maskGS="+9(999)999-99-99"
              name="phone"
              required
              phone={_.defaultTo(phone, '')}
              placeholder={t('Мобильный телефон')}
              label={t('Мобильный телефон')}
              onChange={this.handleChange}
              onUpdate={this.handleChange}
              phoneValid={phoneValid}
              valid={!!_.defaultTo(phone, '') && phoneValid}
              defaultLabel={t('Мобильный телефон')}
            />
          </GridItemSmallB>
        </GridBlockB>
      </div>
    );
  }
}

Contact.propTypes = {
  // updateItem: PropTypes.func.isRequired,
  // data: PropTypes.object,
  // department: PropTypes.array.isRequired,
  // positionEmployee: PropTypes.array.isRequired,
  // handleClearField: PropTypes.func.isRequired,
  // handleSuggestionsFetchRequested: PropTypes.func.isRequired
};

export default withTranslation()(Contact);
