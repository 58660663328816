import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const NewProductDialogNext = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '740px',
        maxWidth: '740px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        height: '500px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '18px 100px 48px',
        textAlign: 'center',
        position: 'relative',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: '600',
        fontSize: '20px',
        color: '#63666A',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px 40px',
        maxHeight: '300px',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: 'none',
        padding: '0 40px 40px',
        justifyContent: 'space-between',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        transition: '.4s ease',
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          color: `${colors.Buttons.buttonPrimary.color}`,
          boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
          opacity: '0.8',
        },
      },
      text: {
        padding: '12px 80px',
        fontSize: '16px',
        fontWeight: '600',
      },
      disabled: {},
    },
  },
});

export const ButtonCancelTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        background: '#868686',
        borderRadius: '3px',
        textTransform: 'none',
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          background: '#868686 !important',
          color: `${colors.Buttons.buttonPrimary.color}`,
          boxShadow: `0px 10px 30px #868686`,
        },
      },
      text: {
        padding: '12px 80px',
        fontSize: '16px',
        fontWeight: '600',
      },
    }
  }
});
