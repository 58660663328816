import styled, { keyframes } from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    right:-15px
  }
  100% {
    opacity: 1;
    right: 0;
  }
`;
export const HowToPanel = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 490px;
  height: 100%;
  animation: 0.2s ${fadeIn} ease-out;
  box-shadow: 0 10px 60px rgba(41, 49, 53, 0.15);
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    opacity: 0.15;
    z-index: -1;
  }
  box-sizing: border-box;
  h2 {
    font-size: 22px;
    color: #52535a;
    margin: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
export const PanelInner = styled.div`
  height: 100%;
  padding: 36px 38px;
  background: #fff;
  z-index: 999;
  position: relative;
`;
export const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 36px;
  button {
    cursor: pointer;
    border: none;
    background: none;
  }
`;
export const PanelContent = styled.div`
  padding-top: 20px;
  font-size: 16px;
  color: #52535a;
`;
export const PanelContentMin = styled.div`
  margin: 40px 0;
  display: flex;
  align-items: flex-start;
`;
export const PanelContentMinNumber = styled.span`
  display: inline-block;
  font-size: 18px;
  border-radius: 100px;
  background: 1px solid ${colors.Common.background};
  min-width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
`;
export const PanelContentMinText = styled.span`
  display: inline-block;
  padding-left: 30px;
  a {
    color: #4aa5e8;
  }
`;
