import { ActionCreator } from 'redux';
import {
  EXPORT_COMPANIES_ACTION,
  LOAD_COMPANIES_DATA_ACTION,
  LOAD_COMPANIES_DATA_FAILED_ACTION,
  LOAD_COMPANIES_DATA_SUCCEEDED_ACTION,
} from './Companies.constants';

export const loadCompaniesData: ActionCreator = (
  pageNum,
  pageSize,
  sort,
  filters,
  fields,
) => ({
  type: LOAD_COMPANIES_DATA_ACTION,
  pageNum: pageNum,
  pageSize: pageSize,
  sort: sort,
  filters: filters,
  fields: fields,
});

export const loadCompaniesDataSucceeded: ActionCreator = (
  data,
  totalObjects,
  filters,
) => ({
  type: LOAD_COMPANIES_DATA_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
  filters: filters,
});

export const loadCompaniesDataFailed: ActionCreator = () => ({
  type: LOAD_COMPANIES_DATA_FAILED_ACTION,
});

export const exportCompanies: ActionCreator = (
  params,
  cbk = () => null,
) => ({
  type: EXPORT_COMPANIES_ACTION,
  params: params,
  cbk: cbk,
});
