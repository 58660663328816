import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const MenuItemInner = styled.div`
  padding: 15px 60px 15px;
  font-size: 14px;
  color: #52535a;
  width: 100%;
  position: relative;
  white-space: normal;
  word-break: break-all;
  span {
    display: block;
  }
`;
export const MenuItemIcon = styled.div`
  position: absolute;
  left: 21px;
  top: 20px;
`;
export const MenuItemName = styled.div`
  font-size: 16px;
`;
export const SelectedCert = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px;
  &:before {
    content: '';
    background: #f9f9f9;
    position: absolute;
    min-width: 1200px;
    height: 100%;
    top: 0;
    left: -18%;
  }
`;

export const MenuItemDate = styled.span`
  color: ${(props) => (props.isValid ? '#52535A' : '#FF4B4B')};
`;
export const SelectedCertInner = styled.div`
  color: #52535a;
  font-size: 12px;
  padding: 15px 20px 15px 33px;
  display: flex;
  position: relative;
  width: 100%;
  @media (max-width: 1024px) {
    font-size: 12px;
    padding: 15px 20px 15px 25px;
  }
`;
export const InvalidCert = styled.div`
  background: #f9f9f9;
  color: #ff4b4b;
  font-size: 14px;
  padding: 15px 110px 15px 150px;
  display: flex;
  position: relative;
`;
export const SelectedIcon = styled(MenuItemIcon)`
  left: 0;
`;
export const SelectedMinInner = styled.div`
  display: flex;
  margin-bottom: 5px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;
export const SelectedMinInnerLeft = styled(SelectedMinInner)`
  padding-right: 10px;
`;
export const SelectedMinTitle = styled.span`
  padding-right: 5px;
  word-break: normal !important;
`;
export const SelectedMinText = styled.span`
  width: 200px;
  word-break: break-all;
`;
export const SelectedMinTitleCert = styled(SelectedMinTitle)`
  word-break: normal !important;
`;
export const CertSelectAuto = createMuiTheme({
  typography: {},
  overrides: {
    MuiAutocomplete: {
      listbox: {
        borderRadius: '3px',
      },
      popupIndicator: {
        marginRight: '10px',
      },
      option: {
        backgroundColor: 'transparent',
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
      inputRoot: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        paddingRight: '0!important',
      },
      input: {
        padding: '23px 20px 6px 19px!important',
      },
      popper: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
        padding: '0',
      },
      paper: {
        margin: '0',
      },
    },
    MuiInputBase: {
      input: {
        width: '0',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&$disabled': {
          color: '#52535A!important',
          cursor: 'default',
          backgroundColor: '#F9F9F9',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      root: {
        paddingBottom: '0px!important',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          background: 'none',
        },
      },
    },
  },
});

export const CurrentInputTnvedGpc = createMuiTheme({
  typography: {},
  overrides: {
    MuiAutocomplete: {
      listbox: {
        borderRadius: '3px',
      },
      popupIndicator: {
        marginRight: '10px',
      },
      option: {
        backgroundColor: 'transparent',
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
      inputRoot: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        paddingRight: '0!important',
        paddingBottom: '0',
      },
      input: {
        padding: '23px 35px 6px 19px!important',
        borderLeft: '4px solid #60E84A',
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
        '&:disabled': {
          borderLeft: 'none',
        },
      },
      popper: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
        padding: '0',
      },
      paper: {
        margin: '0',
      },
    },
    MuiInputBase: {
      input: {
        width: '0',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&$disabled': {
          color: '#52535A!important',
          cursor: 'default',
          backgroundColor: '#F9F9F9',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      root: {
        paddingBottom: '0px!important',
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        fontFamily: 'SegoeUI',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          background: 'none',
        },
      },
    },
  },
});
