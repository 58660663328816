import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const NewBlock = styled.div`
  .b-text {
    font-size: 50px;
  }
`;
export const DefaultProfileDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '720px',
        maxWidth: '720px',
        boxShadow: '0 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        minHeight: '320px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px 10px',
        overflow: 'hidden',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px 40px 80px',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
      },
      text: {
        padding: '18px 35px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontWeight: 'bold',
      },
    },
  },
});
export const NewTree = createMuiTheme({
  typography: {},
  overrides: {
    MuiExpansionPanelSummary: {
      content: {
        whiteSpace: 'normal!important',
        '&:last-child': {
          whiteSpace: 'normal!important',
        },
      },
    },
    MuiButton: {
      root: {
        background: '#0187B0',
        // background: "#F8EC31",
        borderRadius: '3px',
      },
      text: {
        padding: '8px 35px',
      },
    },
  },
});
