import React from 'react';
import {
  ErrorBlock,
  ErrorBlockLeft,
  ErrorBlockRight,
  ErrorCode,
  ErrorContainer,
  ErrorLink,
  ErrorTitle,
} from '../Errors.styled';
import { Error500 } from '../../../common/icons/customIcons';
import { Link } from 'react-router-dom';

export default function Page500() {
  return (
    <ErrorContainer>
      <ErrorBlock>
        <ErrorBlockLeft>
          <ErrorTitle>
            <ErrorCode id="code-500">500</ErrorCode>
            ОШИБКА СЕРВЕРА
          </ErrorTitle>
          На сервере произошла непредвиденная
          <br /> ошибка. Пожалуйста, подождите. Она вскоре
          <br /> будет исправлена.
          <ErrorLink>
            Попробуйте <Link to="/">вернуться на главную</Link>
          </ErrorLink>
        </ErrorBlockLeft>
        <ErrorBlockRight>
          <Error500 />
        </ErrorBlockRight>
      </ErrorBlock>
    </ErrorContainer>
  );
}
