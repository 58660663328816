import { all, call, put, takeEvery } from 'redux-saga/effects';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  LOAD_SITE_PROFILE_ACTION,
  SAVE_SITE_PROFILE_ACTION,
  LOAD_MARKET_FIELDS_ACTION,
} from './SiteProfile.constants';

import { throwNotification } from '../../../../common/structure';
import {
  loadSiteProfileSucceeded,
  loadSiteProfileFailed,
  saveSiteProfileSucceeded,
  saveSiteProfileFailed,
  loadMarketFieldsSucceeded,
  loadMarketFieldsFailed,
} from './SiteProfile.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

export function* loadMarketFields() {
  const errT = i18n.t('Произошла ошибка при загрузке категорий сайтов!');

  try {
    const response = yield call(
      client().get,
      `/dictionaries/parties/market-fields`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadMarketFieldsFailed());
    }

    yield put(loadMarketFieldsSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadMarketFieldsFailed());
  }
}

export function* loadSiteProfile() {
  const errT = i18n.t('Произошла ошибка при загрузке данных о сайте!');

  try {
    const response = yield call(client().get, `/profile/party`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(loadSiteProfileFailed());
    }

    yield put(loadSiteProfileSucceeded(response));
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadSiteProfileFailed());
  }
}

export function* saveSiteProfile(action) {
  const errT = i18n.t('Произошла ошибка при сохранении информации о сайте!');
  const URL = `/profile/party`;

  try {
    /**
     * TODO:
     * переписать на что-то более осмысленное.
     */
    let formData = new FormData();
    const data = { ...action.data };

    Object.keys(data).forEach((item) => {
      if (item === 'microLogo' || item === 'logo') {
        if (Array.isArray(data[item])) {
          formData.append(item, data[item][0]);
        }
      } else formData.append(item, data[item]);
    });

    // formData.append('seoName', data.seoName);
    // formData.append('url', data.url);
    // formData.append('isVisible', data.isVisible);
    // formData.append('microLogo', data.microLogo[0]);
    // formData.append('logo', data.logo[0]);
    // formData.append('marketField', data.marketField);
    // formData.append('description', data.description);
    // formData.append('deleteLogo', data.deleteLogo);
    // formData.append('deleteMicroLogo', data.deleteMicroLogo);

    const response = yield call(client().post, URL, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    if (response.error) {
      yield handleErrorNotification(response.error);
      return yield put(saveSiteProfileFailed());
    }

    yield call(loadSiteProfile);
    yield put(saveSiteProfileSucceeded());
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(saveSiteProfileFailed());
  }
}

export function* watchLoadMarketFields() {
  yield takeEvery(LOAD_MARKET_FIELDS_ACTION, loadMarketFields);
}

export function* watchLoadSiteProfile() {
  yield takeEvery(LOAD_SITE_PROFILE_ACTION, loadSiteProfile);
}

export function* watchSaveSiteProfile() {
  yield takeEvery(SAVE_SITE_PROFILE_ACTION, saveSiteProfile);
}

export default function* profileStatusSaga() {
  yield all([
    watchLoadMarketFields(),
    watchLoadSiteProfile(),
    watchSaveSiteProfile(),
  ]);
}
