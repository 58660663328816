export const LOAD_SITE_PROFILE_ACTION = 'LOAD_SITE_PROFILE_ACTION';
export const LOAD_SITE_PROFILE_ACTION_SUCCEEDED =
  'LOAD_SITE_PROFILE_ACTION_SUCCEEDED';
export const LOAD_SITE_PROFILE_ACTION_FAILED =
  'LOAD_SITE_PROFILE_ACTION_FAILED';

export const SAVE_SITE_PROFILE_ACTION = 'SAVE_FITE_PROFILE_ACTION';
export const SAVE_SITE_PROFILE_ACTION_SUCCEEDED =
  'SAVE_FITE_PROFILE_ACTION_SUCCEEDED';
export const SAVE_SITE_PROFILE_ACTION_FAILED =
  'SAVE_FITE_PROFILE_ACTION_FAILED';

export const LOAD_MARKET_FIELDS_ACTION = 'LOAD_MARKET_FIELDS_ACTION';
export const LOAD_MARKET_FIELDS_ACTION_SUCCEEDED =
  'LOAD_MARKET_FIELDS_ACTION_SUCCEEDED';
export const LOAD_MARKET_FIELDS_ACTION_FAILED =
  'LOAD_MARKET_FIELDS_ACTION_FAILED';
