import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const CreationSiteText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  line-height: 19px;
  color: #63666a;
`;
export const CreationSiteDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '570px',
        maxWidth: '570px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '18px 20px 25px',
        textAlign: 'center',
        position: 'relative',
        color: '#63666A',
        fontSize: '14px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 20px',
      },
    },
    MuiDialogActions: {
      root: {
        margin: '0',
        padding: '40px 20px',
        justifyContent: 'space-between',
      },
    },
    MuiButton: {
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        transition: '.4s ease',
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        padding: '12px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#52535A',
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        background: 'none',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '12px 35px',
        fontSize: '16px',
        fontWeight: '600',
        fontFamily: 'SegoeUI',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
  },
});
