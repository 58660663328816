import React from 'react';
import styled from 'styled-components';
import { Button, Select, FormControl, InputLabel } from '@material-ui/core';
import { IconDeleteSmall } from '../../common/icons/customIcons';

const Container = styled(FormControl)`
  position: relative;
  width: 100%;
`;

const CustomSelect = ({
  required,
  onChange,
  name,
  children,
  disabled,
  label,
  id,
  inputProps,
  IconComponent,
  hideCleanButton,
  ...restProps
}) => {
  const handleClear = () => {
    onChange({ target: { value: '', name } });
  };

  return (
    <div style={{ width: '100%' }}>
      <Container
        style={{ display: 'flex', alignItems: 'bottom' }}
        fullWidth={true}>
        <InputLabel disabled={disabled} htmlFor={id}>
          {label}
        </InputLabel>
        <Select
          style={{ width: '100%' }}
          inputProps={{ ...inputProps, id }}
          disabled={disabled}
          name={name}
          onChange={onChange}
          IconComponent={IconComponent}
          {...restProps}>
          {children}
        </Select>
        {!required && !hideCleanButton && (
          <Button
            style={{
              position: 'absolute',
              top: '50%',
              right: 25,
              width: 20,
              minWidth: 20,
              maxHeight: 20,
              minHeight: 20,
              backgroundColor: 'transparent',
              color: 'rgba(0, 0, 0, 0.87)',
              padding: '0px 10px',
              paddingBottom: '0px',
              paddingTop: '7px',
              fontSize: '0.875rem',
              transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 500,
              lineHeight: 1.75,
              borderRadius: 4,
              letterSpacing: '0.02857em',
              textTransform: 'uppercase',
              border: 0,
              cursor: 'pointer',
              margin: 0,
              display: 'inline-flex',
              outline: 0,
              alignItems: 'center',
              userSelect: 'none',
              verticalAlign: 'middle',
              '-moz-appearance': 'none',
              justifyContent: 'center',
              textDecoration: 'none',
              boxShadow: 'none',
              '-webkit-appearance': 'none',
              '-webkit-tap-highlight-color': 'transparent',
              marginTop: -10,
            }}
            onClick={handleClear}>
            <div style={{ paddingBottom: 5 }}>
              <IconDeleteSmall />
            </div>
          </Button>
        )}
      </Container>
    </div>
  );
};

export default CustomSelect;
