import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const CompaniesGridContainer = styled.div`
  padding: 20px;
  background: #fcfcfc;
  position: relative;
  

  @media (max-width: 1919px) {
    height: calc(100% - 70px);
  }

  @media (min-width: 600px) {
    height: calc(100% - 64px);
  }
`;

export const GridActionsView = styled.div`
  padding: 0 30px 0 0;
  flex-shrink: 0;
  @media (max-width: 1399px) {
    padding: 0 10px 0 0;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    padding: 0 25px 0 0;
  }
  display: flex;
  justify-content: space-between;
  //min-width: 300px
`;

export const ResetFilterButton = createMuiTheme({
    typography: {},
    overrides: {
      MuiButton: {
        label: {
          color: '#868686',
          fontSize: '14px',
          fontWeight: 'bold',
          //textTransform: "uppercase"
        },
      },
    },
});

export const GridTable = styled.div`
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 4px;
`;

export const ColumnCell = styled.div`
  position: relative;
  font-size: 14px;
  color: #52535a;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
`;

export const ColumnCellProfile = styled(ColumnCell)`
  font-size: 12px;
  color: #63666a;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
`;

export const PointerName = styled.span`
  cursor: pointer;
  color: #4AA5E8;
  white-space: nowrap;
  width: -webkit-fill-available;
  transition: 0.4s ease;
  &:hover {
    color: #63666a;
  }
`;