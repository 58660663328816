import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ColumnCellInner } from '../../../common/styles/Common.styled';
import { ColumnCell } from '../Catalog.styled';

export default class BrandCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    return (
      <ColumnCell>
        <ColumnCellInner>
          {this.props.value ? this.props.value : '—'}
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

BrandCell.propTypes = {
  value: PropTypes.string.isRequired,
};
