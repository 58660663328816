import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import {
  defaultSelectTheme,
  NewBackBtns,
} from '../../../../common/styles/Common.styled';
import {
  DialogProductTitleContentBack,
  NewProductDialogNext,
} from './PopupNewGtin.styled';
import {
  IconBack,
  IconLogoDropDownGray,
} from '../../../../common/icons/customIcons';
import { Preloader } from '../../../../components';
import { generationGtin } from '../../ducks/Product.actions';

class PopupNewGtin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packaging: 'trade-unit',
      typeGtin: 'EAN-13',
      loading: false,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  generationGtin = () => {
    this.setState({ loading: true });
    this.props.generationGtin(
      { packageLevel: this.state.packaging },
      (error, gtin) => {
        this.setState({ loading: false });
        if (!error) {
          this.props.returnGtin(gtin);
        } else {
          this.props.handleClose();
        }
      },
    );
  };

  render() {
    const { t } = this.props;
    const disabled = true;
    const id = Date.now();

    return (
      <MuiThemeProvider theme={NewProductDialogNext}>
        <Dialog open>
          <Preloader
            isOpen={this.state.loading}
            text={t('Генерация нового кода товара')}
          />

          {!this.state.loading && (
            <React.Fragment>
              <DialogTitle>
                <DialogProductTitleContentBack>
                  <MuiThemeProvider theme={NewBackBtns}>
                    <Button onClick={this.props.handleClose}>
                      {<IconBack />} {t('Назад')}
                    </Button>
                  </MuiThemeProvider>
                </DialogProductTitleContentBack>
              </DialogTitle>
              <DialogContent>
                <FormControl fullWidth={true}>
                  <MuiThemeProvider theme={defaultSelectTheme}>
                    <InputLabel disabled htmlFor={'packaging-select-' + id}>
                      {t('Тип упаковки')}
                    </InputLabel>
                    <Select
                      required
                      disabled={disabled}
                      valid={'true'}
                      value={this.state.packaging}
                      inputProps={{ id: `packaging-select-${id}` }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      IconComponent={IconLogoDropDownGray}
                      className={cx({ 'disabled-item': disabled })}
                      onChange={(event) =>
                        this.handleChange('packaging', event.target.value)
                      }>
                      <MenuItem value="trade-unit">
                        {t('Потребительская упаковка')}
                      </MenuItem>
                    </Select>
                  </MuiThemeProvider>
                </FormControl>

                <FormControl fullWidth={true}>
                  <MuiThemeProvider theme={defaultSelectTheme}>
                    <InputLabel disabled htmlFor="type-gtin-select">
                      {t('Тип кода товара')}
                    </InputLabel>
                    <Select
                      required
                      disabled={disabled}
                      valid={'true'}
                      value={this.state.typeGtin}
                      inputProps={{ id: 'type-gtin-select' }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      IconComponent={IconLogoDropDownGray}
                      className={cx({ 'disabled-item': disabled })}
                      onChange={(event) =>
                        this.handleChange('typeGtin', event.target.value)
                      }>
                      <MenuItem value="EAN-13">EAN-13</MenuItem>
                    </Select>
                  </MuiThemeProvider>
                </FormControl>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'flex-start' }}>
                <Button onClick={this.generationGtin}>
                  {t('Сгенерировать новый код товара')}
                </Button>
              </DialogActions>
            </React.Fragment>
          )}
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      generationGtin: (data, cbk) => generationGtin(data, cbk),
    },
    dispatch,
  );
};

PopupNewGtin.propTypes = {
  handleClose: PropTypes.func.isRequired,
  returnGtin: PropTypes.func.isRequired,
  generationGtin: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(PopupNewGtin);
