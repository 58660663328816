import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #63666a;
  margin-bottom: 60px;
  font-weight: 600;
`;

export const Line = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 70px;
  ${(props) => props.isSafari && 'margin-left: -22px;'}
`;
