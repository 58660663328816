import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  deburr,
  defaultTo,
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  isEqual,
  isNull,
  some,
  toLower,
  trim,
} from 'lodash';
import {
  Button,
  FormControl,
  MenuItem,
  MuiThemeProvider,
  Popover,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '../../../../components';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterTypingSelect,
} from './Filters.styled';
import { loadDictionaryGoodsCategory } from '../../ducks/Products.actions';
import {
  IconClose,
  IconFilterWhite,
  IconFilteredSquareOrange,
} from '../../../../common/icons/customIcons';
import * as selectors from '../ducks/ProductGrid.selectors';

class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      category: defaultTo(this.props.currentValue, ''),
      currentValue: this.props.currentValue,
      suggestions: this.props.dictionaryCategory,
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.dictionaryCategory)) {
      this.props.fetchDictionaryCategory();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        category: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['dictionaryCategory', 'currentValue'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.category,
    });
    this.props.onAccept('category', this.state.category);
  };

  handleDissmiss = () => {
    this.setState({
      anchorEl: null,
      category: '',
    });
    this.props.onDismiss('category');
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <Tooltip placement="left" title={suggestion.text}>
        <MenuItem
          disableGutters={true}
          selected={isHighlighted}
          component="div">
          {suggestion.text}
        </MenuItem>
      </Tooltip>
    );
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ category: suggestion });
    return suggestion.text;
  };

  getSuggestions = (value) => {
    const inputValue = toLower(deburr(trim(value)));
    return inputValue.length === 0
      ? this.props.dictionaryCategory.slice(0, 30)
      : filter(this.props.dictionaryCategory, (item) =>
          includes(toLower(item.text), inputValue),
        );
  };

  handleSuggestionsFetchRequested = (value) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.dictionaryCategory.slice(0, 10),
      category: '',
    });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconFilterWhite />
              ) : (
                <IconFilteredSquareOrange />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Категория')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={FilterTypingSelect}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    suggestions={this.state.suggestions}
                    name="category"
                    placeholder={t('Выберите категорию')}
                    handleSuggestionsFetchRequested={
                      this.handleSuggestionsFetchRequested
                    }
                    handleSuggestionsClearRequested={
                      this.handleSuggestionsClearRequested
                    }
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    initValue={get(this.state, 'currentValue.text', '')}
                    emptyLabel={true}
                  />
                </FormControl>
              </MuiThemeProvider>
            </FilterContent>
            <FilterBtns>
              <Button
                disabled={!this.state.category}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
              <Button
                disabled={!this.state.category}
                className="backEmpty"
                onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

CategoryFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fetchDictionaryCategory: PropTypes.func.isRequired,
  dictionaryCategory: PropTypes.array.isRequired,
  currentValue: PropTypes.object,
  parentId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dictionaryCategory: selectors.dictionaryCategory(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDictionaryCategory: () => loadDictionaryGoodsCategory(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(CategoryFilter);
