import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import { Gs1, DocumentsAndOptions } from '../../views';
import * as selectorsGS from '../../ducks/GS1/GS1.selectors';
import {
  SmallTabsNew,
  ProductManagementCont,
} from '../profileManagement/ProfileManagement.styled';

class GsOneInteractions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChangeTab = (event, value) => this.setState({ value });

  render() {
    const { loader, t, userCompanyInfo, userInfo } = this.props;

    return (
      <ProductManagementCont>
        <MuiThemeProvider theme={SmallTabsNew}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            variant="fullWidth">
            <Tab disabled={loader} label={t('Статистика и опции')} />
            <Tab
              disabled={loader || userCompanyInfo.isImported || !userInfo.isELK}
              label={t('Заявление в GS1')}
            />
            {/* <Tab disabled={loader} label={t('Индентификаторы', {gs1Name: t(config.gs1Name)})} /> */}
          </Tabs>
        </MuiThemeProvider>
        {this.state.value === 0 && <DocumentsAndOptions />}
        {this.state.value === 1 && <Gs1 />}
        {/* {this.state.value === 2 && <Gs1Identificators/>} */}
      </ProductManagementCont>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: selectorsGS.loader(state),
  userCompanyInfo: state.profileReducer.info.company,
  userInfo: state.profileReducer.info.user,
});

export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    // mapDispatchToProps,
  ),
)(GsOneInteractions);
