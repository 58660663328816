export const LOGIN_ACTION = 'LOGIN';
export const LOGIN_SUCCEEDED_ACTION = 'LOGIN_SUCCEEDED';
export const LOGIN_FAILED_ACTION = 'LOGIN_FAILED';
export const LOGOUT_ACTION = 'LOGOUT';
export const LOGOUT_SUCCESS_ACTION = 'LOGOUT_SUCCESS';
export const REMIND_PASSWORD_ACTION = 'REMIND_PASSWORD';
export const REMIND_PASSWORD_SUCCEEDED_ACTION = 'REMIND_PASSWORD_SUCCEEDED';
export const REMIND_PASSWORD_FAILED_ACTION = 'REMIND_PASSWORD_FAILED';
export const AUTHENTICATION_SESSION_ACTIONS = 'AUTHENTICATION_SESSION';
export const ACTIVE_SESSION_OPEN_MODAL_ACTION = 'ACTIVE_SESSION_OPEN_MODAL';
export const ACTIVE_SESSION_CLOSE_MODAL_ACTION = 'ACTIVE_SESSION_CLOSE_MODAL';
export const ACTIVE_ELK_SESSION_OPEN_MODAL_ACTION = 'ACTIVE_ELK_SESSION_OPEN_MODAL';
export const ACTIVE_ELK_SESSION_CLOSE_MODAL_ACTION = 'ACTIVE_ELK_SESSION_CLOSE_MODAL';
