import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const customInputLeft = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px 0 0 3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B!important',
        },
      },
      input: {
        padding: '15px 19px 12px',
        lineHeight: '23px',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderRadius: '3px 0 0 3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiSelect: {
      icon: {
        right: '-8px',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        height: 'auto',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
