import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MuiThemeProvider,
} from '@material-ui/core';
import { DialogText, DialogTitleStyled } from '../../styles/Common.styled';
import { IconCheck } from '../../icons/customIcons';
import { CertificateSelectModal } from '../../../components';
import { loadCertificates } from '../../../pages/profile/ducks/Profile/Profile.actions';
import {
  Alert,
  ConsentDialog,
  SmallText,
} from './ConsentProvisionOfDataModal.styled';

class ConsentProvisionOfDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectCertModal: false,
      agreement: false,
      refusal: false,
      certificate: {},
    };
  }

  handleClose = () => {
    if (this.state.refusal) {
      this.props.handleClose();
    } else {
      this.setState({ refusal: true });
    }
  };

  handleConfirmation = () => {
    this.props.handleClose();
  };

  changeAgreement = () => {
    this.setState({ agreement: !this.state.agreement });
  };

  onSelectCertClick = () => {
    this.setState({ selectCertModal: !this.state.selectCertModal });
  };

  onSelectCertificate = (value) => {
    this.setState({ certificate: value });
  };

  sendData = (certificate) => {
    this.onSelectCertClick();
    this.handleConfirmation();
  };

  render() {
    const { t } = this.props;

    const agreementText = `Настоящим Вы подтверждаете, что даете ООО «Оператор-ЦРПТ» согласие на обработку 
        ООО «Оператор-ЦРПТ» информации, внесенной Вами в Государственную информационную систему за оборотом товаров, 
        далее – Система, в том числе на обработку данных о товарах подлежащих обязательной маркировке 
        средствами идентификации, содержащихся в Системе, с правом передачи такой информации ООО «Национальный каталог». 
        ООО «Национальный каталог» вправе размещать указанную Вами информацию в Информационной системе 
        «Национальный каталог», включая использование информации следующими способами: воспроизведение, 
        доведение до всеобщего сведения, публичный показ, с правом передачи такой информации третьим лицам.`;

    return (
      <MuiThemeProvider theme={ConsentDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>
              {t('Согласие на передачу данных на обработку')}
            </DialogTitleStyled>
          </DialogTitle>
          <DialogContent>
            <DialogText>
              {this.state.refusal && (
                <Alert>
                  {t(
                    'Внимание! Для доступа к полному функционалу системы и возможности работы ' +
                      'с товарами необходимо подписать Согласие',
                  )}
                  .
                </Alert>
              )}
              <br />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.agreement}
                      onChange={this.changeAgreement}
                      checkedIcon={<IconCheck />}
                    />
                  }
                  label={agreementText}
                />
              </div>
              <SmallText>
                {t(
                  'Нажатием на кнопку «Подписать» Вы даете свое согласие с условиями передачи данных ' +
                    'на проверку третьей стороне',
                )}
              </SmallText>
            </DialogText>
          </DialogContent>

          <DialogActions>
            <Button
              color={'primary'}
              onClick={this.onSelectCertClick}
              disabled={!this.state.agreement}>
              {t('Подписать')}
            </Button>

            <Button color={'secondary'} onClick={this.handleClose}>
              {t('Отменить')}
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.selectCertModal && (
          <CertificateSelectModal
            acceptBtnText={t('Подтвердить')}
            onGetAllCerts={this.props.onLoadCertificates}
            onSelectCert={(cert) => this.onSelectCertificate(cert)}
            onSubmit={(certificate) => this.sendData(certificate)}
            onCancel={this.onSelectCertClick}
            cert={this.state.certificate}
            certificates={this.props.certificates}
          />
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  certificates: state.profileReducer.certificates,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadCertificates: () => loadCertificates(),
    },
    dispatch,
  );
};

ConsentProvisionOfDataModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ConsentProvisionOfDataModal);
