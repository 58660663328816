import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Line } from './SidebarBrowserContent.styled';
import FirefoxLogo from './Logos/FirefoxLogo';
import ChromeLogo from './Logos/ChromeLogo';
import IELogo from './Logos/IELogo';
import SafariLogo from './Logos/SafariLogo';
import { IconClose } from '../../../common/icons/customIcons';
import {
  HowToPanel,
  PanelContent,
  PanelContentMin,
  PanelContentMinText,
  PanelInner,
  PanelTitle,
} from '../../authentication/views/AuthentificationHowTo/AuthentificationHowTo.styled';

const SidebarBrowserContent = (props) => {
  const { onClose, t } = props;

  return (
    <HowToPanel>
      <PanelInner>
        <PanelTitle>
          <h2>{t('Выберите удобный для вас браузер')}</h2>
          <button style={{ maxHeight: '40px' }} onClick={onClose}>
            <IconClose />
          </button>
        </PanelTitle>
        <PanelContent>
          {t(
            'Вы используете браузер, который не поддерживает шифрование защищенных соединений',
          )}
          . &nbsp;
          {t('Пожалуйста, обновите ваш браузер для регистрации в системе')}
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.mozilla.org">
                <FirefoxLogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.com/chrome/">
                <ChromeLogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.microsoft.com/ru-ru/download/internet-explorer-11-for-windows-7-details.aspx">
                <IELogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                isSafari
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.apple.com/ru/safari/">
                <SafariLogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
        </PanelContent>
      </PanelInner>
    </HowToPanel>
  );
};

SidebarBrowserContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withTranslation()(SidebarBrowserContent);
