import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { throwNotification } from '../../common/structure';
import { CancelToken, client } from '../../api/Client';
import { gtinDisplay } from '../../common/utils/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { IconPreloader } from '../../common/icons/customIcons';
import {
  DialogRow,
  DialogRowLeft,
  DialogRowRight,
  DialogTitleRemainder,
  RemainderDialog,
  RemainderDialogTitleContent,
} from './RemainderModal.styled';

let cancelGetRemainder;

async function getRemainder(goodId) {
  const result = await client().instance.get(`/goods/remainders/${goodId}`, {
    cancelToken: new CancelToken((c) => {
      cancelGetRemainder = c;
    }),
  });
  return result.data;
}

const RenderItem = (props) => {
  return (
    <DialogRow>
      <DialogRowLeft>{props.attrName}</DialogRowLeft>
      <DialogRowRight>{props.attrValue}</DialogRowRight>
    </DialogRow>
  );
};

class RemainderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      remainder: {},
      loading: true,
    };
  }

  componentDidMount() {
    getRemainder(this.props.goodId)
      .then((response) => {
        if (_.isEmpty(response)) {
          this.rejectModal('Остатки не найдены');
        } else {
          this.setState({ loading: false, remainder: response });
        }
      })
      .catch((e) => {
        if (this.unmount) return;
        this.rejectModal('Ошибка запроса данных!');
      });
  }

  componentWillUnmount() {
    this.unmount = true;
    if (this.state.loading) {
      cancelGetRemainder('canceled');
    }
  }

  rejectModal = (textError) => {
    const throwError = _.flow([this.props.t, this.props.throwError]);
    throwError(textError);
    this.setState({ loading: false }, this.handleClose);
  };

  handleClose = () => this.props.handleClose(null, null);

  render() {
    const { t } = this.props;
    const { remainder, loading } = this.state;
    const IsData = !loading && !_.isEmpty(remainder);
    const title = IsData
      ? `${t('Код товара')} ${gtinDisplay(remainder.gtin)}`
      : '';
    const attrs = _.get(remainder, 'attrs', []);

    return (
      <MuiThemeProvider theme={RemainderDialog}>
        <Dialog open>
          <DialogTitle>
            <RemainderDialogTitleContent>
              <DialogTitleRemainder>{title}</DialogTitleRemainder>
            </RemainderDialogTitleContent>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <IconPreloader />
            ) : (
              <React.Fragment>
                {remainder.name && (
                  <RenderItem
                    attrName={t('Наименование')}
                    attrValue={remainder.name}
                  />
                )}
                {_.map(attrs, (v, k) => (
                  <RenderItem key={`remainder_${k}`} {...v} />
                ))}
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>{t('Закрыть')}</Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

RemainderModal.propTypes = {
  throwError: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  goodId: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(RemainderModal);
