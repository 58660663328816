export class NCALayerService {
  static webSocket;
  static callback;
  static webSocketUrl = 'wss://127.0.0.1:13579/';
  static module = 'kz.gov.pki.knca.commonUtils';

  static getStorageData(method, args) {
    return new Promise((resolve, reject) => {
      if (NCALayerService.isConnectionAlive()) {
        NCALayerService.callback = (result) => resolve(result);

        NCALayerService.webSocket.send(
          JSON.stringify({
            method,
            args,
          }),
        );
      } else {
        reject();
      }
    });
  }

  /** get data via signature
   * @method - (createCMSSignatureFromBase64, getKeyInfo, ...)
   * @args array of [
   * storageType - type of storage (PKCS12, AKKaztokenStore, AKKZIDCardStore)
   * keType - type of use key (SIGNATURE, AUTHENTICATION)
   * data - source data
   * attached - if "true" attached signed data to result
   * ]
   */

  static getData(method, args) {
    return new Promise((resolve) => {
      if (NCALayerService.isConnectionAlive()) {
        NCALayerService.callback = (result) => resolve(result);
        NCALayerService.webSocket.send(
          JSON.stringify({
            module: NCALayerService.module,
            method,
            args,
          }),
        );
      }
    });
  }

  static isConnectionAlive() {
    return NCALayerService.webSocket.readyState === 1;
  }

  static isNotAvailable() {
    return (
      NCALayerService.webSocket === undefined ||
      NCALayerService.webSocket.readyState === 0 ||
      NCALayerService.webSocket.readyState === 2 ||
      NCALayerService.webSocket.readyState === 3
    );
  }

  static isSystemMessage(message) {
    // eslint-disable-next-line no-prototype-builtins
    return message.result && message.result.hasOwnProperty('version');
  }

  static init() {
    return new Promise((resolve, reject) => {
      NCALayerService.webSocket = new WebSocket(NCALayerService.webSocketUrl);

      NCALayerService.webSocket.onopen = () => resolve();
      NCALayerService.webSocket.onerror = () => {
        if (NCALayerService.isNotAvailable()) {
          reject(
            'Ошибка соединения с NCALayer. Возможно у вас не установлен модуль NCALayer.',
          );
        }
      };

      NCALayerService.webSocket.onmessage = (event) => {
        const response = JSON.parse(event.data);

        if (response != null && !NCALayerService.isSystemMessage(response)) {
          const result = {
            code: response.code,
            message: response.message,
            responseObject: response.responseObject,
            errorCode: response.errorCode,
          };

          NCALayerService.callback && NCALayerService.callback(result);
        }
      };
    });
  }

  static close() {
    NCALayerService.webSocket.close();
  }
}
