import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogContent, MuiThemeProvider } from '@material-ui/core';
import { DialogTextMod, ModDialog } from './styled';
import { Preloader } from '../index';

class ModalLoader extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={ModDialog}>
        <Dialog open>
          <DialogContent>
            <DialogTextMod>
              <Preloader isOpen fullHeight={false} text={this.props.text} />
            </DialogTextMod>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ModalLoader.propTypes = {
  text: PropTypes.string,
};

export default ModalLoader;
