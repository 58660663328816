import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';

export const TabContentPaddingless = styled.div`
  padding: 0;
  @media (max-width: 1024px) {
    padding: 0 0 40px;
  }
`;
export const TabButton = styled.button`
  background: none;
  border-radius: 0;
  border: none;
  color: #52535a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 11px 50px;
  min-width: 310px;
  cursor: pointer;
  :disabled {
    border-bottom: 4px solid ${colors.Tabs.tabLine};
  }
  @media (max-width: 768px) {
    min-width: 110px;
  }
  @media (max-width: 600px) {
    padding: 11px 20px;
  }
`;
export const HowLink = styled.a`
  line-height: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: #4aa5e8;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.4s ease;
  font-weight: bold;
  :hover {
    opacity: 0.7;
  }
  @media (max-width: 700px) {
    margin-top: 20px;
  }
`;
