import i18n from '../../i18n';

// БИК
export const validateBik = (bik, error = { code: null, message: null }) => {
  let result = false;
  if (typeof bik === 'number') {
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    bik = '';
  }
  if (!bik.length) {
    error.code = 1;
    error.message = i18n.t('БИК пуст');
  } else if (/[^0-9]/.test(bik)) {
    error.code = 2;
    error.message = i18n.t('БИК может состоять только из цифр');
  } else if (bik.length !== 9) {
    error.code = 3;
    error.message = i18n.t('Минимальная длина поля БИК 9 символов');
  } else {
    result = true;
  }
  return result;
};

// ИНН
export const validateInn = (inn, error = { code: null, message: null }) => {
  let result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = i18n.t('ИНН пуст');
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = i18n.t('ИНН может состоять только из цифр');
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = i18n.t('ИНН может состоять только из 10 или 12 цифр');
  } else {
    let checkDigit = function (inn, coefficients) {
      let n = 0;
      // eslint-disable-next-line
      for (let i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt((n % 11) % 10);
    };
    switch (inn.length) {
      case 10:
        let n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        let n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        let n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
          result = true;
        }
        break;
      default:
        break;
    }
    if (!result) {
      error.code = 4;
      error.message = i18n.t('Неправильное контрольное число');
    }
  }
  return result;
};

// for using in future
// КПП
// export const validateKpp = (kpp, error = {code: null, message: null}) => {
//     let result = false;
//     if (typeof kpp === 'number') {
//         kpp = kpp.toString();
//     } else if (typeof kpp !== 'string') {
//         kpp = '';
//     }
//     if (!kpp.length) {
//         error.code = 1;
//         error.message = i18n.t('КПП пуст');
//     } else if (kpp.length !== 9) {
//         error.code = 2;
//         error.message = i18n.t('КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)');
//     } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
//         error.code = 3;
//         error.message = i18n.t('Неправильный формат КПП');
//     } else {
//         result = true;
//     }
//     return result;
// };

// Корр. счет
export const validateKs = (ks, bik, error = { code: null, message: null }) => {
  let result = false;
  if (validateBik(bik, error)) {
    if (typeof ks === 'number') {
      ks = ks.toString();
    } else if (typeof ks !== 'string') {
      ks = '';
    }
    if (!ks.length) {
      error.code = 1;
      error.message = i18n.t('К/С пуст');
    } else if (/[^0-9]/.test(ks)) {
      error.code = 2;
      error.message = i18n.t('К/С может состоять только из цифр');
    } else if (ks.length !== 20) {
      error.code = 3;
      error.message = i18n.t(
        'Минимальная длина для поля корреспондентского счета 20 символов',
      );
    } else {
      let bikKs = '0' + bik.toString().slice(4, 6) + ks;
      let checksum = 0;
      let coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      // eslint-disable-next-line
      for (let i in coefficients) {
        checksum += coefficients[i] * (bikKs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = true;
      } else {
        error.code = 4;
        error.message = i18n.t('Неправильное контрольное число');
      }
    }
  }
  return result;
};

// for using in future
// ОГРН
// export const validateOgrn = (ogrn, error = {code: null, message: null}) => {
//     let result = false;
//     if (typeof ogrn === 'number') {
//         ogrn = ogrn.toString();
//     } else if (typeof ogrn !== 'string') {
//         ogrn = '';
//     }
//     if (!ogrn.length) {
//         error.code = 1;
//         error.message = i18n.t('ОГРН пуст');
//     } else if (/[^0-9]/.test(ogrn)) {
//         error.code = 2;
//         error.message = i18n.t('ОГРН может состоять только из цифр');
//     } else if (ogrn.length !== 13 && ogrn.length !== 15) {
//         error.code = 3;
//         error.message = i18n.t('ОГРН может состоять только из 13 или 15 цифр');
//     } else {
//         let n13 = parseInt((parseInt(ogrn.slice(0, -1)) % (ogrn.length === 13 ? 11 : 13)).toString().slice(-1));
//         if (n13 === parseInt(ogrn.length === 13 ? ogrn[12] : ogrn[14])) {
//             result = true;
//         } else {
//             error.code = 4;
//             error.message = i18n.t('Неправильное контрольное число');
//         }
//     }
//     return result;
// };

// ОГРНИП
export const validateOgrnip = (
  ogrnip,
  error = { code: null, message: null },
) => {
  let result = false;
  if (typeof ogrnip === 'number') {
    ogrnip = ogrnip.toString();
  } else if (typeof ogrnip !== 'string') {
    ogrnip = '';
  }
  if (!ogrnip.length) {
    error.code = 1;
    error.message = i18n.t('ОГРНИП пуст');
  } else if (/[^0-9]/.test(ogrnip)) {
    error.code = 2;
    error.message = i18n.t('ОГРНИП может состоять только из цифр');
  } else if (ogrnip.length !== 15) {
    error.code = 3;
    error.message = i18n.t('ОГРНИП может состоять только из 15 цифр');
  } else {
    let n15 = parseInt(
      (parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1),
    );
    if (n15 === parseInt(ogrnip[14])) {
      result = true;
    } else {
      error.code = 4;
      error.message = i18n.t('Неправильное контрольное число');
    }
  }
  return result;
};

// Расчетный счет
export const validateRs = (rs, bik, error = { code: null, message: null }) => {
  let result = false;
  if (validateBik(bik, error)) {
    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      error.code = 1;
      error.message = i18n.t('Р/С пуст');
    } else if (/[^0-9]/.test(rs)) {
      error.code = 2;
      error.message = i18n.t('Р/С может состоять только из цифр');
    } else if (rs.length !== 20) {
      error.code = 3;
      error.message = i18n.t(
        'Минимальная длина для поля Расчетный счет 20 символов',
      );
    } else {
      let bikRs = bik.toString().slice(-3) + rs;
      let checksum = 0;
      let coefficients = [
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
        3,
        7,
        1,
      ];
      // eslint-disable-next-line
      for (let i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = true;
      } else {
        error.code = 4;
        error.message = i18n.t('Неправильное контрольное число');
      }
    }
  }
  return result;
};

// СНИЛС
export const validateSnils = (snils, error = { code: null, message: null }) => {
  let result = false;
  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }
  if (!snils.length) {
    error.code = 1;
    error.message = i18n.t('СНИЛС пуст');
  } else if (/[^0-9]/.test(snils)) {
    error.code = 2;
    error.message = i18n.t('СНИЛС может состоять только из цифр');
  } else if (snils.length !== 11) {
    error.code = 3;
    error.message = i18n.t('СНИЛС может состоять только из 11 цифр');
  } else {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    let checkDigit = 0;
    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true;
    } else {
      error.code = 4;
      error.message = i18n.t('Неправильное контрольное число');
    }
  }
  return result;
};
