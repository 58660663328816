import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../common/styles/Common.colors';

export const DefaultConfirmDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '640px',
        maxWidth: '640px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, .15)',
        borderRadius: '4px',
        padding: '0',
      },
      paperWidthSm: {
        maxWidth: '640px',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '20px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'capitalize',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#52535A',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'capitalize',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px',
        fontSize: '18px;',
        color: '#63666A',
        textAlign: 'center',
        minHeight: '60px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px 100px',
        margin: '0',
        justifyContent: 'space-between',
      },
    },
  },
});
export const ConfirmDialogButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .backEmpty {
    background: none;
    border: 1px solid #868686;
    margin-left: 20px;
  }
`;
