import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Line } from './SidebarPluginContent.styled';
import CryptoProLogo from './Logos/CryptoProLogo';
import RutokenLogo from './Logos/RutokenLogo';
import { IconClose } from '../../../common/icons/customIcons';
import {
  HowToPanel,
  PanelContent,
  PanelContentMin,
  PanelContentMinText,
  PanelInner,
  PanelTitle,
} from '../../authentication/views/AuthentificationHowTo/AuthentificationHowTo.styled';

const SidebarPluginContent = (props) => {
  const { onClose, t } = props;

  return (
    <HowToPanel>
      <PanelInner>
        <PanelTitle>
          <h2>{t('Установите криптографический плагин')}</h2>
          <button style={{ maxHeight: '40px' }} onClick={onClose}>
            <IconClose />
          </button>
        </PanelTitle>
        <PanelContent>
          {t(
            'Криптографический плагин предназначен для создания и проверки электронной подписи на веб-страницах',
          )}
          . &nbsp;
          {t('Выберите и скачайте плагин')}.
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cryptopro.ru/products/cades/plugin/">
                <CryptoProLogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
          <PanelContentMin>
            <PanelContentMinText>
              <Line
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.rutoken.ru/products/all/rutoken-plugin/">
                <RutokenLogo />
              </Line>
            </PanelContentMinText>
          </PanelContentMin>
        </PanelContent>
      </PanelInner>
    </HowToPanel>
  );
};

SidebarPluginContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withTranslation()(SidebarPluginContent);
