import * as React from 'react';
import memoize from 'memoize-state';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { scroller, animateScroll as scroll } from 'react-scroll';
import _, { debounce } from 'lodash';
import { Button, DialogActions, MuiThemeProvider } from '@material-ui/core';
import MediaContent from '../MediaContent';
import PackagingInformation from '../PackagingInformation';
import ConsumerPackage from '../ConsumerPackage';
import MultipleAndTransportPackageInputs from '../MultipleAndTransportInputs';
import { moderationRecall } from '../../../products/ducks/Products.actions';
import {
  CertificateSelectModal,
  ConfirmDialog,
  FloatingButton,
  FloatingButtonView,
  ModalLoader,
} from '../../../../components';
import {
  IconModeration,
  IconProductClose,
  IconProductEdit,
  IconStatusDraft,
  IconValidMin,
  IconPublish,
  IconInvalid,
  IconStatusQueue,
} from '../../../../common/icons/customIcons';
import { config } from '../../../../config';
import { findStatusObj, gtinDisplay } from '../../../../common/utils/utils';
import { throwNotification } from '../../../../common/structure';
import i18n from '../../../../i18n';
import {
  moderateProduct,
  saveProduct,
  loadPhtotoTypes,
} from '../../ducks/Product.actions';
import {
  openDraft,
  publishProduct,
  setFiltersGrid,
} from '../../../products/ducks/Products.actions';
import { loadCertificates } from '../../../profile/ducks/Profile/Profile.actions';
import {
  defaultTheme,
  StickyBtns,
} from '../../../../common/styles/Common.styled';
import { withRouter } from 'react-router-dom';
import {
  NewProductGtin,
  NewProductTitle,
  ProductCont,
  ProductEditButtons,
  ProductMainContainer,
  ProductStatus,
  ProductStatusInfo,
  ProductStatusLine,
  StatusBar,
  TitleClarification,
  CardState,
} from './ProductEdit.styled';
import { ProfileDivider } from '../../../profile/Profile.styled';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { client } from '../../../../api/Client';

function isButtonDisabled(errors, item) {
  let attrErrors = _.reduce(
    errors.attrGroup,
    (g, group) => {
      if (_.some(_.values(group)) && _.isEmpty(item)) {
        item.id = `${_.keys(group)[0]}`;
      }
      return g || _.some(_.values(group));
    },
    false,
  );

  let packageIndex = 0;
  const packageErrors = _.reduce(
    errors.identifier,
    (p, packaging) => {
      if (_.some(_.values(packaging)) && _.isEmpty(item)) {
        _.forEach(_.keys(packaging), (key) => {
          if (packaging[key] && _.isEmpty(item)) {
            if (key === 'status') {
              item.id = `gtin_${packageIndex}`;
            } else {
              item.id = `${key}_${packageIndex}`;
            }
          }
        });
      }
      packageIndex++;
      return p || _.some(_.values(packaging));
    },
    false,
  );

  if (_.some(_.values(errors.images)) && _.isEmpty(item)) {
    _.forEach(_.keys(errors.images), (key) => {
      if (errors.images[key] && _.isEmpty(item)) {
        item.id = key;
      }
    });
  }
  return attrErrors || packageErrors || _.some(_.values(errors.images));
}

function isSaveButtonDisabled(data, productData, errors) {
  let imagesError;
  const countItems = _.size(data.images);
  const isEmptyProductImg = _.isEmpty(productData.images);

  if (isEmptyProductImg && countItems === 1) {
    const im = _.get(data, 'images[0].url');
    imagesError = _.isString(im) && _.isEmpty(im);
  } else {
    const isErrorImg = [];
    const isNotChangeImg = [];
    if (!isEmptyProductImg) {
      isNotChangeImg.push(_.size(productData.images) === countItems);
    }

    _.forEach(data.images, (image, index) => {
      const emptyStringUrl = _.isString(image.url) && _.isEmpty(image.url);
      if (productData.images[index]) {
        const a = _.pick(productData.images[index], ['url', 'gtin', 'type']);
        const b = _.pick(image, ['url', 'gtin', 'type']);
        const isEq = _.isEqual(a, b);
        isNotChangeImg.push(isEq);
        if (!isEq && countItems !== 1) {
          isErrorImg.push(emptyStringUrl);
        }
      } else {
        isErrorImg.push(emptyStringUrl);
      }
    });

    const sizeError = _.size(isErrorImg);
    const isError = _.some(isErrorImg);

    imagesError = _.every(isNotChangeImg)
      ? sizeError
        ? isError
        : true
      : sizeError
      ? isError
      : false;
  }

  let packageErrors = false;
  _.forEach(errors.identifier, (packaging) => {
    packageErrors = !_.isNil(packaging['status']) && packaging['status'];
  });

  const equalAttrGroup = _.isEqual(
    _attrGroupAdapter.get(data.attrGroup),
    productData.attrGroup,
  );
  const equalFlag =
    equalAttrGroup &&
    _.isEqual(data.identifier, productData.identifier) &&
    _.isEqual(data.categories, productData.categories);
  return (equalFlag && imagesError) || packageErrors;
}

const _attrGroupAdapter = {
  set: (attrGroup) => {
    const newAttrGroup = _.cloneDeep(attrGroup);
    _.forEach(newAttrGroup, (groupItem) => {
      _.forEach(groupItem.attributes, (attrItem) => {
        attrItem._attruid = attrItem._attruid || _.uniqueId('attruid_');
      });
    });
    return _.defaultTo(newAttrGroup, []);
  },
  get: (attrGroup) => {
    const newAttrGroup = _.cloneDeep(attrGroup);
    _.forEach(newAttrGroup, (groupItem) => {
      _.forEach(groupItem.attributes, (attrItem) => {
        delete attrItem._attruid;
      });
    });
    return _.defaultTo(newAttrGroup, []);
  },
};
/**
 * Сортируем аттрибуты в соответствующем порядке
 * @param {attrGroup} - аттрибуты которые относятся к самой упаковке
 * @param {outerAttr} - аттрибуты которые приходят отдельно и относятся к уже зарегестрированным упаковкам.
 */
function attributesSorter(attrGroup, outerAttr) {
  const attributes = _.cloneDeep(attrGroup);
  /**
   * Костыль для того чтобы между группами мультиплицированных полей было визуальное разделение.
   */
  if (outerAttr && outerAttr.length) {
    outerAttr = _.forEach(outerAttr, (attr, index) => {
      if (
        index !== outerAttr.length - 1 &&
        attr.attributes[attr.attributes.length - 1].name !== 'divider'
      ) {
        attr.attributes.push({ name: 'divider' });
      }
    });
  }

  const sortedAttributes = _.forEach(attributes, (mainBlock) => {
    if (mainBlock.id === 200) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * @param {15800}  - Код вложенной упаковки
       * @param {15803} - Количество вложенных упаковок
       * @param ????? - Торговое наименование товара | приходит из внешнего объекта, нужно добавлять в resultArray
       * @param ????? - Нетто | приходит из внешнего объекта, нужно добавлять в resultArray
       * @param ????? - ТНВЭД | приходит из внешнего объекта, нужно добавлять в resultArray
       */
      [15800, 15803].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[groupedAttr[0][i], groupedAttr[1][i]].filter((x) => x),
        );
        /**
         * Ищем объект внутри аттрибутов, которые приходят из вне, gtin объекта должен совпадать с
         * value у "Код вложенной упаковки".
         *
         * После чего из этого объекта надо забрать массив attributes и смержить его в resultArray, для
         * вывода, не нарушая при этом последовательность.
         */
        if (outerAttr && outerAttr.length) {
          const correspondOuterAttributes = _.find(outerAttr, (obj) => {
            return obj.gtin === groupedAttr[0][i].value;
          });
          if (!_.isEmpty(correspondOuterAttributes)) {
            const fixedAttributes = _.forEach(
              correspondOuterAttributes.attributes,
              (attribute) => {
                attribute._attruid = _.uniqueId('attruid_');
              },
            );
            resultArray = _.concat(resultArray, fixedAttributes);
          }
        }
      }
      mainBlock.attributes = resultArray;
    }
    if (mainBlock.id === 205) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * @param {15808} - Код вложенной не зарегистрированной упаковки
       * @param {15809} - Количество вложенных не зарегистрированных упаковок
       * @param {15812} - Код ТНВЭД не зарегистрированного товара
       * @param {15810} - Торговое наименование не зарегистрированного товара
       * @param {15811} - Нетто не зарегистрированного товара
       */
      [15808, 15809, 15812, 15810, 15811].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });
      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
            groupedAttr[4][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider' });
        }
      }

      mainBlock.attributes = resultArray;
    }

    if (mainBlock.id === 206) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * добавили первым элементов "Количество вложенных упаковок", т.к. оно должно
       * выводиться только один раз
       */
      const countWithoutCode = mainBlock.attributes.find(
        (el) => el.id === 15813,
      );
      if (countWithoutCode) {
        resultArray.push(countWithoutCode);
      }

      /**
       * @param {15814} - Торговое наименование товара
       * @param {15815} - Нетто
       * @param {15816} - ТНВЭД
       * @param {15817} - Описание вложения
       */
      [15814, 15815, 15816, 15817].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider' });
        }
      }

      mainBlock.attributes = resultArray;
    }
  });

  return sortedAttributes;
}

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

class ProductEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorFields: {},
      data: {
        attrGroup: _attrGroupAdapter.set(
          attributesSorter(
            this.props.productData.attrGroup,
            this.props.productData.nestedGoodAttributes,
          ),
        ),
        identifier: _.defaultTo(this.props.productData.identifier, []),
        images: _.defaultTo(this.props.productData.images, []),
        draftId: _.defaultTo(this.props.productData.draftId, ''),
        goodId: _.defaultTo(this.props.productData.goodId, null),
        categories: _.defaultTo(this.props.productData.categories, []),
        name: _.defaultTo(this.props.productData.name, ''),
        status: _.defaultTo(this.props.productData.status, ''),
        entityType: _.defaultTo(this.props.productData.entityType, ''),
        gtin: _.defaultTo(this.props.productData.gtin, ''),
        draftUpdated: _.defaultTo(this.props.productData.draftUpdated, null),
      },
      cache: {
        attrGroup: this.props.productData.attrGroup,
      },
      hasErrorValidate: false,
      errors: {
        identifier: [],
      },
      censorshipErrors: {},
      modal: {
        confirm: ConfirmStateInit,
        isOpenSelectCertModal: false,
        isOpenMassAction: false,
      },
      certificate: {},
      floatingButtonShow: true,
      preview: this.props.preview,
      disabledModerateButton: false,
      isTobacoModalOpen: false,
    };

    this.updateProductFullNameHiddenValue(this.state.data);

    this.onScrollThrottle = _.throttle(this.onScroll, config.throttleScroll);
    this.handleErrorsThrottle = _.throttle(
      this.handleErrors,
      config.throttleHandleErrors,
    );
  }

  updateProductFullNameHiddenValue = (data) => {
    const productFullName = data.attrGroup
      .find(({ id }) => id == 192)
      ?.attributes.find(({ id }) => id == 13954);
    if (productFullName)
      productFullName.hidden = !data.categories.some(
        ({ classifier }) => classifier == 'tnved',
      );
  };

  setError = (key, value, all = false) => {
    const { setErrorValidate } = this;
    if (all && !Array.isArray(key)) {
      const errors = Object.keys(this.state.errorFields).reduce(
        (obj, key) => ({
          ...obj,
          [key]: null,
        }),
        {},
      );
      this.setState({ errorFields: errors });
      return;
    } else if (Array.isArray(key)) {
      let newErrors = { ...this.state.errorFields };
      key.forEach((id) => {
        newErrors[id] = value;
      });
      this.setState({ errorFields: newErrors });
    } else {
      this.setState({
        errorFields: { ...this.state.errorFields, [key]: value },
      });
      setErrorValidate(true);
    }

    if (value === null) {
      setErrorValidate(false);
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollThrottle);
  
    if (_.isEmpty(this.props.photoTypes)) {
      this.props.loadPhtotoTypes();
    }
  
    const { productData } = this.props;
  
    if (productData.status === 'archive') {
      this.openTobacoModal(); 
    }
  }
  

  componentWillUnmount() {
    this.unmount = true;
    window.removeEventListener('scroll', this.onScrollThrottle);
  }

  setErrorValidate = (status) => {
    this.setState({ hasErrorValidate: status });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (nextProps.preview !== prevState.preview) {
      state = { ...state, preview: nextProps.preview };
    }
    if (nextProps.productData.images !== prevState.data.images) {
      const propsArray = _.cloneDeep(nextProps.productData.images);
      const stateArray = _.cloneDeep(prevState.data.images);

      _.forEach(propsArray, (item, index) => {
        if (
          stateArray[index] &&
          !!stateArray[index].url &&
          typeof stateArray[index].url !== 'object'
        ) {
          stateArray[index].url = item.url;
        }
      });

      state = { ...state, data: { ...prevState.data, images: stateArray } };
    }
    if (
      !_.isEqual(nextProps.productData.attrGroup, prevState.cache.attrGroup)
    ) {
      state = {
        ...state,
        cache: {
          ...prevState.cache,
          attrGroup: nextProps.productData.attrGroup,
        },
        data: {
          ...prevState.data,
          attrGroup: _attrGroupAdapter.set(
            attributesSorter(
              nextProps.productData.attrGroup,
              nextProps.productData.nestedGoodAttributes,
            ),
          ),
        },
      };
    }
    return !_.isEmpty(state) ? { ...state } : null;
  }

  componentDidUpdate() {
    const { t } = this.props;
    if (!this.isShowWarning && !this.state.preview) {
      this.isShowWarning = true;
      if (this.props.productData.status === 'draft') {
        const errors = _.get(this.props, 'productData.errors', []);
        if (!_.isEmpty(errors)) {
          setTimeout(() => {
            _.map(errors, (i) => {
              return this.props.throwNotification(
                t(i === 'action.canceled' ? 'Публикация товара отменена' : i),
                'error',
              );
            });
          }, 500);
        }
      }
    }
  }

  onScroll = () => {
    const pageY = window.pageYOffset || document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const offsetHeight = document.documentElement.offsetHeight;
    if (offsetHeight - clientHeight - 300 > pageY) {
      if (!this.state.floatingButtonShow) {
        this.setState({ floatingButtonShow: true });
      }
    } else {
      if (this.state.floatingButtonShow) {
        this.setState({ floatingButtonShow: false });
      }
    }
  };

  confirmModalOpen = (confirm) => {
    this.setState((prevState) => ({ modal: { ...prevState.modal, confirm } }));
  };

  confirmModalClose = () => {
    this.setState((prevState) => ({
      modal: { ...prevState.modal, confirm: ConfirmStateInit },
    }));
  };

  goToDraft = (draftId) => {
    this.setState({ preview: false }, () => {
      this.props.goToPage(`${config.urls.product}/${draftId}`);
    });
  };

  openDraft = () => {
    const { t } = this.props;
    if (this.props.productData.draftId) {
      this.goToDraft(this.props.productData.draftId);
      return;
    }

    const objModal = {
      isOpen: true,
      funcDismiss: this.confirmModalClose,
      funcCancel: this.confirmModalClose,
      acceptText: t('Подтвердить'),
      dismissText: t('Закрыть'),
    };

    if (
      _.includes(
        ['published', 'published_notsigned'],
        this.props.productData.status,
      )
    ) {
      this.confirmModalOpen({
        ...objModal,
        message: t(
          'Вы действительно хотите создать черновик для внесения изменений в опубликованную версию карточки?',
        ),
        funcAccept: this.onDraftPublished,
      });
    }
  };

  openTobacoModal() {
    const { t, productData } = this.props;    
    const objModal = {
      isOpen: true,
      funcDismiss: this.confirmModalClose,
      funcCancel: this.confirmModalClose, 
      dismissText: t('Закрыть'),
    };
  
    if (productData.status === 'archive') {
      this.confirmModalOpen({
        ...objModal,
        message: t(
          'В связи с введением запрета на ввоз, производство, продажу и распространение не курительных табачных изделий, электронных систем потребления (вейпов), ароматизаторов и жидкостей для них в соответствии с пунктом 9 статьи 110 Кодекса Республики Казахстан "О здоровье народа и системе здравоохранения" в  Национальном каталоге товаров  карточки товаров с вышеуказанными описаниями заблокированы и все операции с кодами маркировки запрещены',
        ),
      });
    }
  }
  
  
  onDraftPublished = () => {
    this.confirmModalClose();
    this.props.onDraftOpen(
      [this.props.productData.goodId],
      'published',
      this.props.productData.isExemplar,
      (error, data) => {
        if (error) {
          if (_.isArray(error)) {
            _.map(error, (i) => this.props.throwNotification(i));
          }
        } else {
          this.goToDraft(data[0].draft.id);
        }
      },
    );
  };

  onEdit = () => {
    this.goToDraft(this.props.productData.draftId);
  };
  onModerationRecall = () => {
    const { t, moderationRecall } = this.props;
    const pd = this.props.productData;
    const data = {
      goodId: [pd.goodId],
    };
    moderationRecall(data, (errors) => {
      // this.setState(state);
      if (_.isNil(errors)) {
        this.onCloseButton();
      }
    });
  };
  scrollTo = (id) => {
    scroller.scrollTo(id, {
      duration: 500,
      delay: 100,
      smooth: true,
      offset: -100,
    });
  };

  onModerateButton = () => {
    const { t, productData } = this.props;
    const { data } = this.state;
    let item = {};
    const isErrors = isButtonDisabled(this.state.errors, item);

    const hasCategories = data && data.categories;
    const hasIdentifier = data?.identifier;
    const isWghRequired = (wgh) =>
      _.includes(productData.identifier[0].required, wgh);
    const isTnvedEmpty =
      hasCategories &&
      _.isEmpty(_.filter(data.categories, (obj) => obj.classifier === 'tnved'));
    const isGpcEmpty =
      hasCategories &&
      _.isEmpty(_.filter(data.categories, (obj) => obj.classifier === 'gpc'));
    const isImagesEmpty =
      data && data.images && data.images[0] && data.images[0].url === '';
    const isWidthEmpty =
      hasIdentifier && hasIdentifier[0] && _.isNull(hasIdentifier[0].width);
    const isHeightEmpty =
      hasIdentifier && hasIdentifier[0] && _.isNull(hasIdentifier[0].height);
    const isLengthEmpty =
      hasIdentifier && hasIdentifier[0] && _.isNull(hasIdentifier[0].length);
    const isConsumerGroupPackage =
      productData.packingPurpose === 'consumer' ||
      productData.packingPurpose === 'group';

    if (isConsumerGroupPackage && isTnvedEmpty) {
      this.props.throwNotification(t('Не заполнен ТНВЭД'));
      const id = `tnvedField`;
      if (scroller.get(id)) {
        this.scrollTo(id);
      }
      return;
    }
    if (isConsumerGroupPackage && isGpcEmpty) {
      this.props.throwNotification(t('Не заполнен GPC'));
      const id = `gpcField`;
      if (scroller.get(id)) {
        this.scrollTo(id);
      }
      return;
    }
    if (
      (isWidthEmpty && isWghRequired('width')) ||
      (isHeightEmpty && isWghRequired('height')) ||
      (isLengthEmpty && isWghRequired('length'))
    ) {
      this.props.throwNotification(t('Не заполнены размеры упаковки'));
      const id = 'width_0-label';
      if (scroller.get(id)) {
        this.scrollTo(id);
      }
      return;
    }
    if (isImagesEmpty && productData.isImageRequired) {
      this.props.throwNotification(t('Не загружен фотоконтент'));
      scroll.scrollToBottom();
      return;
    }
    if (isErrors) {
      this.props.throwNotification(t('Заполните обязательные поля'));
      const id = `id_${item.id}`;
      if (scroller.get(id)) {
        this.scrollTo(id);
      }
      return;
    }
    if (
      !(
        (isWidthEmpty && isWghRequired('width')) ||
        (isHeightEmpty && isWghRequired('height')) ||
        (isLengthEmpty && isWghRequired('length'))
      ) &&
      !(isImagesEmpty && productData.isImageRequired) &&
      !isErrors
    ) {
      this.setState({
        disabledModerateButton: true,
        modal: { ...this.state.modal, isOpenMassAction: true },
      });
      const data = _.cloneDeep(this.state.data);
      data.images = _.filter(data.images, (image) => !!image.url);
      data.attrGroup = _attrGroupAdapter.get(data.attrGroup);

      /**
       * Перед сохранением нужно вырезать аттрибуты из 200 группы, которые пришли из вне:
       * @param {2478} - Торговое наименование товара,
       * @param {13933} - Нетто,
       * @param {15448} - ТНВЭД
       *
       * И еще вырезать разделители('divider'), т.к. по факту это пустой объект, который не нужен
       * бэкенду.
       */
      _.forEach(data.attrGroup, (group) => {
        if (group.id === 200) {
          group.attributes = _.filter(group.attributes, (attribute) => {
            return (
              !_.includes([2478, 13933, 15448], attribute.id) &&
              attribute.name !== 'divider'
            );
          });
        }

        group.attributes = _.filter(group.attributes, (attribute) => {
          return attribute.name !== 'divider';
        });

        /**
         * EACNC-48
         * Теперь надо убедится что бы на бэк не ушло ни одно поле в attrGroup, у которого value: null.
         * говорят бэк удаляет такие поля и у нас нарушается целостность. поэтому заменяем null на ''.
         *
         * После тестирования оказалось, что null на '' надо менять только у 205 и 206 групп.
         */
        if (group.id === 205 || group.id === 206) {
          group.attributes = _.map(group.attributes, (attribute) => {
            if (attribute.value === null) {
              attribute.value = '';
            }
            return attribute;
          });
        }

        _.forEach(group.attributes, (attribute) => {
          if (attribute.valueType && !attribute.value) {
            attribute.valueType = null;
          }
        });
      });

      const filteredIndentifier = _.forEach(data.identifier, (obj) => {
        delete obj.capacity;
      });
      data.identifier = filteredIndentifier;
      this.props.saveProduct(data, (error) => {
        const state = {
          disabledModerateButton: false,
          modal: { ...this.state.modal, isOpenMassAction: false },
        };
        if (error) {
          this.setState(state);
        } else {
          this.props.moderateProduct(this.state.data.draftId, (error) => {
            this.setState(state);
            if (_.isNil(error)) {
              this.props.throwNotification(
                `${t(
                  'Карточка товара успешно отправлена в очередь на модерацию',
                )}. ${t('Ожидайте результатов проверки')}`,
                'success',
              );
              this.onCloseButton();
            }
          });
        }
      });
    }
  };

  onCleanButton = () => {
    const data = _.cloneDeep(this.state.data);

    _.forEach(data.attrGroup, (group) => {
      group.attributes = _.map(group.attributes, (attribute) => {
        if (attribute.fieldType === 'date' || attribute.isPresetOnly) {
          attribute.value = null;
          return attribute;
        }
        return attribute;
      });

      _.forEach(group.attributes, (attribute) => {
        if (attribute.valueType && !attribute.value) {
          attribute.valueType = null;
        }
      });
    });

    this.setState({ data }, () => {
      this.props.throwNotification(i18n.t('Поля успешно очищены'), 'info');
    });
  };

  onSaveButton = () => {
    const { throwNotification } = this.props;
    if (this.state.hasErrorValidate) {
      throwNotification('Не все поля заполнены корректно!');
      return;
    }
    const data = _.cloneDeep(this.state.data);
    data.images = _.filter(data.images, (image) => !!image.url);
    let hasError = false;
    let isError = false;
    let now = new Date();
    let curr_date = now.getDate();
    let curr_month = now.getMonth() + 1;
    let curr_year = now.getFullYear();
    let today = curr_date + '.' + curr_month + '.' + curr_year;
    const attributeDate = [];
    /**
     * Перед сохранением нужно вырезать аттрибуты из 200 группы, которые пришли из вне:
     * @param {2478} - Торговое наименование товара,
     * @param {13933} - Нетто,
     * @param {15448} - ТНВЭД
     *
     * И еще вырезать разделители('divider'), т.к. по факту это пустой объект, который не нужен
     * бэкенду.
     */
    _.forEach(data.attrGroup, (group) => {
      /**
       * Здесь заложена проверка на валидность значений по регулярным выражениям
       */
      _.forEach(group.attributes, (attribute) => {
        if (hasError) {
          return;
        }

        if (attribute.value) {
          if (attribute.fieldType === 'date') {
            const searchId = `${attribute.id}_${attribute._attruid}_${attribute.sortOrder}`;
            attributeDate.push(attribute.value);
            if (attribute.compareWith) {
              const art = group.attributes.find((item) => {
                return (
                  item.id === attribute.compareWith.attrId &&
                  item.fieldType === 'date' &&
                  item.multiplicityGroupId === attribute.multiplicityGroupId &&
                  (Boolean(attribute.newBlockId)
                    ? item.newBlockId === attribute.newBlockId
                    : Boolean(item.newBlockId) === false)
                );
              });
              if (art?.value) {
                if (attribute.compareWith.operator === 'lt') {
                  if (
                    Date.parse(
                      attribute.value.split('.').reverse().join('.'),
                    ) >= Date.parse(art.value.split('.').reverse().join('.'))
                  ) {
                    this.setError(
                      searchId,
                      `Указанное значение должно быть меньше значения поля ${art.name}.`,
                    );
                    isError = true;

                    return;
                  } else {
                    this.setError(searchId, null);
                    this.setError(
                      `${art.id}_${art._attruid}_${art.sortOrder}`,
                      null,
                    );

                    return;
                  }
                } else if (attribute.compareWith.operator === 'gt') {
                  if (
                    Date.parse(
                      attribute.value.split('.').reverse().join('.'),
                    ) <= Date.parse(art.value.split('.').reverse().join('.'))
                  ) {
                    this.setError(
                      searchId,
                      `Указанное значение должно быть больше значения поля ${art.name}.`,
                    );
                    isError = true;

                    return;
                  } else {
                    this.setError(searchId, null);
                    this.setError(
                      `${art.id}_${art._attruid}_${art.sortOrder}`,
                      null,
                    );

                    return;
                  }
                }
              }
            } else {
              const art = group.attributes.find((item) => {
                return (
                  item.fieldType === 'date' &&
                  item.compareWith &&
                  item.multiplicityGroupId === attribute.multiplicityGroupId &&
                  item.compareWith.attrId === attribute.id &&
                  (Boolean(attribute.newBlockId)
                    ? item.newBlockId === attribute.newBlockId
                    : Boolean(item.newBlockId) === false)
                );
              });

              if (art?.value) {
                if (art.compareWith.operator === 'lt') {
                  if (
                    Date.parse(
                      attribute.value.split('.').reverse().join('.'),
                    ) <= Date.parse(art.value.split('.').reverse().join('.'))
                  ) {
                    this.setError(
                      searchId,
                      `Указанное значение должно быть больше значения поля ${art.name}.`,
                    );
                    isError = true;

                    return;
                  } else {
                    this.setError(searchId, null);
                    this.setError(
                      `${art.id}_${art._attruid}_${art.sortOrder}`,
                      null,
                    );

                    return;
                  }
                } else if (art.compareWith.operator === 'gt') {
                  if (
                    Date.parse(
                      attribute.value.split('.').reverse().join('.'),
                    ) >= Date.parse(art.value.split('.').reverse().join('.'))
                  ) {
                    this.setError(
                      searchId,
                      `Указанное значение должно быть меньше значения поля ${art.name}.`,
                    );
                    isError = true;

                    return;
                  } else {
                    this.setError(searchId, null);
                    this.setError(
                      `${art.id}_${art._attruid}_${art.sortOrder}`,
                      null,
                    );

                    return;
                  }
                }
              }
            }
          }

          const preset = attribute.preset.find(
            (item) => item.id === attribute.value,
          );

          if (!preset) {
            const valuesTypeRegex = attribute.valuesTypeRegex.find(
              (item) => item.id === attribute.valueType,
            );

            if (valuesTypeRegex) {
              let reg = valuesTypeRegex.text.split('');
              reg[0] = '';
              reg[reg.length - 1] = '';
              const regExp = new RegExp(reg.join(''));
              if (
                // eslint-disable-next-line no-useless-escape
                !regExp.test(attribute.value)
              ) {
                hasError = true;
                throwNotification(
                  `Поле ${attribute.name} не правильно заполнено!`,
                );
              } else {
                hasError = false;
              }

              return;
            }

            if (attribute.regExpAllowed) {
              let reg = attribute.regExpAllowed.split('');
              reg[0] = '';
              reg[reg.length - 1] = '';
              const regExp = new RegExp(reg.join(''));
              const regExpAllowed = regExp;
              const checkRegExpAllowed = regExpAllowed.test(attribute.value);

              if (attribute.regExpAllowedException) {
                let reg = attribute.regExpAllowedException.split('');
                reg[0] = '';
                reg[reg.length - 1] = '';
                const regExp = new RegExp(reg.join(''));
                const regExpAllowedException = regExp;
                const checkRegExpAllowedException = regExpAllowedException.test(
                  attribute.value,
                );

                if (
                  (checkRegExpAllowed && !checkRegExpAllowedException) ||
                  !checkRegExpAllowed
                ) {
                  hasError = true;
                  throwNotification(
                    `Поле ${attribute.name} не правильно заполнено!`,
                  );
                } else {
                  hasError = false;
                }
              } else if (!checkRegExpAllowed) {
                throwNotification(
                  `Поле ${attribute.name} не правильно заполнено!`,
                );
                hasError = true;
              } else {
                hasError = false;
              }
            } else {
              hasError = false;
            }
          } else {
            hasError = false;
          }
        }
      });

      if (group.id === 200) {
        group.attributes = _.filter(group.attributes, (attribute) => {
          return (
            !_.includes([2478, 13933, 15448], attribute.id) &&
            attribute.name !== 'divider'
          );
        });
      }

      group.attributes = _.filter(group.attributes, (attribute) => {
        return attribute.name !== 'divider';
      });

      /**
       * EACNC-48
       * Теперь надо убедится что бы на бэк не ушло ни одно поле в attrGroup, у которого value: null.
       * говорят бэк удаляет такие поля и у нас нарушается целостность. поэтому заменяем null на ''.
       *
       * После тестирования оказалось, что null на '' надо менять только у 205 и 206 групп.
       */

      if (group.id === 205 || group.id === 206) {
        group.attributes = _.map(group.attributes, (attribute) => {
          if (attribute.value === null) {
            attribute.value = '';
          }
          return attribute;
        });
      }

      _.forEach(group.attributes, (attribute) => {
        if (attribute.valueType && !attribute.value) {
          attribute.valueType = null;
        }
      });
    });
    //   /*Проверка с тек датой */
    // if (this.props.productData.packingPurpose === 'consumer') {
    //   isError = !_.find(attributeDate, (date) => {
    //     return Date.parse(date.split('.').reverse().join('.')) >= Date.parse(today.split('.').reverse().join('.'))
    //   });
    // }

    if (isError) {
      throwNotification(`Не все даты заполнены верно!`);
      return;
    }

    if (hasError) {
      return;
    }

    const filteredIndentifier = _.forEach(data.identifier, (obj) => {
      delete obj.capacity;
    });
    data.identifier = filteredIndentifier;
    this.props.saveProduct(data);
  };

  onCloseButton = () => {
    this.props.setFiltersGrid();
    if (this.props.isCatalogCard) {
      this.props.goToPage(config.urls.catalog);
    } else this.props.goToPage(config.urls.products);
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    if (selection === 'categories') {
      let currentData = _.cloneDeep(this.state.data);
      if (_.some(currentData.categories, ['classifier', data.classifier])) {
        const filteredCategories = currentData.categories.map((item, index) => {
          return item.classifier === data.classifier ? data : item;
        });

        currentData.categories = filteredCategories;
      } else {
        currentData.categories.push(data);
      }

      const k3Id = currentData.categories?.find(
          ({ classifier }) => classifier == 'k3',
        ).categoryId,
        tnvedId = currentData.categories?.find(
          ({ classifier }) => classifier == 'tnved',
        ).categoryId;
      if (k3Id && tnvedId) {
        client()
          .instance.post('draft/mark-group', { k3Id, tnvedId })
          .then(({ data: { status, message } }) => {
            this.props.productData.markGroupId =
              status == 200 ? message.mark_group_id : null;
          });
      }

      this.updateProductFullNameHiddenValue(currentData);
      this.setState({ data: currentData });

      return;
    }

    const { markGroupId } = this.props.productData;
    if (selection === 'attrGroup' && markGroupId == 7) {
      try {
        this.buildProductFullName(data);
      } catch (e) {
        console.log('buildProductFullName', e);
      }
    }

    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  buildProductFullName(data) {
    const PRODUCT_IDENTIFICATION_ATTR_GROUP = 192;
    const productIdentificationAttrs = data.find(
      ({ id }) => id === PRODUCT_IDENTIFICATION_ATTR_GROUP,
    )?.attributes;

    if (productIdentificationAttrs) {
      const PRODUCT_FULL_NAME = 13954;
      const productFullName = productIdentificationAttrs.find(
        ({ id }) => id === PRODUCT_FULL_NAME,
      );
      if (productFullName) {
        const PRODUCT_TRADE_NAME = 2478,
          DOSAGE = 4063,
          CONCENTRATION = 15796,
          DOSAGE_FORM = 4390,
          RELEASE_FORM = 14331;
        const fullName = [];
        [
          PRODUCT_TRADE_NAME,
          DOSAGE,
          CONCENTRATION,
          DOSAGE_FORM,
          RELEASE_FORM,
        ].forEach((id) => {
          const attr = productIdentificationAttrs.find(
            (attr) => attr.id === id,
          );
          if (!attr) return;
          let { value, valueType = '' } = attr;
          if (!value || !value.trim()) return;
          if (id === CONCENTRATION && value === 'Неприменимо') return;
          if (id === DOSAGE) {
            if (value === 'Неприменимо') return;
            if (valueType) value += ` (${valueType})`;
          }
          fullName.push(value);
        });
        productFullName.value = fullName.join(', ');
      }
    }
  }

  handleErrorsArray = (errors) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, identifier: errors },
    }));
  };

  handleErrors = (component, errors, index) => {
    let identifier;
    if (component === 'identifier') {
      identifier = _.cloneDeep(this.state.errors.identifier);
      _.forEach(_.keys(errors), (key) => {
        _.set(identifier, `[${index}].${key}`, errors[key]);
      });
    }
    const componentErrors = component !== 'identifier' ? errors : identifier;
    if (!_.isEqual(this.state.errors[component], componentErrors)) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, [component]: componentErrors },
      }));
    }
  };

  onValidateCensorship = (uidField, errorText) => {
    if (this.unmount) return;
    this.setState((prevState) => {
      const booleanValue = !!errorText;
      const censorshipErrors = booleanValue
        ? { ...prevState.censorshipErrors, [uidField]: booleanValue }
        : _.omit(prevState.censorshipErrors, [uidField]);
      return { censorshipErrors };
    });
  };

  handleGtinStatus = (status, index) => {
    let identifier = _.cloneDeep(this.state.errors.identifier);
    _.set(identifier, `[${index}].status`, status);
    this.setState((prevState) => ({
      errors: { ...prevState.errors, identifier: identifier },
    }));
  };

  onSelectCertClick = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isOpenSelectCertModal: !prevState.modal.isOpenSelectCertModal,
      },
    }));
  };

  onSelectCertificate = (value) => {
    this.setState({ certificate: value });
  };

  onPublish = (certificate, params = {}) => {
    const { t } = this.props;
    let cert = null;
    let setting;
    if (_.has(certificate, 'id')) {
      cert = certificate;
      this.onSelectCertClick();
    }
    this.setState(
      (prevState) => ({
        modal: { ...prevState.modal, isOpenMassAction: true },
      }),
      () => {
        if (cert) {
          setting = {
            ids: [this.props.productData.goodId],
            isSigned: !!cert,
            certificate: cert,
            params: params,
            certId: cert.id,
          };
        } else {
          setting = {
            ids: [this.props.productData.goodId],
            isSigned: !!cert,
            certificate: cert,
            params: params,
          };
        }
        this.props.onPublishProduct(setting, (error) => {
          this.setState(
            (prevState) => ({
              modal: { ...prevState.modal, isOpenMassAction: false },
            }),
            () => {
              if (error) {
                if (_.isArray(error)) {
                  _.map(error, (i) => this.props.throwNotification(i));
                }
              } else {
                this.props.throwNotification(
                  t(
                    'Карточка товара успешно отправлена в очередь на подпись и публикацию.',
                    { gtin: this.props.productData.gtin },
                  ),
                  'success',
                );
                this.onCloseButton();
              }
            },
          );
        });
      },
    );
  };

  renderCertificateSelectModal = () => {
    if (!this.state.modal.isOpenSelectCertModal) {
      return null;
    }
    const { t } = this.props;

    return (
      <CertificateSelectModal
        acceptBtnText={t('Опубликовать')}
        onSelectCert={(cert) => this.onSelectCertificate(cert)}
        onSubmit={(certificate, params) => this.onPublish(certificate, params)}
        onGetAllCerts={this.props.onLoadCertificates}
        cert={this.state.certificate}
        certificates={this.props.certificates}
        onCancel={this.onSelectCertClick}
        questionAboutPublishing={true}
      />
    );
  };

  renderConfirmDialog = () => {
    if (!this.state.modal.confirm.isOpen) {
      return null;
    }

    return (
      <ConfirmDialog
        onAccept={this.state.modal.confirm.funcAccept}
        onDismiss={this.state.modal.confirm.funcDismiss}
        onCancel={this.state.modal.confirm.funcCancel}
        title={this.state.modal.confirm.title}
        message={this.state.modal.confirm.message}
        acceptBtnText={this.state.modal.confirm.acceptText}
        dismissBtnText={this.state.modal.confirm.dismissText}
      />
    );
  };

  renderModalLoader = () => {
    if (!this.state.modal.isOpenMassAction) {
      return null;
    }
    const { t } = this.props;

    return (
      <ModalLoader
        text={`${_.toUpper(
          t('Пожалуйста, подождите, идет обработка карточки'),
        )} ...`}
      />
    );
  };

  renderProductStatus = () => {
    const { t } = this.props;
    const pd = this.props.productData;    
    const status = findStatusObj(pd.status);
    const statusTitle = _.trim(_.replace(status.title, /\([^)]*\)/g, ''));
    const isValid = pd.isValid;
    const published = pd.status === 'published';
    if (statusTitle === 'Ожидает подписания' && pd.isRunningPublication === 1) {
      return (
        <ProductStatus>
          <ProductStatusLine color="orange">
            <IconStatusQueue/>
            <span>{t('В очереди')}</span>            
          </ProductStatusLine>
        </ProductStatus>
      );
    }
    if (
      status.id !== 999 &&
      pd.type !== 'transport' &&
      pd.type !== 'multiple'
    ) {
      return (
        <ProductStatus>
          <ProductStatusLine color={status.style.color}>
            {status.style.icon}
            <span style={{ paddingLeft: '13px' }}>{t(statusTitle)}</span>
          </ProductStatusLine>
          <div style={{ display: 'flex' }}>
            {isValid ? (
              <CardState>
                <FiberManualRecordIcon
                  style={{
                    color: 'green',
                    fontSize: '16px',
                    marginRight: '13px',
                  }}
                />
                <span>{t('Состояние карточки')}</span>
              </CardState>
            ) : (
              <CardState>
                <FiberManualRecordIcon
                  style={{
                    color: 'red',
                    fontSize: '16px',
                    marginRight: '13px',
                  }}
                />
                <span>{t('Состояние карточки')}</span>
              </CardState>
            )}

            {!!pd.hasPublishedGood && published && (
              <ProductStatusInfo>
                <div
                  style={{
                    marginRight: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <IconValidMin style={{ marginRight: '13px' }} />
                  <span>{t('Готовность к эмиссии КМ')}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isValid ? (
                    <IconValidMin style={{ marginRight: '13px' }} />
                  ) : (
                    <IconInvalid
                      style={{ marginRight: '13px', transform: 'scale(0.8)' }}
                    />
                  )}
                  <span>{t('Готовность к вводу в оборот')}</span>
                </div>
              </ProductStatusInfo>
            )}
          </div>
        </ProductStatus>
      );
    }

    return null;
  };

  renderNewProductTitle = () => {
    const { t } = this.props;
    const pd = this.props.productData;

    if (pd.type !== 'transport' || pd.type !== 'multiple') {
      return (
        <NewProductTitle>
          <NewProductGtin>
            <span style={{ textTransform: 'uppercase', marginRight: '10px' }}>
              {t('Код упаковки')}{' '}
            </span>
            <span style={{ fontWeight: '700' }}>
              {gtinDisplay(pd.gtin).padStart(14, '0')}
            </span>
          </NewProductGtin>
        </NewProductTitle>
      );
    }

    return (
      <NewProductTitle>
        <NewProductGtin>
          <span>
            {t('Черновик')} {t('Транспортная упаковка')}
          </span>
          {gtinDisplay(pd.good_gtin)}
        </NewProductGtin>
      </NewProductTitle>
    );
  };

  renderTitleClarification = () => {
    const { t } = this.props;
    const pd = this.props.productData;

    if (pd.type !== 'transport' || pd.type !== 'multiple') {
      return (
        <TitleClarification>
          <span>
            <div
              dangerouslySetInnerHTML={{
                __html: t('Красным отмечены обязательные для заполнения поля', {
                  style: 'style=color:red;',
                }),
              }}
            />
          </span>
        </TitleClarification>
      );
    }
  };

  renderMuiThemeProvider = () => {
    const pd = this.props.productData;
    const wasPublished = pd.hasPublishedGood;

    if (pd.packingPurpose !== 'transport') {
      return (
        <MuiThemeProvider theme={defaultTheme}>
          <ConsumerPackage
            setError={this.setError}
            errorFields={this.state.errorFields}
            setErrorValidate={this.setErrorValidate}
            updateData={this.updateData}
            handleErrors={this.handleErrorsThrottle}
            onValidate={this.onValidateCensorship}
            attrGroup={this.state.data.attrGroup}
            preview={this.state.preview}
            wasPublished={wasPublished}
            status={this.state.status}
          />
        </MuiThemeProvider>
      );
    }

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <MultipleAndTransportPackageInputs data={pd} />
      </MuiThemeProvider>
    );
  };

  renderProductEditButtons = () => {
    const { t } = this.props;
    const pd = this.props.productData;
    const bModerOrSave = _.includes(['errors', 'draft'], pd.status);
    const isSaveDisabled = isSaveButtonDisabled(
      this.state.data,
      pd,
      this.state.errors,
    );
    const isCensorshipErrors = _.some(_.values(this.state.censorshipErrors));

    if (!this.state.preview && bModerOrSave) {
      return (
        <ProductEditButtons>
          <Button
            disabled={this.state.disabledModerateButton || isCensorshipErrors}
            onClick={this.onModerateButton}>
            {t('Отправить на модерацию')}
          </Button>

          <Button
            id="saveProductEditButton"
            color={'secondary'}
            disabled={
              (isSaveDisabled && pd.status !== 'errors') || isCensorshipErrors
            }
            onClick={this.onSaveButton}>
            {t('Сохранить')}
          </Button>

          {/* <Button
                        id="saveProductEditButton"
                        color={"secondary"}
                        disabled={(isSaveDisabled && pd.status !== 'errors') || isCensorshipErrors}
                        onClick={this.onCleanButton}
                    >
                        {t('Очистить')}
                    </Button> */}
        </ProductEditButtons>
      );
    }
  };

  renderButtonsList = () => {
    const { t } = this.props;
    const pd = this.props.productData;
    const bPublish = !!pd.goodId && pd.status === 'notsigned' && pd.isRunningPublication === 0;   
    const bRecall = pd.status === 'moderation';
    const bEdit = _.includes(['errors', 'draft'], pd.status) && !!pd.draftId;
    const bOpenDraft =
      _.includes(['published', 'published_notsigned'], pd.status) &&
      !pd.draftId;

    if (this.state.preview) {
      return (
        <React.Fragment>
          {bOpenDraft && !pd.isRemainder && (
            <Button onClick={this.openDraft}>{t('Открыть черновик')}</Button>
          )}

          {bEdit && (
            <Button color={'primary'} onClick={this.onEdit}>
              {t('Редактировать')}
            </Button>
          )}

          {bRecall && (
            <Button color={'primary'} onClick={this.onModerationRecall}>
              {t('Отозвать из модерации')}
            </Button>
          )}

          {bPublish && (
            <React.Fragment>
              <Button
                onClick={this.onSelectCertClick}
                disabled={!this.props.userInfo.isELK}>
                {t('Подписать и опубликовать')}
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  renderFloatingButtonsList = () => {
    const { t } = this.props;
    const pd = this.props.productData;
    const goodId = pd.goodId;
    const bPublish = !!pd.goodId && pd.status === 'notsigned' && pd.isRunningPublication === 0;
    const bRecall = pd.status === 'moderation';
    const bOpenDraft =
      _.includes(['published', 'published_notsigned'], pd.status) &&
      !pd.draftId;
    const bEdit = _.includes(['errors', 'draft'], pd.status) && !!pd.draftId;
    const bModerOrSave = _.includes(['errors', 'draft'], pd.status);
    const isSaveDisabled = isSaveButtonDisabled(
      this.state.data,
      pd,
      this.state.errors,
    );
    const isCensorshipErrors = _.some(_.values(this.state.censorshipErrors));

    if (this.state.floatingButtonShow) {
      return (
        <FloatingButtonView>
          <StickyBtns>
            {!this.state.preview && bModerOrSave && (
              <React.Fragment>
                <FloatingButton
                  disabled={
                    this.state.disabledModerateButton || isCensorshipErrors
                  }
                  text={t('Отправить на модерацию')}
                  color={'primary'}
                  icon={<IconModeration style={{ marginRight: '5px' }} />}
                  onClick={this.onModerateButton}
                />

                <FloatingButton
                  disabled={
                    (isSaveDisabled && pd.status !== 'errors') ||
                    isCensorshipErrors
                  }
                  text={t('Сохранить')}
                  color={'secondary'}
                  icon={<IconStatusDraft style={{ marginRight: '5px' }} />}
                  onClick={this.onSaveButton}
                />
              </React.Fragment>
            )}

            {this.state.preview && (
              <React.Fragment>
                {bOpenDraft && !pd.isRemainder && (
                  <FloatingButton
                    text={t('Открыть черновик')}
                    color={'primary'}
                    icon={<IconProductEdit style={{ marginRight: '5px' }} />}
                    onClick={this.openDraft}
                  />
                )}

                {bEdit && (
                  <FloatingButton
                    text={t('Редактировать')}
                    color={'primary'}
                    icon={<IconProductEdit style={{ marginRight: '5px' }} />}
                    onClick={this.onEdit}
                  />
                )}

                {bPublish && (
                  <React.Fragment>
                    <FloatingButton
                      text={t('Подписать и опубликовать')}
                      color={'primary'}
                      icon={<IconPublish style={{ marginRight: '5px' }} />}
                      onClick={this.onSelectCertClick}
                      disabled={!this.props.userInfo.isELK}
                    />
                    {bRecall && (
                      <Button
                        color={'primary'}
                        onClick={this.onModerationRecall}>
                        {t('Отозвать из модерации')}
                      </Button>
                    )}
                    {/* {activePubNotSigned && // TODO Если не нужно удалить
                                            <FloatingButton
                                                text={t('Опубликовать без подписи')}
                                                color={"secondary"}
                                                icon={<IconPublishWithout style={{marginRight: "5px"}}/>}
                                                onClick={this.onPublish}
                                            />
                                        } */}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            <FloatingButton
              id="closeProductEditButton"
              color={
                bOpenDraft || bEdit || bPublish || bRecall
                  ? 'secondary'
                  : 'primary'
              }
              onClick={this.onCloseButton}
              text={t('Закрыть')}
              icon={<IconProductClose style={{ marginRight: '5px' }} />}
            />
          </StickyBtns>
        </FloatingButtonView>
      );
    }

    return null;
  };

  render() {
    const { t } = this.props;
    const pd = this.props.productData;
    const bPublish = !!pd.goodId && pd.status === 'notsigned' && pd.isRunningPublication === 0;
    const bRecall = pd.status === 'moderation';
    const bOpenDraft =
      _.includes(['published', 'published_notsigned'], pd.status) &&
      !pd.draftId;
    const bEdit = _.includes(['errors', 'draft'], pd.status) && !!pd.draftId;
    const isExemplar = _.get(pd, 'isExemplar', false);
    const isCatalogCard = this.props.match.url.includes('/catalog/');
    
    return (
      <ProductMainContainer>
        <StatusBar>
          {this.renderNewProductTitle()}
          {this.renderProductStatus()}
        </StatusBar>
        {this.renderCertificateSelectModal()}
        {this.renderConfirmDialog()}
        {this.renderModalLoader()}
        {/* {this.renderProductStatus()} */}

        <ProductCont>
          {this.renderTitleClarification()}
          {/* {this.renderNewProductTitle()} */}
          {this.renderMuiThemeProvider()}

          <PackagingInformation
            updateData={this.updateData}
            handleErrors={this.handleErrorsThrottle}
            onValidate={this.onValidateCensorship}
            handleErrorsArray={this.handleErrorsArray}
            handleGtinStatus={this.handleGtinStatus}
            preview={this.state.preview}
            isExemplar={isExemplar}
            isCatalogCard={isCatalogCard} // флаг, что карточка товара открыта из Каталога, служит для блокировки инпутов
          />

          <MediaContent
            packaging={this.state.data.identifier}
            updateData={this.updateData}
            handleErrors={this.handleErrorsThrottle}
            preview={this.state.preview}
            type={pd.packingPurpose}
            status={this.state.status}
            isCatalogCard={isCatalogCard} // флаг, что карточка товара открыта из Каталога, служит для блокировки инпутов
          />

          <ProfileDivider />

          <DialogActions
            key={bPublish}
            style={{ justifyContent: 'space-evenly' }}>
            {this.renderProductEditButtons()}
            {this.renderButtonsList()}

            <ProductEditButtons>
              <Button
                id="closeProductEditButton"
                color={
                  bOpenDraft || bEdit || bPublish || bRecall
                    ? 'secondary'
                    : 'primary'
                }
                onClick={this.onCloseButton}>
                {t('Закрыть')}
              </Button>
            </ProductEditButtons>
          </DialogActions>
        </ProductCont>
          
        {this.renderFloatingButtonsList()}
      </ProductMainContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  certificates: state.profileReducer.certificates,
  user: state.authentificationReducer.user,
  userInfo: state.profileReducer.info.user,
  photoTypes: state.productReducer.photoTypesDictionary,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type = 'error') =>
        throwNotification(message, type),
      saveProduct: (data, cbk) => saveProduct(data, cbk),
      moderateProduct: (draftId, cbk) => moderateProduct(draftId, cbk),
      goToPage: (page) => push(page),
      setFiltersGrid: () => setFiltersGrid(),
      onDraftOpen: (ids, status, isExemplar, cbk) =>
        openDraft(ids, status, isExemplar, cbk),
      onLoadCertificates: () => loadCertificates(),
      onPublishProduct: (setting, cbk) => publishProduct(setting, cbk),
      loadPhtotoTypes: () => loadPhtotoTypes(),
      moderationRecall: (data, cbk) => moderationRecall(data, cbk),
    },
    dispatch,
  );
};

ProductEdit.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  setFiltersGrid: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  saveProduct: PropTypes.func.isRequired,
  moderateProduct: PropTypes.func.isRequired,
  onDraftOpen: PropTypes.func.isRequired,
  onPublishProduct: PropTypes.func.isRequired,
  preview: PropTypes.bool.isRequired,
  productData: PropTypes.object.isRequired,
  certificates: PropTypes.array,
  loadPhtotoTypes: PropTypes.func.isRequired,
  moderationRecall: PropTypes.func.isRequired,
};

ProductEdit.defaultProps = {
  preview: false,
};

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ProductEdit);
