import * as PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  DialogActions,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DialogTitleContent } from '../../../../common/styles/Common.styled';
import {
  ImportDialogMin,
  ImportDialogMinInner,
  ImportModalDialog,
  DialogTitleOfImport,
} from './ImportModal.styled';
import { IconClose, IconUpload } from '../../../../common/icons/customIcons';
import { importProduct } from '../../ducks/Products.actions';
import { throwNotification } from '../../../../common/structure';

class ImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentReport: {},
    };
  }

  handleChangeFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      this.setState({
        currentReport: {
          name: file.name,
          file: file,
        },
      });
    }
  };

  onUpload = () => {
    const {
      t,
      handleClose,
      changeGridParams,
      throwNotification,
      onImportProduct,
    } = this.props;
    const { currentReport } = this.state;

    if (!_.isEmpty(currentReport)) {
      onImportProduct(currentReport, (error) => {
        if (!error) {
          throwNotification(t('Шаблон успешно загружен. Начался импорт'));
          changeGridParams();
          handleClose();
        }
      });
    }

    return;
  };

  render() {
    const { currentReport } = this.state;
    const { handleClose, loadingProductReport, t } = this.props;

    return (
      <MuiThemeProvider theme={ImportModalDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title">
            <DialogTitleContent>
              <DialogTitleOfImport>
                <div style={{ textAlign: 'center', fontSize: '18px' }}>
                  {t('ИМПОРТ')}
                </div>
                <div style={{ textAlign: 'center' }}>
                  {t(
                    'Обращаем внимание, что системное ограничение для импорта составляет 100 товаров',
                  )}
                </div>
              </DialogTitleOfImport>

              <Button
                className="backEmpty"
                id="dismiss-btn-close"
                onClick={handleClose}>
                <IconClose />
              </Button>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            <ImportDialogMin>
              <div>{t('Выберите файл шаблона для импорта')}</div>
              <br />
              <ImportDialogMinInner>
                <div>{this.state.currentReport.name}</div>
                <Button component="label" disabled={false} color={'primary'}>
                  <IconUpload style={{ marginRight: '15px' }} />
                  {t('Выбрать файл')}
                  <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: 'none' }}
                    onChange={this.handleChangeFile}
                  />
                </Button>
              </ImportDialogMinInner>
            </ImportDialogMin>
          </DialogContent>
          <DialogActions>
            <Button
              color={'primary'}
              disabled={_.isEmpty(currentReport) || loadingProductReport}
              onClick={this.onUpload}>
              {loadingProductReport ? (
                <CircularProgress
                  style={{ color: '#52535A' }}></CircularProgress>
              ) : (
                t('Загрузить')
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ImportModal.propTypes = {
  onImportProduct: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  throwNotification: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onImportProduct: (params, cbk) => importProduct(params, cbk),
      throwNotification: (message, type = 'error') =>
        throwNotification(message, type),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  loadingProductReport: state.productsReducer.loadingProductReport,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ImportModal);
