import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ColumnCellInner } from '../../../common/styles/Common.styled';
import { ColumnHeaderDate } from '../Catalog.styled';

export default class CreatedCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, t } = this.props;

    const packingPurposeType = (value) => {
      switch (value) {
        case 'consumer':
          return t('Потребительская');
        case 'transport':
          return t('Транспортная');
        case 'group':
          return t('Групповая');
        default:
          return '';
      }
    };

    return (
      <ColumnHeaderDate>
        <ColumnCellInner>
          {packingPurposeType(value.packingPurpose)}
          {value.isExemplar ? (
            <img
              src="/images/isExemplarIcon.png"
              alt=""
              style={{ marginLeft: '10px' }}
              height={22}
            />
          ) : null}
        </ColumnCellInner>
      </ColumnHeaderDate>
    );
  }
}

CreatedCell.propTypes = {
  value: PropTypes.object,
};
