import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import _ from 'lodash';
import { MemberDetails, ProfileManagement, Gs1Interactions } from './views';
import * as selectorsP from './ducks/Profile/Profile.selectors';
import * as selectorsGS from './ducks/GS1/GS1.selectors';
import { DefaultTabs } from './Profile.styled';
import { ProfileContainer } from '../../common/styles/Common.styled';
import { loadProfileStatus } from '../profile/ducks/ProfileStatus/ProfileStatus.actions';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  componentDidMount() {
    this.props.onLoadProfileStatus();
  }

  handleChangeTab = (event, value) => this.setState({ value });

  render() {
    const { loader, user, t } = this.props;

    return (
      <ProfileContainer>
        <MuiThemeProvider theme={DefaultTabs}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            variant="fullWidth">
            <Tab disabled={loader} label={t('Данные участника')} />
            {/* {user && user.accountType !== 'csp' && ( */}
            {user && !_.includes(user.accountTypes, 'csp') && (
              <Tab disabled={loader} label={t('Настройки профиля')} />
            )}
            {user &&
              // user.accountType !== 'net' &&
              // user.accountType !== 'csp' && (
              !_.includes(user.accountTypes, 'net') &&
              !_.includes(user.accountTypes, 'csp') && (
                <Tab disabled={loader} label={t('Взаимодействие с GS1')} />
              )}
          </Tabs>
        </MuiThemeProvider>
        {this.state.value === 0 && <MemberDetails />}
        {this.state.value === 1 && <ProfileManagement />}
        {this.state.value === 2 && <Gs1Interactions />}
      </ProfileContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: selectorsP.errors(state),
  loader: selectorsGS.loader(state),
  user: state.authentificationReducer.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadProfileStatus: () => loadProfileStatus(),
    },
    dispatch,
  );
};

Profile.propTypes = {
  loader: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
