import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-top: 130px;
`;
export const ErrorBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const ErrorBlockLeft = styled.div`
  font-size: 18px;
  padding-right: 50px;
  font-weight: 600;
`;
export const ErrorBlockRight = styled.div`
  display: flex;
`;
export const ErrorTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 63px;
`;
export const ErrorCode = styled.span`
  font-size: 180px;
  font-weight: 600;
  display: block;
  line-height: 200px;
`;
export const ErrorLink = styled.span`
  display: block;
  margin-top: 50px;
  a {
    color: #4aa5e8;
    text-decoration: none;
  }
`;
