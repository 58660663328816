import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import 'moment/locale/ru';
import 'moment/locale/kk';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { forEach, get } from 'lodash';
import { config, selectLang } from '../../../../../config';
import {
  Button,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import * as selectors from '../../../ducks/GS1/GS1.selectors';
import {
  DefaultTable,
  GsRegBottom,
  GsRegContainer,
} from './styled/IsRegistered.styled';

class IsRegistered extends React.Component {
  constructor(props) {
    super(props);
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  handleButton = () => this.props.showRegistration();

  buildBlocks = (gcps) => {
    const jsxBlocks = [];
    forEach(gcps, (gcp) => {
      forEach(gcp.glns, (gln, index) => {
        jsxBlocks.push(
          index === 0 ? (
            <TableRow key={gcp.gcp}>
              <TableCell>{gcp.gcp}</TableCell>
              <TableCell />
            </TableRow>
          ) : (
            <TableRow key={gln.gln}>
              <TableCell />
              <TableCell>{gln.gln}</TableCell>
            </TableRow>
          ),
        );
      });
    });

    return <TableBody>{jsxBlocks}</TableBody>;
  };

  render() {
    const { gs1Info, t } = this.props;
    const tableBody = this.buildBlocks(get(gs1Info, 'gcps.gcps', []));

    const expiryDateMoment = moment(gs1Info.gcps.expiryDate);
    const timeDifference = expiryDateMoment - moment.now();
    const subtractDateDuration = moment.duration(timeDifference);
    const lessThanYears = subtractDateDuration.years();
    const lessThanMonth =
      lessThanYears <= 0 && subtractDateDuration.months() < 1;

    return (
      <div>
        <GsRegContainer>
          <MuiThemeProvider theme={DefaultTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('Регистрационный номер предприятия GCP')}
                  </TableCell>
                  <TableCell>
                    {t('Глобальный идентификационный номер GLN')}
                  </TableCell>
                </TableRow>
              </TableHead>
              {tableBody}
            </Table>
          </MuiThemeProvider>
        </GsRegContainer>

        <GsRegBottom>
          <div>
            <div>
              {t('Срок истечения членства')}:
              {lessThanYears > 10 ? (
                <span style={{ color: 'green' }}> не ограничено</span>
              ) : (
                <Moment
                  style={{
                    paddingLeft: '5px',
                    color: lessThanMonth ? 'red' : '',
                  }}
                  format="DD-MM-YYYY">
                  {expiryDateMoment}
                </Moment>
              )}
            </div>
            {lessThanMonth && (
              <div>
                {timeDifference < 0 ? (
                  <div>
                    <span style={{ color: 'red' }}>
                      {t('Членство истекло')}
                    </span>
                    .
                  </div>
                ) : (
                  <>
                    <div>
                      {t('До окончания членства в GS1 остается')}
                      &nbsp;
                      <span style={{ color: 'red' }}>
                        {subtractDateDuration.humanize()}
                      </span>
                      .
                    </div>
                    <div>
                      {t(
                        'Не забудьте его продлить для дальнейшей работы с добавлением товаров',
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div>
            {false && lessThanMonth && (
              <Button disabled={this.props.loader} onClick={this.handleButton}>
                {t('Продлить')}
              </Button>
            )}
          </div>
        </GsRegBottom>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: selectors.loader(state),
  gs1Info: selectors.info(state),
});

IsRegistered.propTypes = {
  showRegistration: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  gs1Info: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(IsRegistered);
