import platform from 'platform';
import { detect } from 'detect-browser';
import { SYSTEM_REQUIREMENTS } from './SystemRequirements.constants';

export const getParsedVersion = function (v) {
  if (v === undefined) {
    return v;
  }
  return ('' + v).split('.').reduce(function (verInfo, number, i) {
    if (i === 0) {
      verInfo.major = number;
    } else if (i === 1) {
      verInfo.minor = number;
    } else if (i === 2) {
      verInfo.patch = number;
    }

    return verInfo;
  }, {});
};

export const isCurrentOkWithRequired = function (required, current) {
  if (+required.major < +current.major) {
    return true;
  }
  if (+required.major > +current.major) {
    return false;
  }
  if (
    (!current.patch && !current.minor) ||
    (!required.patch && !required.minor)
  ) {
    return +required.major <= +current.major;
  }

  if (+required.minor < +current.minor) {
    return true;
  }
  if (+required.minor > +current.minor) {
    return false;
  }
  if (!current.patch || !required.patch) {
    return +required.minor <= +current.minor;
  }
  return +required.patch <= +current.patch;
};

export const getOSInfo = function () {
  return new Promise((resolve, reject) => {
    try {
      const currentVersion = getParsedVersion(platform.os.version);
      const os = Object.keys(SYSTEM_REQUIREMENTS.os).find(
        (key) => platform.os.family.indexOf(key) !== -1,
      );
      const requiredVersion = getParsedVersion(SYSTEM_REQUIREMENTS.os[os]);
      resolve({
        platform: platform.os,
        current: currentVersion,
        required: requiredVersion,
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const getBrowserInfo = function () {
  return new Promise((resolve, reject) => {
    try {
      const browserDetect = detect();
      const currentVersion = getParsedVersion(browserDetect.version);
      const browser = Object.keys(SYSTEM_REQUIREMENTS.browser).find(
        (key) =>
          browserDetect.name
            .toLocaleLowerCase()
            .indexOf(key.toLocaleLowerCase()) !== -1,
      );

      const requiredVersion =
        SYSTEM_REQUIREMENTS.browser[browser] === false
          ? false
          : SYSTEM_REQUIREMENTS.browser[browser] === true
          ? true
          : getParsedVersion(SYSTEM_REQUIREMENTS.browser[browser]);

      resolve({
        detect: browserDetect,
        current: currentVersion,
        required: requiredVersion,
      });
    } catch (e) {
      reject(e);
    }
  });
};

export class CadesUtil {
  static async getVersion() {
    if (window.cadesplugin.CreateObjectAsync) {
      try {
        const info = {};
        await window.cadesplugin;
        const about = await window.cadesplugin.CreateObjectAsync(
          'CAdESCOM.About',
        );
        info.cadesVersion = await about.PluginVersion;
        info.cspVersion = await about.CSPVersion();
        if (!info.cadesVersion) {
          info.cadesVersion = await about.Version;
        }
        info.cadesVersion = await info.cadesVersion.toString();
        info.cspVersion = await info.cspVersion.toString();
        return info;
      } catch (e) {
        console.log('err', e);
      }
    } else {
      try {
        const info = {};
        const about = window.cadesplugin.CreateObject('CAdESCOM.About');
        info.cadesVersion = about.PluginVersion;
        if (!info.cadesVersion) {
          info.cadesVersion = about.Version;
        }
        info.cspVersion = about.CSPVersion();
        info.cadesVersion = info.cadesVersion.toString();
        info.cspVersion = info.cspVersion.toString();
        return info;
      } catch (e) {
        console.log('err', e);
      }
    }
  }
}
export const getCryptoProInfo = function () {
  return new Promise((resolve, reject) => {
    CadesUtil.getVersion()
      .then((version) => {
        const currentCadesVersion = getParsedVersion(version.cadesVersion);
        const currentCspVersion = getParsedVersion(version.cspVersion);
        const requiredCadesVersion = getParsedVersion(
          SYSTEM_REQUIREMENTS.cryptopro.cades,
        );
        const requiredCspVersion = getParsedVersion(
          SYSTEM_REQUIREMENTS.cryptopro.csp,
        );

        resolve({
          currentCades: currentCadesVersion,
          currentCsp: currentCspVersion,
          requiredCades: requiredCadesVersion,
          requiredCsp: requiredCspVersion,
          cadesVersion: version.cadesVersion,
          cspVersion: version.cspVersion,
        });
      })
      .catch((e) => reject(e));
  });
};
