import { Reducer } from 'redux';
import { config, setLangConfig } from '../../../../config';
import i18n from '../../../../i18n';
import {
  CLEAR_INFO_ERROR,
  LOAD_PROFILE_CERTIFICATES_FAILED_ACTION,
  LOAD_PROFILE_CERTIFICATES_SUCCEEDED_ACTION,
  LOAD_PROFILE_FAILED_ACTION,
  LOAD_PROFILE_SUCCEEDED_ACTION,
  SAVE_INFO_FAILED_ACTION,
  SAVE_INFO_SUCCEEDED_ACTION,
  SET_LOCALE,
} from './ProfileActionTypes.constants';

const _initialState = {
  info: {
    user: {
      firstName: '',
      lastName: '',
      middleName: '',
      phone: '',
      created: '',
      email: '',
    },
    company: {
      name: '',
      nameShort: '',
      nameEN: '',
      opf: '',
      address: '',
      inn: '',
      okved2: {
        code: '',
        description: '',
      },
      // kpp: "",
      // ogrn: "",
      roles: [],
      isMarked: false,
    },
    certificate: null /*{
            companyName: "",
            userFullName: "",
            userPosition: "",
            certUid: "",
            owner: "",
            inn: "",
            issuedBy: "",
            validDateFrom: "",
            validDateTo: "",
            isValid: false,
        }*/,
  },
  errors: {},
  validationErrors: {},
  certificates: [],
  locale: config.lang,
};

export const profileReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE_SUCCEEDED_ACTION: {
      return {
        ...state,
        info: action.info,
      };
    }
    case LOAD_PROFILE_FAILED_ACTION: {
      return {
        ...state,
        errors: action.errors,
      };
    }
    case LOAD_PROFILE_CERTIFICATES_SUCCEEDED_ACTION: {
      return {
        ...state,
        certificates: action.certificates,
      };
    }

    case LOAD_PROFILE_CERTIFICATES_FAILED_ACTION: {
      return {
        ...state,
      };
    }

    case SAVE_INFO_SUCCEEDED_ACTION: {
      return {
        ...state,
        info: action.info,
      };
    }
    case SAVE_INFO_FAILED_ACTION: {
      return {
        ...state,
        info: action.info,
        validationErrors: action.validationErrors,
      };
    }
    case CLEAR_INFO_ERROR: {
      return { ...state, errors: {}, validationErrors: {} };
    }
    case SET_LOCALE: {
      setLangConfig(action.locale);
      i18n.changeLanguage(action.locale);
      return { ...state, locale: action.locale };
    }
    default:
      return { ...state };
  }
};
