import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _, { isString } from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  DELETE_TRADE_MARK_ACTION,
  LOAD_TRADE_MARKS_ACTION,
  ADD_TRADE_MARK_ACTION,
  UPDATE_TRADE_MARK_ACTION,
  ADD_TRADE_MARK_FILE_ACTION,
  GET_TRADE_MARK_DOCUMENTS_ACTION,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION,
} from './TradeMarks.constants';
import { throwNotification } from '../../../../common/structure';
import {
  loadTradeMarksFailed,
  loadTradeMarksSucceeded,
  addTradeMarkSucceeded,
  addTradeMarkFailed,
  updateTradeMarkSucceeded,
  updateTradeMarkFailed,
  addTradeMarkFileFailed,
  addTradeMarkFileSucceeded,
  deleteTradeMarkSucceeded,
  deleteTradeMarkFailed,
  getTradeMarkDocumentsSucceeded,
  getTradeMarkDocumentsFailed,
  deleteTradeMarkDocumentsFailed,
  deleteTradeMarkDocumentsSucceeded,
} from './TradeMarks.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

function buildQueryParametersGoodsList(action) {
  let params = {
    pageSize: action.pageSize,
    pageNumber: action.pageNum + 1 || 1,
    sort: action.sort,
    fields: action.fields,
  };

  if (_.isArray(action.sort) || _.isUndefined(action.sort)) {
    const sort = _.defaultTo(_.find(action.sort, 'direction'), {
      field: 'issueDate',
      direction: 'DESC',
    });
    params = _.assign({}, params, { sort: sort });
  }

  let filters = {};
  _.forIn(action.filters, (value, key) => {
    if (!_.isNull(value)) {
      if (key === 'issueDate') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'validDate') {
        if (!_.isNull(value.from) && !_.isNull(value.to)) {
          filters[key] = value;
        }
      } else if (key === 'AttachmentCount') {
        filters[key] = value;
      } else if (key === 'tmNumber') {
        filters[key] = value;
      } else {
        if (!_.isEmpty(value)) {
          filters[key] = _.has(value, 'id') ? value.id : value;
        }
      }
    }
  });

  return _.assign({}, params, { filter: filters });
}

export function* loadTradeMarks(action) {
  try {
    const params = buildQueryParametersGoodsList(action.params);
    const response = yield call(
      client().post,
      `/accounts/trademark/list`,
      JSON.stringify(params),
    );
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadTradeMarksFailed());
    } else {
      yield put(
        loadTradeMarksSucceeded(
          response.data || [],
          response.totalObjects || 0,
        ),
      );
    }
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Ошибка при загрузке Товарных Знаков!'),
        'error',
      ),
    );
    yield put(loadTradeMarksFailed());
  }
}

export function* deleteTradeMarks(action) {
  const errT = i18n.t('Ошибка при удалении Товарного Знака!');
  try {
    const response = yield call(
      client().delete,
      `/accounts/trademark/${action.id}/delete`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(deleteTradeMarkFailed());
    } else {
      yield put(deleteTradeMarkSucceeded());
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(deleteTradeMarkFailed());
  }
}

export function* addTradeMark(action) {
  const errT = i18n.t('Ошибка при добавлении Товарного Знака!');

  try {
    const response = yield call(
      client().post,
      `/accounts/trademark/add`,
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(addTradeMarkFailed());
      return action.cbk({ error: [errT] });
    }
    yield put(addTradeMarkSucceeded());
    action.cbk(null);
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(addTradeMarkFailed());
    action.cbk({ error: [errT] });
  }
}

export function* updateTradeMark(action) {
  const errT = i18n.t('Произошла ошибка при обновлении Товарного Знака!');

  try {
    const response = yield call(
      client().put,
      `/accounts/trademark/${action.id}/update`,
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(updateTradeMarkFailed());
      return action.cbk({ error: [errT] });
    }
    yield put(updateTradeMarkSucceeded());
    action.cbk(null);
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(updateTradeMarkFailed());
    action.cbk({ error: [errT] });
  }
}

export function* addTradeMarkFile(action) {
  const errT = i18n.t(
    'Произошла ошибка при добавлении документа к Товарному Знаку!',
  );
 const succT = i18n.t('Документ успешно добавлен к Товарному Знаку!')
  try {
    const formData = new FormData();
    const data = { ...action.data };

    data.fileImage = action.data.fileImage[0];
    formData.append('files[]', action.data.fileImage[0]);
    const response = yield call(client().post, `/accounts/trademark/${data.id}/uploadscan`, formData, {
      headers: { 'content-type': 'multipart/form-data' }
  });

    if (response.error) {
        yield handleErrorNotification(response.error);
        yield put(addTradeMarkFileFailed());
        return action.cbk(response.error);
    }

    yield put(addTradeMarkFileSucceeded());
    yield put(throwNotification(succT, `success`));
    action.cbk(null);
  } catch (e) {
    yield put(addTradeMarkFileFailed());
    yield put(throwNotification(errT, 'error'));
    action.cbk(e);
  }
}

export function* getTradeMarkDocuments(action) {
  const errT = i18n.t(
    'Произошла ошибка при получении документов Товарного Знака!',
  );

  try {
    const response = yield call(
      client().get,
      `/accounts/trademark/${action.data}`,
    );

    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(getTradeMarkDocumentsFailed());
      return action.cbk(null);
    }

    yield put(getTradeMarkDocumentsSucceeded());
    return action.cbk(response);
  } catch (e) {
    yield put(getTradeMarkDocumentsFailed());
    yield put(throwNotification(errT, 'error'));
    action.cbk(null);
  }
}

export function* deleteTradeMarkDocuments(action) {
  const errT = i18n.t(
    'Произошла ошибка при удалении документа Товарного Знака!',
  );
  const succT = i18n.t('Документ Товарного знака успешно удалён');
  try {
    const response = yield call(
      client().post,
      `/accounts/trademark/${action.data.attachmentId}/deletescan`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(deleteTradeMarkDocumentsFailed());
      return action.cbk(response.error);
    }
    yield put(deleteTradeMarkDocumentsSucceeded());
    yield put(throwNotification(succT, 'success'));
    return action.cbk(response);
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk(e);
  }
}

export function* watchUpdateTradeMark() {
  yield takeEvery(UPDATE_TRADE_MARK_ACTION, updateTradeMark);
}

export function* watchAddTrademark() {
  yield takeEvery(ADD_TRADE_MARK_ACTION, addTradeMark);
}

export function* watchTradeMarks() {
  yield takeEvery(LOAD_TRADE_MARKS_ACTION, loadTradeMarks);
}

export function* watchTradeMarkDelete() {
  yield takeEvery(DELETE_TRADE_MARK_ACTION, deleteTradeMarks);
}

export function* watchAddTradeMarkFile() {
  yield takeEvery(ADD_TRADE_MARK_FILE_ACTION, addTradeMarkFile);
}

export function* watchGetTradeMarkDocuments() {
  yield takeEvery(GET_TRADE_MARK_DOCUMENTS_ACTION, getTradeMarkDocuments);
}

export function* watchDeleteTradeMarkDocuments() {
  yield takeEvery(DELETE_TRADE_MARK_DOCUMENTS_ACTION, deleteTradeMarkDocuments);
}

export default function* TradeMarksSaga() {
  yield all([
    watchTradeMarks(),
    watchTradeMarkDelete(),
    watchAddTrademark(),
    watchUpdateTradeMark(),
    watchAddTradeMarkFile(),
    watchGetTradeMarkDocuments(),
    watchDeleteTradeMarkDocuments(),
  ]);
}
