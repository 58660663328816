import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const GridActionsSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        maxWidth: '200px',
        border: '1px solid rgba(134, 134, 134, 0.3)',
        borderRadius: '2px',
        '&:focus': {
          background: '#fff',
        },
      },
      select: {
        padding: '10px 20px',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      selectMenu: {
        color: '#000',
      },
    },
    MuiInputBase: {
      root: {
        marginTop: '0!important',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#52535A',
        fontSize: '14px',
        lineHeight: '16px',
      },
      formControl: {
        transform: 'translate(19px, 13px) scale(1)',
      },
      shrink: {
        color: '#868686',
        fontSize: '14px',
        transform: 'translate(19px, 13px) scale(1)',
        '&:focus': {
          color: '#868686',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#868686!important',
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '0',
        paddingTop: '0',
        paddingBottom: '0',
        borderTop: '1px solid #F2F2F2',
        '&:hover': {
          backgroundColor: '#F7F7F7',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '2px',
        minWidth: '200px!important',
        marginTop: '-1px',
      },
      elevation8: {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiButton: {
      root: {
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '0',
        backgroundColor: '#FFF',
        color: '#52535A',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          color: `${colors.Buttons.buttonPrimary.color}`,
        },
      },
      text: {
        padding: '10px 20px',
        textTransform: 'none',
      },
      label: {
        fontSize: '12px',
        fontWeight: '600',
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, 0.7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, 0.5) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const GridActionSelectBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        width: '100%',
        justifyContent: 'space-between',
        borderRadius: '0',
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      text: {
        padding: '10px 8px 10px 20px',
        textTransform: 'none',
      },
      label: {
        color: '#52535A',
        fontSize: '14px',
        fontWeight: '600',
      },
      disabled: {},
    },
  },
});
