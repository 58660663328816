import styled from 'styled-components';

export const AgreementTitle = styled.div`
  font-size: 16px;
  margin-bottom: 30px;
`;
export const AgreementMain = styled.div`
  font-weight: 600;
  margin-top: 60px;
`;
