import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { config } from '../../../../config';
import { Preloader } from '../../../../components';
import {
  Addresses,
  Agreement,
  Applicant,
  BankDetails,
  Contacts,
  SignForm,
} from './gsComponents';
import { ProfileTabCont } from '../../Profile.styled';
import * as selectorsGS from '../../ducks/GS1/GS1.selectors';
import {
  clearGS1Data,
  loadGS1Data,
  loadGS1Info,
  loadActivityTypes,
  loadCompanyTypes,
  loadMainProductTypes,
  saveGS1,
  loadGS1OpfDictionary,
} from '../../ducks/GS1/GS1.actions';
import { NewGsTitle } from './Gs1.styled';

/**
 * TODO:
 * отрефакторить.
 */

const applicantRequired = [
  'companyName',
  'companyNameShort',
  'companyNameStateLang',
  'okved2',
  'inn',
  'opf',
  'isMarked',
  'activityType',
  'productType',
  'firstName',
  'lastName',
  'position',
  'email',
  'phone',
];
const bankDetailRequired = ['name', 'bik'];
const legalAddressRequired = [
  'regionCode',
  'region',
  'city',
  'street',
  'building',
  'postalCode',
  'fullAddress',
];
const physicalAddressRequired = ['fullAddress'];
const contactsRequired = [
  'department',
  'lastName',
  'position',
  'firstName',
  'phone',
  'workPhone',
  'email',
];

class Gs1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSent: false,
      applicant: {
        // Заявитель
        // companyName: null, //+"Навзаение предприятия",
        // companyNameShort: null, //+"Сокращенное название предприятия",
        // companyNameStateLang: null, //+"Название предприятия на государственном языке",
        // firstName: null, //"Имя",
        // lastName: null, //"Фамилия",
        // middleName: null, //"Отчество",
        // position: null, //"Должность",
        // email: null, //"Email",
        // phone: null, //"Телефон",
        // workPhone: null, //"Рабочий телефон",
        // okved2: null, //+"Основной вид экономической деятельности",
        // inn: null, //+"ИНН",
        // opf: null, //+"Организационно-правовая форма",
        // isMarked: null, //+ Участник маркировки
        // companyTypes: null, //+["1", "2"], // Тип предприятия
        // activityType: null, //+"Вид деятельности",
        // productType: null, //+"Основной вид продукции"
      },
      bankDetails: {
        //Реквизиты банка
        // name: null, //"Наименование банка",
        // bik: null, //"БИК",
        // correspondentAccount: '', //"Корр. счет (IBAN)",
        // settlementAccount: '', //"Расчетный счет"
      },
      legalAddress: {
        // Юридический адрес
        // region: null, //"Регион/область",
        // locality: null, //"Населенный пункт",
        // city: null, //"Город",
        // area: null, //"Район",
        // street: null, //"Улица",
        // building: null, //"Здание",
        // room: null, //"Квартира/офис",
        // postalCode: null, //"Почтовый индекс",
        // fullAddress: null, //"Полный адрес",
        // fullAddressStateLang: null, //"Полный адрес на государственном языке",
        // regionCode: null //"Код региона", // Для КЗ - КАТО
      },
      physicalAddress: {
        // Адрес для корреспонденции
        // fullAddress: null, //"Полный адрес"
        // fullAddressStateLang: null, //"Полный адрес на государственном языке"
      },
      companyDetails: {
        // Данные предприятия
        // accountType: null, //["1", "2"], // Профиль деятельности организации
        // enterpriseSector: null, //"Сектор предприятия",
        // mainProductGCP: null, //"Основная продукция по GCP",
        // obtainingDocumentsMethod: null, //"Способ получения документов"
      },
      contacts: [
        // Контакты
        // {
        //     department: null, //"Отдел",
        //     lastName: null, //"Фамилия",
        //     firstName: null, //"Имя",
        //     middleName: null, //"Отчество",
        //     position: null, //"Должность",
        //     email: null, //"Email",
        //     workPhone: null, //"Рабочий телефон",
        //     phone: null, //"Телефон" мобильный
        // }
      ],
      agreement: false,
      errors: {
        applicant: {},
        bankDetails: {},
        legalAddress: {},
        physicalAddress: {},
        companyDetails: {},
        contacts: {},
      },
    };
  }

  componentDidMount() {
    this.props.loadGS1Data();
    if (_.isEmpty(this.props.gs1Info.gcps)) {
      this.props.loadGS1Info();
    }
    if (_.isEmpty(this.props.gs1Data)) {
      this.props.loadGS1Data();
    }
    if (_.isEmpty(this.props.activityTypesDictionary)) {
      this.props.loadActivityTypes();
    }
    if (_.isEmpty(this.props.companyTypesDictionary)) {
      this.props.loadCompanyTypes();
    }
    if (_.isEmpty(this.props.mainProductTypesDictionary)) {
      this.props.loadMainProductTypes();
    }
    if (_.isEmpty(this.props.gs1OpfDictionary)) {
      this.props.loadGS1OpfDictionary();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (_.isEmpty(state.applicant) && !_.isEmpty(props.gs1Data.applicant)) {
      newState.applicant = props.gs1Data.applicant;
    }

    if (_.isEmpty(state.bankDetails) && !_.isEmpty(props.gs1Data.bankDetails)) {
      newState.bankDetails = props.gs1Data.bankDetails;
    }
    if (
      _.isEmpty(state.legalAddress) &&
      !_.isEmpty(props.gs1Data.addresses?.legalAddress)
    ) {
      newState.legalAddress = props.gs1Data.addresses.legalAddress;
    }
    if (
      _.isEmpty(state.physicalAddress) &&
      !_.isEmpty(props.gs1Data.addresses?.physicalAddress)
    ) {
      newState.physicalAddress = props.gs1Data.addresses.physicalAddress;
    }
    if (
      _.isEmpty(state.companyDetails) &&
      !_.isEmpty(props.gs1Data.companyDetails)
    ) {
      newState.companyDetails = props.gs1Data.companyDetails;
    }
    if (
      state.contacts[0] &&
      !Object.keys(state.contacts[0]).length &&
      !_.isEmpty(props.gs1Data.contacts)
    ) {
      newState.contacts = props.gs1Data.contacts;
    }
    if (_.isEmpty(state.contacts) && _.isEmpty(props.gs1Data.contacts)) {
      newState.contacts = [{}];
    }

    if (Object.keys(newState).length) {
      return newState;
    }

    return null;
  }

  checkRequiredValuesInObject = (object, requiredValues) => {
    let allFieldsFilled = true;
    _.forEach(object, (value, key) => {
      if (
        _.includes(requiredValues, key) &&
        (value === 0 || value === null || value === '')
      ) {
        return (allFieldsFilled = false);
      }
    });

    return allFieldsFilled;
  };

  checkBankDetailsFields = (object, requiredValues) => {
    let requiredFieldsFilled = true;
    let atleasOneFilled =
      !!object.correspondentAccount || !!object.settlementAccount;
    _.forEach(object, (value, key) => {
      if (
        _.includes(requiredValues, key) &&
        (value === 0 || value === null || value === '')
      ) {
        return (requiredFieldsFilled = false);
      }
    });

    return requiredFieldsFilled && atleasOneFilled;
  };

  checkRequiredFormFields = () => {
    const {
      applicant,
      bankDetails,
      legalAddress,
      physicalAddress,
      contacts,
    } = this.state;
    const applicantFields = this.checkRequiredValuesInObject(
      applicant,
      applicantRequired,
    );
    const bankFields = this.checkBankDetailsFields(
      bankDetails,
      bankDetailRequired,
    );
    const legalAddressFields = this.checkRequiredValuesInObject(
      legalAddress,
      legalAddressRequired,
    );
    const physAddressFields = this.checkRequiredValuesInObject(
      physicalAddress,
      physicalAddressRequired,
    );
    const contactsFields = this.checkRequiredValuesInObject(
      contacts[0],
      contactsRequired,
    );

    return (
      applicantFields &&
      bankFields &&
      legalAddressFields &&
      physAddressFields &&
      contactsFields
    );
  };

  checkForErrors = (object) => {
    if (!Object.keys(object).length) return true;

    return _.values(object).every((item) => item === false);
  };

  noErrors = () => {
    const errors = _.cloneDeep(this.state.errors);
    const {
      applicant,
      bankDetails,
      companyDetails,
      contacts,
      legalAddress,
      physicalAddress,
    } = errors;

    /**
     * если ошибок нет, то должно быть true;
     */
    let noErrors =
      this.checkForErrors(applicant) &&
      this.checkForErrors(bankDetails) &&
      this.checkForErrors(companyDetails) &&
      this.checkForErrors(contacts) &&
      this.checkForErrors(legalAddress) &&
      this.checkForErrors(physicalAddress);

    return noErrors || false;
  };

  handleChange = (tab, name, value, hasError) => {
    switch (tab) {
      case 'contacts':
        this.setState((prevState) => ({
          contacts: prevState.contacts.map((c, i) =>
            i === 0 ? { ...c, [name]: value } : c,
          ),
          errors: {
            ...prevState.errors,
            contacts: {
              ...prevState.errors.contacts,
              [name]: hasError,
            },
          },
        }));
        break;
      default:
        this.setState((prevState) => ({
          [tab]: {
            ...prevState[tab],
            [name]: value,
          },
          errors: {
            ...prevState.errors,
            [tab]: {
              ...prevState.errors[tab],
              [name]: hasError,
            },
          },
        }));
        break;
    }
    return;
  };

  handleCheckBox = () => {
    this.setState({
      agreement: !this.state.agreement,
    });
  };

  onSubmit = () => {
    const currentState = _.cloneDeep(this.state);
    const {
      applicant,
      bankDetails,
      legalAddress,
      physicalAddress,
      contacts,
    } = currentState;
    const finalObject = {
      applicant,
      bankDetails,
      addresses: {
        legalAddress,
        physicalAddress,
      },
      contacts,
      status: null,
    };

    this.props.saveGS1(finalObject, null, (error) => {
      if (_.isNil(error)) {
        this.props.loadGS1Data();
      }
    });
  };

  render() {
    const {
      loaderGs1Info,
      gs1Data,
      t,
      gs1OpfDictionary,
      mainProductTypesDictionary,
    } = this.props;

    if (loaderGs1Info) return <Preloader isOpen />;

    const allRequiredFieldsFilled = this.checkRequiredFormFields();

    const showForm =
      Object.keys(gs1Data).length > 0 &&
      (gs1Data.status === null || gs1Data.status === 'new');
    const showTableInfo =
      Object.keys(gs1Data).length > 0 &&
      gs1Data.status !== 'new' &&
      gs1Data.status !== null;

    const formHasNoErrors = this.noErrors();

    return (
      <ProfileTabCont style={{height: 'auto'}}>
        <NewGsTitle>
          <h3>{t('Вступить в Ассоциацию', { gs1Name: t(config.gs1Name) })}</h3>

          {showForm && (
            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Красным отмечены обязательные для заполнения поля',
                    {
                      style: 'style=color:red;',
                    },
                  ),
                }}
              />
            </span>
          )}
        </NewGsTitle>
        {showForm && (
          <>
            <Applicant
              handleStateChange={this.handleChange}
              applicant={this.state.applicant}
              // errors={this.state.errors.applicant}
              gs1OpfDictionary={gs1OpfDictionary}
            />
            <BankDetails
              handleStateChange={this.handleChange}
              bankDetails={this.state.bankDetails}
              // errors={this.state.errors.bankDetails}
            />
            <Addresses
              handleStateChange={this.handleChange}
              legalAddress={this.state.legalAddress}
              physicalAddress={this.state.physicalAddress}
              // legalAddressErrors={this.state.errors.legalAddress}
              // physicalAddressErrors={this.state.errors.physicalAddress}
            />
            <Contacts
              contacts={this.state.contacts}
              handleStateChange={this.handleChange}
              // errors={this.state.errors.contacts}
            />
            <Agreement
              agreement={this.state.agreement}
              handleCheckBox={this.handleCheckBox}
            />
            <Button
              onClick={this.onSubmit}
              disabled={
                !allRequiredFieldsFilled ||
                !this.state.agreement ||
                this.state.loading ||
                !formHasNoErrors
              }>
              {t('Сохранить и отправить')}
            </Button>
          </>
        )}

        {showTableInfo && (
          <SignForm
            data={gs1Data}
            productDictionary={mainProductTypesDictionary}
          />
        )}
      </ProfileTabCont>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: selectorsGS.loader(state),
  loaderGs1Info: selectorsGS.loaderGs1Info(state),
  gs1Data: selectorsGS.data(state),
  gs1Info: selectorsGS.info(state),
  profileInfo: state.profileReducer.info,
  activityTypesDictionary: state.gs1Reducer.activityTypesDictionary,
  companyTypesDictionary: state.gs1Reducer.companyTypesDictionary,
  mainProductTypesDictionary: state.gs1Reducer.mainProductTypesDictionary,
  gs1OpfDictionary: state.gs1Reducer.gs1OpfDictionary,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadGS1Info: () => loadGS1Info(),
      loadGS1Data: () => loadGS1Data(),
      clearGS1Data: () => clearGS1Data(),
      loadActivityTypes: () => loadActivityTypes(),
      loadCompanyTypes: () => loadCompanyTypes(),
      loadMainProductTypes: () => loadMainProductTypes(),
      saveGS1: (data, certificate, cbk) => saveGS1(data, certificate, cbk),
      loadGS1OpfDictionary: () => loadGS1OpfDictionary(),
    },
    dispatch,
  );
};

Gs1.propTypes = {
  loader: PropTypes.bool.isRequired,
  loaderGs1Info: PropTypes.bool.isRequired,
  loadGS1Info: PropTypes.func.isRequired,
  loadGS1Data: PropTypes.func.isRequired,
  clearGS1Data: PropTypes.func.isRequired,
  gs1Data: PropTypes.object.isRequired,
  gs1Info: PropTypes.object.isRequired,
  profileInfo: PropTypes.object.isRequired,
  activityTypesDictionary: PropTypes.array.isRequired,
  companyTypesDictionary: PropTypes.array.isRequired,
  mainProductTypesDictionary: PropTypes.array.isRequired,
  loadGS1OpfDictionary: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Gs1);
