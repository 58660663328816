import * as React from 'react';
import ProductsGrid from './productGrid/ProductsGrid';

class Products extends React.Component {
  render() {
    return <ProductsGrid />;
  }
}

export default Products;
