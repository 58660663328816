export const loading = (state) => state.catalogGoodsReducer.loading;
export const data = (state) => state.catalogGoodsReducer.data;
export const totalObjects = (state) => state.catalogGoodsReducer.totalObjects;
export const filters = (state) => state.catalogGoodsReducer.filters;
export const versions = (state) => state.catalogGoodsReducer.versions;
export const dictionaryBrand = (state) => state.catalogGoodsReducer.dictionaryBrand;
export const dictionaryCategory = (state) =>
  state.catalogGoodsReducer.dictionaryCategory;
export const alert = (state) => state.catalogGoodsReducer.alert;
export const user = (state) => state.authentificationReducer.user;
export const certificate = (state) => state.profileReducer.info.certificate;
export const certificates = (state) => state.profileReducer.certificates;
export const locale = (state) => state.profileReducer.locale;