import styled from 'styled-components';
import { NewGsTitle } from '../../../profile/views/gsOne/Gs1.styled';
import { StatusLine } from '../../../../common/styles/Common.styled';

export const ProductMainContainer = styled.div`
  padding: 20px;
`;
export const StatusBar = styled.div`
  position: sticky;
  display: flex;
  top: 70px;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 0 0 4px 4px;
  width: 100%;
  min-height: 40px;
  margin-bottom: 20px;
`;
export const ProductCont = styled.div`
  padding: 0 40px 100px;
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 0 0 4px 4px;
  background: #fff;
  position: relative;
`;
export const ProductEditButtons = styled.div`
  button {
    @media (max-width: 1440px) {
      min-width: auto;
    }
  }
`;
export const ProductStatus = styled.div`
  display: flex;
  justify-content: space-between;
  width: 83%;
  padding: 0 40px 0 0;
`;
export const CardState = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;
export const ProductStatusInfo = styled.div`
  padding: 5px 0;
  font-weight: 600;
  display: flex;
  align-items: center;
`;
export const TitleClarification = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  margin-bottom: -35px;
`;
export const NewProductTitle = styled(NewGsTitle)`
  position: inherit;
  width: 310px;
  top: 15px;
  justify-content: space-between;
  padding: 0 40px;
`;
export const NewProductGtin = styled.div`
  font-size: 13px;
  span {
    font-weight: 600;
  }
`;
export const ProductStatusLine = styled(StatusLine)`
  padding-left: 0;
  color: ${(props) => props.color};
`;
