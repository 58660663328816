import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import {
  IconClose,
  IconDrop,
  IconDropColor,
  IconHide,
  IconShow,
} from '../../../../common/icons/customIcons';
import {
  CheckCont,
  DefaultOrderPopover,
  DropIconCont,
  OrderBtns,
  OrderNumber,
} from './ColumnOrder.styled';
import { FilterBtn } from '../../productGrid/filters/Filters.styled';
import {
  DialogTitleContent,
  DialogTitleStyled,
} from '../../../../common/styles/Common.styled';
import { withTranslation } from 'react-i18next';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 0;

const getItemStyle = (isDragging, draggableStyle, isShow) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '10px 10px 10px 40px',
  margin: `0 0 ${grid}px 0`,
  boxShadow: isDragging
    ? '0px 0px 25px 3px rgba(0,0,0,0.15)'
    : 'inset 0px 1px 0px #F2F2F2',
  // change background colour if dragging
  background: isDragging ? '#fff' : 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: '600',
  color: isShow ? '#52535A' : '#B9BDC6',
  borderRadius: isDragging ? '4px' : '0',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'none' : 'none',
  padding: grid,
  width: '100%',
});

class ColumnOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.columns,
      dirty: false,
    };
    // console.log( this.props)
  }

  selectAll = () => {
    const items = _.map(this.state.items, (item) => {
      return { ...item, show: true };
    });
    this.setState({ items, dirty: true });
  };
  
  handleChange = (e, value) => {
    const items = _.map(this.state.items, (item) => {
      if (value.accessor === item.accessor) {
        item.show = !item.show;
      }
      return item;
    });
    this.setState({ items: items, dirty: true });
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    items = _.map(items, (item, index) => {
      item.position = index;
      return item;
    });
    this.setState({ items: items, dirty: true });
  };

  resetToDefault = () => {
    this.setState({
      items: _.cloneDeep(this.props.defaultColumns),
      dirty: true,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <MuiThemeProvider theme={DefaultOrderPopover}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleContent>
              <DialogTitleStyled>
                {t('Редактирование таблицы')}
              </DialogTitleStyled>
              <MuiThemeProvider theme={FilterBtn}>
                <Button onClick={this.props.onCancel}>{<IconClose />}</Button>
              </MuiThemeProvider>
            </DialogTitleContent>
          </DialogTitle>
          <DialogContent>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}>
                    {_.map(this.state.items, (item, index) => (
                      <Draggable
                        key={item.accessor}
                        draggableId={item.accessor}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              item.show,
                            )}>
                            <OrderNumber>{index + 1}</OrderNumber>
                            {item.name}

                            <CheckCont>
                              {item.name !== t('Статус') &&
                                item.name !== t('Наименование') && (
                                  <Checkbox
                                    checkedIcon={<IconShow />}
                                    icon={<IconHide />}
                                    checked={item.show}
                                    onChange={(e) => this.handleChange(e, item)}
                                  />
                                )}
                            </CheckCont>

                            <DropIconCont>
                              {snapshot.isDragging ? (
                                <IconDropColor />
                              ) : (
                                <IconDrop />
                              )}
                            </DropIconCont>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <OrderBtns>
            <Button color={'primary'} onClick={this.selectAll}
                style={{ marginBottom: '10px' }}>
                {t('Выбрать все')}
              </Button>


              <Button
                disabled={!this.state.dirty}
                color={'primary'}
                style={{ marginBottom: '10px' }}
                onClick={() => this.props.onSave(this.state.items)}>
                {t('Сохранить')}
              </Button>

              <Button
                disabled={_.isEqual(
                  this.state.items,
                  this.props.defaultColumns,
                )}
                color={'secondary'}
                onClick={this.resetToDefault}>
                {t('Отменить')}
              </Button>

              

            </OrderBtns>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ColumnOrder.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  defaultColumns: PropTypes.array.isRequired,
};

export default withTranslation()(ColumnOrder);
