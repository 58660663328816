import { ActionCreator } from 'redux';
import {
  LOAD_SITE_PROFILE_ACTION,
  LOAD_SITE_PROFILE_ACTION_SUCCEEDED,
  LOAD_SITE_PROFILE_ACTION_FAILED,
  SAVE_SITE_PROFILE_ACTION,
  SAVE_SITE_PROFILE_ACTION_SUCCEEDED,
  SAVE_SITE_PROFILE_ACTION_FAILED,
  LOAD_MARKET_FIELDS_ACTION,
  LOAD_MARKET_FIELDS_ACTION_SUCCEEDED,
  LOAD_MARKET_FIELDS_ACTION_FAILED,
} from './SiteProfile.constants';

export const loadSiteProfile: ActionCreator = () => ({
  type: LOAD_SITE_PROFILE_ACTION,
});

export const loadSiteProfileSucceeded: ActionCreator = (data) => ({
  type: LOAD_SITE_PROFILE_ACTION_SUCCEEDED,
  data,
});

export const loadSiteProfileFailed: ActionCreator = () => ({
  type: LOAD_SITE_PROFILE_ACTION_FAILED,
});

export const saveSiteProfile: ActionCreator = (data) => ({
  type: SAVE_SITE_PROFILE_ACTION,
  data,
});

export const saveSiteProfileSucceeded: ActionCreator = (data) => ({
  type: SAVE_SITE_PROFILE_ACTION_SUCCEEDED,
  data,
});

export const saveSiteProfileFailed: ActionCreator = () => ({
  type: SAVE_SITE_PROFILE_ACTION_FAILED,
});

export const loadMarketFields: ActionCreator = () => ({
  type: LOAD_MARKET_FIELDS_ACTION,
});

export const loadMarketFieldsSucceeded: ActionCreator = (data) => ({
  type: LOAD_MARKET_FIELDS_ACTION_SUCCEEDED,
  data,
});

export const loadMarketFieldsFailed: ActionCreator = () => ({
  type: LOAD_MARKET_FIELDS_ACTION_FAILED,
});
