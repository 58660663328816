import { Reducer } from 'redux';
import _ from 'lodash';
import {
  CHANGE_ACCESS_COMPANY_MANUAL_ACTION,
  LOAD_SUB_ACCOUNT_ACTION,
  LOAD_SUB_COMPANY_FAILED_ACTION,
  LOAD_SUB_COMPANY_SUCCEEDED_ACTION,
  SAVE_SUBACCOUNT_LETTER_SUCCEEDED_ACTION,
  LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION,
} from './SubAccount.constants';

const _initialState = {
  subaccountsForGoodsCreation: [],
  data: [],
  totalObjects: 0,
  loading: false,
  dataLetter: {},
};

export const subAccountReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_SUB_ACCOUNT_ACTION: {
      return { ...state, loading: true };
    }

    case LOAD_SUB_COMPANY_FAILED_ACTION: {
      return {
        ...state,
        data: [],
        subaccountsForGoodsCreation: [],
        totalObjects: 0,
        loading: false,
      };
    }
    case LOAD_SUB_COMPANY_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: action.data,
        totalObjects: action.totalObjects,
        loading: false,
      };
    }
    case LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION: {
      return {
        ...state,
        subaccountsForGoodsCreation: action.data,
        loading: false,
      };
    }
    case CHANGE_ACCESS_COMPANY_MANUAL_ACTION: {
      return {
        ...state,
        data: _.map(state.data, (i) => {
          const item = _.find(action.payload, { id: i.id });
          return item
            ? _.assign({}, i, { accessAllowed: item.accessAllowed })
            : i;
        }),
      };
    }
    case SAVE_SUBACCOUNT_LETTER_SUCCEEDED_ACTION: {
      return {
        ...state,
        dataLetter: { ...state.dataLetter, ...action.dataLetter },
      };
    }

    default:
      return { ...state };
  }
};
