import { Reducer } from 'redux';
import {
  LOAD_PARENT_COMPANY_ACTION,
  LOAD_PARENT_COMPANY_FAILED_ACTION,
  LOAD_PARENT_COMPANY_SUCCEEDED_ACTION,
} from './ParentCompany.constants';

const _initialState = {
  data: [],
  totalObjects: 0,
  loading: false,
};

export const parentCompanyReducer: Reducer = (
  state = _initialState,
  action,
) => {
  switch (action.type) {
    case LOAD_PARENT_COMPANY_ACTION: {
      return { ...state, loading: true };
    }

    case LOAD_PARENT_COMPANY_FAILED_ACTION: {
      return { ...state, data: [], totalObjects: 0, loading: false };
    }
    case LOAD_PARENT_COMPANY_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: action.data,
        totalObjects: action.totalObjects,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
