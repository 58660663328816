import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MuiThemeProvider,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ButtonAdormentTheme } from '../../common/styles/Common.styled';

class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      dirty: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['value', 'error', 'label', 'placeholder', 'disabled'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  handleChange = (event) => {
    if (!this.state.dirty) {
      this.setState({ dirty: true });
    }
    this.props.onChange(event.target.value);
  };

  handleUpdate = (event) => {
    if (this.props.onBlur) {
      if (!this.state.dirty) {
        this.setState({ dirty: true });
      }
      this.props.onBlur(event.target.value);
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      id,
      name,
      placeholder,
      value,
      error,
      disabled,
      autoComplete,
      label,
      shrink,
    } = this.props;
    let inputLabelOpt = { htmlFor: id };
    if (shrink) {
      inputLabelOpt = { ...inputLabelOpt, shrink: true };
    }
    return (
      <div>
        <FormControl fullWidth={true}>
          <InputLabel {...inputLabelOpt}>{label}</InputLabel>
          <Input
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={this.handleChange}
            onBlur={this.handleUpdate}
            error={error && this.state.dirty}
            disabled={disabled}
            autoComplete={autoComplete}
            type={this.state.showPassword ? 'text' : 'password'}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <MuiThemeProvider theme={ButtonAdormentTheme}>
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </MuiThemeProvider>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  }
}

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  shrink: PropTypes.bool,
};

PasswordInput.defaultProps = {
  label: 'Пароль',
  shrink: false,
};

export default PasswordInput;
