import { createMuiTheme } from '@material-ui/core';

export const PhotoDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minHeight: '335px',
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px 80px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px 0',
        textAlign: 'right',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      label: {
        color: '#52535A!important',
      },
    },
  },
});
