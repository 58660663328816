import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const DialogProductTitleContent = styled.div`
  font-size: 14px;
  button {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }
  &.newProduct {
    button {
      top: 11px;
      right: 17px;
    }
  }
`;
export const PackInfoItem = styled.div`
  display: flex;
`;
export const PackInfoItemMax = styled.div`
  flex-grow: 1;
`;
export const PackInfoItemMin = styled.div`
  padding-left: 20px;
`;
export const ProductViewMinBorder = styled.div`
  margin-bottom: 80px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &:nth-of-type(1) {
    margin-bottom: 80px;
  }
`;
export const ProductViewMediaItemType = styled.span`
  display: inline-block;
  font-size: 11px;
  color: #868686;
  line-height: 20px;
  margin-top: 20px;
  line-height: 1;
  padding: 0 10px;
`;
export const ProductViewMediaItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ProductSearchBtns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ModerationIconHolder = styled.div`
  transform: translateX(-18.5px) translateY(36px);
  height: 0;
  width: 0;
`;
export const ProductSearchBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: 'none',
        height: '50px',
        minWidth: '60px',
        transition: '0.4s ease',
        marginTop: '16px',
        border: '1px solid #D9D9D9',
        borderRadius: '3px 0 0 3px',
      },
      text: {
        padding: '9px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
export const NewProductDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px',
        textAlign: 'left',
        position: 'relative',
        lineHeight: '30px',
      },
    },
    MuiTypography: {
      h6: {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 20px 10px',
      },
    },
    MuiDialogActions: {
      root: {
        //borderTop: "1px solid #F2F2F2",
        padding: '20px',
        margin: '0',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        color: `${colors.Buttons.buttonPrimary.color}`,
        boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        transition: '.4s ease',
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
        '&:hover': {
          boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          opacity: '0.8',
          color: `${colors.Buttons.buttonPrimary.color}`,
        },
      },
      text: {
        padding: '12px 35px',
        fontSize: '16px',
        fontWeight: '600',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '0',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          opacity: '0.8',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
  },
});
