import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  defaultTo,
  find,
  forEach,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
  some,
  toNumber,
} from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Tooltip,
} from '@material-ui/core';
import {
  ModerationIconHolder,
  ProductViewMediaItems,
  ProductViewMediaItemType,
  ProductViewMinBorder,
} from '../Product/Product.styled';
import {
  ProductViewMdiaItem,
  TextTooltipModeration,
  TextTooltipModerationUpper,
} from '../../../../common/styles/Common.styled';
import {
  ProductViewMediaCount,
  ProductViewMediaDialog,
  ProductViewMediaItemsTitle,
  ProductViewMediaText,
} from './MediaContentView.styled';
import {
  IconClose,
  IconError,
  IconMediaLeft,
  IconMediaRight,
} from '../../../../common/icons/customIcons';
import {
  getPhotoType,
  PACKAGING_TYPE,
} from '../../../../common/constants/Packaging';

class MediaContentView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenImg: false,
      photoIndex: 0,
      bottom: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['identifier', 'images', 'errors', 'locale'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  changeOpenImages = (e) => {
    this.setState({
      isOpenImg: !this.state.isOpenImg,
      photoIndex: e.currentTarget.getAttribute('value'),
    });
  };

  prevImg = () => {
    const photoIndex = toNumber(this.state.photoIndex) - 1;
    const index = photoIndex < 0 ? size(this.props.images) - 1 : photoIndex;
    this.setState({ photoIndex: index });
  };

  nextImg = () => {
    const photoIndex = toNumber(this.state.photoIndex) + 1;
    const index = photoIndex > size(this.props.images) - 1 ? 0 : photoIndex;
    this.setState({ photoIndex: index });
  };

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  render() {
    const { t } = this.props;
    const errors = this.props.errors;

    return (
      <React.Fragment>
        <ProductViewMinBorder>
          <ProductViewMediaItems>
            {map(this.props.images, (v, i) => (
              <div key={i}>
                {!isEmpty(errors) && errors[v.url] && (
                  <MuiThemeProvider
                    theme={
                      this.state.bottom
                        ? TextTooltipModeration
                        : TextTooltipModerationUpper
                    }>
                    <Tooltip
                      interactive
                      id={`tooltip_media_view_moderate_${i}`}
                      onOpen={this.handleOpen(
                        `tooltip_media_view_moderate_${i}`,
                      )}
                      placement="bottom-end"
                      title={errors[v.url]}>
                      <ModerationIconHolder>
                        <IconError />
                      </ModerationIconHolder>
                    </Tooltip>
                  </MuiThemeProvider>
                )}
                <ProductViewMdiaItem
                  key={i}
                  value={i}
                  onClick={this.changeOpenImages}>
                  <img alt={`${v.gtin} ${v.type}`} src={v.url} />
                  <ProductViewMediaItemType>
                    {t(getPhotoType(v.type))}
                  </ProductViewMediaItemType>
                </ProductViewMdiaItem>
              </div>
            ))}
          </ProductViewMediaItems>
        </ProductViewMinBorder>

        {this.state.isOpenImg && (
          <MuiThemeProvider theme={ProductViewMediaDialog}>
            <Dialog open onClose={this.changeOpenImages}>
              <DialogTitle>
                <ProductViewMediaItemsTitle>
                  <ProductViewMediaCount>
                    {`${toNumber(this.state.photoIndex) + 1} / ${size(
                      this.props.images,
                    )}`}
                  </ProductViewMediaCount>

                  <Button className="backEmpty" onClick={this.changeOpenImages}>
                    <IconClose />
                  </Button>
                </ProductViewMediaItemsTitle>
              </DialogTitle>

              <DialogContent>
                <Button color={'primary'} onClick={this.prevImg}>
                  <IconMediaLeft />
                </Button>
                <div>
                  <img
                    alt=""
                    height=""
                    width="100%"
                    src={this.props.images[this.state.photoIndex].url}
                  />
                  <ProductViewMediaText>
                    <span>
                      {t(
                        getPhotoType(
                          this.props.images[this.state.photoIndex].type,
                        ),
                      )}
                    </span>
                    <br />
                    <span>
                      {
                        find(PACKAGING_TYPE, {
                          level: defaultTo(
                            find(this.props.identifier, {
                              gtin: this.props.images[this.state.photoIndex]
                                .gtin,
                            }),
                            { level: 'trade-unit' },
                          ).level,
                        }).text
                      }
                    </span>
                  </ProductViewMediaText>
                </div>
                <Button color={'secondary'} onClick={this.nextImg}>
                  <IconMediaRight />
                </Button>
              </DialogContent>
            </Dialog>
          </MuiThemeProvider>
        )}
      </React.Fragment>
    );
  }
}

MediaContentView.propTypes = {
  identifier: PropTypes.array.isRequired,
  images: PropTypes.array.isRequired,
  errors: PropTypes.object,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(MediaContentView);
