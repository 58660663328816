import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isString } from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import { LOAD_PARENT_COMPANY_ACTION } from './ParentCompany.constants';
import { throwNotification } from '../../../../common/structure';
import {
  loadParentCompanyFailed,
  loadParentCompanySucceeded,
} from './ParentCompany.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

const URL = 'accounts/linked-accounts/parents';

export function* loadParentCompany(action) {
  try {
    const url = `${URL}?page=${action.params.pageNum + 1 || 1}&size=${
      action.params.pageSize
    }`;
    const response = yield call(client().get, url);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadParentCompanyFailed());
    } else {
      yield put(
        loadParentCompanySucceeded(
          response.data || [],
          response.totalObjects || 0,
        ),
      );
    }
  } catch (e) {
    yield put(
      throwNotification(
        i18n.t('Ошибка при загрузке родительских компаний!'),
        'error',
      ),
    );
    yield put(loadParentCompanyFailed());
  }
}

export function* watchParentCompany() {
  yield takeEvery(LOAD_PARENT_COMPANY_ACTION, loadParentCompany);
}

export default function* parentCompanySaga() {
  yield all([watchParentCompany()]);
}
