import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ColumnCellInner } from '../../../common/styles/Common.styled';
import { ColumnCell } from '../Catalog.styled';
import { Tooltip } from '@material-ui/core';
import { clippedValue } from '../../../common/utils/utils';

export default class CategoryCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value } = this.props;

    return (
      <ColumnCell>
        <ColumnCellInner>
          <Tooltip arrow placement="bottom-start" title={value}>
            <div>{clippedValue(value)}</div>
          </Tooltip>
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

CategoryCell.propTypes = {
  value: PropTypes.string.isRequired,
};
