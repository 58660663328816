import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import { GridBlock } from '../../common/styles/Common.styled';
import colors from '../../common/styles/Common.colors';

export const ProfileTabCont = styled.div`
  position: relative;
  padding: 70px 40px 100px;
  box-shadow: 0 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 0 0 4px 4px;
  background: #fff;
  height: 98%;
  @media (max-width: 1200px) {
    padding: 70px 20px 100px;
  }
`;
export const ProfileDivider = styled.div`
  height: 60px;
`;
export const GridBlockB = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: 1fr auto auto;
`;
export const GridItem = styled.div`
  grid-column: 1 / -1;
`;
export const NewBtnsContainer = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
`;
export const GridItemC = styled.div`
  grid-column: col 2 / span 2;
`;
export const AdressEachBlock = styled.div`
  position: relative;
  margin-bottom: 20px;
`;
export const AvatarCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const LoginAvatarCell = styled.div`
  width: 40px;
  height: 40px;
  background: ${colors.Header.profileLogo};
  font-size: 16px;
  color: ${colors.Header.profileColor};
  font-weight: 500;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
`;
export const DefaultTabs = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        marginTop: '10px',
      },
      indicator: {
        height: '4px',
        backgroundColor: `${colors.Tabs.tabLine}`,
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Segoe UI',
      },
      wrapper: {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '20px',
        letterSpacing: '0',
      },
    },
  },
});
export const SmallTabs = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        margin: '10px 0 15px',
        minHeight: '40px',
      },
      indicator: {
        backgroundColor: '#FFF',
        height: '4px',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'SegoeUI',
        minHeight: '40px',
        borderBottom: '1px solid #F2F2F2',
        '&$selected': {
          border: '1px solid #F2F2F2',
          borderBottom: '1px solid transparent',
        },
      },
      wrapper: {
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '20px',
      },
      selected: {},
    },
  },
});
export const CreationCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '22px',
        height: '22px',
        position: 'absolute',
        top: '20px',
        right: '20px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
