import * as React from 'react';
import * as PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';
import { ColumnCellInner } from '../../../../common/styles/Common.styled';
import { ColumnCell } from '../ProductsGrid.styled';

export default class ContentProviderCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.original.dto, nextProps.original.dto);
  }

  render() {
    return (
      <ColumnCell>
        <ColumnCellInner>
          {get(this.props.original.dto, 'contentProvider.name', '-')}
          <br />
          {get(this.props.original.dto, 'contentProvider.inn', '-')}
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

ContentProviderCell.propTypes = {
  original: PropTypes.object.isRequired,
};
