import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { CertificateSelect } from '../index';
import { ConfirmDialogStyled as CDS } from '../styled';
import { DialogTitleContent } from '../../common/styles/Common.styled';
import { FilterBtn } from '../../pages/products/productGrid/filters/Filters.styled';
import {
  CertSelectDialog,
  DialogQuestion,
  DialogSelect,
  DialogRadioGroup,
} from './CertificateSelectModal.styled';
import { IconClose } from '../../common/icons/customIcons';

class CertificateSelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishOnWebsite: 'no',
    };
    this.props.onGetAllCerts();
  }

  handlepublishOnWebsiteChange = (event) => {
    return this.setState({
      publishOnWebsite: event.target.value,
    });
  };

  onSubmit = () => {
    let params = {};
    if (this.props.questionAboutPublishing) {
      params = {
        ...params,
        publishOnWebsite: this.state.publishOnWebsite === 'yes',
      };
    }
    this.props.onSubmit(this.props.cert, params);
  };

  render() {
    const {
      certificates,
      cert,
      onSelectCert,
      onCancel,
      acceptBtnText,
      t,
    } = this.props;
    const validCert = _.get(cert, 'isValid', false);
    const answer =
      this.props.questionAboutPublishing &&
      _.isNull(this.state.publishOnWebsite);

    const disabledOnSubmit = !validCert || answer;

    return (
      <MuiThemeProvider theme={CertSelectDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleContent>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={onCancel}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            {this.props.questionAboutPublishing && (
              <DialogQuestion>
                <div>{t('Публикация карточки упаковки товара')}</div>
              </DialogQuestion>
            )}

            <DialogSelect>
              <CertificateSelect
                certificates={certificates}
                onSelectCert={onSelectCert}
                cert={cert}
              />
            </DialogSelect>

            {this.props.questionAboutPublishing && (
              <DialogRadioGroup>
                <FormLabel id="publishOnWebsite-label">
                  {t(
                    'Вы хотите, чтобы Ваш товар отображался на сайте Национальный каталог РК?',
                  )}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="publishOnWebsite-label"
                  name="publishOnWebsite"
                  defaultValue={this.state.publishOnWebsite}
                  onChange={this.handlepublishOnWebsiteChange}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={t('Да')}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label={t('Нет')}
                  />
                </RadioGroup>
              </DialogRadioGroup>
            )}
          </DialogContent>

          <DialogActions>
            <CDS.ConfirmDialogButtons>
              <Button
                color="primary"
                disabled={disabledOnSubmit}
                onClick={this.onSubmit}>
                {!this.props.questionAboutPublishing
                  ? acceptBtnText
                  : this.state.publishOnWebsite == 'no'
                  ? t('Подписать')
                  : acceptBtnText}
              </Button>

              {this.props.isCancelButton && (
                <Button color="secondary" onClick={onCancel}>
                  {t('Отменить')}
                </Button>
              )}
            </CDS.ConfirmDialogButtons>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

CertificateSelectModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSelectCert: PropTypes.func.isRequired,
  onGetAllCerts: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  cert: PropTypes.any.isRequired,
  acceptBtnText: PropTypes.string.isRequired,
  isCancelButton: PropTypes.bool.isRequired,
  questionAboutPublishing: PropTypes.bool.isRequired,
};

CertificateSelectModal.defaultProps = {
  isCancelButton: false,
  questionAboutPublishing: false,
};

export default withTranslation()(CertificateSelectModal);
