import styled from 'styled-components';

export const PopupProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const PopupProductInn = styled.div`
  position: relative;
  flex-basis: 50%;
  max-width: 240px; 
  margin-right: 20px;
`;
export const PopupProductName = styled.div`
  position: relative;
  flex-grow: 1;
  flex-basis: 50%;
`;
export const PopupProductGcp = styled.div`
  position: relative;
  flex-basis: 32%;
`;
export const PopupProductCountry = styled.div`
  position: relative;
  flex-basis: 65%;
`;
export const ProductGtinBlock = styled.div`
  flex-basis: 100%;
`;

export const ProductRegNumerBlock = styled.div`
  flex-basis: 100%;
`;
