import styled from 'styled-components';

export const MediaItemMain = styled.div`
  display: flex;
  @media (max-width: 1830px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
`;
export const MediaItemMainAddLetter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
`;
export const MediaItemSide = styled.div`
  min-width: 110px;
  margin-right: 15px;
  //align-items: flex-end;
  display: flex;
`;
export const MediaItemSideImage = styled(MediaItemSide)`
  margin-right: 0;
`;
export const MediaItemSideDelete = styled.div`
  margin-left: 15px;
  @media (min-width: 1620px) and (max-width: 1818px) {
    margin-left: 0px;
  }
`;
export const MediaItemPack = styled.div`
  min-width: 250px;
  max-width: auto;
  flex-shrink: 0;
  flex-grow: 1;
  margin-right: 15px;
  .pack-select-main {
    .pack-select {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const MediaImg = styled.div`
  border: 1px solid ${(props) => props.theme.main};
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  min-width: 200px;
  max-width: 250px;
  border-radius: 3px;
  margin: 16px 0 0;
  button {
    min-width: 20px;
    height: 30px;
    border: none;
    padding: 3px;
    margin: 0;
    line-height: 20px;
  }
`;
export const MediaImgName = styled.span`
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const UploadWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 40px 0;
`;

export const MediaDeleteButton = styled.div`
  color: #63666a;
  border: 1px solid #868686;
  padding: 14px 0;
  font-size: 16px;
  background: none;
  transition: 0.4s ease;
  font-family: SegoeUI;
  font-weight: 600;
  border-radius: 3px;
  min-width: 120px;
  text-transform: 'none';
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 25px;
  &:hover: {
    color: #868686;
    background-color: transparent;
  }
`;
