import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  deburr,
  defaultTo,
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  isEqual,
  isNull,
  some,
  toLower,
  trim,
} from 'lodash';
import {
  Button,
  FormControl,
  MenuItem,
  MuiThemeProvider,
  Popover,
} from '@material-ui/core';
import {
  IconClose,
  IconFilterWhite,
  IconFilteredSquareOrange,
} from '../../../common/icons/customIcons';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterTypingSelect,
} from './Filters.styled';
import { Autocomplete } from '../../../components';
//временно
import { loadDictionaryGoodsBrand } from '../../products/ducks/Products.actions';
import * as selectors from '../../products/productGrid/ducks/ProductGrid.selectors';

class BrandFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      brand: defaultTo(this.props.currentValue, ''),
      currentValue: this.props.currentValue,
      suggestions: this.props.dictionaryBrand,
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.dictionaryBrand)) {
      this.props.fetchDictionaryBrand();
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        brand: '',
        currentValue: null,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['dictionaryBrand', 'currentValue'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.brand,
    });

    this.props.onAccept('brand', this.state.brand);
  };

  handleDissmiss = () => {
    this.setState({
      anchorEl: null,
      brand: '',
    });
    this.props.onDismiss('brand');
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    return (
      <MenuItem disableGutters={true} selected={isHighlighted} component="div">
        {suggestion.text}
      </MenuItem>
    );
  };

  getSuggestionValue = (suggestion) => {
    this.setState({ brand: suggestion });
    return suggestion.text;
  };

  getSuggestions = (value) => {
    const inputValue = toLower(deburr(trim(value)));
    return inputValue.length === 0
      ? this.props.dictionaryBrand.slice(0, 30)
      : filter(this.props.dictionaryBrand, (item) =>
          includes(toLower(item.text), inputValue),
        );
  };

  handleSuggestionsFetchRequested = (value) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.dictionaryBrand.slice(0, 10),
      brand: '',
    });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconFilterWhite />
              ) : (
                <IconFilteredSquareOrange />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Товарный знак')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={FilterTypingSelect}>
                <FormControl fullWidth={true}>
                  <Autocomplete
                    name="brand"
                    suggestions={this.state.suggestions}
                    placeholder={t('Выберите торговую марку')}
                    handleSuggestionsFetchRequested={
                      this.handleSuggestionsFetchRequested
                    }
                    handleSuggestionsClearRequested={
                      this.handleSuggestionsClearRequested
                    }
                    renderSuggestion={this.renderSuggestion}
                    getSuggestionValue={this.getSuggestionValue}
                    initValue={get(this.state, 'currentValue.text', '')}
                    emptyLabel={true}
                  />
                </FormControl>
              </MuiThemeProvider>
            </FilterContent>
            <FilterBtns>
              <Button disabled={!this.state.brand} onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
              <Button
                disabled={!this.state.brand}
                className="backEmpty"
                onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

BrandFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fetchDictionaryBrand: PropTypes.func.isRequired,
  dictionaryBrand: PropTypes.array.isRequired,
  currentValue: PropTypes.object,
  parentId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchDictionaryBrand: () => loadDictionaryGoodsBrand(),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  dictionaryBrand: selectors.dictionaryBrand(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(BrandFilter);
