import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  compact,
  forEach,
  get,
  includes,
  map,
  mapValues,
  memoize,
  replace,
  size,
  values,
} from 'lodash';
import { client } from '../../../../../api/Client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { CustomInput } from '../../../../../components';
import { ConfirmDialogStyled as CDS } from '../../../../../components/styled';
import { IconClock, IconClose } from '../../../../../common/icons/customIcons';
import { DialogTitleContent } from '../../../../../common/styles/Common.styled';
import {
  ConfirmEmailBtn,
  ConfirmEmailDialog,
  ConfirmEmailInput,
  ConfirmEmailInputs,
  ConfirmEmailTime,
  DialogTitleConfirmed,
} from './ConfirmEmailModal.styled';
import { withTranslation } from 'react-i18next';

async function acceptEmailCode(code) {
  return await client().instance.post(
    `/profile/verify-code`,
    JSON.stringify({ emailCode: code }),
  );
}

async function sendEmailAgainCode() {
  return await client().instance.post(
    `/profile/resend-code`,
    JSON.stringify({ email: true }),
  );
}

const checkFullness = memoize((obj) => size(compact(values(obj))));

const SECONDS_INIT = 30;

class ConfirmEmailModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      objValue: { v1: '', v2: '', v3: '', v4: '', v5: '' },
      seconds: SECONDS_INIT,
      hasBeenClicked: false,
    };
    this.textInput = mapValues(this.state.objValue, () => React.createRef());
    this.timer = 0;
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    const seconds = this.state.seconds - 1;
    this.setState({ seconds: seconds });

    if (seconds === 0) {
      clearInterval(this.timer);
      this.timer = 0;
    }
  };

  handleValue = (value, name) => {
    this.setState((prevState) => ({
      objValue: { ...prevState.objValue, [name]: value },
    }));
  };

  onKeyPress = (name, keyCode) => {
    let nextNumber = +name.substring(1);
    if (includes([8, 37, 40], keyCode)) {
      nextNumber -= 1;
      if (nextNumber < 1) return;
    } else {
      nextNumber += 1;
      if (nextNumber > 5) return;
    }
    this.textInput[`v${nextNumber}`].current.focus();
  };

  onAccept = () => {
    const code = values(this.state.objValue).join('');
    acceptEmailCode(code)
      .then(() => {
        this.props.onCancel(null, true);
      })
      .catch((errors) => {
        const err = get(errors, 'response.data.errors');
        forEach(err, (i) => this.props.throwError(i));
      });
  };

  sendAgain = () => {
    if (this.timer === 0) {
      sendEmailAgainCode()
        .then(() => {
          this.setState({ seconds: SECONDS_INIT, hasBeenClicked: true });
          this.startTimer();
        })
        .catch((errors) => {
          const err = get(errors, 'response.data.errors');
          forEach(err, (i) => this.props.throwError(i));
        });
    }
  };

  render() {
    const { t } = this.props;
    const { hasBeenClicked } = this.state;
    const textEmail = replace(
      t(
        'Для подтверждения адреса электронной почты, необходимо ввести код, который отправлен вам',
        { email: this.props.email },
      ),
      '\n',
      '<br />',
    );
    return (
      <MuiThemeProvider theme={ConfirmEmailDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title">
            <DialogTitleContent>
              <DialogTitleConfirmed>
                {t('Введите код подтверждения')}
              </DialogTitleConfirmed>
              <Button className="backEmpty" onClick={this.props.onCancel}>
                <IconClose />
              </Button>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: textEmail }} />
            <ConfirmEmailInputs>
              {map(this.state.objValue, (value, key) => (
                <ConfirmEmailInput key={key}>
                  <CustomInput
                    inputRef={this.textInput[key]}
                    maxLength={1}
                    onChange={this.handleValue}
                    onKeyPress={this.onKeyPress}
                    name={key}
                    value={value}
                  />
                </ConfirmEmailInput>
              ))}
            </ConfirmEmailInputs>
          </DialogContent>
          <DialogActions>
            <CDS.ConfirmDialogButtons>
              <ConfirmEmailBtn>
                <Button
                  color={'secondary'}
                  onClick={this.sendAgain}
                  disabled={this.timer !== 0 && this.state.seconds > 0}>
                  {hasBeenClicked
                    ? t('Отправить код еще раз')
                    : t('Подтвердить email')}
                </Button>

                <ConfirmEmailTime>
                  <IconClock style={{ marginRight: '10px' }} />
                  00:{('0' + this.state.seconds).slice(-2)}
                </ConfirmEmailTime>
              </ConfirmEmailBtn>

              <Button
                color={'primary'}
                onClick={this.onAccept}
                disabled={checkFullness(this.state.objValue) !== 5}>
                {t('Подтвердить')}
              </Button>
            </CDS.ConfirmDialogButtons>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ConfirmEmailModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  throwError: PropTypes.func.isRequired,
};

export default withTranslation()(ConfirmEmailModal);
