import * as React from 'react';
import i18n from '../../i18n';

export const SYSTEM_REQUIREMENTS = {
  os: {
    'OS X': '10.8',
    Windows: '6.1',
  },
  browser: {
    Chrome: true,
    Firefox: true,
    Safari: true,
    IE: '9',
    Yandexbrowser: true,
    Edge: true,
  },
  cryptopro: {
    cades: '2.0.0',
    csp: '3.6',
  },
};

export const BrowserRequirements = () => (
  <React.Fragment>
    {`${i18n.t('Поддерживаемые версии браузеров')} `}
    <a
      href="https://www.google.com/chrome/"
      target="_blank"
      rel="noopener noreferrer">
      Google Chrome 70
    </a>
    {', '}
    <a href="https://www.mozilla.org" target="_blank" rel="noopener noreferrer">
      Mozilla Firefox 60
    </a>
    {', '}
    <a
      href="https://www.microsoft.com/ru-ru/download/internet-explorer-11-for-windows-7-details.aspx"
      target="_blank"
      rel="noopener noreferrer">
      Internet Explorer 11
    </a>
    {', '}
    <a
      href="https://www.apple.com/ru/safari/"
      target="_blank"
      rel="noopener noreferrer">
      Safari 12
    </a>
    {` ${i18n.t('или новее')}`}
  </React.Fragment>
);

export const CryptographyPluginRequirements = () => (
  <React.Fragment>
    {`${i18n.t('У вас должен быть установлен плагин')} `}
    <a
      href="https://www.cryptopro.ru/products/cades/plugin/"
      target="_blank"
      rel="noopener noreferrer">
      {i18n.t('КриптоПро')}
    </a>
    {` ${i18n.t('или')} `}
    <a
      href="https://www.rutoken.ru/products/all/rutoken-plugin/"
      target="_blank"
      rel="noopener noreferrer">
      {i18n.t('Рутокен')}
    </a>
    &nbsp;
    {i18n.t('для браузеров')} Google Chrome, Mozilla Firefox, Internet Explorer
    11, Safari
  </React.Fragment>
);
