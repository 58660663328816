import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  compact,
  forEach,
  forOwn,
  isEmpty,
  isEqual,
  isNull,
  join,
  trimStart,
} from 'lodash';
import { Button, MuiThemeProvider, Popover } from '@material-ui/core';
import { CustomInput, SimpleTooltip } from '../../../components';
import { packageGtinValidLength } from '../../../common/utils/utils';
import {
  IconClose,
  IconErrorSmall,
  IconFilterWhite,
  IconFilteredSquareOrange,
} from '../../../common/icons/customIcons';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
  FilterErrorSmall,
} from './Filters.styled';

// eslint-disable-next-line
String.prototype.allReplace = function (obj) {
  let retStr = this;
  forOwn(obj, (value, key) => {
    retStr = retStr.replace(new RegExp(key, 'g'), value);
  });
  return retStr;
};

class PackagesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      // gtin: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      gtin: this.props.currentValue ? this.props.currentValue.join('\n') : '',
      error: '',
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        gtin: '',
        error: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (value) => {
    value = value.toString();
    value = value.replace(/[^\d|^\n|^;|^,|^\s]/, '');
    value = value.allReplace({
      ';;': ';',
      ';,': ';',
      '; ': ';',
      ',,': ',',
      ', ': ',',
      ',;': ',',
      '  ': ' ',
      ' ,': ' ',
      ' ;': ' ',
    });

    let values = value.split(new RegExp('[\\n;,\\s+]', 'g'));
    if (values[values.length - 1].length > 14) {
      const v = value[value.length - 1];
      value = value.substring(0, value.length - 1) + '\n' + trimStart(v);
    }

    this.setState({
      gtin: trimStart(value),
      error: '',
    });
  };

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const gtins = compact(
      this.state.gtin.split(new RegExp('[\\n;,\\s+]', 'g')),
    );
    if (this.validateGtins(gtins)) {
      this.setState({
        anchorEl: null,
        currentValue: gtins,
      });
      this.props.onAccept('packages', gtins);
    }
  };

  validateGtins = (gtinsArr) => {
    const { t } = this.props;
    let valid = true;
    let error = '';

    if (!isEmpty(gtinsArr)) {
      forEach(gtinsArr, (gtin) => {
        if (!packageGtinValidLength(gtin)) {
          error = `${t('Введите корректные коды товаров')}. \n ${t(
            'Код товара должен содержать 13 или 14 цифр',
          )}`;
          valid = false;
        }
      });
      this.setState({ gtin: join(gtinsArr, '\n'), error });
    }
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, gtin: '', error: '' });
    this.props.onDismiss('packages');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconFilterWhite />
              ) : (
                <IconFilteredSquareOrange />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Код вложенной упаковки')}</h6>

              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <MuiThemeProvider theme={defaultFilterInput}>
                <CustomInput
                  multiline={true}
                  label={t('Введите коды вложенных упаковок')}
                  onChange={(value) => this.onValueChange(value)}
                  cleanButtonStyles={{ marginTop: -15 }}
                  value={this.state.gtin}
                  onBlur={false}
                  rows={1}
                  rowsMax={6}
                  name="gtin-filter"
                />
              </MuiThemeProvider>
              {!!this.state.error &&
                <FilterErrorSmall>{SimpleTooltip(this.state.error, <IconErrorSmall />)}</FilterErrorSmall>}
            </FilterContent>
            <FilterBtns>
              <Button disabled={!this.state.gtin} onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
              <Button
                disabled={!this.state.gtin}
                className="backEmpty"
                onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

PackagesFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.string),
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(PackagesFilter);
