import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { defaultTo, forEach, isEmpty, isNull } from 'lodash';
import { Button, MuiThemeProvider, Popover } from '@material-ui/core';
import {
  defaultFilterInput,
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContentProvider,
  FilterContentProviderContainer,
  FilterTitle,
} from './Filters.styled';
import {
  IconClose,
  IconErrorSmall,
  IconFilter,
  IconFilteredSquare,
} from '../../../../common/icons/customIcons';
import { CustomInput, SimpleTooltip } from '../../../../components';
import { validateInn } from '../../../../common/utils/validationBankDetails';

class ContentProviderFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      name: defaultTo(this.props.currentName, ''),
      inn: defaultTo(this.props.currentInn, ''),
      currentName: this.props.currentName,
      currentInn: this.props.currentInn,
      error: '',
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (
      (isNull(nextProps.currentName) && !isNull(state.currentName)) ||
      (isNull(nextProps.currentInn) && !isNull(state.currentInn))
    ) {
      return {
        anchorEl: null,
        name: '',
        inn: '',
        currentName: nextProps.currentName,
        currentInn: nextProps.currentInn,
      };
    }
    return null;
  }

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? element : event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onValueChange = (value, name) => {
    if (name === 'inn') {
      value = value.toString();
      value = value.replace(/[^\d|^\n|^;|^,|^\s]/, '');
      value = value.allReplace({
        ';;': ';',
        ';,': ';',
        '; ': ';',
        ',,': ',',
        ', ': ',',
        ',;': ',',
        '  ': ' ',
        ' ,': ' ',
        ' ;': ' ',
      });
    }
    this.setState({ [name]: value });
  };

  handleAccept = () => {
    if (this.validateInns(this.state.inn)) {
      this.props.onAccept('contentProvider', {
        name: this.state.name,
        inn: this.state.inn.split(new RegExp('[\\n;,\\s+]', 'g')),
      });
      this.setState({
        currentName: this.state.name,
        currentInn: this.state.inn,
        anchorEl: null,
      });
    }
  };

  validateInns = (innsString) => {
    let valid = true;
    if (innsString) {
      let inns = innsString.split(new RegExp('[\\n;,\\s+]', 'g'));
      forEach(inns, (inn) => {
        let error = {};
        if (!validateInn(inn, error)) {
          valid = false;
        }
      });
      if (!valid) {
        this.setState({ error: this.props.t('Введите корректное значение') });
      }
    }
    return valid;
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, inn: '', name: '', error: '' });
    this.props.onDismiss('contentProvider');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentName) &&
              isEmpty(this.props.currentInn) ? (
                <IconFilter />
              ) : (
                <IconFilteredSquare />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Поставщик')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContentProvider>
              <MuiThemeProvider theme={defaultFilterInput}>
                <CustomInput
                  label={t('Наименование поставщика')}
                  onChange={(value) => this.onValueChange(value, 'name')}
                  onBlur={false}
                  value={this.state.name}
                  name="name-filter"
                  disabled={!!this.state.inn}
                />
              </MuiThemeProvider>

              <br />
              <FilterContentProviderContainer>
                <MuiThemeProvider theme={defaultFilterInput}>
                  <CustomInput
                    multiline={true}
                    label={t('ИНН поставщиков')}
                    onChange={(value) => this.onValueChange(value, 'inn')}
                    value={this.state.inn}
                    onBlur={false}
                    rows={1}
                    rowsMax={6}
                    name="inn-filter"
                    disabled={!!this.state.name}
                  />
                </MuiThemeProvider>

                {!isEmpty(this.state.error) &&
                  SimpleTooltip(this.state.error, <IconErrorSmall />)}
              </FilterContentProviderContainer>
            </FilterContentProvider>
            <FilterBtns>
              <Button onClick={this.handleAccept}>{t('Применить')}</Button>
              <Button className="backEmpty" onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

ContentProviderFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentName: PropTypes.string,
  currentInn: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(ContentProviderFilter);
