import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { config, selectLang } from '../../../../../config';
import {
  IconInvalid,
  IconValid,
} from '../../../../../common/icons/customIcons';
import {
  CertIcon,
  CertName,
  CertOwner,
  CertViewBlock,
  CertViewBlockIcon,
  CertViewMainBlock,
} from './CertificateView.styled';
import { SelectedCertMin } from '../../../../../common/styles/Common.styled';

class CertificateView extends React.Component {
  constructor(props) {
    super(props);
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !_.isEqual(this.props.cert, nextProps.cert) ||
      !_.isEqual(this.props.locale, nextProps.locale)
    );
  }

  render() {
    const { cert, user, t } = this.props;
    let startDate = '';
    let endDate = '';
    if (cert && cert.validDateFrom && cert.validDateTo) {
      startDate = moment(cert.validDateFrom).format('DD-MM-YYYY');
      endDate = moment(cert.validDateTo).format('DD-MM-YYYY');
    }

    return (
      <React.Fragment>
        {!!cert && (
          <CertViewMainBlock>
            <CertViewBlock>
              <CertOwner>{cert.companyName}</CertOwner>
              <CertName>{cert.userFullName}</CertName>
              <span>{cert.userPosition}</span>
            </CertViewBlock>
            <CertViewBlockIcon>
              <CertIcon>
                {cert.isValid ? <IconValid /> : <IconInvalid />}
              </CertIcon>

              <SelectedCertMin>
                {!user.isElkUser && (
                  <span>
                    {t('Сертификат')}: {cert.certUid}
                  </span>
                )}

                <span>
                  {t('Владелец')}: {_.replace(cert.owner, /"/g, '')}
                </span>
                <span>
                  {t('Налоговый идентификатор')}: {cert.inn}
                </span>
                <br />

                {!user.isElkUser && (
                  <React.Fragment>
                    <span>
                      {t('Выдан')}: {_.replace(cert.issuedBy, /"/g, '')}
                    </span>
                    <span>
                      {t('Действителен')}:{' '}
                      {t('c startDate до endDate', { startDate, endDate })}
                    </span>
                  </React.Fragment>
                )}
              </SelectedCertMin>
            </CertViewBlockIcon>
          </CertViewMainBlock>
        )}
      </React.Fragment>
    );
  }
}

CertificateView.propTypes = {
  user: PropTypes.object.isRequired,
  cert: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authentificationReducer.user,
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(CertificateView);
