import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  DialogActions,
} from '@material-ui/core';
import _, { flow, isNil } from 'lodash';
import {
  IconClose,
  IconUpload,
  IconDeleteSmall,
} from '../../../../../common/icons/customIcons';
import { throwNotification } from '../../../../../common/structure';
import { CertificateSelect } from '../../../../../components';
import { DialogTitleStyled } from '../../../../../common/styles/Common.styled';
import { CreationCloseBtns } from '../../../Profile.styled';
import { SubAccountDialog } from '../../subAccount/styled/AssignSubAccountModal.styled';
import {
  UploadWrapper,
  MediaDeleteButton,
} from '../../../../product/view/itemElement/MediaItem/MediaItem.styled';
import { DialogSelectAddLetter } from '../../../../../components/certificateSelectModal/CertificateSelectModal.styled';
import {
  AddLetterFormWrapper,
  RadioInputsWrapper,
  ImportDialogMinInner,
} from '../styled/AddDocuments.styled.modal';
import { addTradeMarkFile } from '../../../ducks/TradeMarks/TradeMarks.actions';

const allowedFileTypes = ['image/jpg', 'image/jpeg'];

function fileIsIncorrectFiletype(file) {
  return file.size < 1 || allowedFileTypes.indexOf(file.type) === -1;
}

class AddDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: '',
      fileName: '',
      keyStr: '',
      bottom: true,
      certificate: {},
      checked: null,
      isDisabled: true,
      data: {
        fileImage: [],
        tmName: this.props.currentRow.tmName,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileName } = this.state;

    if (fileName !== prevState.fileName) {
      if (fileName) {
        this.setState({ isDisabled: false });
      } else {
        this.setState({ isDisabled: true });
      }
    }
  }

  handleChangeImg = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const throwError = flow([this.props.t, this.props.throwError]);

    if (fileIsIncorrectFiletype(file)) {
      throwError('Неподдерживаемый формат изображения');
      return;
    }

    if (file.size / 1024 / 1024 > 15) {
      throwError('Файл не может превышать размер в 15 мб');
      return;
    }

    let data = { ...this.state.data };
    data.fileImage.push(file);

    if (file) {
      this.setState({
        fileName: file.name,
        preview: URL.createObjectURL(file),
        data,
      });
    }
  };

  deleteImg = () => {
    this.setState({
      fileName: '',
      preview: '',
      keyStr: Math.random().toString(36),
    });
  };

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  onSelectCertificate = (value) => {
    this.setState({ certificate: value });
  };

  handleChange = (e) => {
    this.setState({ checked: e.target.value });
  };

  sendDocument = () => {
    const { data } = this.state;
    const { currentRow, changeGridParams, handleClose } = this.props;
    const queryData = _.cloneDeep(data);
    queryData.id = currentRow.tmId;

    this.props.addTradeMarkFile(queryData, (error) => {
      if (_.isNil(error)) {
        changeGridParams();
        handleClose();
      }
    });
  };

  render() {
    const { handleClose, t, certificates } = this.props;
    const { fileName, certificate, isDisabled, preview } = this.state;

    return (
      <MuiThemeProvider theme={SubAccountDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>
              {t('Загрузка документов и изображений к Товарному знаку')}
            </DialogTitleStyled>
            <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={handleClose}>
                <IconClose />
              </Button>
            </MuiThemeProvider>
          </DialogTitle>

          <DialogContent>
            <AddLetterFormWrapper>
              <RadioInputsWrapper>
                <input
                  className="inputClass"
                  type="text"
                  placeholder={t('Товарный знак отсутствует')}
                  readOnly
                  disabled={true}
                  value={this.state.data.tmName || ''}
                />
              </RadioInputsWrapper>
              <UploadWrapper>
                <ImportDialogMinInner>
                  {preview && (
                    <img
                      style={{ maxWidth: '198px', height: '40px' }}
                      alt=""
                      src={preview}
                    />
                  )}
                  <div>{fileName}</div>
                  <Button component="label" disabled={false} color={'primary'}>
                    <IconUpload style={{ marginRight: '15px' }} />
                    {t('Выбрать файл')}
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={this.handleChangeImg}
                    />
                  </Button>
                </ImportDialogMinInner>

                <MediaDeleteButton disabled={false} onClick={this.deleteImg}>
                  <IconDeleteSmall />
                  {t('Удалить')}
                </MediaDeleteButton>
              </UploadWrapper>

              <DialogSelectAddLetter>
                <CertificateSelect
                  certificates={certificates}
                  onSelectCert={(cert) => this.onSelectCertificate(cert)}
                  cert={certificate}
                />
              </DialogSelectAddLetter>
            </AddLetterFormWrapper>
          </DialogContent>

          <DialogActions>
            <Button
              color={'primary'}
              disabled={isDisabled}
              onClick={this.sendDocument}>
              {t('Подписать')}
            </Button>
            <Button color={'secondary'} onClick={handleClose}>
              {t('Отменить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

AddDocumentModal.propTypes = {
  currentRow: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  addTradeMarkFile: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  certificates: state.profileReducer.certificates,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addTradeMarkFile: (data, cbk) => addTradeMarkFile(data, cbk),
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(AddDocumentModal);
