import styled, { css } from 'styled-components';

export const WrapImg = styled.div`
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
  position: relative;
  margin-top: 10px;
  z-index: 999;
  height: 30px;
  width: 30px;
`;
export const Text = styled.div`
  margin: 10px 0 0 0;
  font-size: 14px;
  line-height: ${20 / 14};
`;
export const WrapPreloader = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  background: white;
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          min-height: 400px;
          right: 0;
        `
      : undefined}
`;
