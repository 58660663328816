import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  IconGridPackage,
  IconProducts,
} from '../../../common/icons/customIcons';
import { ColumnCellInner } from '../../../common/styles/Common.styled';
import {
  ColumnHeaderPackage,
  PackageContainer,
  PackageLine,
} from '../Catalog.styled';
import { gtinDisplay } from '../../../common/utils/utils';
import { Tooltip } from '@material-ui/core';

export default class PackagesCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.packages, nextProps.packages);
  }

  mapPackages = (grid) => {
    return (
      _.map(this.props.packages, (p, index, arr) => (
        <PackageLine key={`${index}_${p.type}_${p.gtin}`} style={grid && index > 1 ? { display: 'none' } : { display: 'inherit' }}>
          {p.type === 'group' && (
            <React.Fragment>
              <IconGridPackage grid={grid} />{' '}
              <span>{gtinDisplay(p.gtin).padStart(14, '0')}{(grid && index === 1 && arr.length > 2) ? '...' : ''}</span>
            </React.Fragment>
          )}
          {p.type === 'consumer' && (
            <React.Fragment>
              <IconProducts grid={grid} />{' '}
              <span>{gtinDisplay(p.gtin).padStart(14, '0')}{(grid && index === 1 && arr.length > 2) ? '...' : ''}</span>
            </React.Fragment>
          )}
        </PackageLine>
      ))
    )
  }

  render() {
    return (
      <ColumnHeaderPackage>
        <ColumnCellInner>
          {this.props.packages.length > 2
            ? (
              <Tooltip arrow placement="bottom-start" title={this.mapPackages()}>
                <PackageContainer>
                  {this.mapPackages('grid')}
                </PackageContainer>
              </Tooltip>
            )
            : (
              <PackageContainer>
                {_.isEmpty(this.props.packages)
                  ? '—'
                  : this.mapPackages('grid')}
              </PackageContainer>
            )
          }
        </ColumnCellInner>
      </ColumnHeaderPackage>
    );
  }
}

PackagesCell.propTypes = {
  packages: PropTypes.array.isRequired,
};
