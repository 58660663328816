import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import EditSubAccountItem from './EditSubAccountItem';
import { updateSubAccount} from '../../../ducks/SubAccount/SubAccount.actions';
import { IconClose } from '../../../../../common/icons/customIcons';
import * as selectors from '../../../ducks/Profile/Profile.selectors';
import { throwNotification } from '../../../../../common/structure';
import {
  defaultTheme,
  DialogTitleStyled,
} from '../../../../../common/styles/Common.styled';
import { CreationCloseBtns } from '../../../Profile.styled';
import { SubAccountDialog } from '../styled/AssignSubAccountModal.styled';

class EditSubAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subAccount: {
        id: _.uniqueId('companyId_'),        
        name: this.props.currentSubAccount[0].Name,        
               
        direction: {
          address:this.props.currentSubAccount[0].Address,
          country:this.props.currentSubAccount[0].Country,
        },
      },
      errors: {},
      validation: false,
    };
  }

  updateItem = (id, fieldData) => {
    let subAccount = _.cloneDeep(this.state.subAccount);
    if (subAccount.id === id) subAccount = _.assign({}, subAccount, fieldData);

    if (subAccount.direction) {
      subAccount.address = subAccount.direction.address;
      subAccount.country = subAccount.direction.country;
    }
    this.setState({ subAccount });
  };



  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const nextSubAccaunt = nextProps.currentSubAccount[0]
    
  // const data = {
  //   gcp: nextSubAccaunt.GCP,
  //   gln: nextSubAccaunt.GLN,
  //   name: nextSubAccaunt.Name,
  //   inn: nextSubAccaunt.ITN,
  //   direction:{      
  //     address: nextSubAccaunt.Address,
  //     сountry: nextSubAccaunt.Country,
  //   }
  // }

//   return {
//     subAccount: { ...prevState.subAccount, ...data }
//   }
// }
 
  // for using in future
  // deleteItem = (id) => {
  //     const subAccount = _.reject(this.state.subAccount, {id: id});
  //     const errors = _.omit(this.state.errors, id);
  //     this.setState({subAccount, errors: errors});
  // };

  handleErrors = (id, error) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [id]: error },
    }));
  };
  // gcpIsValid= () => {  
  //   const { subAccount } = this.state;  
  //   const gcpArr = ((_.toString(subAccount.gcp)).split(", "));
  //   let result = true;
  
  //   for (let i = 0; i < gcpArr.length; i++) { 
  //     const gcpIsValid = _.includes(
  //       [4, 5, 6, 7, 8, 9], _.size(gcpArr[i]),
  //     );    
  //     result = gcpIsValid;    
  //   }
  //   return result;
  // };
   
  gcpIsValid = (gcp) => _.includes([4, 5, 6, 7, 8, 9], _.size(_.toString(gcp)));

  isButtonDisabled = () => {
    const { subAccount, validation } = this.state;
    let result = true;

    if (
      
      !_.isEmpty(subAccount.name) ||
      !_.isEmpty(subAccount.direction
          ? subAccount.direction.country
          : subAccount.country,
      ) &&
      !_.isEmpty(subAccount.direction
          ? subAccount.direction.address
          : subAccount.address,
      )
      
    ) {
      if (validation) result = false;
    } else 

    return result;
  };

  updateSubAccount = () => {
    const {
      t,
      throwError,        
      updateSubAccount,
      handleClose,
      changeGridParams,
      currentSubAccount,
    } = this.props;
    const { subAccount } = this.state;
    const subAccountId =currentSubAccount[0].AccountId;
    
    let data = { ...subAccount };
    delete data.direction;
    
    updateSubAccount(subAccount, subAccountId, (errors) => {     
  
      if (_.isNil(errors)) {        
        handleClose();
        changeGridParams();
      }
    });
  };

  // for using in future
  // innBusy = (value) => {
  //     const item = _.find(this.state.subAccount, {inn: value.inn});
  //     return (item) ? item.id !== value.id : false;
  // };

  onValidateCensorship = (uidField, errorText) => {
    this.setState((prevState) => {
      const booleanValue = !!errorText;
      const censorshipErrors = booleanValue
        ? { ...prevState.censorshipErrors, [uidField]: booleanValue }
        : _.omit(prevState.censorshipErrors, [uidField]);
      return { censorshipErrors };
    });
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  validCbk = (bool) => this.setState({ validation: bool });

  render() {
    const { handleClose, t, countries, currentSubAccount } = this.props;
    const { subAccount } = this.state;
    const disabledButtonUpdateSubAccount = this.isButtonDisabled();

    return (
      <MuiThemeProvider theme={SubAccountDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>{t('Карточка субаккаунта')}</DialogTitleStyled>
            <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={handleClose}>
                <IconClose />
              </Button>
            </MuiThemeProvider>
          </DialogTitle>

          <DialogContent>
            <MuiThemeProvider key={subAccount.id} theme={defaultTheme}>
              <EditSubAccountItem
                key={subAccount.id}
                data={subAccount}
                // isInnBusy={this.innBusy(this.state.subAccount)}
                updateItem={this.updateItem}
                deleteItem={this.deleteItem}
                handleErrors={this.handleErrors}
                onValidate={this.onValidateCensorship}
                updateData={this.updateData}
                validCbk={this.validCbk}
                countries={countries}
                currentSubAccount={currentSubAccount}
              />
            </MuiThemeProvider>
          </DialogContent>

          <DialogActions>
            <Button
              color={'primary'}
              disabled={disabledButtonUpdateSubAccount}
              onClick={this.updateSubAccount}>
              {t('Подтвердить')}
            </Button>
            <Button color={'secondary'} onClick={handleClose}>
              {t('Отменить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

EditSubAccount.propTypes = {
  handleClose: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateSubAccount: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  info: selectors.info(state),
  data: selectors.data(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateSubAccount: (data, id, cbk) => updateSubAccount(data, id, cbk),
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(EditSubAccount);
