import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';

export const PackInfoHelperCont = styled.div`
  padding-right: 30px;
  position: relative;
`;
export const GridItemA1 = styled.div`
  grid-column: col 1 / span 1;
`;
export const GridItemB1 = styled.div`
  grid-column: col 2 / span 1;
`;
export const GridItemC1 = styled.div`
  grid-column: col 3 / span 1;
`;
export const GridItemD1 = styled.div`
  grid-column: col 4 / span 1;
`;

export const packageSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
        },
      },
      select: {
        padding: '23px 19px 6px',
      },
      selectMenu: {
        color: '#52535A',
        fontWeight: '600',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      root: {
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
          borderLeft: '1px solid #D9D9D9 !important',
          "&$error": {
            borderLeft: '1px solid #FF4B4B !important',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
        color: '#868686',
      },
      shrink: {
        color: '#868686',
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '15px 20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
  },
});
