import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { defaultTo, find, get, isEmpty, isNull, map, toUpper } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import {
  IconClose,
  IconDate,
  IconLogoDropDown,
} from '../../../../common/icons/customIcons';
import { ProductSearchBtns } from '../Product/Product.styled';
import {
  defaultTheme,
  DialogTitleContent,
  DialogTitleStyled,
} from '../../../../common/styles/Common.styled';
import {
  NewSearchDialog,
  ProductDateIcon,
  productSearchDate,
  productSearchSelect,
} from './SearchParameters.styled';
import PopupSearchModal from '../PopupSearchModal';
import { searchTypes } from '../../../../common/constants/Constants';
import DateFilter from '../../../products/productGrid/filters/DateFilter';
import { CustomInput, ModalLoader } from '../../../../components';
import { loadProductsCategories } from '../../../../common/catalog/Catalog.action';
import { searchStateCertificate } from '../../ducks/Product.actions';
import { config, selectLang } from '../../../../config';

const getPlaceholder = (queryType, searchTypes) => {
  if (isEmpty(queryType)) {
    return 'Номер свидетельства';
  }
  const text = find(searchTypes, { id: queryType }).text;
  return text === 'Номер документа' ? 'Номер свидетельства' : text;
};

class SearchParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTypes: searchTypes,
      data: {
        queryType: 'DocNumb',
        queryValue: defaultTo(this.props.value, ''),
        code: null,
        dateBegin: null,
        dateEnd: null,
      },
      modal: {
        resultModalOpen: false,
        isOpenSearch: false,
      },
    };

    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  componentDidMount() {
    if (isEmpty(this.props.productsCategories)) {
      this.props.loadProductsCategories(this.props.categoryId);
    }
  }

  resetForm = () => {
    const data = {
      queryType: 'DocNumb',
      queryValue: '',
      code: null,
      dateBegin: null,
      dateEnd: null,
    };
    this.setState({ data: data, selected: {} });
  };

  searchStateCertificate = () => {
    this.setState((prevState) => ({
      modal: { ...prevState.modal, isOpenSearch: true },
    }));
    this.props.searchStateCertificate(this.state.data, (errors) => {
      let modal = { isOpenSearch: false };
      if (!errors) {
        modal = { ...modal, resultModalOpen: true };
      }
      this.setState((prevState) => ({
        modal: { ...prevState.modal, ...modal },
      }));
    });
  };

  handleModalClose = (closeSearchParameters) => {
    this.setState((prevState) => ({
      modal: { ...prevState.modal, resultModalOpen: false },
    }));
    if (closeSearchParameters) {
      this.props.handleClose();
    }
  };

  handleChange = (name, value) => {
    if (name === 'date') {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          dateBegin: moment(value.from).toISOString(),
          dateEnd: moment(value.to).toISOString(),
        },
      }));
    } else {
      this.setState((prevState) => ({
        data: { ...prevState.data, [name]: value },
      }));
    }
  };

  chooseCertificate = (item) => {
    this.props.chooseCertificate(item);
  };

  render() {
    const { productsCategories, t } = this.props;
    const { searchTypes, data } = this.state;

    const code = defaultTo(data.code, '');
    const queryType = defaultTo(data.queryType, '');
    const queryValue = get(data, 'queryValue', '');
    const placeholder = getPlaceholder(queryType, searchTypes);

    return (
      <MuiThemeProvider theme={NewSearchDialog}>
        {this.state.modal.resultModalOpen && (
          <PopupSearchModal
            handleClose={this.handleModalClose}
            chooseCertificate={this.chooseCertificate}
            resetForm={this.resetForm}
          />
        )}
        <Dialog open>
          <DialogTitle>
            <DialogTitleContent>
              <DialogTitleStyled>
                {t('Свидетельство о государственной регистрации')}
              </DialogTitleStyled>
              <Button onClick={this.props.handleClose}>
                <IconClose />
              </Button>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            {this.state.modal.isOpenSearch && (
              <ModalLoader
                text={`${toUpper(t('Пожалуйста, подождите, идет поиск'))} ...`}
              />
            )}

            <MuiThemeProvider theme={productSearchSelect}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="select-search-type">
                  {t('Выберите поле для поиска')} …
                </InputLabel>
                <Select
                  value={queryType}
                  inputProps={{ id: 'select-search-type' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange('queryType', event.target.value)
                  }>
                  {map(searchTypes, (item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {t(item.text)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>
            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                required
                id="input-search-value"
                name="search-value"
                type="text"
                value={queryValue}
                valid={!!queryValue}
                label={t(placeholder)}
                onUpdate={() => {}}
                onChange={(value) => this.handleChange('queryValue', value)}
              />
            </MuiThemeProvider>
            <MuiThemeProvider theme={productSearchSelect}>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="select-category">
                  {t('Категории продукции')} …
                </InputLabel>
                <Select
                  value={code}
                  inputProps={{ id: 'select-category' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange('code', event.target.value)
                  }>
                  {map(productsCategories, (item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>
            <MuiThemeProvider theme={productSearchDate}>
              <FormControl
                key="input-date"
                id="dateGridFilter"
                fullWidth={true}>
                <DateFilter
                  parentId="dateGridFilter"
                  currentValue={
                    isNull(data.dateBegin)
                      ? { from: null, to: null }
                      : { from: data.dateBegin, to: data.dateEnd }
                  }
                  onAccept={(name, range) => this.handleChange('date', range)}
                  onDismiss={() =>
                    this.handleChange('date', { from: '', to: '' })
                  }
                  showButton={false}
                />
                <ProductDateIcon>
                  <IconDate />
                </ProductDateIcon>
              </FormControl>
            </MuiThemeProvider>
          </DialogContent>
          <DialogActions>
            <ProductSearchBtns>
              <Button
                style={{ minWidth: '200px', height: '50px', marginTop: '15px' }}
                disabled={
                  (isEmpty(queryValue) || queryValue.length < 2) &&
                  isEmpty(code) &&
                  isEmpty(data.dateBegin)
                }
                onClick={this.searchStateCertificate}
                color={'primary'}>
                {t('Найти')}
              </Button>
              <Button color={'secondary'} onClick={this.resetForm}>
                {t('Сбросить')}
              </Button>
            </ProductSearchBtns>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

SearchParameters.propTypes = {
  chooseCertificate: PropTypes.func.isRequired,
  categoryId: PropTypes.number.isRequired,
  value: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  productsCategories: state.catalogReducer.productsCategories,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      searchStateCertificate: (data, cbk) => searchStateCertificate(data, cbk),
      loadProductsCategories: (categoryId) =>
        loadProductsCategories(categoryId),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SearchParameters);
