import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { BigNumber } from 'bignumber.js';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import _ from 'lodash';
import { PackageInformationItem } from './itemElement';
import {
  loadDictionaryPackageMaterial,
  loadDictionaryPackageType,
  loadDictionaryPalletType,
  loadDictionaryShowboxDisplaySign,
  loadDictionaryPackageLetterNumeralCode,
  loadDictionaryPackageTypesCapping,
  loadDictionaryPackageMaterialsCapping,
} from '../../../common/catalog/Catalog.action';
import { throwNotification } from '../../../common/structure';
import { clearTransaction } from '../ducks/Product.actions';
import {
  PackInfoItem,
  PackInfoItemMax,
  PackInfoItemMin,
} from './Product/Product.styled';
import {
  IconDropDownGrey,
  IconLogoDropDown,
  IconPlus,
} from '../../../common/icons/customIcons';
import {
  getPackagingType,
  PACKAGING_TYPE,
} from '../../../common/constants/Packaging';
import {
  AdresFields,
  AdresTitle,
  AdresTitleH3,
  CompanyBlock,
  defaultSelectTheme,
  PackageTitleHolder,
  ProductButton,
  ProductTabBtn,
} from '../../../common/styles/Common.styled';
import { gtinDisplay } from '../../../common/utils/utils';

// Список обязательный для заполнения полей, ранее включал почти все поля ВГХ, теперь дополняется данными с бэка
let REQUIRED_FIELDS_BY_LEVEL = {
  'trade-unit': ['gtin'],
  'inner-pack': ['gtin'],
  box: ['gtin'],
  pallet: ['gtinInner'],
  'second-pack': ['gtin'],
  '': [],
};

// Список упаковок которые недоступны
const alwaysHideLevels = ['trade-unit', 'pallet', 'inner-pack', 'box'];
// const alwaysHideLevels = ['trade-unit', 'pallet'];

// Список полей ВГХ для валидации ошибок
const wdhKeys = ['weight', 'height', 'length', 'width', 'capacity'];

function checkOnFill(data, errors) {
  errors.id = data.id;
  let fields = REQUIRED_FIELDS_BY_LEVEL[data.level];
  let keys = Object.keys(data);
  let vghListNames = [];

  if (_.includes(keys, 'vghList')) {
    vghListNames = _.map(data.vghList, 'name');
    keys = _.concat(keys, vghListNames);
    _.pull(keys, 'vghList');
  }

  /**
   * костыль, т.к. поля packageMaterialCapping и packageTypeCapping не должны быть обязательными.
   * а еще как работает required_fields_by_level.
   */
  vghListNames = _.filter(
    vghListNames,
    (item) =>
      item !== 'packageMaterialCapping' && item !== 'packageTypeCapping',
  );
  fields = _.filter(
    fields,
    (item) =>
      item !== 'packageMaterialCapping' && item !== 'packageTypeCapping',
  );

  let notFill = false;
  _.forEach(fields, (key) => {
    if (_.includes(keys, key)) {
      let value;
      if (_.includes(vghListNames, key)) {
        value = _.find(data.vghList, { name: key }).value;
      } else {
        value = data[key];
      }

      if (
        (typeof value === 'number' && value === 0) ||
        (typeof value === 'string' && value === '') ||
        (typeof value === 'object' && value === {}) ||
        _.isNil(value)
      ) {
        notFill = true;
        errors[key] = true;
      } else {
        if (_.includes(fields, key)) {
          errors[key] = false;
        }
      }
    }
  });
  return notFill;
}

const ITEM = {
  id: 0,
  level: 'trade-unit',
  name: null,
  purpose: null,
  gtin: null,
  gtinType: 1,
  gtinInner: null,
  hasShowBox: null,
  vghList: [
    { id: 2710, name: 'packageType', value: '', isRequired: false },
    { id: 2713, name: 'packageMaterial', value: '', isRequired: false },
    { id: 15761, name: 'packageMaterialCapping', value: '', isRequired: false },
    { id: 15759, name: 'packageTypeCapping', value: '', isRequired: false },
  ],
  palletType: null,
  length: null,
  width: null,
  height: null,
  weight: null,
  capacity: null,
  multiplier: null,
  layerCount: null,
  on1stLayer: null,
  unitType: 'шт',
  alphabetCode: null,
  digitCode: null,
};

const getErrors = (moderateErrors, identifiers, t) => {
  if (_.isNil(moderateErrors)) {
    return {};
  }
  let errors = {};
  let identErrors = _.filter(moderateErrors, { type: 'identifier' });
  if (!identErrors) {
    return {};
  }
  _.forEach(identErrors, (identError) => {
    const identifier = _.find(identifiers, {
      gtin: identError.data.gtin,
      level: identError.data.level,
    });
    const identifierSecondPack = _.find(identifiers, {
      gtin: '',
      level: '',
    });

    if (!!identifier) {
      if (errors[identifier.id]) {
        if (_.isNil(identError.data.field)) {
          if (errors[identifier.id].comment) {
            errors[identifier.id].comment = `${errors[identifier.id].comment
              }; ${identError.data.comment}`;
          } else {
            _.assign(errors[identifier.id], {
              comment: identError.data.comment,
            });
          }
        } else {
          _.assign(errors[identifier.id], {
            [identError.data.field]: identError.data.comment,
          });
          switch (identError.data.field) {
            case 'width':
              addError(errors, identError, identifier.id, t('Ширина'));
              break;
            case 'height':
              addError(errors, identError, identifier.id, t('Высота'));
              break;
            case 'length':
              addError(errors, identError, identifier.id, t('Глубина'));
              break;
            case 'weight':
              addError(errors, identError, identifier.id, t('Вес брутто'));
              break;
            default:
              break;
          }
        }
      } else {
        if (_.isNil(identError.data.field)) {
          errors[identifier.id] = {
            comment: identError.data.comment,
          };
        } else {
          errors[identifier.id] = {
            [identError.data.field]: identError.data.comment,
          };
          switch (identError.data.field) {
            case 'width':
              addError(errors, identError, identifier.id, t('Ширина'));
              break;
            case 'height':
              addError(errors, identError, identifier.id, t('Высота'));
              break;
            case 'length':
              addError(errors, identError, identifier.id, t('Глубина'));
              break;
            case 'weight':
              addError(errors, identError, identifier.id, t('Вес брутто'));
              break;
            default:
              break;
          }
        }
      }
    } else if (errors[identifierSecondPack.id]) {
      if (_.isNil(identError.data.field)) {
        if (errors[identifierSecondPack.id].comment) {
          errors[identifierSecondPack.id].comment = `${errors[identifierSecondPack.id].comment
            }; ${identError.data.comment}`;
        } else {
          _.assign(errors[identifierSecondPack.id], {
            comment: identError.data.comment,
          });
        }
      } else {
        _.assign(errors[identifierSecondPack.id], {
          [identError.data.field]: identError.data.comment,
        });

        switch (identError.data.field) {
          case 'width':
            addError(errors, identError, identifierSecondPack.id, t('Ширина'));
            break;
          case 'height':
            addError(errors, identError, identifierSecondPack.id, t('Высота'));
            break;
          case 'length':
            addError(errors, identError, identifierSecondPack.id, t('Глубина'));
            break;
          case 'weight':
            addError(errors, identError, identifierSecondPack.id, t('Вес брутто'));
            break;
          default:
            break;
        }
      }
    } else {
      if (_.isNil(identError.data.field)) {
        errors[identifierSecondPack.id] = {
          comment: identError.data.comment,
        };
      } else {
        errors[identifierSecondPack.id] = {
          [identError.data.field]: identError.data.comment,
        };
        switch (identError.data.field) {
          case 'width':
            addError(errors, identError, identifierSecondPack.id, t('Ширина'));
            break;
          case 'height':
            addError(errors, identError, identifierSecondPack.id, t('Высота'));
            break;
          case 'length':
            addError(errors, identError, identifierSecondPack.id, t('Глубина'));
            break;
          case 'weight':
            addError(errors, identError, identifierSecondPack.id, t('Вес брутто'));
            break;
          default:
            break;
        }
      }
    }
  });
  return errors;
};

const addError = (errors, identError, id, value) => {
  if (errors[id]) {
    if (errors[id].comment) {
      errors[
        id
      ].comment = `${errors[id].comment}; \r\n ${value}: ${identError.data.comment}`;
    } else {
      _.assign(errors[id], { comment: `${value}: ${identError.data.comment}` });
    }
  } else {
    errors[id] = {
      comment: `${value}: ${identError.data.comment}`,
    };
  }
};

class PackagingInformation extends React.Component {
  constructor(props) {
    super(props);

    const validation = [];
    const errors = [];
    const identifiers = _.map(
      this.props.productData.identifier,
      (identifier, index) => {
        // Дополняем список обязательных для заполнения полей
        // _.forEach(identifier.vghList, (i) => {
        //   if (
        //     i.isRequired &&
        //     !_.includes(REQUIRED_FIELDS_BY_LEVEL[identifier.level], i.name)
        //   ) {
        //     REQUIRED_FIELDS_BY_LEVEL[identifier.level].push(i.name);
        //   }
        // }); // настройка обязательных полей до 462 (439) задачи

        _.forEach(identifier.required, (i) => {       // 462 (439) задача  
          if (!_.includes(REQUIRED_FIELDS_BY_LEVEL[identifier.level], i)) {
            REQUIRED_FIELDS_BY_LEVEL[identifier.level].push(i);
          }
        }
        );

        identifier.id = index;
        let error = {};
        validation.push({
          id: identifier.id,
          notFill: checkOnFill(identifier, error),
        });
        errors.push(_.omit(error, 'id'));
        return identifier;
      },
    );

    this.state = {
      contentShow: true,
      validation: validation,
      data: {
        packaging: identifiers,
      },
      errors: [],
      level: '',
    };

    this.props.handleErrorsArray(errors);
    this.props.updateData('identifier', identifiers);
  }

  componentDidMount() {
    if (_.isEmpty(this.props.dictionaryShowboxDisplaySigns)) {
      this.props.loadDictionaryShowboxDisplaySign();
    }
    if (_.isEmpty(this.props.dictionaryPackageMaterials)) {
      this.props.loadDictionaryPackageMaterial();
    }
    if (_.isEmpty(this.props.dictionaryPackageTypes)) {
      this.props.loadDictionaryPackageType();
    }
    if (_.isEmpty(this.props.dictionaryPalletTypes)) {
      this.props.loadDictionaryPalletType();
    }
    if (_.isEmpty(this.props.dictionaryPackageLetterNumeralCode)) {
      this.props.loadDictionaryPackageLetterNumeralCode();
    }
    if (_.isEmpty(this.props.dictionaryPackageTypesCapping)) {
      this.props.loadDictionaryPackageTypesCapping();
    }
    if (_.isEmpty(this.props.dictionaryPackageMaterialsCapping)) {
      this.props.loadDictionaryPackageMaterialsCapping();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (_.isEmpty(prevState.errors) && !_.isEmpty(nextProps.moderateErrors)) {
      return {
        errors: getErrors(
          nextProps.moderateErrors,
          prevState.data.packaging,
          nextProps.t,
        ),
      };
    }
    return null;
  }

  componentWillUnmount() {
    REQUIRED_FIELDS_BY_LEVEL = {
      'trade-unit': ['gtin'],
      'inner-pack': ['gtin'],
      box: ['gtin'],
      pallet: ['gtinInner'],
      'second-pack': ['gtin'],
      '': [],
    }
  }

  onClickHide = () => {
    this.setState({ contentShow: !this.state.contentShow });
  };

  handleChange = (name, value) => {
    this.setState((prevState) => ({
      data: { ...prevState.data, [name]: value },
    }));
  };

  handleChangeLevel = (value) => {
    const throwError = _.flow([this.props.t, this.props.throwError]);
    const levels = _.map(this.state.data.packaging, (pack) => pack.level);

    if (value === 'trade-unit' && levels.includes('trade-unit')) {
      throwError(
        'Невозможно добавить две потребительские упаковки для одного товара',
      );
    } else if (value === 'pallet' && !levels.includes('box')) {
      throwError(
        'Уровень «Паллета» невозможно создать без описанного уровня «Транспортная упаковка»',
      );
    } else if (value === 'inner-pack' && levels.includes('box')) {
      throwError(
        'Уровень «Групповая потребительская упаковка» невозможно создать после описанного уровня «Транспортная упаковка»',
      );
    } else {
      this.setState({ level: value });
    }
  };

  addItem = () => {
    const packaging = _.cloneDeep(this.state.data.packaging);
    // const validation = _.cloneDeep(this.state.validation);
    // validation.push({id: packaging.length, notFill: true});
    delete packaging.level;

    packaging.push(
      _.assign({}, ITEM, {
        id: packaging.length,
        level: '',
        gtin: '',
        hideStatisticButtons: true,
      }),
    );

    let errors = {};
    checkOnFill(packaging[packaging.length - 1], errors);
    errors = _.omit(errors, 'id');
    this.props.handleErrors('identifier', errors, packaging.length - 1);
    this.setState({ data: { packaging: packaging }, level: '' });
  };

  /*
   * костыль для обновления letterCode & digitCode
   */
  updateConnectedFields = (id, updateObject) => {
    let packaging = _.cloneDeep(this.state.data.packaging);
    _.forEach(packaging, (v, k) => {
      if (v.id === id) {
        _.assign(v, updateObject);
      }
    });

    this.setState({ data: { packaging } }, () => {
      this.props.updateData('identifier', this.state.data.packaging);
    });
  };

  updateItem = (id, fieldData, gtinStatus) => {
    let packaging = _.cloneDeep(this.state.data.packaging);
    let pack = {};
    _.forEach(packaging, (v, k) => {
      if (v.id === id) {
        packaging[k] = _.assign({}, v, fieldData);

        if (
          (!_.isUndefined(fieldData.length) ||
            !_.isUndefined(fieldData.width) ||
            !_.isUndefined(fieldData.height)) &&
          _.includes(
            ['trade-unit', 'second-pack', 'box', 'inner-pack', ''],
            v.level,
          ) // Расчет объема только для потребительской упаковки
        ) {
          packaging[k].capacity = _.ceil(
            BigNumber(packaging[k].length)
              .multipliedBy(BigNumber(packaging[k].width))
              .multipliedBy(Number(packaging[k].height)),
          );
        }

        if (v.level === 'box' || v.level === 'inner-pack') {
          const limites = this.getLimites(packaging[k]);

          if (
            !_.isUndefined(fieldData.multiplier) ||
            !_.isUndefined(fieldData.gtinInner)
          ) {
            packaging[k].capacity =
              limites.mincapacity === 0 ? null : _.ceil(limites.mincapacity);
            packaging[k].weight =
              limites.minweight === 0 ? null : limites.minweight;
          }
        }

        if (v.level === 'pallet') {
          const limites = this.getLimites(packaging[k]);
          if (
            !_.isUndefined(fieldData.layerCount) ||
            !_.isUndefined(fieldData.gtinInner)
          ) {
            packaging[k].height =
              limites.minheight === 0 ? null : limites.minheight;
          }

          if (
            !_.isUndefined(fieldData.multiplier) ||
            !_.isUndefined(fieldData.layerCount)
          ) {
            packaging[k].on1stLayer = _.ceil(
              Number(packaging[k].multiplier) / Number(packaging[k].layerCount),
            );
          }

          if (
            !_.isUndefined(fieldData.multiplier) ||
            !_.isUndefined(fieldData.gtinInner)
          ) {
            packaging[k].weight =
              limites.minweight === 0 ? null : limites.minweight;
          }
        }

        pack = _.cloneDeep(packaging[k]);
      }
    });
    let validation = _.cloneDeep(this.state.validation);
    let errors = {};

    let notFilled = checkOnFill(pack, errors);
    _.forEach(validation, (v, k) => {
      if (v.id === id) {
        validation[k].notFill = notFilled;
      }
    });
    errors = _.omit(errors, 'id');
    if (!_.isNil(gtinStatus)) {
      errors.status = gtinStatus;
    }
    this.props.handleErrors('identifier', errors, id);
    this.setState({ data: { packaging }, validation: validation }, () => {
      this.props.updateData('identifier', this.state.data.packaging);
    });
  };

  handleWdhErrors = (id, errors) => {
    const packaging = _.cloneDeep(this.state.data.packaging);
    let wdhErrors = {};
    let pack = {};
    _.forEach(packaging, (v, k) => {
      if (v.id === id) {
        pack = _.cloneDeep(packaging[k]);
        _.forEach(wdhKeys, (key) => {
          wdhErrors[key] = !_.isEmpty(errors[key]);
        });
      }
    });

    let check = {};
    checkOnFill(pack, check);
    check = _.omit(check, 'id');
    check = _.assign(check, wdhErrors);
    this.props.handleErrors('identifier', check, id);
  };

  deleteIt = (id, toDelete, packaging) => {
    let modifiedPackaging;
    // switch (toDelete.level) {
    //     case "inner-pack": {
    //         const gtinBoxs = _.map(_.filter(packaging, {level: "box", gtinInner: toDelete.gtin}), 'gtin');
    //         modifiedPackaging = _.reject(packaging, item =>
    //             item.id === id
    //             || (item.level === "box" && item.gtinInner === toDelete.gtin)
    //             || (item.level === "pallet" && _.includes(gtinBoxs, item.gtinInner))
    //         );
    //         break;
    //     }
    //     case "box": {
    //         modifiedPackaging = _.reject(packaging, item =>
    //             item.id === id || (item.level === "pallet" && item.gtinInner === toDelete.gtin)
    //         );
    //         break;
    //     }
    //     case "pallet": {
    //         modifiedPackaging = _.reject(packaging, {id: id});
    //         break;
    //     }
    //     default:
    //         modifiedPackaging = packaging;
    //         break;
    // }
    modifiedPackaging = _.reject(packaging, { id: id });

    let errors = [];
    _.forEach(modifiedPackaging, (packItem) => {
      let error = {};
      checkOnFill(packItem, error);
      errors.push(_.omit(error, 'id'));
    });

    const ids = _.map(modifiedPackaging, 'id');
    this.setState(
      (prevState) => ({
        data: { ...prevState.data, packaging: modifiedPackaging },
        validation: _.filter(this.state.validation, (item) =>
          _.includes(ids, item.id),
        ),
      }),
      () => {
        this.props.handleErrorsArray(errors);
        this.props.updateData('identifier', this.state.data.packaging);
      },
    );
  };

  deleteItem = (id) => {
    const packaging = _.cloneDeep(this.state.data.packaging);
    const toDelete = this.getById(packaging, id);
    //если есть гтин - освобождаем, затем удаляем
    this.deleteIt(id, toDelete, packaging);
  };

  getById = (items, id) => _.find(items, { id: id });

  getByGtin = (items, gtin) =>
    _.find(items, (o) => Number(o.gtin) === Number(gtin));

  getChildGtinsByType = (level) => {
    const { t } = this.props;
    const p = _.map(this.state.data.packaging, (item) => {
      let condition;
      switch (level) {
        case 'inner-pack': {
          condition = item.level === 'trade-unit';
          break;
        }
        case 'box': {
          condition = item.level !== 'pallet' && item.level !== 'box';
          break;
        }
        case 'pallet': {
          condition = item.level !== 'pallet';
          break;
        }
        default:
          condition = false;
          break;
      }
      return condition
        ? {
          text: `${t(getPackagingType(item.level))}: ${gtinDisplay(
            item.gtin,
          )}`,
          gtin: item.gtin,
        }
        : { gtin: undefined };
    });
    return _.filter(p, (item) => !_.isUndefined(item.gtin));
  };

  getLimites = (pack) => {
    let limites = {
      minweight: 0,
      mincapacity: 0,
      minheight: 0,
    };

    if (pack.level === 'trade-unit' || pack.level === 'second-pack') {
      if (!!pack.width && !!pack.height && !!pack.length) {
        limites.mincapacity = BigNumber(pack.width).multipliedBy(
          BigNumber(pack.height).multipliedBy(BigNumber(pack.length)),
        );
      }
    } else if (!!pack.gtinInner) {
      const child = this.getByGtin(this.state.data.packaging, pack.gtinInner);
      if (!_.isUndefined(child)) {
        if (!!pack.multiplier) {
          if (!!child.weight) {
            limites.minweight = BigNumber(child.weight).multipliedBy(
              BigNumber(pack.multiplier),
            );
          }
          if (pack.level !== 'pallet' && !!child.capacity) {
            limites.mincapacity = BigNumber(child.capacity).multipliedBy(
              BigNumber(pack.multiplier),
            );
          }
        }
        if (pack.level === 'pallet' && !!child.height && !!pack.layerCount) {
          limites.minheight = BigNumber(child.height).multipliedBy(
            BigNumber(pack.layerCount),
          );
          limites.mincapacity = BigNumber(child.height).multipliedBy(
            BigNumber(pack.layerCount),
          );
        }
      }
    }
    return limites;
  };

  render() {
    const { onValidate, t } = this.props;
    const canAdd = !_.reduce(
      _.map(this.state.validation, (v) => v.notFill),
      (sum, n) => sum || n,
      false,
    );
    const GTINString = _.toString(_.get(this.props, 'productData.gtin'));

    const selectedLevels = _.map(this.state.data.packaging, 'level');
    const displayedLevels = _.reject(PACKAGING_TYPE, (i) =>
      _.includes(_.concat(selectedLevels, alwaysHideLevels), i.level),
    );

    const id = Date.now();

    return (
      <CompanyBlock>
        <AdresTitle>
          <PackageTitleHolder>
            <MuiThemeProvider theme={ProductTabBtn}>
              <Button onClick={this.onClickHide}>
                {this.state.contentShow ? (
                  <IconDropDownGrey />
                ) : (
                  <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                )}
              </Button>
              <AdresTitleH3>{t('Весогабаритные характеристики')}</AdresTitleH3>
            </MuiThemeProvider>
          </PackageTitleHolder>
        </AdresTitle>
        <AdresFields>
          {this.state.contentShow && (
            <React.Fragment>
              {_.map(this.state.data.packaging, (pack) => (
                <Element key={pack.id} name={`id_${pack.id}`}>
                  <PackageInformationItem
                    dictionaryShowboxDisplaySigns={
                      this.props.dictionaryShowboxDisplaySigns
                    }
                    dictionaryPackageMaterials={
                      this.props.dictionaryPackageMaterials
                    }
                    dictionaryPackageTypes={this.props.dictionaryPackageTypes}
                    dictionaryPalletTypes={this.props.dictionaryPalletTypes}
                    dictionaryPackageLetterNumeralCode={
                      this.props.dictionaryPackageLetterNumeralCode
                    }
                    dictionaryPackageTypesCapping={
                      this.props.dictionaryPackageTypesCapping
                    }
                    dictionaryPackageMaterialsCapping={
                      this.props.dictionaryPackageMaterialsCapping
                    }
                    key={pack.id}
                    data={pack}
                    updateItem={this.updateItem}
                    deleteItem={this.deleteItem}
                    handleWdhErrors={this.handleWdhErrors}
                    onValidate={onValidate}
                    gtinTradeUnit={GTINString}
                    gtinInners={this.getChildGtinsByType(pack.level)}
                    handleGtinStatus={this.props.handleGtinStatus}
                    limites={this.getLimites(pack)}
                    errors={this.state.errors[pack.id]}
                    preview={this.props.preview}
                    status={this.props.productData.status}
                    requiredFields={REQUIRED_FIELDS_BY_LEVEL[pack.level]}
                    updateConnectedFields={this.updateConnectedFields}
                    packingPurpose={this.props.productData.packingPurpose}
                    isExemplar={this.props.isExemplar}
                    isCatalogCard={this.props.isCatalogCard} // флаг, что карточка товара открыта из Каталога, служит для блокировки инпутов 
                  />
                </Element>
              ))}

              {!_.isEmpty(displayedLevels) &&
                !_.includes(
                  ['transport', 'group'],
                  this.props.productData.packingPurpose,
                ) &&
                this.state.data.packaging.length < 2 && (
                  <PackInfoItem>
                    <PackInfoItemMax>
                      <FormControl fullWidth={true}>
                        <MuiThemeProvider theme={defaultSelectTheme}>
                          <InputLabel htmlFor={'packaging-select-' + id}>
                            {this.state.level
                              ? t('Назначение упаковки')
                              : t('Добавить назначение упаковки')}
                          </InputLabel>
                          <Select
                            disabled={
                              (this.props.productData.status !== 'draft' &&
                                this.props.isExemplar) ||
                              this.props.preview ||
                              this.props.isCatalogCard
                            }
                            value={this.state.level}
                            inputProps={{ id: `packaging-select-${id}` }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            IconComponent={IconLogoDropDown}
                            onChange={(event) =>
                              this.handleChangeLevel(event.target.value)
                            }>
                            {_.map(displayedLevels, (i) => (
                              <MenuItem key={i.level} value={i.level}>
                                {t(i.text)}
                              </MenuItem>
                            ))}
                          </Select>
                        </MuiThemeProvider>
                      </FormControl>
                    </PackInfoItemMax>

                    {this.state.data.packaging.length < 2 && (
                      <PackInfoItemMin>
                        <MuiThemeProvider theme={ProductButton}>
                          <Button
                            color={'secondary'}
                            disabled={
                              !this.state.level.length ||
                              //this.props.isExemplar ||
                              !canAdd ||
                              this.props.isCatalogCard
                            }
                            onClick={this.addItem}>
                            <IconPlus style={{ marginRight: '15px' }} />{' '}
                            {t('Добавить упаковку')}
                          </Button>
                        </MuiThemeProvider>
                      </PackInfoItemMin>
                    )}
                  </PackInfoItem>
                )}
            </React.Fragment>
          )}
        </AdresFields>
      </CompanyBlock>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
  dictionaryShowboxDisplaySigns:
    state.catalogReducer.dictionaryShowboxDisplaySigns,
  dictionaryPackageTypes: state.catalogReducer.dictionaryPackageTypes,
  dictionaryPackageMaterials: state.catalogReducer.dictionaryPackageMaterials,
  dictionaryPalletTypes: state.catalogReducer.dictionaryPalletTypes,
  dictionaryPackageLetterNumeralCode:
    state.catalogReducer.dictionaryPackageLetterNumeralCode,
  dictionaryPackageTypesCapping:
    state.catalogReducer.dictionaryPackageTypesCapping,
  dictionaryPackageMaterialsCapping:
    state.catalogReducer.dictionaryPackageMaterialsCapping,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadDictionaryShowboxDisplaySign: () =>
        loadDictionaryShowboxDisplaySign(),
      loadDictionaryPackageType: () => loadDictionaryPackageType(),
      loadDictionaryPackageMaterial: () => loadDictionaryPackageMaterial(),
      loadDictionaryPalletType: () => loadDictionaryPalletType(),
      loadDictionaryPackageLetterNumeralCode: () =>
        loadDictionaryPackageLetterNumeralCode(),
      loadDictionaryPackageTypesCapping: () =>
        loadDictionaryPackageTypesCapping(),
      loadDictionaryPackageMaterialsCapping: () =>
        loadDictionaryPackageMaterialsCapping(),
      throwError: (title) => throwNotification(title, 'error'),
      clearTransaction: (gtin, cbk) => clearTransaction(gtin, cbk),
    },
    dispatch,
  );
};

PackagingInformation.propTypes = {
  updateData: PropTypes.func.isRequired,
  handleErrors: PropTypes.func.isRequired,
  handleErrorsArray: PropTypes.func.isRequired,
  handleGtinStatus: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  productData: PropTypes.object,
  moderateErrors: PropTypes.array.isRequired,
  preview: PropTypes.bool.isRequired,
  dictionaryPackageLetterNumeralCode: PropTypes.array.isRequired,
  dictionaryPackageTypesCapping: PropTypes.array.isRequired,
  dictionaryPackageMaterialsCapping: PropTypes.array.isRequired,
  isExemplar: PropTypes.bool.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PackagingInformation);
