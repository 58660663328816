import { Reducer } from 'redux';
import {
  LOAD_TRADE_MARKS_ACTION,
  LOAD_TRADE_MARKS_FAILED_ACTION,
  LOAD_TRADE_MARKS_SUCCEEDED_ACTION,
  ADD_TRADE_MARK_ACTION,
  ADD_TRADE_MARK_ACTION_FAILED,
  ADD_TRADE_MARK_ACTION_SUCCEEDED,
  UPDATE_TRADE_MARK_ACTION,
  UPDATE_TRADE_MARK_ACTION_SUCCEEDED,
  UPDATE_TRADE_MARK_ACTION_FAILED,
  ADD_TRADE_MARK_FILE_ACTION,
  ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED,
  ADD_TRADE_MARK_FILE_ACTION_FAILED,
  DELETE_TRADE_MARK_ACTION,
  DELETE_TRADE_MARK_ACTION_SUCCEEDED,
  DELETE_TRADE_MARK_ACTION_FAILED,
  GET_TRADE_MARK_DOCUMENTS_ACTION,
  GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
  GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
} from './TradeMarks.constants';
import { subtractNotNegative } from '../../../../common/utils/utils';

const _initialState = {
  data: [],
  totalObjects: 0,
  loading: 0,
  images: [],
  imagesLoading: 0,
};

export const tradeMarksReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case DELETE_TRADE_MARK_ACTION:
    case ADD_TRADE_MARK_FILE_ACTION:
    case UPDATE_TRADE_MARK_ACTION:
    case ADD_TRADE_MARK_ACTION:
    case LOAD_TRADE_MARKS_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }

    case LOAD_TRADE_MARKS_FAILED_ACTION: {
      return {
        ...state,
        data: [],
        totalObjects: 0,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_TRADE_MARKS_SUCCEEDED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
        data: action.data,
        totalObjects: action.totalObjects,
      };
    }

    case DELETE_TRADE_MARK_ACTION_FAILED:
    case DELETE_TRADE_MARK_ACTION_SUCCEEDED:
    case ADD_TRADE_MARK_FILE_ACTION_FAILED:
    case ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED:
    case UPDATE_TRADE_MARK_ACTION_FAILED:
    case UPDATE_TRADE_MARK_ACTION_SUCCEEDED:
    case ADD_TRADE_MARK_ACTION_SUCCEEDED:
    case ADD_TRADE_MARK_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    case DELETE_TRADE_MARK_DOCUMENTS_ACTION:
    case GET_TRADE_MARK_DOCUMENTS_ACTION: {
      return {
        ...state,
        imagesLoading: state.imagesLoading + 1,
      };
    }

    case GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED:
    case DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED:
    case DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED:
    case GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED: {
      return {
        ...state,
        imagesLoading: subtractNotNegative(state.imagesLoading),
      };
    }

    default:
      return { ...state };
  }
};
