import * as React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { CustomInput } from '../../../../../components';
import { GridBlockB, GridItem, ProfileDivider } from '../../../Profile.styled';
import {
  GridItemSmall,
  GridItemSmallA,
  GridItemSmallB,
  GridItemC,
} from '../../../../../common/styles/Common.styled';
import { InputRowContainer } from './styled/BankDetails.styled';
/**
 * TODO:
 * отрефакторить.
 */

class BankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (name, value) => {
    const { handleStateChange } = this.props;
    switch (name) {
      case 'bik':
      case 'name': {
        handleStateChange(
          'bankDetails',
          name,
          value === '' ? '' : _.toString(value),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      case 'settlementAccount':
      case 'correspondentAccount': {
        handleStateChange(
          'bankDetails',
          name,
          value === '' ? '' : _.toString(value),
          false,
        );
        break;
      }
      default:
        break;
    }
  };

  render() {
    const { t } = this.props;
    const {
      name,
      bik,
      correspondentAccount,
      settlementAccount,
    } = this.props.bankDetails;

    return (
      <div>
        <h1>{t('Банковские реквизиты')}</h1>
        <GridBlockB>
          <GridItem>
            <CustomInput
              id="name"
              name="name"
              required
              valid={!!name}
              label={t('Наименование банка')}
              value={_.defaultTo(name, '')}
              disabled={false}
              onChange={(value) => this.handleChange('name', value)}
              type="text"
            />
          </GridItem>
          <GridItemSmall>
            <InputRowContainer>
              <CustomInput
                id="bik"
                name="bik"
                required
                valid={!!bik}
                label={t('БИК')}
                value={_.defaultTo(bik, '')}
                disabled={!name}
                maxLength={9}
                onChange={(value) => this.handleChange('bik', value)}
                type="text"
                dirty={true}
              />
            </InputRowContainer>
          </GridItemSmall>

          <GridItemSmallA>
            <InputRowContainer>
              <CustomInput
                id="correspondentAccount"
                name="correspondentAccount"
                required={!settlementAccount}
                valid={!!correspondentAccount}
                label={t('Корр. счет (IBAN)')}
                value={_.defaultTo(correspondentAccount, '')}
                disabled={!bik}
                maxLength={20}
                dirty={true}
                onChange={(value) =>
                  this.handleChange('correspondentAccount', value)
                }
                type="text"
              />
            </InputRowContainer>
          </GridItemSmallA>

          <GridItemSmallB>
            <InputRowContainer>
              <CustomInput
                id="settlementAccount"
                name="settlementAccount"
                required={!correspondentAccount}
                valid={!!settlementAccount}
                label={t('Расчетный счет')}
                value={_.defaultTo(settlementAccount, '')}
                disabled={!bik}
                maxLength={20}
                dirty={true}
                onChange={(value) =>
                  this.handleChange('settlementAccount', value)
                }
                type="text"
              />
            </InputRowContainer>
          </GridItemSmallB>
          <GridItemC>
            <div style={{ textAlign: 'center', color: 'red' }}>
              *{t('Необходимо заполнить оба или одно из полей')}
            </div>
          </GridItemC>
        </GridBlockB>
        <ProfileDivider />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

BankDetails.propTypes = {};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(BankDetails);
