import { Reducer } from 'redux';
import {
  LOAD_SITE_PROFILE_ACTION,
  LOAD_SITE_PROFILE_ACTION_SUCCEEDED,
  LOAD_SITE_PROFILE_ACTION_FAILED,
  SAVE_SITE_PROFILE_ACTION,
  SAVE_SITE_PROFILE_ACTION_SUCCEEDED,
  SAVE_SITE_PROFILE_ACTION_FAILED,
  LOAD_MARKET_FIELDS_ACTION,
  LOAD_MARKET_FIELDS_ACTION_SUCCEEDED,
  LOAD_MARKET_FIELDS_ACTION_FAILED,
} from './SiteProfile.constants';
import { subtractNotNegative } from '../../../../common/utils/utils';

const _initialState = {
  formData: {},
  loading: 0,
  marketFields: [],
};

export const siteProfileReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_SITE_PROFILE_ACTION:
    case SAVE_SITE_PROFILE_ACTION:
    case LOAD_MARKET_FIELDS_ACTION: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case LOAD_SITE_PROFILE_ACTION_SUCCEEDED: {
      return {
        ...state,
        formData: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case LOAD_MARKET_FIELDS_ACTION_SUCCEEDED: {
      return {
        ...state,
        marketFields: action.data,
        loading: subtractNotNegative(state.loading),
      };
    }

    case SAVE_SITE_PROFILE_ACTION_SUCCEEDED:
    case LOAD_SITE_PROFILE_ACTION_FAILED:
    case SAVE_SITE_PROFILE_ACTION_FAILED:
    case LOAD_MARKET_FIELDS_ACTION_FAILED: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    default:
      return { ...state };
  }
};
