import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { CustomInput, PasswordInput } from '../../../components';
import { IconInfo, IconPreloader } from '../../../common/icons/customIcons';
import {
  validateEmail,
  validateEmailOnDirty,
  validatePassword,
} from '../../../common/utils/Validation';
import { HowLink } from '../Authentification.styled';
import {
  BtnContPaddingless,
  InputContainer,
  SelectContLogin,
} from '../../../common/styles/Common.styled';
import ActiveSessionModal from '../modal/ActiveSessionModal';

class AuthentificationLoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      accountId: '',
      emailValid: true,
      emailDirty: false,
      accountIdDirty: false,
    };
  }

  handleChange = (name, value) => {
    let state = cloneDeep(this.state);
    state[name] = value;
    if (name === 'email') state.emailDirty = true;

    this.setState(state);
  };

  onUpdateEmail = (value) => {
    this.setState({ emailValid: validateEmail(value) });
  };

  onRemindPasswordClick = () => {
    if (this.props.loading) return;
    this.setState(
      {
        emailValid: validateEmail(this.state.email),
        emailDirty: true,
        accountIdDirty: true,
      },
      () => {
        if (
          this.state.emailValid &&
          this.state.emailDirty &&
          this.state.accountId
        ) {
          this.props.onRemindPassword({
            email: this.state.email,
            accountId: this.state.accountId,
          });
        }
      },
    );
  };

  render() {
    const { onLogin, t } = this.props;

    return (
      <SelectContLogin>
        {this.props.isActiveSessionOpenModal && (
          <ActiveSessionModal
            activeSessionInfo={this.props.activeSessionInfo}
            handleSubmit={() =>
              onLogin({
                email: this.state.email,
                password: this.state.password,
                accountId: this.state.accountId,
                closeOpenSessions: true,
              })
            }
          />
        )}
        <InputContainer>
          <CustomInput
            id="accountId"
            name="accountId"
            cleanButtonStyles={{ paddingTop: 15 }}
            onChange={(value) => this.handleChange('accountId', value)}
            label={t('Идентификатор  аккаунта компании')}
            placeholder={t('Введите идентификатор аккаунта компании')}
            value={this.state.accountId}
            shrink={true}
            type="number"
            error={!this.state.accountId && this.state.accountIdDirty}
            dirty={this.state.accountIdDirty}
          />
        </InputContainer>
        <InputContainer>
          <CustomInput
            id="email"
            cleanButtonStyles={{ paddingTop: 15 }}
            name="email"
            onChange={(value) => this.handleChange('email', value)}
            onUpdate={(value) => this.onUpdateEmail(value)}
            label={
              !this.state.emailValid
                ? t('Введите корректный E-mail')
                : this.state.email
                  ? t('Ваш E-mail')
                  : t('Введите ваш E-mail')
            }
            placeholder={t('Введите ваш E-mail')}
            autoComplete="email"
            value={this.state.email}
            type="email"
            shrink={true}
            dirty={this.state.emailDirty}
            error={!this.state.emailValid && this.state.emailDirty}
          />
        </InputContainer>
        <InputContainer>
          <PasswordInput
            error={validatePassword(this.state.password)}
            name="password"
            id="password"
            onChange={(value) => this.handleChange('password', value)}
            value={this.state.password}
            label={t('Пароль')}
            placeholder={t('Введите ваш пароль')}
            shrink={true}
          />
        </InputContainer>
        <BtnContPaddingless>
          <Button
            disabled={
              this.props.loading ||
              validateEmailOnDirty(this.state.email) ||
              validatePassword(this.state.password) ||
              !this.state.accountId
            }
            onClick={() =>
              onLogin({
                email: this.state.email,
                password: this.state.password,
                accountId: this.state.accountId,
              })
            }>
            {this.props.loading ? <IconPreloader /> : t('Войти')}
          </Button>

          <HowLink onClick={this.onRemindPasswordClick}>
            <IconInfo style={{ marginRight: '12px' }} /> {t('ЗАБЫЛИ ПАРОЛЬ?')}
          </HowLink>
        </BtnContPaddingless>
      </SelectContLogin>
    );
  }
}

AuthentificationLoginView.propTypes = {
  onLogin: PropTypes.func,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func,
  onRemindPassword: PropTypes.func,
  loading: PropTypes.bool,
  emailValid: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  isActiveSessionOpenModal: PropTypes.bool.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
};

export default withTranslation()(AuthentificationLoginView);
