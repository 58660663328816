import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { IconResetFilterBig } from '../../../../../common/icons/customIcons';
import {
  NoDataIconHolder,
  NoDataInner,
} from '../../../../../common/styles/Common.styled';

class TmNoDataComponent extends React.PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="rt-noData" style={{ height: '50vh' }}>
        <NoDataInner>
          <NoDataIconHolder>
            <IconResetFilterBig />
          </NoDataIconHolder>
          <div>
            {t('Нет данных для отображения')}.
            {_.isFunction(this.props.resetFilters) && (
              <span>
                {t('Выберите другие фильтры')}
                <br />
                {t('или')}{' '}
                <Button onClick={this.props.resetFilters}>
                  {t('сбросьте все фильтры')}
                </Button>
              </span>
            )}
          </div>
        </NoDataInner>
      </div>
    );
  }
}

TmNoDataComponent.propTypes = {
  resetFilters: PropTypes.func,
};

export default withTranslation()(TmNoDataComponent);
