import { Reducer } from 'redux';
import {
  BROWSER_CHECK,
  CLEAR_ERROR,
  GET_CERTIFICATES,
  GET_CERTS_FAILED,
  REG_FAILED,
  REG_REQUEST,
  REG_SUCCESS,
} from './RegistrationActionTypes';

const _initialState = {
  certificates: [],
  errors: [],
  validationErrors: {},
  regSuccess: false,
  requestNumber: '',
  loading: false,
  browserCheck: false,
};

export const registrationReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case REG_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_CERTIFICATES: {
      return { ...state, certificates: action.certificates };
    }
    case GET_CERTS_FAILED: {
      return { ...state };
    }
    case REG_FAILED: {
      return {
        ...state,
        errors: action.errors,
        validationErrors: action.validationErrors,
        loading: false,
      };
    }
    case REG_SUCCESS: {
      return {
        ...state,
        errors: [],
        regSuccess: true,
        requestNumber: action.requestNumber,
        loading: false,
      };
    }
    case CLEAR_ERROR: {
      return { ...state, errors: [], validationErrors: {} };
    }
    case BROWSER_CHECK: {
      return { ...state, browserCheck: true };
    }
    default:
      return { ...state };
  }
};
