import { push } from 'connected-react-router';
import { all, put } from 'redux-saga/effects';
import _ from 'lodash';
import { cancel } from '../../api/Client';
import i18n from '../../i18n';
import { throwNotification } from '../../common/structure';
import { logout } from '../authentication/ducks/Authentification.actions';

export function* handleErrorNotification(responseError, textDefault = '') {
  const errText = i18n.t('Ошибка запроса данных!');

  if (!_.has(responseError, 'response.status')) {
    // yield put(throwNotification(textDefault || errText, 'error'));   
    return;
  }

  switch (responseError.response.status) {
    case 400:
      if (
        responseError.response.config.responseType === 'arraybuffer' &&
        responseError.response.data.toString() === '[object ArrayBuffer]'
      ) {
        const data = JSON.parse(
          Buffer.from(responseError.response.data).toString('utf8'),
        );
        return yield all(
          _.map(data.errors, (i) => put(throwNotification(i, 'error'))),
        );
      }
      if (!_.isEmpty(responseError.response.data.errors)) {
        yield all(
          _.map(responseError.response.data.errors, (i) =>
            put(throwNotification(i, 'error')),
          ),
        );
      }
      break;

    case 401:
      cancel();
      if (!_.isEmpty(responseError.response.data.errors)) {
        yield put(throwNotification(responseError.response.data.errors[0], 'error'));
      } else yield put(throwNotification(i18n.t('Вы не авторизованы!'), 'error'));
      yield put(logout());
      break;

    case 403:
      if (!_.isEmpty(responseError.response.data.errors)) {
        yield all(
          _.map(responseError.response.data.errors, (i) =>
            put(throwNotification(i, 'error')),
          ),
        );
      } else {
        yield put(
          throwNotification(textDefault || i18n.t('Доступ запрещен!'), 'error'),
        );
      }
      yield put(push('/403'));
      break;

    case 404:
      if (!_.isEmpty(responseError.response.data.errors)) {
        yield all(
          _.map(responseError.response.data.errors, (i) =>
            put(throwNotification(i, 'error')),
          ),
        );
      } else {
        yield put(
          throwNotification(
            textDefault ||
            _.get(responseError, 'response.data.systemError') ||
            errText,
            'error',
          ),
        );
      }
      break;

    case 405:
      yield put(
        throwNotification(
          i18n.t('Данное действие не поддерживается сервером'),
          'error',
        ),
      );
      break;

    default:
      if (!_.isEmpty(responseError.response.data.errors)) {
        yield all(
          _.map(responseError.response.data.errors, (i) =>
            put(throwNotification(i, 'error')),
          ),
        );
      } else {
        yield put(throwNotification(textDefault || errText, 'error'));
      }
      break;
  }
}
