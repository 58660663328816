import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const DialogProductTitleContentBack = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  button {
    position: absolute;
    top: 35px;
    left: 43px;
  }
`;
export const NewProductDialogNext = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '770px',
        maxWidth: '770px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        height: '621px',
        transform: 'translateX(110px)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '18px 100px 80px',
        textAlign: 'center',
        position: 'relative',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px 40px',
        maxHeight: '200px',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: 'none',
        padding: '0 40px 40px',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        textTransform: 'none',
        fontFamily: 'SegoeUI',
        boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        transition: '.4s ease',
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.8',
        },
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          color: `${colors.Buttons.buttonPrimary.color}`,
          boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
        },
      },
      text: {
        padding: '12px 35px',
        fontSize: '16px',
        fontWeight: '600',
      },
      disabled: {},
    },
  },
});
