import styled from 'styled-components';
import { ProfileTabCont } from '../../Profile.styled';

export const ProfileTabContPadingless = styled(ProfileTabCont)`
  padding: 70px 0 100px;
  position: relative;
`;
export const ProfileGsCont = styled(ProfileTabCont)`
  position: relative;
  padding: 70px 100px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;
export const NewGsTitle = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  h3 {
    font-size: 20px;
    margin: 15px 0;
  }
`;
