import * as React from 'react';

const SafariLogo = () => (
  <svg
    width="227"
    height="120"
    viewBox="0 0 227 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M90.75 49C90.75 67.0873 76.0873 81.75 58 81.75C39.9127 81.75 25.25 67.0873 25.25 49C25.25 30.9127 39.9127 16.25 58 16.25C76.0873 16.25 90.75 30.9127 90.75 49Z"
      fill="url(#safari-paint0_linear)"
      stroke="#D6D6D5"
      strokeWidth="0.5"
    />
    <path
      d="M58.0001 79.5249C74.8586 79.5249 88.5251 65.8583 88.5251 48.9999C88.5251 32.1414 74.8586 18.4749 58.0001 18.4749C41.1416 18.4749 27.4751 32.1414 27.4751 48.9999C27.4751 65.8583 41.1416 79.5249 58.0001 79.5249Z"
      fill="url(#safari-paint1_radial)"
    />
    <path
      d="M58.0517 25.9001C57.8455 25.9001 57.665 25.7455 57.665 25.5392V20.1509C57.665 19.9447 57.8455 19.79 58.0517 19.79C58.258 19.79 58.4384 19.9447 58.4384 20.1509V25.5392C58.4126 25.7455 58.258 25.9001 58.0517 25.9001Z"
      fill="#F3F3F3"
    />
    <path
      d="M58.0517 25.9256C57.8196 25.9256 57.6392 25.7452 57.6392 25.5389V20.1506C57.6392 19.9444 57.8196 19.7639 58.0517 19.7639C58.2837 19.7639 58.4642 19.9444 58.4642 20.1506V25.5389C58.4642 25.7452 58.2579 25.9256 58.0517 25.9256ZM58.0517 19.8153C57.8712 19.8153 57.6908 19.9702 57.6908 20.1506V25.5389C57.6908 25.7194 57.8454 25.8742 58.0517 25.8742C58.2321 25.8742 58.4125 25.7194 58.4125 25.5389V20.1506C58.387 19.9702 58.2321 19.8153 58.0517 19.8153Z"
      fill="#fff"
    />
    <path
      d="M60.6299 23.0125C60.4237 22.9867 60.269 22.8321 60.269 22.6258L60.4495 20.228C60.475 20.022 60.6557 19.8671 60.862 19.893C61.0682 19.9188 61.2228 20.0737 61.2228 20.2799L61.0424 22.6775C61.0166 22.8837 60.8362 23.0383 60.6299 23.0128V23.0125Z"
      fill="#F3F3F3"
    />
    <path
      d="M60.6299 23.0381C60.3978 23.0123 60.2432 22.8319 60.2432 22.6256L60.4236 20.2278C60.4494 20.0218 60.6299 19.8414 60.8619 19.8669C61.0937 19.8927 61.2486 20.0734 61.2486 20.2794L61.0682 22.6772C61.0424 22.9093 60.8619 23.0639 60.6299 23.0381ZM60.8619 19.9186C60.6815 19.9186 60.5008 20.0476 60.5008 20.2281L60.3203 22.6259C60.3203 22.8061 60.4494 22.9609 60.6557 22.9865C60.8361 22.9865 61.0165 22.8577 61.0165 22.6772L61.197 20.2794C61.197 20.099 61.0424 19.9444 60.8616 19.9186H60.8619Z"
      fill="#fff"
    />
    <path
      d="M62.5638 26.3642C62.3572 26.3125 62.2284 26.1321 62.2543 25.9258L63.3113 20.6405C63.3629 20.4345 63.5434 20.3055 63.7496 20.3571C63.9559 20.4087 64.0847 20.5892 64.0589 20.7954L63.0018 26.0807C62.976 26.2609 62.7697 26.39 62.5635 26.3642H62.5638Z"
      fill="#F3F3F3"
    />
    <path
      d="M62.5637 26.39C62.3314 26.3384 62.2025 26.1321 62.2284 25.9259L63.2854 20.6405C63.3371 20.4346 63.5433 20.2797 63.7751 20.3313C64.0072 20.3829 64.1362 20.5892 64.1104 20.7954L63.0534 26.0807C63.0017 26.2867 62.7955 26.4158 62.5637 26.39ZM63.7751 20.3829C63.5947 20.3571 63.4142 20.4601 63.3626 20.6408L62.3058 25.9261C62.28 26.1063 62.383 26.2867 62.5892 26.3386C62.7697 26.3642 62.9501 26.2609 63.0017 26.0805L64.0588 20.7954C64.0846 20.5892 63.9558 20.4346 63.7751 20.3829Z"
      fill="#fff"
    />
    <path
      d="M65.657 24.0438C65.6079 24.032 65.5616 24.0106 65.5208 23.9808C65.48 23.9511 65.4454 23.9136 65.4191 23.8705C65.3928 23.8274 65.3752 23.7795 65.3674 23.7296C65.3596 23.6797 65.3617 23.6288 65.3736 23.5797L66.0182 21.2596C66.0695 21.0533 66.2758 20.9501 66.482 21.0017C66.6883 21.0533 66.8174 21.2596 66.7657 21.4658L66.1212 23.7862C66.0695 23.9922 65.8633 24.0954 65.657 24.0438Z"
      fill="#F3F3F3"
    />
    <path
      d="M65.6573 24.0695C65.451 24.0179 65.2964 23.7858 65.3738 23.5798L66.0184 21.2592C66.0698 21.0532 66.3018 20.9241 66.5081 20.9757C66.7143 21.0274 66.8689 21.2594 66.7918 21.4654L66.1472 23.7861C66.0956 23.992 65.8893 24.1211 65.6575 24.0695H65.6573ZM66.5081 21.0532C66.3276 21.0016 66.1214 21.1048 66.0698 21.2852L65.4255 23.6054C65.3738 23.7858 65.4768 23.9665 65.6831 24.0179C65.8635 24.0695 66.0698 23.9665 66.1214 23.7861L66.766 21.4654C66.7918 21.2852 66.6888 21.1048 66.5081 21.0529V21.0532Z"
      fill="#fff"
    />
    <path
      d="M66.9207 27.6789C66.7144 27.6015 66.6372 27.3694 66.7144 27.189L68.7769 22.2132C68.8541 22.0327 69.0864 21.9295 69.2666 22.0327C69.4731 22.1099 69.5505 22.3422 69.4731 22.5224L67.4106 27.4982C67.3334 27.6789 67.1011 27.7819 66.9209 27.6789H66.9207Z"
      fill="#F3F3F3"
    />
    <path
      d="M66.9205 27.7051C66.8214 27.6673 66.7414 27.5918 66.6979 27.4951C66.6544 27.3985 66.651 27.2885 66.6884 27.1893L68.7509 22.2135C68.8284 22.0072 69.0604 21.9298 69.2925 22.0072C69.3916 22.045 69.4716 22.1205 69.5151 22.2172C69.5586 22.3138 69.562 22.4238 69.5246 22.523L67.4621 27.4988C67.3588 27.7051 67.1267 27.8081 66.9205 27.7051ZM69.2667 22.0589C69.0862 21.9817 68.88 22.0589 68.8026 22.2393L66.7401 27.2151C66.6629 27.3956 66.7659 27.576 66.9463 27.6534C67.1267 27.7306 67.333 27.6534 67.4104 27.473L69.4729 22.4972C69.5246 22.3423 69.4471 22.1361 69.2667 22.0589Z"
      fill="#fff"
    />
    <path
      d="M70.401 26.0292C70.2203 25.926 70.1429 25.7197 70.2203 25.5393L71.3032 23.3996C71.4062 23.2189 71.6125 23.1415 71.819 23.2447C71.9994 23.348 72.0766 23.5542 71.9994 23.7344L70.9163 25.8744C70.8133 26.0548 70.607 26.1325 70.4008 26.0292H70.401Z"
      fill="#F3F3F3"
    />
    <path
      d="M70.4009 26.0548C70.1944 25.9516 70.1172 25.7195 70.2202 25.5133L71.3031 23.3736C71.4061 23.1671 71.6384 23.0899 71.8444 23.1929C72.0507 23.2961 72.1281 23.5282 72.0248 23.7345L70.9422 25.8741C70.839 26.0804 70.6069 26.1578 70.4007 26.0546L70.4009 26.0548ZM71.8189 23.2445C71.6382 23.1673 71.4319 23.2187 71.3547 23.3991L70.2719 25.5391C70.1947 25.6937 70.2719 25.8999 70.4265 26.0032C70.6069 26.0804 70.8132 26.029 70.8906 25.8486L71.9735 23.7086C72.0507 23.554 71.9735 23.3478 71.8189 23.2445Z"
      fill="#fff"
    />
    <path
      d="M70.9165 29.8445C70.736 29.7157 70.6844 29.4837 70.8132 29.329L73.804 24.8687C73.907 24.6883 74.1393 24.6625 74.3195 24.7657C74.5002 24.8945 74.5515 25.1266 74.4227 25.2812L71.432 29.7415C71.329 29.922 71.0969 29.9736 70.9165 29.8445Z"
      fill="#F3F3F3"
    />
    <path
      d="M70.9168 29.8704C70.7364 29.7416 70.6847 29.4837 70.788 29.3032L73.7785 24.8429C73.9073 24.6625 74.1394 24.6109 74.3456 24.7399C74.526 24.8687 74.5777 25.1266 74.4747 25.307L71.4839 29.7674C71.3551 29.9478 71.0972 29.9992 70.9168 29.8704ZM74.3198 24.7916C74.1654 24.6883 73.9331 24.7141 73.8301 24.8687L70.8394 29.3291C70.7364 29.4834 70.7877 29.6899 70.9426 29.7929C71.0972 29.8962 71.3293 29.8704 71.4323 29.7157L74.423 25.2812C74.526 25.1266 74.4747 24.8945 74.3198 24.7916Z"
      fill="#fff"
    />
    <path
      d="M74.6804 28.8909C74.5261 28.7618 74.4744 28.5298 74.6033 28.3751L76.0986 26.4931C76.2274 26.3387 76.4595 26.3126 76.6141 26.4417C76.769 26.5705 76.8204 26.8026 76.6913 26.9572L75.1962 28.8393C75.0674 28.9939 74.8353 29.0197 74.6807 28.8909H74.6804Z"
      fill="#F3F3F3"
    />
    <path
      d="M74.6549 28.9165C74.4745 28.7874 74.4487 28.5295 74.5778 28.3491L76.0729 26.4673C76.2017 26.2866 76.4596 26.2608 76.64 26.4157C76.8204 26.5445 76.8462 26.8024 76.7172 26.9828L75.2221 28.8649C75.0932 29.0453 74.8354 29.0711 74.6549 28.9165ZM76.5884 26.467C76.434 26.3382 76.2275 26.364 76.0987 26.5187L74.6033 28.4007C74.5003 28.5553 74.5261 28.7616 74.6805 28.8904C74.8354 29.0195 75.0416 28.9937 75.1704 28.839L76.6658 26.957C76.7691 26.8024 76.743 26.5703 76.5886 26.4673L76.5884 26.467Z"
      fill="#fff"
    />
    <path
      d="M74.4231 32.758C74.2685 32.6034 74.2685 32.3713 74.4231 32.2422L78.2386 28.4525C78.3935 28.2979 78.6253 28.3235 78.7543 28.4525C78.8831 28.5814 78.909 28.8392 78.7543 28.968L74.9647 32.758C74.8098 32.9126 74.5777 32.9126 74.4231 32.758Z"
      fill="#F3F3F3"
    />
    <path
      d="M74.3973 32.7838C74.3229 32.7081 74.2812 32.6063 74.2812 32.5002C74.2812 32.3941 74.3229 32.2923 74.3973 32.2166L78.2128 28.4267C78.2884 28.3523 78.3903 28.3105 78.4965 28.3105C78.6026 28.3105 78.7045 28.3523 78.7802 28.4267C78.8545 28.5024 78.8962 28.6042 78.8962 28.7103C78.8962 28.8163 78.8545 28.9182 78.7802 28.9938L74.9644 32.7835C74.8887 32.8579 74.7869 32.8995 74.6808 32.8995C74.5748 32.8995 74.4729 32.8579 74.3973 32.7835V32.7838ZM78.7543 28.4525C78.6255 28.3234 78.3935 28.3234 78.2647 28.4525L74.4489 32.2422C74.3198 32.3713 74.3198 32.6033 74.4489 32.7321C74.5777 32.8612 74.8098 32.8612 74.9386 32.7321L78.7543 28.9425C78.8831 28.8134 78.8831 28.6071 78.7543 28.4525Z"
      fill="#fff"
    />
    <path
      d="M78.2899 32.5515C78.1611 32.3969 78.1611 32.1648 78.3157 32.0358L80.1461 30.489C80.3008 30.3602 80.5328 30.3858 80.6619 30.5407C80.7907 30.6953 80.7907 30.9273 80.6361 31.0561L78.8056 32.6032C78.6508 32.732 78.4187 32.7061 78.2899 32.5515Z"
      fill="#F3F3F3"
    />
    <path
      d="M78.264 32.5775C78.1094 32.397 78.1352 32.1391 78.2898 32.0103L80.1203 30.4633C80.2749 30.3087 80.5328 30.3345 80.6874 30.515C80.8423 30.6954 80.8165 30.9533 80.6618 31.0821L78.8311 32.6291C78.6768 32.7834 78.4186 32.7579 78.2643 32.5775H78.264ZM80.636 30.5408C80.507 30.3861 80.3007 30.3861 80.1464 30.4891L78.3156 32.0361C78.1868 32.165 78.161 32.3712 78.2898 32.5258C78.4186 32.6804 78.6252 32.6804 78.7795 32.5775L80.6102 31.0304C80.7648 30.9016 80.7648 30.6954 80.636 30.5408Z"
      fill="#fff"
    />
    <path
      d="M77.2585 36.2641C77.1297 36.0836 77.1813 35.8516 77.3618 35.7483L81.8477 32.7578C82.0281 32.6546 82.2602 32.7062 82.3631 32.8611C82.4922 33.0415 82.4406 33.2736 82.2602 33.3766L77.7743 36.3671C77.6194 36.4703 77.3873 36.4187 77.2585 36.2641Z"
      fill="#F3F3F3"
    />
    <path
      d="M77.2331 36.2641C77.1043 36.0836 77.156 35.8257 77.3364 35.6969L81.8223 32.7062C82.0027 32.5774 82.2606 32.629 82.3894 32.8352C82.5185 33.0157 82.4666 33.2736 82.2864 33.4021L77.8005 36.3931C77.6198 36.5222 77.3622 36.4706 77.2331 36.2643V36.2641ZM82.3378 32.8869C82.2348 32.732 82.0285 32.6804 81.8739 32.7836L77.3878 35.7741C77.2334 35.8774 77.1815 36.0836 77.3106 36.2641C77.4136 36.4187 77.6198 36.4703 77.7747 36.3673L82.2606 33.3765C82.4152 33.2477 82.441 33.0412 82.3378 32.8869Z"
      fill="#fff"
    />
    <path
      d="M81.1005 36.8058C80.9972 36.6251 81.0489 36.3933 81.2293 36.29L83.3176 35.1041C83.498 35.0009 83.7301 35.0783 83.8331 35.2585C83.9364 35.4392 83.8847 35.671 83.7043 35.7742L81.616 36.9604C81.4355 37.0634 81.2035 36.9862 81.1002 36.8055L81.1005 36.8058Z"
      fill="#F3F3F3"
    />
    <path
      d="M81.0746 36.8313C80.9714 36.6251 81.023 36.393 81.2034 36.29L83.2917 35.1041C83.4722 35.0009 83.73 35.0783 83.833 35.2585C83.9363 35.465 83.8847 35.6971 83.7042 35.8001L81.6159 36.9862C81.4355 37.0892 81.1776 37.0117 81.0746 36.8313ZM83.8072 35.2846C83.7042 35.1041 83.498 35.0525 83.3434 35.1555L81.255 36.3416C81.1004 36.4446 81.0488 36.6509 81.126 36.8055C81.2292 36.9859 81.4355 37.0376 81.5901 36.9346L83.6784 35.7484C83.833 35.6712 83.8847 35.465 83.8072 35.2846Z"
      fill="#fff"
    />
    <path
      d="M79.3731 40.2345C79.2959 40.0282 79.3731 39.822 79.5794 39.7448L84.5552 37.6823C84.7356 37.6049 84.9677 37.7079 85.0448 37.8883C85.1223 38.0945 85.0448 38.3008 84.8386 38.378L79.863 40.4405C79.6823 40.5179 79.4505 40.4405 79.3731 40.2345Z"
      fill="#F3F3F3"
    />
    <path
      d="M79.3472 40.2602C79.27 40.0542 79.3472 39.8221 79.5535 39.7189L84.5293 37.6564C84.6284 37.619 84.7383 37.6224 84.835 37.6659C84.9316 37.7094 85.007 37.7894 85.0448 37.8884C85.1222 38.0947 85.0448 38.3267 84.8385 38.4297L79.863 40.4922C79.7638 40.5297 79.6539 40.5263 79.5572 40.4829C79.4605 40.4394 79.385 40.3594 79.3472 40.2604V40.2602ZM84.9934 37.9142C84.916 37.7338 84.7097 37.6564 84.5551 37.708L79.5793 39.7705C79.3989 39.8477 79.3214 40.0542 79.3988 40.2346C79.476 40.415 79.6823 40.4925 79.8372 40.4409L84.8127 38.3784C84.9934 38.3012 85.0709 38.0947 84.9934 37.9142Z"
      fill="#fff"
    />
    <path
      d="M83.008 41.5235C82.9309 41.317 83.0338 41.111 83.2401 41.0336L85.5088 40.2858C85.6893 40.2086 85.9213 40.3374 85.973 40.5178C86.0501 40.7241 85.9472 40.9303 85.7409 41.0078L83.4722 41.7556C83.2917 41.8328 83.0855 41.7298 83.008 41.5233V41.5235Z"
      fill="#F3F3F3"
    />
    <path
      d="M82.9823 41.5491C82.9052 41.3429 83.0082 41.1108 83.2405 41.0336L85.509 40.2858C85.7152 40.2086 85.9473 40.3374 86.0247 40.5439C86.1019 40.7499 85.9989 40.982 85.7666 41.0594L83.4981 41.8073C83.2919 41.8586 83.0598 41.7554 82.9823 41.5491ZM85.9731 40.5698C85.9215 40.3891 85.7152 40.2858 85.5348 40.3374L83.266 41.0852C83.0856 41.1366 83.0082 41.3429 83.0598 41.5236C83.1114 41.704 83.3177 41.807 83.4981 41.7554L85.7669 41.0078C85.9473 40.9306 86.0247 40.7499 85.9731 40.5695V40.5698Z"
      fill="#fff"
    />
    <path
      d="M80.6877 44.5914C80.636 44.3852 80.7648 44.1789 80.9711 44.1531L86.2564 43.1218C86.4624 43.0702 86.6431 43.2248 86.6945 43.4311C86.7461 43.6373 86.6173 43.8436 86.411 43.8694L81.1257 44.9006C80.9195 44.9265 80.7132 44.7977 80.6874 44.5914H80.6877Z"
      fill="#F3F3F3"
    />
    <path
      d="M80.636 44.5917C80.5844 44.3596 80.739 44.1534 80.9452 44.1017L86.2306 43.0705C86.4368 43.0189 86.6431 43.1738 86.6944 43.4058C86.7461 43.6376 86.5914 43.8439 86.3852 43.8955L81.0999 44.9267C80.8939 44.9784 80.6874 44.8238 80.636 44.5914V44.5917ZM86.6431 43.4314C86.6172 43.2509 86.4368 43.1221 86.2564 43.1479L80.9711 44.1792C80.7906 44.205 80.6618 44.411 80.7132 44.5917C80.739 44.7721 80.9194 44.9009 81.1001 44.8751L86.3852 43.8439C86.5656 43.7922 86.6944 43.6118 86.6431 43.4314Z"
      fill="#fff"
    />
    <path
      d="M83.9877 46.5764C83.9618 46.3702 84.0906 46.1897 84.2969 46.1639L86.6686 45.8802C86.8749 45.8547 87.0556 46.0093 87.0811 46.2155C87.107 46.4218 86.9781 46.6022 86.7719 46.628L84.3999 46.9115C84.2195 46.9373 84.0132 46.7827 83.9874 46.5764H83.9877Z"
      fill="#F3F3F3"
    />
    <path
      d="M83.9618 46.5765C83.936 46.3445 84.0906 46.1382 84.2969 46.1124L86.6686 45.829C86.8749 45.8032 87.0811 45.9578 87.1069 46.1899C87.1327 46.4219 86.9781 46.6282 86.7719 46.654L84.3999 46.9374C84.1939 46.9632 83.9874 46.8086 83.9618 46.5765ZM87.0811 46.2157C87.0556 46.0352 86.8751 45.8803 86.6944 45.9062L84.3227 46.1899C84.1422 46.2157 84.0132 46.3961 84.039 46.5765C84.0648 46.757 84.2452 46.9119 84.4257 46.886L86.7977 46.6024C86.9781 46.5765 87.1069 46.3961 87.0814 46.2157H87.0811Z"
      fill="#fff"
    />
    <path
      d="M81.1001 49.0774C81.1001 48.8711 81.2544 48.6907 81.461 48.6907H86.8493C87.0555 48.6907 87.2102 48.8711 87.2102 49.0774C87.2102 49.2836 87.0555 49.464 86.8493 49.464H81.461C81.2547 49.464 81.1001 49.2836 81.1001 49.0774Z"
      fill="#F3F3F3"
    />
    <path
      d="M81.0747 49.0775C81.0747 48.8455 81.2551 48.665 81.4614 48.665H86.8497C87.056 48.665 87.2364 48.8455 87.2364 49.0775C87.2364 49.3096 87.056 49.49 86.8497 49.49H81.4614C81.2551 49.49 81.0747 49.3096 81.0747 49.0775ZM87.1848 49.1034C87.1848 48.9229 87.0301 48.7425 86.8494 48.7425H81.4617C81.2812 48.7425 81.1263 48.8968 81.1263 49.1034C81.1263 49.2838 81.2812 49.4642 81.4617 49.4642H86.85C87.0304 49.4384 87.185 49.2838 87.185 49.1034H87.1848Z"
      fill="#fff"
    />
    <path
      d="M83.9622 51.6812C83.9877 51.4749 84.1426 51.3203 84.3489 51.3203L86.7467 51.5007C86.9527 51.5266 87.1073 51.707 87.0815 51.9132C87.0559 52.1195 86.901 52.2741 86.6948 52.2741L84.2972 52.0937C84.1168 52.0679 83.9619 51.8874 83.9619 51.6812H83.9622Z"
      fill="#F3F3F3"
    />
    <path
      d="M83.936 51.6814C83.9618 51.4493 84.1423 51.2947 84.3485 51.2947L86.7464 51.4751C86.9523 51.5009 87.1328 51.6814 87.107 51.9134C87.0814 52.1455 86.9007 52.3001 86.6945 52.3001L84.2969 52.1197C84.0907 52.0939 83.936 51.8876 83.936 51.6814ZM87.0814 51.9134C87.107 51.733 86.9523 51.5526 86.7719 51.5526L84.3741 51.3721C84.1939 51.3721 84.039 51.5009 84.0132 51.7074C83.9877 51.8876 84.1423 52.068 84.3227 52.068L86.7205 52.2485C86.9007 52.2485 87.0556 52.0941 87.0814 51.9134Z"
      fill="#fff"
    />
    <path
      d="M80.6361 53.5633C80.6877 53.3571 80.8682 53.228 81.0744 53.2541L86.3595 54.3111C86.5657 54.3625 86.6948 54.5429 86.6432 54.7492C86.5915 54.9554 86.4111 55.0845 86.2049 55.0587L80.9195 54.0016C80.7391 53.9758 80.6103 53.7696 80.6361 53.5633Z"
      fill="#F3F3F3"
    />
    <path
      d="M80.6102 53.5634C80.6618 53.3313 80.8681 53.2025 81.0743 53.2281L86.3594 54.2854C86.5656 54.3368 86.7203 54.543 86.6686 54.7751C86.6173 55.0071 86.411 55.1362 86.2048 55.1104L80.9195 54.0533C80.7132 54.0017 80.5844 53.7954 80.6102 53.5636V53.5634ZM86.6173 54.7751C86.6431 54.5946 86.5401 54.4142 86.3594 54.3626L81.0741 53.3055C80.8939 53.2799 80.7132 53.3829 80.6616 53.5892C80.636 53.7696 80.739 53.9501 80.9197 54.0017L86.2048 55.0588C86.411 55.0846 86.5656 54.9555 86.6173 54.7751Z"
      fill="#fff"
    />
    <path
      d="M82.9567 56.6572C82.9685 56.6081 82.9899 56.5619 83.0196 56.5211C83.0494 56.4803 83.0868 56.4457 83.1299 56.4194C83.173 56.3931 83.2208 56.3755 83.2707 56.3677C83.3206 56.3599 83.3715 56.3619 83.4205 56.3738L85.7409 57.0181C85.9472 57.0697 86.0502 57.2762 85.9988 57.4822C85.987 57.5313 85.9656 57.5777 85.9359 57.6185C85.9062 57.6594 85.8687 57.694 85.8256 57.7203C85.7825 57.7467 85.7346 57.7643 85.6847 57.7721C85.6348 57.7799 85.5838 57.7778 85.5347 57.7659L83.2143 57.1213C83.0081 57.0697 82.9051 56.8635 82.9564 56.6572H82.9567Z"
      fill="#F3F3F3"
    />
    <path
      d="M82.9307 56.657C82.9823 56.4508 83.2143 56.2961 83.4206 56.3736L85.741 57.0179C85.9472 57.0695 86.0763 57.3016 86.0247 57.5078C85.973 57.7141 85.741 57.8687 85.5347 57.7915L83.2143 57.147C83.0081 57.0953 82.879 56.8891 82.9309 56.6573L82.9307 56.657ZM85.9472 57.5078C85.9989 57.3274 85.8956 57.1211 85.7152 57.0695L83.3948 56.4249C83.2143 56.3733 83.0339 56.4766 82.9823 56.6828C82.9309 56.8633 83.0339 57.0695 83.2143 57.1211L85.5347 57.7657C85.7152 57.7915 85.8956 57.6885 85.9472 57.5078Z"
      fill="#fff"
    />
    <path
      d="M79.3215 57.8944C79.3986 57.6882 79.631 57.6107 79.8111 57.6882L84.7872 59.7507C84.9677 59.8278 85.0706 60.0602 84.9935 60.2403C84.916 60.4468 84.684 60.524 84.5035 60.4469L79.5277 58.3844C79.3215 58.3072 79.244 58.0748 79.3215 57.8947V57.8944Z"
      fill="#F3F3F3"
    />
    <path
      d="M79.2955 57.8687C79.3332 57.7697 79.4087 57.6896 79.5054 57.6461C79.6021 57.6026 79.712 57.5992 79.8112 57.6367L84.787 59.6992C84.9933 59.7766 85.0705 60.0087 84.9933 60.2407C84.9555 60.3398 84.88 60.4199 84.7834 60.4634C84.6867 60.5069 84.5767 60.5103 84.4775 60.4728L79.5017 58.4103C79.2955 58.307 79.2183 58.075 79.2955 57.8687ZM84.9417 60.2149C85.0188 60.0345 84.9417 59.8282 84.7612 59.7508L79.7854 57.6883C79.605 57.6111 79.4245 57.7141 79.3471 57.8945C79.2699 58.075 79.3471 58.2812 79.5275 58.3587L84.5033 60.4212C84.6838 60.4728 84.89 60.3954 84.9417 60.2149Z"
      fill="#fff"
    />
    <path
      d="M80.9969 61.3749C81.1002 61.1945 81.3062 61.117 81.4866 61.1945L83.6266 62.2773C83.807 62.3545 83.8844 62.5866 83.7814 62.7928C83.6782 62.9733 83.4719 63.051 83.2915 62.9733L81.1518 61.8907C80.9711 61.7874 80.8937 61.5553 80.9969 61.3749Z"
      fill="#F3F3F3"
    />
    <path
      d="M80.9712 61.3489C81.0745 61.1427 81.3065 61.0655 81.5128 61.1685L83.6525 62.2514C83.8587 62.3546 83.9361 62.5867 83.8329 62.7927C83.7299 62.9989 83.4976 63.0764 83.2916 62.9731L81.1519 61.8905C80.9454 61.8133 80.8682 61.5552 80.9712 61.3489ZM83.7557 62.7668C83.8329 62.5864 83.7813 62.3802 83.6008 62.303L81.4611 61.2201C81.3063 61.1429 81.1003 61.2201 80.997 61.3747C80.9198 61.5552 80.9712 61.7614 81.1516 61.8389L83.2913 62.9217C83.472 62.9989 83.6783 62.9475 83.7557 62.7668Z"
      fill="#fff"
    />
    <path
      d="M77.2073 61.865C77.3363 61.6845 77.5426 61.6329 77.723 61.7617L82.2089 64.7525C82.3893 64.8554 82.4151 65.0878 82.3121 65.2679C82.1831 65.4486 81.9768 65.5 81.7964 65.3712L77.3105 62.3804C77.1301 62.2516 77.0784 62.0196 77.2073 61.865Z"
      fill="#F3F3F3"
    />
    <path
      d="M77.1812 61.8392C77.3103 61.6587 77.5682 61.6071 77.7486 61.7104L82.2345 64.7008C82.4149 64.8297 82.4666 65.0617 82.3375 65.268C82.2087 65.4487 81.9508 65.5 81.7706 65.397L77.2845 62.4063C77.0782 62.2775 77.0524 62.0196 77.1812 61.8392ZM82.2603 65.2422C82.3633 65.0878 82.3375 64.8555 82.1831 64.7525L77.697 61.7617C77.5424 61.6587 77.3361 61.7104 77.2329 61.865C77.1299 62.0196 77.1557 62.2517 77.31 62.3547L81.7962 65.3454C81.9508 65.4487 82.1571 65.397 82.2603 65.2424V65.2422Z"
      fill="#fff"
    />
    <path
      d="M78.1612 65.6033C78.29 65.4484 78.5221 65.3971 78.6767 65.5261L80.5588 66.9954C80.7134 67.1242 80.7392 67.3563 80.6104 67.5112C80.4816 67.6655 80.2495 67.7174 80.0946 67.5884L78.2126 66.1188C78.0582 65.99 78.0321 65.7579 78.1612 65.6033Z"
      fill="#F3F3F3"
    />
    <path
      d="M78.1353 65.5773C78.2641 65.3969 78.5219 65.371 78.7024 65.5001L80.5845 66.9694C80.7649 67.0982 80.7907 67.3561 80.6616 67.5365C80.5328 67.7172 80.2749 67.7428 80.0948 67.614L78.2124 66.1444C78.032 66.0156 78.0065 65.7577 78.1353 65.5773ZM80.6103 67.511C80.7391 67.3561 80.7133 67.1499 80.5586 67.021L78.6766 65.5515C78.5219 65.4485 78.3157 65.4743 78.1869 65.6287C78.0578 65.7836 78.0836 65.9898 78.2383 66.1186L80.1203 67.5882C80.2749 67.6914 80.4812 67.6653 80.61 67.511H80.6103Z"
      fill="#fff"
    />
    <path
      d="M74.3197 65.3455C74.4746 65.1908 74.7067 65.1908 74.8355 65.3455L78.651 69.1351C78.8059 69.29 78.7801 69.5221 78.651 69.6509C78.4966 69.8055 78.2643 69.8055 78.1355 69.6509L74.3197 65.8612C74.1909 65.7321 74.1909 65.4743 74.3197 65.3455Z"
      fill="#F3F3F3"
    />
    <path
      d="M74.3194 65.3195C74.3951 65.2451 74.497 65.2034 74.6031 65.2034C74.7092 65.2034 74.8111 65.2451 74.8868 65.3195L78.7023 69.1092C78.7767 69.1849 78.8184 69.2868 78.8184 69.3929C78.8184 69.499 78.7767 69.6009 78.7023 69.6766C78.6266 69.751 78.5248 69.7926 78.4187 69.7926C78.3127 69.7926 78.2108 69.751 78.1352 69.6766L74.3194 65.8869C74.139 65.732 74.139 65.4744 74.3194 65.3195ZM78.6507 69.625C78.7797 69.4962 78.7797 69.2641 78.6507 69.1353L74.8352 65.3453C74.7064 65.2165 74.4743 65.2165 74.3455 65.3453C74.2164 65.4741 74.2164 65.7062 74.3455 65.835L78.161 69.625C78.2898 69.7799 78.4963 69.7799 78.6507 69.625Z"
      fill="#fff"
    />
    <path
      d="M74.5516 69.1869C74.7064 69.0581 74.9382 69.0581 75.0673 69.2127L76.6401 71.0173C76.7687 71.1722 76.7431 71.4042 76.5882 71.5331C76.4339 71.6619 76.2016 71.6619 76.0728 71.5072L74.4999 69.7026C74.3711 69.5738 74.3969 69.3417 74.5516 69.1869Z"
      fill="#F3F3F3"
    />
    <path
      d="M74.5262 69.1865C74.7066 69.0322 74.9387 69.0577 75.093 69.2123L76.6658 71.017C76.8205 71.1718 76.7946 71.4295 76.6142 71.5843C76.4338 71.739 76.2017 71.7131 76.0471 71.5585L74.4743 69.7539C74.3455 69.5735 74.3455 69.3156 74.5259 69.1865H74.5262ZM76.5887 71.5327C76.743 71.4039 76.743 71.1974 76.64 71.043L75.0672 69.2381C74.9384 69.1093 74.7321 69.0835 74.5775 69.2123C74.4229 69.3414 74.4229 69.5477 74.5259 69.702L76.0987 71.5069C76.2275 71.6615 76.434 71.6615 76.5884 71.5327H76.5887Z"
      fill="#fff"
    />
    <path
      d="M70.8391 68.207C71.0196 68.0782 71.2516 68.1298 71.3549 68.3103L74.3451 72.7703C74.4484 72.9508 74.3967 73.1828 74.2421 73.2861C74.0617 73.4149 73.8296 73.3633 73.7264 73.1828L70.7359 68.7225C70.6326 68.5423 70.6842 68.31 70.8389 68.207H70.8391Z"
      fill="#F3F3F3"
    />
    <path
      d="M70.8391 68.1811C71.0196 68.0523 71.2775 68.104 71.4063 68.2844L74.397 72.7447C74.5258 72.9249 74.4742 73.1828 74.2682 73.3116C74.0875 73.4406 73.8299 73.389 73.7008 73.2086L70.7103 68.7483C70.5815 68.5423 70.6332 68.3102 70.8391 68.1814V68.1811ZM74.2424 73.2602C74.397 73.1569 74.4486 72.9507 74.3454 72.7961L71.3549 68.3357C71.2516 68.1814 71.0454 68.1553 70.8652 68.2586C70.7103 68.3616 70.6587 68.5678 70.762 68.7227L73.7525 73.1828C73.8557 73.3116 74.0878 73.3632 74.2421 73.2599L74.2424 73.2602Z"
      fill="#fff"
    />
    <path
      d="M70.2979 72.0224C70.4783 71.9194 70.7104 71.9711 70.8134 72.1515L71.9995 74.2398C72.1025 74.4202 72.0251 74.6523 71.8446 74.7553C71.6642 74.8586 71.4321 74.8069 71.3291 74.6265L70.1433 72.5382C70.0658 72.3577 70.1174 72.1257 70.2976 72.0227L70.2979 72.0224Z"
      fill="#F3F3F3"
    />
    <path
      d="M70.2981 71.997C70.5043 71.8937 70.7364 71.9453 70.8394 72.1258L72.0252 74.2141C72.1285 74.3945 72.0769 74.6524 71.8709 74.7554C71.6644 74.8587 71.4323 74.807 71.3293 74.6266L70.1434 72.5383C70.0402 72.3578 70.0918 72.1258 70.2978 71.997H70.2981ZM71.8448 74.7299C71.9997 74.6266 72.0769 74.4203 71.9739 74.2657L70.788 72.1774C70.6848 72.0228 70.4785 71.9712 70.3239 72.0483C70.169 72.1516 70.0918 72.3578 70.1948 72.5125L71.3809 74.6008C71.4581 74.7554 71.6902 74.8328 71.8448 74.7296V74.7299Z"
      fill="#fff"
    />
    <path
      d="M66.8688 70.3211C67.075 70.2439 67.2813 70.3211 67.3587 70.5015L69.447 75.4773C69.5242 75.6577 69.4212 75.8898 69.2408 75.967C69.0603 76.0444 68.8283 75.967 68.7511 75.7865L66.6628 70.811C66.5853 70.6303 66.6883 70.3985 66.8688 70.3211Z"
      fill="#F3F3F3"
    />
    <path
      d="M66.8688 70.2955C67.0751 70.2183 67.3072 70.2955 67.4104 70.5017L69.4987 75.4775C69.5361 75.5767 69.5327 75.6866 69.4892 75.7832C69.4457 75.8798 69.3657 75.9553 69.2667 75.993C69.0604 76.0705 68.8284 75.993 68.7254 75.7868L66.637 70.8112C66.5596 70.6305 66.6629 70.3987 66.8688 70.2955ZM69.215 75.9417C69.3955 75.8642 69.4729 75.658 69.3955 75.5033L67.3072 70.5275C67.23 70.3471 67.0237 70.2696 66.8433 70.3471C66.6629 70.4243 66.5852 70.6305 66.6629 70.7854L68.7509 75.7612C68.8542 75.9417 69.0346 76.0188 69.215 75.9417Z"
      fill="#fff"
    />
    <path
      d="M65.6056 73.9819C65.8121 73.9047 66.0181 74.0077 66.0955 74.214L66.8433 76.4827C66.9205 76.6632 66.7917 76.8952 66.6113 76.9469C66.405 77.024 66.1988 76.921 66.1216 76.7148L65.3738 74.446C65.2966 74.2656 65.3996 74.0594 65.6058 73.9819H65.6056Z"
      fill="#F3F3F3"
    />
    <path
      d="M65.5801 73.9564C65.6793 73.919 65.7892 73.9225 65.8858 73.966C65.9824 74.0095 66.0579 74.0895 66.0956 74.1885L66.8434 76.4572C66.9206 76.6635 66.7918 76.8955 66.5853 76.973C66.4861 77.0103 66.3762 77.0069 66.2796 76.9634C66.183 76.9199 66.1076 76.8399 66.0698 76.7409L65.3222 74.4722C65.2706 74.2659 65.3739 74.0338 65.5801 73.9564ZM66.5856 76.9211C66.766 76.8697 66.869 76.6635 66.8176 76.483L66.0698 74.2143C66.0184 74.0338 65.8122 73.9564 65.6318 74.008C65.451 74.0597 65.3481 74.2659 65.3997 74.4463L66.1472 76.7151C66.1604 76.7589 66.1826 76.7994 66.2124 76.834C66.2423 76.8686 66.279 76.8965 66.3204 76.916C66.3617 76.9354 66.4067 76.946 66.4524 76.9469C66.4981 76.9478 66.5435 76.9391 66.5856 76.9213V76.9211Z"
      fill="#fff"
    />
    <path
      d="M62.5635 71.6359C62.7695 71.5843 62.976 71.7131 63.0015 71.9193L64.0844 77.2047C64.136 77.4106 63.9811 77.5913 63.7749 77.6427C63.5689 77.6943 63.3624 77.5655 63.3368 77.3593L62.2537 72.074C62.2282 71.8935 62.357 71.6873 62.5632 71.6356L62.5635 71.6359Z"
      fill="#F3F3F3"
    />
    <path
      d="M62.5637 71.61C62.7954 71.5587 63.0017 71.7133 63.0533 71.9195L64.1362 77.2049C64.1878 77.4108 64.0329 77.6429 63.8267 77.6687C63.5946 77.7203 63.3884 77.5657 63.337 77.3595L62.2539 72.0742C62.2025 71.8679 62.3316 71.6617 62.5634 71.6103L62.5637 71.61ZM63.7751 77.6171C63.9558 77.5915 64.0846 77.4108 64.0588 77.2046L62.9759 71.9195C62.9503 71.7391 62.7441 71.6103 62.5634 71.6617C62.3829 71.6875 62.2541 71.8679 62.28 72.0742L63.3628 77.3595C63.4142 77.5399 63.5946 77.6429 63.7753 77.6174L63.7751 77.6171Z"
      fill="#fff"
    />
    <path
      d="M60.604 74.9874C60.8102 74.9616 60.9907 75.0907 61.0423 75.2969L61.3515 77.6687C61.3774 77.8749 61.2227 78.0553 61.0162 78.0812C60.8102 78.107 60.6298 77.9782 60.5782 77.7719L60.2689 75.3999C60.2431 75.1937 60.3977 75.0132 60.604 74.9874Z"
      fill="#F3F3F3"
    />
    <path
      d="M60.6039 74.9615C60.836 74.936 61.0422 75.0906 61.068 75.2968L61.3773 77.6686C61.4031 77.8748 61.2485 78.0811 61.0164 78.1069C60.7844 78.1327 60.5781 77.9781 60.5523 77.7716L60.243 75.3998C60.2172 75.1936 60.3719 74.9873 60.6039 74.9615ZM60.9906 78.0553C61.171 78.0297 61.3259 77.849 61.3001 77.6686L60.9906 75.2968C60.9648 75.1164 60.7844 74.9873 60.6039 75.0131C60.4235 75.0389 60.2686 75.2194 60.2944 75.3998L60.6039 77.7718C60.6297 77.9523 60.8102 78.0811 60.9906 78.0553Z"
      fill="#fff"
    />
    <path
      d="M57.9487 72.0999C58.1549 72.0999 58.3354 72.2547 58.3354 72.461V77.849C58.3354 78.0553 58.1549 78.2102 57.9487 78.2102C57.7424 78.2102 57.562 78.0553 57.562 77.849V72.461C57.5878 72.2547 57.7424 72.0999 57.9487 72.0999Z"
      fill="#F3F3F3"
    />
    <path
      d="M57.9486 72.0742C58.1807 72.0742 58.3611 72.2547 58.3611 72.4609V77.8492C58.3611 78.0555 58.1807 78.2359 57.9486 78.2359C57.7166 78.2359 57.5361 78.0555 57.5361 77.8492V72.4612C57.5361 72.2549 57.7424 72.0742 57.9486 72.0742ZM57.9486 78.1845C58.1291 78.1845 58.3095 78.0299 58.3095 77.8492V72.4612C58.3095 72.2805 58.1549 72.1258 57.9486 72.1258C57.7682 72.1258 57.5878 72.2805 57.5878 72.4612V77.8492C57.6133 78.0299 57.7682 78.1845 57.9486 78.1845Z"
      fill="#fff"
    />
    <path
      d="M55.3703 74.9877C55.5765 75.0135 55.7312 75.1681 55.7312 75.3744L55.5507 77.7722C55.5252 77.9784 55.3445 78.1331 55.1382 78.1075C54.932 78.0814 54.7773 77.9268 54.7773 77.7206L54.9578 75.3227C54.9836 75.1168 55.164 74.9619 55.3703 74.9877Z"
      fill="#F3F3F3"
    />
    <path
      d="M55.3702 74.9617C55.6023 74.9876 55.7569 75.168 55.7569 75.3742L55.5765 77.7721C55.5506 77.9783 55.3702 78.1587 55.1381 78.1329C54.9064 78.1071 54.7515 77.9267 54.7515 77.7204L54.9319 75.3226C54.9577 75.0908 55.1381 74.9362 55.3702 74.9617ZM55.1381 78.0813C55.3186 78.0813 55.4993 77.9525 55.4993 77.7721L55.6797 75.3742C55.6797 75.1938 55.5506 75.0392 55.3444 75.0134C55.164 75.0134 54.9835 75.1422 54.9835 75.3229L54.8031 77.7204C54.8031 77.9009 54.9577 78.0558 55.1384 78.0813H55.1381Z"
      fill="#fff"
    />
    <path
      d="M53.4371 71.6361C53.6434 71.6875 53.7722 71.8679 53.7464 72.0742L52.6893 77.3595C52.6377 77.5657 52.4572 77.6948 52.251 77.6429C52.0447 77.5915 51.9159 77.4111 51.9418 77.2046L52.9988 71.9195C53.0246 71.7391 53.2309 71.6103 53.4371 71.6361Z"
      fill="#F3F3F3"
    />
    <path
      d="M53.437 71.61C53.6688 71.6616 53.7979 71.8681 53.772 72.0741L52.715 77.3595C52.6633 77.5657 52.4571 77.7203 52.2253 77.6687C51.9932 77.6171 51.8642 77.4108 51.89 77.2046L52.947 71.9195C52.9987 71.7133 53.2049 71.5842 53.4367 71.6103L53.437 71.61ZM52.2253 77.6171C52.4057 77.6429 52.5862 77.5399 52.6378 77.3595L53.6946 72.0741C53.7204 71.8937 53.6174 71.7133 53.4112 71.6616C53.2307 71.6361 53.0503 71.7391 52.9987 71.9198L51.9416 77.2046C51.9158 77.4108 52.0446 77.5657 52.2253 77.6171Z"
      fill="#fff"
    />
    <path
      d="M50.3429 73.9564C50.5494 74.0081 50.6782 74.2143 50.6266 74.4206L49.982 76.7409C49.9306 76.9472 49.7244 77.0502 49.5181 76.9988C49.469 76.987 49.4227 76.9656 49.3818 76.9359C49.341 76.9062 49.3064 76.8687 49.28 76.8256C49.2537 76.7825 49.2361 76.7346 49.2283 76.6847C49.2204 76.6348 49.2225 76.5838 49.2344 76.5347L49.879 74.2143C49.9306 74.0081 50.1369 73.9051 50.3431 73.9564H50.3429Z"
      fill="#F3F3F3"
    />
    <path
      d="M50.3429 73.9306C50.5494 73.982 50.7041 74.214 50.6266 74.4203L49.982 76.7407C49.9307 76.9469 49.6986 77.076 49.4924 77.0241C49.2861 76.9727 49.1315 76.7407 49.2087 76.5344L49.8532 74.214C49.9049 74.0078 50.1111 73.8787 50.3429 73.9303V73.9306ZM49.4924 76.9469C49.6728 76.9985 49.8791 76.8953 49.9307 76.7148L50.575 74.3945C50.6266 74.214 50.5236 74.0336 50.3174 73.982C50.1369 73.9303 49.9307 74.0336 49.8791 74.214L49.2345 76.5344C49.2087 76.7148 49.3117 76.8953 49.4924 76.9469Z"
      fill="#fff"
    />
    <path
      d="M49.0799 70.3213C49.2862 70.3985 49.3634 70.6305 49.2862 70.811L47.2237 75.7868C47.1465 75.9675 46.9142 76.0705 46.734 75.9675C46.5533 75.8642 46.45 75.658 46.5275 75.4775L48.59 70.5017C48.6672 70.3213 48.8995 70.218 49.0797 70.3213H49.0799Z"
      fill="#F3F3F3"
    />
    <path
      d="M49.08 70.2953C49.1791 70.333 49.2591 70.4085 49.3026 70.5052C49.3461 70.6019 49.3495 70.7119 49.3121 70.811L47.2496 75.7866C47.1721 75.9928 46.9401 76.0703 46.708 75.9928C46.609 75.9551 46.529 75.8796 46.4855 75.783C46.442 75.6864 46.4386 75.5765 46.4759 75.4773L48.5384 70.5015C48.6417 70.2953 48.8738 70.1923 49.08 70.2953ZM46.7338 75.9415C46.9142 76.0187 47.1205 75.9415 47.1979 75.761L49.2604 70.7852C49.3376 70.6048 49.2346 70.4241 49.0542 70.3469C48.8738 70.2697 48.6675 70.3469 48.5901 70.5273L46.5276 75.5032C46.4759 75.6578 46.5534 75.864 46.7338 75.9415Z"
      fill="#fff"
    />
    <path
      d="M45.5996 71.9711C45.7801 72.074 45.8572 72.2803 45.7801 72.4607L44.6972 74.6007C44.5939 74.7811 44.3877 74.8586 44.1814 74.7553C44.001 74.6523 43.9238 74.4461 44.001 74.2656L45.0841 72.1259C45.1871 71.9452 45.3934 71.8678 45.5996 71.9711Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.5994 71.9456C45.8057 72.0486 45.8828 72.2809 45.7799 72.4869L44.697 74.6268C44.5937 74.8331 44.3617 74.9105 44.1557 74.8073C43.9494 74.7043 43.872 74.4719 43.9752 74.2659L45.0578 72.1263C45.1611 71.9198 45.3932 71.8426 45.5994 71.9456ZM44.1815 74.7559C44.3619 74.8331 44.5682 74.7814 44.6453 74.601L45.7282 72.4613C45.8054 72.3064 45.7282 72.1002 45.5736 71.9972C45.3932 71.92 45.1869 71.9714 45.1095 72.1518L44.0266 74.2915C43.9494 74.4464 44.0266 74.6526 44.1815 74.7556V74.7559Z"
      fill="#fff"
    />
    <path
      d="M45.0836 68.1555C45.2641 68.2843 45.3157 68.5164 45.1869 68.671L42.1961 73.1313C42.0931 73.3115 41.8608 73.3373 41.6806 73.2343C41.5002 73.1053 41.4486 72.8735 41.5774 72.7186L44.5681 68.2583C44.6711 68.0781 44.9032 68.0265 45.0836 68.1553V68.1555Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.084 68.1296C45.2644 68.2587 45.316 68.5166 45.2128 68.697L42.2223 73.1573C42.0935 73.3375 41.8614 73.3891 41.6552 73.2603C41.4747 73.1312 41.4231 72.8736 41.5261 72.6929L44.5168 68.2329C44.6456 68.0524 44.9035 68.0008 45.084 68.1296ZM41.681 73.2087C41.8353 73.3117 42.0677 73.2859 42.1706 73.1315L45.1614 68.6712C45.2644 68.5166 45.213 68.3103 45.0581 68.2071C44.9035 68.1041 44.6715 68.1299 44.5685 68.2842L41.5777 72.7187C41.4747 72.8736 41.5261 73.1054 41.681 73.2084V73.2087Z"
      fill="#fff"
    />
    <path
      d="M41.3196 69.1097C41.4745 69.2385 41.5259 69.4706 41.3971 69.6252L39.9017 71.5072C39.7729 71.6619 39.5408 71.6877 39.3862 71.5589C39.2316 71.4301 39.1799 71.198 39.309 71.0431L40.8041 69.161C40.9329 69.0067 41.165 68.9806 41.3196 69.1097Z"
      fill="#F3F3F3"
    />
    <path
      d="M41.3456 69.0835C41.526 69.2123 41.5518 69.4702 41.4227 69.6506L39.9276 71.5327C39.7988 71.7131 39.5409 71.739 39.3605 71.5843C39.1801 71.4553 39.1543 71.1977 39.2833 71.017L40.7784 69.1352C40.9072 68.9545 41.1651 68.9289 41.3456 69.0835ZM39.4121 71.5327C39.5665 71.6615 39.773 71.636 39.9018 71.4811L41.3972 69.599C41.5004 69.4447 41.4744 69.2381 41.32 69.1093C41.1651 68.9803 40.9589 69.0061 40.8301 69.161L39.3347 71.0428C39.2317 71.1977 39.2575 71.4295 39.4119 71.5327H39.4121Z"
      fill="#fff"
    />
    <path
      d="M41.5774 65.2424C41.732 65.3967 41.732 65.6291 41.5774 65.7579L37.7619 69.5476C37.607 69.7024 37.3752 69.6766 37.2462 69.5476C37.1173 69.4187 37.0915 69.1609 37.2462 69.0321L41.0358 65.2424C41.1907 65.0875 41.4228 65.0875 41.5774 65.2424Z"
      fill="#F3F3F3"
    />
    <path
      d="M41.6033 65.2166C41.6775 65.2923 41.7192 65.394 41.7192 65.5C41.7192 65.6061 41.6775 65.7078 41.6033 65.7835L37.7875 69.5737C37.7119 69.648 37.6101 69.6896 37.5041 69.6896C37.3981 69.6896 37.2963 69.648 37.2206 69.5737C37.1462 69.498 37.1045 69.3961 37.1045 69.29C37.1045 69.1838 37.1462 69.0819 37.2206 69.0063L41.0361 65.2166C41.1118 65.1423 41.2136 65.1006 41.3197 65.1006C41.4258 65.1006 41.5276 65.1423 41.6033 65.2166ZM37.2462 69.5476C37.375 69.6764 37.6071 69.6764 37.7359 69.5476L41.5516 65.7576C41.6807 65.6288 41.6807 65.3968 41.5516 65.268C41.4228 65.1389 41.1908 65.1389 41.062 65.268L37.2462 69.0577C37.1174 69.1865 37.1174 69.393 37.2462 69.5473V69.5476Z"
      fill="#fff"
    />
    <path
      d="M37.7102 65.4487C37.839 65.6031 37.839 65.8354 37.6844 65.9642L35.854 67.5112C35.6994 67.64 35.4673 67.6142 35.3382 67.4596C35.2094 67.3047 35.2094 67.0729 35.364 66.9438L37.1945 65.3971C37.3494 65.268 37.5812 65.2938 37.7102 65.4487Z"
      fill="#F3F3F3"
    />
    <path
      d="M37.736 65.4226C37.8906 65.6033 37.8648 65.861 37.7101 65.99L35.8797 67.5368C35.7251 67.6917 35.4672 67.6658 35.3126 67.4854C35.1577 67.3047 35.1835 67.0471 35.3381 66.918L37.1688 65.3713C37.3232 65.2167 37.5813 65.2425 37.7357 65.4229L37.736 65.4226ZM35.3639 67.4596C35.493 67.6142 35.6993 67.6142 35.8536 67.5112L37.6843 65.9642C37.8131 65.8354 37.8389 65.6289 37.7101 65.4745C37.5811 65.3197 37.3748 65.3197 37.2205 65.4229L35.3898 66.9697C35.2351 67.0985 35.2351 67.305 35.3639 67.4593V67.4596Z"
      fill="#fff"
    />
    <path
      d="M38.7418 61.736C38.8706 61.9167 38.8189 62.1485 38.6385 62.2517L34.1526 65.2425C33.9722 65.3455 33.7401 65.2938 33.6371 65.1392C33.5081 64.9588 33.5597 64.7267 33.7401 64.6235L38.2263 61.633C38.3812 61.53 38.613 61.5813 38.742 61.736H38.7418Z"
      fill="#F3F3F3"
    />
    <path
      d="M38.7674 61.7359C38.8962 61.9166 38.8446 62.1742 38.6642 62.3033L34.1783 65.2937C33.9979 65.4228 33.74 65.3709 33.6112 65.1649C33.4821 64.9845 33.534 64.7266 33.7142 64.5978L38.2003 61.6071C38.3808 61.4783 38.6384 61.5299 38.7672 61.7359H38.7674ZM33.6628 65.1133C33.7658 65.2679 33.9721 65.3196 34.1267 65.2166L38.6126 62.2258C38.7674 62.1228 38.8188 61.9166 38.69 61.7361C38.587 61.5812 38.3808 61.5299 38.2259 61.6329L33.74 64.6236C33.5854 64.7524 33.5596 64.9587 33.6628 65.1133Z"
      fill="#fff"
    />
    <path
      d="M34.9 61.1945C35.0033 61.375 34.9516 61.607 34.7712 61.7103L32.6829 62.8962C32.5024 62.9991 32.2704 62.922 32.1674 62.7415C32.0641 62.5611 32.1158 62.329 32.2962 62.2258L34.3845 61.0399C34.5649 60.9367 34.797 61.0141 34.9003 61.1945H34.9Z"
      fill="#F3F3F3"
    />
    <path
      d="M34.9261 61.1687C35.0291 61.3749 34.9774 61.607 34.797 61.7102L32.7087 62.8961C32.5283 62.9991 32.2704 62.9219 32.1674 62.7415C32.0641 62.5352 32.1158 62.3032 32.2962 62.1999L34.3845 61.014C34.5649 60.9108 34.8228 60.9882 34.9258 61.1687H34.9261ZM32.1929 62.7157C32.2962 62.8961 32.5024 62.9477 32.6571 62.8445L34.7454 61.6586C34.9 61.5553 34.9516 61.3491 34.8744 61.1945C34.7712 61.014 34.5649 60.9624 34.4103 61.0657L32.322 62.2515C32.1674 62.3287 32.1158 62.5352 32.1932 62.7157H32.1929Z"
      fill="#fff"
    />
    <path
      d="M36.6275 57.7659C36.7047 57.9721 36.6275 58.1784 36.4212 58.2556L31.4454 60.3181C31.265 60.3955 31.0329 60.2925 30.9557 60.1118C30.8783 59.9055 30.9557 59.6993 31.162 59.6221L36.1378 57.5596C36.3182 57.4824 36.5503 57.5596 36.6275 57.7659Z"
      fill="#F3F3F3"
    />
    <path
      d="M36.6533 57.74C36.7305 57.9462 36.6533 58.1783 36.447 58.2813L31.4712 60.3438C31.3721 60.3812 31.2622 60.3777 31.1655 60.3342C31.0689 60.2907 30.9935 60.2107 30.9557 60.1117C30.8783 59.9055 30.9557 59.6734 31.162 59.5704L36.1378 57.5079C36.2369 57.4705 36.3469 57.474 36.4435 57.5175C36.5401 57.561 36.6155 57.641 36.6533 57.74ZM31.0071 60.0859C31.0845 60.2666 31.2908 60.3441 31.4454 60.2922L36.4212 58.2297C36.6017 58.1525 36.6791 57.9462 36.6017 57.7658C36.5245 57.5854 36.3182 57.5079 36.1633 57.5595L31.1875 59.622C31.0071 59.6992 30.9299 59.9055 31.0071 60.0862V60.0859Z"
      fill="#fff"
    />
    <path
      d="M32.9925 56.4767C33.0697 56.6829 32.9667 56.8892 32.7604 56.9663L30.4917 57.7142C30.3113 57.7913 30.0792 57.6625 30.0276 57.4821C29.9504 57.2759 30.0534 57.0696 30.2596 56.9924L32.5284 56.2446C32.7088 56.1674 32.9151 56.2704 32.9925 56.4767Z"
      fill="#F3F3F3"
    />
    <path
      d="M33.0181 56.4506C33.0956 56.6569 32.9926 56.8889 32.7605 56.9664L30.4918 57.7139C30.2855 57.7911 30.0534 57.6623 29.976 57.4561C29.8988 57.2498 30.0018 57.0177 30.2341 56.9406L32.5026 56.1928C32.7089 56.1414 32.9409 56.2444 33.0184 56.4509L33.0181 56.4506ZM30.0279 57.4305C30.0795 57.611 30.2858 57.7142 30.4662 57.6626L32.735 56.9148C32.9154 56.8634 32.9928 56.6571 32.9412 56.4767C32.8896 56.2963 32.6833 56.193 32.5029 56.2446L30.2341 56.9925C30.0537 57.0696 29.9763 57.2501 30.0279 57.4305Z"
      fill="#fff"
    />
    <path
      d="M35.3129 53.4087C35.3643 53.6149 35.2355 53.8212 35.0292 53.847L29.7439 54.8782C29.5376 54.9299 29.3572 54.775 29.3058 54.5687C29.2542 54.3625 29.383 54.1562 29.5893 54.1304L34.8746 53.0992C35.0808 53.0736 35.2871 53.2024 35.3126 53.4087H35.3129Z"
      fill="#F3F3F3"
    />
    <path
      d="M35.3643 53.4087C35.416 53.6408 35.2614 53.8471 35.0551 53.8984L29.7698 54.9297C29.5635 54.9813 29.3573 54.8267 29.3059 54.5946C29.2543 54.3625 29.4089 54.1563 29.6152 54.1049L34.9005 53.0737C35.1065 53.0221 35.313 53.1767 35.3643 53.4087ZM29.3573 54.5691C29.3831 54.7492 29.5635 54.8783 29.744 54.8525L35.0293 53.8212C35.2097 53.7954 35.3385 53.5892 35.2872 53.4087C35.2614 53.2283 35.0809 53.0992 34.9002 53.125L29.6152 54.1563C29.4347 54.2079 29.3059 54.3884 29.3573 54.5688V54.5691Z"
      fill="#fff"
    />
    <path
      d="M32.013 51.4238C32.0386 51.6298 31.9098 51.8105 31.7035 51.8363L29.3318 52.1198C29.1253 52.1456 28.9448 51.991 28.9193 51.7844C28.8935 51.5785 29.0223 51.398 29.2285 51.3719L31.6005 51.0885C31.781 51.0627 31.9872 51.2173 32.013 51.4238Z"
      fill="#F3F3F3"
    />
    <path
      d="M32.0388 51.4235C32.0644 51.6553 31.9098 51.8616 31.7035 51.8874L29.3318 52.1711C29.1252 52.1969 28.9193 52.0423 28.8934 51.8102C28.8676 51.5781 29.0223 51.3719 29.2285 51.3461L31.6005 51.0624C31.8065 51.0366 32.013 51.1914 32.0386 51.4232L32.0388 51.4235ZM28.919 51.7844C28.9448 51.9648 29.1255 52.1197 29.3059 52.0936L31.6777 51.8102C31.8581 51.7844 31.9872 51.6039 31.9614 51.4235C31.9356 51.2428 31.7551 51.0882 31.5747 51.114L29.2027 51.3977C29.0223 51.4235 28.8934 51.6039 28.919 51.7844Z"
      fill="#fff"
    />
    <path
      d="M34.8998 48.9226C34.8998 49.1291 34.7455 49.3095 34.539 49.3095H29.1509C28.9447 49.3095 28.79 49.1291 28.79 48.9228C28.79 48.7166 28.9447 48.5359 29.1509 48.5359H34.5392C34.7455 48.5359 34.9004 48.7166 34.9004 48.9228L34.8998 48.9226Z"
      fill="#F3F3F3"
    />
    <path
      d="M34.9263 48.9225C34.9263 49.1548 34.7456 49.335 34.5394 49.335H29.1513C28.9451 49.335 28.7646 49.1546 28.7646 48.9225C28.7646 48.6907 28.9451 48.51 29.1513 48.51H34.5396C34.7459 48.51 34.9263 48.6907 34.9263 48.9225ZM28.816 48.897C28.816 49.0774 28.9706 49.2578 29.1513 49.2578H34.5394C34.7198 49.2578 34.8747 49.1032 34.8747 48.897C34.8747 48.7165 34.7198 48.5358 34.5394 48.5358H29.1513C28.9709 48.5616 28.8163 48.7165 28.8163 48.897H28.816Z"
      fill="#fff"
    />
    <path
      d="M32.0386 46.3188C32.0125 46.525 31.8579 46.6796 31.6517 46.6796L29.2538 46.4992C29.0479 46.4734 28.8932 46.293 28.9188 46.0867C28.9446 45.8805 29.0995 45.7258 29.3057 45.7258L31.7033 45.9063C31.8837 45.9321 32.0386 46.1125 32.0386 46.3188Z"
      fill="#E2E2E2"
    />
    <path
      d="M32.064 46.3187C32.0382 46.5508 31.8578 46.7054 31.6515 46.7054L29.2537 46.525C29.0477 46.4991 28.8673 46.3187 28.8931 46.0866C28.9186 45.8548 29.0993 45.7 29.3056 45.7L31.7032 45.8804C31.9094 45.9062 32.064 46.1125 32.064 46.3187ZM28.9186 46.0866C28.8931 46.2671 29.0477 46.4478 29.2282 46.4478L31.626 46.6282C31.8061 46.6282 31.961 46.4991 31.9868 46.2929C32.0127 46.1125 31.8578 45.932 31.6773 45.932L29.2795 45.7516C29.0993 45.7516 28.9445 45.9062 28.9186 46.0869V46.0866Z"
      fill="#fff"
    />
    <path
      d="M35.3642 44.4366C35.3125 44.6429 35.1321 44.7719 34.9258 44.7461L29.6408 43.6632C29.4345 43.6116 29.3055 43.4312 29.3571 43.2249C29.4087 43.0187 29.5892 42.8896 29.7954 42.9157L35.0807 43.9728C35.2612 44.0241 35.39 44.2304 35.3642 44.4366Z"
      fill="#F3F3F3"
    />
    <path
      d="M35.3902 44.4365C35.3386 44.6685 35.1323 44.7976 34.9261 44.7718L29.641 43.7147C29.4348 43.6631 29.2802 43.4569 29.3318 43.2251C29.3832 42.9927 29.5894 42.8639 29.7957 42.8897L35.081 43.9468C35.2872 43.9984 35.416 44.2047 35.3902 44.4365ZM29.3832 43.2248C29.3573 43.4052 29.4603 43.5857 29.641 43.6373L34.9264 44.6944C35.1068 44.7202 35.2872 44.6172 35.3389 44.4107C35.3644 44.2302 35.2614 44.0498 35.0807 43.9982L29.7959 42.9414C29.5897 42.9156 29.4351 43.0444 29.3834 43.2248H29.3832Z"
      fill="#fff"
    />
    <path
      d="M33.0442 41.3431C33.0324 41.3922 33.011 41.4385 32.9812 41.4794C32.9515 41.5202 32.914 41.5547 32.8709 41.5811C32.8278 41.6074 32.7799 41.625 32.73 41.6328C32.6801 41.6406 32.6292 41.6385 32.5801 41.6266L30.2597 40.982C30.0535 40.9304 29.9505 40.7241 30.0018 40.5179C30.0137 40.4688 30.0351 40.4225 30.0648 40.3817C30.0945 40.3408 30.132 40.3063 30.1751 40.2799C30.2183 40.2536 30.2661 40.2361 30.316 40.2283C30.3659 40.2204 30.4169 40.2226 30.466 40.2345L32.7863 40.879C32.9926 40.9304 33.0956 41.1366 33.0442 41.3429V41.3431Z"
      fill="#F3F3F3"
    />
    <path
      d="M33.0695 41.3433C33.0181 41.5493 32.7861 41.7042 32.5798 41.6267L30.2594 40.9822C30.0532 40.9305 29.9241 40.6985 29.9757 40.4925C30.0274 40.286 30.2594 40.1313 30.4657 40.2088L32.7861 40.8534C32.9923 40.905 33.1214 41.1112 33.0695 41.343V41.3433ZM30.0532 40.492C30.0016 40.6727 30.1048 40.8789 30.2852 40.9303L32.6056 41.5748C32.7861 41.6265 32.9665 41.5232 33.0181 41.317C33.0695 41.1365 32.9665 40.9303 32.7861 40.8789L30.4657 40.2343C30.2852 40.2085 30.1048 40.3115 30.0532 40.4922V40.492Z"
      fill="#fff"
    />
    <path
      d="M36.6794 40.1056C36.6022 40.3116 36.3699 40.389 36.1897 40.3116L31.2136 38.2491C31.0332 38.1719 30.9302 37.9398 31.0074 37.7594C31.0848 37.5531 31.3169 37.4757 31.4973 37.5531L36.4731 39.6156C36.6794 39.6928 36.7568 39.9249 36.6794 40.1053V40.1056Z"
      fill="#F3F3F3"
    />
    <path
      d="M36.7047 40.1313C36.667 40.2304 36.5915 40.3105 36.4948 40.354C36.3981 40.3975 36.2881 40.4009 36.189 40.3634L31.2131 38.3009C31.0069 38.2237 30.9297 37.9917 31.0069 37.7596C31.0446 37.6605 31.1201 37.5805 31.2168 37.537C31.3135 37.4935 31.4235 37.4901 31.5226 37.5275L36.4985 39.59C36.7047 39.693 36.7819 39.9254 36.7047 40.1313ZM31.0585 37.7854C30.9813 37.9658 31.0585 38.1721 31.239 38.2493L36.2148 40.3118C36.3952 40.3892 36.5756 40.286 36.6531 40.1055C36.7303 39.9251 36.6531 39.7188 36.4726 39.6417L31.4968 37.5792C31.3164 37.5275 31.1101 37.605 31.0585 37.7854Z"
      fill="#fff"
    />
    <path
      d="M35.0034 36.6251C34.9004 36.8055 34.6941 36.883 34.5137 36.8055L32.3737 35.7229C32.1933 35.6455 32.1159 35.4134 32.2191 35.2072C32.3221 35.0267 32.5284 34.9493 32.7088 35.0267L34.8485 36.1096C35.0292 36.2126 35.1066 36.4449 35.0034 36.6251Z"
      fill="#F3F3F3"
    />
    <path
      d="M35.0293 36.6509C34.926 36.8572 34.694 36.9346 34.4877 36.8314L32.3481 35.7485C32.1418 35.6455 32.0644 35.4134 32.1676 35.2072C32.2706 35.0009 32.5029 34.9235 32.7089 35.0267L34.8486 36.1096C35.0551 36.1868 35.1323 36.4449 35.0293 36.6509ZM32.2448 35.233C32.1676 35.4134 32.2192 35.6197 32.3997 35.6971L34.5394 36.78C34.6942 36.8572 34.9005 36.78 35.0035 36.6251C35.0807 36.4447 35.0293 36.2384 34.8489 36.161L32.6828 35.0786C32.5282 35.0015 32.322 35.0528 32.2445 35.2333L32.2448 35.233Z"
      fill="#fff"
    />
    <path
      d="M38.7928 36.1352C38.664 36.3159 38.4578 36.3675 38.2773 36.2385L33.817 33.2738C33.6369 33.1705 33.611 32.9385 33.714 32.758C33.8173 32.5776 34.0494 32.526 34.2298 32.6548L38.7157 35.6455C38.8703 35.7488 38.9219 35.9808 38.7928 36.1352Z"
      fill="#F3F3F3"
    />
    <path
      d="M38.8186 36.161C38.6898 36.3417 38.432 36.3931 38.2518 36.2901L33.7915 33.2993C33.611 33.1705 33.5594 32.9385 33.6882 32.7325C33.8173 32.5518 34.0752 32.5002 34.2556 32.6034L38.7157 35.5939C38.9219 35.723 38.9477 35.9806 38.8186 36.161ZM33.7398 32.758C33.6368 32.9127 33.6627 33.1447 33.817 33.2477L38.3032 36.2387C38.4578 36.342 38.664 36.2904 38.7673 36.1357C38.8703 35.9809 38.8445 35.7488 38.6901 35.6458L34.2037 32.655C34.0491 32.5518 33.8428 32.6034 33.7396 32.758H33.7398Z"
      fill="#fff"
    />
    <path
      d="M37.8392 32.3968C37.7104 32.5515 37.4784 32.6031 37.3238 32.474L35.4417 31.0047C35.2871 30.8757 35.2613 30.6436 35.3901 30.489C35.5189 30.3343 35.7509 30.2827 35.9058 30.4118L37.7876 31.8811C37.9425 32.0102 37.9683 32.242 37.8392 32.3968Z"
      fill="#F3F3F3"
    />
    <path
      d="M37.8649 32.4225C37.7361 32.6032 37.4782 32.629 37.2977 32.4999L35.4157 31.0306C35.2352 30.9018 35.2094 30.6439 35.3385 30.4638C35.4673 30.2831 35.7252 30.2573 35.9054 30.3863L37.7874 31.8556C37.9681 31.9847 37.9939 32.2423 37.8649 32.423V32.4225ZM35.3899 30.4891C35.2611 30.6437 35.2869 30.8499 35.4415 30.9787L37.3236 32.4486C37.4782 32.5516 37.6844 32.5257 37.8132 32.3711C37.9423 32.2165 37.9165 32.0103 37.7619 31.8814L35.8798 30.4116C35.7252 30.3086 35.5187 30.3344 35.3901 30.4891H35.3899Z"
      fill="#fff"
    />
    <path
      d="M41.6804 32.6546C41.5261 32.8095 41.2937 32.8095 41.1649 32.6546L37.3239 28.8649C37.1693 28.7103 37.1948 28.4782 37.3239 28.3492C37.4785 28.1948 37.7106 28.1948 37.8394 28.3492L41.6551 32.1391C41.8098 32.2679 41.8098 32.5258 41.681 32.6549L41.6804 32.6546Z"
      fill="#F3F3F3"
    />
    <path
      d="M41.6807 32.6807C41.605 32.7549 41.5033 32.7965 41.3972 32.7965C41.2912 32.7965 41.1895 32.7549 41.1138 32.6807L37.2978 28.8904C37.2234 28.8148 37.1816 28.7129 37.1816 28.6068C37.1816 28.5006 37.2234 28.3987 37.2978 28.3231C37.3734 28.2487 37.4753 28.207 37.5814 28.207C37.6874 28.207 37.7893 28.2487 37.8649 28.3231L41.6807 32.113C41.8611 32.2676 41.8611 32.5255 41.6807 32.6801V32.6807ZM37.35 28.3747C37.2209 28.5038 37.2209 28.7358 37.35 28.8644L41.1655 32.6543C41.2945 32.7834 41.5263 32.7834 41.6551 32.6543C41.7842 32.5255 41.7842 32.2934 41.6551 32.1646L37.8396 28.3491C37.7108 28.2203 37.5043 28.2203 37.35 28.375V28.3747Z"
      fill="#fff"
    />
    <path
      d="M41.4487 28.8133C41.2941 28.9424 41.062 28.9424 40.933 28.7875L39.3601 26.9829C39.2316 26.8282 39.2572 26.5962 39.412 26.4674C39.5664 26.3383 39.7987 26.3383 39.9275 26.4929L41.5004 28.2978C41.6292 28.4266 41.6033 28.6587 41.4487 28.8133Z"
      fill="#F3F3F3"
    />
    <path
      d="M41.4744 28.8134C41.2939 28.968 41.0619 28.9425 40.907 28.7876L39.3344 26.9829C39.1798 26.8283 39.2056 26.5704 39.386 26.4158C39.5665 26.2609 39.7985 26.2868 39.9532 26.4416L41.526 28.246C41.6548 28.4267 41.6548 28.6846 41.4744 28.8134ZM39.4119 26.4675C39.257 26.5963 39.257 26.8025 39.3602 26.9571L40.9331 28.7618C41.0619 28.8908 41.2681 28.9166 41.4227 28.7876C41.5774 28.6588 41.5774 28.4522 41.4744 28.2979L39.9015 26.493C39.7727 26.3387 39.5662 26.3387 39.4119 26.4675Z"
      fill="#fff"
    />
    <path
      d="M45.161 29.7932C44.9806 29.922 44.7485 29.8703 44.6452 29.6899L41.6289 25.2296C41.5257 25.0491 41.5773 24.8171 41.7319 24.7141C41.9124 24.5853 42.1444 24.6369 42.2477 24.8173L45.2382 29.2774C45.3672 29.4578 45.3156 29.6899 45.161 29.7929V29.7932Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.1613 29.8188C44.9808 29.9479 44.723 29.896 44.5939 29.7158L41.6034 25.2555C41.4746 25.0751 41.5262 24.8175 41.7322 24.6887C41.9126 24.5596 42.1705 24.6112 42.2996 24.7916L45.2901 29.2517C45.4189 29.458 45.3673 29.69 45.1613 29.8188ZM41.7578 24.74C41.6031 24.843 41.5515 25.0493 41.6548 25.2041L44.6453 29.6642C44.7485 29.8188 44.9548 29.8446 45.1349 29.7414C45.2898 29.6384 45.3414 29.4321 45.2382 29.2775L42.248 24.843C42.1447 24.6887 41.9126 24.6368 41.7583 24.74H41.7578Z"
      fill="#fff"
    />
    <path
      d="M45.7023 25.9773C45.5219 26.0803 45.2899 26.0289 45.1869 25.8485L44.0007 23.7602C43.8977 23.5797 43.9749 23.3477 44.1556 23.2444C44.336 23.1414 44.5681 23.1928 44.6711 23.3735L45.857 25.4615C45.9344 25.642 45.8828 25.874 45.7026 25.9773H45.7023Z"
      fill="#F3F3F3"
    />
    <path
      d="M45.7027 26.0031C45.4965 26.1061 45.2644 26.0547 45.1614 25.874L43.9753 23.786C43.8723 23.6053 43.9239 23.3477 44.1302 23.2444C44.3364 23.1414 44.5685 23.1928 44.6715 23.3735L45.8573 25.4615C45.9606 25.642 45.909 25.874 45.703 26.0031H45.7027ZM44.156 23.2702C44.0011 23.3732 43.9239 23.5795 44.0269 23.7343L45.213 25.8227C45.316 25.977 45.5223 26.0289 45.6769 25.9515C45.8318 25.8485 45.909 25.642 45.806 25.4873L44.6198 23.399C44.5427 23.2447 44.3106 23.167 44.156 23.2702Z"
      fill="#fff"
    />
    <path
      d="M49.1312 27.6789C48.9253 27.7561 48.7187 27.6789 48.6416 27.4985L46.5533 22.5224C46.4761 22.342 46.5791 22.1099 46.7595 22.0325C46.9658 21.9553 47.172 22.0325 47.2492 22.2129L49.3375 27.1887C49.4149 27.3692 49.3119 27.6012 49.1312 27.6784V27.6789Z"
      fill="#F3F3F3"
    />
    <path
      d="M49.1315 27.705C48.9255 27.7821 48.6935 27.705 48.5902 27.4984L46.5019 22.5229C46.4644 22.4237 46.4678 22.3137 46.5113 22.2171C46.5548 22.1204 46.6349 22.0449 46.734 22.0071C46.9402 21.93 47.1723 22.0071 47.2755 22.2134L49.3636 27.1892C49.441 27.3696 49.338 27.6017 49.1318 27.705H49.1315ZM46.7856 22.0588C46.6052 22.1359 46.5277 22.3425 46.6052 22.4971L48.6932 27.4726C48.7704 27.6531 48.9766 27.7302 49.1571 27.6531C49.3375 27.5759 49.4152 27.3694 49.3375 27.2147L47.2495 22.2389C47.1462 22.0585 46.9658 21.9811 46.7853 22.0585L46.7856 22.0588Z"
      fill="#fff"
    />
    <path
      d="M50.3946 24.0183C50.1884 24.0955 49.9821 23.9922 49.9049 23.7862L49.1571 21.5172C49.0799 21.3368 49.2087 21.1047 49.3892 21.0533C49.5696 21.0017 49.8017 21.0791 49.8788 21.2854L50.6267 23.5541C50.7038 23.7346 50.6009 23.9408 50.3946 24.018V24.0183Z"
      fill="#F3F3F3"
    />
    <path
      d="M50.4202 24.044C50.3211 24.0814 50.2111 24.0779 50.1145 24.0344C50.0179 23.9909 49.9425 23.9109 49.9047 23.8119L49.1569 21.5432C49.0797 21.3369 49.2085 21.1051 49.415 21.0277C49.5142 20.9903 49.6241 20.9938 49.7207 21.0373C49.8173 21.0808 49.8928 21.1608 49.9305 21.2598L50.6781 23.5285C50.7297 23.7348 50.6265 23.9668 50.4202 24.044ZM49.4148 21.0793C49.2343 21.1307 49.1311 21.3369 49.1827 21.5174L49.9305 23.7864C49.9819 23.9668 50.1881 24.044 50.3688 23.9924C50.5493 23.941 50.6523 23.7348 50.6006 23.5543L49.8531 21.2853C49.8399 21.2415 49.8177 21.201 49.7879 21.1664C49.7581 21.1318 49.7213 21.1039 49.6799 21.0844C49.6386 21.0649 49.5936 21.0544 49.5479 21.0535C49.5022 21.0526 49.4569 21.0613 49.4148 21.0791V21.0793Z"
      fill="#fff"
    />
    <path
      d="M53.437 26.3642C53.2307 26.4158 53.0245 26.287 52.9986 26.0805L51.9158 20.7954C51.8641 20.5892 52.019 20.4087 52.225 20.3571C52.4315 20.3055 52.6375 20.4343 52.6633 20.6408L53.7465 25.9261C53.772 26.1063 53.6432 26.3125 53.437 26.3642Z"
      fill="#F3F3F3"
    />
    <path
      d="M53.4368 26.39C53.2048 26.4416 52.9985 26.2867 52.9469 26.0805L51.864 20.7954C51.8124 20.5892 51.9673 20.3571 52.1735 20.3313C52.4056 20.2797 52.6118 20.4346 52.6632 20.6408L53.7463 25.9261C53.7977 26.1321 53.6689 26.3386 53.4368 26.39ZM52.2251 20.3829C52.0444 20.4087 51.9156 20.5892 51.9415 20.7954L53.0243 26.0807C53.0501 26.2609 53.2561 26.39 53.4368 26.3384C53.6173 26.3125 53.7461 26.1321 53.7203 25.9259L52.6374 20.6405C52.586 20.4604 52.4056 20.3571 52.2249 20.3829H52.2251Z"
      fill="#fff"
    />
    <path
      d="M55.3964 23.0123C55.1901 23.0381 55.0097 22.9093 54.9581 22.7031L54.6488 20.3311C54.623 20.1248 54.7776 19.9444 54.9841 19.9186C55.1901 19.8927 55.3706 20.0218 55.4222 20.2281L55.7314 22.5998C55.7572 22.8061 55.6026 22.9865 55.3964 23.0123Z"
      fill="#F3F3F3"
    />
    <path
      d="M55.3963 23.0383C55.1642 23.0641 54.958 22.9095 54.9322 22.703L54.6229 20.3312C54.5971 20.125 54.7517 19.9187 54.9838 19.8929C55.2159 19.8674 55.4221 20.022 55.4479 20.2282L55.7572 22.6C55.783 22.8062 55.6284 23.0125 55.3963 23.0383ZM55.0096 19.9445C54.8292 19.9703 54.6743 20.1508 54.7001 20.3312L55.0096 22.7032C55.0354 22.8837 55.2159 23.0125 55.3963 22.9867C55.5767 22.9611 55.7316 22.7804 55.7058 22.6L55.3963 20.2282C55.3705 20.0478 55.19 19.9187 55.0096 19.9445Z"
      fill="#fff"
    />
    <path
      d="M58.1808 79.4992C75.0393 79.4992 88.7058 65.8327 88.7058 48.9742C88.7058 32.1157 75.0393 18.4492 58.1808 18.4492C41.3223 18.4492 27.6558 32.1157 27.6558 48.9742C27.6558 65.8327 41.3223 79.4992 58.1808 79.4992Z"
      fill="url(#safari-paint2_linear)"
      fillOpacity="0.2"
    />
    <path
      d="M79.3212 29.6125L54.7001 45.3908H54.6742V45.4164L54.6484 45.4422L39.2832 70.7854L61.8674 52.6096L61.8933 52.584V52.5582L79.3215 29.6128L79.3212 29.6125Z"
      fill="#000"
      fillOpacity="0.05"
      filter="url(#safari-filter0_d)"
    />
    <path
      d="M78.857 28.1428L54.5195 45.442L61.7383 52.6091L78.857 28.1428Z"
      fill="#CD151E"
    />
    <path
      d="M54.5454 45.3906L58.1807 48.9741L78.8571 28.1428L54.5454 45.3906Z"
      fill="#FA5153"
    />
    <path
      d="M54.5455 45.3906L61.7643 52.5575L37.4268 69.857L54.5455 45.3904V45.3906Z"
      fill="#ACACAC"
    />
    <path
      d="M37.4268 69.857L58.1806 48.9741L54.5452 45.3904L37.4268 69.857Z"
      fill="#EEE"
    />
    <path
      d="M120.842 54.7208C121.018 56.2746 121.799 57.5234 123.187 58.4673C124.574 59.4112 126.313 59.8832 128.401 59.8832C130.388 59.8832 132.031 59.4257 133.331 58.5109C134.617 57.596 135.26 56.4343 135.26 55.0257C135.26 53.8059 134.799 52.8112 133.879 52.0416C132.959 51.2574 131.476 50.6185 129.431 50.1248L125.334 49.1446C119.666 47.7795 116.833 44.9551 116.833 40.6713C116.833 37.9703 117.906 35.7703 120.053 34.0713C122.186 32.3723 124.947 31.5228 128.336 31.5228C131.666 31.5228 134.383 32.3723 136.487 34.0713C138.59 35.7558 139.678 37.9558 139.751 40.6713H134.975C134.829 39.1175 134.149 37.8832 132.937 36.9683C131.739 36.0825 130.184 35.6396 128.27 35.6396C126.371 35.6396 124.823 36.0752 123.625 36.9465C122.442 37.8178 121.85 38.9505 121.85 40.3446C121.85 41.4482 122.296 42.3558 123.187 43.0673C124.078 43.7644 125.546 44.367 127.591 44.8752L131.075 45.7248C134.317 46.4799 136.662 47.5472 138.108 48.9267C139.539 50.2917 140.255 52.1287 140.255 54.4376C140.255 57.371 139.16 59.7016 136.969 61.4297C134.778 63.1432 131.805 64 128.051 64C124.531 64 121.682 63.165 119.506 61.495C117.315 59.8106 116.146 57.5525 116 54.7208H120.842Z"
      fill="#52535A"
    />
    <path
      d="M152.438 59.9485C154.205 59.9485 155.68 59.4475 156.864 58.4455C158.047 57.429 158.638 56.1439 158.638 54.5901V52.7386L152.854 53.1307C149.567 53.334 147.924 54.4957 147.924 56.6158C147.924 57.6323 148.333 58.4383 149.151 59.0337C149.969 59.6436 151.065 59.9485 152.438 59.9485ZM151.167 63.7168C148.786 63.7168 146.85 63.0779 145.36 61.8C143.871 60.5076 143.126 58.8086 143.126 56.703C143.126 54.6409 143.907 53.0218 145.47 51.8455C147.018 50.6693 149.268 49.9868 152.219 49.798L158.638 49.4277V47.598C158.638 46.2475 158.2 45.2092 157.324 44.4832C156.462 43.7716 155.235 43.4158 153.643 43.4158C152.357 43.4158 151.269 43.7208 150.378 44.3307C149.502 44.9406 148.961 45.7611 148.757 46.7921H144.309C144.382 44.701 145.309 42.9657 147.091 41.5861C148.873 40.2066 151.101 39.5168 153.774 39.5168C156.681 39.5168 158.996 40.2211 160.72 41.6297C162.458 43.0383 163.327 44.9116 163.327 47.2495V63.4772H158.792V59.5347H158.682C158.025 60.8125 157.017 61.829 155.659 62.5842C154.3 63.3393 152.803 63.7168 151.167 63.7168Z"
      fill="#52535A"
    />
    <path
      d="M169.528 43.5465H166.241V39.8218H169.528V37.6436C169.528 35.4218 170.068 33.8099 171.149 32.8079C172.23 31.7914 173.969 31.2832 176.364 31.2832C177.124 31.2832 177.927 31.3558 178.774 31.501V35.095C178.263 34.9789 177.672 34.9208 177 34.9208C176.021 34.9208 175.32 35.1459 174.896 35.596C174.458 36.0462 174.232 36.7795 174.217 37.796V39.8218H178.796V43.5465H174.261V63.4772H169.528V43.5465Z"
      fill="#52535A"
    />
    <path
      d="M190.913 59.9485C192.68 59.9485 194.156 59.4475 195.339 58.4455C196.522 57.429 197.114 56.1439 197.114 54.5901V52.7386L191.329 53.1307C188.043 53.334 186.399 54.4957 186.399 56.6158C186.399 57.6323 186.808 58.4383 187.626 59.0337C188.444 59.6436 189.54 59.9485 190.913 59.9485ZM189.642 63.7168C187.261 63.7168 185.326 63.0779 183.836 61.8C182.346 60.5076 181.601 58.8086 181.601 56.703C181.601 54.6409 182.382 53.0218 183.945 51.8455C185.494 50.6693 187.743 49.9868 190.694 49.798L197.114 49.4277V47.598C197.114 46.2475 196.675 45.2092 195.799 44.4832C194.937 43.7716 193.71 43.4158 192.118 43.4158C190.833 43.4158 189.744 43.7208 188.853 44.3307C187.977 44.9406 187.436 45.7611 187.232 46.7921H182.784C182.857 44.701 183.785 42.9657 185.567 41.5861C187.349 40.2066 189.576 39.5168 192.25 39.5168C195.156 39.5168 197.472 40.2211 199.195 41.6297C200.933 43.0383 201.803 44.9116 201.803 47.2495V63.4772H197.267V59.5347H197.158C196.5 60.8125 195.492 61.829 194.134 62.5842C192.775 63.3393 191.278 63.7168 189.642 63.7168Z"
      fill="#52535A"
    />
    <path
      d="M206.579 39.8218H211.115V43.8079H211.202C211.568 42.4865 212.269 41.4554 213.306 40.7149C214.328 39.9597 215.541 39.5822 216.943 39.5822C217.6 39.5822 218.126 39.6403 218.521 39.7564V44.1782C217.995 43.9749 217.301 43.8733 216.439 43.8733C214.876 43.8733 213.634 44.3743 212.714 45.3762C211.794 46.3637 211.334 47.6924 211.334 49.3624V63.4772H206.579V39.8218Z"
      fill="#52535A"
    />
    <path
      d="M221.829 39.8218H226.584V63.4772H221.829V39.8218ZM224.217 36.4455C223.443 36.4455 222.786 36.1769 222.245 35.6396C221.705 35.1168 221.435 34.4779 221.435 33.7228C221.435 32.9531 221.705 32.3069 222.245 31.7842C222.786 31.2614 223.443 31 224.217 31C224.992 31 225.649 31.2614 226.189 31.7842C226.73 32.3069 227 32.9531 227 33.7228C227 34.4779 226.73 35.1168 226.189 35.6396C225.649 36.1769 224.992 36.4455 224.217 36.4455Z"
      fill="#52535A"
    />
    <defs>
      <filter
        id="safari-filter0_d"
        x="0.221"
        y="0.316"
        width="118.163"
        height="119.298"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="9.766" />
        <feGaussianBlur stdDeviation="19.531" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="safari-paint0_linear"
        x1="58"
        y1="82"
        x2="58"
        y2="16"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.25" stopColor="#DBDBDA" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <radialGradient
        id="safari-paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.289 42.295) scale(37.26)">
        <stop stopColor="#2ABCE1" />
        <stop offset="0.114" stopColor="#2ABBE1" />
        <stop offset="1" stopColor="#3375F8" />
      </radialGradient>
      <linearGradient
        id="safari-paint2_linear"
        x1="57.601"
        y1="40.246"
        x2="43.353"
        y2="65.807"
        gradientUnits="userSpaceOnUse">
        <stop stopOpacity="0" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
);

export default SafariLogo;
