import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { CustomInput, SimpleTooltip } from '../../../../components';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import _ from 'lodash';
import {
    MuiThemeProvider,
    FormControl,
    TextField,
    Select,
    InputLabel,
    MenuItem,
  } from '@material-ui/core';
import {
    defaultTheme,
    defaultSelectTheme,
    InputContainer,
    InputIcon,
  } from '../../../../common/styles/Common.styled';
import {
    IconDropDownAuto,
    IconError,
    IconLogoDropDown,
    IconGtnValid,
    IconInfoTooltip,
    IconValid,
    IconPreloader,
  } from '../../../../common/icons/customIcons';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import {
  packageGtinDisplay,
} from '../../../../common/utils/utils';

class PopupNewTechnicalCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          errors: true,
        };
    }

    render() {
        const {
            t, 
            technicalOptions,
            technicalCategories,
            data,
            errors,
            gtinValidation,
          } = this.props;

        const getOptions = (data) => {
          return _.map(data, (item) => {
            return {
              id: item.id,
              path: t(item.text),
            };
          });
        };

        const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);
  
  /* Должно быть от 1 до 100 */
  const getCorrectCount = (value) => {
    return value = value.replace(/[^[0-9]/, '')
  };

  const isEmptyNull = (item) =>
  _.isNumber(item) ? false : _.isNil(item) || _.isEmpty(item) || item === '';

  const isCorrectInterval = data.count > 100 || data.count < 0

        return (
          <div>
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                {/* <MuiThemeProvider theme={CertSelectAuto}>
                  <Autocomplete
                    options={getOptions(technicalOptions)}
                    autoHighlight
                    onChange={(_, item) => item ? this.props.handleChange('optionId', item.id): this.props.handleChange('optionId', '') }
                    getOptionLabel={(item) => item.path}
                    popupIcon={<IconDropDownAuto />}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Выберите опцию')}
                        variant="standard"
                        fullWidth
                        error={true}
                      />
                    )}
                  />
                </MuiThemeProvider> */}
                <MuiThemeProvider theme={defaultSelectTheme}>
                <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="technical-optionss"
                  disabled
                  error={false}>
                  
                  {/* {!selectIsDuplicated
                    ? t('Выберите опцию')
                    : t(
                        'Нельзя выбрать два одинаковых кода вложенной упаковки',
                      )} */}
                      {t('Выберите опцию')}
                </InputLabel>
                <Select
                  required
                  name="technical-options"
                  value={data.optionId}
                  valid={_.toString(!!data.optionId)}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  inputProps={{ id: 'gtin-select' }}
                  IconComponent={IconLogoDropDown}
                  onChange={(e) => this.props.handleChange('optionId', e.target.value.id) }
                  //disabled={lockAllFields}>
                    >
                  {_.map(technicalOptions, (item) => (
                    <MenuItem key={`${item.text}_${item.id}`} value={item}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl> 
                  </MuiThemeProvider>


                {/* <MuiThemeProvider theme={defaultTheme}>
                    <InputContainer>
                      <CustomInput
                        id="gtin"
                        name="gtin"
                        type="text"
                        maxLength={14}
                        // disabled={
                        //   data.count || data.CatId || !data.optionId
                        // }
                        disabled={true}
                        label={t('Укажите штриховой код при наличии')}
                        onUpdate={() => {}}
                        onChange={(value) => this.props.handleChange('gtin', value)}
                        value={data.gtin}
                        hideCleanButton={true}
                      />
                      {errors ? (
                        <InputIcon>
                          <IconError />
                        </InputIcon>
                      ) : !_.isEmpty(gtinValidation.message) ? (
                        SimpleTooltip(gtinValidation.message, <IconError />)
                      ) : gtinValidation.process ? (
                        <InputIcon>
                          <IconPreloader />
                        </InputIcon>
                      ) : gtinValidation.successed ? (
                        <InputIcon>
                          <IconValid />
                        </InputIcon>
                      ) : (
                        SimpleTooltip(
                          t('Код товара состоит из 8, 12 или 13 цифр.'),
                          <IconInfoTooltip />,
                        )
                      )}
                    </InputContainer>
                  </MuiThemeProvider> */}
                </FormControl>
              </MuiThemeProvider>

              <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <MuiThemeProvider theme={CertSelectAuto}>
                  <Autocomplete
                    options={getOptions(technicalCategories)}
                    autoHighlight
                    onChange={(_, item) => item ? this.props.handleChange('CatId', item.id) : this.props.handleChange('CatId', '')}
                    getOptionLabel={(item) => item.path}
                    popupIcon={<IconDropDownAuto />}
                    disabled={data.gtin || !data.optionId}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('Выберите товарную категорию')}
                        variant="standard"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                    noOptionsText={t('Нет доступных товарных категорий')}
                  />
                </MuiThemeProvider>
                
                <MuiThemeProvider theme={defaultTheme}>
                  <InputContainer>
                    <CustomInput
                      id="technicalCount"
                      name="count"
                      type="text"
                      disabled={data.gtin || !data.optionId }
                      label={t('Укажите количество необходимых технических карточек')}
                      onUpdate={() => {}}
                      onChange={(value) => this.props.handleChange('count', getCorrectCount(value))}
                      value={data.count}
                      error={isCorrectInterval}
                    />
                    {isCorrectInterval ? (
                      <InputIcon style={{marginRight: '20px', position: 'absolute', top: '5px'}}>
                        {SimpleTooltip(t('Максимальное количество 100 '),<IconError />)}
                      </InputIcon>
                    ) : null}
                  </InputContainer>
                </MuiThemeProvider>
              </FormControl>
            </MuiThemeProvider>
          </div>
        )
    }
}

PopupNewTechnicalCard.propTypes = {
  updateItem: PropTypes.func.isRequired,
  };
  
  export default compose(withTranslation())(PopupNewTechnicalCard);