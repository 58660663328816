import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductDateIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 12px;
`;
export const NewSearchDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '570px',
        maxWidth: '570px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '18px 10px 46px 40px',
        textAlign: 'center',
        position: 'relative',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px 40px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px 40px 20px 40px',
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '0',
        color: '#52535A',
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        marginTop: '15px',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
  },
});
export const productSearchSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      select: {
        padding: '23px 47px 6px 19px',
      },
      selectMenu: {
        color: '#000',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
        '&$disabled': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
      disabled: {},
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '9px',
        paddingTop: '9px',
        paddingBottom: '9px',
        color: '#52535A',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
  },
});
export const productSearchDate = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '23px 19px 6px',
        lineHeight: '21px',
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
        },
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
