import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductSearchCount = styled.div`
  font-size: 14px;
  color: #868686;
  font-weight: 600;
  padding: 40px 20px 0;
`;
export const ProductSearchTable = styled.div`
    padding-top: 20px;
    .ReactTable{
        border: none;
    }
    .ReactTable .rt-table {    
        min-height: 65px;
    } 
    .ReactTable .rt-tbody{
        overflow: hidden;
    }
    .ReactTable .rt-tr {
        background: #fff!important;
    }
    .ReactTable .rt-noData {       
        position: absolute;
        left: 0;
        top: 61px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 300px;
        transform: translate(0);
        background: rgba(255,255,255,1);
        transition: all .3s ease;
        z-index: 2;
        pointer-events: auto;
        padding: 20px;
        color: rgba(0,0,0,0.5);
        font-weight: 600;
        font-size: 20px;
        color: #52535A;
        button{
            padding: 0;
            background: none;
            box-shadow: none;
            color: #4AA5E8;
            font-size: 20px;
            text-transform: none;
            margin-top: 10px;
            font-weight: 600;
            display: inline-block;
            margin: 0;
            line-height: 3px;
            vertical-align: baseline;
            min-width: auto;
        }
    }
    .ReactTable .rt-thead {
        box-shadow: none!important;
        border-bottom: 1px solid #F2F2F2;
    }
    .ReactTable .rt-thead .rt-th {
        border-bottom: none;
        border-top: 1px solid #F2F2F2!important;
        border-right: 1px solid #F2F2F2!important;
        padding: 0;
        text-align: left;
        align-items: center;
        display: flex;
        font-weight: 500;
        overflow: visible;
        word-break: normal;
        white-space: normal;
        line-height: 14px;
        max-height: 60px;
        
    }
        .ReactTable .rt-tbody .rt-td {
        border-right: 1px solid #F2F2F2!important;
        padding: 0;
        text-align:left;
        min-height: 100px;
        display: flex;
        align-items:center;
        background:#fff;
        position: relative;
        transition: 0.4s ease;
        font-size: 16px;
        word-break: break-word;
        white-space: normal;
        &:nth-last-of-type(1){
            padding: 0;
            overflow: visible;
        }
    }
    .ReactTable .rt-tbody .rt-tr{
        position: relative;

    }
    .ReactTable .rt-tbody .rt-tr:hover .rt-td{
       background:#F9F9F9;
    }
     .ReactTable .rt-tbody .rt-tr:hover button{
       opacity: 1;
`;
export const ProductSearchTableCell = styled.div`
  color: #52535a;
  font-size: 12px;
  font-weight: 600;
  padding: 13px 10px;
`;
export const ProductSearchTableRadio = styled.div`
  padding: 0;
  transform: translateX(8px);
`;
export const ProductSearchDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '1000px',
        maxWidth: '1000px',
        maxHeight: '500px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px 23px 10px',
        textAlign: 'center',
        position: 'relative',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid #F2F2F2',
        padding: '20px',
        justifyContent: 'flex-start',
        margin: '0',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '0',
        color: '#52535A',
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        marginTop: '15px',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
  },
});
