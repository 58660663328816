import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { IconResetFormBig } from '../../../../common/icons/customIcons';
import {
  NoDataIconHolder,
  NoDataInner,
} from '../../../../common/styles/Common.styled';
import { NodataNew } from './EmptyResultComponent.styled';
import { withTranslation } from 'react-i18next';

class EmptyResultComponent extends React.PureComponent {
  render() {
    const { t } = this.props;
    return (
      <NodataNew className="rt-noData">
        <NoDataInner>
          <NoDataIconHolder>
            <IconResetFormBig />
          </NoDataIconHolder>
          <div>
            {t('Нет данных для отображения')}. {t('Заполните форму')}
            <br />
            {t('или')}{' '}
            <Button onClick={this.props.resetForm}>
              {t('сбросьте все данные')}
            </Button>
          </div>
        </NoDataInner>
      </NodataNew>
    );
  }
}

EmptyResultComponent.propTypes = {
  resetForm: PropTypes.func.isRequired,
};

export default withTranslation()(EmptyResultComponent);
