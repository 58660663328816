import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const ConfirmEmailInputs = styled.div`
  display: flex;
  padding: 35px 70px 0;
  justify-content: space-between;
`;
export const ConfirmEmailInput = styled.div`
  max-width: 90px;
`;
export const DialogTitleConfirmed = styled.div`
  width: 100%;
  margin-right: -30px;
  text-align: center;
`;
export const ConfirmEmailTime = styled.div`
  display: flex;
  font-size: 12px;
  font=weight: 500;
  color: #868686;
  align-items: center;
  padding-left: 12px;
`;
export const ConfirmEmailBtn = styled.div`
  display: flex;
`;
export const ConfirmEmailDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        padding: '0',
      },
      paperWidthSm: {
        maxWidth: '640px',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '0',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#52535A',
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px',
        fontSize: '14px;',
        color: '#63666A',
        textAlign: 'center',
        minHeight: '60px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px 110px',
        margin: '0',
        justifyContent: 'space-between',
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        marginTop: '15px!important',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      error: {},
      input: {
        padding: '14px 19px 14px 19px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
        },
      },
      disabled: {},
    },
  },
});
