import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../../../common/styles/Common.colors';

export const DialogTitleOfMassImport = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #63666a;
  padding: 0 0 0 40px;
  text-align: left;
`;

export const ImportModalDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '530px',
        maxWidth: '530px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, 0.15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px 0 0',
        textAlign: 'right',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
        color: `${colors.Buttons.buttonPrimary.color}`,
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        width: '197px',
        padding: '9px 10px',
        height: '44px',
        fontSize: '14px',
        fontWeight: '600',
        border: '1px solid transparent',
        transition: 'all .4s ease',
        '&:hover': {
          border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
          backgroundColor: '#fff',
          color: '#52535A',
        },
      },
      textSecondary: {
        background: 'none',
        width: '197px',
        color: '#63666A',
        transition: '.4s ease',
        borderRadius: '3px',
        border: '1px solid #868686',
        padding: '9px 10px',
        height: '44px',
        fontSize: '14px',
        fontWeight: '600',
        // fontFamily: "SegoeUI",
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

export const ExportDialogMin = styled.div`
  padding: 22px 40px;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  text-align: center;
  color: #52535a;
  font-weight: 600;
  font-family: SegoeUI !important;
  flex-direction: column;
  &:nth-of-type(1) {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const ImportDialogMin = styled.div`
  padding: 22px 40px;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  text-align: center;
  color: #52535a;
  font-weight: 600;
  font-family: SegoeUI !important;
  flex-direction: column;
`;

export const ExportDialogMinInner = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 3px 3px 3px 21px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  span.exportSpan {
    color: #ded7d7;
  }
  &:nth-of-type(1) {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const ImportDialogMinInner = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 3px 3px 3px 21px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  span.exportSpan {
    color: #ded7d7;
  }
  &:nth-of-type(1) {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const ActionWrapper = styled.div`
  padding: 22px 40px;
  display: flex;
  justify-content: space-between;
`;
