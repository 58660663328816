import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import ProductView from './ProductView';
import {
  clearProductData,
  loadCardItemVersion,
} from '../ducks/Product.actions';
import { setFiltersGrid } from '../../products/ducks/Products.actions';

class ProductVersion extends ProductView {
  componentDidMount() {
    this.eventListenerOnScroll();
    const { match, productData, onLoadCardItemVersion } = this.props;
    const id = _.toNumber(match.params.productId);
    const versionId = _.toNumber(match.params.versionId);
    if (_.isEmpty(productData) && id && versionId) {
      onLoadCardItemVersion(id, versionId);
    }
  }
}

const mapStateToProps = (state) => ({
  loading: !!state.productReducer.loading,
  productData: state.productReducer.data,
  images: state.productReducer.data.images,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadCardItemVersion: (id, versionId) =>
        loadCardItemVersion(id, versionId),
      clearProductData: () => clearProductData(),
      goToPage: (page) => push(page),
      setFiltersGrid: () => setFiltersGrid(),
      // onDownloadProduct: (params, cbk) => downloadProduct(params, cbk),
    },
    dispatch,
  );
};

ProductVersion.defaultProps = {
  buttonViewSite: false,
};

ProductVersion.propTypes = {
  onLoadCardItemVersion: PropTypes.func.isRequired,
  clearProductData: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  setFiltersGrid: PropTypes.func.isRequired,
  buttonViewSite: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  productData: PropTypes.object,
  images: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
      versionId: PropTypes.string,
    }),
  }),
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductVersion);
