import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Menu, MenuItem, MuiThemeProvider } from '@material-ui/core';
import { IconMenu } from '../../../../common/icons/customIcons';
import { findStatusObj } from '../../../../common/utils/utils';
import { GridActionsSelect } from './CatalogGridActionSelect.styled';
import { ButtonActionCont } from '../../../../common/styles/Common.styled';

class CatalogGridActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.original, nextProps.original) ||
      !_.isEqual(this.state, nextState)
    );
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      onPrint,
      // onViewSite,
      onView,
      original,
      // accountType,
      t,
      // isEklUser,
    } = this.props;
    const { id, status } = original.dto;
    const isExemplar = _.get(original.goods, 'isExemplar', 0);

    let actions;
    // if (_.includes(['producer', 'importer'], accountType)) {
    actions = [
      {
        id: 0,
        label: 'Печать',
        onClick: onPrint,
        statuses: [0, 1, 2, 3, 4, 5],
        disabled: false,
      },
      {
        id: 1,
        label: 'Просмотр',
        onClick: onView,
        statuses: [4, 5],
        disabled: false,
      },
    ];
    // }

    const st = findStatusObj(status);

    return (
      <div>
        <ButtonActionCont>
          <Button
            onClick={this.handleClick}
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true">
            <IconMenu />
          </Button>
        </ButtonActionCont>

        <MuiThemeProvider theme={GridActionsSelect}>
          <Menu
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            id="simple-menu"
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {_.map(actions, (action) => {
              if (_.includes(action.statuses, st.id)) {
                return (
                  <MenuItem
                    key={`m_${id}_${action.id}`}
                    disableGutters={true}
                    onClick={this.handleClose}
                    disabled={action.disabled}>
                    <Button
                      onClick={() =>
                        action.onClick(id, original.nameDto, isExemplar)
                      }>
                      {t(action.label)}
                    </Button>
                  </MenuItem>
                );
              }
            })}
          </Menu>
        </MuiThemeProvider>
      </div>
    );
  }
}

CatalogGridActions.propTypes = {
  onPrint: PropTypes.func.isRequired,
  // onViewSite: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  // accountType: PropTypes.string.isRequired,
  original: PropTypes.object.isRequired,
};

export default withTranslation()(CatalogGridActions);
