import * as React from 'react';
import * as PropTypes from 'prop-types';
import { defaultTo, forEach, isEqual, isNil, some, throttle } from 'lodash';
import {
  CustomInput,
  DatePicker,
  RenderTooltip,
} from '../../../../../components';
import {
  IconError,
  IconInfoTooltip,
} from '../../../../../common/icons/customIcons';
import {
  InputContainer,
  InputIcon,
  InputIconLeft,
} from '../../../../../common/styles/Common.styled';

class InputItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: true,
      defaultValueAddress: '',
    };
    this.uidTooltip1 = `inputItem_tooltip1_${this.props.attrUid}`;
    this.uidTooltip2 = `inputItem_tooltip2_${this.props.attrUid}`;
    this.onChange = throttle(this.props.onChange, 70);
  }

  handleChange = (...args) => {
    this.onChange(...args);
    const { onValidateChange } = this.props;
    if (onValidateChange) {
      onValidateChange(...args);
    }
  }

  componentWillUnmount() {
    this.onChange.cancel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.t !== nextProps.t) return true
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = [
      'id',
      'value',
      'errors',
      'error',
      'disabled',
      'required',
    ];

    forEach(nameProps, (i) => {
      const notEqual = !isEqual(this.props[i], nextProps[i]);
      if (i === 'id' && nextProps.fieldType !== 'date' && notEqual) {
        this.onChange = throttle(nextProps.onChange, 70);
      }
      equal.push(notEqual);
    });
    return some(equal);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.defaultValueAddress !== prevState.defaultValueAddress) {
      return {
        defaultValueAddress: nextProps.defaultValueAddress,
      };
    }
    return null;
  }

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  render() {
    const {
      onUpdate,
      onChange,
      onValidate,
      required,
      disabled,
      error,
      attrUid,
      id,
      itemId,
      name,
      label,
      value,
      fieldType,
      description,
      errors,
      multiline,
      onBlur,
      setError,
      searchIds,
    } = this.props;
    const { t } = this.props

    const { defaultValueAddress = '' } = this.state;

    const inputValue = defaultValueAddress
      ? defaultValueAddress
      : defaultTo(value, '');
    const isError = !!errors[itemId] || error;

    return (
      <InputContainer>
        {fieldType === 'date' ? (
          <DatePicker
            searchIds={searchIds}
            id={`input-date-${id}`}
            required={required}
            disabled={disabled}
            valid={!!inputValue && !isError}
            error={isError}
            name={t(name)}
            setError={setError}
            currentValue={inputValue}
            onAccept={onChange}
            onDismiss={onChange}
            onBlur={onBlur}
            onChange={onBlur}
          />
        ) : (
          <CustomInput
            attrUid={attrUid}
            id={`input-${id}`}
            name={name}
            disabled={disabled}
            required={required}
            isValidateText
            onValidate={onValidate}
            valid={required && !!inputValue && !isError}
            error={isError}
            dirty={isError}
            label={t(label)}
            value={inputValue}
            type={fieldType}
            onUpdate={onUpdate}
            onChange={this.handleChange}
            multiline={multiline}
            infoText={Boolean(description)}
            onBlur={onBlur}
          />
        )}

        {t(description) &&
          RenderTooltip(
            this.uidTooltip1,
            description,
            <InputIcon /*style={{ top: error ? '35%' : '53%' }}*/>
              <IconInfoTooltip />
            </InputIcon>,
            this.state.bottom,
            this.handleOpen,
          )}

        {!!errors[itemId] &&
          RenderTooltip(
            this.uidTooltip2,
            errors[itemId],
            <InputIconLeft>
              <IconError />
            </InputIconLeft>,
            this.state.bottom,
            this.handleOpen,
          )}
      </InputContainer>
    );
  }
}

InputItem.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  attrUid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  fieldType: PropTypes.string.isRequired,
  description: PropTypes.string,
  errors: PropTypes.object.isRequired,
};

InputItem.defaultProps = {
  required: false,
};

export default InputItem;
