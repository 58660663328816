export const LOAD_SUB_ACCOUNT_ACTION = 'LOAD_SUB_ACCOUNT';
export const LOAD_SUB_COMPANY_SUCCEEDED_ACTION = 'LOAD_SUB_COMPANY_SUCCEEDED';
export const LOAD_SUB_COMPANY_FAILED_ACTION = 'LOAD_SUB_COMPANY_FAILED';
export const DELETE_SUB_ACCOUNT_ACTION = 'DELETE_SUB_ACCOUNT';
export const VALIDATION_INN_SUB_COMPANY_ACTION = 'VALIDATION_INN_SUB_COMPANY';
export const SEND_SUB_ACCOUNT_TO_ASSIGN_ACTION = 'SEND_SUB_ACCOUNT_TO_ASSIGN';
export const CHANGE_ACCESS_COMPANY_ACTION = 'CHANGE_ACCESS_COMPANY';
export const CHANGE_ACCESS_COMPANY_MANUAL_ACTION =
  'CHANGE_ACCESS_COMPANY_MANUAL';
export const SAVE_SUBACCOUNT_LETTER_ACTION = 'SAVE_SUBACCOUNT_LETTER';
export const SAVE_SUBACCOUNT_LETTER_SUCCEEDED_ACTION =
  'SAVE_SUBACCOUNT_LETTER_SUCCEEDED';
export const LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION =
  'LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION';

export const GRANT_KM_ORDER_ACTION = 'GRANT_KM_ORDER_ACTION';
export const REVOKE_KM_ORDER_ACTION = 'REVOKE_KM_ORDER_ACTION';

export const ADD_LETTER_INFORMATION = 'ADD_LETTER_INFORMATION';

export const GET_GCP_GLN_LETTERS = 'GET_GCP_GLN_LETTERS';

export const DELETE_GCP_GLN_LETTER = 'DELETE_GCP_GLN_LETTER';

export const UPDATE_SUBACCOUNT_ACTION = 'UPDATE_SUBACCOUNT_ACTION';
export const UPDATE_SUBACCOUNT_ACTION_SUCCEEDED =
  'UPDATE_SUBACCOUNT_ACTION_SUCCEEDED';
export const UPDATE_SUBACCOUNT_ACTION_FAILED =
  'UPDATE_SUBACCOUNT_ACTION_FAILED';
export const SEND_SUBACCOUNT_GCP = 'SEND_SUBACCOUNT_GCP';
