import * as moment from 'moment';
import axios from 'axios';
import { config } from '../config';

export const CancelToken = axios.CancelToken;
export let cancel;

export const client = () => {
  const axiosInstanse = axios.create({
    baseURL: config.baseURL,
    headers: config.headers,
    withCredentials: true,
  });

  axiosInstanse.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return {
    get: (url, options = {}) =>
      axiosInstanse
        .get(url, {
          ...options,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => response.data)
        .catch((error) => ({ error })),

    post: (url, data, options = {}) =>
      axiosInstanse
        .post(url, data, {
          ...options,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => response.data)
        .catch((error) => ({ error })),

    put: (url, data, options = {}) =>
      axiosInstanse
        .put(url, data, {
          ...options,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => response.data)
        .catch((error) => ({ error })),

    delete: (url, options = {}) =>
      axiosInstanse
        .delete(url, {
          ...options,
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => response.data)
        .catch((error) => ({ error })),
    instance: axiosInstanse,
  };
};

export function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function getToken() {
  const count = getCookie('count') || 0;
  const token = new Array(+count)
    .fill(1)
    .map((v, i) => getCookie(`tokenPart${i + 1}`))
    .join('');
  return count ? token : null;
}

export function setCookie(name, value, isLiving, domain, secure) {
  let cookie_string = `${name}=${encodeURIComponent(value)}`;

  if (isLiving) {
    const expires = moment().add(6, 'months');
    cookie_string += `; expires=${expires.toString()}`;
  }

  cookie_string += `; path=/`;

  if (domain) {
    cookie_string += `; domain=${encodeURIComponent(domain)}`;
  }
  if (secure) {
    cookie_string += '; secure';
  }

  document.cookie = cookie_string;
}

export function deleteAllCookies() {
  let cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    if (name.trim() == 'products_grid-columns') continue;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}
