import * as PropTypes from 'prop-types';
import * as React from 'react';
import download from 'downloadjs';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import {
  DialogTitleContent,
  DialogTitleOfContentStyled,
} from '../../../../common/styles/Common.styled';
import {
  ExportDialogMin,
  ExportDialogMinInner,
  ExportModalDialog,
} from './ExportModal.styled';
import { IconClose } from '../../../../common/icons/customIcons';
import { exportProduct } from '../../ducks/Products.actions';

const openxmlFormats =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class ExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  exportProduct = () => {
    const { getBuildParamsForList, onExportProduct, selected, t } = this.props;
    const params = getBuildParamsForList();
    const goodIds = [];

    if (selected) {
      _.forEach(selected, (object) => {
        goodIds.push(object.id);
      });

      params.goodIds = goodIds;
    }

    this.setState({ loading: true });
    onExportProduct('short', params, (errors, data) => {
      if (!errors) {
        this.setState({ loading: false });
        const blob = new Blob([data], { type: openxmlFormats });
        return download(blob, `${t('Краткий отчет')}.xlsx`);
      }
      this.props.handleClose();
    });
  };

  exportProductFull = () => {
    const { getBuildParamsForList, onExportProduct, selected, t } = this.props;
    const params = getBuildParamsForList();
    const goodIds = [];

    if (selected) {
      _.forEach(selected, (object) => {
        goodIds.push(object.id);
      });

      params.goodIds = goodIds;
    }

    this.setState({ loading: true });
    onExportProduct('full', params, (errors, data) => {
      if (!errors) {
        this.setState({ loading: false });
        const blob = new Blob([data], { type: openxmlFormats });
        return download(blob, `${t('Полный отчет')}.xlsx`);
      }
      this.props.handleClose();
    });
  };

  render() {
    const { handleClose, filters, t } = this.props;

    const allowedExportStatuses = _.includes(
      ['published', 'draft', 'errors'],
      filters.status,
    );

    return (
      <MuiThemeProvider theme={ExportModalDialog}>
        <Dialog open>
          <DialogTitle id="alert-dialog-title">
            <DialogTitleContent>
              <DialogTitleOfContentStyled>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '18px',
                    fontWeight: '700',
                  }}>
                  {t('ЭКСПОРТ')}
                </div>
                <div
                  style={{
                    margin: '0 0 0 40px',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}>
                  {t(
                    'Обращаем внимание, что экcпорт данных возможен только после применения обязательных фильтров',
                  ) + `. `}
                  {t(
                    'Системное ограничение для экспорта составляет 100 товаров',
                  ) + `.`}
                </div>
              </DialogTitleOfContentStyled>

              <Button
                className="backEmpty"
                id="dismiss-btn-close"
                onClick={handleClose}>
                <IconClose />
              </Button>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            <ExportDialogMin>
              <div style={{ textAlign: 'left' }}>
                {t(
                  `Необходимо выбрать фильтр по столбцу 'Назначение упаковки' и 'Статус'`,
                )}
              </div>
              <br />
              <ExportDialogMinInner
                disabled={!filters.packingPurpose || !allowedExportStatuses}>
                <div>
                  {t('Краткий отчет')} <span>.xlsx</span>
                </div>
                <Button
                  color={'primary'}
                  disabled={
                    this.state.loading ||
                    !filters.packingPurpose ||
                    !allowedExportStatuses
                  }
                  onClick={this.exportProduct}>
                  {t('Скачать')}
                </Button>
              </ExportDialogMinInner>
            </ExportDialogMin>

            <ExportDialogMin>
              <div style={{ textAlign: 'left' }}>
                {t(
                  `Необходимо выбрать фильтры по столбцам 'Назначение упаковки', 'Классификация' и 'Статус'`,
                )}
              </div>
              <br />
              <ExportDialogMinInner
                disabled={
                  !filters.packingPurpose ||
                  !filters.category ||
                  !allowedExportStatuses
                }>
                <div>
                  {t('Полный отчет')} <span>.xlsx</span>
                </div>
                <Button
                  color={'primary'}
                  disabled={
                    this.state.loading ||
                    !filters.packingPurpose ||
                    !filters.category ||
                    !allowedExportStatuses
                  }
                  onClick={this.exportProductFull}>
                  {t('Скачать')}
                </Button>
              </ExportDialogMinInner>
            </ExportDialogMin>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

ExportModal.propTypes = {
  onExportProduct: PropTypes.func.isRequired,
  getBuildParamsForList: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  categoryFilter: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onExportProduct: (status, params, cbk) =>
        exportProduct(status, params, cbk),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(ExportModal);
