import * as React from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Sidebar } from './common/structure';
import { withCookies } from 'react-cookie';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = rest.isAuthentificated;
  const isCookies = rest.cookies.get('isAuth') && rest.cookies.get('userInfo');

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn || isCookies ? (
          <Sidebar>
            <Component {...props} />
          </Sidebar>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthentificated: state.authentificationReducer.isAuthentificated,
});

export default compose(withCookies, connect(mapStateToProps))(PrivateRoute);
