import _ from 'lodash';
import { all } from 'redux-saga/effects';
import authentificationSaga from './pages/authentication/ducks/Authentification.saga';
import registrationSaga from './pages/registration/ducks/Registration.saga';
import productsSaga from './pages/products/ducks/Products.saga';
import productSaga from './pages/product/ducks/Product.saga';
import profileSaga from './pages/profile/ducks/Profile/Profile.saga';
import profileStatusSaga from './pages/profile/ducks/ProfileStatus/ProfileStatus.saga';
import subAccountSaga from './pages/profile/ducks/SubAccount/SubAccount.saga';
import tradeMarksSaga from './pages/profile/ducks/TradeMarks/TradeMarks.saga';
import parentCompanySaga from './pages/profile/ducks/ParentCompany/ParentCompany.saga';
import subProfilesSaga from './pages/profile/ducks/SubProfiles/SubProfiles.saga';
import catalogSaga from './common/catalog/Catalog.saga';
import gs1Saga from './pages/profile/ducks/GS1/GS1.saga';
import utilsSaga from './pages/ducks/utils.saga';
import siteProfileSaga from './pages/profile/ducks/SiteProfile/SiteProfile.saga';
import catalogGoodsSaga from './pages/catalog/ducks/Catalog.saga';
import companiesSaga from './pages/companies/ducks/Companies.saga';

export const sagas = {
  authentificationSaga,
  registrationSaga,
  productsSaga,
  productSaga,
  profileSaga,
  profileStatusSaga,
  subAccountSaga,
  tradeMarksSaga,
  parentCompanySaga,
  subProfilesSaga,
  catalogSaga,
  gs1Saga,
  utilsSaga,
  siteProfileSaga,
  catalogGoodsSaga,
  companiesSaga,
};

export default function* watchAll() {
  yield all(_.map(sagas, (i) => i()));
}
