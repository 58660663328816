import { ActionCreator } from 'redux';
import {
  ARCHIVE_CARDS_ACTION,
  CLEAR_ALERT_GRID_ACTION,
  CLEAR_VERSIONS_ACTION,
  DELETE_PRODUCTS_ACTION,
  DELETE_PRODUCTS_FAILED_ACTION,
  DELETE_PRODUCTS_SUCCESSED_ACTION,
  EXPORT_PRODUCT_ACTION,
  LOAD_DATA_ACTION,
  LOAD_DATA_FAILED_ACTION,
  LOAD_DATA_SUCCESSED_ACTION,
  LOAD_DICTIONARY_GOODS_BRAND_ACTION,
  LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED_ACTION,
  LOAD_DICTIONARY_GOODS_CATEGORY_ACTION,
  LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED_ACTION,
  LOAD_VERSIONS_ACTION,
  LOAD_VERSIONS_SUCCESSED_ACTION,
  OPEN_DRAFT_ACTION,
  OPEN_DRAFT_PUBLISHED_FAILED_ACTION,
  OPEN_DRAFT_PUBLISHED_SUCCESED_ACTION,
  PUBLISH_PRODUCT_ACTION,
  RESET_STATE_ACTION,
  SEND_FOR_MODERATION_PRODUCT_ACTION,
  SET_FILTERS_GRID_ACTION,
  IMPORT_PRODUCT_ACTION,
  IMPORT_PRODUCT_ACTION_SUCCEEDED,
  IMPORT_PRODUCT_ACTION_FAILED,
  MASS_MEDIA_IMPORT_ACTION,
  MASS_MEDIA_IMPORT_ACTION_SUCCEEDED,
  MASS_MEDIA_IMPORT_ACTION_FAILED,
  ABORT_MASS_MEDIA_IMPORT_ACTION,
  CHANGE_MASS_UPLOAD_LIST_COUNT,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED,
  CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED,
  MODERATION_RECALL_ACTION,
} from './Products.constants';

export const loadProductData: ActionCreator = (
  pageNum,
  pageSize,
  sort,
  filters,
  fields,
) => ({
  type: LOAD_DATA_ACTION,
  pageNum: pageNum,
  pageSize: pageSize,
  sort: sort,
  filters: filters,
  fields: fields,
});

export const loadProductDataSuccesed: ActionCreator = (
  data,
  totalObjects,
  filters,
) => ({
  type: LOAD_DATA_SUCCESSED_ACTION,
  data: data,
  totalObjects: totalObjects,
  filters: filters,
});

export const loadProductDataFailed: ActionCreator = () => ({
  type: LOAD_DATA_FAILED_ACTION,
});

export const setFiltersGrid: ActionCreator = () => ({
  type: SET_FILTERS_GRID_ACTION,
});

export const loadProductVersions: ActionCreator = (id) => ({
  type: LOAD_VERSIONS_ACTION,
  id: id,
});

export const loadProductVersionsSuccesed: ActionCreator = (data) => ({
  type: LOAD_VERSIONS_SUCCESSED_ACTION,
  data: data,
});

export const clearVersions: ActionCreator = () => ({
  type: CLEAR_VERSIONS_ACTION,
});

export const exportProduct: ActionCreator = (
  status,
  params,
  cbk = () => null,
) => ({
  type: EXPORT_PRODUCT_ACTION,
  status: status,
  params: params,
  cbk: cbk,
});

export const sendForModerationProduct: ActionCreator = (
  draftsIds,
  cbk = () => null,
) => ({
  type: SEND_FOR_MODERATION_PRODUCT_ACTION,
  draftsIds: draftsIds,
  cbk: cbk,
});

export const openDraft: ActionCreator = (
  ids,
  status,
  isExemplar,
  cbk = () => null,
) => ({
  type: OPEN_DRAFT_ACTION,
  ids: ids,
  status: status,
  isExemplar: isExemplar,
  cbk: cbk,
});

export const openDraftPublishedSuccesed: ActionCreator = (data) => ({
  type: OPEN_DRAFT_PUBLISHED_SUCCESED_ACTION,
  data: data,
});

export const openDraftPublishedFailed: ActionCreator = () => ({
  type: OPEN_DRAFT_PUBLISHED_FAILED_ACTION,
});

export const publishProduct: ActionCreator = (setting, cbk = () => null) => ({
  type: PUBLISH_PRODUCT_ACTION,
  setting: setting,
  cbk: cbk,
});

export const deleteProducts: ActionCreator = (draftsIds, cbk = () => null) => ({
  type: DELETE_PRODUCTS_ACTION,
  draftsIds: draftsIds,
  cbk: cbk,
});

export const deleteProductsSuccesed: ActionCreator = (ids) => ({
  type: DELETE_PRODUCTS_SUCCESSED_ACTION,
  ids: ids,
});

export const deleteProductsFailed: ActionCreator = () => ({
  type: DELETE_PRODUCTS_FAILED_ACTION,
});

export const loadDictionaryGoodsBrand: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GOODS_BRAND_ACTION,
});

export const loadDictionaryGoodsBrandSuccesed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_GOODS_BRAND_SUCCESSED_ACTION,
  data: data,
});

export const loadDictionaryGoodsCategory: ActionCreator = () => ({
  type: LOAD_DICTIONARY_GOODS_CATEGORY_ACTION,
});

export const loadDictionaryGoodsCategorySuccesed: ActionCreator = (data) => ({
  type: LOAD_DICTIONARY_GOODS_CATEGORY_SUCCESSED_ACTION,
  data: data,
});

export const clearAlert: ActionCreator = () => ({
  type: CLEAR_ALERT_GRID_ACTION,
});
export const resetStateProducs: ActionCreator = () => ({
  type: RESET_STATE_ACTION,
});

export const archiveCards: ActionCreator = (
  goodsIds,
  cert,
  isSigned,
  cbk = () => null,
) => ({
  type: ARCHIVE_CARDS_ACTION,
  goodsIds: goodsIds,
  cert: cert,
  isSigned: isSigned,
  cbk: cbk,
});

export const importProduct: ActionCreater = (params, cbk = () => null) => ({
  type: IMPORT_PRODUCT_ACTION,
  params,
  cbk,
});

export const importProductSucceeded: ActionCreator = () => ({
  type: IMPORT_PRODUCT_ACTION_SUCCEEDED,
});

export const importProductFailed: ActionCreator = () => ({
  type: IMPORT_PRODUCT_ACTION_FAILED,
});

export const massMediaImport: ActionCreator = (
  data,
  files,
  isSameFolder,
  cbk = () => null,
) => ({
  type: MASS_MEDIA_IMPORT_ACTION,
  data,
  files,
  isSameFolder,
  cbk,
});

export const massMediaImportSucceeded: ActionCreator = () => ({
  type: MASS_MEDIA_IMPORT_ACTION_SUCCEEDED,
});

export const massMediaImportFailed: ActionCreator = () => ({
  type: MASS_MEDIA_IMPORT_ACTION_FAILED,
});

export const cancelMassMediaImport: ActionCreator = (data) => ({
  type: ABORT_MASS_MEDIA_IMPORT_ACTION,
  data,
});

export const changeMassUploadListCount: ActionCreator = (data) => ({
  type: CHANGE_MASS_UPLOAD_LIST_COUNT,
  data,
});

export const cancelAllUploadedMassMedia: ActionCreator = (data) => ({
  type: CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION,
  data,
});

export const cancelAllUploadedMassMediaSucceeded: ActionCreator = () => ({
  type: CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_SUCCEDED,
});

export const cancelAllUploadedMassMediaFailed: ActionCreator = () => ({
  type: CANCEL_ALL_UPLOADED_MASS_MEDIA_ACTION_FAILED,
});

export const moderationRecall: ActionCreator = (data, cbk = () => null) => ({
  type: MODERATION_RECALL_ACTION,
  data: data,
  cbk: cbk,
});


