import _ from 'lodash';

export const sortColumns = function (columns, orderedColumns) {
  if (!!orderedColumns && !!orderedColumns.length) {
    columns = _.map(columns, function (c) {
      let o = _.find(orderedColumns, { accessor: c.accessor });
      if (!!o) {
        c.show = o.show;
        return c;
      }
    });
    orderedColumns = _.map(
      _.sortBy(orderedColumns, [
        function (o) {
          return o.position;
        },
      ]),
      function (o) {
        return o.accessor;
      },
    );
    columns.sort(function (a, b) {
      return (
        orderedColumns.indexOf(a.accessor) - orderedColumns.indexOf(b.accessor)
      );
    });
  }
  return columns;
};

export const getSelectedIds = (selectedRows) =>
  _.map(selectedRows, (i) => i.id);

export const getColumns = function (columns) {
  return _.map(_.filter(columns, { show: true }), (column) => {
    return column.accessor;
  });
};

export const returnSimplifiedSetFields = (columns) => {
  return _.map(columns, (column, index) => {
    return {
      position: index,
      show: column.show,
      accessor: column.accessor,
      name: column.name,
    };
  });
};
