// import {isKZ} from "../../config";

const commonColors = {
  Home: {
    background: '#0187B0',
    // background: isKZ ? "#0187B0" : "#F8EC31",
    title: '#FFF',
    // title: isKZ ? "#FFF" : "#52535A"
  },
  Header: {
    profileLogo: '#0187B0',
    // profileLogo: isKZ ? "#0187B0" : "#F8EC31",
    profileColor: '#FFF',
    // profileColor: isKZ ? "#FFF" : "#52535A",
    profileMenuColor: '#FFF',
    // profileMenuColor: isKZ ? "#FFF" : "#52535A",
    profileMenuBackground: '#0187B0',
    // profileMenuBackground: isKZ ? "#0187B0" : "#F8EC31"
  },
  Sidebar: {
    buttonBorder: '#0187B0',
    // buttonBorder: isKZ ? "#0187B0" : "#F8EC31"
  },
  Buttons: {
    buttonPrimary: {
      background: '#0187B0',
      // background: isKZ ? "#0187B0" : "#F8EC31",
      backgroundHovered: '#0187B0',
      // backgroundHovered: isKZ ? "#0187B0" : "#F8EC31",
      color: '#FFF',
      // color: isKZ ? "#FFF" : "#52535A",
      colorHovered: '#0187B0',
      // colorHovered: isKZ ? "#0187B0" : "#F8EC31",
      boxShadow: 'rgba(1, 135, 176, .4)',
      // boxShadow: isKZ ? "rgba(1, 135, 176, .4)" : "rgba(242, 239, 19, .4)",
      boxShadowHovered: 'rgba(1, 135, 176, .6)',
      // boxShadowHovered: isKZ ? "rgba(1, 135, 176, .6)" : "rgba(242, 239, 19, .6)"
    },
  },
  Tabs: {
    tabLine: '#0187B0',
    // tabLine: isKZ ? "#0187B0" : "#F8EC31"
  },
  Grid: {
    pageSizeBackground: '#0187B0',
    // pageSizeBackground: isKZ ? "#0187B0" : "#F8EC31",
    paginationBackground: '#0187B0',
    // paginationBackground: isKZ ? "#0187B0" : "#F8EC31",
    paginationColor: '#FFF',
    // paginationColor: isKZ ? "#FFF" : "#52535A",
    pageSizeColor: '#FFF',
    // pageSizeColor: isKZ ? "#FFF" : "#52535A"
  },
  Scroll: {
    background: '#0187B0',
    // background: isKZ ? "#0187B0" : "#F8EC31"
  },
  Icons: {
    background: '#0187B0',
    // background: isKZ ? "#0187B0" : "#F8EC31",
    fill: '#FFF',
    // fill: isKZ ? "#FFF" : "#52535A",
    fillSecondary: '#FFF',
    // fillSecondary: isKZ ? "#FFF" : "#52535A"
  },
  Input: {
    border: '#0187B0',
    // border: isKZ ? "#0187B0" : "#F8EC31",
    checkBox: '#0187B0',
    // checkBox: isKZ ? "#0187B0" : "#F8EC31"
  },
  Common: {
    background: '#0187B0',
    // background: isKZ ? "#0187B0" : "#F8EC31",
    color: '#FFF',
    // color: isKZ ? "#FFF" : "#52535A"
  },
};
export default commonColors;
