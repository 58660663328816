import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox, MuiThemeProvider } from '@material-ui/core';
import { IconCheck } from '../../../../common/icons/customIcons';
import {
  CheckBoxGrid,
  ColumnCellCheck,
  ColumnCellCheckInner,
} from '../../../../common/styles/Common.styled';

export default class CheckboxCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      !_.isEqual(this.props.original, nextProps.original) ||
      !_.isEqual(this.props.selected, nextProps.selected)
    );
  }

  render() {
    const checked = !!_.find(this.props.selected, {
      id: this.props.original.dto.id,
      nameDto: this.props.original.nameDto,
    });

    return (
      <ColumnCellCheck>
        <ColumnCellCheckInner>
          <MuiThemeProvider theme={CheckBoxGrid}>
            <Checkbox
              checked={checked}
              onChange={() =>
                this.props.toggleRow(
                  this.props.original.dto,
                  this.props.original.nameDto,
                )
              }
              checkedIcon={<IconCheck />}
            />
          </MuiThemeProvider>
        </ColumnCellCheckInner>
      </ColumnCellCheck>
    );
  }
}

CheckboxCell.propTypes = {
  toggleRow: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  original: PropTypes.object.isRequired,
};
