import moment from 'moment';

export const formatDate = (date) => moment(date).format('DD.MM.YYYY');

const modifyCert = (cert) => {
  return {
    id: cert.certId,
    startDate: formatDate(cert.validDateFrom),
    expireDate: formatDate(cert.validDateTo),
    organization: cert.issuedBy,
    fullName: cert.userFullName,
    post: cert.userPosition,
    certUid: cert.certUid,
    inn: cert.inn,
    owner: cert.owner,
    isValid: cert.isValid,
    certificate: cert,
    issuedBy: cert.issuedBy,
  };
};

export const formatCerts = (certs) => {
  const modifiedCerts = certs.map((cert) => {
    if (cert.certId) {
      cert = modifyCert(cert);
      delete cert.certificate;
    } else {
      console.error('Errr occured during certificate modification!');
    }
    return cert;
  });
  return modifiedCerts;
};

export const getSubstring = (str, startWith, endWith) => {
  let substring =
    str.indexOf(startWith) > -1
      ? str.substring(str.indexOf(startWith) + startWith.length, str.length)
      : '';
  substring = substring.length
    ? substring.substring(0, substring.indexOf(endWith))
    : substring;
  return substring;
};
