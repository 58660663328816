import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MenuItem, MuiThemeProvider, Select } from '@material-ui/core';
import { setLocale } from '../../pages/profile/ducks/Profile/Profile.actions';
import {
  HeaderLangRow,
  HomeLangSelect,
  HomeLangSelectTheme,
  LangMenuIconHome,
} from '../../common/styles/Common.styled';

class HeaderLanguageSelection extends React.Component {
  componentDidMount() {
    const savedLang = localStorage.getItem('savedLanguage');

    if (savedLang) {
      this.props.setLocale(savedLang);
    }
  }

  onLangChange = (e) => {
    localStorage.setItem('savedLanguage', e.target.value);
    this.props.setLocale(e.target.value);
  };

  render() {
    const { locale, t } = this.props;
    const fnUp = _.flow([t, _.upperCase]);

    return (
      <HeaderLangRow>
        <HomeLangSelect>
          <MuiThemeProvider theme={HomeLangSelectTheme}>
            <Select
              value={locale}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'top', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              }}
              onChange={this.onLangChange}>
              <MenuItem value="ru">
                <LangMenuIconHome></LangMenuIconHome>
                {fnUp('    RU')}
              </MenuItem>
              <MenuItem value="kz">
                <LangMenuIconHome></LangMenuIconHome>
                {fnUp('    KZ')}
              </MenuItem>
            </Select>
          </MuiThemeProvider>
        </HomeLangSelect>
      </HeaderLangRow>
    );
  }
}

HeaderLanguageSelection.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setLocale: (locale) => setLocale(locale),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderLanguageSelection);
