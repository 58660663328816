import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const DefaultFloatBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiFab: {
      root: {
        height: '50px',
        borderRadius: '100px',
        fontFamily: 'SegoeUI',
        zIndex: '3',
      },
      primary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        fontSize: '0px',
        width: '50px',
        transition: 'font-size .1s ease, width .2s ease, color .2s ease',
        '&:hover': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          width: 'auto',
          fontSize: '16px',
          padding: '0 22px',
          transition: 'font-size .2s ease, width .2s ease, color .4s ease',
        },
        '&:disabled': {
          boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          opacity: '0.5',
        },
      },
      secondary: {
        backgroundColor: '#FFF',
        marginTop: '15px',
        boxShadow: '0px 5px 15px rgba(0, 0, 0, .1)',
        color: '#FFF',
        fontSize: '0px',
        width: '50px',
        transition: 'font-size .2s ease, width .2s ease, color .4s ease',
        '&:hover': {
          color: '#52535A',
          backgroundColor: '#FFF',
          width: 'auto',
          fontSize: '16px',
          padding: '0 22px',
          transition: 'font-size .2s ease, width .2s ease, color .4s ease',
        },
        '&:disabled': {
          boxShadow: '0px 5px 15px rgba(0, 0, 0, .1)',
          backgroundColor: '#FFF',
          opacity: '.5',
        },
      },
      label: {
        fontSize: 'inherit',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
        textTransform: 'none',
        fontWeight: '600',
        paddingLeft: '5px',
      },
    },
  },
});
