import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const RedirectIconHolder = styled.span`
  display: inline-block;
  margin-left: 15px;
  transform: translateY(5px);
`;
export const RedirectDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '640px',
        maxWidth: '640px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, .15)',
        borderRadius: '4px',
        padding: '0',
      },
      paperWidthSm: {
        maxWidth: '530px',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '20px',
        color: '#52535A',
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'capitalize',
        height: '50px',
        fontWeight: '600',
        margin: '0!important',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'capitalize',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        marginLeft: '40px!important',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      disabled: {},
    },
    MuiDialogContent: {
      root: {
        padding: '60px 40px 5px!important',
        fontSize: '18px;',
        color: '#63666A',
        textAlign: 'center',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '35px 100px',
        margin: '0',
        justifyContent: 'center',
      },
    },
    MuiDialogContentText: {
      root: {
        padding: '0 65px',
        fontSize: '20px;',
        color: '#52535A',
        textAlign: 'center',
      },
    },
  },
});
