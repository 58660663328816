import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import {
  RegCheck,
  RegForm,
  RegInfo,
  RegInfoTitle,
  SelectCont,
  SelectContMin,
} from '../Registration.styled';
import {
  IconCheck,
  IconError,
  IconLogoDropDown,
  IconPreloader,
} from '../../../common/icons/customIcons';
import {
  CertificateSelect,
  CustomInput,
  PhoneInput,
} from '../../../components';
import {
  validateEmail,
  validateEmailOnDirty,
  validatePhone,
} from '../../../common/utils/Validation';
import {
  BtnContPaddingless,
  InputContainer,
  InputIcon,
} from '../../../common/styles/Common.styled';

export class RegistrationProcessView extends React.Component {
  constructor(props) {
    super(props);
    this.props.loadCerts();
    this.state = {
      cert: {},
      sendTypeAccount: 'producer',
      selectedType: 'producer',
      subType: {
        producer: true,
        importer: false,
      },
      accountType: 'producer',
      isMarked: false,
      email: '',
      phone: '',
    };
  }

  handleChange = (name, value) => {
    const state = _.cloneDeep(this.state);
    switch (name) {
      case 'phone': {
        state[name] = value;
        break;
      }
      case 'selectedType': {
        state[name] = value;
        if (value === 'net') {
          state.accountType = value;
        } else {
          state.accountType = state.subType.producer ? 'producer' : 'importer';
        }
        state.sendTypeAccount = value;
        break;
      }
      case 'producer': {
        state.subType[name] = value;
        state.accountType = value ? name : 'importer';
        state.sendTypeAccount = state.accountType;
        state.subType.importer = !value;
        break;
      }
      case 'importer': {
        state.subType[name] = value;
        state.accountType = value ? name : 'producer';
        state.sendTypeAccount = state.accountType;
        state.subType.producer = !value;
        break;
      }
      default: {
        state[name] = value;
        break;
      }
    }

    this.setState(state);
  };

  render() {
    const {
      certificates,
      accountTypes,
      onRegRequest,
      validationErrors,
      loading,
      t,
    } = this.props;

    const {
      accountType,
      selectedType,
      subType,
      isMarked,
      email,
      phone,
      cert,
    } = this.state;
    const emailValid = validateEmail(email);
    const phoneValid = !!phone.replace(/\D/g, '') && validatePhone(phone);
    const disable =
      validateEmailOnDirty(email) ||
      !phoneValid ||
      !emailValid ||
      loading ||
      !cert.isValid;

    const validationErrorPhone = _.has(validationErrors, 'phone');
    const validationErrorEmail = _.has(validationErrors, 'email');

    return (
      <div>
        <SelectCont>
          <CertificateSelect
            certificates={certificates}
            onSelectCert={(cert) => this.handleChange('cert', cert)}
            cert={cert}
          />
        </SelectCont>

        {cert &&
          cert.certUid && ( //TODO вернуть как было
            <React.Fragment>
              <SelectContMin>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="reg-select">
                    {t('Зарегистрироваться как')} ...
                  </InputLabel>
                  <Select
                    value={_.defaultTo(selectedType, '')}
                    inputProps={{ id: 'reg-select' }}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    IconComponent={IconLogoDropDown}
                    onChange={(event) =>
                      this.handleChange('selectedType', event.target.value)
                    }>
                    {_.map(accountTypes, (accountType) => (
                      <MenuItem key={accountType.id} value={accountType.value}>
                        {t(accountType.title)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SelectContMin>
              <RegForm>
                {accountType && accountType.length && (
                  <div>
                    {selectedType === 'producer' && (
                      <RegCheck>
                        <FormControlLabel
                          control={
                            <Checkbox
                              type="checkbox"
                              checked={subType.producer}
                              checkedIcon={<IconCheck />}
                              onChange={(e) =>
                                this.handleChange('producer', e.target.checked)
                              }
                            />
                          }
                          label={t('Производитель')}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              type="checkbox"
                              checked={subType.importer}
                              checkedIcon={<IconCheck />}
                              onChange={(e) =>
                                this.handleChange('importer', e.target.checked)
                              }
                            />
                          }
                          label={t('Импортер')}
                        />
                      </RegCheck>
                    )}
                    {selectedType !== 'csp' && (
                      <RegCheck>
                        <FormControlLabel
                          control={
                            <Checkbox
                              type="checkbox"
                              checked={isMarked}
                              checkedIcon={<IconCheck />}
                              onChange={(e) =>
                                this.handleChange('isMarked', e.target.checked)
                              }
                            />
                          }
                          label={t(
                            'Я участник оборота товаров, маркированных средствами идентификации',
                          )}
                        />
                      </RegCheck>
                    )}
                    <RegInfo>
                      <RegInfoTitle>
                        <h3>{t('Контактные данные')}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'Красным отмечены обязательные для заполнения поля',
                              {
                                style: 'style=color:red;',
                              },
                            ),
                          }}
                        />
                      </RegInfoTitle>
                      <CustomInput
                        required
                        id="email"
                        name="email"
                        label={
                          !emailValid
                            ? t('Введите корректный E-mail')
                            : validationErrorEmail
                            ? validationErrors.email
                            : 'E-mail'
                        }
                        placeholder={t('Электронная почта')}
                        autoComplete="email"
                        value={email}
                        type="email"
                        valid={emailValid}
                        error={!emailValid || validationErrorEmail}
                        onChange={(value) => this.handleChange('email', value)}
                        onUpdate={() => {}}
                      />
                      <InputContainer>
                        <PhoneInput
                          required
                          phone={phone}
                          placeholder={
                            phoneValid
                              ? t('Телефон')
                              : t('Введите корректный номер телефона')
                          }
                          phoneValid={phoneValid && !validationErrorPhone}
                          valid={phoneValid}
                          onChange={(value) =>
                            this.handleChange('phone', value)
                          }
                          onUpdate={() => {}}
                        />

                        {validationErrorPhone && (
                          <Tooltip
                            title={t('Телефон обязателен для заполнения')}>
                            <InputIcon>
                              <IconError />
                            </InputIcon>
                          </Tooltip>
                        )}
                      </InputContainer>
                    </RegInfo>
                    <BtnContPaddingless>
                      <Button
                        disabled={disable}
                        onClick={() =>
                          onRegRequest({
                            cert: cert,
                            accountType: this.state.sendTypeAccount,
                            email: email,
                            phone: phone,
                            isMarked: isMarked,
                          })
                        }>
                        {this.props.loading ? (
                          <IconPreloader />
                        ) : (
                          t('Отправить заявку')
                        )}
                      </Button>
                    </BtnContPaddingless>
                  </div>
                )}
              </RegForm>
            </React.Fragment>
          )}
      </div>
    );
  }
}

RegistrationProcessView.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadCerts: PropTypes.func.isRequired,
  onRegRequest: PropTypes.func.isRequired,
  accountTypes: PropTypes.arrayOf(PropTypes.any),
  certificates: PropTypes.arrayOf(PropTypes.object),
  validationErrors: PropTypes.object,
};

export default withTranslation()(RegistrationProcessView);
