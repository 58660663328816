import { ActionCreator } from 'redux';
import {
  LOAD_SUB_PROFILES_ACTION,
  LOAD_SUB_PROFILES_FAILED_ACTION,
  LOAD_SUB_PROFILES_SUCCEEDED_ACTION,
  ADD_SUB_PROFILE_ACTION,
  ADD_SUB_PROFILE_SUCCEEDED_ACTION,
  ADD_SUB_PROFILE_FAILED_ACTION,
  EDIT_SUB_PROFILE_ACTION,
  EDIT_SUB_PROFILE_SUCCEEDED_ACTION,
  EDIT_SUB_PROFILE_FAILED_ACTION,
  BLOCK_SUB_PROFILE_ACTION,
  BLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
  BLOCK_SUB_PROFILE_FAILED_ACTION,
  UNBLOCK_SUB_PROFILE_ACTION,
  UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
  UNBLOCK_SUB_PROFILE_FAILED_ACTION,
} from './SubProfiles.constants';

export const loadSubProfiles: ActionCreator = (params) => ({
  type: LOAD_SUB_PROFILES_ACTION,
  params: params,
});

export const loadSubProfilesSucceeded: ActionCreator = (
  data,
  totalObjects,
) => ({
  type: LOAD_SUB_PROFILES_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadSubProfilesFailed: ActionCreator = () => ({
  type: LOAD_SUB_PROFILES_FAILED_ACTION,
});

export const addSubProfile: ActionCreator = (data, cbk = () => {}) => ({
  type: ADD_SUB_PROFILE_ACTION,
  data,
  cbk,
});
export const addSubProfileSucceeded: ActionCreator = () => ({
  type: ADD_SUB_PROFILE_SUCCEEDED_ACTION,
});
export const addSubProfileFailed: ActionCreator = () => ({
  type: ADD_SUB_PROFILE_FAILED_ACTION,
});

export const editSubProfile: ActionCreator = (data, cbk = () => {}) => ({
  type: EDIT_SUB_PROFILE_ACTION,
  data,
  cbk,
});
export const editSubProfileSucceeded: ActionCreator = () => ({
  type: EDIT_SUB_PROFILE_SUCCEEDED_ACTION,
});
export const editSubProfileFailed: ActionCreator = () => ({
  type: EDIT_SUB_PROFILE_FAILED_ACTION,
});

export const blockSubProfile: ActionCreator = (id, cbk = () => {}) => ({
  type: BLOCK_SUB_PROFILE_ACTION,
  id,
  cbk,
});
export const blockSubProfileSucceeded: ActionCreator = () => ({
  type: BLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
});
export const blockSubProfileFailed: ActionCreator = () => ({
  type: BLOCK_SUB_PROFILE_FAILED_ACTION,
});

export const unblockSubProfile: ActionCreator = (id, cbk = () => {}) => ({
  type: UNBLOCK_SUB_PROFILE_ACTION,
  id,
  cbk,
});
export const unblockSubProfileSucceeded: ActionCreator = () => ({
  type: UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
});
export const unblockSubProfileFailed: ActionCreator = () => ({
  type: UNBLOCK_SUB_PROFILE_FAILED_ACTION,
});
