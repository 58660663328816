import { ActionCreator } from 'redux';
import {
  CHANGE_ACCESS_COMPANY_ACTION,
  CHANGE_ACCESS_COMPANY_MANUAL_ACTION,
  DELETE_SUB_ACCOUNT_ACTION,
  LOAD_SUB_ACCOUNT_ACTION,
  LOAD_SUB_COMPANY_FAILED_ACTION,
  LOAD_SUB_COMPANY_SUCCEEDED_ACTION,
  SEND_SUB_ACCOUNT_TO_ASSIGN_ACTION,
  VALIDATION_INN_SUB_COMPANY_ACTION,
  SAVE_SUBACCOUNT_LETTER_ACTION,
  SAVE_SUBACCOUNT_LETTER_SUCCEEDED_ACTION,
  LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION,
  GRANT_KM_ORDER_ACTION,
  REVOKE_KM_ORDER_ACTION,
  GET_GCP_GLN_LETTERS,
  DELETE_GCP_GLN_LETTER,
  UPDATE_SUBACCOUNT_ACTION,
  UPDATE_SUBACCOUNT_ACTION_SUCCEEDED,
  UPDATE_SUBACCOUNT_ACTION_FAILED,
  SEND_SUBACCOUNT_GCP
} from './SubAccount.constants';

export const loadSubAccount: ActionCreator = (params) => ({
  type: LOAD_SUB_ACCOUNT_ACTION,
  params: params,
});

export const loadSubAccountSucceeded: ActionCreator = (data, totalObjects) => ({
  type: LOAD_SUB_COMPANY_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadSubAccountsForGoodsCreation: ActionCreator = (
  data,
  totalObjects,
) => ({
  type: LOAD_SUB_COMPANY_FOR_GOODS_CREATION_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadSubAccountFailed: ActionCreator = () => ({
  type: LOAD_SUB_COMPANY_FAILED_ACTION,
});

export const deleteSubAccount: ActionCreator = (id, cbk = () => null) => ({
  type: DELETE_SUB_ACCOUNT_ACTION,
  id: id,
  cbk: cbk,
});

export const validateInnSubAccount: ActionCreator = (
  inn,
  cbk = () => null,
) => ({
  type: VALIDATION_INN_SUB_COMPANY_ACTION,
  inn: inn,
  cbk: cbk,
});

export const sendSubAccount: ActionCreator = (data, cbk = () => null) => ({
  type: SEND_SUB_ACCOUNT_TO_ASSIGN_ACTION,
  data: data,
  cbk: cbk,
});

export const changeAccessCompany: ActionCreator = (data, cbk = () => null) => ({
  type: CHANGE_ACCESS_COMPANY_ACTION,
  data: data,
  cbk: cbk,
});

export const changeAccessCompanyManual: ActionCreator = (payload) => ({
  type: CHANGE_ACCESS_COMPANY_MANUAL_ACTION,
  payload: payload,
});

export const saveSubAccountLetter: ActionCreator = (data, cbk) => ({
  type: SAVE_SUBACCOUNT_LETTER_ACTION,
  data: data,
  cbk: cbk,
});

export const saveSubAccountLetterSucceeded: ActionCreator = (data) => ({
  type: SAVE_SUBACCOUNT_LETTER_SUCCEEDED_ACTION,
  data: data,
});

export const grantPermissionForOrderKm: ActionCreator = (data, cbk) => ({
  type: GRANT_KM_ORDER_ACTION,
  data,
  cbk,
});

export const revokePermissionForOrderKm: ActionCreator = (data, cbk) => ({
  type: REVOKE_KM_ORDER_ACTION,
  data,
  cbk,
});

export const getGcpGlnLetters: ActionCreator = (data, cbk) => ({
  type: GET_GCP_GLN_LETTERS,
  data,
  cbk,
});

export const deleteGcpGlnLetter: ActionCreator = (data, cbk) => ({
  type: DELETE_GCP_GLN_LETTER,
  data,
  cbk,
});

export const updateSubAccount: ActionCreator = (data, id, cbk = () => null) => ({
  type: UPDATE_SUBACCOUNT_ACTION,
  data,
  id,
  cbk,
});

export const updateSubAccountSucceeded: ActionCreator = () => ({
  type: UPDATE_SUBACCOUNT_ACTION_SUCCEEDED,
});

export const updateSubAccountFailed: ActionCreator = () => ({
  type: UPDATE_SUBACCOUNT_ACTION_FAILED,
});

export const sendSubAccountGCP: ActionCreator = (data, id, cbk = () => null) => ({
  type: SEND_SUBACCOUNT_GCP,
  data,
  id,
  cbk,
});