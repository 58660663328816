import * as React from 'react';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { config } from '../../../../config';
import {
  FloatingButton,
  FloatingButtonView,
  Preloader,
  RedirectModal,
} from '../../../../components';
import {
  getPackagingType,
  getPhotoType,
  PackagingFieldName,
} from '../../../../common/constants/Packaging';
import {
  ProductViewMediaItems,
  ProductViewMediaItemType,
  ProductViewMinBorder,
} from '../Product/Product.styled';
import {
  IconNoPhotoBig,
  IconProductClose,
  IconView,
} from '../../../../common/icons/customIcons';
import {
  ProductColumnName,
  ProductColumnValue,
  ProductPrintimgHolder,
  ProductPrintLeft,
  ProductPrintMain,
  ProductPrintMediaItem,
  ProductPrintRight,
  ProductViewButtons,
  ProductViewColumn,
  ProductViewColumnMin,
  ProductViewContainer,
  ProductViewTitle,
  ProductViewTitleMin,
} from './ProductView.styled';
import { StickyBtns } from '../../../../common/styles/Common.styled';
import { gtinDisplay } from '../../../../common/utils/utils';

/**
 * Сортируем аттрибуты в соответствующем порядке
 * @param {attrGroup} - аттрибуты которые относятся к самой упаковке
 * @param {outerAttr} - аттрибуты которые приходят отдельно и относятся к уже зарегестрированным упаковкам.
 */
function attributesSorter(attributes, outerAttr) {
  /**
   * Костыль для того чтобы между группами мультиплицированных полей было визуальное разделение.
   */
  if (outerAttr && outerAttr.length) {
    outerAttr = _.forEach(outerAttr, (attr, index) => {
      if (
        index !== outerAttr.length - 1 &&
        attr.attributes[attr.attributes.length - 1].name !== 'divider'
      ) {
        attr.attributes.push({ name: 'divider', value: Math.random() });
      }
    });
  }

  const sortedAttributes = _.forEach(attributes, (mainBlock) => {
    if (mainBlock.id === 200) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * @param {15800}  - Код вложенной упаковки
       * @param {15803} - Количество вложенных упаковок
       * @param ????? - Торговое наименование товара | приходит из внешнего объекта, нужно добавлять в resultArray
       * @param ????? - Нетто | приходит из внешнего объекта, нужно добавлять в resultArray
       * @param ????? - ТНВЭД | приходит из внешнего объекта, нужно добавлять в resultArray
       */
      [15800, 15803].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[groupedAttr[0][i], groupedAttr[1][i]].filter((x) => x),
        );
        /**
         * Ищем объект внутри аттрибутов, которые приходят из вне, gtin объекта должен совпадать с
         * value у "Код вложенной упаковки".
         *
         * После чего из этого объекта надо забрать массив attributes и смержить его в resultArray, для
         * вывода, не нарушая при этом последовательность.
         */
        if (outerAttr && outerAttr.length) {
          const correspondOuterAttributes = _.find(outerAttr, (obj) => {
            return obj.gtin === groupedAttr[0][i].value;
          });
          if (!_.isEmpty(correspondOuterAttributes)) {
            const fixedAttributes = _.forEach(
              correspondOuterAttributes.attributes,
              (attribute) => {
                attribute._attruid = _.uniqueId('attruid_');
              },
            );
            resultArray = _.concat(resultArray, fixedAttributes);
          }
        }
      }
      mainBlock.attributes = resultArray;
    }
    if (mainBlock.id === 205) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * @param {15808} - Код вложенной не зарегистрированной упаковки
       * @param {15809} - Количество вложенных не зарегистрированных упаковок
       * @param {15812} - Код ТНВЭД не зарегистрированного товара
       * @param {15810} - Торговое наименование не зарегистрированного товара
       * @param {15811} - Нетто не зарегистрированного товара
       */
      [15808, 15809, 15812, 15810, 15811].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });
      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
            groupedAttr[4][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider', value: Math.random() });
        }
      }

      mainBlock.attributes = resultArray;
    }

    if (mainBlock.id === 206) {
      let resultArray = [];
      const groupedAttr = [];

      /**
       * добавили первым элементов "Количество вложенных упаковок", т.к. оно должно
       * выводиться только один раз
       */
      const countWithoutCode = mainBlock.attributes.find(
        (el) => el.id === 15813,
      );
      if (countWithoutCode) {
        resultArray.push(countWithoutCode);
      }

      /**
       * @param {15814} - Торговое наименование товара
       * @param {15815} - Нетто
       * @param {15816} - ТНВЭД
       * @param {15817} - Описание вложения
       */
      [15814, 15815, 15816, 15817].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider', value: Math.random() });
        }
      }

      mainBlock.attributes = resultArray;
    }

    /**
     * Далее два блока, котороные относятся к type: consumer
     * @param {192} - относится к блоку ИЗГОТОВИТЕЛЬ, для consumer package
     * @param {199} - относится к блоку ИМПОРТЕР, для consumer package
     */

    if (mainBlock.id === 197) {
      let resultArray = [];
      const groupedAttr = [];

      [15713, 15716, 15719, 15722].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider', value: Math.random() });
        }
      }

      mainBlock.attributes = resultArray;
    }

    if (mainBlock.id === 199) {
      let resultArray = [];
      const groupedAttr = [];

      [15725, 15728, 15731, 15734].forEach((field) => {
        groupedAttr.push(mainBlock.attributes.filter((el) => el.id === field));
      });

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(
          ...[
            groupedAttr[0][i],
            groupedAttr[1][i],
            groupedAttr[2][i],
            groupedAttr[3][i],
          ].filter((x) => x),
        );
        if (i < groupedAttr[0].length - 1) {
          resultArray.push({ name: 'divider', value: Math.random() });
        }
      }

      mainBlock.attributes = resultArray;
    }
  });

  return sortedAttributes;
}

const boxAttrGroup = (data, type) => {
  const responseField = [];
  const groupedAttr = [];
  const resultArray = [];
  const sortedData = attributesSorter(
    _.cloneDeep(data.attrGroup),
    _.cloneDeep(data.nestedGoodAttributes),
  );

  if (type === 'consumer' || type === 'group') {
    _.forEach(sortedData, (obj, ind) => {
      const fieldArray = [];
      _.forEach(obj.attributes, (o, i) => {
        if (
          !_.isEmpty(o.value) ||
          _.isNumber(o.value) ||
          !_.isEmpty(o.valueType) ||
          !_.isEmpty(o.valueTitle)
        ) {
          o.name !== 'divider'
            ? fieldArray.push(
              <ProductViewColumnMin key={`${i}_${o.id}`}>
                <ProductColumnName>{o.name}</ProductColumnName>
                <ProductColumnValue>
                  {o.valueTitle || o.value} {o.valueType}
                </ProductColumnValue>
              </ProductViewColumnMin>,
            )
            : fieldArray.push(
              <ProductViewColumnMin
                key={`${i}_${Math.random()}`}
                style={{ minHeight: '41px' }}></ProductViewColumnMin>,
            );
        }
      });

      if (!_.isEmpty(fieldArray)) {
        responseField.push(
          <ProductViewColumn key={`${ind}_${obj.id}`}>
            <ProductViewTitleMin>{obj.name}</ProductViewTitleMin>
            <div>{fieldArray}</div>
          </ProductViewColumn>,
        );
      }
    });
  } else {
    _.forEach(sortedData, (obj, ind) => {
      const fieldArray = [];

      _.forEach(
        ['Код вложенной упаковки', 'Количество вложенных упаковок'],
        (field) => {
          groupedAttr.push(
            obj.attributes.filter((el) => el.name.indexOf(field) === 0),
          );
        },
      );

      for (let i = 0; i < groupedAttr[0].length; i++) {
        resultArray.push(groupedAttr[0][i], groupedAttr[1][i]);
      }

      _.forEach(resultArray, (o, i) => {
        if (
          !_.isEmpty(o.value) ||
          _.isNumber(o.value) ||
          !_.isEmpty(o.valueType) ||
          !_.isEmpty(o.valueTitle)
        ) {
          fieldArray.push(
            <ProductViewColumnMin key={`${i}_${o.id}`}>
              <ProductColumnName>{o.name}</ProductColumnName>
              <ProductColumnValue>
                {o.valueTitle || o.value} {o.valueType}
              </ProductColumnValue>
            </ProductViewColumnMin>,
          );
        }
      });

      if (!_.isEmpty(fieldArray)) {
        responseField.push(
          <ProductViewColumn key={`${ind}_${obj.id}`}>
            <ProductViewTitleMin>{obj.name}</ProductViewTitleMin>
            <div>{fieldArray}</div>
          </ProductViewColumn>,
        );
      }
    });
  }

  return responseField;
};

const boxIdentifierLevel = (data, t) => {
  return (
    <div>
      <ProductViewTitleMin>
        {t(getPackagingType(data.level))}
        {data.level === '' ? t('Вторичная упаковка') : null}
      </ProductViewTitleMin>

      <div>{fieldPackage(data, t)}</div>
    </div>
  );
};

const fieldPackage = (data, t) => {
  const findValueVghList = (name) =>
    _.defaultTo(_.find(data.vghList, { name }), { value: '' }).value;
  const packageMaterial = findValueVghList('packageMaterial');
  const packageType = findValueVghList('packageType');
  const packageMaterialCapping = findValueVghList('packageMaterialCapping');
  const packageTypeCapping = findValueVghList('packageTypeCapping');

  return (
    <React.Fragment>
      {!!data.name && (
        <ProductViewColumnMin>
          <ProductColumnName>{t(PackagingFieldName('name'))}</ProductColumnName>
          <ProductColumnValue>{data.name}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!packageMaterial && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('packageMaterial'))}
          </ProductColumnName>
          <ProductColumnValue>{packageMaterial}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!packageType && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('packageType'))}
          </ProductColumnName>
          <ProductColumnValue>{packageType}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!packageMaterialCapping && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('packageMaterialCapping'))}
          </ProductColumnName>
          <ProductColumnValue>{packageMaterialCapping}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!packageTypeCapping && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('packageTypeCapping'))}
          </ProductColumnName>
          <ProductColumnValue>{packageTypeCapping}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.gtin && (
        <ProductViewColumnMin>
          <ProductColumnName>{t(PackagingFieldName('gtin'))}</ProductColumnName>
          <ProductColumnValue>{gtinDisplay(data.gtin)}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.gtinInner && (
        <ProductViewColumnMin className="blockE">
          <ProductColumnName>
            {t(PackagingFieldName('gtinInner'))}
          </ProductColumnName>
          <ProductColumnValue>{data.gtinInner}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.purpose && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('purpose'))}
          </ProductColumnName>
          <ProductColumnValue>{data.purpose}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.width && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('width'))}, см
          </ProductColumnName>
          <ProductColumnValue>{data.width}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.height && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('height'))}, см
          </ProductColumnName>
          <ProductColumnValue>{data.height}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.length && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('length'))}, см
          </ProductColumnName>
          <ProductColumnValue>{data.length}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.weight && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('weight'))}, кг
          </ProductColumnName>
          <ProductColumnValue>{data.weight}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.capacity && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('capacity'))}
          </ProductColumnName>
          <ProductColumnValue>{data.capacity}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.multiplier && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('multiplier'))}
          </ProductColumnName>
          <ProductColumnValue>{data.multiplier}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.layerCount && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('layerCount'))}
          </ProductColumnName>
          <ProductColumnValue>{data.layerCount}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
      {!!data.hasShowBox && (
        <ProductViewColumnMin>
          <ProductColumnName>
            {t(PackagingFieldName('hasShowBox'))}
          </ProductColumnName>
          <ProductColumnValue>{data.hasShowBox}</ProductColumnValue>
        </ProductViewColumnMin>
      )}
    </React.Fragment>
  );
};

class ProductView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      floatingButtonShow: true,
      isOpenRedirectModal: false,
    };
    this.onScrollThrottle = _.throttle(this.onScroll, config.throttleScroll);
  }

  eventListenerOnScroll = () =>
    window.addEventListener('scroll', this.onScrollThrottle);

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollThrottle);
    if (!_.isEmpty(this.props.productData)) {
      this.props.clearProductData();
    }
  }

  onScroll = () => {
    const pageY = window.pageYOffset || document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    const offsetHeight = document.documentElement.offsetHeight;
    if (offsetHeight - clientHeight - 300 > pageY) {
      if (!this.state.floatingButtonShow) {
        this.setState({ floatingButtonShow: true });
      }
    } else {
      if (this.state.floatingButtonShow) {
        this.setState({ floatingButtonShow: false });
      }
    }
  };

  onViewSite = () => {
    const photoDefaultUrl = _.defaultTo(
      _.find(this.props.images,
        (image) => image.type === 'A1N1'
          || image.type === 'A2N1'
          || image.type === 'A7N1'
          || image.type === 'A8N1'
          || image.type === 'A3N1'
          || image.type === 'A9N1'
          || image.type === 'A1R1'),
      { url: '' },
    ).url;
    const link = this.props.productData.link;
    if (photoDefaultUrl || !this.props.productData.isImageRequired) {
      window.open(link, '_black');
    } else {
      this.handleRedirectModal();
    }
  };

  handleRedirectModal = () => {
    this.setState((prevState) => ({
      isOpenRedirectModal: !prevState.isOpenRedirectModal,
    }));
  };

  onCloseButton = () => {
    this.props.setFiltersGrid();
    if (this.props.match.params.catalog) {
      this.props.goToPage(config.urls.catalog);
    } else this.props.goToPage(config.urls.products);
  };

  /*
    downloadProduct = () => {
        let params = {
            entityType: this.props.productData.entityType,
            id: this.props.productData.entityType === 'draft' ? this.props.productData.draftId : this.props.productData.goodId
        };

        this.props.onDownloadProduct(params, (errors, data) => {
            // if (!errors) {
            //     const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            //     download(blob, "Краткий отчет.xlsx");
            // }
            // this.setState({loading: false});
        });
    };
*/

  render() {
    const { loading, productData, images, t } = this.props;
    const technicalPhoto = !_.find(images,
      (image) => image.type === 'A1N1'
        || image.type === 'A2N1'
        || image.type === 'A7N1'
        || image.type === 'A8N1'
        || image.type === 'A3N1'
        || image.type === 'A9N1'
        || image.type === 'A1R1')
    const PrintImgHolder = !_.isEmpty(images) && !technicalPhoto ? (images[0].url) : null;
    if (loading) return <Preloader isOpen={loading} />;

    const photoDefaultUrl = _.defaultTo(_.find(images, { type: 'A1N1' }), {
      url: '',
    }).url;

    const photoUrl = _.defaultTo(
      _.find(images,
        (image) => image.type === 'A1N1'
          || image.type === 'A2N1'
          || image.type === 'A7N1'
          || image.type === 'A8N1'
          || image.type === 'A3N1'
          || image.type === 'A9N1'
          || image.type === 'A1R1'),
      { url: '' },
    ).url;
    const fieldCategory = _.find(_.get(productData, 'categories'), {
      classifier: 'k3',
    });
    const fieldTNVD = _.find(_.get(productData, 'categories'), {
      classifier: 'tnved',
    });
    const fieldGpc = _.find(_.get(productData, 'categories'), {
      classifier: 'gpc',
    });
    const currentCardType = productData && productData.packingPurpose;

    const returnPackageType = (type) => {
      switch (type) {
        case 'consumer':
          return t('Потребительская упаковка');
        case 'transport':
          return t('Транспортная упаковка');
        case 'group':
          return t('Групповая упаковка');
        default:
          return;
      }
    };

    return (
      <div>
        <ProductViewContainer>
          <ProductPrintMain>
            {this.state.isOpenRedirectModal && (
              <RedirectModal
                handleClose={this.handleRedirectModal}
                noImg={true}
              />
            )}

            <ProductPrintLeft>
              <ProductPrintimgHolder>
                {!photoUrl ? (
                  <IconNoPhotoBig />
                ) : (
                  <img width="100%" alt="default" src={PrintImgHolder} />
                )}
              </ProductPrintimgHolder>
            </ProductPrintLeft>

            <ProductPrintRight>
              {!_.isEmpty(productData.attrGroup) && (
                <ProductViewMinBorder>
                  <ProductViewTitle>
                    {returnPackageType(currentCardType)}
                  </ProductViewTitle>

                  {!_.isEmpty(fieldCategory) && (
                    <ProductViewColumn>
                      <ProductViewTitleMin>
                        {t('Категория товара')}
                      </ProductViewTitleMin>
                      <div>{_.get(fieldCategory, 'categoryName', '')}</div>
                    </ProductViewColumn>
                  )}

                  {!_.isEmpty(fieldTNVD) && (
                    <ProductViewColumn>
                      <ProductViewTitleMin>{t('ТНВЭД')}</ProductViewTitleMin>
                      <div>{_.get(fieldTNVD, 'categoryName', '')}</div>
                    </ProductViewColumn>
                  )}

                  {!_.isEmpty(fieldGpc) && (
                    <ProductViewColumn>
                      <ProductViewTitleMin>{'GPC'}</ProductViewTitleMin>
                      <div>{_.get(fieldGpc, 'categoryName', '')}</div>
                    </ProductViewColumn>
                  )}

                  {boxAttrGroup(productData, currentCardType)}
                </ProductViewMinBorder>
              )}

              {!_.isEmpty(productData.identifier) && (
                <ProductViewMinBorder>
                  <ProductViewTitle>
                    {t('Весогабаритные характеристики')}
                  </ProductViewTitle>
                  {_.map(productData.identifier, (data, n) => (
                    <ProductViewColumn key={`data.level_${n}`}>
                      {data.level === 'trade-unit' &&
                        boxIdentifierLevel(data, t)}
                      {data.level === 'inner-pack' &&
                        boxIdentifierLevel(data, t)}
                      {data.level === 'box' && boxIdentifierLevel(data, t)}
                      {data.level === 'pallet' && boxIdentifierLevel(data, t)}
                      {data.level === '' && boxIdentifierLevel(data, t)} {/* времено, пока с бека не приходит для вторичной упаковки */}
                    </ProductViewColumn>
                  ))}
                </ProductViewMinBorder>
              )}

              {!_.isEmpty(images) && (
                <ProductViewMinBorder>
                  <ProductViewTitle>{t('Фотоконтент')}</ProductViewTitle>
                  <ProductViewMediaItems>
                    {_.map(images, (v, i) => (
                      <ProductPrintMediaItem key={i} value={i}>
                        <img alt={`${v.gtin} ${v.type}`} src={v.url} />
                        <ProductViewMediaItemType>
                          {t(getPhotoType(v.type))}
                        </ProductViewMediaItemType>
                      </ProductPrintMediaItem>
                    ))}
                  </ProductViewMediaItems>
                </ProductViewMinBorder>
              )}

              {this.state.floatingButtonShow && (
                <StickyBtns>
                  <FloatingButtonView>
                    <React.Fragment>
                      {this.props.buttonViewSite &&
                        !_.isNil(productData.link) && (
                          <FloatingButton
                            text={t('Открыть на сайте')}
                            onClick={this.onViewSite}
                            color={'primary'}
                            icon={<IconView style={{ marginRight: '5px' }} />}
                          />
                        )}

                      <FloatingButton
                        text={t('Закрыть')}
                        onClick={this.onCloseButton}
                        color={'secondary'}
                        icon={
                          <IconProductClose style={{ marginRight: '5px' }} />
                        }
                      />
                    </React.Fragment>
                  </FloatingButtonView>
                </StickyBtns>
              )}

              <ProductViewButtons>
                {/*
                            <Button onClick={this.downloadProduct}>
                                Скачать
                            </Button>
                            */}

                <React.Fragment>
                  {this.props.buttonViewSite && !_.isNil(productData.link) && (
                    <Button onClick={this.onViewSite}>
                      {t('Открыть на сайте')}
                    </Button>
                  )}

                  <Button
                    color={'secondary'}
                    id="closeProductViewButton"
                    onClick={this.onCloseButton}>
                    {t('Закрыть')}
                  </Button>
                </React.Fragment>
              </ProductViewButtons>
            </ProductPrintRight>
          </ProductPrintMain>
        </ProductViewContainer>
      </div>
    );
  }
}

ProductView.defaultProps = {
  buttonViewSite: true,
};

export default ProductView;
