import * as React from 'react';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { IconError } from '../../common/icons/customIcons';
import {
  InputIcon,
  TextTooltip,
  TextTooltipModeration,
  TextTooltipModerationUpper,
  TextTooltipUpper,
} from '../../common/styles/Common.styled';
import { ModerationIconHolder } from '../../pages/product/view/Product/Product.styled';
import i18n from '../../i18n';
const t = function (cell) { return (i18n.t(cell)) };
const _customTooltip = (  
  type,
  id,
  title,
  icon,
  bottom = true,
  handleOpen = () => null,
) => {
  const theme =
    type === 'moderate'
      ? bottom
        ? TextTooltipModeration
        : TextTooltipModerationUpper
      : bottom
      ? TextTooltip
      : TextTooltipUpper;
  
  const props =
    type === 'simple' ? { title: title } : { id, title: title, onOpen: handleOpen(id) };
  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip {...props} placement="bottom-end">
        {icon}
      </Tooltip>
    </MuiThemeProvider>
  );
};

export const SimpleTooltip = (title, icon) => {
  const icons = <InputIcon>{icon}</InputIcon>;
  return _customTooltip('simple', null, title, icons);
};

export const RenderTooltip = (...arg) => _customTooltip('normal', ...arg);

export const ModerateTooltip = (id, title, bottom, handleOpen) => {
  const icon = (
    <ModerationIconHolder>
      <IconError />
    </ModerationIconHolder>
  );
  return _customTooltip('moderate', id, title, icon, bottom, handleOpen);
};
