import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { client } from '../../../../api/Client';

import {
  RowItem,
  DateItem,
  ValueItem,
} from './styled/DocumentAndOptions.styled';

class StatisticBlock extends React.Component {
  returnStatusString = (status) => {
    const { t } = this.props;
    switch (_.toString(status)) {
      case '0':
        return <div style={{ color: 'green' }}>{t(`Действующий`)}</div>;
      case '1':
        return t(`Членство приостановлено`);
      case '2':
        return t(`Исключен`);
      default:
        return t(`Отсутствует`);
    }
  };

  render() {
    const { t, data } = this.props;

    return (
      <div style={{ padding: '15px 0' }}>
        <RowItem>
          <div>{t('Статус членства в организации GS1')}:</div>
          <ValueItem>
            {this.returnStatusString(_.get(data, 'status', null))}
          </ValueItem>
        </RowItem>
        <RowItem style={{ padding: 0, color: "#a9a9a9" }}>
          <div>{t('Зарегистрированный префикс GCP')}:</div>
          <ValueItem>{_.get(data, 'prefix', null)}</ValueItem>
        </RowItem>
        <RowItem style={{ padding: 0, color: "#a9a9a9" }}>
          <div>{t('Количество лимита gtin ')}:</div>
          <ValueItem>{_.get(data, 'LimitGtin', null)}</ValueItem>
        </RowItem>
        <RowItem style={{ padding: 0, color: "#a9a9a9" }}>
          <div>{t('Количество сгенерированных gtin ')}:</div>
          <ValueItem>{_.get(data, 'UsedGtin', null)}</ValueItem>
        </RowItem>
        <RowItem style={{ padding: 0, color: "#a9a9a9" }}>
          <div>{t('Количество остатка gtin ')}:</div>
          <ValueItem>{ (_.get(data, 'LimitGtin', null)-(_.get(data, 'UsedGtin', null)))}</ValueItem>
        </RowItem>
        <RowItem style={{ padding: 0, color: "#a9a9a9" }}>
          <DateItem>{t('Дата регистрации')}:</DateItem>
          <DateItem>
            <ValueItem>{_.get(data, 'issueDate', `00.00.0000`)}</ValueItem>
          </DateItem>
        </RowItem>

      </div>
    );
  }
}

StatisticBlock.propTypes = {
  data: PropTypes.object.isRequired,
};

export default compose(withTranslation(), connect())(StatisticBlock);
