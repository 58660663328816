import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withAlert } from 'react-alert';
import { forEach, isEmpty, isEqual } from 'lodash';
import { clearNotification } from './Notification.actions';
import Root from '../../root/Root';

const WAIT_INTERVAL = 5000;
// пример использования в саге
// yield put(throwNotification('loadHistoryProduct', 'success'));
class NotificationView extends Root {
  constructor(props) {
    super(props);
    this.state = {
      prevMessage: '',
    };
  }

  componentDidUpdate() {
    if (this.props.type) {
      const message = this.props.message;
      if (isEmpty(this.state.prevMessage)) {
        this.setState({ prevMessage: message });
        this.showAlert(this.props.type, message, this.props.timeout);
        this.intervalID = setInterval(this.clearState, WAIT_INTERVAL);
      } else {
        if (!isEqual(message, this.state.prevMessage)) {
          this.showAlert(this.props.type, message, this.props.timeout);
        }
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    const { clearNotification, type, remove, alert } = nextProps;
    if (remove) {
      forEach(alert.alerts, (alert) => {
        this.removeAlert(alert);
      });
    }
    if (!type) {
      return false;
    }
    clearNotification();
    return true;
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  clearState = () => {
    this.setState({ prevMessage: '' });
    clearInterval(this.intervalID);
  };

  render() {
    return '';
  }
}

const mapStateToProps = (state) => ({
  type: state.notificationReducer.type,
  message: state.notificationReducer.message,
  timeout: state.notificationReducer.timeout,
  remove: state.notificationReducer.remove,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { clearNotification: () => clearNotification() },
    dispatch,
  );
};

Root.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  timeout: PropTypes.number,
  remove: PropTypes.bool,
};

export default compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationView);
