import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { NoDataComponent, Pagination } from '../../../components';
import 'react-table/react-table.css';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default class TableGrid extends React.Component {
  shouldComponentUpdate(nextProps) {
    const equal = [];
    const nameProps = [
      'data',
      'columns',
      'defaultPageSize',
      'page',
      'pages',
      'pageSize',
      'selected',
      'filters', // эти нужны только для перерендера defaultColumns
    ];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  render() {
    const data = _.map(this.props.data, i => i);

    return (
      <ReactTableFixedColumns
        data={data}
        key={this.props.pageSize}
        columns={this.props.columns}
        defaultPageSize={this.props.defaultPageSize}
        page={this.props.page}
        pages={this.props.pages}
        pageSize={this.props.pageSize}
        PaginationComponent={Pagination}
        getPaginationProps={this.props.getPaginationProps}
        onPageChange={(page) => this.props.onPageChange(page)}
        NoDataComponent={NoDataComponent}
        getNoDataProps={this.props.getNoDataProps}
        className="-highlight"
        resizable={false}
        minRows={0}
        manual // this would indicate that server side pagination has been enabled
      />
    );
  }
}

TableGrid.propTypes = {
  getPaginationProps: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  getNoDataProps: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};
