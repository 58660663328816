import { Reducer } from 'redux';
import {
  LOAD_SUB_PROFILES_ACTION,
  LOAD_SUB_PROFILES_FAILED_ACTION,
  LOAD_SUB_PROFILES_SUCCEEDED_ACTION,
  ADD_SUB_PROFILE_ACTION,
  ADD_SUB_PROFILE_SUCCEEDED_ACTION,
  ADD_SUB_PROFILE_FAILED_ACTION,
  EDIT_SUB_PROFILE_ACTION,
  EDIT_SUB_PROFILE_SUCCEEDED_ACTION,
  EDIT_SUB_PROFILE_FAILED_ACTION,
  BLOCK_SUB_PROFILE_ACTION,
  BLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
  BLOCK_SUB_PROFILE_FAILED_ACTION,
  UNBLOCK_SUB_PROFILE_ACTION,
  UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION,
  UNBLOCK_SUB_PROFILE_FAILED_ACTION,
} from './SubProfiles.constants';
import { subtractNotNegative } from '../../../../common/utils/utils';

const _initialState = {
  data: [],
  totalObjects: 0,
  loading: 0,
};

export const subProfilesReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case ADD_SUB_PROFILE_ACTION:
    case EDIT_SUB_PROFILE_ACTION:
    case BLOCK_SUB_PROFILE_ACTION:
    case UNBLOCK_SUB_PROFILE_ACTION:
    case LOAD_SUB_PROFILES_ACTION: {
      return { ...state, loading: state.loading + 1 };
    }

    case LOAD_SUB_PROFILES_FAILED_ACTION: {
      return {
        ...state,
        data: [],
        totalObjects: 0,
        loading: subtractNotNegative(state.loading),
      };
    }
    case LOAD_SUB_PROFILES_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: action.data,
        totalObjects: action.data.length,
        loading: subtractNotNegative(state.loading),
      };
    }

    case ADD_SUB_PROFILE_SUCCEEDED_ACTION:
    case ADD_SUB_PROFILE_FAILED_ACTION:
    case EDIT_SUB_PROFILE_SUCCEEDED_ACTION:
    case EDIT_SUB_PROFILE_FAILED_ACTION:
    case BLOCK_SUB_PROFILE_SUCCEEDED_ACTION:
    case BLOCK_SUB_PROFILE_FAILED_ACTION:
    case UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION:
    case UNBLOCK_SUB_PROFILE_FAILED_ACTION: {
      return {
        ...state,
        loading: subtractNotNegative(state.loading),
      };
    }

    default:
      return { ...state };
  }
};
