import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ColumnCellInner,
  TextTooltipName,
} from '../../../../common/styles/Common.styled';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import { ColumnCell, PointerName } from '../ProductsGrid.styled';

const styleText = {
  width: '-webkit-fill-available',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
const styleDiv = {
  width: 'auto',
  display: 'inline-block',
  visibility: 'hidden',
  position: 'fixed',
  overflow: 'auto',
};

export default class NameCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipText: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.original, nextProps.original) ||
      !_.isEqual(this.state, nextState)
    );
  }

  componentDidMount() {
    this.calculationSizeTooltip();
    this.timeout = setTimeout(this.calculationSizeTooltip, 3000);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.original, this.props.original)) {
      this.calculationSizeTooltip();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  calculationSizeTooltip = () => {
    const uid = `${this.props.original.nameDto}_${this.props.original.dto.id}`;
    const elemDiv = document.getElementById(`div-${uid}`);
    const elemText = document.getElementById(`box-${uid}`);
    if (!_.isNil(elemDiv)) {
      this.setState({
        isTooltipText: elemDiv.clientWidth > elemText.clientWidth,
      });
    }
  };

  render() {
    const { onView, onViewRemainder } = this.props;
    const { dto, nameDto } = this.props.original;

    const uid = `${nameDto}_${dto.id}`;
    const disabled = dto.status === 'require_changes';
    const actionFunc = !!dto.isRemainder ? onViewRemainder : onView;

    return (
      <ColumnCell>
        <ColumnCellInner
          onClick={() => (disabled ? null : actionFunc(dto.id, nameDto))}>
          <PointerName disabled={disabled} id={`box-${uid}`}>
            {!this.state.isTooltipText || !dto.name ? (
              <span style={styleText}>{dto.name}</span>
            ) : (
              <MuiThemeProvider theme={TextTooltipName}>
                <Tooltip leaveDelay={0} placement="bottom-end" title={dto.name}>
                  <span style={styleText}>{dto.name}</span>
                </Tooltip>
              </MuiThemeProvider>
            )}

            <div id={`div-${uid}`} style={styleDiv}>
              {dto.name}
            </div>
          </PointerName>
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

NameCell.propTypes = {
  onView: PropTypes.func.isRequired,
  onViewRemainder: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};
