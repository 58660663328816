import { ActionCreator } from 'redux';

import {
  AUTHENTICATION_SESSION_ACTIONS,
  LOGIN_ACTION,
  LOGIN_FAILED_ACTION,
  LOGIN_SUCCEEDED_ACTION,
  LOGOUT_ACTION,
  LOGOUT_SUCCESS_ACTION,
  REMIND_PASSWORD_ACTION,
  REMIND_PASSWORD_FAILED_ACTION,
  REMIND_PASSWORD_SUCCEEDED_ACTION,
  ACTIVE_SESSION_OPEN_MODAL_ACTION, //449
  ACTIVE_SESSION_CLOSE_MODAL_ACTION,
  ACTIVE_ELK_SESSION_OPEN_MODAL_ACTION,
  ACTIVE_ELK_SESSION_CLOSE_MODAL_ACTION,
} from './AuthentificationActionTypes.contants';

export const login: ActionCreator = (data) => ({
  type: LOGIN_ACTION,
  data: data,
});

export const loginSucceesed: ActionCreator = (userInfo) => {
  return {
    type: LOGIN_SUCCEEDED_ACTION,
    userInfo: userInfo,
  };
};

export const loginFailed: ActionCreator = () => ({
  type: LOGIN_FAILED_ACTION,
});

export const logout: ActionCreator = () => ({
  type: LOGOUT_ACTION,
});

export const logoutSuccess: ActionCreator = () => ({
  type: LOGOUT_SUCCESS_ACTION,
});

export const remindPassword: ActionCreator = (data) => ({
  type: REMIND_PASSWORD_ACTION,
  data: data,
});

export const remindPasswordSucceesed: ActionCreator = () => ({
  type: REMIND_PASSWORD_SUCCEEDED_ACTION,
});

export const remindPasswordFailed: ActionCreator = () => ({
  type: REMIND_PASSWORD_FAILED_ACTION,
});
export const authenticationSession: ActionCreator = (params, pathname) => ({
  type: AUTHENTICATION_SESSION_ACTIONS,
  params: params,
  pathname: pathname,
});

export const activeSessionOpenModal: ActionCreator = (info) => ({
  type: ACTIVE_SESSION_OPEN_MODAL_ACTION,
  info: info,
});

export const activeSessionCloseModal: ActionCreator = () => ({
  type: ACTIVE_SESSION_CLOSE_MODAL_ACTION,
});

export const activeElkSessionOpenModal: ActionCreator = (info) => ({
  type: ACTIVE_ELK_SESSION_OPEN_MODAL_ACTION,
  info: info,
});

export const activeElkSessionCloseModal: ActionCreator = () => ({
  type: ACTIVE_ELK_SESSION_CLOSE_MODAL_ACTION,
});
