import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const DefaultFilterPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '500px',
        minWidth: '330px',
        maxWidth: '330px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '0px 0px 3px 3px',
        padding: '25px 15px',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
    },
    MuiButton: {
      root: {
        maxWidth: '150px',
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '8px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
      },
      disabled: {},
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#52535A!important',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
  },
});
export const FilterBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const FilterTypingSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '16px 85px 16px 9px',
        fontSize: '14px',
        color: '#52535A',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '8px 10px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontSize: '14px',
        color: '#52535A',
        fontWeight: '500',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px',
        overflowY: 'auto',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        marginTop: '-15px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '3px',
      },
    },
  },
});
export const defaultFilterSelect = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      select: {
        padding: '16px 9px',
      },
      selectMenu: {
        color: '#000',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#52535A',
        fontSize: '14px',
        lineHeight: '20px',
      },
      formControl: {
        transform: 'translate(9px, 18px) scale(1)',
        color: '#52535A',
      },
      shrink: {
        display: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#52535A',
        '&$focused': {
          color: '#52535A',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '9px',
        paddingTop: '9px',
        paddingBottom: '9px',
        color: '#52535A',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      },
    },
  },
});
export const defaultFilterInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '16px 9px',
        borderRadius: '3px',
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '51px',
        padding: '10px 9px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#52535A',
        fontSize: '14px',
        lineHeight: '20px',
      },
      formControl: {
        transform: 'translate(9px, 18px) scale(1)',
        color: '#52535A',
      },
      shrink: {
        display: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#52535A',
        '&$focused': {
          color: '#52535A',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const FilterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: #52535a;
    font-size: 14px;
    margin: 0;
    line-height: 20px;
  }
  .backEmpty {
    background: none;
  }
`;
export const FilterContent = styled.div`
  display: flex;
  padding: 20px 0;
  position: relative;
`;
export const FilterContentProvider = styled(FilterContent)`
  flex-direction: column;
`;
export const FilterContentProviderContainer = styled.div`
  position: relative;
`;
export const FilterBtns = styled.div`
  display: flex;
  justify-content: space-between;
  .backEmpty {
    background: none;
    color: #52535a !important;
    &:hover{
      color: #fff !important;
    }
  }
`;
export const FilterContainer = styled.div`
  .filterBtn {
    min-width: 25px;
    padding: 4px;
    height: 25px;
  }
`;
export const DateRangeCont = styled.div`
  width: 100%;
  .rdrCalendarWrapper {
    width: 100%;
  }
  .rdrMonthAndYearPickers {
    justify-content: flex-start;
  }
  .rdrMonths {
    padding-top: 20px;
  }
  .rdrMonth {
    padding: 0;
    box-sizing: border-box;
    width: 100% !important;
  }
  .rdrWeekDays {
    display: none;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    min-width: 130px;
    select {
      width: 100%;
    }
  }
  .rdrDay {
    font-size: 14px;
    color: #52535a;
    font-weight: 600 !important;
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrInRange {
      background: ${colors.Common.background};
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border-radius: 4px;
      z-index: 2;
    }
    .rdrStartEdge {
      border-radius: 4px 0 0 4px;
    }
    .rdrEndEdge {
      border-radius: 0 4px 4px 0;
    }
    .rdrInRange {
      border-radius: 0;
    }
    .rdrStartEdge.rdrEndEdge {
      border-radius: 4px;
    }
    .rdrDayNumber {
      background: #f9f9f9;
      font-weight: 600 !important;
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border: 1px solid #fff;
    }

    .rdrDayNumber span {
      color: #52535a !important;
      z-index: 3;
    }
  }
  .rdrDayPassive .rdrDayNumber span {
    color: #d9d9d9 !important;
  }
  .rdrDay.rdrDayToday {
    .rdrDayNumber {
      span: after {
        background: #52535a !important;
      }
    }
  }
`;
export const FilterButton = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;
