import _ from 'lodash';

const getDefaultTo = (obj, path, def = '') =>
  _.defaultTo(_.get(obj, path, def), def);

const Base64 = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  encode: function (input) {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }

    return output;
  },

  decode: function (input) {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;

    //eslint-disable-next-line
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 !== 64) {
        output = output + String.fromCharCode(chr2);
      }

      if (enc4 !== 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';

    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  _utf8_decode: function (utftext) {
    let string = '';
    let i = 0;
    let c2;
    let c = (c2 = 0);

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        let c3;
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63),
        );
        i += 3;
      }
    }

    return string;
  },
};

export function signData(certificate, dataBase64) {
  const cadesplugin = window.cadesplugin;
  return cadesplugin.async_spawn(
    function* (args) {
      const certificate = args[0];
      const dataBase64 = args[1]; // Data приходит в base64 c бэка

      let Signature;

      let oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
      if (!oSigner) {
        throw new Error('Failed to create CAdESCOM.CPSigner');
      }

      let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
        'CADESCOM.CPAttribute',
      );

      yield oSigningTimeAttr.propset_Name(
        cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
      );

      const oTimeNow = new Date();
      yield oSigningTimeAttr.propset_Value(oTimeNow);

      let attr = yield oSigner.AuthenticatedAttributes2;
      yield attr.Add(oSigningTimeAttr);

      let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
        'CADESCOM.CPAttribute',
      );
      yield oDocumentNameAttr.propset_Name(
        cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME,
      );
      yield oDocumentNameAttr.propset_Value('CRPT');
      yield attr.Add(oDocumentNameAttr);

      yield oSigner.propset_Certificate(certificate);

      let oSignedData = yield cadesplugin.CreateObjectAsync(
        'CAdESCOM.CadesSignedData',
      );

      // Данные на подпись ввели
      yield oSigner.propset_Options(
        cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN,
      );
      yield oSignedData.propset_ContentEncoding(
        cadesplugin.CADESCOM_BASE64_TO_BINARY,
      );
      yield oSignedData.propset_Content(dataBase64);

      try {
        Signature = yield oSignedData.SignCades(
          oSigner,
          cadesplugin.CADESCOM_CADES_BES,
        );
      } catch (err) {
        throw cadesplugin.getLastError(err);
      }
      return Signature;
    },
    certificate,
    dataBase64,
  );
}

export function signXML(certificate, xml, cachePin) {
  const cadesplugin = window.cadesplugin;

  return cadesplugin.async_spawn(
    function* (args) {
      let Signature;
      const certificate = args[0];
      const dataToSign = args[1];
      const cachePin = args[2];

      const linkPrivateKey = yield certificate.PrivateKey;
      linkPrivateKey.propset_CachePin(cachePin);

      const oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');

      if (!oSigner) {
        throw new Error('Failed to create CAdESCOM.CPSigner');
      }
      yield oSigner.propset_Certificate(certificate);

      const oSignedXML = yield cadesplugin.CreateObjectAsync(
        'CAdESCOM.SignedXML',
      );

      let signMethod = '';
      let digestMethod = '';

      const pubKey = yield certificate.PublicKey();
      const algo = yield pubKey.Algorithm;
      const algoOid = yield algo.Value;

      if (algoOid === '1.2.643.7.1.1.1.1') {
        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
        signMethod =
          'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256';
        digestMethod =
          'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256';
      } else if (algoOid === '1.2.643.7.1.1.1.2') {
        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
        signMethod =
          'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512';
        digestMethod =
          'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512';
      } else if (algoOid === '1.2.643.2.2.19') {
        // алгоритм ГОСТ Р 34.10-2001
        signMethod =
          'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';
        digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';
      } else {
        throw new Error(
          'Поддерживается XML подпись только сертификатами с алгоритмом ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001',
        );
      }

      const CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0;

      if (dataToSign) {
        // Данные на подпись ввели
        yield oSignedXML.propset_Content(dataToSign);
        yield oSignedXML.propset_SignatureType(
          CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED,
        );
        yield oSignedXML.propset_SignatureMethod(signMethod);
        yield oSignedXML.propset_DigestMethod(digestMethod);

        try {
          Signature = yield oSignedXML.Sign(oSigner);
        } catch (err) {
          throw new Error(
            'Не удалось создать подпись из-за ошибки: ' +
              cadesplugin.getLastError(err),
          );
        }
      }
      return Signature;
    },
    certificate,
    xml,
    cachePin,
  );
}

export function handlerCryptoMessage(error, textDefault) {
  let message;
  const err = getDefaultTo(error, 'message', error);
  switch (true) {
    case /0x000004C7/.test(err): {
      message = 'Операция была отменена пользователем';
      break;
    }
    case /0x8007065B/.test(err): {
      message = 'Истекла лицензия на КриптоПро CSP';
      break;
    }
    case /0X80090008/.test(err): {
      message = 'Подписание не удалось. Требуется переустановка КриптоПро CSP';
      break;
    }
    case /0X80070057/.test(err): {
      message =
        'Подписание не удалось. Требуется переустановка КриптоПро ЭЦП browser plug-in';
      break;
    }
    case /0X80092004/.test(err): {
      message =
        'Подписание не удалось. Не установлена связь с закрытым ключом, привяжите личный сертификат к закрытому ключу';
      break;
    }
    case /0Х80070645/.test(err): {
      message =
        'Подписание не удалось. Активируйте лицензию, введите все серийные номера';
      break;
    }
    case /0X800A01AD/.test(err): {
      message =
        'Подписание не удалось. Разрешите предлагаемую надстройку в интернет-браузере';
      break;
    }
    case /0X8009000D/.test(err): {
      message =
        'Подписание не удалось. Истек срок действия лицензии КриптоПро CSP или произошел сбой в запомненных паролях';
      break;
    }
    case /0X8007139F/.test(err): {
      message =
        'Подписание не удалось. Группа или ресурс не находятся в нужном состоянии для выполнения требуемой операции';
      break;
    }
    case /0x8000FFFF/.test(err): {
      message = 'Сертификат УКЭП не действителен';
      break;
    }
    case /0x800B010A/.test(err): {
      message = 'Не установлен корневой сертификат';
      break;
    }
    case /0x8009200B/.test(err): {
      message =
        'Сертификат некорректный. Или вы используете тестовый сертификат от тестового УЦ';
      break;
    }
    case '0x8010006E':
    default:
      message = textDefault;
      const errCode = err.match(/0[xX][0-9a-fA-F]+/gm, '');
      if (errCode[0]) {
        message = `${message} Code: ${errCode[0]}`;
      }
      break;
  }
  return message;
}
