export const accountTypes = [
  { id: 0, title: 'Поставщик контента', value: 'producer' },
  { id: 1, title: 'Потребитель контента', value: 'net' },
  { id: 2, title: 'КСП', value: 'csp' },
];

// export const fullTypes = [
//   { id: 0, title: 'Поставщик контента', value: 'producer' },
//   { id: 1, title: 'Потребитель контента', value: 'net' },  
//   { id: 2, title: 'Импортер', value: 'importer' },
//   { id: 3, title: 'КСП', value: 'csp' }, 
// ];

export const fullTypes = [
  { id: 0, title: 'Заявитель сведений', value: 'producer' },
  { id: 1, title: 'Потребитель сведений', value: 'net' },
  { id: 2, title: 'Импортер', value: 'importer' },
  { id: 3, title: 'КСП', value: 'csp' },
  { id: 4, title: 'Потребитель сведений', value: 'content-consumer.catalog' },
  { id: 5, title: 'Потребитель сведений', value: 'content-consumer.companies' },
];

export const searchTypes = [
  { id: 'DocNumb', text: 'Номер документа' },
  { id: 'DocStatus', text: 'Статус' },
  { id: 'SerialNumb', text: 'Типографский номер бланка' },
  { id: 'MakerName', text: 'Наименование изготовителя' },
  { id: 'ReceiverName', text: 'Наименование получателя' },
];

export const activityTypes = [
  { id: 1, text: 'Производитель' },
  { id: 2, text: 'Розничный торговец' },
  { id: 3, text: 'Оптовик' },
  { id: 4, text: 'Другое' },
  { id: 5, text: 'Импортёр' },
];

export const productionBaseTypes = [
  { id: 1, text: 'Химическая продукция' },
  { id: 2, text: 'Приборы и оборудование' },
  { id: 3, text: 'Таблетки, настойки, субстанции' },
  { id: 4, text: 'Лекарственные травы' },
  { id: 5, text: 'Лекарственные средства' },
  { id: 6, text: 'Нет информации' },
  { id: 7, text: 'Полиграфическая продукция' },
  { id: 8, text: 'Табачные изделия' },
  { id: 9, text: 'Изделия медицинского назначения' },
  { id: 10, text: 'Предметы личной гигиены' },
  { id: 11, text: 'Строительно материалы' },
  { id: 12, text: 'Программное обеспечение' },
  { id: 13, text: 'Предметы быта' },
  { id: 15, text: 'Бытовая химия' },
  { id: 16, text: 'Косметическая продукция' },
  { id: 17, text: 'Пластиковые карты' },
  { id: 18, text: 'Безалкогольные напитки, Соки и питьевая вода' },
  { id: 19, text: 'Рыба, мясо, яйцо' },
  { id: 20, text: 'Мука, Мучные изделия' },
  { id: 21, text: 'Кондитерские изделия' },
  { id: 22, text: 'Алкогольная продукция' },
  { id: 23, text: 'Пищевые добавки и специи' },
  { id: 24, text: 'Масло, жиры, маргарин' },
  { id: 25, text: 'Сахар' },
  { id: 26, text: 'Молочные изделия' },
  { id: 27, text: 'Канцелярские' },
  { id: 28, text: 'Консервированные овощи и фрукты' },
  { id: 29, text: 'Сухофрукты, Фрукты и овощи' },
  { id: 30, text: 'Соль' },
  { id: 31, text: 'Крупы' },
  { id: 32, text: 'Колбасные изделия' },
  { id: 33, text: 'Мясо и мясоконсервная продукция' },
  { id: 34, text: 'Полуфабрикаты' },
  { id: 38, text: 'Хлебо изделия' },
  { id: 39, text: 'Меховые изделия' },
  { id: 40, text: 'Текстильные изделия' },
  { id: 41, text: 'Обувная продукция' },
  { id: 42, text: 'oeuoaeu' },
];

export const companyTypesList = {
  0: 'small',
  1: 'beekeeper',
  2: 'big',
  3: 'family',
  4: 'y.t.t',
  5: 'invalid',
};

export const validateRangesIds = {
  range1: [790, 820],
  range2: [791, 13863],
};
