import { all, call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { client } from '../../../api/Client';
import { handleErrorNotification } from '../../ducks/HandleErrors';

import {
  loadCompaniesDataFailed,
  loadCompaniesDataSucceeded,
} from './Companies.actions';

import {
  LOAD_COMPANIES_DATA_ACTION,
  EXPORT_COMPANIES_ACTION,
} from './Companies.constants';

function buildQueryParametersCompaniesList(action) {
  let params = {
    pageSize: action.pageSize,
    pageNumber: action.pageNum + 1 || 1,
    sort: action.sort,
    fields: action.fields,
  };

  if (_.isArray(action.sort) || _.isUndefined(action.sort)) {
    const sort = _.defaultTo(_.find(action.sort, 'direction'), {
      field: 'name',
      direction: 'DESC',
    });
    params = _.assign({}, params, { sort: sort });
  }

  let filters = {};
  _.forIn(action.filters, (value, key) => {
    if (!_.isNull(value)) {
      filters[key] = value;
    }
  });

  return _.assign({}, params, { filter: filters });
}


export function* loadCompaniesData(action) {
  try {
    const params = buildQueryParametersCompaniesList(action);
    const response = yield call(
      client().post,
      'consumer/companies',
      JSON.stringify(params),
    );
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (_.get(response, 'error.response.status') === 500) {
        yield put(push('/500'));
      } else {
        yield handleErrorNotification(response.error);
        yield put(loadCompaniesDataFailed());
      }
    } else {
      yield put(
        loadCompaniesDataSucceeded(
          response.data || [],
          response.totalObjects || 0,
          {
            filters: action.filters,
            sort: action.sort,
            pageNum: action.pageNum,
            pageSize: action.pageSize,
            fields: action.fields,
          },
        ),
      );
    }
  } catch (e) {
    yield put(loadCompaniesDataFailed());
  }
}

export function* exportCompanies(action) {
  const params = _.pick(buildQueryParametersCompaniesList(action.params), [
    'sort',
    'filter',
    'pageNumber',
    'pageSize',
    'fields',
  ]);

  if (!_.isEmpty(action.params.ids)) {
    params.filter.ids = action.params.ids;
  }

  const url = 'consumer/companies/reports';
  try {
    const response = yield call(client().post, url, JSON.stringify(params), {
      responseType: 'arraybuffer',
    });
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk(response.error);
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    action.cbk({ error: e });
  }
}

export function* watchLoadCompaniesData() {
  yield takeLatest(LOAD_COMPANIES_DATA_ACTION, loadCompaniesData);
}

export function* watchExportCompanies() {
  yield takeLatest(EXPORT_COMPANIES_ACTION, exportCompanies);
}

export default function* companiesSaga() {
  yield all([
    watchLoadCompaniesData(),
    watchExportCompanies(),
  ]);
}