import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from 'react-moment';
import { ColumnCellInner } from '../../../../common/styles/Common.styled';
import { ColumnHeaderDate } from '../ProductsGrid.styled';

export default class CreatedCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    return (
      <ColumnHeaderDate>
        <ColumnCellInner>
          {this.props.value && (
            <Moment format="DD.MM.YYYY">{this.props.value}</Moment>
          )}
        </ColumnCellInner>
      </ColumnHeaderDate>
    );
  }
}

CreatedCell.propTypes = {
  value: PropTypes.string,
};
