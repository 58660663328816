import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cloneDeep, find, forEach, isEmpty, map } from 'lodash';
import MuiTreeView from 'material-ui-treeview';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MuiThemeProvider,
} from '@material-ui/core';
import { CustomInput } from '../../components';
import { IconClose, IconSearch } from '../../common/icons/customIcons';
import { loadTnveds } from '../../common/catalog/Catalog.action';
import {
  FilterBtn,
  FilterBtns,
} from '../../pages/products/productGrid/filters/Filters.styled';
import { DialogTitleContent } from '../../common/styles/Common.styled';
import { DefaultProfileDialog, NewBlock, NewTree } from './TnvedDialog.styled';

class TnvedDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestion: {},
      suggestions: [],
      tnveds: cloneDeep(this.props.tnveds),
      searchValue: '',
      selected: null,
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.tnveds)) {
      this.props.loadTnvedsFetchRequested();
    }
  }

  onSearchChange = (value) => {
    this.setState({ suggestions: [] }, () => {
      const isDigits = /^\d+$/.test(value);
      if (value.length >= 3) {
        let suggestions = isDigits
          ? this.searchDigitsSuggestions(this.props.tnveds, value)
          : this.searchTextSuggestions(this.props.tnveds, value);
        this.setState({ searchValue: value, suggestions: suggestions });
      } else {
        this.setState({ searchValue: value });
      }
    });
  };

  searchDigitsSuggestions = (tnveds, value) => {
    let result = [];
    forEach(tnveds, (tnved) => {
      if (tnved.id.toString().includes(value)) {
        result.push(tnved);
      } else {
        if (tnved.sublist && tnved.sublist.length) {
          const subResult = this.searchDigitsSuggestions(tnved.sublist, value);
          if (subResult.length) {
            result = subResult;
          }
        }
      }
    });
    return result;
  };

  searchTextSuggestions = (tnveds, value) => {
    const result = [];
    forEach(tnveds, (tnved) => {
      if (tnved.text.toLowerCase().includes(value.toLowerCase())) {
        result.push(tnved);
      } else {
        if (tnved.sublist && tnved.sublist.length) {
          const subResult = this.searchTextSuggestions(tnved.sublist, value);
          if (subResult.length) {
            let foundTnved = {
              id: tnved.id,
              text: tnved.text,
              sublist: subResult,
            };
            result.push(foundTnved);
          }
        }
      }
    });
    return result;
  };

  includesValue = (tnved, value) => {
    let isIncludes = false;
    if (tnved.text.toLowerCase().includes(value.toLowerCase())) {
      isIncludes = true;
    } else {
      if (tnved.sublist.length) {
        forEach(tnved.sublist, (tnvedSub) => {
          isIncludes = this.includesValue(tnvedSub, value);
        });
      }
    }
    return isIncludes;
  };

  handleChange = (event) => {
    const suggestion = event.target.value;
    this.setState({ suggestion: suggestion });
  };

  buildTree = (suggestions) => {
    const nodes = [];
    map(suggestions, (suggestion) => {
      const sublist = this.buildTree(
        suggestion.sublist ? suggestion.sublist : [],
      );
      const node = {
        id: suggestion.id,
        text: suggestion.text,
        value: `${suggestion.id}\t${suggestion.text}`,
        nodes: sublist,
      };
      nodes.push(node);
    });
    return nodes;
  };

  onLeafSelect = (node) => {
    if (this.state.selected && this.state.selected.id === node.id) {
      this.setState({ selected: null });
    } else {
      this.setState({ selected: node });
    }
  };

  handleClick = () => {
    let tnved;
    if (this.state.selected.parent) {
      tnved = find(this.state.selected.parent.nodes, {
        id: this.state.selected.id,
      });
    } else {
      tnved = find(this.state.suggestions, { id: this.state.selected.id });
    }
    if (!!tnved) {
      const value = {
        rowId: this.props.rowId,
        text: tnved.text,
        id: tnved.id,
      };

      this.props.onSave(value);
    }

    this.setState({
      suggestions: [],
      suggestion: {},
      searchValue: '',
      selected: null,
    });
  };

  handleClose = () => {
    this.setState({
      suggestions: [],
      suggestion: {},
      searchValue: '',
      selected: null,
    });
    this.props.onClose();
  };

  render() {
    const { suggestions, searchValue } = this.state;
    let tree = [];
    if (!!suggestions && suggestions.length) {
      tree = this.buildTree(suggestions);
    }
    return (
      <MuiThemeProvider theme={DefaultProfileDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleContent>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </DialogTitleContent>
          </DialogTitle>

          <DialogContent>
            <CustomInput
              id={'search'}
              name={'search'}
              label={'Введите 4 цифры ТН ВЭД ЕАЭС или название продукции'}
              onChange={(value) => this.onSearchChange(value)}
              onUpdate={() => {}}
              value={searchValue}
              type="text"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconSearch />
                  </InputAdornment>
                ),
              }}
            />
            {!!suggestions && suggestions.length && (
              <NewBlock>
                <MuiThemeProvider theme={NewTree}>
                  <MuiTreeView
                    tree={tree}
                    onLeafClick={(node) => this.onLeafSelect(node)}
                    listItemProps={{ selected: true }}
                  />
                  <FilterBtns>
                    <Button
                      disabled={this.state.selected === null}
                      onClick={this.handleClick}>
                      Добавить ТНВЭД
                    </Button>
                  </FilterBtns>
                </MuiThemeProvider>
              </NewBlock>
            )}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

TnvedDialog.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  rowId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

TnvedDialog.defaultProps = {
  rowId: 0,
};

const mapStateToProps = (state) => ({
  tnveds: state.catalogReducer.dictionaryTnveds,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadTnvedsFetchRequested: () => loadTnveds(),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TnvedDialog);
