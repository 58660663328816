export const LOAD_GS1_INFO_ACTION = 'LOAD_GS1_INFO';
export const LOAD_GS1_INFO_SUCCEEDED_ACTION = 'LOAD_GS1_INFO_SUCCEEDED';
export const LOAD_GS1_INFO_FAILED_ACTION = 'LOAD_GS1_INFO_FAILED';

export const LOAD_GS1_DATA_ACTION = 'LOAD_GS1_DATA';
export const LOAD_GS1_DATA_SUCCEEDED_ACTION = 'LOAD_GS1_DATA_SUCCEEDED';
export const LOAD_GS1_DATA_FAILED_ACTION = 'LOAD_GS1_DATA_FAILED';

export const SAVE_GS1_APPLICANT_ACTION = 'SAVE_GS1_APPLICANT';
export const SAVE_GS1_APPLICANT_SUCCEEDED_ACTION =
  'SAVE_GS1_APPLICANT_SUCCEEDED';
export const SAVE_GS1_APPLICANT_FAILED_ACTION = 'SAVE_GS1_APPLICANT_FAILED';

export const SAVE_GS1_BANKINGDETAILS_ACTION = 'SAVE_GS1_BANKINGDETAILS';
export const SAVE_GS1_BANKINGDETAILS_SUCCEEDED_ACTION =
  'SAVE_GS1_BANKINGDETAILS_SUCCEEDED';
export const SAVE_GS1_BANKINGDETAILS_FAILED_ACTION =
  'SAVE_GS1_BANKINGDETAILS_FAILED';

export const SAVE_GS1_ADDRESSES_ACTION = 'SAVE_GS1_ADDRESSES';
export const SAVE_GS1_ADDRESSES_SUCCEEDED_ACTION =
  'SAVE_GS1_ADDRESSES_SUCCEEDED';
export const SAVE_GS1_ADDRESSES_FAILED_ACTION = 'SAVE_GS1_ADDRESSES_FAILED';

export const SAVE_GS1_COMPANYINFORMATION_ACTION = 'SAVE_GS1_COMPANYINFORMATION';
export const SAVE_GS1_COMPANYINFORMATION_SUCCEEDED_ACTION =
  'SAVE_GS1_COMPANYINFORMATION_SUCCEEDED';
export const SAVE_GS1_COMPANYINFORMATION_FAILED_ACTION =
  'SAVE_GS1_COMPANYINFORMATION_FAILED';

export const SAVE_GS1_CONTACTS_ACTION = 'SAVE_GS1_CONTACTS';
export const SAVE_GS1_CONTACTS_SUCCEEDED_ACTION = 'SAVE_GS1_CONTACTS_SUCCEEDED';
export const SAVE_GS1_CONTACTS_FAILED_ACTION = 'SAVE_GS1_CONTACTS_FAILED';

export const SAVE_GS1_ACTION = 'SAVE_GS1';
export const SAVE_GS1_SUCCEEDED_ACTION = 'SAVE_GS1_SUCCEEDED';
export const SAVE_GS1_FAILED_ACTION = 'SAVE_GS1_FAILED';

export const CLEAR_GS1_DATA_ACTION = 'CLEAR_GS1_DATA';
export const CLEAR_GS1_INFO_ACTION = 'CLEAR_GS1_INFO';

export const LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION =
  'LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION';
export const LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED =
  'LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED';

export const LOAD_COMPANY_TYPES_DICTIONARY_ACTION =
  'LOAD_COMPANY_TYPES_DICTIONARY_ACTION';
export const LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED =
  'LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED';

export const LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION =
  'LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION';
export const LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED =
  'LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED';

export const LOAD_GS1_OPF_DICTIONARY_ACTION = 'LOAD_GS1_OPF_DICTIONARY_ACTION';
export const LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED =
  'LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED';
export const LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED =
  'LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED';
