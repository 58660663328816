import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { MuiThemeProvider, TextField } from '@material-ui/core';
import {
  CertSelectAuto,
  InvalidCert,
  MenuItemDate,
  MenuItemIcon,
  MenuItemInner,
  MenuItemName,
  SelectedCert,
  SelectedCertInner,
  SelectedIcon,
  SelectedMinInner,
  SelectedMinInnerLeft,
  SelectedMinText,
  SelectedMinTitle,
  SelectedMinTitleCert,
} from './CertificateSelect.styled';
import { SelectedCertMin } from '../../common/styles/Common.styled';
import {
  IconDropDownAuto,
  IconInvalid,
  IconValid,
} from '../../common/icons/customIcons';
import { formatCerts } from './Certificate.utils';

class CertificateSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certs: [],
    };
  }

  componentDidMount() {
    this.preLoadCertificate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['certificates', 'cert', 'locale'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  componentDidUpdate = () => {
    this.preLoadCertificate();
  };

  preLoadCertificate = () => {
    if (_.isEmpty(this.state.certs) && !_.isEmpty(this.props.certificates)) {
      const certs = formatCerts(this.props.certificates);
      this.setState({ certs });
    }
  };

  render() {
    const { cert, t } = this.props;
    const certUid = _.get(cert, 'certUid', null);

    let validDate;
    if (cert.startDate && cert.expireDate) {
      const currentDate = moment();
      const startDate = moment(cert.startDate, 'DD.MM.YYYY');
      const expireDate = moment(cert.expireDate, 'DD.MM.YYYY');
      validDate = startDate <= currentDate && currentDate <= expireDate;
    }

    const messageInvalidCert = [];
    if (certUid && !cert.isValid) {
      if (!validDate) {
        messageInvalidCert.push(
          `${t('Срок действия сертификата истек')}. ${t(
            'Пожалуйста, загрузите действующий УКЭП',
          )}. `,
        );
      } else {
        if (!cert.isValid) {
          messageInvalidCert.push(
            `${t('Ошибка при использовании сертификата')}. ${t(
              'Пожалуйста, обратитесь в техническую поддержку',
            )}. `,
          );
        }
      }
    }

    return (
      <React.Fragment>
        <MuiThemeProvider theme={CertSelectAuto}>
          <Autocomplete
            options={this.state.certs}
            autoHighlight
            onChange={(event, cert) => this.props.onSelectCert(cert || {})}
            getOptionLabel={(cert) => _.replace(cert.issuedBy, /"/g, '')}
            popupIcon={<IconDropDownAuto />}
            renderOption={(cert) => (
              <MenuItemInner>
                <MenuItemIcon>
                  {cert.isValid ? <IconValid /> : <IconInvalid />}
                </MenuItemIcon>
                <span>{_.replace(cert.organization, /"/g, '')}</span>
                <MenuItemDate isValid={cert.isValid}>
                  {cert.startDate}-{cert.expireDate}
                </MenuItemDate>
                <MenuItemName>
                  {cert.fullName} {cert.post ? `• ${cert.post}` : ''}
                </MenuItemName>
              </MenuItemInner>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={_.replace(
                  _.get(
                    cert,
                    'organization',
                    t('Выберите электронную подпись'),
                  ),
                  /"/g,
                  '',
                )}
                variant="standard"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
              />
            )}
            noOptionsText={t('Нет совпадений')}
          />
        </MuiThemeProvider>

        {certUid && (
          <SelectedCert>
            <SelectedCertInner>
              <SelectedIcon>
                {cert.isValid ? <IconValid /> : <IconInvalid />}
              </SelectedIcon>

              <SelectedCertMin>
                <SelectedMinInnerLeft>
                  <SelectedMinTitleCert>
                    {t('Сертификат')}:
                  </SelectedMinTitleCert>
                  <SelectedMinText>{cert.certUid}</SelectedMinText>
                </SelectedMinInnerLeft>
                <SelectedMinInnerLeft>
                  <SelectedMinTitle>{t('Владелец')}:</SelectedMinTitle>
                  <span>{_.replace(cert.owner, /"/g, '')}</span>
                </SelectedMinInnerLeft>
                <SelectedMinInnerLeft>
                  <SelectedMinTitle>{t('Налоговый идентификатор')}:</SelectedMinTitle>
                  <span>{cert.inn}</span>
                </SelectedMinInnerLeft>
              </SelectedCertMin>

              <SelectedCertMin>
                <SelectedMinInner>
                  <SelectedMinTitle>{t('Выдан')}:</SelectedMinTitle>
                  <span>{_.replace(cert.issuedBy, /"/g, '')}</span>
                </SelectedMinInner>
                <SelectedMinInner>
                  <SelectedMinTitleCert>
                    {t('Действителен')}:{' '}
                  </SelectedMinTitleCert>
                  <span>
                    {t('c startDate до endDate', {
                      startDate: cert.startDate,
                      endDate: cert.expireDate,
                    })}
                  </span>
                </SelectedMinInner>
              </SelectedCertMin>
            </SelectedCertInner>
          </SelectedCert>
        )}

        {!_.isEmpty(messageInvalidCert) && (
          <InvalidCert>{messageInvalidCert}</InvalidCert>
        )}
      </React.Fragment>
    );
  }
}

CertificateSelect.propTypes = {
  onSelectCert: PropTypes.func.isRequired,
  certificates: PropTypes.array.isRequired,
  cert: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  locale: state.profileReducer.locale,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(CertificateSelect);
