import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import {
  TradeMarksGrid,
  SubProfiles,
  SubAccount,
  ProfileStatus,
  SiteProfile,
} from '../index';
import {
  ProductManagementCont,
  SmallTabsNew,
} from './ProfileManagement.styled';

class ProfileManagement extends React.Component {
  constructor(props) {
    super(props);
    // this.accountType = _.get(this.props, 'user.accountType');// было до 338 задачи: роль-строка, теперь массив (user.accountType - undefined в этом файле)
    this.accountTypes = _.cloneDeep(_.get(this.props, 'profile.company.roles'));

    this.isIP = _.get(this.props, 'profile.company.opf') === 'FS_IP';

    // this.disableProfileStatus = this.accountType === 'net' || this.isIP;
    this.disableProfileStatus = _.includes(this.accountTypes, 'net') || this.isIP;
    // this.disableSubAccount = _.includes(['net', 'csp'], this.accountType);
    this.disableSubAccount = _.includes(this.accountTypes, ('net' || 'csp'));
    const tabProfiles = this.props.location.pathname === '/profile/data';
    const tabProducts = this.props.location.pathname === '/profile/products';
    const tabAccounts = this.props.location.pathname === '/profile/accounts';
    this.state = {
      value: tabProfiles ? 1 : tabProducts ? 2 : tabAccounts ? 3 : _.toNumber(this.disableProfileStatus),
    };
  }

  handleChangeTab = (event, value) => this.setState({ value });

  render() {
    const { loader, t, userInfo } = this.props;
    const fnUp = _.flow([t, _.upperCase]);

    const values = {
      0: <ProfileStatus />,
      1: <SubProfiles />,
      2: <TradeMarksGrid />,
      3: <SubAccount />,
      4: <SiteProfile />,
    };

    return (
      <ProductManagementCont>
        <MuiThemeProvider theme={SmallTabsNew}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            variant="fullWidth">
            {!this.disableProfileStatus && (
              <Tab style={{ height: 50, padding: 0 }} disabled={loader} value={0} label={<Link style={{ color: '#63666A', textDecoration: 'none', padding: '30px 20px', width: '100%' }} to="/profile">{fnUp('Статус профиля')}</Link>} />
            )}


            <Tab
              style={{ height: 50, padding: 0 }}
              disabled={loader || !userInfo.isELK}
              value={1}
              label={<Link style={{ color: '#63666A', textDecoration: 'none', padding: '30px 20px', width: '100%' }} to="/profile/data">{fnUp('Доверенные пользователи')}</Link>}
            />

            <Tab style={{ height: 50, padding: 0 }} disabled={loader} value={2} label={<Link style={{ color: '#63666A', textDecoration: 'none', padding: '30px 20px', width: '100%' }} to="/profile/products">{fnUp('Товарные знаки')}</Link>} />

            {!this.disableSubAccount && (
              <Tab style={{ height: 50, padding: 0 }} disabled={loader} value={3} label={<Link style={{ color: '#63666A', textDecoration: 'none', padding: '30px 20px', width: '100%' }} to="/profile/accounts">{fnUp('Субаккаунты')}</Link>} />
            )}
            <Tab
              style={{ height: 50, padding: 0 }}
              disabled={loader}
              value={4}
              label={<Link style={{ color: '#63666A', textDecoration: 'none', padding: '30px 20px', width: '100%' }} to="/profile">{fnUp('Профиль на сайте')}</Link>}
            />
          </Tabs>
        </MuiThemeProvider>
        {values[this.state.value]}
      </ProductManagementCont>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.gs1Reducer.loader,
  profile: state.profileReducer.info,
  userInfo: state.profileReducer.info.user,
});

ProfileManagement.propTypes = {
  loader: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps),
)(ProfileManagement);
