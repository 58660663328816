import { isEmpty, isPlainObject, isString, map, omit, get, } from 'lodash';
import { push } from 'connected-react-router';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  loginFailed,
  loginSucceesed,
  logoutSuccess,
  remindPasswordFailed,
  remindPasswordSucceesed,
  activeSessionOpenModal,
  activeSessionCloseModal,
  activeElkSessionOpenModal,
  activeElkSessionCloseModal,
} from './Authentification.actions';
import { resetStateProducs } from '../../products/ducks/Products.actions';
import { clearGlnDictionary } from '../../profile/ducks/ProfileStatus/ProfileStatus.actions';
import { clearGS1Info } from '../../profile/ducks/GS1/GS1.actions';
import { client, deleteAllCookies, setCookie } from '../../../api/Client';
import i18n from '../../../i18n';
import {
  AUTHENTICATION_SESSION_ACTIONS,
  LOGIN_ACTION,
  LOGOUT_ACTION,
  REMIND_PASSWORD_ACTION,
} from './AuthentificationActionTypes.contants';
import { throwNotification } from '../../../common/structure';
import { handleErrorNotification } from '../../ducks/HandleErrors';
import { handlerCryptoMessage } from '../../../common/utils/cryptoPro';
import { isLocalhost } from '../../../common/utils/utils';

export function* login(action) {
  try {
    let token;
    let data = action.data;
    let body = data.closeOpenSessions
      ? {
        email: data.email,
        password: data.password,
        accountId: data.accountId,
        closeOpenSessions: data.closeOpenSessions,
      }
      : {
        email: data.email,
        password: data.password,
        accountId: data.accountId,
      }
    /**
     * TODO:
     * Удалить, если ничего не упадёт от комментирования.
     */
    // if (data.cert) {
    //     const dataForSign = yield call(client().get, "/signed-data");
    //     if (isString(dataForSign)) {
    //         yield put(push('/500'));
    //         return;
    //     }
    //     if (dataForSign.error) {
    //         yield handleErrorNotification(dataForSign.error);
    //         yield put(loginFailed());
    //         return;
    //     }

    //     const signature = isKZ
    //         ? mock.signature
    //         : yield call(signData, data.cert.certificate, dataForSign.data);

    //     token = yield call(client().post, "/certlogin", {
    //         signature: signature,
    //         fingerprint: data.cert.fingerprint,
    //         inn: data.cert.inn
    //     });

    // } else {
    //     token = yield call(client().post, "/userlogin", JSON.stringify({
    //         email: data.email,
    //         password: data.password,
    //         accountId: data.accountId
    //     }));
    // }

    token = yield call(
      client().post,
      '/userlogin',
      JSON.stringify(body),
    );

    if (!!token.error) {
      if (get(token, 'error.response.status') === 401  //449
        && token.error.response.data.sessionIsAlreadyOpen) {
        yield put(activeSessionOpenModal(token.error.response.data.errors[0] || ''));
        yield put(loginFailed());
        return;
      } else {
        yield handleErrorNotification(token.error);
        yield put(loginFailed());
      }
    } else {
      if (!isPlainObject(token)) {
        yield put(loginFailed());
        yield put(push('/500'));
        return;
      }

      const user = omit(token, ['sessionName', 'sessionId']);
      if (isLocalhost && !isEmpty(token)) {
        setCookie(token.sessionName, token.sessionId, true);
      }

      setCookie('userInfo', JSON.stringify(user), true);
      setCookie('isAuth', true, true);
      yield put(loginSucceesed(user));
      if (data.closeOpenSessions) {
        yield put(activeSessionCloseModal()); //449
      }
      yield put(push('/products'));
    }
  } catch (error) {
    const text = i18n.t('Неизвестная ошибка авторизации!');
    yield put(throwNotification(handlerCryptoMessage(error, text), 'error'));
    yield put(loginFailed());
  }
}

export function* logout() {
  yield call(client().get, 'logout');
  deleteAllCookies();
  yield put(push('/login'));
  yield put(resetStateProducs());
  yield put(clearGlnDictionary());
  yield put(clearGS1Info());
  yield put(logoutSuccess());
}

export function* remindPassword(action) {
  try {
    const response = yield call(client().post, 'restore-password', action.data);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (!!response.error) {
      if (!isEmpty(response.error.response.data.errors)) {
        yield all(
          map(response.error.response.data.errors, (i) =>
            put(throwNotification(i, 'error', 6000)),
          ),
        );
        yield put(remindPasswordFailed(response.error.response.data.errors));
      }
    } else {
      yield put(
        throwNotification(
          i18n.t(
            'На указанный Email отправлена ссылка для восстановления пароля',
          ),
          'error',
        ),
      );
      yield put(remindPasswordSucceesed());
    }
  } catch (e) {
    yield put(remindPasswordFailed(e));
  }
}

export function* authenticationSession(action) {
  try {
    const response = yield call(
      client().post,
      'session',
      JSON.stringify(action.params),
    );
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (get(response, 'error.response.status') === 401  //449
        && response.error.response.data.sessionIsAlreadyOpen) {
        yield put(activeElkSessionOpenModal(response.error.response.data.errors[0] || ''));
        yield put(push('/login'));
      } else {
        yield handleErrorNotification(response.error);
        yield put(push('/login'));
      }
    }

    else {
      const user = omit(response, ['sessionName', 'sessionId']);
      if (isLocalhost) {
        setCookie(response.sessionName, response.sessionId, true);
      }
      setCookie('userInfo', JSON.stringify(user), true);
      setCookie('isAuth', true, true);
      yield put(loginSucceesed(user));
      if (action.params.closeOpenSessions) {
        yield put(activeElkSessionCloseModal()); //449
      }
      yield put(push(action.pathname));
    }
  } catch (e) {
    yield put(push('/login'));
  }
}

export function* watchRemindPassword() {
  yield takeEvery(REMIND_PASSWORD_ACTION, remindPassword);
}

export function* watchLogout() {
  yield takeEvery(LOGOUT_ACTION, logout);
}

export function* watchLogin() {
  yield takeLatest(LOGIN_ACTION, login);
}

export function* watchAuthenticationSession() {
  yield takeLatest(AUTHENTICATION_SESSION_ACTIONS, authenticationSession);
}

export default function* authentificationSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchRemindPassword(),
    watchAuthenticationSession(),
  ]);
}
