import * as React from "react";
import * as PropTypes from "prop-types";
import moment from "moment-timezone";
import { series } from "async";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { throwNotification } from "../../../../common/structure";
import {
    config,
    // isKZ,
    selectLang
} from "../../../../config";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    MuiThemeProvider,
    Select
} from "@material-ui/core";

import {
    Autocomplete,
    CertificateSelectModal,
    ConfirmDialog,
    CustomInput,
    FloatingButton,
    FloatingButtonView,
    PasswordInput,
    PhoneInput,
    Preloader
} from "../../../../components";

import { ProfileDivider, ProfileTabCont } from "../../Profile.styled";
import {
    CheckBoxDefault,
    InputContainerMail,
    InputIcon,
    ProfileAutocomplete,
    StickyBtns
} from "../../../../common/styles/Common.styled";
import {
    ConfirmEmailButton,
    PasswordErrorsWarning,
    ProfileCenter,
    ProfileH3,
    ProfileLeft,
    ProfileMain,
    ProfileRight
} from "./MemberDetails.styled";
import { validateEmail, validatePhone } from "../../../../common/utils/Validation";
import {
    IconCheck,
    IconFloatSave,
    IconLogoDropDown,
    IconValid
} from "../../../../common/icons/customIcons";

import CertificateView from "./certificateView";
import ConfirmEmailModal from "./modal/ConfirmEmailModal";
import * as selectors from "../../ducks/Profile/Profile.selectors";
import { loadCertificates, loadProfileInfo, saveInfo, savePassword } from "../../ducks/Profile/Profile.actions";
import { loadDictionaryOkved, loadDictionaryOpf } from "../../../../common/catalog/Catalog.action";
import { fullTypes } from "../../../../common/constants/Constants";
import { client } from "../../../../api/Client";

function renderSuggestion(suggestion, { query, isHighlighted }) {
    return (
        <MenuItem disableGutters={true} selected={isHighlighted} component="div">
            {suggestion.text}
        </MenuItem>
    );
}

const getDefaultTo = (obj, path) => _.defaultTo(_.get(obj, path, ''), '');

const validatePassword = (password) => {
    const type = {
        minimum8Chars: /^.{8,}$/,
        containsNumbers: /^.*[0-9]+.*$/,
        containsUpperCaseLetters: /^.*[A-Z]+.*$/,
        containsLowerCaseLetters: /^.*[a-z]+.*$/,
        withoutSpecialChars: /[!@#$%^&*]/
    };
    const arr = _.reduce(type, (result, value) => {
        result.push(value.test(password));
        return result;
    }, []);
    return _.every(arr);
};

class MemberDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            info: _.cloneDeep(this.props.info),
            selectCertModal: false,
            showChangeEmailModal: false,
            confirmEmailModal: false,
            certificate: {},
            password: {
                password: '',
                confirmPassword: ''
            },
            suggestions: this.props.dictionaryOkved,
            suggestionsLastValue: '',
            showFloatingButton: true,
            preloader: true,
        };
        this.onScrollThrottle = _.throttle(this.onScroll, config.throttleScroll);

        const tz = moment.tz.guess();
        moment.locale(selectLang(config.lang));
        moment.tz.setDefault(tz);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScrollThrottle);
        series([
            (cbk) => _.isEmpty(this.props.dictionaryOkved) ? this.props.onLoadDictionaryOkved(null, cbk) : cbk(null),
            (cbk) => _.isEmpty(this.props.opfDictionary) ? this.props.loadOpfDictionary(cbk) : cbk(null),
            (cbk) => this.props.onLoadProfileInfo(cbk),
        ]).finally(() => this.setState({ preloader: false }));
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.info, this.props.info)) {
            this.setState({ info: _.cloneDeep(this.props.info) });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollThrottle);
    }

    onScroll = () => {
        const pageY = window.pageYOffset || document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;
        const offsetHeight = document.documentElement.offsetHeight;
        if ((offsetHeight - clientHeight - 250) > pageY) {
            if (!this.state.showFloatingButton) {
                this.setState({ showFloatingButton: true })
            }
        } else {
            if (this.state.showFloatingButton) {
                this.setState({ showFloatingButton: false })
            }
        }
    };

    onNewCertClick = () => {
        this.setState({ selectCertModal: !this.state.selectCertModal })
    };

    onSelectCertificate = (value) => {
        this.setState({ certificate: value })
    };

    handleChange = (name, value) => {
        switch (name) {
            case 'password': {
                this.setState({ password: { ...this.state.password, password: value } });
                break;
            }
            case 'confirmPassword': {
                this.setState({ password: { ...this.state.password, confirmPassword: value } });
                break;
            }
            case 'certificate': {
                this.setState({ selectCertModal: false });
                const certificate = {
                    certificate: value.certificate,
                    certUid: value.certUid,
                    fingerprint: value.fingerprint,
                    userFullName: `${value.lastName} ${value.firstName}`,
                    owner: value.owner,
                    issuedBy: value.issuedBy,
                    companyName: value.organization,
                    inn: value.inn,
                    userPosition: '',
                    validDateFrom: moment(value.startDate, "DD-MM-YYYY").toISOString(true),
                    validDateTo: moment(value.expireDate, "DD-MM-YYYY").toISOString(true),
                    isValid: value.isValid
                };
                this.handleChangeInfo('certificate', name, certificate);
                break;
            }
            case 'isMarked': {
                this.handleChangeInfo('company', 'isMarked', !this.state.info.company.isMarked);
                break;
            }
            case 'opf': {
                this.handleChangeInfo('company', 'opf', value);
                break;
            }
            case 'isTechCardSupport': {
                this.handleChangeInfo('user', 'isTechCardSupport', !this.state.info.user.isTechCardSupport);
                break;
            }
            default:
                this.handleChangeInfo('user', name, value);
                break;
        }
    };

    handleChangeInfo(section, name, value) {
        let info = _.cloneDeep(this.state.info);
        if (name === 'certificate') {
            info[section] = value;
        } else {
            info[section][name] = value;
        }
        this.setState({ info: info }, () => {
            if (name === 'certificate') {
                this.onSaveInfoButton();
            }
        });
    }

    onSavePassword = () => {
        if (!validatePassword(this.state.password.password)) return;
        this.props.onSavePassword(this.state.password.password);
        this.setState({ password: { password: '', confirmPassword: '' } });
    };

    onSaveInfoButton = () => {
        this.props.onSaveInfo(this.props.info, this.state.info, (errors) => {
            if (errors) {
                if (_.get(errors, 'errors.type') === 'certificate') {
                    this.setState(prevState => ({ info: { ...prevState.info, certificate: this.props.info.certificate } }));
                }
            } else {
                if (!_.isEqual(this.state.info.user.email, this.props.info.user.email)) {
                    this.setState({ showChangeEmailModal: true });
                }
            }
        });
    };

    getSuggestionValue = (suggestion) => {
        let info = _.cloneDeep(this.state.info);
        info['company']['okved2'] = suggestion;
        this.setState({ info: info });
        return `${suggestion.text}`;
    };

    handleSuggestionsFetchRequested = (value) => {
        const inputValue = _.toLower(_.deburr(_.trim(value)));
        if (!inputValue || this.state.suggestionsLastValue === inputValue) return;

        this.props.onLoadDictionaryOkved(inputValue, (error, suggestions) => {
            this.setState({
                suggestionsLastValue: inputValue,
                suggestions: suggestions,
            });
        })
    };

    handleSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    showCertificateUpdate = () => {
        const certificate = this.props.info.certificate;
        return !(!this.props.userInfo.isMainAccountUser || (!!certificate && certificate.isValid))
    };

    confirmEmailModal = (e, reload) => {
        if (reload) {
            this.props.onLoadProfileInfo();
        }
        this.setState(prevState => ({ confirmEmailModal: !prevState.confirmEmailModal }))
    };

    showPasswordChange = () => {
        const { t } = this.props;
        const { password, confirmPassword } = this.state.password;
        const isELK = this.props.info.user.isELK
        const passwordValid = validatePassword(password);
        const labelPasswordError = t('Пароль должен содержать не менее 8 символов, иметь маленькие и большие буквы, цифры и хотя бы один из спецсимволов (!@#$%^&*)');
        const disabledChangePassword = (!((password && confirmPassword) && password === confirmPassword)) || !passwordValid;

        if (isELK === false) {
            return (
                <React.Fragment>
                    <ProfileDivider />
                    <ProfileH3>{t('Смена пароля')}</ProfileH3>
                    <PasswordInput
                        id="password"
                        name="password"
                        placeholder={t('Введите новый пароль')}
                        label={t('Введите новый пароль')}
                        onChange={(value) => this.handleChange('password', value)}
                        value={password}
                        shrink={true}
                        autoComplete="off"
                    />
                    {(password && !passwordValid) &&
                        <PasswordErrorsWarning>{labelPasswordError}</PasswordErrorsWarning>
                    }

                    <PasswordInput
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder={t('Повторите пароль')}
                        label={!!password && password !== confirmPassword
                            ? t('Пароли не совпадают')
                            : t('Повторите пароль')
                        }
                        value={_.defaultTo(confirmPassword, '')}
                        onChange={(value) => this.handleChange('confirmPassword', value)}
                        error={!!(password && confirmPassword) && (password !== confirmPassword)}
                        shrink={true}
                        autoComplete="off"
                    />
                    <ProfileDivider />
                    <Button
                        disabled={disabledChangePassword}
                        onClick={this.onSavePassword}
                    >
                        {t('Изменить пароль')}
                    </Button>
                </React.Fragment>
            )
        }
        else return null
    }

    onGetApiKey = () => {
        //this.props.onGetApiKey();
        //const email = this.props.info.user.email
        //const message = 'APIKEY направлен на Ваш электронный адрес'
        //this.props.throwNotification(message, 'success');
        return client().instance.post(`/profile/send_api_key`);

    }

    sendApiKey = () => {
        const message = 'APIKEY направлен на Ваш электронный адрес'
        this.onGetApiKey()
            .then(() => {
                this.props.throwNotification(message, 'success');
            })
            .catch((errors) => {
                const err = _.get(errors, 'response.data.errors');
                _.forEach(err, (i) => this.props.throwError(i));
            });
    }

    getRoles = () => {
        const roles = [];
        this.state.info.company.roles.forEach((item) => {
            const role = _.find(fullTypes, { value: item });
            if (role) {
                roles.push(getDefaultTo(role, 'title'))
            }
        });
        return _.uniq(roles).join(', ');
    }

    render() {
        const { validationErrors, info, t } = this.props;
        // const fnUp = _.flow([t, _.upperCase]);
        const { certificate } = info;
        const { company, user } = this.state.info;
        // const { password, confirmPassword } = this.state.password;
        const isELK = this.props.info.user.isELK

        const isNoChange = _.isEqual(this.props.info, this.state.info);
        const isElkUser = _.get(this.props, 'userInfo.isElkUser');
        const opfProps = _.find(this.props.opfDictionary, { id: getDefaultTo(this.props, 'info.company.opf') });
        const valueOpf = getDefaultTo(company, 'opf');
        const valueOkved2Code = _.get(company, 'okved2.code');

        const valueEmail = getDefaultTo(user, 'email');
        const emailValid = isElkUser || validateEmail(valueEmail);
        const validationErrorEmail = _.has(validationErrors, 'email');

        const valuePhone = getDefaultTo(user, 'phone');
        const phoneValid = validatePhone(valuePhone);

        // const passwordValid = validatePassword(password);
        // const labelPasswordError = t('Пароль должен содержать не менее 8 символов, иметь маленькие и большие буквы, цифры и хотя бы один из спецсимволов (!@#$%^&*)');
        // const disabledChangePassword = (!((password && confirmPassword) && password === confirmPassword)) || !passwordValid;

        if (this.state.preloader) {
            return <Preloader isOpen text='Загрузка данных профиля' />;
        }

        return (
            <div>
                <ProfileTabCont>
                    <ProfileMain>
                        <ProfileLeft />
                        <ProfileCenter>
                            <ProfileH3>{t('Общие данные')}</ProfileH3>
                            <CustomInput
                                disabled
                                id="name"
                                name="name"
                                label={t('Полное наименование организации')}
                                value={getDefaultTo(company, 'name')}
                                type="text"
                            />
                            {/* <CustomInput
                                disabled
                                id="nameShort"
                                name="nameShort"
                                label={t('Краткое наименование')}
                                value={getDefaultTo(company, 'nameShort')}
                                type="text"
                            /> */}
                            <FormControl fullWidth={true}>
                                <InputLabel disabled={!!opfProps} htmlFor="opf-select">
                                    {t('Организационно-правовая форма')}
                                </InputLabel>
                                <Select
                                    required
                                    disabled={!!opfProps}
                                    style={!!opfProps ? { borderLeft: "1px solid #D9D9D9" } : {}}
                                    value={valueOpf}
                                    valid={_.toString(!!valueOpf)}
                                    inputProps={{ id: 'opf-select' }}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: { vertical: "bottom", horizontal: "left" }
                                    }}
                                    IconComponent={IconLogoDropDown}
                                    onChange={(event) => this.handleChange('opf', event.target.value)}
                                >
                                    {_.map(this.props.opfDictionary, opf =>
                                        <MenuItem key={opf.id} value={opf.id}>
                                            {opf.text}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <CustomInput
                                disabled
                                id="accountType"
                                name="accountType"
                                label={t('Тип профиля')}
                                // value={t(getDefaultTo(_.find(fullTypes, { value: company.role }), 'title'))}
                                value={this.getRoles()}
                                type="text"
                            />
                            <ProfileDivider />
                            {/* {this.props.userInfo.accountType !== 'csp' && <MuiThemeProvider theme={CheckBoxDefault}> */}
                            {/* {!_.includes(this.props.userInfo.accountTypes, 'csp') && <MuiThemeProvider theme={CheckBoxDefault}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={company.isMarked}
                                            onChange={() => this.handleChange('isMarked')}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={t('Я участник оборота товаров, маркированных средствами идентификации')}
                                />
                            </MuiThemeProvider>} */}
                            {/* {!_.includes(this.props.userInfo.accountTypes, 'csp') && <MuiThemeProvider theme={CheckBoxDefault}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user.isTechCardSupport}
                                            onChange={() => this.handleChange('isTechCardSupport')}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={t('Включить функционал маркировки остатков и перемаркировки')}
                                />
                            </MuiThemeProvider>} */}

                            {/* <ProfileDivider /> */}
                            <ProfileH3>{t('Налоговые данные')}</ProfileH3>
                            <CustomInput
                                disabled
                                id="inn"
                                name="inn"
                                label={t('Налоговый идентификатор')}
                                value={getDefaultTo(company, 'inn')}
                                maxLength={12}
                                type="text"
                            />

                            {/* for using in future */}
                            {/* {!isKZ &&
                                <CustomInput
                                    disabled
                                    id="ogrn"
                                    name="ogrn"
                                    label={t('ОГРН')}
                                    value={getDefaultTo(company, 'ogrn')}
                                    type="text"
                                />
                            } */}
                            <MuiThemeProvider theme={ProfileAutocomplete}>
                                <FormControl fullWidth={true}>
                                    <Autocomplete
                                        name="okved2"
                                        suggestions={this.state.suggestions.slice(0, 200)}
                                        placeholder={t('Основной вид экономической деятельности')}
                                        handleSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                        handleSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={this.getSuggestionValue}
                                        initValue={!!valueOkved2Code
                                            ? `${valueOkved2Code} ${company.okved2.description}`
                                            : ''
                                        }
                                        forceSetValue={!!valueOkved2Code}
                                    />
                                </FormControl>
                            </MuiThemeProvider>

                            {/* for using in future */}
                            {/* {!isKZ &&
                                <CustomInput
                                    disabled
                                    id="kpp"
                                    name="kpp"
                                    label={t('КПП головного филиала')}
                                    value={getDefaultTo(company, 'kpp')}
                                    type="text"
                                />
                            } */}
                            <CustomInput
                                disabled
                                id="address"
                                name="address"
                                label={t('Юридический адрес')}
                                value={getDefaultTo(company, 'address')}
                                type="text"
                            />
                            <ProfileDivider />
                            <Button
                                onClick={this.onSaveInfoButton}
                                disabled={isNoChange || !phoneValid || !emailValid}
                            >
                                {t('Сохранить изменения')}
                            </Button>
                        </ProfileCenter>

                        <ProfileRight certificate={!_.isNil(certificate)} >

                            {!_.isNil(certificate) && <CertificateView cert={certificate} /> }
                            {!_.isNil(certificate) && <ProfileDivider />}
                            <ProfileH3>{t('Контактные данные')}</ProfileH3>
                            <CustomInput
                                disabled
                                id="fullname"
                                name="fullname"
                                label={t('ФИО')}
                                value={_.defaultTo(`${user.lastName} ${user.firstName} ${user.middleName}`, '')}
                                type="text"
                            />
                            <InputContainerMail>
                                <CustomInput
                                    required
                                    disabled={isElkUser}
                                    id="email"
                                    name="email"
                                    placeholder="E-mail"
                                    label={
                                        !emailValid
                                            ? t('Введите корректный E-mail')
                                            : validationErrorEmail ? validationErrors.email : "E-mail"
                                    }
                                    onChange={(value) => this.handleChange('email', value)}
                                    onUpdate={() => { }}
                                    shrink={true}
                                    value={valueEmail}
                                    valid={emailValid && !validationErrorEmail}
                                    error={!emailValid || validationErrorEmail}
                                    type="email"
                                    autoComplete="email"
                                />
                                {_.get(this.props, 'info.user.isVerifiedEmail')
                                    ? _.isEqual(this.props.info.user.email, this.state.info.user.email) &&
                                    <InputIcon><IconValid /></InputIcon>
                                    : (!isElkUser && !!this.props.info.user.email) && (
                                        <MuiThemeProvider theme={ConfirmEmailButton}>
                                            <Button onClick={this.confirmEmailModal}>{t('Подтвердить')}</Button>
                                        </MuiThemeProvider>
                                    )
                                }
                            </InputContainerMail>

                            <PhoneInput
                                phone={valuePhone}
                                placeholder={phoneValid ? t('Телефон') : t('Введите корректный номер телефона')}
                                onChange={(value) => this.handleChange('phone', value)}
                                onUpdate={() => { }}
                                phoneValid={phoneValid}
                                autoComplete="off"
                            />

                            <CustomInput
                                disabled
                                id="created"
                                name="created"
                                label={t('Дата регистрации')}
                                value={_.defaultTo(moment(user.created).format('DD-MM-YYYY'), '')}
                                type="text"
                            />

                            <ProfileDivider />
                            <MuiThemeProvider theme={CheckBoxDefault}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user.isEmailNotifications}
                                            onChange={() => this.handleChange('isEmailNotifications', !user.isEmailNotifications)}
                                            checkedIcon={<IconCheck />}
                                        />
                                    }
                                    label={t(`Осуществлять информирование меня обо всех изменениях в личном кабинете, путём направления ежедневного отчёта на мой электронный адрес`)}
                                />
                            </MuiThemeProvider>

                            {this.showPasswordChange()}
                            <ProfileDivider />
                            <Button
                                disabled={!isELK}
                                onClick={this.sendApiKey}
                            >
                                {t('Предоставить Apikey')}
                            </Button>
                        </ProfileRight>
                    </ProfileMain>
                </ProfileTabCont>

                {this.state.showFloatingButton &&
                    <FloatingButtonView>
                        <StickyBtns>
                            <FloatingButton
                                text={t('Сохранить изменения')}
                                onClick={this.onSaveInfoButton}
                                disabled={isNoChange || !phoneValid || !emailValid}
                                color={"primary"}
                                icon={<IconFloatSave style={{ marginRight: "5px" }} />}
                            />
                        </StickyBtns>
                    </FloatingButtonView>
                }

                {this.state.selectCertModal &&
                    <CertificateSelectModal
                        acceptBtnText={t('Загрузить новый сертификат')}
                        onSelectCert={(cert) => this.onSelectCertificate(cert)}
                        onSubmit={(certificate) => this.handleChange('certificate', certificate)}
                        onGetAllCerts={this.props.onLoadCertificates}
                        cert={this.state.certificate}
                        certificates={this.props.certificates}
                        onCancel={this.onNewCertClick}
                    />
                }

                {this.state.showChangeEmailModal &&
                    <ConfirmDialog
                        onCancel={() => this.setState({ showChangeEmailModal: false })}
                        message="Для подтверждения смены почты пройдите по ссылке, высланной на указанный вами e-mail"
                    />
                }

                {this.state.confirmEmailModal &&
                    <ConfirmEmailModal
                        email={valueEmail}
                        throwError={this.props.throwError}
                        onCancel={this.confirmEmailModal}
                    />
                }
            </div>
        );
    }
}

MemberDetails.propTypes = {
    throwError: PropTypes.func.isRequired,
    onLoadProfileInfo: PropTypes.func.isRequired,
    onLoadCertificates: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    onSaveInfo: PropTypes.func.isRequired,
    onLoadDictionaryOkved: PropTypes.func.isRequired,
    loadOpfDictionary: PropTypes.func.isRequired,
    info: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    validationErrors: PropTypes.object.isRequired,
    certificates: PropTypes.array.isRequired,
    dictionaryOkved: PropTypes.array.isRequired,
    opfDictionary: PropTypes.array.isRequired,
    userInfo: PropTypes.object.isRequired,
    throwNotification: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    info: selectors.info(state),
    errors: selectors.errors(state),
    certificates: selectors.certificates(state),
    dictionaryOkved: selectors.dictionaryOkved(state),
    opfDictionary: selectors.dictionaryOpf(state),
    validationErrors: selectors.validationErrors(state),
    userInfo: state.authentificationReducer.user,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        onLoadProfileInfo: (cbk) => loadProfileInfo(cbk),
        onLoadCertificates: () => loadCertificates(),
        onSavePassword: (password) => savePassword(password),
        onSaveInfo: (oldInfo, newInfo, cbk) => saveInfo(oldInfo, newInfo, cbk),
        onLoadDictionaryOkved: (searchText, cbk) => loadDictionaryOkved(searchText, cbk),
        loadOpfDictionary: (cbk) => loadDictionaryOpf(cbk),
        throwError: (message) => throwNotification(message, 'error'),
        throwNotification: (message, type) => throwNotification(message, type),
    }, dispatch);
};


export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(MemberDetails);
