import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import { CancelToken, client } from '../../api/Client';
import { config } from '../../config';
import {
  FormControl,
  MenuItem,
  Paper,
  Popper,
  TextField,
  withStyles,
} from '@material-ui/core';
import { RemainderModal } from '../index';
import { throwNotification } from '../../common/structure';
import { findStatusObj, gtinDisplay } from '../../common/utils/utils';
import { SugCat, SugIcon, SugName } from './HeaderAutocomplete.styled';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <FormControl fullWidth={true}>
      <TextField
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          disableUnderline: true,
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    </FormControl>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

let cancelGetSuggestions;

async function getSuggestions(payload) {
  if (_.isFunction(cancelGetSuggestions)) {
    cancelGetSuggestions('canceled');
  }
  const result = await client().instance.get(
    `/goods/autocomplete?search=${encodeURIComponent(payload)}&count=20`,
    {
      cancelToken: new CancelToken((c) => {
        cancelGetSuggestions = c;
      }),
    },
  );
  cancelGetSuggestions = undefined;
  return result;
}

class HeaderAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: this.props.pathname,
      popper: '',
      suggestions: [],
      isOpenRemainderModal: null,
    };
    this.fetchRequestedDebounce = _.debounce(
      this.suggestionsFetchRequested,
      500,
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.state, nextState) ||
      !_.isEqual(this.props.locale, nextProps.locale)
    );
  }

  componentWillUnmount() {
    this.fetchRequestedDebounce.cancel();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.pathname === config.urls.products &&
      nextProps.pathname !== prevState.pathname &&
      prevState.popper !== ''
    ) {
      return {
        popper: '',
        pathname: nextProps.pathname,
      };
    }
    if (nextProps.pathname !== prevState.pathname) {
      return {
        pathname: nextProps.pathname,
      };
    }
    return null;
  }

  handleChange = (event, { newValue }) => {
    this.setState({ popper: newValue });
  };

  handleSuggestionsFetchRequested = ({ value, reason }) => {
    if (value.length >= 3 && reason === 'input-changed') {
      this.fetchRequestedDebounce(value);
    }
  };

  suggestionsFetchRequested = (value) => {
    const throwError = _.flow([this.props.t, this.props.throwError]);
    getSuggestions(value)
      .then((response) => {
        const suggestions = _.get(response, 'data', []);
        if (_.isEmpty(suggestions)) throwError('Товар не найден');
        this.setState({ suggestions });
      })
      .catch((e) => {
        if (_.get(e, 'message') === 'canceled') {
          this.setState({ suggestions: [] });
        } else {
          const err = _.get(e, 'response.data.errors');
          if (_.isArray(err) && !_.isEmpty(err)) {
            _.forEach(err, (i) => throwError(i));
          } else {
            throwError('Ошибка получения данных поиска!');
          }
        }
      });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const status = findStatusObj(suggestion.status);
    return (
      <MenuItem disableGutters={true} selected={isHighlighted} component="div">
        <SugIcon style={{ color: status.style.color }}>
          {' '}
          {status.style.icon}
        </SugIcon>
        <SugName>{suggestion.name}</SugName>
        <div>{gtinDisplay(suggestion.gtin)}</div>
        <SugCat>{suggestion.category}</SugCat>
      </MenuItem>
    );
  };

  getSuggestionValue = (suggestion) => {
    if (!!suggestion.isRemainder) {
      this.onViewRemainder(suggestion.id);
    } else {
      this.props.goToPage(
        `${config.urls.product}/${suggestion.entityType}/${suggestion.id}`,
      );
    }
    return suggestion.name;
  };

  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  onViewRemainder = (id) => this.setState({ isOpenRemainderModal: id });

  render() {
    const { classes, t } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      renderSuggestion: this.renderSuggestion,
      getSuggestionValue: this.getSuggestionValue,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: this.props.placeholder || t('Поиск'),
            value: this.state.popper,
            onChange: this.handleChange,
            inputRef: (node) => {
              this.popperNode = node;
            },
            InputLabelProps: { shrink: true },
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Popper
              style={{ zIndex: '9999' }}
              anchorEl={this.popperNode}
              open={Boolean(options.children)}>
              <Paper
                square
                {...options.containerProps}
                style={{
                  width: this.popperNode ? this.popperNode.clientWidth : null,
                }}>
                {options.children}
              </Paper>
            </Popper>
          )}
        />

        {!!this.state.isOpenRemainderModal && (
          <RemainderModal
            goodId={this.state.isOpenRemainderModal}
            handleClose={this.onViewRemainder}
          />
        )}
      </div>
    );
  }
}

HeaderAutocomplete.propTypes = {
  throwError: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwError: (message) => throwNotification(message, 'error'),
      goToPage: (page) => push(page),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderAutocomplete);
