import * as React from "react";
import * as PropTypes from "prop-types";
import cx from 'classnames';
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { config } from "../../../config";
import { withRouter } from 'react-router-dom';
import {
    AppBar,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core";
import { deleteNotification, Header, throwNotification } from "../index";
import { setLocale } from "../../../pages/profile/ducks/Profile/Profile.actions";
import {
    BluLogoMin,
    SidebarHead,
    SidebarLink,
    SidebarLinkIcon,
    SidebarLinkTitle,
    SidebarMenuBtn,
    SidebarMenuButton,
    SidebarLinkProfile
} from "./Sidebar.styled";
import {
    LogoKzSide,
    LogoKzSideWide,
    IconDashboardActive,
    IconDashboard,
    IconProfileSystem,
    IconProfileSystemActive,
    IconProduct,
    IconProductActive,
    IconDirectory,
    IconDirectoryActive,
    IconCompanies,
    IconCompaniesActive,
    IconHamburgerMenu,
} from "../../icons/customIcons";
import { loadProfileStatus } from "../../../pages/profile/ducks/ProfileStatus/ProfileStatus.actions";


const drawerWidth = 200;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 99,
        boxShadow: "none",
        background: "none",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',

    },
    drawerOpen: {
        width: "200px",
        boxShadow: "2px 0px 15px rgba(0, 0, 0, 0.05)",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#0187B0'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: "2px 0px 15px rgba(0, 0, 0, 0.05)",
        border: "none",
        overflowX: 'hidden',
        width: 70,
        [theme.breakpoints.up('sm')]: {
            width: 70,
        },
        backgroundColor: '#0187B0'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: 40,
        [theme.breakpoints.up('sm')]: {
            minHeight: 40,
        },
        ...theme.mixins.toolbar,
        '@media (max-width: 1919px)': {
            minHeight: '70px',
        },
        '@media (min-width: 1920px)': {
            minHeight: '70px',
        }
    },
    content: {
        height: '100vh',
        overflowY: 'hidden',
        flexGrow: 1,
        padding: 0, // theme.spacing.unit,
        width: "calc(100% - 80px)"
    },
    '@media (min-width: 1920px)': {
        drawerClose: {
            width: 70,
        }
    }

});


// const ComponentItems = ({ Item, t, user, handleCloseMenuAddProduct }) => {
//     return (<>
//         <Item
//             onClick={handleCloseMenuAddProduct('unit')}
//             disableGutters={true}
//             disabled={false}
//             className="hovered-item"
//         >
//             <AddProductSelectItemSpan>
//                 <Tooltip
//                     arrow
//                     placement="bottom-start"
//                     title={t("Для регистрации новых упаковок необходимо указать статус профиля")}
//                 >
//                     <ListItemIcon><IconConsumerPackage /></ListItemIcon>
//                 </Tooltip>
//                 {t('Потребительская упаковка')}
//             </AddProductSelectItemSpan>
//         </Item>
//         <Item
//             onClick={handleCloseMenuAddProduct('group')}
//             disableGutters={true}
//             disabled={false}
//             className="hovered-item"
//         >
//             <AddProductSelectItemSpan>
//                 <Tooltip
//                     arrow
//                     placement="bottom-start"
//                     title={t("Описание карточек товаров с собственным или импортным кодом товара")}
//                 >
//                     <ListItemIcon><IconGroupPackage /></ListItemIcon>
//                 </Tooltip>
//                 {t('Групповая упаковка')}
//             </AddProductSelectItemSpan>
//         </Item>
//         <Item
//             onClick={handleCloseMenuAddProduct('transport')}
//             disableGutters={true}
//             disabled={false}
//             className="hovered-item"
//         >
//             <AddProductSelectItemSpan>
//                 <Tooltip
//                     arrow
//                     placement="bottom-start"
//                     title={t("Описание карточек товаров с собственным или импортным кодом товара")}
//                 >
//                     <ListItemIcon><IconTransportPackage /></ListItemIcon>
//                 </Tooltip>
//                 {t('Транспортная упаковка')}
//             </AddProductSelectItemSpan>
//         </Item>
//         {/*временно отключаем*/}
//         {/* {isElkUser && <>
//             <Item
//                 onClick={handleCloseMenuAddProduct('kit')}
//                 disableGutters={true}
//                 disabled={!isKitEnabled}
//                 className="hovered-item"
//             >
//                 <AddProductSelectItemSpan>
//                     <Tooltip
//                         arrow
//                         placement="bottom-start"
//                         title={t("Описание комплекта")}
//                     >
//                         <ListItemIcon><IconAddProductNew /></ListItemIcon>
//                     </Tooltip>
//                     {t('Комплект')}
//                 </AddProductSelectItemSpan>
//             </Item>
//             <Item
//                 onClick={handleCloseMenuAddProduct('technical')}
//                 disableGutters={true}
//                 disabled={!isTechGtinEnabled}
//                 className="hovered-item"
//             >
//                 <AddProductSelectItemSpan>
//                     <Tooltip
//                         arrow
//                         placement="bottom-start"
//                         title={t("Описание остатков и перемаркировка товара. Используется технический код товара из диапазона 290")}
//                     >
//                         <ListItemIcon><IconAddProductUnionNew /></ListItemIcon>
//                     </Tooltip>
//                     {t('Техническая карточка')}
//                 </AddProductSelectItemSpan>
//             </Item>
//         </>} */}
//     </>)
// };



class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isOpenList: false,
            anchorEl: null
        };
        // this.accountType = _.get(this.props, 'user.accountType', 'producer'); //было до 338 задачи, когда роль-строка, теперь массив
        this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes'));
        this.isElkUser = _.get(this.props, 'user.isElkUser', false);
    }

    componentDidMount() {
        const { usercompany, profileStatusWasLoaded } = this.props;
        const savedLang = localStorage.getItem('savedLanguage');

        if (savedLang) {
            this.props.setLocale(savedLang);
        }

        if (_.isEmpty(usercompany) && profileStatusWasLoaded === false) {
            this.props.onLoadProfileStatus();
        }

    }

    getClassName = (pathname) => this.props.pathname === pathname ? 'active' : '';

    goToProducts = () => {
        const { t } = this.props;
        if (!_.get(this.props, 'user.readyToWorkWithGoods')) {
            this.props.throwError(
                `${t('Идет процесс загрузки карточек из', {
                    gs1Name: t(config.gs1Name)
                })}. ${t('Начать работу с реестром товаров Вы сможете после окончания загрузки')}`
            );
        } else {
            this.props.goToPage(config.urls.products);
        }
    };
   
    goToCompanies = () => {
        this.props.goToPage(config.urls.companies);
    };

    goToCatalog = () => {
        this.props.goToPage(config.urls.catalog);
    };

    goToProfile = () => {
        this.props.goToPage('/profile');
    }

    onLangChange = (e) => {
        localStorage.setItem('savedLanguage', e.target.value);
        this.props.setLocale(e.target.value);
    };

    handleCloseMenuAddProduct = type => () => {
        const { t } = this.props;
        this.setState({ anchorEl: null });
        if (!_.isNull(type)) {
            if (!_.get(this.props, 'user.readyToWorkWithGoods')) {
                this.props.throwError(t('Создание карточек будет доступно после наполнения реестра товаров'));
            } else {
                this.props.deleteNotification();
                this.props.goToPage(`${config.urls.productNew}?type=${type}`);
            }
        }
    };

    onClickSidebarMenu = () => {
        this.state.open
            ? this.setState({ open: false, isOpenList: false })
            : this.setState({ open: true, isOpenList: false })
    }

    render() {
        const { locale, classes, t } = this.props;
        // const LOGO = isELK || _.get(this.props, 'user.isElkUser')
        //     ? <SidebarLogoKMT />
        //     : locale === 'kz' ? <SidebarLogoKZ /> : <SidebarLogoKZRu />
        // : isKZ
        //     ? locale === 'kz' ? <SidebarLogoKZ/> : <SidebarLogoKZRu/>
        //     : <SidebarLogoRU/>;

        // const userCanRegisterGoods = !_.isEmpty(usercompany);

        // const propsComponentItems = {
        //     t,
        //     user: _.get(this.props, 'user'),
        //     handleCloseMenuAddProduct: this.handleCloseMenuAddProduct
        // };

        // const tooltipText = "Функция регистрации упаковок доступна, если указан статус профиля";

        const overflow = this.props.pathname === '/profile' || /product$/.test(this.props.pathname) || /product\//.test(this.props.pathname) || /print/.test(this.props.pathname) || /catalog\//.test(this.props.pathname);        
        const ProductLink = this.getClassName(config.urls.products) ? <IconProductActive /> : <IconProduct />
        const CompaniesLink = this.getClassName(config.urls.companies) ? <IconCompaniesActive /> : <IconCompanies />
        const CatalogLink = this.getClassName(config.urls.catalog) ? <IconDirectoryActive /> : <IconDirectory />
        const ProfileLink = this.getClassName(config.urls.profile) ? <IconProfileSystem /> : <IconProfileSystemActive />


        return (
            <div className={classes.root}>
                <CssBaseline />

                <AppBar
                    style={{ display: "block" }}
                    position="fixed"
                    className={cx(classes.appBar, {
                        [classes.appBarShift]: this.state.open,
                    })}
                >
                    <Header locale={locale} open={this.state.open} />
                </AppBar>

                <Drawer
                    style={{ display: "block" }}
                    variant="permanent"
                    className={cx(classes.drawer, {
                        [classes.drawerOpen]: this.state.open,
                        [classes.drawerClose]: !this.state.open,
                    })}
                    classes={{
                        paper: cx({
                            [classes.drawerOpen]: this.state.open,
                            [classes.drawerClose]: !this.state.open,
                        }),
                    }}
                    open={this.state.open}
                >

                    <Divider />

                    <SidebarHead>
                        {!this.state.open
                            ? <BluLogoMin> <LogoKzSide /> </BluLogoMin>
                            : <BluLogoMin> <LogoKzSide /> <LogoKzSideWide /> </BluLogoMin>
                        }
                    </SidebarHead>
                  
                    <SidebarLink open={this.state.open} onClick={this.goToProducts}>
                        <SidebarLinkIcon open={this.state.open} className={this.getClassName(config.urls.products)}>
                            {ProductLink}
                        </SidebarLinkIcon>
                        <SidebarLinkTitle open={this.state.open} className={this.getClassName(config.urls.products)}>
                            {t('Товары')}
                        </SidebarLinkTitle>
                    </SidebarLink>

                    {this.isElkUser && _.includes(this.accountTypes, 'content-consumer.companies') &&
                        <SidebarLink open={this.state.open} onClick={this.goToCompanies}>
                            <SidebarLinkIcon open={this.state.open} className={this.getClassName(config.urls.companies)}>
                                {CompaniesLink}
                            </SidebarLinkIcon>
                            <SidebarLinkTitle open={this.state.open} className={this.getClassName(config.urls.companies)}>
                                {t('Компании')}
                            </SidebarLinkTitle>
                        </SidebarLink>
                    }

                    {this.isElkUser && _.includes(this.accountTypes, 'content-consumer.catalog') &&
                        <SidebarLink open={this.state.open} onClick={this.goToCatalog}>
                            <SidebarLinkIcon open={this.state.open} className={this.getClassName(config.urls.catalog)}>
                                {CatalogLink}
                            </SidebarLinkIcon>
                            <SidebarLinkTitle open={this.state.open} className={this.getClassName(config.urls.catalog)}>
                                {t('Каталог')}
                            </SidebarLinkTitle>
                        </SidebarLink>
                    }

                    <SidebarLinkProfile open={this.state.open} onClick={this.goToProfile}>
                        <SidebarLinkIcon open={this.state.open} className={this.getClassName(config.urls.profile)}>
                            {ProfileLink}
                        </SidebarLinkIcon>
                        <SidebarLinkTitle open={this.state.open} className={this.getClassName(config.urls.profile)}>
                            {t('Профиль')}
                        </SidebarLinkTitle>
                    </SidebarLinkProfile>

                    <SidebarMenuBtn open={this.state.open}>
                        <MuiThemeProvider theme={SidebarMenuButton}>
                            {this.state.open ?
                                <IconButton
                                    onClick={this.onClickSidebarMenu}>
                                    <IconHamburgerMenu />
                                </IconButton>
                                :
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.onClickSidebarMenu}>
                                    <IconHamburgerMenu />
                                </IconButton>
                            }
                        </MuiThemeProvider>
                    </SidebarMenuBtn>

                </Drawer>
                <main className={classes.content} style={{ overflowY: overflow && 'auto' }}>
                    <div className={classes.toolbar} />
                    {this.props.children}
                </main>
            </div>

        );
    }
}

Sidebar.propTypes = {
    goToPage: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
    throwError: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    pathname: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    user: state.authentificationReducer.user,
    locale: state.profileReducer.locale,
    usercompany: state.profileStatusReducer.data,
    profileStatusWasLoaded: state.profileStatusReducer.profileStatusWasLoaded,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        goToPage: (page) => push(page),
        throwError: (message) => throwNotification(message, 'error'),
        setLocale: (locale) => setLocale(locale),
        deleteNotification: () => deleteNotification(),
        onLoadProfileStatus: () => loadProfileStatus()
    }, dispatch);
};

export default compose(
    withTranslation(),
    withStyles(styles, { withTheme: true }),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Sidebar)
