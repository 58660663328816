import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../../../common/styles/Common.colors';

export const defaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '6px 12px',
        lineHeight: '21px',
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      error: {},
    },
    MuiSelect: {
      root: {
        width: '100%',
        // border: "1px solid #D9D9D9",
        // borderRadius: "3px",
        // '&$disabled': {
        //     backgroundColor: "#F9F9F9!important"
        // }
      },
      select: {
        padding: '6px 12px',
      },
      selectMenu: {
        color: '#52535A',
        fontWeight: '600',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
        color: '#868686',
      },
      shrink: {
        color: '#868686',
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '15px 20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiButton: {
      root: {
        minWidth: '40px',
        minHeight: '33px',
        backgroundColor: '#eeeeee',
        border: '1px solid #ebebeb',
        borderRadius: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
});

export const PageWrapper = styled.div`
  padding: 70px 45px;
  min-width: 1100px;
`;

export const ColumnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ColumnItem = styled.div`
  flex-direction: column;
  min-width: 40vw;
`;

export const InputRowItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;

export const InputLabelItem = styled.div`
  min-width: 170px;
  margin-right: 10px;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  .iconFix {
    margin-left: 20px;
    margin-bottom: -3px;
  }
`;

export const CheckBoxItem = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin-left: 160px;
`;

export const PredicateWrapper = styled.div`
  min-width: 100px;
  min-height: 33px;
  background-color: #eeeeee;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const SaveButtonWrapper = styled.div`
  margin-top: 40px;
  margin-left: 170px;
`;
