import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const GsRegContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const GsRegBottom = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  button {
    box-shadow: 0 15px 35px rgba(242, 239, 19, 0.4);
    min-width: 310px;
  }
`;
export const DefaultTable = createMuiTheme({
  typography: {},
  overrides: {
    MuiTableHead: {
      root: {
        borderTop: '1px solid #F2F2F2',
      },
    },
    MuiTableCell: {
      root: {
        padding: '20px 40px',
        fontSize: '14px',
        color: '#52535A',
        borderBottom: '1px solid #F2F2F2',
        '&:nth-of-type(1)': {
          borderRight: '1px solid #F2F2F2',
        },
      },
      head: {
        fontSize: '14px',
        fontWeight: '600',
        color: '#52535A',
      },
      body: {
        fontSize: '16px',
        color: '#52535A',
      },
    },
  },
});
