export const animationData = {
  v: '5.3.4',
  fr: 30,
  ip: 0,
  op: 43,
  w: 512,
  h: 512,
  nm: 'Comp 1',
  ddd: 0,
  assets: [
    // u: путь, p: название файла
    { id: 'image_0', w: 171, h: 171, u: '/images/', p: 'preloader.png', e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'Векторный смарт-объект',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -270, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.631, y: 0.525 },
                  o: { x: 0.34, y: 0.228 },
                  n: '0p631_0p525_0p34_0p228',
                  t: 0,
                  s: [426, 426.75, 0],
                  e: [339.5, 341.5, 0],
                  to: [-14.4166669845581, -14.2083330154419, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.056, y: 0.858 },
                  o: { x: 0.167, y: 0.094 },
                  n: '0p056_0p858_0p167_0p094',
                  t: 10,
                  s: [339.5, 341.5, 0],
                  e: [426, 426.75, 0],
                  to: [0, 0, 0],
                  ti: [-14.4166669845581, -14.2083330154419, 0],
                },
                { t: 26 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [85.5, 85.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'Векторный смарт-объект',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -90, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.631, y: 0.521 },
                  o: { x: 0.34, y: 0.225 },
                  n: '0p631_0p521_0p34_0p225',
                  t: 0,
                  s: [85, 85.75, 0],
                  e: [168.5, 170.5, 0],
                  to: [13.9166669845581, 14.125, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.056, y: 0.855 },
                  o: { x: 0.167, y: 0.093 },
                  n: '0p056_0p855_0p167_0p093',
                  t: 10,
                  s: [168.5, 170.5, 0],
                  e: [85, 85.75, 0],
                  to: [0, 0, 0],
                  ti: [13.9166669845581, 14.125, 0],
                },
                { t: 26 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [85.5, 85.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          nm: 'Векторный смарт-объект',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -180, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.631, y: 0.522 },
                  o: { x: 0.34, y: 0.225 },
                  n: '0p631_0p522_0p34_0p225',
                  t: 0,
                  s: [85, 426.75, 0],
                  e: [168.5, 341.5, 0],
                  to: [13.9166669845581, -14.2083330154419, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.056, y: 0.855 },
                  o: { x: 0.167, y: 0.093 },
                  n: '0p056_0p855_0p167_0p093',
                  t: 10,
                  s: [168.5, 341.5, 0],
                  e: [85, 426.75, 0],
                  to: [0, 0, 0],
                  ti: [13.9166669845581, -14.2083330154419, 0],
                },
                { t: 26 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [85.5, 85.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 2,
          nm: 'Векторный смарт-объект',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.631, y: 0.525 },
                  o: { x: 0.34, y: 0.229 },
                  n: '0p631_0p525_0p34_0p229',
                  t: 0,
                  s: [427, 85.75, 0],
                  e: [339.5, 170.5, 0],
                  to: [-14.5833330154419, 14.125, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.056, y: 0.858 },
                  o: { x: 0.167, y: 0.094 },
                  n: '0p056_0p858_0p167_0p094',
                  t: 10,
                  s: [339.5, 170.5, 0],
                  e: [427, 85.75, 0],
                  to: [0, 0, 0],
                  ti: [-14.5833330154419, 14.125, 0],
                },
                { t: 26 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [85.5, 85.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'Pre-comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [1], y: [1] },
              o: { x: [0.848], y: [0] },
              n: ['1_1_0p848_0'],
              t: 0,
              s: [0],
              e: [45],
            },
            {
              i: { x: [0.024], y: [1] },
              o: { x: [0.007], y: [0.089] },
              n: ['0p024_1_0p007_0p089'],
              t: 7,
              s: [45],
              e: [90],
            },
            { t: 29 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [256, 256, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: -1,
      op: 899,
      st: -1,
      bm: 0,
    },
  ],
  markers: [],
};
