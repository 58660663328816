import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  MuiThemeProvider,
  FormControl,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  IconDropDownGrey,
  IconDropDownAuto,
  IconError,
} from '../../../../common/icons/customIcons';
import cx from 'classnames';
import _ from 'lodash';
import {
  getSuggestions,
  loadDictionaryGpcConsumerPackage,
  loadDictionaryTnvedConsumerPackage,
  clearGpcAndTnvedSelectOptions,
  checkTnvedForMarking,
  clearTnvedArrayForMarking,
} from '../../ducks/Product.actions';
import { ConsumerItem } from '../itemElement';
import { CustomInput } from '../../../../components';
import SearchParameters from '../SearchParameters';
import { FieldTitle } from './ConsumerPackage.styled';
import {
  AdresFields,
  AdresTitle,
  AdresTitleH3,
  CompanyBlock,
  PackageTitleHolder,
  ProductTabBtn,
  BlocksDivider,
  NonMarkedLabel,
  MarkedLabel,
  TextTooltipModeration,
  TextTooltipModerationUpper,
} from '../../../../common/styles/Common.styled';
import { CurrentInputTnvedGpc } from '../../../../components/certificateSelect/CertificateSelect.styled';
import { ModerationIconHolder } from '../Product/Product.styled';

const getErrors = (moderateErrors) => {
  if (_.isNil(moderateErrors)) return [];
  const errors = _.filter(moderateErrors, { type: 'attribute' });
  return _.map(errors, (error) => error.data);
};

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);
const getDefaultToObj = (obj, path, defaultValue = {}) =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);
const isEmptyNull = (item) =>
  _.isNumber(item) ? false : _.isNil(item) || _.isEmpty(item) || item === '';

const findItemIndex = (attributes, item, index) => {
  /**
   * TODO: если что удалить.
   * старая реализация
   */
  // let findIndex;
  // let i = 0;
  // for (let k = 0; k < attributes.length; k++) {
  //
  //     // if (attributes[k].id === item.id) {
  //         if (i === index) {
  //             findIndex = k;
  //             break;
  //         }
  //         i++;
  //     }
  // }
  let findIndex;
  // let i = 0;
  for (let k = 0; k < attributes.length; k++) {
    if (attributes[k]._attruid === item._attruid) {
      findIndex = k;
      break;
    }
  }
  return findIndex;
};

/**
 * Костыль для того чтобы понять, что мы находимся в редактировании сущности.
 *
 * надо разбить url на массив, посмотреть что length === 3 и в результирующем массиве
 * отсуствутют элементы "goods", "draft"
 */

const checkStateFormUrl = (url) => {
  const arrayFromUrl = url.split('/');

  return (
    arrayFromUrl.length === 3 && !_.includes(['goods', 'draft'], arrayFromUrl)
  );
};

class ConsumerPackage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentShow: true,
      errors: {},
      moderateErrors: [],
      popupElement: {},
      showPopupSearch: false,
      tnved: '',
      gcp: '',
      gpcSelectOptions: [],
      tnvedSelectOptions: [],
      labels: [],
      bottom: true,
    };
    this.checkRequiredFields(this.state);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = {};
    if (
      _.isEmpty(prevState.moderateErrors) &&
      !_.isEmpty(nextProps.moderateErrors)
    ) {
      state = { moderateErrors: getErrors(nextProps.moderateErrors) };
    }

    return _.isEmpty(state) ? null : state;
  }

  componentDidMount() {
    const {
      tnvedSelectOptions,
      gpcSelectOptions,
      productData,
      gpcWasLoaded,
      tnvedWasLoaded,
    } = this.props;
    const catId = _.find(_.get(productData, 'categories'), {
      classifier: 'k3',
    });

    if (
      catId &&
      catId.categoryId &&
      _.isEmpty(gpcSelectOptions) &&
      !gpcWasLoaded
    ) {
      this.props.loadDictionaryGpcConsumerPackage(catId.categoryId);
    }

    if (
      catId &&
      catId.categoryId &&
      _.isEmpty(tnvedSelectOptions) &&
      !tnvedWasLoaded
    ) {
      this.props.loadDictionaryTnvedConsumerPackage(catId.categoryId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevState, this.state) ||
      !_.isEqual(prevProps, this.props)
    ) {
      this.checkRequiredFields(this.state);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //     const result = [_.isEqual(this.state, nextState)];
  //     _.forEach(Object.keys(nextProps), key => result.push(_.isEqual(this.props[key], nextProps[key])));
  //     return !_.every(result);
  // }

  // переписать
  componentWillUnmount() {
    this.props.clearGpcAndTnvedSelectOptions();

    if (!_.isEmpty(this.props.tnvedArrayForMarking)) {
      this.props.clearTnvedArrayForMarking();
    }
  }

  getLabel(element) {
    if (!element) {
      return null;
    }

    const tnvedArray = this.props.tnvedArrayForMarking;
    const corresponedeObject = _.find(tnvedArray, (object) => {
      return object.uiId === element._attruid;
    });

    if (_.isEmpty(corresponedeObject)) {
      if (Number(element.value)) {
        this.props.checkTnvedForMarking(element);
      }
      return null;
    }

    if (corresponedeObject && corresponedeObject.marked === 1) {
      return (
        <MarkedLabel>* {this.props.t('Маркируемый код тнвэд')}</MarkedLabel>
      );
    } else if (corresponedeObject) {
      return (
        <NonMarkedLabel>
          * {this.props.t('Немаркируемый код тнвэд')}
        </NonMarkedLabel>
      );
    }

    return null;
  }

  // TODO: refactoring need
  filterFieldsArr = (arr) => {
    return Object.values(
      arr.flat().reduce((obj, item) => {
        if (item.id === 15810) {
          const objArr = Object.keys(obj);
          const newObj = objArr
            .splice(0, objArr.length - 1)
            .reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {});
          const keyLast = objArr.splice(objArr.length - 1)[0];
          const lastObj = obj[keyLast];
          let resultObj = {};

          if (lastObj) {
            resultObj = {
              ...newObj,
              [Date.now() + item.multiplicityGroupId]: [item],
              [keyLast]: lastObj,
            };
          } else {
            resultObj = {
              ...obj,
              [Date.now() + item.multiplicityGroupId]: [item],
            };
          }
          return resultObj;
        }

        if (item.id === 15813) {
          return {
            ...obj,
            [Date.now() + item.multiplicityGroupId + item.addItemSortId]: [
              item,
            ],
          };
        }

        if (item.newBlockId) {
          if (obj[item.newBlockId]) {
            return {
              ...obj,
              [item.newBlockId]: [...obj[item.newBlockId], item],
            };
          }

          return {
            ...obj,
            [item.newBlockId]: [item],
          };
        }

        if (item.multiplicityGroupId) {
          if (item.sortOrder) {
            if (obj[`${item.multiplicityGroupId}_${item.sortOrder}`]) {
              const arr = obj[`${item.multiplicityGroupId}_${item.sortOrder}`];

              if (item.sortOrderInside) {
                const newArr = [...arr, item].sort(
                  (a, b) => a.sortOrderInside - b.sortOrderInside,
                );
                return {
                  ...obj,
                  [`${item.multiplicityGroupId}_${item.sortOrder}`]: newArr,
                };
              }

              return {
                ...obj,
                [`${item.multiplicityGroupId}_${item.sortOrder}`]: [
                  ...arr,
                  item,
                ],
              };
            }

            return {
              ...obj,
              [`${item.multiplicityGroupId}_${item.sortOrder}`]: [item],
            };
          }

          if (obj[item.multiplicityGroupId]) {
            return {
              ...obj,
              [item.multiplicityGroupId]: [
                ...obj[item.multiplicityGroupId],
                item,
              ],
            };
          }

          return {
            ...obj,
            [item.multiplicityGroupId]: [item],
          };
        }
        const key = item.sortOrder;

        if (obj[key]) {
          const clone = obj[key].find((field) => field.id === item.id);
          if (clone) {
            const arr =
              obj[`${item.multiplicityGroupId}_${item.addItemSortId}`];

            if (obj[`${item.multiplicityGroupId}_${item.addItemSortId}`]) {
              if (item.sortOrderInside) {
                const newArr = [...arr, item].sort(
                  (a, b) => a.sortOrderInside - b.sortOrderInside,
                );
                return {
                  ...obj,
                  [`${item.multiplicityGroupId}_${item.addItemSortId}`]: newArr,
                };
              }
              return {
                ...obj,
                [`${item.multiplicityGroupId}_${item.addItemSortId}`]: [
                  ...obj[`${item.multiplicityGroupId}_${item.addItemSortId}`],
                  item,
                ],
              };
            }

            return {
              ...obj,
              [`${item.multiplicityGroupId}_${item.addItemSortId}`]: [item],
            };
          }

          const arr = obj[key];

          if (item.sortOrderInside) {
            const newArr = [...arr, item].sort(
              (a, b) => a.sortOrderInside - b.sortOrderInside,
            );
            return {
              ...obj,
              [key]: newArr,
            };
          }

          return {
            ...obj,
            [key]: [...obj[key], item],
          };
        }

        return {
          ...obj,
          [item.sortOrder]: [item],
        };
      }, {}),
    );
  };

  buildBlocks = (firstPackageGroups, dependAttrArray = []) => {
    const { t } = this.props;
    const jsxBlocks = [];
    _.forEach(firstPackageGroups, (attribute, attributeIndex) => {
      let groupsOfIdForMultiplicity;
      if (
        attribute.multiplicityGroups &&
        !_.isEmpty(attribute.multiplicityGroups.groups)
      ) {
        groupsOfIdForMultiplicity = _.cloneDeep(
          attribute.multiplicityGroups.groups,
        );
        /**
         * допустим список будут присылать в аттрибутной группе.
         * надо его отсортировать и молится.
         */
        _.forEach(groupsOfIdForMultiplicity, (group) => {
          group.sort((a, b) => {
            return a - b;
          });

          let button = false;
          let skippedFirst = false;

          _.forEachRight(attribute.attributes, (attribute) => {
            attribute.multiplicityGroup = null;
            if (attribute.id === group[group.length - 1] && !button) {
              button = true;
              attribute.multiplicityGroup = group;
              attribute.multiplicityAdd = true;
            }
          });

          _.forEach(attribute.attributes, (attribute) => {
            attribute.multiplicityDelete = false;
            if (attribute.id === group[group.length - 1] && skippedFirst) {
              attribute.multiplicityDelete = true;
            }
            if (attribute.id === group[group.length - 1] && !skippedFirst) {
              skippedFirst = true;
            }
          });
        });

        /**
         * возвращаемся к тому, что надо добавить какой-то аттрибут,который будет отвечать за кнопку addItem(причем только у одного(последнего) блока)
         * скорей всего нужно оставить текущий функционал, но добавить новый аттрибут.
         */
        // _.forEach(groupsOfIdForMultiplicity, (group) => {
        //     let button = false;
        //     _.forEachRight(attribute.attributes, (attribute) => {
        //         attribute.multiplicityGroup = null;
        //         if (attribute.id === group[group.length - 1] && !button) {
        //             button = true;
        //             attribute.multiplicityGroup = group
        //             attribute.multiplicityAdd = true;
        //         }
        //     })
        // })

        /**
         * Потом проставить новые атрибуты, которые будут отвечать за кнопку удалить у всех, кроме оригинального блока.
         * возможно инверсию на проставление addButton.
         */

        // _.forEach(groupsOfIdForMultiplicity, (group) => {
        //     let skippedFirst = false;
        //     _.forEach(attribute.attributes, (attribute) => {
        //         attribute.multiplicityDelete = false;
        //         if (attribute.id === group[group.length - 1] && skippedFirst) {
        //             attribute.multiplicityDelete = true;
        //         }
        //         if (attribute.id === group[group.length - 1] && !skippedFirst) {
        //             skippedFirst = true;
        //         }
        //     })
        // })
      }

      if (!_.isEmpty(dependAttrArray) && _.isArray(dependAttrArray)) {
        /**
         * получили массив из ведущих аттрибутов.
         * у каждого такого аттрибута есть dependedRequiredAttrsIds - аттрибуты которые зависят от него.
         * если у ведущего аттрибута value !== null, то значит оно заполнено, что в свою очередь должно привести
         * аттрибуты из dependedRequiredAttrsIds к свойству required.
         */
        const leadingAttrsArray = [];

        _.forEach(dependAttrArray, (arrayItem) => {
          _.forEach(attribute.attributes, (attribute) => {
            if (
              arrayItem.attrId === attribute.id &&
              !_.isEmpty(attribute.value)
            ) {
              leadingAttrsArray.push(attribute);
            }
          });
        });

        _.forEach(leadingAttrsArray, (leadingAttr) => {
          _.forEach(attribute.attributes, (attribute) => {
            if (
              _.includes(
                leadingAttr.dependedRequiredAttrsIds,
                _.toString(attribute.id),
              ) &&
              !attribute.required
            ) {
              attribute.required = true;
            }
          });
        });
      }

      const elements = [];
      const ids = [];
      const uiIds = [];

      let isMultiplyProducer = false;

      const attributes = this.filterFieldsArr(attribute.attributes);
      // TODO: Временно уберу этот странный рендер

      /**
       * Блок Изготовителя
       */
      // if (attribute.id === 197) {
      //   isMultiplyProducer = true;

      //   let elements = [];
      //   let producerFields = [];
      //   /**
      //    *
      //    * @param {15713} - Наименование изготовителя
      //    * @param {15716} - ИИН/БИН изготовителя
      //    * @param {15719} - GCP изготовителя
      //    * @param {15722} - Юридический адрес изготовителя
      //    */
      //   [15713, 15716, 15719, 15722].forEach((field) => {
      //     producerFields.push(
      //       attribute.attributes.filter((el) => el.id === field),
      //     );
      //   });

      //   producerFields[0].forEach((el, i) => {
      //     elements = [];
      //     // const elementsSort = this.filterFieldsArr(producerFields);

      //     producerFields.forEach((el) =>
      //       elements.push(
      //         this.buildElement(
      //           el[i] || null,
      //           attribute.attributes,
      //           attributeIndex,
      //         ),
      //       ),
      //     );

      //     jsxBlocks.push(
      //       <div key={`block_group_${attributeIndex + Math.random()}`}>
      //         <FieldTitle>{attribute.name}</FieldTitle>
      //         {elements}
      //       </div>,
      //     );
      //   });
      // }

      if (isMultiplyProducer) return;
      let isMultiplyImporter = false;

      /**
       * Блок Импортёра.
       */
      // if (attribute.id === 199) {
      //   isMultiplyImporter = true;
      //   let elements = [];
      //   let importerFields = [];

      //   /**
      //    *
      //    * @param {15725} - Наименование изготовителя
      //    * @param {15728} - ИИН/БИН изготовителя
      //    * @param {15731} - GCP изготовителя
      //    * @param {15734} - Юридический адрес импортёра
      //    */
      //   [15725, 15728, 15731, 15734].forEach((field) => {
      //     importerFields.push(
      //       attribute.attributes.filter((el) => el.id === field),
      //     );
      //   });

      //   importerFields[0].forEach((el, i) => {
      //     elements = [];
      //     // const elementsSort = this.filterFieldsArr(importerFields);

      //     importerFields.forEach((el) =>
      //       elements.push(
      //         this.buildElement(
      //           el[i] || null,
      //           attribute.attributes,
      //           attributeIndex,
      //         ),
      //       ),
      //     );

      //     jsxBlocks.push(
      //       <div key={`block_group_${attributeIndex + Math.random()}`}>
      //         <FieldTitle>{attribute.name}</FieldTitle>
      //         {elements}
      //       </div>,
      //     );
      //   });
      // }
      if (isMultiplyImporter) return;
      _.forEach(attributes, (item, idx) => {
        isMultiplyProducer = false;
        isMultiplyImporter = false;

        if (Array.isArray(item)) {
          const els = [];
          item.forEach((element, index) => {
            if (
              !uiIds.includes(element._attruid) &&
              !ids.includes(element.id)
            ) {
              uiIds.push(element._attruid);
              if (element.multiplicity) {
                ids.push(element.id);
              }

              const fullItem = item.find(
                (item) => item.id === 15752 || item.id === 2478,
              );
              const hasFullItems = Boolean(fullItem);
              const dividerElement = element.id === 15752 && index !== 0;
              const length = 100 / item.length === 100 ? 0 : 8;
              const hasTwoFields =
                item.length % 3 === 2 &&
                (index === item.length - 1 || index === item.length - 2);
              const isNatural =
                item.length % 2 === 0 || hasTwoFields || hasFullItems
                  ? 50
                  : 33.333333333333336;
              const width = `calc(${
                100 / item.length < isNatural ? isNatural : 100 / item.length
              }% - ${length}px)`;
              const documentDate = item.find((i) => i.id === 15754);
              const documentValidity = item.find((i) => i.id === 15757);
              const inOneString =
                documentDate &&
                documentValidity &&
                documentDate.sortOrder === documentValidity.sortOrder;

              els.push(
                <div
                  key={element._attruid + element.id}
                  style={{
                    width: `${
                      element.name === 'divider' ||
                      !inOneString ||
                      (hasFullItems && fullItem.id === element.id)
                        ? '100%'
                        : width
                    }`,
                    minWidth:
                      element.name === 'divider' ||
                      !inOneString ||
                      (hasFullItems && fullItem.id === element.id)
                        ? '100%'
                        : width,
                    marginBottom:
                      element.id === 15722 && idx < attributes.length - 1
                        ? 15
                        : 0,
                    order: inOneString && element.sortOrderInside,
                    // flex: hasLastEl && 1
                  }}>
                  {this.buildElement(
                    element,
                    attribute.attributes,
                    attributeIndex,
                    dividerElement,
                  )}
                </div>,
              );
            }
          });
          const documentDateInString = item.filter(
            (item) => item.id === 15754 || item.id === 15757,
          );
          const isDocumentDateInString = !_.isEmpty(
            documentDateInString.sort((a, b) => a.sortOrder === b.sortOrder),
          );
          elements.push(
            <div
              key={item.length + idx}
              style={{
                display: `${isDocumentDateInString ? 'flex' : 'block'}`,
                width: '100%',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}>
              {els}
            </div>,
          );
        } else {
          if (!uiIds.includes(item._attruid) && !ids.includes(item.id)) {
            uiIds.push(item._attruid);
            if (item.multiplicity) {
              ids.push(item.id);
            }

            const dividerElement = item.id === 15752 && idx !== 0;

            elements.push(
              this.buildElement(
                item,
                attribute.attributes,
                attributeIndex,
                dividerElement,
              ),
            );
          }
        }
      });
      jsxBlocks.push(
        <div key={`block_group_${attributeIndex}`}>
          <FieldTitle>{t(attribute.name)}</FieldTitle>
          {elements.map((el, idx) => {
            if (Array.isArray(el)) {
              if (el.length === 1) {
                return el[0];
              }
              return (
                <div key={idx} style={{ display: 'flex' }}>
                  {el.map((item, idx) => {
                    return <div key={idx}>{item}</div>;
                  })}
                </div>
              );
            }

            return el;
          })}
        </div>,
      );
    });

    return jsxBlocks;
  };

  renderConsumerItems = (el, props, divider) => {
    if (Array.isArray(el)) {
      return el.map((item) => {
        if (Array.isArray(item)) {
          return this.renderConsumerItems(item, props, divider);
        }
        return this.renderCosumerItem(item, props, divider);
      });
    }

    return this.renderCosumerItem(el, props, divider);
  };

  renderCosumerItem = (element, props, divider) => {
    const includesElem = _.includes([15816, 15812], element.id);
    return (
      <div key={element.id}>
        {divider ? <BlocksDivider /> : null}
        <Element
          style={{ width: '100%' }}
          key={`element_${element._attruid}`}
          name={`id_${element.id}`}>
          <ConsumerItem
            {...props}
            setError={this.props.setError}
            errorFields={this.props.errorFields}
            // hasPublishedGood={this.props.productData.hasPublishedGood}
          />
          {includesElem && element.valueTitle ? this.getLabel(element) : null}
        </Element>
      </div>
    );
  };

  buildElement = (element, elements, attributeIndex, divider) => {
    if (element.hidden) return null;

    const {
      onValidate,
      preview,
      productData,
      currentLocale,
      status,
    } = this.props;
    const { moderateErrors, popupElement, errors } = this.state;
    const resizeable = _.get(element, 'resizeable', false);
    const allowEditRequiredAttr = _.get(
      element,
      'allowEditRequiredAttr',
      false,
    );
    const isCopy = _.get(element, 'isCopy', false);
    const isDraft = _.get(productData, 'status') === 'draft';
    const isError = _.get(productData, 'status') === 'errors';
    const isEdit = isError || isDraft;
    const editIsAllowed =
      allowEditRequiredAttr &&
      isEdit &&
      checkStateFormUrl(window.location.pathname);
    const draftAndEditMode =
      isDraft && checkStateFormUrl(window.location.pathname);

    let props = {
      groupIndex: attributeIndex,
      updateItem: this.updateItem,
      onValidate: onValidate,
      showPopupSearch: this.showPopupSearch,
      closePopupSearch: this.closePopupSearch,
      popupElement: popupElement,
      errors: errors,
      moderateErrors: moderateErrors,
      preview: preview,
      hasPublishedGood: _.get(productData, 'hasPublishedGood', false),
      currentLocale,
      allowEditRequiredAttr: editIsAllowed,
      resizeable,
      attrGroup: this.props.attrGroup,
      isCopy,
      isDraft,
      draftAndEditMode,
      multiplicityGroupId: element && element.multiplicityGroupId,
      multiplicityGroupUiId: element && element.multiplicityGroupUiId,
      multiplicityDelete: element && element.multiplicityDelete,
      status,
      elements,
    };

    if (element === null) return;
    if (element.multiplicity) {
      props = {
        ...props,
        multiplicity: true,
        items: _.filter(elements, { id: element.id }),
        addItem: this.addItem,
        deleteItem: this.deleteItem,
        addIsAllowed: isDraft,
        isDraft,
      };
    } else if (!_.isEmpty(element.multiplicityGroup)) {
      props = {
        ...props,
        multiplicity: true,
        multiplicityAdd: element.multiplicityAdd,
        items: _.filter(elements, { _attruid: element._attruid }),
        addItem: this.addItem,
        deleteItem: this.deleteItem,
        multiplicityGroup: element.multiplicityGroup,
        addIsAllowed: isDraft,
        isDraft,
      };
    } else if (element.multiplicityGroupUiId || element.multiplicityGroupId) {
      props = {
        ...props,
        multiplicity: false,
        items: [element],
        addItem: () => {},
        deleteItem: this.deleteItem,
        isDraft,
      };
    } else {
      props = {
        ...props,
        multiplicity: false,
        items: [element],
        addItem: () => {},
        deleteItem: () => {},
      };
    }
    return element.name === 'divider'
      ? null
      : this.renderConsumerItems(element, props, false);
    // return element.name === 'divider' ? (
    //   <Element key={`element_${element._attruid}`} name={`id_${element.name}`}>
    //     <BlocksDivider />
    //   </Element>
    // ) : (
    //   this.renderConsumerItems(element, props, divider)
    // );
  };

  onClickHide = () => {
    this.setState({ contentShow: !this.state.contentShow });
  };

  addItem = (newItem, groupIndex, isArray = false, idOfLastItem) => {
    let attrGroup = _.cloneDeep(this.props.attrGroup);
    const items = attrGroup[groupIndex].attributes;

    if (isArray && idOfLastItem) {
      const addItemSortId = Date.now();
      const newItemsWithId = newItem.map((item) => {
        if (item.id === 15752) {
          return {
            ...item,
            addItemSortId: Date.now() + Math.random(),
          };
        }

        return {
          ...item,
          addItemSortId,
        };
      });
      const indexOfInsertion = _.findLastIndex(items, (item) => {
        return item.id === idOfLastItem;
      });
      _.forEach(items, (item) => {
        item.multiplicityGroup = null;
      });
      items.splice(indexOfInsertion + 1, 0, ...newItemsWithId);
      _.set(attrGroup, `[${groupIndex}].attributes`, items);
      return this.props.updateData('attrGroup', attrGroup);
    }
    items.push(newItem);
    _.set(attrGroup, `[${groupIndex}].attributes`, items);
    this.props.updateData('attrGroup', attrGroup);
  };

  deleteItem = (items, groupIndex, attrUid) => {
    let attrGroup = _.cloneDeep(this.props.attrGroup);
    const { setErrorValidate } = this.props;

    setErrorValidate(false);

    if (items[0].multiplicityGroupUiId || items[0].multiplicityGroupId) {
      let deleteGroupId =
        items[0].multiplicityGroupUiId || items[0].multiplicityGroupId;
      /**
       * надо искать в массиве элементы с одинаковыми multiplicityGroupUiId или multiplicityGroupId
       * и удалять их из группы.
       */
      const attributes = _.reject(
        attrGroup[groupIndex].attributes,
        (attrbute) => {
          return _.includes(
            [attrbute.multiplicityGroupId, attrbute.multiplicityGroupUiId],
            deleteGroupId,
          );
        },
      );

      _.set(attrGroup, `[${groupIndex}].attributes`, attributes);
      return this.props.updateData('attrGroup', attrGroup);
    }

    const attributes = _.reject(
      attrGroup[groupIndex].attributes,
      (i) => i._attruid === attrUid,
    );
    _.set(attrGroup, `[${groupIndex}].attributes`, attributes);
    this.props.updateData('attrGroup', attrGroup);
  };

  updateItem = (items, groupIndex, index) => {
    if (_.includes([15816, 15812], items[0].id)) {
      if (Number(items[0].value)) {
        this.props.checkTnvedForMarking(items[0]);
      }
    }

    let attrGroup = _.cloneDeep(this.props.attrGroup);
    let attributes = attrGroup[groupIndex].attributes;
    const updateItem = items[index];

    /**
     * почистить.
     */
    if (
      _.isArray(items[0].dependedRequiredAttrsIds) &&
      !_.isEmpty(items[0].dependedRequiredAttrsIds) &&
      items[0].dependedRequiredTrack
    ) {
      if (items[0].value) {
        /**
         * проставить required:true полям, id'шники которых находятся в items[0].dependedRequiredAttrsIds
         */
        _.forEach(attributes, (attribute) => {
          if (
            _.includes(
              items[0].dependedRequiredAttrsIds,
              _.toString(attribute.id),
            )
          ) {
            attribute.required = true;
          }
        });
      } else {
        /**
         * проставить required:false полям, id'шники которых находятся в items[0].dependedRequiredAttrsIds
         */
        _.forEach(attributes, (attribute) => {
          if (
            _.includes(
              items[0].dependedRequiredAttrsIds,
              _.toString(attribute.id),
            )
          ) {
            attribute.required = false;
          }
        });
      }
    }

    const updatedIndex = findItemIndex(attributes, items[index], index);
    if (!_.isUndefined(updatedIndex)) {
      _.set(
        attrGroup,
        `[${groupIndex}].attributes.[${updatedIndex}]`,
        updateItem,
      );
      if (updateItem.id === 790 || updateItem.id === 820) {
        this.validateRanges(attrGroup, groupIndex, 790, 820);
      }
      if (updateItem.id === 791 || updateItem.id === 13863) {
        this.validateRanges(attrGroup, groupIndex, 791, 13863);
      }
      this.props.updateData('attrGroup', attrGroup);
    }
  };

  validateRanges = (attrGroup, groupIndex, minId, maxId) => {
    const attributes = attrGroup[groupIndex].attributes;
    const minValue = _.find(attributes, { id: minId });
    const maxValue = _.find(attributes, { id: maxId });
    if (minValue && maxValue) {
      if (
        !_.isNil(minValue.value) &&
        !_.isNil(maxValue.value) &&
        _.toNumber(maxValue.value) < _.toNumber(minValue.value)
      ) {
        const errors = {
          [maxValue.id]:
            minId === 790
              ? 'Значение атрибута «Макс. Температура» не может быть меньше значения атрибута «Мин. Температура».'
              : 'Значение атрибута «Относительная Влажность, до» не может быть меньше значения атрибута «Относительная Влажность, от».',
        };

        if (this.state.errors[maxValue.id] !== errors[maxValue.id]) {
          this.setState((prevState) => ({
            errors: { ...prevState.errors, ...errors },
          }));
        }
      } else {
        if (this.state.errors[maxValue.id]) {
          this.setState((prevState) => ({
            errors: _.omit(prevState.errors, [maxValue.id]),
          }));
        }
      }
    }
  };

  checkRequiredFields = (state) => {
    const { attrGroup } = this.props;
    let errors = [];

    _.forEach(attrGroup, (attribute) => {
      _.forEach(attribute.attributes, (element) => {
        let error = {};
        if (element.required) {
          if (_.isEmpty(element.valuesTypePreset)) {
            error[element.id] = _.isEmpty(element.valueTitle)
              ? isEmptyNull(element.value)
              : false;
          } else {
            error[element.id] = _.isEmpty(element.valueTitle)
              ? isEmptyNull(element.value) || _.isEmpty(element.valueType)
              : _.isEmpty(element.valueType);
          }
        }
        if (element.id === 13863 || element.id === 820) {
          error[element.id] = _.isNil(error[element.id])
            ? !!state.errors[element.id]
            : error[element.id] || !!state.errors[element.id];
        }

        if (error[element.id]) {
          errors.push(error);
        }
      });
    });
    this.props.handleErrors('attrGroup', errors, null);
  };

  showPopupSearch = (groupIndex, rowIndex, name, value) => {
    const item = { groupIndex, rowIndex, name, value };
    this.setState({ showPopupSearch: true, popupElement: item });
  };

  closePopupSearch = () => {
    this.setState({ showPopupSearch: false, popupElement: {} });
  };

  chooseCertificate = (certificate) => {
    let attrGroup = _.cloneDeep(this.props.attrGroup);
    const item = this.state.popupElement;
    const group = attrGroup[item.groupIndex];
    const certField = group.attributes[item.rowIndex];
    certField.value = certificate.documentNumber;
    _.set(
      attrGroup,
      `[${item.groupIndex}].attributes.[${item.rowIndex}]`,
      certField,
    );

    const dateIndex = _.findIndex(group.attributes, { id: 6003 });
    if (dateIndex !== -1) {
      const dateField = group.attributes[dateIndex];
      dateField.value = certificate.documentDate;
      _.set(
        attrGroup,
        `[${item.groupIndex}].attributes.[${dateIndex}]`,
        dateField,
      );
    }

    const statusIndex = _.findIndex(group.attributes, { id: 6002 });
    if (statusIndex !== -1) {
      const statusField = group.attributes[statusIndex];
      statusField.value = certificate.statusName;
      statusField.valueTitle = certificate.statusName;
      _.set(
        attrGroup,
        `[${item.groupIndex}].attributes.[${statusIndex}]`,
        statusField,
      );
    }
    this.props.updateData('attrGroup', attrGroup);
    this.setState({ showPopupSearch: false, popupElement: {} });
  };

  onChangeOptions = (options, value, classifier) => {
    if (value) {
      let correspondOption = _.find(
        options,
        (option) => option.categoryId === value,
      );

      delete correspondOption.categoryPath;
      correspondOption.classifier = classifier;

      this.props.updateData('categories', correspondOption);
    }

    this.setState({ [classifier]: value });
  };

  handleChangeTnved = (value) => {
    const { tnvedSelectOptions } = this.props;
    this.onChangeOptions(tnvedSelectOptions, value, 'tnved');
  };

  handleChangeGpc = (value) => {
    const { gpcSelectOptions } = this.props;
    this.onChangeOptions(gpcSelectOptions, value, 'gpc');
  };

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!_.isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  returnPackageType = (type) => {
    const { t } = this.props;

    switch (type) {
      case 'consumer':
        return t('Потребительская упаковка');
      case 'transport':
        return t('Транспортная упаковка');
      case 'group':
        return t('Групповая упаковка');
      default:
        return;
    }
  };

  getField = (classifier) => {
    const { productData } = this.props;
    return _.find(_.get(productData, 'categories'), { classifier });
  };

  findCorrespondObject = (array, incomValue) => {
    return _.find(array, (arrayItem) => {
      return arrayItem.categoryId === incomValue;
    });
  };

  render() {
    const {
      productData,
      attrGroup,
      t,
      gpcSelectOptions,
      tnvedSelectOptions,
      preview,
      wasPublished,
      status,
      moderateErrors,
    } = this.props;
    const fields = this.buildBlocks(
      attrGroup,
      productData.dependedWholeRequiredAttrsIds,
    );

    const fieldTNVD = this.getField('tnved');
    const fieldGPC = this.getField('gpc');
    const fieldCategory = this.getField('k3');

    const correspondObjectGcp =
      this.findCorrespondObject(gpcSelectOptions, this.state.gpc) ||
      this.findCorrespondObject(
        gpcSelectOptions,
        getDefaultToObj(fieldGPC, 'categoryId'),
      );
    const correspondObjectTnved =
      this.findCorrespondObject(tnvedSelectOptions, this.state.tnved) ||
      this.findCorrespondObject(
        tnvedSelectOptions,
        getDefaultToObj(fieldTNVD, 'categoryId'),
      );

    const goodsErrors = _.filter(moderateErrors, { type: 'goods' });
    const goodsTnvedErrors = _.find(
      goodsErrors,
      (item) => item.data.classifier === 'tnved',
    );
    const goodsGpcErrors = _.find(
      goodsErrors,
      (item) => item.data.classifier === 'gpc',
    );

    return (
      <CompanyBlock>
        {this.state.showPopupSearch && (
          <SearchParameters
            handleClose={this.closePopupSearch}
            chooseCertificate={this.chooseCertificate}
            value={this.state.popupElement.value}
            categoryId={_.get(fieldCategory, 'categoryId')}
          />
        )}
        <AdresTitle>
          {productData.isExemplar ? (
            <PackageTitleHolder style={{ paddingTop: '25px' }}>
              <MuiThemeProvider theme={ProductTabBtn}>
                <Button onClick={this.onClickHide}>
                  {this.state.contentShow ? (
                    <IconDropDownGrey />
                  ) : (
                    <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                  )}
                </Button>
                <AdresTitleH3>
                  {this.returnPackageType(productData.packingPurpose)}
                  <img
                    src="/images/isExemplarIcon.png"
                    alt=""
                    style={{ marginLeft: '10px' }}
                    height={32}
                  />
                </AdresTitleH3>
              </MuiThemeProvider>
            </PackageTitleHolder>
          ) : (
            <PackageTitleHolder>
              <MuiThemeProvider theme={ProductTabBtn}>
                <Button onClick={this.onClickHide}>
                  {this.state.contentShow ? (
                    <IconDropDownGrey />
                  ) : (
                    <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                  )}
                </Button>
                <AdresTitleH3>
                  {this.returnPackageType(productData.packingPurpose)}
                </AdresTitleH3>
              </MuiThemeProvider>
            </PackageTitleHolder>
          )}
        </AdresTitle>

        <AdresFields>
          <div>
            <FieldTitle>{t('Национальная классификация товара')}</FieldTitle>
            <CustomInput
              name="categoryName"
              disabled={
                preview ? preview : fieldCategory && fieldCategory.isDisabled
              }
              label={t('Код государственного классификатора')}
              value={getDefaultTo(fieldCategory, 'categoryName')}
              type="text"
            />
          </div>
          <div>
            <FieldTitle>{t('Международная классификация товара')}</FieldTitle>
            <FormControl fullWidth={true}>
              <MuiThemeProvider theme={CurrentInputTnvedGpc}>
                {goodsTnvedErrors && (
                  <MuiThemeProvider
                    theme={
                      this.state.bottom
                        ? TextTooltipModeration
                        : TextTooltipModerationUpper
                    }>
                    <Tooltip
                      interactive
                      id={`tooltip_moderate_tnvedField`}
                      onOpen={this.handleOpen(`tooltip_moderate_tnvedField}`)}
                      placement="bottom-end"
                      title={goodsTnvedErrors.data.comment}>
                      <ModerationIconHolder>
                        <IconError />
                      </ModerationIconHolder>
                    </Tooltip>
                  </MuiThemeProvider>
                )}
                <Autocomplete
                  id={'tnvedField'}
                  options={tnvedSelectOptions}
                  autoHighlight
                  onChange={(event, item) =>
                    this.handleChangeTnved(item && item.categoryId)
                  }
                  getOptionLabel={(option) =>
                    _.toString(option.categoryName) || ''
                  }
                  value={
                    this.findCorrespondObject(
                      tnvedSelectOptions,
                      this.state.tnved,
                    ) ||
                    this.findCorrespondObject(
                      tnvedSelectOptions,
                      getDefaultToObj(fieldTNVD, 'categoryId'),
                    ) ||
                    {}
                  }
                  popupIcon={<IconDropDownAuto />}
                  //disabled={status !== 'draft' && (preview || wasPublished)}
                  //disabled={((productData.status === 'draft' || productData.status === 'errors') && wasPublished && fieldTNVD) || (preview)}
                  disabled={
                    preview ? preview : fieldTNVD && fieldTNVD.isDisabled
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('ТНВЭД')}
                      variant="standard"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      required={!correspondObjectTnved || !!goodsTnvedErrors}
                      className={cx({
                        'valid-textfield': correspondObjectTnved,
                      })}
                      error={!!goodsTnvedErrors}
                    />
                  )}
                  noOptionsText={t('Нет совпадений')}
                />
              </MuiThemeProvider>
            </FormControl>
            <FormControl fullWidth={true}>
              <MuiThemeProvider theme={CurrentInputTnvedGpc}>
                {goodsGpcErrors && (
                  <MuiThemeProvider
                    theme={
                      this.state.bottom
                        ? TextTooltipModeration
                        : TextTooltipModerationUpper
                    }>
                    <Tooltip
                      interactive
                      id={`tooltip_moderate_gpcField`}
                      onOpen={this.handleOpen(`tooltip_moderate_gpcField}`)}
                      placement="bottom-end"
                      title={goodsGpcErrors.data.comment}>
                      <ModerationIconHolder>
                        <IconError />
                      </ModerationIconHolder>
                    </Tooltip>
                  </MuiThemeProvider>
                )}
                <Autocomplete
                  id={'gpcField'}
                  options={gpcSelectOptions}
                  autoHighlight
                  onChange={(event, item) =>
                    this.handleChangeGpc(item && item.categoryId)
                  }
                  getOptionLabel={(option) =>
                    _.toString(option.categoryName) || ''
                  }
                  value={
                    this.findCorrespondObject(
                      gpcSelectOptions,
                      this.state.gpc,
                    ) ||
                    this.findCorrespondObject(
                      gpcSelectOptions,
                      getDefaultToObj(fieldGPC, 'categoryId'),
                    ) ||
                    {}
                  }
                  popupIcon={<IconDropDownAuto />}
                  //disabled={status !== 'draft' && (preview || wasPublished)}
                  //disabled={((productData.status === 'draft' || productData.status === 'errors') && wasPublished && fieldGPC) || (preview)}
                  disabled={preview ? preview : fieldGPC && fieldGPC.isDisabled}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Код GPC')}
                      variant="standard"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'off',
                      }}
                      required={!correspondObjectGcp || !!goodsGpcErrors}
                      className={cx({ 'valid-textfield': correspondObjectGcp })}
                      error={!!goodsGpcErrors}
                    />
                  )}
                  noOptionsText={t('Нет совпадений')}
                />
              </MuiThemeProvider>
            </FormControl>
          </div>

          <div style={{ display: this.state.contentShow ? 'block' : 'none' }}>
            {fields}
          </div>
        </AdresFields>
      </CompanyBlock>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
  tnvedSelectOptions: state.productReducer.tnvedSelectOptions,
  gpcSelectOptions: state.productReducer.gpcSelectOptions,
  currentLocale: state.profileReducer.locale,
  gpcWasLoaded: state.productReducer.gpcSelectOptionsTriedToLoad,
  tnvedWasLoaded: state.productReducer.tnvedSelectOptionsTriedToLoad,
  tnvedArrayForMarking: state.productReducer.tnvedArrayForMarking,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSuggestions: (url, value, cbk) => getSuggestions(url, value, cbk),
      loadDictionaryGpcConsumerPackage: (data) =>
        loadDictionaryGpcConsumerPackage(data),
      loadDictionaryTnvedConsumerPackage: (data) =>
        loadDictionaryTnvedConsumerPackage(data),
      clearGpcAndTnvedSelectOptions: () => clearGpcAndTnvedSelectOptions(),
      checkTnvedForMarking: (data) => checkTnvedForMarking(data),
      clearTnvedArrayForMarking: () => clearTnvedArrayForMarking(),
    },
    dispatch,
  );
};

ConsumerPackage.propTypes = {
  updateData: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  loadDictionaryGpcConsumerPackage: PropTypes.func.isRequired,
  loadDictionaryTnvedConsumerPackage: PropTypes.func.isRequired,
  clearGpcAndTnvedSelectOptions: PropTypes.func.isRequired,
  clearTnvedArrayForMarking: PropTypes.func.isRequired,
  handleErrors: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  checkTnvedForMarking: PropTypes.func.isRequired,
  attrGroup: PropTypes.array,
  productData: PropTypes.object,
  moderateErrors: PropTypes.array.isRequired,
  tnvedSelectOptions: PropTypes.array.isRequired,
  gpcSelectOptions: PropTypes.array.isRequired,
  preview: PropTypes.bool.isRequired,
  tnvedArrayForMarking: PropTypes.array.isRequired,
  status: PropTypes.string,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ConsumerPackage);
