import styled from 'styled-components';

export const AutoInfoIcon = styled.span`
  height: 20px;
  min-width: 20px;
  padding: 0 5px;
`;
export const AutoInfoIconHolder = styled.div`
  padding-top: 3px;
`;
