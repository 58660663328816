import _ from 'lodash';
import { Statuses, UserStatuses } from '../constants/Statuses';

export const getSortField = function (sortFields) {
  return _.find(sortFields, (sortField) => {
    return sortField.direction !== '';
  });
};

const _findStatus = (obj, status) => {
  let st = _.find(obj, { value: status });
  if (_.isUndefined(st)) {
    st = {
      id: 999,
      title: `Статус «${status}» не определен`,
      value: '',
      style: { color: 'red', icon: '!' },
    };
  }
  return st;
};

export const findStatusObj = (status) => _findStatus(Statuses, status);
export const findUserStatusObj = (status) => _findStatus(UserStatuses, status);

export const insertParam = (url, key, value) => {
  key = encodeURI(key);
  value = encodeURI(value);
  let kvp = url.split('&');
  let i = kvp.length;
  let x;
  while (i--) {
    x = kvp[i].split('=');
    if (x[0] === key) {
      x[1] = value;
      kvp[i] = x.join('=');
      break;
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join('=');
  }
  return kvp.join(kvp[0].includes('?') ? '&' : '?');
};

export const difference = (object, base) => {
  const changes = (object, base) => {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObjectLike(value) && _.isObjectLike(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  };
  return changes(object, base);
};

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

export const gtinValidLength = (gtin) => {
  return _.includes([8, 12, 13], _.size(_.toString(gtin)));
};

export const packageGtinValidLength = (gtin) => {
  return _.includes([8, 12, 13], _.size(_.toString(gtin))); // && _.toNumber(gtin[0]) !== 0
};

export const gtinValidationForSearch = (gtin) => {
  return _.includes([8, 12, 13], _.size(_.toString(gtin)));
};

export const gtinDisplay = (gtin) => {
  let value = _.trim(gtin);
  if (_.isEmpty(value)) return value;
  if (value[0] === '0') return value;
  if (_.includes([13, 12, 8], _.size(value))) {
    value = value.padStart(14, '0');
  }
  return value;
};

export const packageGtinDisplay = (gtin) => {
  let value = _.trim(gtin);
  if (_.isEmpty(value)) return value;
  if (value[0] === '0') return value;
  if (_.includes([13, 12, 8], _.size(value))) {
    value = value.padStart(14, '0');
  }
  return value;
};

export const subtractNotNegative = (n1, n2 = 1) => {
  const s = n1 - n2;
  return s < 0 ? 0 : s;
};

export const nameAvatarStr = (name) => {
  const arr = name.split(' ');
  let str = '';
  arr.forEach((i, k) => {
    if (k > 1) return;
    str += i.substr(0, 1);
  });
  return str;
};

export const namesItems = (data, ids) => {
  const namesArray = _.reduce(
    data,
    (result, value) => {
      if (_.includes(ids, value.id)) result.push(`«${value.name}»`);
      return result;
    },
    [],
  );
  return _.join(namesArray, ', ');
};

export const clippedValue = (value = '') => {
  return isNaN(+value[0]) ? value : value.split(' ')[0] + '...';
};

export const clippedString = (value, quantity) => {
  return value.length > quantity ? value.slice(0, quantity) + '...' : value;
};
