import * as PropTypes from 'prop-types';
import * as React from 'react';
import cx from 'classnames';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import Scrollspy from 'react-scrollspy';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import { NotTitle } from '../Header/Header.styled';
import { IconClose, IconPreloader } from '../../../icons/customIcons';
import {
  loadNotification,
  markAsReadNotification,
} from '../../../catalog/Catalog.action';
import { ChartContInner } from '../../../styles/Common.styled';
import {
  NotAllDialog,
  NotDialogBlockMin,
  NotDialogContent,
  NotDialogTitle,
  NotifLoader,
  NotifMonth,
  NotNoData,
} from './NotificationModal.styled';
import { config, selectLang } from '../../../../config';

class NotificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().toISOString(true),
      dateEnd: moment().toISOString(true),
      error: false,
      isEnd: false,
      isLoading: false,
    };
    const tz = moment.tz.guess();
    moment.locale(selectLang(config.lang));
    moment.tz.setDefault(tz);
  }

  componentDidMount() {
    this.uploadNotification();
  }

  onScroll = (event) => {
    const { error, isLoading, isEnd, dateEnd } = this.state;
    if (error || isLoading || isEnd) return;
    if (
      event.target.scrollTop + event.target.clientHeight >=
      event.target.scrollHeight
    ) {
      this.markAsRead();
      this.uploadNotification(dateEnd);
    }
  };

  markAsRead = () => {
    const unReadIds = _.map(
      _.filter(this.props.items, { isRead: false }),
      'id',
    );
    if (!_.isEmpty(unReadIds)) {
      this.props.markAsReadNotification(unReadIds);
    }
  };

  uploadNotification = (date) => {
    this.setState({ isLoading: true }, () => {
      const prevMonths = moment(date || this.state.date);
      const dateBegin = prevMonths.toISOString(true);
      const dateEnd = prevMonths.subtract(1, 'months').toISOString(true);
      this.props.loadNotifications(
        dateBegin,
        dateEnd,
        !date,
        (error, isEnd) => {
          const state = error ? { error: true } : { dateEnd: dateEnd };
          this.setState({ ...state, isLoading: false, isEnd: isEnd });
        },
      );
    });
  };

  buildItems = (items) => {
    const splitOnBlocks = _.groupBy(items, 'sectionName');
    const jsxElements = [];
    let index = 0;

    _.forEach(splitOnBlocks, (block) => {
      let jsxBlocks = [];
      _.forEach(block, (item) => {
        jsxBlocks.push(
          <NotDialogBlockMin
            className={cx({ bordered: !item.isRead })}
            key={`notif_${index}`}>
            <NotTitle>
              {item.section} • <Moment format="DD.MM.YYYY">{item.date}</Moment>
            </NotTitle>
            <NotDialogContent dangerouslySetInnerHTML={{ __html: item.text }} />
          </NotDialogBlockMin>,
        );
        index++;
      });

      jsxElements.push(
        block[0].sectionName ? (
          <section key={block[0].sectionName} id={block[0].sectionName}>
            {jsxBlocks}
          </section>
        ) : (
          <div key={index}>{jsxBlocks}</div>
        ),
      );
    });
    return jsxElements;
  };

  render() {
    const { t } = this.props;
    const keysOnBlocks = _.keys(_.groupBy(this.props.items, 'sectionName'));

    return (
      <MuiThemeProvider theme={NotAllDialog}>
        <Dialog open>
          <DialogTitle disableTypography={true} id="alert-dialog-title">
            <Button color={'primary'} id="clean-btn" onClick={this.markAsRead}>
              {t('Отметить как прочитанные')}
            </Button>
            <NotDialogTitle>{t('Уведомления')}</NotDialogTitle>
            <Button
              className="backEmpty"
              id="dismiss-btn-close"
              onClick={this.props.handleClose}>
              <IconClose />
            </Button>
            <NotifMonth id="tooltip" />
            <Scrollspy
              rootEl="#dialog"
              items={keysOnBlocks}
              onUpdate={(el) => {
                const name = _.get(el, 'id') || '';
                const title = keysOnBlocks[0] === name ? '' : name;
                const tooltipEl = document.getElementById('tooltip');
                tooltipEl.style.opacity = !!title ? 1 : 0;
                tooltipEl.innerHTML = title;
              }}
            />
          </DialogTitle>

          <DialogContent id={'dialog'}
            onScroll={this.onScroll}
            onClick={this.props.exportIfReport}
            onContextMenu={this.props.cancelReportLink}
          >
            {_.isEmpty(this.props.items) && (
              <ChartContInner>
                <NotNoData>{t('У вас пока нет уведомлений')}</NotNoData>
              </ChartContInner>
            )}
            {this.buildItems(this.props.items)}
            {this.state.isLoading && (
              <NotifLoader>
                <IconPreloader />
              </NotifLoader>
            )}
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

NotificationModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  loadNotifications: PropTypes.func.isRequired,
  markAsReadNotification: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  exportIfReport: PropTypes.func.isRequired,
  cancelReportLink: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  items: state.catalogReducer.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadNotifications: (dateBegin, dateEnd, firstLoad, cbk) =>
        loadNotification(dateBegin, dateEnd, firstLoad, cbk),
      markAsReadNotification: (ids) => markAsReadNotification(ids),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationModal);
