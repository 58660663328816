import React from 'react';
import { Link } from 'react-router-dom';
import {
  ErrorBlock,
  ErrorBlockLeft,
  ErrorBlockRight,
  ErrorCode,
  ErrorContainer,
  ErrorLink,
  ErrorTitle,
} from '../Errors.styled';
import { Error404 } from '../../../common/icons/customIcons';

export default function Page404() {
  return (
    <ErrorContainer>
      <ErrorBlock>
        <ErrorBlockLeft>
          <ErrorTitle>
            <ErrorCode id="code-404">404</ErrorCode>
            СТРАНИЦА НЕ НАЙДЕНА
          </ErrorTitle>
          К сожалению, запрошенной вами страницы
          <br />
          не существует.
          <ErrorLink>
            Попробуйте <Link to="/">вернуться на главную</Link>
          </ErrorLink>
        </ErrorBlockLeft>
        <ErrorBlockRight>
          <Error404 />
        </ErrorBlockRight>
      </ErrorBlock>
    </ErrorContainer>
  );
}
