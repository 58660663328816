export const loading = (state) => state.profileStatusReducer.loading;
export const data = (state) => state.profileStatusReducer.data;
export const totalObjects = (state) => state.profileStatusReducer.totalObjects;
export const glnDictionary = (state) =>
  state.profileStatusReducer.glnDictionary;
export const isMainAccountUser = (state) =>
  state.authentificationReducer.user.isMainAccountUser;
export const company = (state) => state.profileReducer.info.company;
export const statisticAndOptions = (state) =>
  state.profileStatusReducer.statisticAndOptions;
