import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import colors from './Common.colors';
/*Styled components*/
export const NonMarkedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
`;

export const MarkedLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  color: red;
  font-size: 14px;
  font-weight: 400;
`;
export const BlocksDivider = styled.div`
  margin-top: 40px;
`;
export const Header = styled.div`
  min-height: 430px;
  padding: 6.5% 0 115px 0;
  background: ${colors.Home.background};
  text-align: center;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 1024px) {
    padding: 40px 0;
    min-height: 365px;
    span {
      transform: scale(0.6) translateX(-40px);
      display: inline-block;
    }
  }
`;
export const HeaderLangRow = styled.div`
  display: flex;
  padding: 20px 70px 0 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;
export const Footer = styled.div`
  text-align: center;
  font-size: 16px;
  color: #52535a;
  font-weight: 600;
  padding: 45px 0;
  a {
    color: #4aa5e8;
  }
  @media (max-width: 1024px) {
    padding: 45px 20px;
  }
`;
export const StyledH1 = styled.h1`
  font-size: 32px;
  color: ${colors.Home.title};
  text-transform: uppercase;
  margin: 75px 0 0 0;
  line-height: 43px;
  text-align: center;
  @media (max-width: 1024px) {
    margin: 25px 0 0 0;
    padding: 0 20px;
    font-size: 25px;
  }
`;
export const Content = styled.div``;
export const TabsCont = styled.div`
  padding: 35px 110px 0;
  border-bottom: 1px solid #f2f2f2;
  @media (max-width: 1024px) {
    padding: 24px 10px 0px;
    display: flex;
    justify-content: space-around;
  }
`;
export const SelectContLogin = styled.div`
  padding: 40px 110px;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding: 40px 80px;
  }
  @media (max-width: 700px) {
    padding: 40px 20px;
  }
`;
export const LoginFormCont = styled.div`
  width: 1180px;
  box-shadow: 0px 4px 10px rgba(41, 49, 53, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -70px;
  background: #fff;
  position: relative;
  @media (max-width: 1440px) {
    width: 860px;
  }
  @media (max-width: 1024px) {
    width: 620px;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
`;
export const LoginFormContAuth = styled(LoginFormCont)`
  width: 860px;
  box-shadow: 0px 4px 10px rgba(41, 49, 53, 0.1);
  border-radius: 6px;
  margin: 0 auto;
  margin-top: -70px;
  background: #fff;
  @media (max-width: 1440px) {
    width: 860px;
  }
  @media (max-width: 1024px) {
    width: 620px;
  }
  @media (max-width: 700px) {
    width: 90%;
  }
`;
export const SelectOption = styled.option`
  font-size: 14px;
  color: #52535a;
  padding: 15px 20px;
  width: 100%;
`;
export const GridActions = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: flex-end;
`;
export const GridActionsPie = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  justify-content: flex-start;
  flex-grow: 1;
`;
export const GridActionsLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
`;
export const GridActionsRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
`;
export const CountCont = styled.div`
  font-size: 12px;
  color: #b8b8b8;
  font-weight: 600;
  flex-shrink: 0;
`;
export const PageGridRight = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
export const CompanyBlock = styled.div`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 40px 0;
`;
export const SmallEl = styled.span`
  color: #ff4b4b;
`;
export const ChartContInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transform: translate(0);
  background: rgba(255, 255, 255, 1);
  transition: all 0.3s ease;
  z-index: 2;
  font-weight: 600;
  font-size: 20px;
  color: #63666a;
`;
export const SelectedCertMin = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  span {
    display: block;
    white-space: normal;
    word-break: break-word;
  }
`;
export const InputContainer = styled.div`
  :nth-of-type(1) {
    margin-bottom: 4px;
  }
  position: relative;
`;
export const InputContainerMail = styled(InputContainer)`
  :nth-of-type(1) {
    margin-bottom: 0;
  }
  display: flex;
`;
export const InputIcon = styled.span`
  position: absolute;
  top: 35px;
  right: 12px;
  transform: translateY(-3px);
`;
export const AddressInputIcon = styled.span`
  position: absolute;
  top: 34%;
  right: 5px;
  transform: translateY(-3px);
`;
export const InputIconLeft = styled.span`
  position: absolute;
  top: 50%;
  right: 73px;
  transform: translateY(-2px);
`;
export const SelectIconLeft = styled.span`
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-2px);
`;
export const BtnContPaddingless = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  @media (max-width: 1024px) {
    padding: 40px 0 0;
    flex-direction: row;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;
export const MoreIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 19px;
`;
export const MoreIconAuto = styled.div`
  align-self: flex-end;
  transform: translateY(2px);
`;
/*Таблица*/
export const ColumnHeader = styled.div`
  display: flex;
  min-height: 20px;
  padding: 20px 8px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
`;
export const ColumnHeaderProfile = styled(ColumnHeader)`
  padding: 23px 20px;
  white-space: ${props => props.small ? "pre-wrap" : "nowrap"};
`;
export const ColumnHeaderPhoto = styled(ColumnHeader)`
  padding: 20px 8px;
`;
export const ColumnHeaderIsValid = styled(ColumnHeader)`
  padding: 20px 3px;
`;
export const ColumnCellInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const ColumnCellCheck = styled.div`
  padding: 0 10px;
  text-align: center;
  width: 100%;
  height: 100%;
`;
export const ColumnCellCheckHeader = styled(ColumnCellCheck)`
  padding: 0 10px 0 12px;
`;
export const ColumnCellCheckInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const GridTableContainer = styled.div`
  height: calc(100% - 100px);
  overflow: hidden;

  .table-grid--list {
    height: 100%;
  }

  .ReactTable {
    max-height: 100%;
    height: 100%;
    border: none;
    // min-height: calc(100vh - 183px);
    // @media (min-width: 1920px) {
    //   min-height: calc(100vh - 209px);
    // }
  }
  &.customTable {
    .ReactTable {
      border: none;
    }
  }
  &.customTable.customBorderTable {          
    .rt-tr-group {
      border-left: 1px solid #f2f2f2; 
      border-right: 1px solid #f2f2f2; 
    } 
    .rt-table {    
      max-height: ${window.innerHeight - 264 >= 700 ? '655px' : 'calc(100vh - 309px)'};
      overflow-x: hidden;
      scrollbar-width: thin;      
    }   
  }
  .ReactTable .rt-table {
    overflow-x: auto;
    height: 100%;
    flex: initial !important;
    min-height: ${state => state.noData ? '400px' : 'auto'};
  }

  .ReactTable .rt-tbody {
    // overflow: visible !important;
    overflow-x: visible;
    overflow-y: visible;
    
    // max-height: ${props => props.height};
    // min-height: ${props => props.minHeight};
  }

  .ReactTable .rt-thead {
    max-height: 64px;
    position: sticky;
    z-index: 12;
  }
  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none !important;
    flex: 0 0 auto;
  }
  .ReactTable .rt-tr {
    background: #0187b0 !important;
    height: 63px;
  }
  .ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 0;
    top: 63px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 119px);
    transform: translate(0);
    background: rgba(255, 255, 255, 1);
    transition: all 0.3s ease;
    z-index: 2;
    pointer-events: auto;
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    color: #52535a;
    button {
      padding: 0;
      background: none;
      box-shadow: none;
      color: #4aa5e8;
      font-size: 16px;
      text-transform: none;
      margin-top: 10px;
      font-weight: 600;
      display: inline-block;
      margin: 0;
      line-height: 3px;
      vertical-align: baseline;
      min-width: auto;
    }
  }
  .ReactTable .rt-thead {
    box-shadow: none !important;
    border-bottom: 1px solid #f2f2f2;
  }
  .ReactTable .rt-thead .rt-th {
    border-bottom: none;
    border-right: 1px solid #f2f2f2 !important;
    padding: 0;
    text-align: left;
    align-items: center;
    display: flex;
    font-weight: 500;
    overflow: visible;
    &:nth-last-of-type(1) {
      padding: 0;
    }
    &.rthfc-th-fixed-left {
      position: sticky !important;
      z-index: 10;
      background: #0187b0;
    }
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid #f2f2f2 !important;
    border-bottom: 1px solid #f2f2f2 !important;
    padding: 0;
    text-align: left;
    background: #fff;
    transition: all 0.4s ease;
    font-size: 14px;
    word-break: break-word;
    white-space: normal;
    &:nth-last-of-type(1) {
      padding: 0;
      overflow: visible;
    }
    &.rthfc-td-fixed-left {
      position: sticky !important;
      z-index: 10;
      background: #fff;
      left: 0;
    }
    &.rthfc-td-fixed-right {
      position: sticky !important;
      z-index: 10;
      background: #fff;
      right: 0;
    }
  }
  .ReactTable .rt-tbody .rt-tr {
    position: relative;
  }
  .ReactTable .rt-tbody .rt-tr:hover .rt-td {
    background: #f9f9f9;
  }
  .ReactTable .rt-tbody .rt-tr:hover button {
    opacity: 1;
  }
`;
export const AdresTitle = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  padding-right: 50px;
  flex-direction: column;
`;
export const AdresTitleH3 = styled.h3`
  font-size: 13px;
  font-weight: 600;
  margin: 5px 0 0;
  text-transform: uppercase;
`;
export const AdresFields = styled.div`
  width: 80%;
`;
/*Грид*/
export const GridBlock = styled.div`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 3px 20px;
  display: grid;
`;
export const GridBlockA = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridBlockB = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridBlockC = styled(GridBlock)`
  grid-template-columns: [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr [col] 1fr;
  grid-template-rows: auto auto;
`;
export const GridItemA = styled.div`
  grid-column: col / span 2;
`;
export const GridItemC = styled.div`
  grid-column: col 2 / span 2;
`;
export const GridItemB = styled.div`
  grid-column: col 3 / span 2;
`;
export const GridItemD = styled.div`
  grid-column: col 5 / span 2;
`;
export const GridItemSmall = styled.div`
  grid-column: col;
`;
export const GridItemSmallA = styled.div`
  grid-column: col 2;
`;
export const GridItemSmallB = styled.div`
  grid-column: col 3;
`;
export const GridItemSmallC = styled.div`
  grid-column: col 4;
`;
export const GridItemSmallD = styled.div`
  grid-column: col 5;
`;
/*Окна*/
export const DialogTitleStyled = styled.div`
  font-size: 20px;
  color: #63666a;
  margin: 0;
  font-weight: 600;
`;

export const DialogTitleOfContentStyled = styled.div`
  font-size: 16px;
  color: #63666a;
  margin: 0;
  font-weight: 600;
`;

export const DialogTitleOfContentStyledWithMargin = styled.div`
  font-size: 16px;
  color: #63666a;
  margin: 25px 0 0 0;
  font-weight: 600;
`;
export const DialogTitlePhotoStyled = styled.div`
  font-size: 20px;
  color: #63666a;
  margin: 0 auto;
  font-weight: 600;
`;
export const DialogTitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 36px;
  .backChanges {
    position: absolute;
    top: 0;
    left: 0;
  }
  .backEmpty {
    background: none;
    border: none;
  }
`;
export const ButtonActionCont = styled.div`
  position: absolute;
  width: 30px;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  button {
    background: transparent;
    width: 30px;
    border-radius: 0;
    border-left: 1px solid #f2f2f2;
    box-shadow: none;
    opacity: 0;
    transition: all 0.4s ease;
    min-width: 30px;
    padding: 0;
    &:hover {
      box-shadow: -10px 0 25px -2px rgba(242, 242, 242, 0.9);
      background: #fff;
      opacity: 1;
      border-left: 1px solid transparent;
    }
  }
`;
export const NoDataInner = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  color: #63666a;
  margin: auto;
`;
export const NoDataIconHolder = styled.div`
  display: inline-block;
  margin-bottom: 45px;
`;
export const StatusLine = styled.span`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    padding-left: 6px;
    font-weight: 600;
  }
`;
export const GridEmpty = styled.div`
  padding: 20px;
  text-align: center;
  line-height: 1.5;
  display: flex;
  align-items: center;
  height: calc(100vh - 190px);
  flex-direction: column;
  justify-content: center;
`;
export const GridEmptyText = styled.div`
  font-size: 20px;
  color: #52535a;
  text-align: center;
  line-height: 1;
  font-weight: 600;
  a {
    color: #4aa5e8;
    text-decoration: none;
  }
`;
export const DialogIcon = styled.div`
  margin-bottom: 18px;
`;
export const DialogText = styled.div`
  font-size: 20px;
  color: #52535a;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
`;
/*Кнопки*/
export const SortingBtn = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 27px;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  &:hover {
    color: #fff;
    box-shadow: inset 0 -10px 20px -11px rgba(242, 242, 242, 1);
  }
`;
export const WithoutSortingBtn = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //padding-left: 11px;
  right: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  &:hover {
    color: #fff;
    box-shadow: inset 0 -10px 20px -11px rgba(242, 242, 242, 1);
  }
`;

export const WithoutSortingTitle = styled.span`
  position: absolute;
  left: ${(props) => props.left};
`;
export const StickyBtns = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 5px;
  bottom: 200px;
  align-items: flex-end;
`;
export const ProductViewMdiaItem = styled.div`
  padding: 15px 3px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  max-width: 110px;
  margin: 0 20px 10px 0;
  cursor: pointer;
  text-align: center;
  img {
    width: 100%;
  }
`;
export const ProfileContainer = styled.div`
  padding: 0 20px;

  height: calc(100% - 90px);
`;

export const PackageTitleHolder = styled.div`
  position: relative;
  padding-top: 45px;
`;
export const CustomPagination = styled.div`
  position: relative;
  padding-top: 45px;
  .Table__pageButton--active {
    color: ${colors.Grid.paginationColor};
    font-weight: bold;
    background-color: ${colors.Grid.paginationBackground};
    border-radius: 3px;
  }
`;
export const FieldIconsHolder = styled.div`
    position: absolute;
    top: 15px;
    right: 35px;
    height: calc(100% - 15px);
    display: flex;
    align-items: center;
}
`;
export const LangMenuIcon = styled.span`
  margin-right: 25px;
  flex-shrink: 0;
`;
export const LangMenuIconHome = styled(LangMenuIcon)`
  margin-right: 4px;
`;
export const HomeLangSelect = styled.div`
  max-width: 93px;
  background: #fff;
  border-radius: 4px;
`;
export const ProductsGridContainer = styled.div`
  padding: 20px;
  background: #fcfcfc;
  position: relative;
  

  @media (max-width: 1919px) {
    height: calc(100% - 70px);
  }

  @media (min-width: 600px) {
    height: calc(100% - 64px);
  }
`;
export const ManagementSiteCont = styled(ProductsGridContainer)`
  padding: 0;
  background: none;
  height: ${props => props.big ? "calc(100% - 64px)" : "calc(100% - 100px)"};
  .ReactTable .Table__pagination {
    border-top: 1px solid #f2f2f2;
  }
`;
export const ManagementSiteActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px;
  border-bottom: 1px solid #f2f2f2;
`;
export const TableEmpty = styled(GridEmpty)`
  height: calc(100vh - 325px);
`;
export const StatusBlock = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  .icon {
    margin-right: 13px;
  }
  &.valid {
    color: #2fa93b;
  }
  &.invalid {
    color: #ff4b4b;
  }
`;
/*Material themes*/
export const MainDefaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        border: 'none',
        fontSize: '14px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        textTransform: 'uppercase',
        borderRadius: '3px',
        height: 'auto',
        width: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        fontWeight: 'bold',
        boxShadow: `0px 15px 35px ${colors.Buttons.buttonPrimary.boxShadow}`,
        transition: 'background-color 0.4s ease',
        '&:hover': {
          boxShadow: `0px 10px 30px ${colors.Buttons.buttonPrimary.boxShadowHovered}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          opacity: '0.8',
          color: `${colors.Buttons.buttonPrimary.color}`,
        },
        '&$disabled': {
          boxShadow: 'none',
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textPrimary: {
        color: `${colors.Buttons.buttonPrimary.color}`,
        '&:hover': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        },
      },
      textSecondary: {
        color: '#52535A',
        border: '1px solid #868686',
        backgroundColor: '#fff',
        boxShadow: 'none',
        marginLeft: '20px',
        minWidth: '310px',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
          boxShadow: 'none',
        },
        '&$disabled': {
          color: '#52535A',
          opacity: '0.5',
        },
      },
      text: {
        padding: '20px 60px',
      },
      label: {
        lineHeight: '19px',
      },
      disabled: {},
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&:required': {
          borderLeft: '4px solid #FF4B4B',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '23px 42px 6px 19px',
        fontWeight: '600',
        lineHeight: '21px',
        '&:required': {
          borderLeft: '4px solid #FF4B4B',
          borderRadius: '3px',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      multiline: {
        padding: '0',
      },
      input: {
        color: '#52535A',
        fontWeight: '600',
        height: 'auto',
        fontFamily: 'SegoeUI',
      },
      root: {
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
        '&$disabled': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
      disabled: {},
    },
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
        marginBottom: '15px',
        color: '#52535A',
        fontFamily: 'SegoeUI',
        '&:nth-last-of-type(1)': {
          marginBottom: '0',
        },
        fontWeight: '600',
      },
    },
    // в случае чего вернуть как было.
    MuiTooltip: {
      // popper: {
      //     background: "#fff",
      //     width: "200px"
      // },
      popper: {
        background: '#4C4C4C',
        width: '200px',
        borderRadius: '20px',
      },
      tooltip: {
        fontSize: '12px',
        lineHeight: '16px',
        // color: "#FF4B4B",
        color: '#FFFFFF',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        // boxShadow: "0px 2px 15px rgba(0, 0, 0, .25)",
        padding: '10px',
        textAlign: 'center',
        position: 'relative',
        // '&::before': {
        //     content: '""',
        //     display: "block",
        //     width: "0",
        //     height: "0",
        //     borderWidth: "0 10px 17px 10px",
        //     borderColor: "transparent transparent #fff transparent",
        //     borderStyle: "solid",
        //     position: "absolute",
        //     top: "-31px",
        //     right: "30px"
        // }
      },
    },
    MuiSelect: {
      root: {
        width: '100%',
        borderRadius: '3px',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
      },
      select: {
        padding: '23px 55px 6px 19px',
      },
      selectMenu: {
        color: '#52535A',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
      //icon: {
      //top: "calc(50% - 10px)",
      //transform: "translateX(-10px)"
      //}
    },
    MuiMenu: {
      paper: {
        maxHeight: '300px!important',
        overflowY: 'auto',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '15px 20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiFormControl: {
      root: {
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
        },
      },
    },
  },
});
export const defaultTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '23px 47px 6px 19px',
        lineHeight: '21px',
        '&:required': {
          borderLeft: '4px solid #FF4B4B',
          borderRadius: '3px',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        height: 'auto',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        right: '-30px',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#52535',
        fontSize: '16px',
      },
    },
  },
});
export const defaultSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiSelect: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
        },
      },
      select: {
        padding: '23px 19px 6px',
      },
      selectMenu: {
        color: '#52535A',
        fontWeight: '600',
      },
      iconOpen: {
        transform: 'translate(-8px, 2px) rotate(180deg)',
      },
      disabled: {},
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        width: '100%',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9!important',
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
        color: '#868686',
      },
      shrink: {
        color: '#868686',
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '15px 20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        color: '#52535A',
        fontSize: '16px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
  },
});
export const ButtonDeleteTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        border: '1px solid #868686',
        borderRadius: '3px',
        height: '60px',
        width: '60px',
        fontFamily: 'SegoeUI',
        position: 'absolute',
        left: '-80px',
        top: '17px',
      },
      text: {
        padding: '15px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
export const ButtonAdormentTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        border: '1px solid #868686',
        borderRadius: '100px',
        height: '50px',
        minWidth: '50px',
        fontFamily: 'SegoeUI',
      },
      text: {
        padding: '15px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
export const CheckBoxDefault = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const CheckBoxGrid = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        '&:hover': {
          background: 'none',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        width: '16px',
        height: '16px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
      },
    },
  },
});
export const CheckBoxGridThead = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        '&:hover': {
          background: 'none',
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
          border: '2px solid white',
          borderRadius: '2px',
        },
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiButtonBase: {
      root: {
        width: '16px',
        height: '16px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
      },
      label: {
        marginLeft: '20px',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#fff',
      },
    },
  },
});
export const CheckBoxProfile = createMuiTheme({
  typography: {},
  overrides: {
    MuiCheckbox: {
      root: {
        color: '#D9D9D9',
        checked: {
          color: `${colors.Input.checkBox}`,
        },
      },
      colorSecondary: {
        '&$checked': {
          color: `${colors.Input.checkBox}`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        width: '20px',
        height: '20px',
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: '0',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '0',
        marginBottom: '15px',
      },
      label: {
        marginLeft: '20px',
        fontFamily: 'SegoeUI',
      },
    },
  },
});
export const DefaultTooltip = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        width: '200px',
        marginTop: '10px',
        marginRight: '-32px',
      },
      tooltip: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FF4B4B',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: '0px -3px 30px rgba(0, 0, 0, .25)',
        padding: '13px 20px',
        textAlign: 'center',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px',
          borderColor: 'transparent transparent #fff transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-15px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltip = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
        marginRight: '-30px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        background: 'none!important',
        opacity: '1!important',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipName = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        background: 'none!important',
        opacity: '1!important',
        transform: 'none!important',
        transition: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipArchive = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
        marginRight: '-38px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        background: 'none!important',
        opacity: '1!important',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipModeration = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        minWidth: '100px',
        marginTop: '40px',
        borderRadius: '4px',
        marginRight: '-47px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipUpper = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '10px',
        borderRadius: '4px',
        marginRight: '-30px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        opacity: '1!important',
        transform: 'none!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '17px 10px 0 10px',
          borderColor: 'rgba(0, 0, 0, .5) transparent transparent transparent',
          borderStyle: 'solid',
          position: 'absolute',
          bottom: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const TextTooltipModerationUpper = createMuiTheme({
  typography: {},
  overrides: {
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '15px',
        borderRadius: '4px',
        marginRight: '-47px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '17px 10px 0 10px',
          borderColor: 'rgba(0, 0, 0, .7) transparent transparent transparent',
          borderStyle: 'solid',
          position: 'absolute',
          bottom: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const NewCloseBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        width: '40px',
        height: '40px',
      },
      text: {
        padding: '3px',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const NewBackBtns = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '70px',
        height: '30px',
      },
      text: {
        padding: '0 3px 0 0',
        color: '#868686',
        fontSize: '14px',
        textTransform: 'uppercase',
      },
    },
  },
});
export const DefaultGridButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        color: '#4AA5E8',
        '&$disabled': {
          color: '#52535A',
          opacity: '.7',
        },
      },
      label: {
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'none',
      },
      disabled: {},
    },
  },
});
export const AdressBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        position: 'absolute',
        top: '0',
        left: '-35px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const ProductTabBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        position: 'absolute',
        top: '45px',
        left: '-35px',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const ProfileAutocomplete = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        marginTop: '15px!important',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      error: {},
      input: {
        padding: '23px 110px 6px 19px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&:required': {
          borderLeft: '4px solid #FF4B4B',
          borderRadius: '3px',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#63666A',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '10px 20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '500',
        whiteSpace: 'normal',
        lineHeight: '1.22',
        height: 'auto',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
      },
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        //position: "absolute",
        //top: "50%",
        //transform: "translateY(-50%)",
        //marginTop: "9px",
        //right: "35px",
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '3px',
      },
    },
  },
});
export const AddressButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      textPrimary: {
        background: '#868686',
        color: '#FFF',
        border: '1px solid #868686',
        '&:hover': {
          color: '#868686',
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        color: '#868686',
        background: '#FFF',
        border: '1px solid #868686',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: 'auto',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
      },
      text: {
        padding: '14px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '220px',
        marginTop: '20px',
        borderRadius: '4px',
        marginLeft: '-100px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        position: 'relative!important',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .7) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const ProductButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      textPrimary: {
        color: '#434244',
        background: '#FFF',
        border: '1px solid #868686',
        minWidth: '120px',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      textSecondary: {
        color: '#434244',
        background: '#FFF',
        border: '1px solid #868686',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
      root: {
        fontSize: '16px',
        textTransform: 'none',
        borderRadius: '3px',
        height: '50px',
        minWidth: '200px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: '600',
        marginTop: '17px',
      },
      text: {
        padding: '9px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .5) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
export const HomeLangSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiMenuItem: {
      root: {
        height: 'auto',
        padding: '10px 0',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 'bold',
        //borderTop: "1px solid #F2F2F2",
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    MuiSelect: {
      select: {
        padding: '10px 6px 10px 10px',
        color: '#63666A',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
        paddingRight: '25px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
        minWidth: 'auto',
        marginTop: '-1px',
      },
      elevation8: {
        boxShadow: 'none',
      },
    },
    MuiTypography: {
      root: {
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    MuiInput: {
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
  },
});
