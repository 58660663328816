import { Reducer } from 'redux';
import {
  CLEAR_GS1_DATA_ACTION,
  CLEAR_GS1_INFO_ACTION,
  LOAD_GS1_DATA_ACTION,
  LOAD_GS1_DATA_FAILED_ACTION,
  LOAD_GS1_DATA_SUCCEEDED_ACTION,
  LOAD_GS1_INFO_ACTION,
  LOAD_GS1_INFO_FAILED_ACTION,
  LOAD_GS1_INFO_SUCCEEDED_ACTION,
  SAVE_GS1_ADDRESSES_ACTION,
  SAVE_GS1_ADDRESSES_FAILED_ACTION,
  SAVE_GS1_ADDRESSES_SUCCEEDED_ACTION,
  SAVE_GS1_APPLICANT_ACTION,
  SAVE_GS1_APPLICANT_FAILED_ACTION,
  SAVE_GS1_APPLICANT_SUCCEEDED_ACTION,
  SAVE_GS1_BANKINGDETAILS_ACTION,
  SAVE_GS1_BANKINGDETAILS_FAILED_ACTION,
  SAVE_GS1_BANKINGDETAILS_SUCCEEDED_ACTION,
  SAVE_GS1_COMPANYINFORMATION_ACTION,
  SAVE_GS1_COMPANYINFORMATION_FAILED_ACTION,
  SAVE_GS1_COMPANYINFORMATION_SUCCEEDED_ACTION,
  SAVE_GS1_CONTACTS_ACTION,
  SAVE_GS1_CONTACTS_FAILED_ACTION,
  SAVE_GS1_CONTACTS_SUCCEEDED_ACTION,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED,
  SAVE_GS1_ACTION,
  SAVE_GS1_SUCCEEDED_ACTION,
  SAVE_GS1_FAILED_ACTION,
  LOAD_GS1_OPF_DICTIONARY_ACTION,
  LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED,
} from './GS1ActionTypes.constants';
import _ from 'lodash';
import { convertAddress } from '../../../../common/catalog/Catalog.utils';

const _initialState = {
  loader: false,
  loaderGs1Info: false,
  info: {
    // gcps: []// Это информация о членстве в GS1
  },
  data: {},
  originAddresses: {
    legalAddress: {},
    physicalAddress: {},
    productionAddresses: [],
  },
  activityTypesDictionary: [],
  companyTypesDictionary: [],
  mainProductTypesDictionary: [],
  gs1OpfDictionary: [],
};

export const gs1Reducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_GS1_OPF_DICTIONARY_ACTION:
    case SAVE_GS1_ACTION:
    case LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION:
    case LOAD_COMPANY_TYPES_DICTIONARY_ACTION:
    case LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION:
    case LOAD_GS1_INFO_ACTION: {
      return { ...state, loader: true, loaderGs1Info: true };
    }
    case LOAD_GS1_DATA_ACTION:
    case SAVE_GS1_APPLICANT_ACTION:
    case SAVE_GS1_BANKINGDETAILS_ACTION:
    case SAVE_GS1_ADDRESSES_ACTION:
    case SAVE_GS1_COMPANYINFORMATION_ACTION:
    case SAVE_GS1_CONTACTS_ACTION: {
      return { ...state, loader: true };
    }
    case LOAD_GS1_INFO_SUCCEEDED_ACTION: {
      return {
        ...state,
        info: action.info,
        loader: false,
        loaderGs1Info: false,
      };
    }
    case LOAD_GS1_INFO_FAILED_ACTION: {
      return { ...state, info: {}, loader: false, loaderGs1Info: false };
    }
    case LOAD_GS1_DATA_SUCCEEDED_ACTION: {
      let originAddresses = _.cloneDeep(state.originAddresses);
      if (!_.isNil(action.data.addresses)) {
        if (
          !_.isNil(state.originAddresses.legalAddress) &&
          _.isEmpty(state.originAddresses.legalAddress) &&
          !_.isNil(action.data.addresses.legalAddress) &&
          !_.isEmpty(action.data.addresses.legalAddress)
        ) {
          originAddresses.legalAddress = convertAddress(
            action.data.addresses.legalAddress,
            1,
            0,
          );
        }

        if (
          !_.isNil(state.originAddresses.physicalAddress) &&
          _.isEmpty(state.originAddresses.physicalAddress) &&
          !_.isNil(action.data.addresses.physicalAddress) &&
          !_.isEmpty(action.data.addresses.physicalAddress)
        ) {
          originAddresses.physicalAddress = convertAddress(
            action.data.addresses.physicalAddress,
            2,
            0,
          );
        }

        if (
          !_.isNil(state.originAddresses.productionAddresses) &&
          _.isEmpty(state.originAddresses.productionAddresses) &&
          !_.isNil(action.data.addresses.productionAddresses) &&
          !_.isEmpty(action.data.addresses.productionAddresses)
        ) {
          originAddresses.productionAddresses = action.data.addresses.productionAddresses.map(
            (address, index) => convertAddress(address, 3, index),
          );
        }
      }
      return {
        ...state,
        data: action.data,
        loader: false,
        originAddresses: originAddresses,
      };
    }
    case LOAD_GS1_DATA_FAILED_ACTION: {
      return { ...state, data: {}, loader: false };
    }
    case SAVE_GS1_APPLICANT_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          applicant: action.applicant,
        },
        loader: false,
      };
    }
    case SAVE_GS1_BANKINGDETAILS_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          bankDetails: action.bankDetails,
        },
        loader: false,
      };
    }
    case SAVE_GS1_ADDRESSES_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          addresses: action.addresses,
        },
        originAddresses: action.originAddresses,
        loader: false,
      };
    }
    case SAVE_GS1_COMPANYINFORMATION_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          companyDetails: action.companyDetails,
        },
        loader: false,
      };
    }
    case SAVE_GS1_CONTACTS_SUCCEEDED_ACTION: {
      return {
        ...state,
        data: {
          ...state.data,
          contacts: action.contacts,
        },
        loader: false,
      };
    }

    case SAVE_GS1_APPLICANT_FAILED_ACTION:
    case SAVE_GS1_BANKINGDETAILS_FAILED_ACTION:
    case SAVE_GS1_ADDRESSES_FAILED_ACTION:
    case SAVE_GS1_COMPANYINFORMATION_FAILED_ACTION:
    case SAVE_GS1_CONTACTS_FAILED_ACTION: {
      return { ...state, loader: false };
    }
    case CLEAR_GS1_DATA_ACTION: {
      return { ...state, data: {} };
    }
    case CLEAR_GS1_INFO_ACTION: {
      return { ...state, info: {} };
    }

    case LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED:
    case SAVE_GS1_FAILED_ACTION:
    case SAVE_GS1_SUCCEEDED_ACTION:
    case LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED:
    case LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED:
    case LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED: {
      return {
        ...state,
        loader: false,
        loaderGs1Info: false,
      };
    }

    case LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        loader: false,
        loaderGs1Info: false,
        activityTypesDictionary: action.data,
      };
    }

    case LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        loader: false,
        loaderGs1Info: false,
        companyTypesDictionary: action.data,
      };
    }

    case LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        loader: false,
        loaderGs1Info: false,
        mainProductTypesDictionary: action.data,
      };
    }

    case LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED: {
      return {
        ...state,
        loader: false,
        loaderGs1Info: false,
        gs1OpfDictionary: action.data,
      };
    }

    default:
      return { ...state };
  }
};
