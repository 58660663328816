import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const DefaultOrderPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '530px',
        maxWidth: '530px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        minWidth: '200px',
        transition: 'all 0.4s ease',
      },
      text: {
        fontWeight: '600',
        color: '#52535A',
        padding: '10px 40px',
        transition: 'all 0.4s ease',
        fontSize: '16px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '20px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#52535A',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        textTransform: 'capitalize',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
    },
    MuiCheckbox: {
      root: {
        minWidth: '40px',
        height: '40px',
        padding: '0',
        border: '1px solid #F2F2F2',
        borderRadius: '3px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px',
        margin: '0',
        justifyContent: 'flex-start',
        borderTop: '1px solid #F2F2F2',
      },
    },
    MuiDialogContentText: {
      root: {
        padding: '0 65px',
        fontSize: '20px;',
        color: '#52535A',
        textAlign: 'center',
      },
    },
  },
});
export const OrderBtns = styled.div`
  .backEmpty {
    background: none;
    border: 1px solid #868686;
    margin-left: 20px;
  }
`;
export const OrderNumber = styled.div`
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  margin-right: 30px;
`;
export const CheckCont = styled.div`
  margin-left: auto;
`;
export const DropIconCont = styled.span`
  padding: 0 10px 0 20px;
`;
