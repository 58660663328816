import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
`;

export const ColumnItem = styled.div`
  flex-direction: column;
  display: flex;
  padding: 40px 40px 80px 40px;
  min-width: 500px;
  max-width: 500px;
`;
export const ColumnItemLeft = styled.div`
  flex-direction: column;
  display: flex;
  padding: 40px 40px 0 40px;
  min-width: 500px;
`;

export const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  line-height: 21px;
  padding: 5px 0;

  color: ${({ color }) => color};
`;

export const DateItem = styled.div`
  color: #a9a9a9;
`;

export const ValueItem = styled.div`
  font-weight: bold;
`;
