import { ActionCreator } from 'redux';
import {
  // CHECK_UNIQUENESS_KPP_ACTION,
  CLEAR_GLN_DICTIONARY_ACTION,
  CREATE_PROFILE_STATUS_ACTION,
  DELETE_PROFILE_STATUS_ACTION,
  LOAD_GLN_DICTIONARY_ACTION,
  LOAD_GLN_DICTIONARY_FAILED_ACTION,
  LOAD_GLN_DICTIONARY_SUCCESSED_ACTION,
  LOAD_PROFILE_STATUS_ACTION,
  LOAD_PROFILE_STATUS_FAILED_ACTION,
  LOAD_PROFILE_STATUS_SUCCEEDED_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION,
  LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION,
  SAVE_OPTIONS_ACTION,
  SAVE_OPTIONS_SUCCEEDED_ACTION,
  SAVE_OPTIONS_FAILED_ACTION,
} from './ProfileStatus.constants';

export const loadProfileStatus: ActionCreator = () => ({
  type: LOAD_PROFILE_STATUS_ACTION,
});

export const loadProfileStatusSucceeded: ActionCreator = (
  data,
  totalObjects,
) => ({
  type: LOAD_PROFILE_STATUS_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadProfileStatusFailed: ActionCreator = () => ({
  type: LOAD_PROFILE_STATUS_FAILED_ACTION,
});

export const deleteProfileStatus: ActionCreator = (id, cbk = () => null) => ({
  type: DELETE_PROFILE_STATUS_ACTION,
  id: id,
  cbk: cbk,
});

// export const checkUniquenessKpp: ActionCreator = (kpp, cbk) => ({
//     type: CHECK_UNIQUENESS_KPP_ACTION,
//     kpp: kpp,
//     cbk: cbk
// });

export const createProfileStatus: ActionCreator = (
  // data,
  statusType,
  cbk = () => null,
) => ({
  type: CREATE_PROFILE_STATUS_ACTION,
  // data: data,
  statusType: statusType,
  cbk: cbk,
});

export const loadGlnDictionary: ActionCreator = () => ({
  type: LOAD_GLN_DICTIONARY_ACTION,
});

export const loadGlnDictionarySuccessed: ActionCreator = (data) => ({
  type: LOAD_GLN_DICTIONARY_SUCCESSED_ACTION,
  data: data,
});

export const loadGlnDictionaryFailed: ActionCreator = () => ({
  type: LOAD_GLN_DICTIONARY_FAILED_ACTION,
});

export const clearGlnDictionary: ActionCreator = () => ({
  type: CLEAR_GLN_DICTIONARY_ACTION,
});

export const loadStatisticAndOptions: ActionCreator = () => ({
  type: LOAD_STATISTIC_AND_OPTIONS_ACTION,
});

export const loadStatisticAndOptionsSucceeded: ActionCreator = (data) => ({
  type: LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION,
  data,
});

export const loadStatisticAndOptionsFailed: ActionCreator = () => ({
  type: LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION,
});

export const saveOptions: ActionCreator = (data) => ({
  type: SAVE_OPTIONS_ACTION,
  data,
});

export const saveOptionsSucceeded: ActionCreator = (ckb = () => null) => ({
  type: SAVE_OPTIONS_SUCCEEDED_ACTION,
  ckb,
});

export const saveOptionsFailed: ActionCreator = () => ({
  type: SAVE_OPTIONS_FAILED_ACTION,
});
