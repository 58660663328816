import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MuiThemeProvider,
} from '@material-ui/core';
import { IconMenu } from '../../common/icons/customIcons';
import { DialogTitleStyled } from '../../common/styles/Common.styled';
import { RedirectDialog, RedirectIconHolder } from './RedirectModal.styled';

class RedirectModal extends React.Component {
  render() {
    const { t } = this.props;

    if (this.props.noImg) {
      return (
        <MuiThemeProvider theme={RedirectDialog}>
          <Dialog open>
            <DialogContent>
              <div>
                {t(
                  'В данной карточке отсутствует фотоконтент, поэтому товар не доступен для публичного просмотра',
                )}
                .
                {this.props.previewIconText && (
                  <span>
                    &nbsp;
                    {t(
                      'Для доступа к информации в карточке выберите действие «Просмотр» в меню справа',
                    )}
                    <RedirectIconHolder>
                      <IconMenu />
                    </RedirectIconHolder>
                  </span>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button color={'primary'} onClick={this.props.handleClose}>
                {t('Закрыть')}
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      );
    }

    return (
      <MuiThemeProvider theme={RedirectDialog}>
        <Dialog open>
          <DialogContent>
            <DialogTitleStyled>{t('Обратите внимание')}</DialogTitleStyled>
            <div>
              {t('Если Вы продолжите, страница товара откроется в новом окне')}.
            </div>
          </DialogContent>
          <DialogActions>
            <Button color={'primary'} onClick={this.props.handleRedirect}>
              {t('Продолжить')}
            </Button>
            <Button color={'secondary'} onClick={this.props.handleClose}>
              {t('Отмена')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

RedirectModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  noImg: PropTypes.bool.isRequired,
  previewIconText: PropTypes.bool.isRequired,
};

RedirectModal.defaultProps = {
  handleRedirect: () => {},
  noImg: false,
  previewIconText: false,
};

export default withTranslation()(RedirectModal);
