import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MuiThemeProvider,
} from '@material-ui/core';
import { CustomInput } from '../index';
import { IconCheck } from '../../common/icons/customIcons';
import { CheckBoxProfile, SmallEl } from '../../common/styles/Common.styled';
import { checkboxInput, CheckboxTitle } from './CheckBoxGroup.styled';

class CheckBoxGroup extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.checkboxs, nextProps.checkboxs);
  }

  render() {
    const { handleChange, handleChangeInput, required, t } = this.props;

    return (
      <FormControl required component="fieldset">
        <FormLabel component="legend">
          <CheckboxTitle>
            {required && <SmallEl>* </SmallEl>}
            {this.props.title}
          </CheckboxTitle>
        </FormLabel>
        <FormGroup>
          {_.map(this.props.checkboxs, (checkbox, index) => (
            <React.Fragment key={index}>
              <MuiThemeProvider theme={CheckBoxProfile}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox.checked}
                      onChange={() => handleChange(checkbox.name)}
                      value={checkbox.value}
                      checkedIcon={<IconCheck />}
                    />
                  }
                  label={checkbox.label}
                />
              </MuiThemeProvider>

              {checkbox.checked && checkbox.hasInput && (
                <MuiThemeProvider theme={checkboxInput}>
                  <CustomInput
                    required
                    id={checkbox.input.id}
                    name={checkbox.input.name}
                    label={
                      !checkbox.input.error
                        ? checkbox.input.label
                        : t('Не указано значение')
                    }
                    onChange={(value) =>
                      handleChangeInput(checkbox.input.name, value)
                    }
                    onUpdate={() => {}}
                    value={checkbox.input.value}
                    error={checkbox.input.error}
                    valid={!!checkbox.input.value}
                    dirty={
                      !!checkbox.input.value.length || checkbox.input.error
                    }
                    type="text"
                  />
                </MuiThemeProvider>
              )}
            </React.Fragment>
          ))}
        </FormGroup>
      </FormControl>
    );
  }
}

CheckBoxGroup.propTypes = {
  handleChange: PropTypes.func,
  handleChangeInput: PropTypes.func,
  checkboxs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};
CheckBoxGroup.defaultProps = {
  required: true,
};
export default withTranslation()(CheckBoxGroup);
