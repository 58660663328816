import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Provider as AlertProvider } from 'react-alert';
import { CookiesProvider, withCookies } from 'react-cookie';
import { push } from 'connected-react-router';
import { YMInitializer } from 'react-yandex-metrika';
import { forEach, get, includes } from 'lodash';
import { parse } from 'qs';
import { client, deleteAllCookies } from '../api/Client';
import {
  config,
  // isKZ
} from '../config';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { GlobalStyle } from './App.styled';
import { MainDefaultTheme } from './styles/Common.styled';

import { NotificationView, throwNotification } from './structure';
import { Alert, Preloader } from '../components';
import ConsentProvisionOfDataModal from './root/modal/ConsentProvisionOfDataModal';
import {
  authenticationSession,
  loginSucceesed,
} from '../pages/authentication/ducks/Authentification.actions';
import { loadProfileInfo } from '../pages/profile/ducks/Profile/Profile.actions';
import { setCookie, getCookie } from '../api/Client';
import ActiveSessionModal from '../pages/authentication/modal/ActiveSessionModal';

const theme = createMuiTheme({ typography: {} });

async function acceptEmailCode(code) {
  return await client().instance.post(
    `/profile/verify-code`,
    JSON.stringify({ emailCode: code }),
  );
}

class AppView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreementOpenModal: false,
    };

    setCookie('user', JSON.stringify({ name: 'ssss' }));

    const params = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { psid, elkCode, code } = params;

    if (elkCode) {
      deleteAllCookies();
      this.props.onAuthenticationSession(
        { elkCode: elkCode },
        config.urls.products,
      );
      this.state.elkCode = elkCode; //449
    } else if (psid) {
      this.props.onAuthenticationSession(
        { psid: psid },
        this.props.location.pathname,
      );
      this.state.psid = psid; //449
    } else {
      const isAuth = getCookie('isAuth');
      const userInfo = getCookie('userInfo');

      if (isAuth && userInfo) {
        this.props.onLoginSuccesed(JSON.parse(userInfo));
        this.props.loadProfileInfo();
        if (code) {
          acceptEmailCode(code)
            .then(() => {
              this.props.goToPage('/profile');
            })
            .catch((errors) => {
              const err = get(errors, 'response.data.errors');
              forEach(err, (i) => this.props.throwError(i));
              this.props.goToPage('/profile');
            });
        }
        if (
          includes(['/login', '/registration'], this.props.location.pathname)
        ) {
          this.props.goToPage('/profile');
        }
      }
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //     if (!prevProps.isAuthentificated && this.props.isAuthentificated) {
  //         console.log(this.props.isAuthentificated)
  //         console.log(this.props.user)
  //     }
  // }

  handleClose = () => {
    this.setState({ agreementOpenModal: false });
  };

  handleSubmitClosingSession = () => { //449
    const { psid, elkCode } = this.state;
    if (elkCode) {
      this.props.onAuthenticationSession(
        { elkCode: elkCode, closeOpenSessions: true },
        config.urls.products,
      );
    } else if (psid) {
      this.props.onAuthenticationSession(
        { psid: psid, closeOpenSessions: true },
        config.urls.products,
      );
    }
  }

  render() {
    const { t } = this.props;
    const params = parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const { psid, elkCode } = params;

    if (psid || elkCode) {
      return (
        <React.Fragment>
          <GlobalStyle />
          <Preloader isOpen />
        </React.Fragment>
      );
    }

    const options = { containerStyle: { zIndex: 9999 } };
    const favicon = `${process.env.PUBLIC_URL}/favicon-kz-16x16.png`;
    // const favicon = `${process.env.PUBLIC_URL}/favicon-${isKZ ? 'kz' : config.lang}-16x16.png`;

    return (
      <CookiesProvider>
        <MuiThemeProvider theme={theme}>
          <MuiThemeProvider theme={MainDefaultTheme}>
            <AlertProvider template={Alert} {...options}>
              <Helmet>
                <link
                  rel="icon"
                  type="image/png"
                  href={favicon}
                  sizes="16x16"
                />
                <title>{t('Национальный каталог товаров')}</title>
              </Helmet>

              {!!config.YaMetric && (
                <YMInitializer
                  accounts={[config.YaMetric]}
                  options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true,
                  }}
                />
              )}

              <NotificationView />

              {this.state.agreementOpenModal && (
                <ConsentProvisionOfDataModal handleClose={this.handleClose} />
              )}
              {this.props.isActiveElkSessionOpenModal && (
                <ActiveSessionModal
                  activeSessionInfo={this.props.activeElkSessionInfo}
                  handleSubmit={this.handleSubmitClosingSession}
                />
              )}

              {this.props.children}

              <GlobalStyle />
            </AlertProvider>
          </MuiThemeProvider>
        </MuiThemeProvider>
      </CookiesProvider>
    );
  }
}

AppView.propTypes = {
  onLoginSuccesed: PropTypes.func.isRequired,
  loadProfileInfo: PropTypes.func.isRequired,
  onAuthenticationSession: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired,
  isActiveElkSessionOpenModal: PropTypes.bool.isRequired,
  activeElkSessionInfo: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onAuthenticationSession: (params, pathname) =>
        authenticationSession(params, pathname),
      onLoginSuccesed: (user) => loginSucceesed(user),
      loadProfileInfo: (cbk) => loadProfileInfo(cbk),
      goToPage: (page) => push(page),
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => ({
  // user: state.authentificationReducer.user,
  // isAuthentificated: state.authentificationReducer.isAuthentificated,
  location: state.router.location,
  isActiveElkSessionOpenModal: state.authentificationReducer.isActiveElkSessionOpenModal,
  activeElkSessionInfo: state.authentificationReducer.activeElkSessionInfo,
});

export default compose(
  withCookies,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(AppView);
