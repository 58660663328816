import React from 'react';
import {
  ErrorBlock,
  ErrorBlockLeft,
  ErrorBlockRight,
  ErrorCode,
  ErrorContainer,
  ErrorLink,
  ErrorTitle,
} from '../Errors.styled';
import { Error403 } from '../../../common/icons/customIcons';
import { Link } from 'react-router-dom';

export default function Page403() {
  return (
    <ErrorContainer>
      <ErrorBlock>
        <ErrorBlockLeft>
          <ErrorTitle>
            <ErrorCode id="code-403">403</ErrorCode>
            ОТКАЗАНО В ДОСТУПЕ
          </ErrorTitle>
          Запрошенная вами страница имеет
          <br /> ограничения на доступ.
          <ErrorLink>
            Попробуйте <Link to="/">вернуться на главную</Link>
          </ErrorLink>
        </ErrorBlockLeft>
        <ErrorBlockRight>
          <Error403 />
        </ErrorBlockRight>
      </ErrorBlock>
    </ErrorContainer>
  );
}
