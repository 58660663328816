import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import { withTranslation } from 'react-i18next';
import { FormControl, TextField } from '@material-ui/core';

class PhoneInput extends React.Component {
  state = {
    isDirty: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['phone', 'phoneValid', 'placeholder', 'valid'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  handleChange = (event) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    this.props.onChange(event.target.value, this.props.name);
  };

  handleUpdate = (event) => {
    if (!this.state.isDirty) {
      this.setState({ isDirty: true });
    }
    this.props.onUpdate(event.target.value, this.props.name);
  };

  render() {
    const {
      phone,
      phoneValid,
      valid,
      label,
      name,
      id,
      maskGS,
      autoComplete,
      t,
      disabled = false,
      defaultLabel = '',
    } = this.props;
    const labelLang = _.isUndefined(label) ? t('Телефон') : label;

    const formatChars = { 9: '[0-9]', 7: '7' };
    const mask = maskGS
      ? maskGS
      : phone[0] === '+' && phone[1] === '7'
      ? '+7 (999) 999 99-99'
      : '+999 999 999 999';
    const isError = this.state.isDirty && !phoneValid;

    return (
      <FormControl fullWidth={true}>
        <InputMask
          value={phone}
          onChange={this.handleChange}
          disableUnderline={true}
          mask={mask}
          formatChars={formatChars}
          onBlur={this.handleUpdate}
          disabled={disabled}>
          {() => (
            <TextField
              name={name}
              id={id}
              type="text"
              required={this.props.required}
              InputProps={{ disableUnderline: true }}
              inputProps={{
                valid: _.toString(valid && phoneValid && !isError),
              }}
              label={
                phoneValid
                  ? labelLang
                  : !!defaultLabel
                  ? defaultLabel
                  : t('Введите корректный номер телефона')
              }
              error={isError}
              autoComplete={autoComplete}
              disabled={disabled}
            />
          )}
        </InputMask>
      </FormControl>
    );
  }
}

PhoneInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  phone: PropTypes.string.isRequired,
  phoneValid: PropTypes.bool,
  valid: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  maskGS: PropTypes.string,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
};

PhoneInput.defaultProps = {
  onUpdate: () => {},
  id: 'formatted-text-mask-input',
  name: 'phone',
  required: false,
};

export default withTranslation()(PhoneInput);
