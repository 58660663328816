export const LOAD_PROFILE_STATUS_ACTION = 'LOAD_PROFILE_STATUS';
export const LOAD_PROFILE_STATUS_SUCCEEDED_ACTION =
  'LOAD_PROFILE_STATUS_SUCCEEDED';
export const LOAD_PROFILE_STATUS_FAILED_ACTION = 'LOAD_PROFILE_STATUS_FAILED';
export const DELETE_PROFILE_STATUS_ACTION = 'DELETE_PROFILE_STATUS';
export const CHECK_UNIQUENESS_KPP_ACTION = 'CHECK_UNIQUENESS_KPP';
export const CREATE_PROFILE_STATUS_ACTION = 'CREATE_PROFILE_STATUS';
export const LOAD_GLN_DICTIONARY_ACTION = 'LOAD_GLN_DICTIONARY';
export const LOAD_GLN_DICTIONARY_SUCCESSED_ACTION =
  'LOAD_GLN_DICTIONARY_SUCCESSED';
export const LOAD_GLN_DICTIONARY_FAILED_ACTION = 'LOAD_GLN_DICTIONARY_FAILED';
export const CLEAR_GLN_DICTIONARY_ACTION = 'CLEAR_GLN_DICTIONARY';

export const LOAD_STATISTIC_AND_OPTIONS_ACTION =
  'LOAD_STATISTIC_AND_OPTIONS_ACTION';
export const LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION =
  'LOAD_STATISTIC_AND_OPTIONS_SUCCEEDED_ACTION';
export const LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION =
  'LOAD_STATISTIC_AND_OPTIONS_FAILED_ACTION';

export const SAVE_OPTIONS_ACTION = 'SAVE_OPTIONS_ACTION';
export const SAVE_OPTIONS_SUCCEEDED_ACTION = 'SAVE_OPTIONS_SUCCEEDED_ACTION';
export const SAVE_OPTIONS_FAILED_ACTION = 'SAVE_OPTIONS_FAILED_ACTION';
