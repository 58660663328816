import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import { ProductViewMdiaItem } from '../../../../common/styles/Common.styled';
import colors from '../../../../common/styles/Common.colors';

export const DefaultVersionsPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: '750px',
        maxWidth: '750px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, 0.15);',
        borderRadius: '4px',
        padding: '0',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '20px 20px 20px 95px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 0 10px 0',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
      label: {
        color: '#52535A!important',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        width: '197px',
        padding: '9px 10px',
        height: '44px',
        fontSize: '16px',
        fontWeight: '600',
        border: '1px solid transparent',
        transition: 'all .4s ease',
        '&:hover': {
          border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
          backgroundColor: '#fff',
          color: '#52535A',
        },
      },
    },
  },
});
export const VersionLineInner = styled.div`
  display: block;
  width: 100%;
  border-top: 1px solid #f2f2f2;
`;
export const VersionLineInnerTop = styled.div`
  font-size: 14px;
  color: #63666a;
  font-weight: 500;
  padding: 10px 40px 20px;
  display: flex;
`;
export const VersionLineInnerBottom = styled.div`
  font-size: 12px;
  color: #63666a;
  font-weight: 500;
  padding: 20px 40px;
  border-top: 1px solid #f2f2f2;
`;
export const VersionInfoDate = styled.div`
  font-size: 14px;
  color: #63666a;
  font-weight: 600;
  padding-right: 25px;
`;
export const VersionInfoChanges = styled.div`
  color: #4aa5e8;
  font-size: 14px;
  font-weight: 500;
  padding-left: 25px;
  flex-grow: 1;
  cursor: pointer;
  transition: color 0.4s ease;
  &:hover {
    color: #0088ec;
  }
`;
export const VersionInfoView = styled.div`
  a {
    color: #4aa5e8;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.4s ease;
    &:hover {
      color: #0088ec;
    }
  }
`;
export const VersionImages = styled.div`
  display: flex;
`;
export const VersionImagesItem = styled.div`
  padding-left: 3px;
`;
export const ProductVersionMediaItemInner = styled.div`
  overflow: hidden;
  padding: 6px 3px;
  height: 100%;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: #fff;
  }
  img {
    transition: transform 0.4s ease;
    &:hover {
      transform: scale(1.5);
    }
  }
`;
export const ProductVersionMdiaItem = styled(ProductViewMdiaItem)`
  max-width: 70px;
  padding: 0;
  height: 50px;
  margin-top: 10px;
`;
