import { Reducer } from 'redux';
import i18n from '../../../i18n';
import _ from 'lodash';
import {
  LOAD_COMPANIES_DATA_ACTION,
  LOAD_COMPANIES_DATA_FAILED_ACTION,
  LOAD_COMPANIES_DATA_SUCCEEDED_ACTION,
} from './Companies.constants';

const _initialState = {
  loading: false,
  filters: null,
  filtersCache: null,
  data: [],
  totalObjects: 0,
};

export const companiesReducer: Reducer = (state = _initialState, action) => {
  switch (action.type) {
    case LOAD_COMPANIES_DATA_ACTION: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOAD_COMPANIES_DATA_FAILED_ACTION: {
      return {
        ...state,
        loading: false,
      };
    }
    case LOAD_COMPANIES_DATA_SUCCEEDED_ACTION: {
      const data = _.map(action.data, (d) => {
        d.isEdit = false;
        return d;
      });
      return {
        ...state,
        loading: false,
        data: data,
        totalObjects: action.totalObjects,
        filters: null,
        filtersCache: _.cloneDeep(action.filters),
      };
    }

    default:
      return { ...state };
  }
};