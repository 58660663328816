import styled from 'styled-components';

export const AlertPopup = styled.div`
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px 50px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  position: relative;
  margin-top: 60px;
  z-index: 9999;
  max-width: 530px;
  button {
    background: none;
    border: none;
    font-size: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
