import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import {
  defaultTo,
  forEach,
  isEqual,
  isNil,
  map,
  some,
  toString,
} from 'lodash';
import { RenderTooltip } from '../../../../../components';
import {
  IconInfoTooltip,
  IconLogoDropDown,
} from '../../../../../common/icons/customIcons';
import { SelectIconLeft } from '../../../../../common/styles/Common.styled';
import { Button } from '@material-ui/core';
import { IconMenuClose } from '../../../../../common/icons/customIcons';

class SelectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: true,
    };
    this.uidItem = `selectItem_${this.props.attrUid}`;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.t !== nextProps.t) return true
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['id', 'value', 'error', 'disabled'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  handleClear = () => {
    this.props.onChange({ target: { value: '' } });
  };

  renderCleanButton = () => {
    const { disabled, required, description } = this.props;

    const marginRight = description ? 55 : 35;

    if (!required && !disabled) {
      return (
        <Button
          style={{
            position: 'absolute',
            right: marginRight,
            width: 30,
            minWidth: 30,
            maxHeight: 30,
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)',
            padding: '6px 16px',
            paddingBottom: '8px',
            fontSize: '0.875rem',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            lineHeight: 1.75,
            borderRadius: 4,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            border: 0,
            cursor: 'pointer',
            margin: 0,
            display: 'inline-flex',
            outline: 0,
            alignItems: 'center',
            userSelect: 'none',
            verticalAlign: 'middle',
            justifyContent: 'center',
            textDecoration: 'none',
            boxShadow: 'none',
            top: '50%',
            marginTop: -7,
          }}
          onClick={this.handleClear}>
          <div style={{ width: 20, height: 20, paddingBottom: 5 }}>
            <IconMenuClose style={{ width: 12, height: 12 }} />
          </div>
        </Button>
      );
    }

    return null;
  };

  render() {
    const {
      id,
      name,
      required,
      preset,
      value,
      description,
      onChange,
      error,
      disabled,
    } = this.props;
    const { t } = this.props
    const valueItem = defaultTo(value, '');

    return (
      <FormControl
        style={{ position: 'relative' }}
        key={`select-${id}`}
        fullWidth={true}>
        <InputLabel disabled={disabled} htmlFor={`select-${id}`} error={error}>
          {t(name)}
        </InputLabel>
        <Select
          disabled={disabled}
          required={required}
          valid={toString(required && !!valueItem && !error)}
          error={error}
          value={valueItem}
          inputProps={{ id: `select-${id}` }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          }}
          IconComponent={IconLogoDropDown}
          onChange={(event) => {
            onChange(event);
          }}
          className={cx({ 'disabled-item': disabled })}>
          {map(preset, (item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        {this.renderCleanButton()}
        {t(description) &&
          RenderTooltip(
            this.uidItem,
            description,
            <SelectIconLeft>
              <IconInfoTooltip />
            </SelectIconLeft>,
            this.state.bottom,
            this.handleOpen,
          )}
      </FormControl>
    );
  }
}

SelectItem.propTypes = {
  attrUid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  preset: PropTypes.array.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

SelectItem.defaultProps = {
  required: false,
  preview: false,
};
export default SelectItem;
