import { Reducer } from 'redux';
import {
  DELETE_NOTIFICATION,
  THROW_NOTIFICATION,
} from './Notification.actions';

const _initialState = {
  type: '',
  message: '',
  timeout: 5000,
  remove: false,
};

export const notificationReducer: Reducer = (
  state = _initialState,
  action: actionType,
) => {
  switch (action.type) {
    case THROW_NOTIFICATION:
      return {
        ...state,
        ...action.data,
        remove: false,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        remove: true,
      };
    default:
      return state;
  }
};
