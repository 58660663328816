export const LOAD_PROFILE_INFO_ACTION = 'LOAD_PROFILE_INFO';
export const LOAD_PROFILE_SUCCEEDED_ACTION = 'LOAD_PROFILE_SUCCEEDED';
export const LOAD_PROFILE_FAILED_ACTION = 'LOAD_PROFILE_FAILED';
export const LOAD_PROFILE_CERTIFICATES_ACTION = 'LOAD_PROFILE_CERTIFICATES';
export const LOAD_PROFILE_CERTIFICATES_SUCCEEDED_ACTION =
  'LOAD_PROFILE_CERTIFICATES_SUCCEEDED';
export const LOAD_PROFILE_CERTIFICATES_FAILED_ACTION =
  'LOAD_PROFILE_CERTIFICATES_FAILED';

export const SAVE_PASSWORD_ACTION = 'SAVE_PASSWORD';

export const SAVE_INFO_ACTION = 'SAVE_INFO';
export const SAVE_INFO_SUCCEEDED_ACTION = 'SAVE_INFO_SUCCEEDED';
export const SAVE_INFO_FAILED_ACTION = 'SAVE_INFO_FAILED';

export const CLEAR_INFO_ERROR = 'CLEAR_INFO_ERROR';

export const SET_LOCALE = 'SET_LOCALE';
