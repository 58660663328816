import * as React from 'react';
import * as PropTypes from 'prop-types';
import { defaultTo, forEach, isEqual, isNil, some, toString } from 'lodash';
import { FormControl, MenuItem } from '@material-ui/core';
import { Autocomplete, RenderTooltip } from '../../../../../components';
import {
  IconError,
  IconInfoTooltip,
} from '../../../../../common/icons/customIcons';
import { AutoInfoIcon, AutoInfoIconHolder } from './AutocompleteItem.styled';

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem disableGutters={true} selected={isHighlighted} component="div">
      {suggestion.text}
    </MenuItem>
  );
}

class AutocompleteItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom: true,
    };
    this.uidTooltip1 = `autocompleteItem_tooltip1_${this.props.attrUid}`;
    this.uidTooltip2 = `autocompleteItem_tooltip2_${this.props.attrUid}`;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const t = this.props;
    if (this.props.t !== nextProps.t) return true;
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = [
      'id',
      'suggestions',
      'value',
      'errors',
      'error',
      'disabled',
    ];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(e, this.props);
    }
  };

  render() {
    const {
      t,
      attrUid,
      id,
      itemId,
      name,
      type,
      value,
      description,
      errors,
      suggestions,
      handleSuggestionsFetchRequested,
      handleClearField,
      getSuggestionValue,
      onValidate,
      required,
      disabled,
      error,
      onlyFormList,
    } = this.props;

    const valueItem = defaultTo(value, '');

    return (
      <FormControl key={`dataqualifier-${id}`} fullWidth={true}>
        <Autocomplete
          {...this.props}
          attrUid={attrUid}
          name={id}
          disabled={disabled}
          required={required}
          onlyFromList={onlyFormList} // разрешить значения только из списка
          isValidateText // включить валидацию на цензуру
          onValidate={onValidate} // функция будет вызвана по завершению валидации
          placeholder={name}
          type={type}
          suggestions={suggestions ? suggestions : []}
          initValue={toString(valueItem)}
          valid={!!valueItem}
          error={error}
          labelError={typeof error === 'string' && error}
          renderSuggestion={renderSuggestion}
          getSuggestionValue={(value) => getSuggestionValue(value)}
          handleSuggestionsFetchRequested={(value) =>
            handleSuggestionsFetchRequested(value)
          }
          handleClearField={handleClearField}
          handleSuggestionsClearRequested={() => {}}
          handleBlur={this.handleBlur}>
          <AutoInfoIconHolder>
            {t(description) &&
              RenderTooltip(
                this.uidTooltip1,
                description,
                <AutoInfoIcon>
                  <IconInfoTooltip />
                </AutoInfoIcon>,
                this.state.bottom,
                this.handleOpen,
              )}
            {!!errors[itemId] &&
              RenderTooltip(
                this.uidTooltip2,
                errors[itemId],
                <span>
                  <IconError />
                </span>,
                this.state.bottom,
                this.handleOpen,
              )}
          </AutoInfoIconHolder>
        </Autocomplete>
      </FormControl>
    );
  }
}

AutocompleteItem.propTypes = {
  handleSuggestionsFetchRequested: PropTypes.func.isRequired,
  handleClearField: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  attrUid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
};

AutocompleteItem.defaultProps = {
  required: false,
  error: false,
  preview: false,
  onlyFromList: false,
};

export default AutocompleteItem;
