import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import {
  Autocomplete,
  ConfirmDialog,
  CustomInput,
  ModerateTooltip,
  SimpleTooltip,
} from '../../../../../components';
import {
  IconError,
  IconGtnValid,
  IconInfoTooltip,
  IconLogoDropDown,
  IconMinus,
  IconPreloader,
} from '../../../../../common/icons/customIcons';
import {
  PackInfoItem,
  PackInfoItemMax,
  PackInfoItemMin,
} from '../../Product/Product.styled';
import {
  defaultSelectTheme,
  defaultTheme,
  GridBlockA,
  GridBlockB,
  GridBlockC,
  GridItemA,
  GridItemB,
  GridItemC,
  GridItemSmall,
  GridItemSmallA,
  GridItemSmallB,
  GridItemSmallC,
  GridItemSmallD,
  InputContainer,
  InputIcon,
  ProductButton,
  ProfileAutocomplete,
} from '../../../../../common/styles/Common.styled';
import {
  GridItemA1,
  GridItemB1,
  GridItemC1,
  GridItemD1,
  PackInfoHelperCont,
  packageSelectTheme,
} from './PackageInformationItem.styled';
import { PACKAGING_TYPE } from '../../../../../common/constants/Packaging';
import {
  generationGtin,
  validateGtinPackage,
} from '../../../ducks/Product.actions';
import {
  gtinDisplay,
  gtinValidLength,
} from '../../../../../common/utils/utils';

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem disableGutters={true} selected={isHighlighted} component="div">
      {suggestion.text}
    </MenuItem>
  );
}

const WAIT_INTERVAL = 1000;

class PackageInformationItem extends React.Component {
  constructor(props) {
    super(props);
    const data = this.props.data;
    this.state = {
      added: false,
      errors: {
        weight: '',
        capacity: '',
        height: '',
        length: '',
        width: '',
      },
      validation: false,
      showConfirmDelete: false,
      confirmDeleteMessage: '',
      suggestions: {
        showboxDisplaySigns: this.props.dictionaryShowboxDisplaySigns,
        // packageTypes: this.props.dictionaryPackageTypes,
        // packageMaterials: this.props.dictionaryPackageMaterials
      },
      gtinValidation: {
        process: false,
        message: '',
        successed: false,
      },
      previousWDH: {
        weight: this.props.data.disabled ? data.weight : 0,
        height: this.props.data.disabled ? data.height : 0,
        width: this.props.data.disabled ? data.width : 0,
        length: this.props.data.disabled ? data.length : 0,
      },
      openModalNewGtin: false,
      bottom: true,
    };
    this.validateGtinLengthDebounce = _.debounce(
      this.validateGtinLength,
      WAIT_INTERVAL,
    );
    this.disabledButton = false;
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.data.gtin)) {
      this.setState({
        gtinValidation: { successed: true, message: '', process: false },
      });
    }
  }

  componentWillUnmount() {
    this.validateGtinLengthDebounce.cancel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const equal = [!_.isEqual(this.state, nextState)];
    // const nameProps = [
    //     'data', 'dictionaryShowboxDisplaySigns', 'dictionaryPackageTypes', 'dictionaryPackageMaterials',
    //     'dictionaryPalletTypes', 'limites', 'gtinInners', 'errors', 'preview', 'status', 'locale',
    // ];
    // _.forEach(nameProps, i => equal.push(!_.isEqual(this.props[i], nextProps[i])));
    // return _.some(equal);

    // быстрый фикс для селект опшенов.
    const result = [_.isEqual(this.state, nextState)];
    _.forEach(Object.keys(nextProps), (key) =>
      result.push(_.isEqual(this.props[key], nextProps[key])),
    );
    return !_.every(result);
  }

  componentDidUpdate(prevProps) {
    const disabledButton =
      !_.isEmpty(this.state.gtinValidation.message) ||
      this.state.gtinValidation.process ||
      !!this.state.errors.gtin;

    if (this.disabledButton !== disabledButton) {
      this.disabledButton = disabledButton;
      this.props.handleGtinStatus(disabledButton, this.props.data.id);
    }

    const key = [
      'data.multiplier',
      'data.gtinInner',
      'data.weight',
      'data.length',
      'data.height',
      'data.width',
      'limites',
    ];
    if (
      _.some(
        _.map(
          key,
          (i) => !_.isEqual(_.get(prevProps, i), _.get(this.props, i)),
        ),
      )
    ) {
      this.checkWDHMessage();
    }
  }

  checkWDH = (name, value) => {
    const { id } = this.props.data;
    const { t } = this.props;
    let flag = false;
    let message = '';
    if (!!this.props.limites[`min${name}`]) {
      let msgMin = this.props.limites[`min${name}`];

      if (name === 'capacity' && this.props.data.on1stLayer) {
        msgMin =
          _.max([
            this.props.limites[`minheight`],
            Number(this.props.data.height),
          ]) * this.props.data.on1stLayer;
      }

      flag = msgMin > Number(value);

      message = flag
        ? `${t('Введенное значение должно быть больше или равно')} ${msgMin}`
        : '';
    }
    if (this.props.data.disabled && _.isEmpty(message) && name !== 'capacity') {
      message = this.checkGtinLimits(name, Number(value));
    }
    this.setState(
      (prevState) => ({
        errors: { ...prevState.errors, [name]: message },
      }),
      () => {
        this.props.updateItem(this.props.data.id, {
          [name]: _.isString(value) && _.isEmpty(value) ? null : value,
        });
        this.props.handleWdhErrors(this.props.data.id, this.state.errors);
      },
    );
    const vghList = _.cloneDeep(this.props.data.vghList);
    const index = _.findIndex(vghList, { name });
    _.set(vghList, `[${index}].value`, value);
    this.props.updateItem(id, { vghList });
  };

  validateWDH = (name, value) => {
    const { id } = this.props.data;
    let message = '';

    if (this.props.data.disabled) {
      message = this.checkGtinLimits(name, Number(value));
    }
    this.setState(
      (prevState) => ({
        errors: { ...prevState.errors, [name]: message },
      }),
      () => {
        this.props.updateItem(this.props.data.id, {
          [name]: _.isString(value) && _.isEmpty(value) ? null : value,
        });
        this.props.handleWdhErrors(this.props.data.id, this.state.errors);
      },
    );
    const vghList = _.cloneDeep(this.props.data.vghList);
    const index = _.findIndex(vghList, { name });
    _.set(vghList, `[${index}].value`, value);
    this.props.updateItem(id, { vghList });
  };

  checkWDHMessage = () => {
    const { t } = this.props;
    const keys = ['weight', 'height', 'length', 'width', 'capacity'];
    let errors = {};

    _.forEach(keys, (name) => {
      let flag = false;
      errors[name] = '';
      if (!!this.props.limites[`min${name}`]) {
        let msgMin = this.props.limites[`min${name}`];

        if (name === 'capacity' && this.props.data.on1stLayer) {
          msgMin =
            _.max([
              this.props.limites[`minheight`],
              Number(this.props.data.height),
            ]) * this.props.data.on1stLayer;
        }

        flag = msgMin > Number(this.props.data[name]);

        errors[name] = flag
          ? `${t('Введенное значение должно быть больше или равно')} ${msgMin}`
          : '';
        if (this.props.data.level !== 'pallet' && name === 'height') {
          errors.height = '';
        }
      }
      if (
        this.props.data.disabled &&
        _.isEmpty(errors[name]) &&
        name !== 'capacity'
      ) {
        errors[name] = this.checkGtinLimits(
          name,
          Number(this.props.data[name]),
        );
      }
    });

    this.setState({ errors: errors }, () => {
      this.props.handleWdhErrors(this.props.data.id, this.state.errors);
    });
  };

  checkGtinLimits = (name, value) => {
    let message = '';
    let previous = _.defaultTo(this.state.previousWDH[name], 0);
    let limit = previous * 0.2;
    if (value > previous + limit || value < previous - limit) {
      message = this.props.t('Некорректное значение');
    }
    return message;
  };

  handleChange = (name, value) => {
    const { id, level } = this.props.data;

    if (name === 'gtin' && level !== 'pallet') {
      this.checkGtin(name, value);
    } else {
      if (_.includes(['length', 'width', 'height', 'weight'], name)) {
        value = value.replace(/[^[0-9.]/, '');
        if (value.startsWith('.')) {
          value = null;
        } else {
          const values = value.split('.');
          if (values.length > 1) {
            value =
              name === 'weight'
                ? values[0] + '.' + values[1].slice(0, 4)
                : values[0] + '.' + values[1].slice(0, 2);
          } else {
            if (
              !_.isNil(values) &&
              values[0].toString().startsWith('0') &&
              !value.includes('.')
            ) {
              value = '0';
            }
          }
        }
      }

      if (
        name === 'weight' ||
        name === 'capacity' ||
        (name === 'height' && level === 'pallet')
      ) {
        this.checkWDH(name, value);
      } else if (
        name === 'length' ||
        name === 'width' ||
        (name === 'height' && level !== 'pallet')
      ) {
        this.validateWDH(name, value);
      } else if (
        _.includes(
          [
            'packageType',
            'packageMaterial',
            'packageTypeCapping',
            'packageMaterialCapping',
          ],
          name,
        )
      ) {
        const vghList = _.cloneDeep(this.props.data.vghList);
        const index = _.findIndex(vghList, { name });
        _.set(vghList, `[${index}].value`, value);
        this.props.updateItem(id, { vghList });

        const materialHelpDictionary = this.props
          .dictionaryPackageLetterNumeralCode;

        /**
         * костыль для обновления поля packageMaterial и его связанных полей: letterCode, numeralCode.
         */
        if (materialHelpDictionary.length && name === 'packageMaterial') {
          const letterAndNumericPair = _.find(
            materialHelpDictionary,
            (obj) => obj.material === value,
          );
          const { alphabet, digital } = letterAndNumericPair;
          const collection = {
            alphabetCode: alphabet,
            digitCode: digital,
            vghList,
          };

          this.props.updateConnectedFields(id, collection);
        }
      } else {
        this.props.updateItem(id, {
          [name]: _.isString(value) && _.isEmpty(value) ? null : value,
        });
      }
    }
  };

  handlePaste = (name, typeId) => (value) => {
    this.props.updateItem(this.props.data.id, { [name]: value }, typeId);
  };

  addItem = () => {
    this.setState({ added: true }, () =>
      this.props.addItem(this.props.data.id),
    );
  };

  handleSuggestionsFetchRequested = (name, value, fieldName) => {
    const inputValue = _.toLower(_.deburr(_.trim(value)));
    if (inputValue.length === 0) {
      let fullSuggestions = [];
      switch (name) {
        case 'showboxDisplaySigns': {
          fullSuggestions = this.props.dictionaryShowboxDisplaySigns;
          this.handleChange('hasShowBox', null);
          break;
        }
        // case 'packageTypes': {
        //     fullSuggestions = this.props.dictionaryPackageTypes;
        //     this.handleChange('packageType', null);
        //
        //     break;
        // }
        // case 'packageMaterials': {
        //     fullSuggestions = this.props.dictionaryPackageMaterials;
        //     this.handleChange('packageMaterial', null);
        //     break;
        // }
        default:
          break;
      }

      this.setState((prevState) => ({
        suggestions: { ...prevState.suggestions, [name]: fullSuggestions },
      }));
    } else {
      this.fetchData(name, inputValue);
    }
  };

  fetchData = (name, value) => {
    let suggestions = [];
    switch (name) {
      case 'showboxDisplaySigns': {
        suggestions = _.filter(
          this.props.dictionaryShowboxDisplaySigns,
          (suggestion) => {
            return suggestion.text.toLowerCase().includes(value);
          },
        );
        break;
      }
      // case 'packageTypes': {
      //     suggestions = _.filter(this.props.dictionaryPackageTypes, (suggestion) => {
      //         return suggestion.text.toLowerCase().includes(value);
      //     });
      //     break;
      // }
      // case 'packageMaterials': {
      //     suggestions = _.filter(this.props.dictionaryPackageMaterials, (suggestion) => {
      //         return suggestion.text.toLowerCase().includes(value)
      //     });
      //     break;
      // }
      default:
        break;
    }

    this.setState((prevState) => ({
      suggestions: { ...prevState.suggestions, [name]: suggestions },
    }));
  };

  getSuggestionValue = (value, name) => {
    this.handleChange(name, value.text);
    return value.text;
  };

  onDeleteConfirm = () => {
    this.setState({ showConfirmDelete: !this.state.showConfirmDelete });
    this.props.deleteItem(this.props.data.id);
  };

  onShowDeleteDialog = () => {
    const { t } = this.props;
    const message = t('Вы действительно хотите удалить уровень упаковки?');
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete,
      confirmDeleteMessage: message,
    });
  };

  validateGtinLength = (name, gtin) => {
    const { t } = this.props;
    if (_.isEmpty(gtin) || !gtinValidLength(gtin)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [name]: t('Неверный формат кода товара'),
        },
      }));
      this.props.updateItem(this.props.data.id, { gtin: gtin }, true);
    } else {
      if (_.find(this.props.gtinInners, { gtin: gtin })) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            [name]: t('Код товара уже используется'),
          },
        }));
        this.props.updateItem(this.props.data.id, { gtin: gtin }, true);
      } else {
        this.setState(() => ({
          gtinValidation: { process: true, message: '', successed: false },
        }));
        this.props.validateGtinPackage(
          gtin,
          this.props.data.level,
          (errors, successed) => {
            this.setState({
              gtinValidation: {
                successed: successed,
                message: getDefaultTo(errors, 'error.gtin'),
                process: false,
              },
            });
            this.props.updateItem(
              this.props.data.id,
              { gtin: gtin },
              !successed,
            );
          },
        );
      }
    }
  };

  checkGtin = (name, value) => {
    const v = value.replace(/[^[0-9]/, '');
    this.setState(
      (prevState) => ({
        errors: { ...prevState.errors, [name]: false },
        gtinValidation: {
          process: false,
          message: '',
          successed: false,
        },
      }),
      () => {
        this.props.updateItem(this.props.data.id, { [name]: v });
        this.validateGtinLengthDebounce(name, v);
      },
    );
  };

  generateNewGtin = () => {
    this.props.generationGtin(
      {
        gtin: this.props.gtinTradeUnit,
        packageLevel: this.props.data.level,
      },
      (error, gtin) => {
        if (!error) {
          this.setState(
            (prevState) => ({
              gtinValidation: { process: false, message: '', successed: true },
              errors: { ...prevState.errors, gtin: false },
            }),
            () => {
              const disabledButton =
                !_.isEmpty(this.state.gtinValidation.message) ||
                this.state.gtinValidation.process ||
                !!this.state.errors.gtin;
              this.props.updateItem(
                this.props.data.id,
                { gtin: gtin },
                disabledButton,
              );
            },
          );
        }
      },
    );
  };

  moderateTooltip = (id, field) => {
    const title = _.get(this.props.errors, field);
    return ModerateTooltip(id, title, this.state.bottom, this.handleOpen);
  };

  handleOpen = (id) => (event) => {
    const element = document.getElementById(id);
    if (!_.isNil(element)) {
      const bottom = element.getBoundingClientRect().y > event.clientY;
      if (this.state.bottom !== bottom) {
        this.setState({ bottom });
      }
    }
  };

  isErrorAndNotEmptyErrorField = (field) => {
    return (
      !_.isEmpty(this.props.errors) &&
      !_.isEmpty(_.get(this.props.errors, field))
    );
  };

  isErrorWDH = (field) => {
    return (
      this.isErrorAndNotEmptyErrorField(field) ||
      !_.isEmpty(_.get(this.state.errors, field))
    );
  };

  getValueVghList = (name) => {
    const vghList = _.get(this.props, 'data.vghList', []);
    return _.defaultTo(_.find(vghList, { name }), { value: '' }).value;
  };

  getDisabledVghList = (name) => {
    const vghList = _.get(this.props, 'data.vghList', []);
    return _.defaultTo(_.find(vghList, { name }), { isDisabled: false })
      .isDisabled;
  };

  render() {
    const {
      requiredFields,
      t,
      packingPurpose,
      data,
      productData,
      typeId,
      status,
      preview,
    } = this.props;
    const { id, hideStatisticButtons } = this.props.data;
    const disabledGTIN =
      this.state.gtinValidation.process || this.state.gtinValidation.successed;
    const disabledField =
      this.props.preview ||
      (status !== 'draft' && this.props.data.disabled) ||
      this.props.isCatalogCard;
    const disabledPreview = this.props.preview;
    const disableRequired = this.props.data.disableRequired; // KAT-3782
    const fieldGtinDisabled =
      disabledGTIN || disabledPreview || disableRequired;

    const packageType = this.getValueVghList('packageType');
    const packageMaterial = this.getValueVghList('packageMaterial');
    const packageTypeCapping = this.getValueVghList('packageTypeCapping');
    const packageMaterialCapping = this.getValueVghList(
      'packageMaterialCapping',
    );

    const fieldCategory = _.find(_.get(productData, 'categories'), {
      classifier: 'k3',
    });

    const mainCode = fieldCategory.categoryName.split('.')[0];

    const ids = Date.now();

    return (
      <React.Fragment>
        {this.props.packingPurpose !== 'transport' && (
          <PackInfoItem>
            <PackInfoItemMax>
              <FormControl fullWidth={true}>
                <MuiThemeProvider theme={defaultSelectTheme}>
                  {/**
                   * костыль для отображения нормального лейбла и value, т.к. по факту в модели с бэка level всегда приходит пустой строкой
                   */}
                  <InputLabel disabled htmlFor={'packaging-select-' + ids}>
                    {t('Назначение упаковки')}
                  </InputLabel>
                  <Select
                    disabled
                    value={this.props.data.level || 'second-pack'}
                    inputProps={{ id: 'packaging-select-' + ids }}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    IconComponent={IconLogoDropDown}
                    onChange={(event) =>
                      this.handleChange('level', event.target.value)
                    }>
                    {_.map(PACKAGING_TYPE, (i) => (
                      <MenuItem key={i.level} value={i.level}>
                        {t(i.text)}
                      </MenuItem>
                    ))}
                  </Select>
                </MuiThemeProvider>
              </FormControl>
            </PackInfoItemMax>
            {data.level !== 'pallet' &&
              data.level !== 'trade-unit' &&
              packingPurpose !== 'group' && (
                <PackInfoItemMin>
                  <MuiThemeProvider theme={ProductButton}>
                    <Button
                      disabled={disabledField || disableRequired}
                      onClick={this.onShowDeleteDialog}
                      color={'secondary'}>
                      <IconMinus style={{ marginRight: '15px' }} />
                      {t('Удалить упаковку')}
                    </Button>
                  </MuiThemeProvider>
                </PackInfoItemMin>
              )}
          </PackInfoItem>
        )}

        <MuiThemeProvider theme={defaultTheme}>
          {!_.includes(
            ['pallet', 'trade-unit', 'second-pack', ''],
            this.props.data.level,
          ) &&
            !_.includes(['transport'], packingPurpose) && (
              <PackInfoItem>
                <PackInfoItemMax>
                  <Element key={`gtin_${id}`} name={`id_gtin_${id}`}>
                    <InputContainer>
                      <CustomInput
                        id={`gtin_${id}`}
                        name="gtin"
                        type="text"
                        maxLength={14}
                        required={_.includes(requiredFields, 'gtin')}
                        disabled={fieldGtinDisabled}
                        valid={this.state.gtinValidation.successed}
                        label={
                          !this.props.data.gtin
                            ? t('Введите код товара упаковки')
                            : this.state.errors.gtin
                            ? this.state.errors.gtin
                            : !!this.state.gtinValidation.message
                            ? 'Код товара'
                            : t('Код товара упаковки')
                        }
                        onUpdate={() => {}}
                        onChange={(value) => this.handleChange('gtin', value)}
                        value={
                          fieldGtinDisabled
                            ? gtinDisplay(getDefaultTo(this.props, 'data.gtin'))
                            : getDefaultTo(this.props, 'data.gtin')
                        }
                        error={
                          !!this.state.errors.gtin ||
                          !!this.state.gtinValidation.message
                        }
                      />
                      {this.state.errors.gtin ? (
                        <InputIcon>
                          <IconError />
                        </InputIcon>
                      ) : !!this.state.gtinValidation.message ? (
                        SimpleTooltip(
                          this.state.gtinValidation.message,
                          <IconError />,
                        )
                      ) : this.state.gtinValidation.process ? (
                        <InputIcon>
                          <IconPreloader />
                        </InputIcon>
                      ) : this.state.gtinValidation.successed ? (
                        <InputIcon>
                          <IconGtnValid />
                        </InputIcon>
                      ) : (
                        SimpleTooltip(
                          t(
                            'Код идентификации потребительской упаковки и транспортной упаковки должен указываться в формате ITF-14, согласно методологии ГС1 РУС',
                          ),
                          <IconInfoTooltip />,
                        )
                      )}
                    </InputContainer>
                  </Element>
                </PackInfoItemMax>
              </PackInfoItem>
            )}
        </MuiThemeProvider>
        <MuiThemeProvider theme={defaultTheme}>
          {this.props.data.level === 'trade-unit' &&
            this.props.data.level !== 'second-pack' &&
            !_.includes(['transport', 'group'], this.props.packingPurpose) && (
              <InputContainer>
                <CustomInput
                  disabled
                  id={`gtin_${id}`}
                  name="gtin"
                  type="text"
                  label={t('Код упаковки')}
                  value={gtinDisplay(getDefaultTo(this.props, 'data.gtin'))}
                  onUpdate={() => {}}
                />
                <InputIcon>
                  <IconGtnValid />
                </InputIcon>
              </InputContainer>
            )}
        </MuiThemeProvider>

        {this.props.data.level === 'pallet' && (
          <FormControl fullWidth={true}>
            {this.isErrorAndNotEmptyErrorField('palletType') &&
              this.moderateTooltip(`tooltip_info_moderate_${id}`, 'palletType')}

            <InputLabel
              htmlFor="palletType-select"
              disabled={disabledField}
              error={this.isErrorAndNotEmptyErrorField('palletType')}>
              {t('Тип паллеты')}
            </InputLabel>
            <Select
              disabled={disabledField}
              required={_.includes(requiredFields, 'palletType')}
              value={getDefaultTo(this.props, 'data.palletType')}
              error={this.isErrorAndNotEmptyErrorField('palletType')}
              inputProps={{ id: 'palletType-select' }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }}
              IconComponent={IconLogoDropDown}
              onChange={(event) =>
                this.handleChange('palletType', event.target.value)
              }>
              {_.map(this.props.dictionaryPalletTypes, (palletType) => (
                <MenuItem key={palletType.id} value={palletType.id}>
                  {palletType.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {this.props.data.level === 'pallet' && (
          <InputContainer>
            <CustomInput
              disabled={disabledField}
              required={_.includes(requiredFields, 'gtin')}
              id={`SSCC_${id}`}
              name="SSCC"
              type="text"
              maxLength={18}
              value={getDefaultTo(this.props, 'data.gtin')}
              label={
                this.props.data.gtin
                  ? t('Идентификатор')
                  : t('Введите идентификатор')
              }
              onChange={(value) => this.handleChange('gtin', value)}
              onUpdate={() => {}}
            />
          </InputContainer>
        )}

        {!_.includes(
          ['trade-unit', 'second-pack', ''],
          this.props.data.level,
        ) &&
          !_.includes(['group', 'transport'], packingPurpose) && (
            <Element key={`gtinInner_${id}`} name={`id_gtinInner_${id}`}>
              <FormControl fullWidth={true}>
                {this.isErrorAndNotEmptyErrorField('gtinInner') &&
                  this.moderateTooltip(
                    `tooltip_info_moderate2_${id}`,
                    'gtinInner',
                  )}

                <InputLabel
                  htmlFor="gtinInner-select"
                  disabled={disabledField || disableRequired}
                  error={this.isErrorAndNotEmptyErrorField('gtinInner')}>
                  {t('Код товара вложения в упаковку')}
                </InputLabel>
                <Select
                  required={_.includes(requiredFields, 'gtinInner')}
                  disabled={disabledField || disableRequired}
                  style={
                    disabledField || disableRequired
                      ? { borderLeft: '1px solid #D9D9D9' }
                      : {}
                  }
                  valid={_.toString(
                    !!getDefaultTo(this.props, 'data.gtinInner'),
                  )}
                  value={getDefaultTo(this.props, 'data.gtinInner')}
                  error={this.isErrorAndNotEmptyErrorField('gtinInner')}
                  inputProps={{ id: 'gtinInner-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange('gtinInner', event.target.value)
                  }>
                  {_.map(this.props.gtinInners, (item, index) => (
                    <MenuItem key={`${item.gtin}_${index}`} value={item.gtin}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Element>
          )}

        {_.includes(
          ['inner-pack', 'box', 'secondary-pack'],
          this.props.data.level,
        ) &&
          !_.includes(['group', 'transport'], packingPurpose) && (
            <React.Fragment>
              <MuiThemeProvider theme={defaultTheme}>
                {this.isErrorAndNotEmptyErrorField('multiplier') &&
                  this.moderateTooltip(
                    `tooltip_info_moderate13_${id}`,
                    'multiplier',
                  )}

                <CustomInput
                  disabled={disabledField || disableRequired}
                  required={_.includes(requiredFields, 'multiplier')}
                  id={`count_${id}`}
                  name="count"
                  label={t('Количество вложений в упаковку')}
                  value={getDefaultTo(this.props, 'data.multiplier')}
                  onChange={(value) => this.handleChange('multiplier', value)}
                  onUpdate={() => {}}
                  type="number"
                  error={this.isErrorAndNotEmptyErrorField('multiplier')}
                  dirty={this.isErrorAndNotEmptyErrorField('multiplier')}
                />
              </MuiThemeProvider>
            </React.Fragment>
          )}

        {this.props.data.level === 'pallet' && (
          <React.Fragment>
            <MuiThemeProvider theme={defaultTheme}>
              {this.isErrorAndNotEmptyErrorField('multiplier') &&
                this.moderateTooltip(
                  `tooltip_info_moderate3_${id}`,
                  'multiplier',
                )}

              <CustomInput
                disabled={disabledField}
                required={_.includes(requiredFields, 'multiplier')}
                id={`multiplier_${id}`}
                name="multiplier"
                label={t('Количество вложений в упаковку')}
                value={getDefaultTo(this.props, 'data.multiplier')}
                onChange={(value) => this.handleChange('multiplier', value)}
                onUpdate={() => {}}
                type="number"
                error={this.isErrorAndNotEmptyErrorField('multiplier')}
                dirty={this.isErrorAndNotEmptyErrorField('multiplier')}
              />
            </MuiThemeProvider>

            {this.isErrorAndNotEmptyErrorField('layerCount') &&
              this.moderateTooltip(
                `tooltip_info_moderate4_${id}`,
                'layerCount',
              )}

            <CustomInput
              disabled={disabledField}
              required={_.includes(requiredFields, 'layerCount')}
              id={`count_${id}`}
              name="count"
              label={t(
                'Количество слоев на паллете для выбранного кода товара',
              )}
              value={getDefaultTo(this.props, 'data.layerCount')}
              onChange={(value) => this.handleChange('layerCount', value)}
              onUpdate={() => {}}
              error={this.isErrorAndNotEmptyErrorField('layerCount')}
              dirty={this.isErrorAndNotEmptyErrorField('layerCount')}
              type="number"
            />

            {this.isErrorAndNotEmptyErrorField('on1stLayer') &&
              this.moderateTooltip(
                `tooltip_info_moderate5_${id}`,
                'on1stLayer',
              )}

            <CustomInput
              disabled
              id={`count_${id}`}
              name="count"
              label={t('Количество в 1 слое паллеты выбранного кода товара')}
              value={getDefaultTo(this.props, 'data.on1stLayer')}
              onChange={(value) => this.handleChange('on1stLayer', value)}
              onUpdate={() => {}}
              error={this.isErrorAndNotEmptyErrorField('on1stLayer')}
              dirty={this.isErrorAndNotEmptyErrorField('on1stLayer')}
              type="number"
            />
          </React.Fragment>
        )}

        {/* for using in future */}
        {/* {_.includes(["trade-unit", "inner-pack", "box"], this.props.data.level) &&
                    <MuiThemeProvider theme={defaultTheme}>
                        {this.isErrorAndNotEmptyErrorField('name') &&
                            this.moderateTooltip(`tooltip_info_moderate6_${id}`, 'name')
                        }

                        <Element key={`name_${id}`} name={`id_name_${id}`}>
                            <CustomInput
                                disabled={disabledField}
                                required={_.includes(requiredFields, 'name')}
                                id={`shortName_${id}`}
                                name="shortName"
                                type="text"
                                isValidateText
                                onValidate={onValidate}
                                label={t('Краткое наименование товара')}
                                onUpdate={() => {}}
                                onChange={(value) => this.handleChange('name', value)}
                                value={getDefaultTo(this.props, 'data.name')}
                                error={this.isErrorAndNotEmptyErrorField('name')}
                                dirty={this.isErrorAndNotEmptyErrorField('name')}
                            />
                        </Element>
                    </MuiThemeProvider>
                } */}

        {_.includes(['inner-pack', 'box'], this.props.data.level) &&
          !_.includes(['group', 'transport'], packingPurpose) && (
            <MuiThemeProvider theme={ProfileAutocomplete}>
              {this.isErrorAndNotEmptyErrorField('hasShowBox') &&
                this.moderateTooltip(
                  `tooltip_info_moderate7_${id}`,
                  'hasShowBox',
                )}

              <Autocomplete
                disabled={disabledField}
                required={_.includes(requiredFields, 'hasShowBox')}
                name="showbox"
                suggestions={this.state.suggestions.showboxDisplaySigns}
                placeholder={t('Признак выкладки шоубокс')}
                handleSuggestionsFetchRequested={(value) =>
                  this.handleSuggestionsFetchRequested(
                    'showboxDisplaySigns',
                    value,
                    'hasShowBox',
                  )
                }
                handleSuggestionsClearRequested={() =>
                  this.handleSuggestionsFetchRequested('showboxDisplaySigns')
                }
                renderSuggestion={renderSuggestion}
                getSuggestionValue={(value) =>
                  this.getSuggestionValue(value, 'hasShowBox')
                }
                initValue={getDefaultTo(this.props, 'data.hasShowBox')}
                error={this.isErrorAndNotEmptyErrorField('hasShowBox')}
              />
            </MuiThemeProvider>
          )}

        {/* for using in future */}
        {/* {_.includes(["trade-unit", "inner-pack", "box"], this.props.data.level) &&
                    <MuiThemeProvider theme={defaultTheme}>
                        {this.isErrorAndNotEmptyErrorField('purpose') &&
                            this.moderateTooltip(`tooltip_info_moderate8_${id}`, 'purpose')
                        }

                        <CustomInput
                            disabled={disabledField}
                            required={_.includes(requiredFields, 'purpose')}
                            id={`purpose_${id}`}
                            name="purpose"
                            label={t('Назначение упаковки')}
                            isValidateText
                            onValidate={onValidate}
                            onUpdate={() => {}}
                            onChange={value => this.handleChange('purpose', value)}
                            type="text"
                            value={getDefaultTo(this.props, 'data.purpose')}
                            error={this.isErrorAndNotEmptyErrorField('purpose')}
                            dirty={this.isErrorAndNotEmptyErrorField('purpose')}
                        />
                    </MuiThemeProvider>
                } */}

        {this.props.data.level !== 'pallet' &&
          !_.includes(['transport', 'group'], this.props.packingPurpose) &&
          mainCode !== '21' && (
            <GridBlockA>
              <MuiThemeProvider theme={packageSelectTheme}>
                <GridItemA1>
                  <Element
                    key={`packageType_${id}`}
                    name={`id_packageType_${id}`}>
                    <FormControl fullWidth={true}>
                      {this.isErrorAndNotEmptyErrorField('packageType') &&
                        this.moderateTooltip(
                          `tooltip_info_moderate9_${id}`,
                          'packageType',
                        )}
                      <InputLabel
                        htmlFor="packageType-select"
                        //disabled={disabledField}
                        disabled={
                          preview || this.getDisabledVghList('packageType')
                        }
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageType',
                        )}>
                        {t('Тип упаковки')}
                      </InputLabel>
                      <Select
                        //disabled={disabledField}
                        disabled={
                          preview || this.getDisabledVghList('packageType')
                        }
                        required={_.includes(requiredFields, 'packageType')}
                        valid={_.toString(
                          !!packageType &&
                            !this.isErrorAndNotEmptyErrorField('packageType'),
                        )}
                        value={packageType || ''}
                        error={this.isErrorAndNotEmptyErrorField('packageType')}
                        inputProps={{ id: 'packageType-select' }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        IconComponent={IconLogoDropDown}
                        onChange={(event) =>
                          this.handleChange('packageType', event.target.value)
                        }>
                        {_.map(
                          this.props.dictionaryPackageTypes,
                          (item, index) => (
                            <MenuItem
                              key={`${item.id}_${index}`}
                              value={item.id}>
                              {item.text}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Element>

                  {/*<Autocomplete
                                        disabled={disabledField}
                                        required={_.includes(requiredFields, 'packageType')}
                                        // onlyFromList
                                        name="packageType"
                                        suggestions={this.state.suggestions.packageTypes}
                                        placeholder={t('Тип упаковки')}
                                        isValidateText
                                        onValidate={onValidate}
                                        handleSuggestionsFetchRequested={
                                            (value) => this.handleSuggestionsFetchRequested('packageTypes', value, 'packageType')
                                        }
                                        handleSuggestionsClearRequested={
                                            () => this.handleSuggestionsFetchRequested('packageTypes')
                                        }
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={(value) => this.getSuggestionValue(value, 'packageType')}
                                        initValue={packageType}
                                        error={this.isErrorAndNotEmptyErrorField('packageType')}
                                    />*/}
                </GridItemA1>
                <GridItemB1>
                  <Element
                    key={`packageMaterial_${id}`}
                    name={`id_packageMaterial_${id}`}>
                    <FormControl fullWidth={true}>
                      {this.isErrorAndNotEmptyErrorField('packageMaterial') &&
                        this.moderateTooltip(
                          `tooltip_info_moderate10_${id}`,
                          'packageMaterial',
                        )}
                      <InputLabel
                        htmlFor="packageMaterial-select"
                        //disabled={disabledField}
                        disabled={
                          preview || this.getDisabledVghList('packageMaterial')
                        }
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageMaterial',
                        )}>
                        {t('Материал упаковки')}
                      </InputLabel>
                      <Select
                        //disabled={disabledField}
                        disabled={
                          preview || this.getDisabledVghList('packageMaterial')
                        }
                        required={_.includes(requiredFields, 'packageMaterial')}
                        valid={_.toString(
                          !!packageMaterial &&
                            !this.isErrorAndNotEmptyErrorField(
                              'packageMaterial',
                            ),
                        )}
                        value={packageMaterial || ''}
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageMaterial',
                        )}
                        inputProps={{ id: 'packageMaterial-select' }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        IconComponent={IconLogoDropDown}
                        onChange={(event) =>
                          this.handleChange(
                            'packageMaterial',
                            event.target.value,
                          )
                        }>
                        {_.map(
                          this.props.dictionaryPackageMaterials,
                          (item, index) => (
                            <MenuItem
                              key={`${item.id}_${index}`}
                              value={item.id}>
                              {item.text}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Element>

                  {/*<Autocomplete
                                        disabled={disabledField}
                                        required={_.includes(requiredFields, 'packageMaterial')}
                                        // onlyFromList
                                        name="packageMaterial"
                                        suggestions={this.state.suggestions.packageMaterials}
                                        placeholder={t('Материал упаковки')}
                                        isValidateText
                                        onValidate={onValidate}
                                        handleSuggestionsFetchRequested={
                                            (value) => this.handleSuggestionsFetchRequested('packageMaterials', value, 'packageMaterial')
                                        }
                                        handleSuggestionsClearRequested={
                                            () => this.handleSuggestionsFetchRequested('packageMaterials')
                                        }
                                        renderSuggestion={renderSuggestion}
                                        getSuggestionValue={(value) => this.getSuggestionValue(value, 'packageMaterial')}
                                        initValue={packageMaterial}
                                        error={this.isErrorAndNotEmptyErrorField('packageMaterial')}
                                    />*/}
                </GridItemB1>
              </MuiThemeProvider>
              <MuiThemeProvider theme={defaultTheme}>
                <GridItemC1>
                  <PackInfoHelperCont>
                    <InputContainer>
                      <Element
                        key={`alphabetCode_${id}`}
                        name={`id_alphabetCode_${id}`}>
                        <CustomInput
                          disabled={true}
                          // required={_.includes(requiredFields, 'alphabetCode ')}
                          id={`alphabetCode_${id}`}
                          name="alphabetCode"
                          label={t('Буквенный код')}
                          value={_.toString(
                            getDefaultTo(this.props, 'data.alphabetCode'),
                          )}
                          onChange={() => {}}
                          onUpdate={() => {}}
                          type="text"
                          // error={this.isErrorWDH('alphabetCode ')}
                          // dirty={this.isErrorWDH('alphabetCode ')}
                        />
                      </Element>
                      {/* {!!this.state.errors.alphabetCode  && SimpleTooltip(this.state.errors.alphabetCode , <IconError />)} */}
                    </InputContainer>
                  </PackInfoHelperCont>
                </GridItemC1>
                <GridItemD1>
                  <PackInfoHelperCont>
                    <InputContainer>
                      <Element
                        key={`digitCode_${id}`}
                        name={`id_digitCode_${id}`}>
                        <CustomInput
                          disabled={true}
                          required={_.includes(requiredFields, 'width')}
                          id={`digitCode_${id}`}
                          name="digitCode"
                          label={t('Цифровой код')}
                          value={_.toString(
                            getDefaultTo(this.props, 'data.digitCode'),
                          )}
                          onChange={(value) =>
                            this.handleChange('digitCode', value)
                          }
                          onUpdate={() => {}}
                          type="text"
                          // error={this.isErrorWDH('digitCode')}
                          // dirty={this.isErrorWDH('digitCode')}
                        />
                      </Element>
                      {/* {!!this.state.errors.digitCode && SimpleTooltip(this.state.errors.digitCode, <IconError />)} */}
                    </InputContainer>
                  </PackInfoHelperCont>
                </GridItemD1>
              </MuiThemeProvider>
            </GridBlockA>
          )}

        {/**
         * посмотреть по какому именно типу выводить этот блок
         */}
        {_.includes(['transport', 'group'], this.props.packingPurpose) && (
          <GridBlockB>
            <MuiThemeProvider theme={packageSelectTheme}>
              <GridItemSmall>
                <Element
                  key={`packageType_${id}`}
                  name={`id_packageType_${id}`}>
                  <FormControl fullWidth={true}>
                    {this.isErrorAndNotEmptyErrorField('packageType') &&
                      this.moderateTooltip(
                        `tooltip_info_moderate9_${id}`,
                        'packageType',
                      )}

                    <InputLabel
                      htmlFor="packageType-select"
                      //disabled={disabledField}
                      disabled={
                        preview || this.getDisabledVghList('packageType')
                      }
                      error={this.isErrorAndNotEmptyErrorField('packageType')}>
                      {t('Тип упаковки')}
                    </InputLabel>
                    <Select
                      //disabled={disabledField}
                      disabled={
                        preview || this.getDisabledVghList('packageType')
                      }
                      required={_.includes(requiredFields, 'packageType')}
                      valid={_.toString(
                        !!packageType &&
                          !this.isErrorAndNotEmptyErrorField('packageType'),
                      )}
                      value={packageType || ''}
                      error={this.isErrorAndNotEmptyErrorField('packageType')}
                      inputProps={{ id: 'packageType-select' }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      IconComponent={IconLogoDropDown}
                      onChange={(event) =>
                        this.handleChange('packageType', event.target.value)
                      }>
                      {_.map(
                        this.props.dictionaryPackageTypes,
                        (item, index) => (
                          <MenuItem key={`${item.id}_${index}`} value={item.id}>
                            {item.text}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Element>
              </GridItemSmall>
              <GridItemC>
                <Element
                  key={`packageMaterial_${id}`}
                  name={`id_packageMaterial_${id}`}>
                  <FormControl fullWidth={true}>
                    {this.isErrorAndNotEmptyErrorField('packageMaterial') &&
                      this.moderateTooltip(
                        `tooltip_info_moderate10_${id}`,
                        'packageMaterial',
                      )}
                    <InputLabel
                      htmlFor="packageMaterial-select"
                      //disabled={disabledField}
                      disabled={
                        preview || this.getDisabledVghList('packageMaterial')
                      }
                      error={this.isErrorAndNotEmptyErrorField(
                        'packageMaterial',
                      )}>
                      {t('Материал упаковки')}
                    </InputLabel>
                    <Select
                      //disabled={disabledField}
                      disabled={
                        preview || this.getDisabledVghList('packageMaterial')
                      }
                      required={_.includes(requiredFields, 'packageMaterial')}
                      valid={_.toString(
                        !!packageMaterial &&
                          !this.isErrorAndNotEmptyErrorField('packageMaterial'),
                      )}
                      // value={_.isEmpty(this.props.dictionaryPackageMaterials) ? '' : packageMaterial}
                      value={packageMaterial || ''}
                      error={this.isErrorAndNotEmptyErrorField(
                        'packageMaterial',
                      )}
                      inputProps={{ id: 'packageMaterial-select' }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}
                      IconComponent={IconLogoDropDown}
                      onChange={(event) =>
                        this.handleChange('packageMaterial', event.target.value)
                      }>
                      {_.map(
                        this.props.dictionaryPackageMaterials,
                        (item, index) => (
                          <MenuItem key={`${item.id}_${index}`} value={item.id}>
                            {item.text}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </Element>
              </GridItemC>
              <GridItemSmallC>
                <MuiThemeProvider theme={defaultTheme}>
                  <PackInfoHelperCont>
                    <InputContainer>
                      <Element
                        key={`alphabetCode_${id}`}
                        name={`id_alphabetCode_${id}`}>
                        <CustomInput
                          disabled={true}
                          // required={_.includes(requiredFields, 'width')}
                          id={`alphabetCode_${id}`}
                          name="alphabetCode"
                          label={t('Буквенный код')}
                          value={_.toString(
                            getDefaultTo(this.props, 'data.alphabetCode'),
                          )}
                          // onChange={value => this.handleChange('alphabetCode', value)}
                          onUpdate={() => {}}
                          onChange={() => {}}
                          type="text"
                          // error={this.isErrorWDH('width')}
                          // dirty={this.isErrorWDH('width')}
                        />
                      </Element>
                      {/* {!!this.state.errors.width && SimpleTooltip(this.state.errors.width, <IconError/>)} */}
                    </InputContainer>
                  </PackInfoHelperCont>
                </MuiThemeProvider>
              </GridItemSmallC>
              <GridItemSmallD>
                <MuiThemeProvider theme={defaultTheme}>
                  <PackInfoHelperCont>
                    <InputContainer>
                      <Element
                        key={`digitCode_${id}`}
                        name={`id_digitCode_${id}`}>
                        <CustomInput
                          disabled={true}
                          required={_.includes(requiredFields, 'width')}
                          id={`digitCode_${id}`}
                          name="digitCode"
                          label={t('Цифровой код')}
                          value={_.toString(
                            getDefaultTo(this.props, 'data.digitCode'),
                          )}
                          onChange={(value) =>
                            this.handleChange('digitCode', value)
                          }
                          onUpdate={() => {}}
                          type="text"
                          // error={this.isErrorWDH('width')}
                          // dirty={this.isErrorWDH('width')}
                        />
                      </Element>
                      {/* {!!this.state.errors.width && SimpleTooltip(this.state.errors.width, <IconError/>)} */}
                    </InputContainer>
                  </PackInfoHelperCont>
                </MuiThemeProvider>
              </GridItemSmallD>
            </MuiThemeProvider>
          </GridBlockB>
        )}

        {/* {_.includes(["transport", "multiple"], this.props.data.type) === true ? ( */}
        {_.includes(['transport', 'group'], this.props.packingPurpose) ===
        true ? (
          <GridBlockC>
            <MuiThemeProvider theme={defaultTheme}>
              {this.isErrorAndNotEmptyErrorField('comment') &&
                this.moderateTooltip(
                  `tooltip_info_moderate11_${id}`,
                  'comment',
                )}

              <GridItemSmall>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`width_${id}`} name={`id_width_${id}`}>
                      <CustomInput
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('width')}
                        required={_.includes(requiredFields, 'width')}
                        id={`width_${id}`}
                        name="width"
                        label={t('Ширина')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.width'),
                        )}
                        onChange={(value) => this.handleChange('width', value)}
                        onPaste={this.handlePaste('width', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        valid={!!data.width}
                        helperText="см"
                        error={this.isErrorWDH('width')}
                        dirty={this.isErrorWDH('width')}
                      />
                    </Element>
                    {!!this.state.errors.width &&
                      SimpleTooltip(this.state.errors.width, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmall>
              <GridItemSmallA>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`height_${id}`} name={`id_height_${id}`}>
                      <CustomInput
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('height')}
                        required={_.includes(requiredFields, 'height')}
                        id={`height_${id}`}
                        name="height"
                        label={t('Высота')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.height'),
                        )}
                        onChange={(value) => this.handleChange('height', value)}
                        onPaste={this.handlePaste('height', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        valid={!!data.height}
                        helperText="см"
                        error={this.isErrorWDH('height')}
                        dirty={this.isErrorWDH('height')}
                      />
                    </Element>
                    {!!this.state.errors.height &&
                      SimpleTooltip(this.state.errors.height, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallA>
              <GridItemSmallB>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`length_${id}`} name={`id_length_${id}`}>
                      <CustomInput
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('length')}
                        required={_.includes(requiredFields, 'length')}
                        id={`length_${id}`}
                        name="length"
                        label={t('Глубина')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.length'),
                        )}
                        onChange={(value) => this.handleChange('length', value)}
                        onPaste={this.handlePaste('length', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        valid={!!data.length}
                        helperText="см"
                        error={this.isErrorWDH('length')}
                        dirty={this.isErrorWDH('length')}
                      />
                    </Element>
                    {!!this.state.errors.length &&
                      SimpleTooltip(this.state.errors.length, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallB>
              <GridItemSmallC>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`weight_${id}`} name={`id_weight_${id}`}>
                      <CustomInput
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('weight')}
                        required={_.includes(requiredFields, 'weight')}
                        id={`weight_${id}`}
                        name="weight"
                        label={t('Вес брутто')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.weight'),
                        )}
                        onChange={(value) => this.handleChange('weight', value)}
                        onPaste={this.handlePaste('weight', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        helperText="кг"
                        valid={!!data.weight}
                        error={this.isErrorWDH('weight')}
                        dirty={this.isErrorWDH('weight')}
                      />
                    </Element>
                    {!!this.state.errors.weight &&
                      SimpleTooltip(this.state.errors.weight, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallC>
              {/* <GridItemD>
                                <PackInfoHelperCont>
                                    <InputContainer>
                                        <Element key={`packingCapacity_${id}`} name={`id_packingCapacity_${id}`}>
                                            <CustomInput
                                                disabled={disabledField}
                                                required={_.includes(requiredFields, 'capacity')}
                                                id={`capacity_${id}`}
                                                name="capacity"
                                                label={t('Вместимость упаковки')}
                                                value={getDefaultTo(this.props, 'data.capacity')}
                                                onChange={value => this.handleChange('capacity', value)}
                                                onUpdate={() => { }}
                                                type="number"
                                                error={this.isErrorWDH('capacity')}
                                                dirty={this.isErrorWDH('capacity')}
                                            />
                                        </Element>
                                        {!!this.state.errors.capacity && SimpleTooltip(this.state.errors.capacity, <IconError />)}
                                    </InputContainer>
                                </PackInfoHelperCont>
                            </GridItemD> */}
            </MuiThemeProvider>
          </GridBlockC>
        ) : (
          <GridBlockA>
            <MuiThemeProvider theme={defaultTheme}>
              {this.isErrorAndNotEmptyErrorField('comment') &&
                this.moderateTooltip(
                  `tooltip_info_moderate11_${id}`,
                  'comment',
                )}

              <GridItemSmall>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`width_${id}`} name={`id_width_${id}`}>
                      <CustomInput
                        hideCleanButton={hideStatisticButtons}
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('width')}
                        required={_.includes(requiredFields, 'width')}
                        id={`width_${id}`}
                        name="width"
                        label={t('Ширина')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.width'),
                        )}
                        onChange={(value) => this.handleChange('width', value)}
                        onPaste={this.handlePaste('width', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        valid={!!data.width}
                        helperText="см"
                        error={this.isErrorWDH('width')}
                        dirty={this.isErrorWDH('width')}
                      />
                    </Element>
                    {!!this.state.errors.width &&
                      SimpleTooltip(this.state.errors.width, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmall>
              <GridItemSmallA>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`height_${id}`} name={`id_height_${id}`}>
                      <CustomInput
                        hideCleanButton={hideStatisticButtons}
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('height')}
                        required={_.includes(requiredFields, 'height')}
                        id={`height_${id}`}
                        name="height"
                        label={t('Высота')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.height'),
                        )}
                        onChange={(value) => this.handleChange('height', value)}
                        onPaste={this.handlePaste('height', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        valid={!!data.height}
                        helperText="см"
                        error={this.isErrorWDH('height')}
                        dirty={this.isErrorWDH('height')}
                      />
                    </Element>
                    {!!this.state.errors.height &&
                      SimpleTooltip(this.state.errors.height, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallA>
              <GridItemSmallB>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`length_${id}`} name={`id_length_${id}`}>
                      <CustomInput
                        hideCleanButton={hideStatisticButtons}
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('length')}
                        required={_.includes(requiredFields, 'length')}
                        id={`length_${id}`}
                        name="length"
                        label={t('Глубина')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.length'),
                        )}
                        onChange={(value) => this.handleChange('length', value)}
                        onPaste={this.handlePaste('lengt', typeId)}
                        valid={!!data.length}
                        onUpdate={() => {}}
                        type="text"
                        helperText="см"
                        error={this.isErrorWDH('length')}
                        dirty={this.isErrorWDH('length')}
                      />
                    </Element>
                    {!!this.state.errors.length &&
                      SimpleTooltip(this.state.errors.length, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallB>
              <GridItemSmallC>
                <PackInfoHelperCont>
                  <InputContainer>
                    <Element key={`weight_${id}`} name={`id_weight_${id}`}>
                      <CustomInput
                        hideCleanButton={hideStatisticButtons}
                        //disabled={disabledField}
                        disabled={preview || this.getDisabledVghList('weight')}
                        required={_.includes(requiredFields, 'weight')}
                        id={`weight_${id}`}
                        name="weight"
                        label={t('Вес брутто')}
                        value={_.toString(
                          getDefaultTo(this.props, 'data.weight'),
                        )}
                        onChange={(value) => this.handleChange('weight', value)}
                        onPaste={this.handlePaste('weight', typeId)}
                        onUpdate={() => {}}
                        type="text"
                        helperText="кг"
                        valid={!!data.weight}
                        error={this.isErrorWDH('weight')}
                        dirty={this.isErrorWDH('weight')}
                      />
                    </Element>
                    {!!this.state.errors.weight &&
                      SimpleTooltip(this.state.errors.weight, <IconError />)}
                  </InputContainer>
                </PackInfoHelperCont>
              </GridItemSmallC>
            </MuiThemeProvider>
          </GridBlockA>
        )}

        {/* {!_.includes(["transport", "group"], this.props.packingPurpose) &&
                    <FormControl fullWidth={true}>
                        {this.isErrorAndNotEmptyErrorField('capacity') &&
                            this.moderateTooltip(`tooltip_info_moderate12_${id}`, 'capacity')
                        }
                        <InputContainer>
                            <Element key={`capacity_${id}`} name={`id_capacity_${id}`}>
                                <CustomInput
                                    disabled={disabledField}
                                    required={_.includes(requiredFields, 'capacity')}
                                    id={`capacity_${id}`}
                                    name="capacity"
                                    label={t('Вместимость упаковки')}
                                    value={getDefaultTo(this.props, 'data.capacity')}
                                    onChange={value => this.handleChange('capacity', value)}
                                    onUpdate={() => { }}
                                    type="number"
                                    error={this.isErrorWDH('capacity')}
                                    dirty={this.isErrorWDH('capacity')}
                                />
                            </Element>
                            {!!this.state.errors.capacity && SimpleTooltip(this.state.errors.capacity, <IconError />)}
                        </InputContainer>
                    </FormControl>
                } */}

        {!_.includes(['transport', 'group'], this.props.packingPurpose) &&
          mainCode !== '21' &&
          !productData.isTechCard && (
            <GridBlockA>
              <MuiThemeProvider theme={packageSelectTheme}>
                <GridItemA>
                  <Element
                    key={`packageTypeCapping_${id}`}
                    name={`id_packageTypeCapping_${id}`}>
                    <FormControl fullWidth={true}>
                      {this.isErrorAndNotEmptyErrorField(
                        'packageTypeCapping',
                      ) &&
                        this.moderateTooltip(
                          `tooltip_info_moderate14_${id}`,
                          'packageTypeCapping',
                        )}
                      <InputLabel
                        htmlFor="packageTypeCapping-select"
                        //disabled={disabledField}
                        disabled={
                          preview ||
                          this.getDisabledVghList('packageTypeCapping')
                        }
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageTypeCapping',
                        )}>
                        {t('Тип укупорочного средства')}
                      </InputLabel>
                      <Select
                        //disabled={disabledField}
                        disabled={
                          preview ||
                          this.getDisabledVghList('packageTypeCapping')
                        }
                        required={_.includes(
                          requiredFields,
                          'packageTypeCapping',
                        )}
                        valid={_.toString(!!packageTypeCapping)}
                        // value={getDefaultTo(this.props, 'data.packageTypeCapping')}
                        value={packageTypeCapping || ''}
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageTypeCapping',
                        )}
                        inputProps={{ id: 'packageTypeCapping-select' }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        IconComponent={IconLogoDropDown}
                        onChange={(event) =>
                          this.handleChange(
                            'packageTypeCapping',
                            event.target.value,
                          )
                        }>
                        {_.map(
                          this.props.dictionaryPackageTypesCapping,
                          (item, index) => (
                            <MenuItem
                              key={`${item.id}_${index}`}
                              value={item.id}>
                              {item.text}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Element>
                </GridItemA>
                <GridItemB>
                  <Element
                    key={`packageMaterialCapping_${id}`}
                    name={`id_packageMaterialCapping_${id}`}>
                    <FormControl fullWidth={true}>
                      {this.isErrorAndNotEmptyErrorField(
                        'packageMaterialCapping',
                      ) &&
                        this.moderateTooltip(
                          `tooltip_info_moderate15_${id}`,
                          'packageMaterialCapping',
                        )}
                      <InputLabel
                        htmlFor="packageMaterialCapping-select"
                        //disabled={disabledField}
                        disabled={
                          preview ||
                          this.getDisabledVghList('packageMaterialCapping')
                        }
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageMaterialCapping',
                        )}>
                        {t('Материал укупорочного средства')}
                      </InputLabel>
                      <Select
                        //disabled={disabledField}
                        disabled={
                          preview ||
                          this.getDisabledVghList('packageMaterialCapping')
                        }
                        required={_.includes(
                          requiredFields,
                          'packageMaterialCapping',
                        )}
                        valid={_.toString(!!packageMaterialCapping)}
                        // value={getDefaultTo(this.props, 'data.packageMaterialCapping')}
                        value={packageMaterialCapping || ''}
                        error={this.isErrorAndNotEmptyErrorField(
                          'packageMaterialCapping',
                        )}
                        inputProps={{ id: 'packageMaterialCapping-select' }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        IconComponent={IconLogoDropDown}
                        onChange={(event) =>
                          this.handleChange(
                            'packageMaterialCapping',
                            event.target.value,
                          )
                        }>
                        {_.map(
                          this.props.dictionaryPackageMaterialsCapping,
                          (item, index) => (
                            <MenuItem
                              key={`${item.id}_${index}`}
                              value={item.id}>
                              {item.text}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Element>
                </GridItemB>
              </MuiThemeProvider>
            </GridBlockA>
          )}

        {this.state.showConfirmDelete && (
          <ConfirmDialog
            acceptBtnText={t('Удалить')}
            dismissBtnText={t('Отмена')}
            onAccept={this.onDeleteConfirm}
            onDismiss={this.onShowDeleteDialog}
            onCancel={this.onShowDeleteDialog}
            message={this.state.confirmDeleteMessage}
          />
        )}
      </React.Fragment>
    );
  }
}

PackageInformationItem.propTypes = {
  updateItem: PropTypes.func.isRequired,
  updateConnectedFields: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  handleWdhErrors: PropTypes.func.isRequired,
  handleGtinStatus: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  dictionaryShowboxDisplaySigns: PropTypes.array.isRequired,
  dictionaryPackageTypes: PropTypes.array.isRequired,
  dictionaryPackageMaterials: PropTypes.array.isRequired,
  dictionaryPalletTypes: PropTypes.array.isRequired,
  dictionaryPackageLetterNumeralCode: PropTypes.array.isRequired,
  dictionaryPackageTypesCapping: PropTypes.array.isRequired,
  dictionaryPackageMaterialsCapping: PropTypes.array.isRequired,
  requiredFields: PropTypes.array.isRequired,
  limites: PropTypes.object.isRequired,
  gtinTradeUnit: PropTypes.string.isRequired,
  gtinInners: PropTypes.array,
  errors: PropTypes.object,
  preview: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  accountingSystem: PropTypes.string,
};

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  accountingSystem: _.get(
    state.authentificationReducer,
    'user.accountingSystem',
    null,
  ),
  locale: state.profileReducer.locale,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      validateGtinPackage: (gtin, level, cbk) =>
        validateGtinPackage(gtin, level, cbk),
      generationGtin: (data, cbk) => generationGtin(data, cbk),
    },
    dispatch,
  );
};
export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PackageInformationItem);
