import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  Button,
  FormControlLabel,
  MuiThemeProvider,
  Popover,
  Radio,
} from '@material-ui/core';
import {
  IconClose,
  IconPulse,
  IconPulseOrange,
} from '../../../../common/icons/customIcons';
import {
  DefaultFilterPopover,
  FilterBtn,
  FilterBtns,
  FilterContainer,
  FilterContent,
  FilterTitle,
} from './Filters.styled';

class IsValidFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isValid: !!this.props.currentValue,
      isDirty: !_.isNull(this.props.currentValue),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (_.isNull(nextProps.currentValue) && !_.isNull(state.currentValue)) {
      return {
        anchorEl: null,
        isValid: false,
        isDirty: false,
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(nextState, this.state);
  }

  handleClick = (event) => {
    let element = document.getElementById(this.props.parentId);
    this.setState({
      anchorEl: !!element ? element : event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    this.setState({
      anchorEl: null,
      currentValue: this.state.isValid,
    });
    this.props.onAccept('isValid', this.state.isValid);
  };

  handleDissmiss = () => {
    this.setState({
      isDirty: false,
      anchorEl: null,
    });
    this.props.onDismiss('isValid');
  };

  handleChange = (value) => {
    this.setState({
      isDirty: true,
      isValid: value,
    });
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <FilterContainer>
        <MuiThemeProvider theme={FilterBtn}>
          <Button
            aria-owns={open ? 'simple-popper' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}>
            {/* <IconPulse style={{cursor: 'pointer'}}/> */}
            {_.isNull(this.props.currentValue) ? (
              <IconPulse />
            ) : (
              <IconPulseOrange />
            )}
          </Button>
        </MuiThemeProvider>
        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Обязательные атрибуты заполнены')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>
            <FilterContent>
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.isValid && this.state.isDirty}
                    onChange={() => this.handleChange(true)}
                  />
                }
                label={t('Да')}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!this.state.isValid && this.state.isDirty}
                    onChange={() => this.handleChange(false)}
                  />
                }
                label={t('Нет')}
              />
            </FilterContent>
            <FilterBtns>
              <Button
                disabled={!this.state.isDirty}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
              <Button
                disabled={!this.state.isDirty}
                className="backEmpty"
                onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </FilterContainer>
    );
  }
}

IsValidFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.bool,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(IsValidFilter);
