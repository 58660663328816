import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import _, { isNull } from 'lodash';
import { Button, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { throwNotification } from '../../../../common/structure';
import { ConfirmDialog, Pagination, Preloader, NoDataComponent } from '../../../../components';
import AddSubAccountModal from './modal/AddSubAccount';
import EditSubAccountModal from './modal/EditSubAccount';
import GridActionsItems from './gridActions/GridActionsItems';
import AddGCP from './modal/AddGCP';
import {
  IconGridEmpty,
  IconPlusBlue,
  IconEmail,
} from '../../../../common/icons/customIcons';
import AddLetter from './modal/AddLetter';
import SubAccountFileView from './modal/SubAccountFileView';
import { ColumnCellProfile } from '../../../products/productGrid/ProductsGrid.styled';
import { loadCountries } from '../../../product/ducks/Product.actions';
import {
  ColumnCellInner,
  ColumnHeaderProfile,
  DefaultGridButton,
  GridActionsRight,
  GridEmptyText,
  GridTableContainer,
  ManagementSiteActions,
  ManagementSiteCont,
  TableEmpty,
  WithoutSortingBtn,
} from "../../../../common/styles/Common.styled";
import * as selectors from "../../ducks/SubAccount/SubAccount.selectors";
// import TmNoDataComponent from '../../../../common/utils/TmNoDataComponent';
import {
  CompanyTypeFilter,
  INNTypeFilter,
  GCPTypeFilter,
  GLNTypeFilter,
  SystemMember,
  GcpGlnConfirmationMails,
  OrderMarkedCode,
  Country,
  LegalAddress,
} from './filters';
import {
  deleteSubAccount,
  loadSubAccount,
  grantPermissionForOrderKm,
  revokePermissionForOrderKm,
} from '../../ducks/SubAccount/SubAccount.actions';
import { clippedString } from '../../../../common/utils/utils';
// import { clippedString } from '../../../../common/utils/utils';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const FiltersInit = {
  name: null,
  ITN: null,
  GCP: null,
  GLN: null,
  isMemberELK: null,
  subAccountsGcpGlnConfirmationMails: null,
  canOrderMarkedCode: null,
  country: null,
  address: null,
};

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);
class SubAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: FiltersInit,
      pageSize: 10,
      pageNum: 0,
      currentRow: null,
      deleteIds: [],
      modalConfirm: ConfirmStateInit,
      isVisibleModalCreate: false,
      isVisibleModalEdit: false,
      isVisibleModalAddGCP: false,
      isVisibleModalAddLetter: false,
      modalViewFile: {
        isOpen: false,
        viewFileModalType: '',
        subaccountData: {},
      },
      loading: false,
    };
    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
  }

  componentDidMount() {
    const filters = localStorage.getItem('savedFiltersSubAccount');
    if (filters) {
      this.setState({ filters: JSON.parse(filters) }, this.changeGridParams);
    } else {
      this.changeGridParams();
    }
    if (_.isEmpty(this.props.countries)) {
      this.props.loadCountries();
    }
  }

  changeGridParams = () => {
    let query = 'profile=true';
    const { filters } = this.state;

    Object.keys(filters).forEach((item) => {
      if (!isNull(filters[item])) {
        const field = String(filters[item]);
        const needEncode = item === 'address' || item === 'name';
        query += `&${item}=${needEncode ? encodeURIComponent(field) : field}`;
      }
    });

    const params = {
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
      query: query,
    };
    this.props.onLoadSubAccountData(params);
  };

  getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  confirmModal = (confirm, state = {}) => {
    const s = _.assign({}, state);
    this.setState((prevState) => ({
      ...prevState,
      ...s,
      modalConfirm: confirm,
    }));
  };

  onDelete = (id) => {
    const { t } = this.props;
    const ids = [id];
    const message = t(`Вы действительно хотите удалить субаккаунт?`);

    const objModal = {
      isOpen: true,
      message: message,
      funcAccept: this.onDeleteAccept,
      funcDismiss: this.onDeleteDissmiss,
      funcCancel: this.onDeleteDissmiss,
      acceptText: t('Удалить'),
      dismissText: t('Отменить'),
    };
    this.confirmModal(objModal, { deleteIds: ids });
  };

  onDeleteAccept = () => {
    const { t } = this.props;
    const state = { deleteIds: [] };
    const deleteIds = _.clone(this.state.deleteIds);
    this.confirmModal(ConfirmStateInit, state);
    this.props.onDeleteSubAccount(deleteIds[0], (error) => {
      const message = t(`Карточка субаккаунта успешно удалена`);
      if (_.isNull(error)) {
        this.props.throwNotification(message, 'success');
        this.changeGridParams();
      }
    });
  };

  onDeleteDissmiss = () => {
    this.confirmModal(ConfirmStateInit, { deleteIds: [] });
  };

  handleOnRequestOpen = () => {
    this.setState((prevState) => ({
      isVisibleModalCreate: !prevState.isVisibleModalCreate,
    }));
  };
  handleOnEditOpen = (id) => {
    this.setState((prevState) => ({
      currentRow: id,
      isVisibleModalEdit: !prevState.isVisibleModalEdit,
    }));
  };
  handleOnAddGCP = (id) => {
    this.setState((prevState) => ({
      currentRow: id,
      isVisibleModalAddGCP: !prevState.isVisibleModalAddGCP,
    }));
  };

  handleAddLetterOpen = (id) => {
    this.setState((prevState) => ({
      currentRow: id,
      isVisibleModalAddLetter: !prevState.isVisibleModalAddLetter,
    }));
  };

  handleViewFileOpen = (type, subaccountData) => {
    if (this.state.modalViewFile.isOpen) {
      this.changeGridParams();
    }
    this.setState((prevState) => ({
      modalViewFile: {
        ...prevState.modalViewFile,
        isOpen: !prevState.modalViewFile.isOpen,
        viewFileModalType: type,
        subaccountData,
      },
    }));
  };

  // grantKmOrderPermission = (id, IsMemberELK) => {
  //   const { t } = this.props;
  //   if (!IsMemberELK) {
  //     const errT = t(
  //       `Предоставление права заказа КМ доступно только для Ваших субаккаунтов, которые являются участниками маркировки`,
  //     );
  //     return this.props.throwNotification(errT, 'error');
  //   }

  //   return this.props.grantKmPermission(id, (error) => {
  //     if (_.isNull(error)) {
  //       this.changeGridParams();
  //     }
  //   });
  // };

  // revokeKmOrderPermission = (id, IsMemberELK, kmPermission) => {
  //   const { t } = this.props;
  //   if (!kmPermission) {
  //     const errT = t(
  //       `Отменить право заказа КМ доступно только для тех, у кого такие права есть`,
  //     );
  //     return this.props.throwNotification(errT, 'error');
  //   }
  //   return this.props.revokeKmPermission(id, (error) => {
  //     if (_.isNull(error)) {
  //       this.changeGridParams();
  //     }
  //   });
  // };

  onFilterAccept = (name, value) => {
    let savedFilters;
    savedFilters = localStorage.getItem('savedFiltersSubAccount');
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    if (!savedFilters) {
      savedFilters = _.cloneDeep(FiltersInit);
    }
    savedFilters[name] = value;
    localStorage.setItem(
      'savedFiltersSubAccount',
      JSON.stringify(savedFilters),
    );

    if (name && toString(value)) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, { [name]: value }),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
    }
  };

  onFilterDissmiss = (name) => {
    // удалить один из фильтров в localStorage
    let savedFilters = localStorage.getItem('savedFiltersSubAccount');
    savedFilters = JSON.parse(savedFilters);

    let filters = _.cloneDeep(this.state.filters);

    filters[name] = null;
    savedFilters[name] = null;

    localStorage.setItem(
      'savedFiltersSubAccount',
      JSON.stringify(savedFilters),
    );
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: filters,
        selectDTOItems: [],
      }),
      this.changeGridParams,
    );
  };

  resetFilters = () => {
    // убить все фильтры в localStorage
    localStorage.removeItem('savedFiltersSubAccount');
    this.setState(
      {
        filters: FiltersInit,
        selectDTOItems: [],
      },
      this.changeGridParams,
    );
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });

  paginateData = () => {
    const { data, totalObjects } = this.props;
    const {
      pageSize,
      pageNum,
    } = this.state;

    if (totalObjects <= 10 || !data) {
      return data;
    }

    const dataArr = [];

    for (let i = 0; i < Math.ceil(totalObjects / pageSize); i++) {
      dataArr[i] = data.slice((i * pageSize), (i * pageSize) + pageSize);
    }
    return dataArr[pageNum];
  }

  render() {
    const { loading, t, countries, } = this.props;
    const {
      modalConfirm,
      isVisibleModalCreate,
      isVisibleModalEdit,
      isVisibleModalAddLetter,
      isVisibleModalAddGCP,
      modalViewFile,
      currentRow,
    } = this.state;
    const data = this.paginateData();
    const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);

    return (
      <React.Fragment>
        {modalConfirm.isOpen &&
          <ConfirmDialog
            onAccept={modalConfirm.funcAccept}
            onDismiss={modalConfirm.funcDismiss}
            onCancel={modalConfirm.funcCancel}
            title={modalConfirm.title}
            message={modalConfirm.message}
            acceptBtnText={modalConfirm.acceptText}
            dismissBtnText={modalConfirm.dismissText}
          />
        }

        {isVisibleModalCreate &&
          <AddSubAccountModal
            handleClose={this.handleOnRequestOpen}
            changeGridParams={this.changeGridParams}
            countries={countries[0]}
          />
        }
         {isVisibleModalEdit&&
          <EditSubAccountModal
            handleClose={this.handleOnEditOpen}
            changeGridParams={this.changeGridParams}
            currentRow={currentRow}
            currentSubAccount={data.filter(el => el.AccountId === currentRow)}
            countries={countries[0]}
          />
        }
         {isVisibleModalAddGCP&&
          <AddGCP
            handleClose={this.handleOnAddGCP}
            changeGridParams={this.changeGridParams}
            currentRow={currentRow}
            currentSubAccount={data.filter(el => el.AccountId === currentRow)}            
          />
        }
        {isVisibleModalAddLetter &&
          <AddLetter
            handleClose={this.handleAddLetterOpen}
            changeGridParams={this.changeGridParams}
            currentRow={currentRow}
            currentSubAccount={data.filter(el => el.AccountId === currentRow)}
          />
        }

        {modalViewFile.isOpen &&
          <SubAccountFileView
            type={modalViewFile.viewFileModalType}
            handleClose={this.handleViewFileOpen}
            subaccountData={modalViewFile.subaccountData}
          />
        }

        <ManagementSiteCont>
          <ManagementSiteActions>
            <GridActionsRight>
              <MuiThemeProvider theme={DefaultGridButton}>
                <Button
                  disabled={loading}
                  onClick={this.handleOnRequestOpen}
                >
                  <IconPlusBlue style={{ marginRight: "10px" }} /> {t('Добавить субаккаунт')}
                </Button>
              </MuiThemeProvider>
            </GridActionsRight>
          </ManagementSiteActions>

          <GridTableContainer noData={!data.length && !IsNotActiveFilters} height="37vh" minHeight="10vh" className="customTable">
            {!data.length && IsNotActiveFilters
              ? <TableEmpty>
                <IconGridEmpty />
                <GridEmptyText>
                  <p>{t('У вас еще нет добавленных субаккаунтов')}.</p>
                </GridEmptyText>
              </TableEmpty>
              :
              <div style={{ display: loading ? "none" : "block", height: '100%' }}>
                <ReactTableFixedColumns
                  data={data}
                  columns={[
                    {
                      name: t('Наименование компании'),
                      show: true,
                      position: 0,
                      accessor: 'Name',
                      sortable: false,
                      minWidth: 320,
                      //maxWidth: 250,
                      Header: () => (
                        <ColumnHeaderProfile>
                          {t('Наименование компании')}
                          <WithoutSortingBtn />
                          <CompanyTypeFilter
                            parentId="name"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.name}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {(original.Name && original.Name.length > 85)
                              ? (
                                <Tooltip arrow placement="bottom-start" title={original.Name}>
                                  <div>{clippedString(original.Name, 85)}</div>
                                </Tooltip>
                              )
                              : original.Name || ''
                            }
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    {
                      name: t('Налоговый идентификатор'),
                      show: true,
                      position: 1,
                      accessor: 'ITN',
                      sortable: false,
                      width: 160,
                      Header: () => (
                        <ColumnHeaderProfile small>
                          {t('Налоговый идентификатор')}
                          <WithoutSortingBtn />
                          <INNTypeFilter
                            parentId="ITN"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.ITN}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {original.ITN}
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    {
                      name: 'GCP',
                      show: true,
                      position: 3,
                      accessor: 'GCP',
                      sortable: false,
                      width: 120,
                      Header: () => (
                        <ColumnHeaderProfile>
                          <span>GCP</span>
                          <WithoutSortingBtn />
                          <GCPTypeFilter
                            parentId="GCP"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.GCP}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {(original.GCP && _.split(original.GCP, ', ').length > 1)
                              ? (
                                <Tooltip arrow placement="bottom-start" title={original.GCP}>
                                  <div>
                                    {_.split(original.GCP, ', ')[0] + ', ...'}
                                  </div>
                                </Tooltip>
                              )
                              : original.GCP || ''
                            }
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    {
                      name: 'GLN',
                      show: true,
                      position: 3,
                      accessor: 'GLN',
                      sortable: false,
                      width: 150,
                      Header: () => (
                        <ColumnHeaderProfile id="glnGridFilter">
                          <span>GLN</span>
                          <WithoutSortingBtn />
                          <GLNTypeFilter
                            parentId="GLN"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.GLN}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {(original.GLN && _.split(original.GLN, ', ').length > 1)
                              ? (
                                <Tooltip arrow placement="bottom-start" title={original.GLN}>
                                  <div>
                                    {_.split(original.GLN, ', ')[0] + ', ...'}
                                  </div>
                                </Tooltip>
                              )
                              : original.GLN || ''
                            }
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    {
                      name: 'Является участником системы',
                      show: true,
                      position: 4,
                      accessor: 'IsMemberELK',
                      sortable: false,
                      width: 200,
                      Header: () => (
                        <ColumnHeaderProfile small>
                          {t('Является участником системы')}
                          <WithoutSortingBtn />
                          <SystemMember
                            parentId="isMemberELK"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.isMemberELK}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {original.IsMemberELK === true ? t('Да') : t('Нет')}
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    // {
                    //     name: 'Ответ GS1',
                    //     show: true,
                    //     position: 3,
                    //     accessor: 'ResponseGS1',
                    //     sortable: false,
                    //     width: 170,
                    //     Header: () => <ColumnHeaderProfile>{t('Ответ GS1')}</ColumnHeaderProfile>,
                    //     Cell: ({original}) => (
                    //         <ColumnCellProfile>
                    //             <ColumnCellInner>
                    //                 {original.ResponseGS1}
                    //             </ColumnCellInner>
                    //         </ColumnCellProfile>
                    //     )
                    // },
                    // {
                    //   name: 'Письмо/подтверждение GCP/GLN',
                    //   show: true,
                    //   position: 5,
                    //   accessor: 'subAccountsGcpGlnConfirmationMailsCount ',
                    //   sortable: false,
                    //   width: 200,
                    //   Header: () => (
                    //     <ColumnHeaderProfile small>
                    //       {t('Письмо/подтверждение GCP/GLN')}
                    //       <WithoutSortingBtn />
                    //       <GcpGlnConfirmationMails
                    //         parentId="subAccountsGcpGlnConfirmationMails"
                    //         onAccept={this.onFilterAccept}
                    //         onDismiss={this.onFilterDissmiss}
                    //         currentValue={this.state.filters.subAccountsGcpGlnConfirmationMails}
                    //       />
                    //     </ColumnHeaderProfile>
                    //   ),
                    //   Cell: ({ original }) => (
                    //     <ColumnCellProfile>
                    //       <ColumnCellInner>
                    //         {(_.isNumber(original.subAccountsGcpGlnConfirmationMailsCount) && original.subAccountsGcpGlnConfirmationMailsCount >= 1) && (
                    //           <div onClick={() => this.handleViewFileOpen('withGcpGln', original)} style={{ display: 'flex', cursor: 'pointer', height: '40px', alignItems: 'center' }}>
                    //             <IconEmail />
                    //             <div style={{ fontSize: '11px', alignSelf: 'flex-start' }}>{original.subAccountsGcpGlnConfirmationMailsCount}</div>
                    //           </div>
                    //         )}
                    //       </ColumnCellInner>
                    //     </ColumnCellProfile>
                    //   )
                    // },
                    // {
                    //     name: 'Письмо/подтверждение Товарных знаков',
                    //     show: true,
                    //     position: 5,
                    //     accessor: 'LetterTrademark',
                    //     sortable: false,
                    //     minWidth: 300,
                    //     Header: () => <ColumnHeaderProfile>{t('Письмо/подтверждение Товарных знаков')}</ColumnHeaderProfile>,
                    //     Cell: ({original}) => (
                    //         <ColumnCellProfile>
                    //             <ColumnCellInner>
                    //                 {/* return when backend is ready */}
                    //                 {/* {original.LetterTrademark ?
                    //                     <div onClick={() => this.handleViewFileOpen('withTradeMark', original.LetterTrademark)}><IconStatusDraft/></div>
                    //                     : 'Нет загруженных файлов'
                    //                 } */}
                    //             </ColumnCellInner>
                    //         </ColumnCellProfile>
                    //     )
                    // },
                    // {
                    //   name: 'Право заказа КМ',
                    //   show: true,
                    //   position: 6,
                    //   accessor: 'CanOrderMarkedCode',
                    //   sortable: false,
                    //   width: 170,
                    //   Header: () => (
                    //     <ColumnHeaderProfile small>
                    //       {t('Право заказа КМ')}
                    //       <WithoutSortingBtn />
                    //       <OrderMarkedCode
                    //         parentId="canOrderMarkedCode"
                    //         onAccept={this.onFilterAccept}
                    //         onDismiss={this.onFilterDissmiss}
                    //         currentValue={this.state.filters.canOrderMarkedCode}
                    //       />
                    //     </ColumnHeaderProfile>
                    //   ),
                    //   Cell: ({ original }) => (
                    //     <ColumnCellProfile>
                    //       <ColumnCellInner>
                    //         {original.canOrderMarkedCode === true ? t('Предоставлено') : ''}
                    //       </ColumnCellInner>
                    //     </ColumnCellProfile>
                    //   )
                    // },
                    {
                      name: 'Страна юридического адреса',
                      show: true,
                      position: 7,
                      accessor: 'Country',
                      sortable: false,
                      width: 200,
                      Header: () => (
                        <ColumnHeaderProfile small>
                          {t('Страна юридического адреса')}
                          <WithoutSortingBtn />
                          <Country
                            parentId="country"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.country}
                            countries={countries[0]}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {original.Country}
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    {
                      name: 'Юридический адрес',
                      show: true,
                      position: 8,
                      accessor: 'Address',
                      sortable: false,
                      minWidth: 230,
                      Header: () => (
                        <ColumnHeaderProfile>
                          {t('Юридический адрес')}
                          <WithoutSortingBtn />
                          <LegalAddress
                            parentId="address"
                            onAccept={this.onFilterAccept}
                            onDismiss={this.onFilterDissmiss}
                            currentValue={this.state.filters.address}
                          />
                        </ColumnHeaderProfile>
                      ),
                      Cell: ({ original }) => (
                        <ColumnCellProfile>
                          <ColumnCellInner>
                            {(original.Address && original.Address.length > 75)
                              ? (
                                <Tooltip arrow placement="bottom-start" title={original.Address}>
                                  <div>{clippedString(original.Address, 75)}</div>
                                </Tooltip>
                              )
                              : original.Address || ''
                            }
                          </ColumnCellInner>
                        </ColumnCellProfile>
                      )
                    },
                    // {
                    //     name: '',
                    //     show: true,
                    //     position: 9,
                    //     accessor: 'hasSubAccountsGcpGlnConfirmationMails',
                    //     sortable: false,
                    //     minWidth: 75,
                    //     Header: () => (
                    //         <ColumnHeaderProfile>
                    //             {''}
                    //         </ColumnHeaderProfile>
                    //     ),
                    //     Cell: ({original}) => (
                    //         <ColumnCellProfile>
                    //             <ColumnCellInner>
                    //                 {original.hasSubAccountsGcpGlnConfirmationMails && (
                    //                     <div onClick={() => this.handleViewFileOpen('withGcpGln', original)}><IconEmail/></div>
                    //                 )}
                    //             </ColumnCellInner>
                    //         </ColumnCellProfile>
                    //     )
                    // },
                    {
                      accessor: 'actions',
                      Header: '',
                      sortable: false,
                      filterable: false,
                      fixed: 'right',
                      width: 0,
                      Cell: ({ original }) => <GridActionsItems
                        // onLoadLetter={this.handleAddLetterOpen}
                        id={original.AccountId}
                        isElk={original.IsMemberELK}
                        kmPermission={original.CanOrderMarkedCode}
                        // grantKmPermission={this.grantKmOrderPermission}
                        // revokeKmPermission={this.revokeKmOrderPermission}
                        onDelete={this.onDelete}
                        onEdit={this.handleOnEditOpen}
                        onAddGCP={this.handleOnAddGCP}
                      />
                    }
                  ]}
                  defaultPageSize={this.state.pageSize}
                  className="-highlight"
                  page={this.state.pageNum}
                  pages={Math.ceil(
                    this.props.totalObjects / this.state.pageSize,
                  )}
                  pageSize={this.state.pageSize}
                  NoDataComponent={NoDataComponent}
                  PaginationComponent={Pagination}
                  getPaginationProps={this.getPaginationProps}
                  onPageChange={(page) => {
                    this.setState({ pageNum: page }, this.changeGridParams);
                  }}
                  resizable={false}
                  getNoDataProps={this.getNoDataProps}
                  minRows={0}
                  manual
                />
              </div>
            }
          </GridTableContainer>

          <Preloader isOpen={loading} />

        </ManagementSiteCont>
      </React.Fragment>
    );
  }
}

SubAccount.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadSubAccountData: PropTypes.func.isRequired,
  onDeleteSubAccount: PropTypes.func.isRequired,
  onEdit:PropTypes.func.isRequired,
  // grantKmPermission: PropTypes.func.isRequired,
  // revokeKmPermission: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  loadCountries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
  countries: state.productReducer.countries,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadSubAccountData: (params) => loadSubAccount(params),
      onDeleteSubAccount: (id, cbk) => deleteSubAccount(id, cbk),
      // onEdit: (id, cbk) => handleOnEditOpen(id, cbk),
      // grantKmPermission: (id, cbk) => grantPermissionForOrderKm(id, cbk),
      // revokeKmPermission: (id, cbk) => revokePermissionForOrderKm(id, cbk),
      loadCountries: () => loadCountries(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SubAccount);
