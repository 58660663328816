import styled from 'styled-components';

export const TableWrapper = styled.div`
  max-width: 700px;
  min-width: 640px;
  border: 1px solid;
  flex-drection: column;
  padding: 20px 20px;
  margin-top: 30px;
`;

export const TableCellValue = styled.td`
  padding: 5px 0;
  font-size: 15px;
  font-weight: 600;
  vertical-align: top;
`;

export const TableCell = styled(TableCellValue)`
  min-width: 300px;  
  color: #808080;
  font-weight: normal;
`;
