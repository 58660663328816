import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isString } from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  LOAD_SUB_PROFILES_ACTION,
  ADD_SUB_PROFILE_ACTION,
  EDIT_SUB_PROFILE_ACTION,
  BLOCK_SUB_PROFILE_ACTION,
  UNBLOCK_SUB_PROFILE_ACTION,
} from './SubProfiles.constants';
import { throwNotification } from '../../../../common/structure';
import {
  loadSubProfilesFailed,
  loadSubProfilesSucceeded,
  addSubProfileSucceeded,
  addSubProfileFailed,
  editSubProfileSucceeded,
  editSubProfileFailed,
  blockSubProfileSucceeded,
  blockSubProfileFailed,
  unblockSubProfileSucceeded,
  unblockSubProfileFailed,
} from './SubProfiles.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

const URL = '/profile/users?withCurrent=true';

export function* loadSubProfiles(action) {
  const url =
    action.params && action.params.query ? URL + action.params.query : URL;
  const errT = i18n.t('Ошибка при загрузке Привязанных пользователей');
  try {
    const response = yield call(client().get, url);
    if (isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadSubProfilesFailed());
    } else {
      yield put(
        loadSubProfilesSucceeded(
          response.users || [],
          response.totalObjects || 0,
        ),
      );
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(loadSubProfilesFailed());
  }
}

export function* addSubProfile(action) {
  const errT = i18n.t('Ошибка при добавлении Доверенного пользователя');
  const succT = i18n.t('Пользователь добавлен');
  try {
    const response = yield call(client().post, `/profile/users`, action.data);
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      yield put(addSubProfileFailed());
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(addSubProfileFailed());
    } else {
      yield put(addSubProfileSucceeded());
      yield put(throwNotification(succT, 'info'));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(addSubProfileFailed());
  }
}

export function* editSubProfile(action) {
  const errT = i18n.t('Ошибка при редактировании Доверенного пользователя');
  const succT = i18n.t('Изменения сохранены');
  try {
    const response = yield call(
      client().put,
      `/profile/users/${action.data.userId}`,
      action.data,
    );
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      yield put(editSubProfileFailed());
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(editSubProfileFailed());
    } else {
      yield put(editSubProfileSucceeded());
      yield put(throwNotification(succT, 'info'));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(editSubProfileFailed());
  }
}

export function* blockSubProfile(action) {
  const errT = i18n.t('Ошибка при блокировке Доверенного пользователя');
  const succT = i18n.t('Пользователь заблокирован');
  try {
    const response = yield call(
      client().post,
      `/profile/users/${action.id}/block`,
    );
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      yield put(blockSubProfileFailed());
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(blockSubProfileFailed());
    } else {
      yield put(blockSubProfileSucceeded());
      yield put(throwNotification(succT, 'info'));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(blockSubProfileFailed());
  }
}

export function* unblockSubProfile(action) {
  const errT = i18n.t('Ошибка при разблокировке Доверенного пользователя');
  const succT = i18n.t('Пользователь разблокирован');
  try {
    const response = yield call(
      client().post,
      `/profile/users/${action.id}/unblock`,
    );
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      yield put(unblockSubProfileFailed());
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(unblockSubProfileFailed());
    } else {
      yield put(unblockSubProfileSucceeded());
      yield put(throwNotification(succT, 'info'));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    yield put(unblockSubProfileFailed());
  }
}

export function* watchSubProfiles() {
  yield takeEvery(LOAD_SUB_PROFILES_ACTION, loadSubProfiles);
}

export function* watchAddSubProfile() {
  yield takeEvery(ADD_SUB_PROFILE_ACTION, addSubProfile);
}

export function* watchEditSubProfile() {
  yield takeEvery(EDIT_SUB_PROFILE_ACTION, editSubProfile);
}

export function* watchBlockSubProfile() {
  yield takeEvery(BLOCK_SUB_PROFILE_ACTION, blockSubProfile);
}

export function* watchUnblockSubProfie() {
  yield takeEvery(UNBLOCK_SUB_PROFILE_ACTION, unblockSubProfile);
}

export default function* subProfilesSaga() {
  yield all([
    watchSubProfiles(),
    watchAddSubProfile(),
    watchEditSubProfile(),
    watchBlockSubProfile(),
    watchUnblockSubProfie(),
  ]);
}
