import { ActionCreator } from 'redux';
import {
  LOAD_PROFILE_CERTIFICATES_ACTION,
  LOAD_PROFILE_CERTIFICATES_FAILED_ACTION,
  LOAD_PROFILE_CERTIFICATES_SUCCEEDED_ACTION,
  LOAD_PROFILE_FAILED_ACTION,
  LOAD_PROFILE_INFO_ACTION,
  LOAD_PROFILE_SUCCEEDED_ACTION,
  SAVE_INFO_ACTION,
  SAVE_INFO_FAILED_ACTION,
  SAVE_INFO_SUCCEEDED_ACTION,
  SAVE_PASSWORD_ACTION,
  SET_LOCALE,
} from './ProfileActionTypes.constants';

export const loadProfileInfo: ActionCreator = (cbk = () => null) => ({
  type: LOAD_PROFILE_INFO_ACTION,
  cbk: cbk,
});

export const loadProfileSucceded: ActionCreator = (info) => ({
  type: LOAD_PROFILE_SUCCEEDED_ACTION,
  info: info,
});

export const loadProfileFailed: ActionCreator = (errors) => ({
  type: LOAD_PROFILE_FAILED_ACTION,
  errors: errors,
});

export const loadCertificates: ActionCreator = () => ({
  type: LOAD_PROFILE_CERTIFICATES_ACTION,
});

export const loadCertificatesSucceesed: ActionCreator = (certificates) => ({
  type: LOAD_PROFILE_CERTIFICATES_SUCCEEDED_ACTION,
  certificates: certificates,
});

export const loadCertificatesFailed: ActionCreator = () => ({
  type: LOAD_PROFILE_CERTIFICATES_FAILED_ACTION,
});

export const savePassword: ActionCreator = (password) => ({
  type: SAVE_PASSWORD_ACTION,
  password: password,
});

export const saveInfo: ActionCreator = (
  oldInfo,
  newInfo,
  cbk = () => null,
) => ({
  type: SAVE_INFO_ACTION,
  oldInfo: oldInfo,
  newInfo: newInfo,
  cbk: cbk,
});

export const saveInfoSuccessed: ActionCreator = (info) => ({
  type: SAVE_INFO_SUCCEEDED_ACTION,
  info: info,
});

export const saveInfoFailed: ActionCreator = (info, validationErrors = {}) => ({
  type: SAVE_INFO_FAILED_ACTION,
  info: info,
  validationErrors: validationErrors,
});

export const setLocale: ActionCreator = (locale) => ({
  type: SET_LOCALE,
  locale: locale,
});
