import { NCALayerConfigBuilder } from '../../common/utils/NCALayer';
import { NCALayerService } from '../../common/utils/NCALayerService';

export class NcalayerMethodsService {
  static signData(storageType, data) {
    const {
      method,
      keyType,
    } = new NCALayerConfigBuilder()
      .attachSignData()
      .useSignXmlMethod()
      .useSignKeyType()
      .configure();
    const args = [storageType, keyType, data, '', ''];
    return NCALayerService.init().then(
      () =>
        NCALayerService.getData(method, args)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          })
          .finally(() => NCALayerService.close()),
      (error) => {
        return error;
      },
    );
  }

  static signDataArray(storageType, data) {
    const {
      method,
      keyType,
    } = new NCALayerConfigBuilder()
      .attachSignData()
      .useSignXmlsMethod()
      .useSignKeyType()
      .configure();
    const args = [storageType, keyType, data, '', ''];
    return NCALayerService.init().then(
      () =>
        NCALayerService.getData(method, args)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          })
          .finally(() => NCALayerService.close()),
      (error) => {
        return error;
      },
    );
  }

  static signCmsData(storageType, data) {
    const {
      method,
      keyType,
      attached
    } = new NCALayerConfigBuilder()
      .useSignKeyType()
      .configure();
    const args = [storageType, keyType, data, attached];
    return NCALayerService.init().then(
      () =>
        NCALayerService.getData(method, args)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          })
          .finally(() => NCALayerService.close()),
      (error) => {
        return error;
      },
    );
  }

  static signCmsDataArray(storageType, data) {
    const {
      method,
      keyType,
      attached
    } = new NCALayerConfigBuilder()
      .useSignKeyType()
      .configure();
    const args = [storageType, keyType, data, attached];
    console.log(args);
    return NCALayerService.init().then(
      () =>
        NCALayerService.getData(method, args)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error;
          })
          .finally(() => NCALayerService.close()),
      (error) => {
        return error;
      },
    );
  }
}
