import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import SubAccountItem from './SubAccountItem';
import { sendSubAccount } from '../../../ducks/SubAccount/SubAccount.actions';
import { IconClose } from '../../../../../common/icons/customIcons';
import * as selectors from '../../../ducks/Profile/Profile.selectors';
import { throwNotification } from '../../../../../common/structure';
import {
  defaultTheme,
  DialogTitleStyled,
} from '../../../../../common/styles/Common.styled';
import { CreationCloseBtns } from '../../../Profile.styled';
import { SubAccountDialog } from '../styled/AssignSubAccountModal.styled';

const initItem = {
  id: _.uniqueId('companyId_'),
  inn: '',
  name: '',
  gcp: null,
  gln: null,
  email: '',
  phone: null,
  direction: {},
};

class AddSubAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subAccount: initItem,
      errors: {
        [initItem.id]: true,
      },
      validation: false,
    };
  }

  updateItem = (id, fieldData) => {
    let subAccount = _.cloneDeep(this.state.subAccount);
    if (subAccount.id === id) subAccount = _.assign({}, subAccount, fieldData);

    if (subAccount.direction) {
      subAccount.address = subAccount.direction.address;
      subAccount.country = subAccount.direction.country;
    }
    this.setState({ subAccount });
  };

  // for using in future
  // deleteItem = (id) => {
  //     const subAccount = _.reject(this.state.subAccount, {id: id});
  //     const errors = _.omit(this.state.errors, id);
  //     this.setState({subAccount, errors: errors});
  // };

  handleErrors = (id, error) => {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [id]: error },
    }));
  };

  gcpIsValid = (gcp) => _.includes([4, 5, 6, 7, 8, 9], _.size(_.toString(gcp)));

  isButtonDisabled = () => {
    const { subAccount, validation } = this.state;
    let result = true;

    if (
      !_.isEmpty(subAccount.inn) &&
      !_.isEmpty(subAccount.name) &&
      !_.isEmpty(
        subAccount.direction
          ? subAccount.direction.country
          : subAccount.country,
      ) &&
      !_.isEmpty(
        subAccount.direction
          ? subAccount.direction.address
          : subAccount.address,
      ) &&
      (_.isEmpty(subAccount.gcp) || this.gcpIsValid(subAccount.gcp))
    ) {
      if (validation) result = false;
    } else if (
      _.some(_.values(this.state.errors)) ||
      (!_.isEmpty(subAccount.gcp) && !this.gcpIsValid(subAccount.gcp))
    ) {
      result = true;
    }

    return result;
  };

  sendSubAccount = () => {
    const {
      t,
      throwError,
      info,
      sendSubAccount,
      handleClose,
      changeGridParams,
    } = this.props;
    const { subAccount } = this.state;
    const throwMessageError = _.flow([t, throwError]);

    if (subAccount.inn === info.company.inn) {
      return throwMessageError(
        'Добавление своей организации в субаккаунты не предусмотрено. Укажите корректные данные',
      );
    }

    let data = { ...subAccount };
    delete data.direction;
    sendSubAccount(subAccount, (errors) => {
      if (_.isNil(errors)) {
        handleClose();
        changeGridParams();
      }
    });
  };

  // for using in future
  // innBusy = (value) => {
  //     const item = _.find(this.state.subAccount, {inn: value.inn});
  //     return (item) ? item.id !== value.id : false;
  // };

  onValidateCensorship = (uidField, errorText) => {
    this.setState((prevState) => {
      const booleanValue = !!errorText;
      const censorshipErrors = booleanValue
        ? { ...prevState.censorshipErrors, [uidField]: booleanValue }
        : _.omit(prevState.censorshipErrors, [uidField]);
      return { censorshipErrors };
    });
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  validCbk = (bool) => this.setState({ validation: bool });

  render() {
    const { handleClose, t, countries } = this.props;
    const { subAccount } = this.state;
    const disabledButtonSendSubAccount = this.isButtonDisabled();

    return (
      <MuiThemeProvider theme={SubAccountDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleStyled>{t('Карточка субаккаунта')}</DialogTitleStyled>
            <MuiThemeProvider theme={CreationCloseBtns}>
              <Button className="backEmpty" onClick={handleClose}>
                <IconClose />
              </Button>
            </MuiThemeProvider>
          </DialogTitle>

          <DialogContent>
            <MuiThemeProvider key={subAccount.id} theme={defaultTheme}>
              <SubAccountItem
                key={subAccount.id}
                data={subAccount}
                // isInnBusy={this.innBusy(this.state.subAccount)}
                updateItem={this.updateItem}
                deleteItem={this.deleteItem}
                handleErrors={this.handleErrors}
                onValidate={this.onValidateCensorship}
                updateData={this.updateData}
                validCbk={this.validCbk}
                countries={countries}
              />
            </MuiThemeProvider>
          </DialogContent>

          <DialogActions>
            <Button
              color={'primary'}
              disabled={disabledButtonSendSubAccount}
              onClick={this.sendSubAccount}>
              {t('Подтвердить')}
            </Button>
            <Button color={'secondary'} onClick={handleClose}>
              {t('Отменить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

AddSubAccount.propTypes = {
  handleClose: PropTypes.func.isRequired,
  changeGridParams: PropTypes.func.isRequired,
  info: PropTypes.object.isRequired,
  sendSubAccount: PropTypes.func.isRequired,
  throwError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  info: selectors.info(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendSubAccount: (data, cbk) => sendSubAccount(data, cbk),
      throwError: (message) => throwNotification(message, 'error'),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(AddSubAccount);
