import * as React from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import {
  TableWrapper,
  TableCell,
  TableCellValue,
} from '../styled/SignForm.styled';

const formatDate = (date) => moment(date).format('DD.MM.YYYY');
class SignForm extends React.Component {
  getPorductionType = (id) => {
    const product = _.find(this.props.productDictionary, (item) => {
      return item.id === id;
    });

    if (product) {
      return product.text;
    }
    return `-`;
  };

  render() {
    const { t, data } = this.props;

    return (
      <>
        <h3>
          {t('Электронное заявление в Ассоциацию ГС1 было успешно отправлено')}{' '}
          {formatDate(data.sendDate)}
        </h3>
        <TableWrapper>
          <h2>{t('ЗАЯВЛЕНИЕ О ВСТУПЛЕНИИ В АССОЦИАЦИЮ ГС1')}</h2>
          <table>
            <tbody>
              <tr>
                <TableCell>{t('Наименование предприятия')}:</TableCell>
                <TableCellValue>{data.applicant.companyName || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Юридический адрес')}:</TableCell>
                <TableCellValue>{data.addresses.legalAddress.fullAddress || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Телефон')}:</TableCell>
                <TableCellValue>{data.applicant.phone || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('E-mail')}:</TableCell>
                <TableCellValue>{data.applicant.email || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Руководитель')}:</TableCell>
                <TableCellValue>
                  {data.applicant.lastName || ''}{' '}
                  {data.applicant.firstName || ''}{' '}
                  {data.applicant.middleName || ''}
                </TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Должность руководителя')}:</TableCell>
                <TableCellValue>{data.applicant.position || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Телефон руководителя')}:</TableCell>
                <TableCellValue>{data.applicant.phone || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Ответственный по работе с ГС1')}:</TableCell>
                <TableCellValue>
                  {data.contacts[0].firstName || ''}{' '}
                  {data.contacts[0].lastName || ''}
                </TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Основной вид продукции')}:</TableCell>
                <TableCellValue>{this.getPorductionType(data.applicant.productType)}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Основной вид деятельности')}:</TableCell>
                <TableCellValue>{data.applicant.okved2 || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Наименование банка')}:</TableCell>
                <TableCellValue>{data.bankDetails.name || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('БИК')}:</TableCell>
                <TableCellValue>{data.bankDetails.bik || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Корр.счёт (IBAN)')}:</TableCell>
                <TableCellValue>{data.bankDetails.correspondentAccount || '-'}</TableCellValue>
              </tr>
              <tr>
                <TableCell>{t('Расчётный счёт')}:</TableCell>
                <TableCellValue>{data.bankDetails.settlementAccount || '-'}</TableCellValue>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </>
    );
  }
}

export default withTranslation()(SignForm);
