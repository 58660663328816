import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import _, { isNull } from 'lodash';
import { NoDataComponent } from '../../../../components';
import {
  Avatar,
  // Button,
  // Checkbox,
  // MuiThemeProvider,
} from '@material-ui/core';
import { throwNotification } from '../../../../common/structure';
import {
  Pagination,
  Preloader,
  ConfirmDialog,
} from '../../../../components';
// import GridActionsItems from './gridActions/GridActionsItem';
import {
  IconError,
  IconGridEmpty,
  // IconCheck,
  // IconPlusBlue,
} from '../../../../common/icons/customIcons';
import { AvatarCell, LoginAvatarCell } from '../../Profile.styled';
import { ColumnCellProfile } from '../../../products/productGrid/ProductsGrid.styled';
import { NameFilter, INNTypeFilter, UserOrganization, Status } from './filters';
import {
  // CheckBoxGrid,
  // ColumnCellCheckHeader,
  // ColumnCellCheckInner,
  ColumnCellInner,
  ColumnHeaderProfile,
  // DefaultGridButton,
  // GridActionsRight,
  GridEmptyText,
  GridTableContainer,
  ManagementSiteActions,
  ManagementSiteCont,
  StatusLine, TableEmpty, WithoutSortingBtn
} from "../../../../common/styles/Common.styled";
import { ColumnCellStatus } from "../../../products/views/SelectSwitchingGoods/SelectSwitchingGoods.styled";
import * as selectors from "../../ducks/SubProfiles/SubProfiles.selectors";
import { loadSubProfiles, blockSubProfile, unblockSubProfile } from "../../ducks/SubProfiles/SubProfiles.actions";
// import CheckboxCell from "./cell/Checkbox";
import {
  findUserStatusObj,
  nameAvatarStr,
} from '../../../../common/utils/utils';
import AddTrustedAccountModal from './modal/AddTrustedAccountModal';
import { Tooltip } from '@material-ui/core';
import { clippedString } from '../../../../common/utils/utils';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const FiltersInit = {
  name: null,
  ITN: null,
  organizationName: null,
  status: null,
};


const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);
class SubProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: FiltersInit,
      pageSize: 10,
      pageNum: 0,
      selectedIds: [],
      deleteIds: [],
      modalIsOpen: false,
      modalData: {},
      modalState: '',
      confirmIsOpen: false,
      confirmAction: '',
      currentUser: {},
    };
    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);
    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
  }

  componentDidMount() {
    const filters = localStorage.getItem('savedFiltersSubProfiles');
    if (filters) {
      this.setState({ filters: JSON.parse(filters) }, this.changeGridParams);
    } else {
      this.changeGridParams();
    }
  }

  changeGridParams = () => {
    let query = '';
    const { filters } = this.state;

    Object.keys(filters).forEach((item) => {
      if (!isNull(filters[item])) {
        const field = String(filters[item]);
        const needEncode = item === 'address' || item === 'name';
        query += `&${item}=${needEncode ? encodeURIComponent(field) : field}`;
      }
    });
    const params = {
      pageSize: this.state.pageSize,
      pageNum: this.state.pageNum,
      query,
    };
    this.props.onLoadSubProfilesData(params);
  };

  onBlock = (origin) => {
    return this.setState({
      confirmIsOpen: true,
      confirmAction: 'block',
      currentUser: origin,
    });
  };

  onCreate = () => {
    this.setState({ modalData: {}, modalState: 'create' });
    return this.openModal();
  };

  onEdit = (origin) => {
    this.setState({ modalData: origin, modalState: 'edit' });
    return this.openModal();
  };

  onView = (origin) => {
    this.setState({ modalData: origin, modalState: 'view' });
    return this.openModal();
  };

  onUnblock = (origin) => {
    return this.setState({
      confirmIsOpen: true,
      confirmAction: 'unblock',
      currentUser: origin,
    });
  };

  getPaginationProps = () => ({ totalObjects: this.props.totalObjects });

  getStatus = (textStatus, id) => {
    const { t } = this.props;
    const status = findUserStatusObj(textStatus);
    return (
      <StatusLine key={`${id}_${textStatus}`} color={status.style.color}>
        {status.style.icon}
        <span>{t(status.title)}</span>
      </StatusLine>
    );
  };

  getCertificateLine = (certificateStartDate, certificateEndDate) => {
    if (certificateStartDate && certificateEndDate) {
      const isAfter = moment().isAfter(certificateEndDate);
      return (
        <ColumnCellInner style={isAfter ? { color: '#FF4B4B' } : {}}>
          <Moment format="DD.MM.YYYY">{certificateStartDate}</Moment>
          <div style={{ padding: '5px' }}> - </div>
          <Moment format="DD.MM.YYYY">{certificateEndDate}</Moment>
          {isAfter && (
            <IconError style={{ position: 'relative', marginLeft: '10px' }} />
          )}
        </ColumnCellInner>
      );
    } else {
      return <ColumnCellInner> – </ColumnCellInner>;
    }
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
      modalState: '',
    });
    return this.changeGridParams();
  };

  dismissModal = () => {
    this.setState({
      modalIsOpen: false,
      modalState: '',
    });
  };

  closeConfirm = () => {
    return this.setState({
      confirmIsOpen: false,
      currentUser: {},
    });
  };

  onFilterDissmiss = (name) => {
    // удалить один из фильтров в localStorage
    let savedFilters = localStorage.getItem('savedFiltersSubProfiles');
    savedFilters = JSON.parse(savedFilters);

    let filters = _.cloneDeep(this.state.filters);

    filters[name] = null;
    savedFilters[name] = null;

    localStorage.setItem(
      'savedFiltersSubProfiles',
      JSON.stringify(savedFilters),
    );
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: filters,
      }),
      this.changeGridParams,
    );
  };

  onFilterAccept = (name, value) => {
    let savedFilters;
    savedFilters = localStorage.getItem('savedFiltersSubProfiles');
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    if (!savedFilters) {
      savedFilters = _.cloneDeep(FiltersInit);
    }
    savedFilters[name] = value;
    localStorage.setItem(
      'savedFiltersSubProfiles',
      JSON.stringify(savedFilters),
    );

    if (name && toString(value)) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, { [name]: value }),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
    }
  };

  onAccept = () => {
    const { currentUser } = this.state;
    if (this.state.confirmAction === 'unblock') {
      this.setState({ currentUser: {}, confirmIsOpen: false });
      this.props.unblockSubProfile(currentUser.id, (error) => {
        if (_.isNull(error)) {
          return this.changeGridParams();
        }
      });
      return;
    }

    this.setState({ currentUser: {}, confirmIsOpen: false });
    this.props.blockSubProfile(currentUser.id, (error) => {
      if (_.isNull(error)) {
        return this.changeGridParams();
      }
    });
    return;
  };

  resetFilters = () => {
    // убить все фильтры в localStorage
    localStorage.removeItem('savedFiltersSubProfiles');
    this.setState(
      {
        filters: FiltersInit,
        selectDTOItems: [],
      },
      this.changeGridParams,
    );
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });

  paginateData = () => {
    const { data, totalObjects } = this.props;
    const {
      pageSize,
      pageNum,
    } = this.state;

    if (totalObjects <= 10 || !data) {
      return data;
    }

    const dataArr = [];

    for (let i = 0; i < Math.ceil(totalObjects / pageSize); i++) {
      dataArr[i] = data.slice((i * pageSize), (i * pageSize) + pageSize);
    }
    return dataArr[pageNum];
  }

  render() {
    const {
      // user,
      loading,
      t,
    } = this.props;
    const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);
    const data = this.paginateData();

    const confirmMessage =
      this.state.confirmAction === 'block'
        ? t(`Вы действительно хотите заблокировать пользователя?`)
        : t(`Вы действительно хотите разблокировать пользователя?`);

    return (
      <div style={{ height: '100%' }}>
        {this.state.modalIsOpen && (
          <AddTrustedAccountModal
            handleClose={this.closeModal}
            modalData={this.state.modalData}
            mode={this.state.modalState}
            dismissModal={this.dismissModal}
          />
        )}

        {this.state.confirmIsOpen && (
          <ConfirmDialog
            onAccept={this.onAccept}
            onDismiss={this.closeConfirm}
            onCancel={this.closeConfirm}
            title=""
            message={confirmMessage}
            acceptBtnText={t('Подтвердить')}
            dismissBtnText={t('Закрыть')}
          />
        )}

        <ManagementSiteCont big>
          <ManagementSiteActions>
            {/* <GridActionsRight>
              <MuiThemeProvider theme={DefaultGridButton}>
                <Button disabled={loading} onClick={this.onCreate}>
                  <IconPlusBlue style={{ marginRight: '10px' }} />{' '}
                  {t('Добавить доверенного пользователя')}
                </Button>
              </MuiThemeProvider>
            </GridActionsRight> */}
          </ManagementSiteActions>

          <GridTableContainer noData={data.length === 0 && !IsNotActiveFilters} height="37vh" minHeight="10vh" className="customTable">
            {!data.length && IsNotActiveFilters ? (
              <TableEmpty>
                <IconGridEmpty />
                <GridEmptyText>
                  <p>{t('У вас еще нет привязанных пользователей')}.</p>
                </GridEmptyText>
              </TableEmpty>
            ) : (
              <div style={{ display: loading ? 'none' : 'block', height: '100%' }}>
                <ReactTableFixedColumns
                  data={data}
                  columns={[
                    /*{
                        id: "checkbox",
                        accessor: "",
                        fixed: 'left',
                        sortable: false,
                        maxWidth: 40,
                        Header: x => {
                            return (
                                <ColumnCellCheckHeader>
                                    <ColumnCellCheckInner>
                                        <MuiThemeProvider theme={CheckBoxGrid}>
                                            <Checkbox
                                                checked={(!isEmpty(this.props.data) && this.props.data.length === this.state.selectedIds.length)}
                                                onChange={this.toggleSelectAll}
                                                checkedIcon={<IconCheck/>}
                                            />
                                        </MuiThemeProvider>
                                    </ColumnCellCheckInner>
                                </ColumnCellCheckHeader>
                            );
                        },
                        Cell: ({original}) => {
                            return <CheckboxCell
                                original={original}
                                checked={includes(this.state.selectedIds, original.id)}
                                toggleRow={this.toggleRow}
                            />
                        }
                    },*/
                    {
                      name: t('Фото'),
                      show: true,
                      accessor: 'photo',
                      position: 0,
                      sortable: false,
                      maxWidth: 80,
                      minWidth: 80,
                      style: { justifyContent: 'center' },
                      Header: x => {
                        return <ColumnHeaderProfile><WithoutSortingBtn /></ColumnHeaderProfile>
                      },
                      Cell: ({ original }) => {
                        return (
                          <AvatarCell>
                            <LoginAvatarCell>
                              {original.photoUrl
                                ? <Avatar alt={original.firstName} src={original.photoUrl} />
                                : nameAvatarStr(`${original.firstName} ${original.lastName}`)
                              }
                            </LoginAvatarCell>
                          </AvatarCell>
                        )
                      },
                    },
                    {
                      name: t('ФИО пользователя'),
                      show: true,
                      position: 0,
                      accessor: 'name',
                      sortable: false,
                      minWidth: 255,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile>
                            {t('ФИО пользователя')}
                            <WithoutSortingBtn>
                              <NameFilter
                                parentId="name"
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                currentValue={this.state.filters.name}
                              />
                            </WithoutSortingBtn>
                          </ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>
                              {`${original.lastName} ${original.firstName} ${original.middleName}`}
                            </ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                    },
                    {
                      name: t('Налоговый идентификатор'),
                      show: true,
                      position: 1,
                      accessor: 'ITN',
                      sortable: false,
                      minWidth: 180,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile small>
                            {t('Налоговый идентификатор')}
                            <WithoutSortingBtn>
                              <INNTypeFilter
                                parentId="ITN"
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                currentValue={this.state.filters.ITN}
                              />
                            </WithoutSortingBtn>
                          </ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>
                              {`${original.ITN}`}
                            </ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                    },
                    {
                      name: t('Организация пользователя'),
                      show: true,
                      position: 2,
                      accessor: 'organizationName',
                      sortable: false,
                      minWidth: 280,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile>
                            {t('Организация пользователя')}
                            <WithoutSortingBtn>
                              <UserOrganization
                                parentId="organizationName"
                                onAccept={this.onFilterAccept}
                                onDismiss={this.onFilterDissmiss}
                                currentValue={this.state.filters.organizationName}
                              />
                            </WithoutSortingBtn>
                          </ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>
                              {(original.organizationName && original.organizationName.length > 75)
                                ? (
                                  <Tooltip arrow placement="bottom-start" title={original.organizationName}>
                                    <div>{clippedString(original.organizationName, 75)}</div>
                                  </Tooltip>
                                )
                                : original.organizationName || ''
                              }
                            </ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      },
                    },
                    {
                      name: t('Срок действия сертификата'),
                      show: true,
                      position: 3,
                      accessor: 'date',
                      sortable: false,
                      minWidth: 200,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile small>{t('Срок действия сертификата')}<WithoutSortingBtn /></ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            {this.getCertificateLine(original.certificateValidFrom, original.certificateValidTo)}
                          </ColumnCellProfile>
                        )
                      }
                    },
                    {
                      name: t('Последняя активность'),
                      show: true,
                      position: 4,
                      accessor: 'lastActivity',
                      sortable: false,
                      minWidth: 170,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile>{t('Последняя активность')}<WithoutSortingBtn /></ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellProfile>
                            <ColumnCellInner>
                              {original.lastActivity
                                ? <Moment format="DD.MM.YYYY в HH:mm">{original.lastActivity}</Moment>
                                : '–'
                              }
                            </ColumnCellInner>
                          </ColumnCellProfile>
                        )
                      }
                    },
                    {
                      name: t('Статус пользователя'),
                      show: true,
                      position: 5,
                      accessor: 'status',
                      sortable: false,
                      minWidth: 180,
                      Header: x => {
                        return (
                          <ColumnHeaderProfile>
                            {t('Статус пользователя')}
                            <WithoutSortingBtn />
                            <Status
                              parentId="status"
                              onAccept={this.onFilterAccept}
                              onDismiss={this.onFilterDissmiss}
                              currentValue={this.state.filters.status}
                            />
                          </ColumnHeaderProfile>
                        )
                      },
                      Cell: ({ original }) => {
                        return (
                          <ColumnCellStatus>
                            <ColumnCellInner>
                              {this.getStatus(original.status, original.id)}
                            </ColumnCellInner>
                          </ColumnCellStatus>
                        )
                      }
                    },
                    // {
                    //   accessor: 'actions',
                    //   Header: '',
                    //   sortable: false,
                    //   filterable: false,
                    //   fixed: 'right',
                    //   width: 0,
                    //   Cell: ({ original }) => {
                    //     return (
                    //       <div>
                    //         <GridActionsItems
                    //           onEdit={this.onEdit}
                    //           onBlock={this.onBlock}
                    //           onView={this.onView}
                    //           onUnblock={this.onUnblock}
                    //           original={original}
                    //         />
                    //         {/* {user.isMainAccountUser &&
                    //                     } */}
                    //       </div>
                    //     )
                    //   }
                    // }
                  ]}
                  defaultPageSize={this.state.pageSize}
                  className="-highlight"
                  page={this.state.pageNum}
                  pages={Math.ceil(
                    this.props.totalObjects / this.state.pageSize,
                  )}
                  pageSize={this.state.pageSize}
                  NoDataComponent={NoDataComponent}
                  PaginationComponent={Pagination}
                  getPaginationProps={this.getPaginationProps}
                  onPageChange={(page) => {
                    this.setState({ pageNum: page }, this.changeGridParams);
                  }}
                  resizable={false}
                  getNoDataProps={this.getNoDataProps}
                  minRows={0}
                  manual
                />


              </div>
            )}
          </GridTableContainer>

          <Preloader isOpen={loading} />
        </ManagementSiteCont>
      </div>
    );
  }
}

SubProfiles.propTypes = {
  throwNotification: PropTypes.func.isRequired,
  onLoadSubProfilesData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  blockSubProfile: PropTypes.func.isRequired,
  unblockSubProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: !!selectors.loading(state),
  totalObjects: selectors.totalObjects(state),
  data: selectors.data(state),
  user: state.authentificationReducer.user,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      throwNotification: (message, type) => throwNotification(message, type),
      onLoadSubProfilesData: (params) => loadSubProfiles(params),
      blockSubProfile: (id, callback) => blockSubProfile(id, callback),
      unblockSubProfile: (id, callback) => unblockSubProfile(id, callback),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SubProfiles);
