import styled from 'styled-components';

export const NodataNew = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  button {
    padding: 0;
    background: none;
    box-shadow: none;
    color: #4aa5e8;
    font-size: 16px;
    text-transform: none;
    margin-top: 10px;
    font-weight: 600;
    display: inline-block;
    margin: 0;
    line-height: 3px;
    vertical-align: baseline;
    min-width: auto;
  }
`;
