import { createMuiTheme } from '@material-ui/core';

export const ModDialogSucceed = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minHeight: '335px',
        minWidth: '530px',
        maxWidth: '530px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 56px 75px',
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '15px 20px 0 0',
        textAlign: 'right',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px',
      },
    },
  },
});
