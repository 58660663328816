import { createMuiTheme } from '@material-ui/core';
import styled from 'styled-components';
import colors from '../../styles/Common.colors';

export const ConsentDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '800px',
        maxWidth: '800px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        maxHeight: '300px',
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'flex-start',
      },
      label: {
        textAlign: 'left',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '13px 20px',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '30px',
        color: '#63666A',
        textAlign: 'center',
        borderBottom: '1px solid #F2F2F2',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px 15px',
      },
      label: {
        justifyContent: 'center',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '20px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        width: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#52535A',
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        width: '200px',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 20px 39px',
        fontSize: '18px;',
        color: '#63666A',
        textAlign: 'center',
        minHeight: '60px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '20px 20px',
        margin: '0',
        justifyContent: 'space-between',
        borderTop: '1px solid #F2F2F2',
      },
    },
    MuiIconButton: {
      root: {
        width: '44px',
      },
    },
  },
});
export const Alert = styled.div`
  color: #ff4b4b;
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  text-align: left;
`;
export const SmallText = styled.div`
  color: #868686;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 0 0;
  text-align: left;
  a {
    font-size: 14px;
    color: #4aa5e8;
  }
`;
