import { all, call, put, takeEvery } from 'redux-saga/effects';
import { has, isEmpty, isPlainObject, isString } from 'lodash';
import { push } from 'connected-react-router';
// import {CadesUtils} from "@crpt/cades-utils";
import { client } from '../../../api/Client';
import { isKZ, mock } from '../../../config';
import i18n from '../../../i18n';
import {
  CLEAR_ERROR,
  LOAD_CERTS,
  REG_FAILED,
  REG_REQUEST,
  REG_SUCCESS,
} from './RegistrationActionTypes';
import { getCertsFailed, getCertsSuccessed } from './Registration.actions';
import { throwNotification } from '../../../common/structure';
import { handleErrorNotification } from '../../ducks/HandleErrors';
import {
  handlerCryptoMessage,
  signData,
} from '../../../common/utils/cryptoPro';

export function* loadCertificate() {
  try {
    // const certificates = isKZ
    //     ? mock.certificates
    //     : yield CadesUtils.getFinalCertsArray();

    const certificates = mock.certificates;

    yield put(getCertsSuccessed(certificates));
  } catch (e) {
    const text = i18n.t(
      'Квалифицированный сертификат ключа проверки электронной подписи не найден',
    );
    yield put(throwNotification(handlerCryptoMessage(e, text), 'error', 5000));
    yield put(getCertsFailed(e));
  }
}

export function* registrationRequest(data) {
  try {
    yield put({ type: CLEAR_ERROR });
    const dataForSign = yield call(client().get, '/signed-data');
    if (isString(dataForSign)) {
      yield put(push('/500'));
      return;
    }
    if (dataForSign.error) {
      yield handleErrorNotification(dataForSign.error);
      yield put({ type: REG_FAILED, errors: [] });
      return;
    }

    const signature = isKZ
      ? mock.signature
      : yield signData(data.data.cert.certificate, dataForSign.data);

    yield (data.data.cert.message = signature);
    const response = yield call(
      client().post,
      '/registration',
      JSON.stringify({
        accountType: data.data.accountType,
        signature: signature,
        email: data.data.email,
        phone: data.data.phone,
        fingerprint: data.data.cert.fingerprint,
        isMarked: data.data.isMarked,
      }),
    );

    if (!isPlainObject(response)) {
      yield put({ type: REG_FAILED, errors: [] });
      yield put(push('/500'));
      return;
    }

    if (response.error) {
      yield handleErrorNotification(response.error);
      if (has(response, 'error.response.data.messages')) {
        yield put({
          type: REG_FAILED,
          errors: response.error.response.data.messages,
        });
      } else if (has(response, 'error.message')) {
        const validationErrors = response.error.response.data.validationErrors;
        if (!isEmpty(validationErrors)) {
          yield put({
            type: REG_FAILED,
            errors: [],
            validationErrors: validationErrors,
          });
        } else {
          yield put({ type: REG_FAILED, errors: [] });
        }
      } else {
        yield put({ type: REG_FAILED, errors: [] });
      }
    } else {
      yield put({ type: REG_SUCCESS });
    }
  } catch (e) {
    const text = i18n.t('Неизвестная ошибка запроса на регистрацию!');
    yield put(throwNotification(handlerCryptoMessage(e, text), 'error'));
    yield put({ type: REG_FAILED, errors: [] });
  }
}

export function* watchLoadCertificates() {
  yield takeEvery(LOAD_CERTS, loadCertificate);
}

export function* watchRegistrationRequest() {
  yield takeEvery(REG_REQUEST, registrationRequest);
}

export default function* registrationSaga() {
  yield all([watchLoadCertificates(), watchRegistrationRequest()]);
}
