import styled from 'styled-components';
import { get } from 'lodash';
import { SelectContLogin, TabsCont } from '../../common/styles/Common.styled';
import colors from '../../common/styles/Common.colors';

export const BrowserCheckHeader = styled(TabsCont)`
  border-bottom: none;
`;
export const BrowserCheckContent = styled(SelectContLogin)`
  padding: 40px;
`;
export const Block = styled.div`
  border: 1px solid
    ${(props) =>
      props.error ? get(props, 'theme.palette.error.main') : '#d9d9d9'};
  border-radius: 3px;
  height: 170px;
  padding: 20px;
  margin: 0 20px 20px 0;
  @media (max-width: 600px) {
    margin: 0 10px 10px 0;
    height: auto;
    padding: 10px;
  }
  @media (max-width: 400px) {
    margin: 0 0 10px;
  }
  a {
    color: #4aa5e8;
    text-decoration: underline;
  }
  flex: 1 1 0;
  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;
export const BrowserCheckRow = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;
export const BrowserCheckLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  button {
    font-size: 16px !important;
  }
`;
export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  background-color: ${colors.Common.background};
  color: ${colors.Common.color};
  &.valid {
    background-color: #60e84a;
  }
  &.inValid {
    background-color: #ff4b4b;
  }
  background: ${(props) =>
    props.valid
      ? get(props.theme, 'palette.success.main')
      : props.checked
      ? get(props.theme, '#60E84A')
      : get(props.theme, 'palette.primary.main')};
  & > svg {
    color: white;
  }
`;
export const BlockTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
export const BlockInfo = styled.div`
  font-size: 12px;
  color: #52535a;
  color: ${(props) =>
    props.error ? get(props, 'theme.palette.error.main') : 'inherit'};
`;
