import styled from 'styled-components';
import { ProfileTabCont } from '../../Profile.styled';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../common/styles/Common.colors';

export const ProductManagementCont = styled(ProfileTabCont)`
  padding: 20px 0 0;
  @media (max-width: 1200px) {
    padding: 20px 0 0;
  }
`;
export const SmallTabsNew = createMuiTheme({
  typography: {},
  overrides: {
    MuiTabs: {
      root: {
        margin: '0',
        minHeight: '40px',
        padding: '0 40px',
        overflow: 'visible',
        borderBottom: `1px solid ${colors.Tabs.tabLine}`,
      },
      indicator: {
        backgroundColor: '#FFF',
        height: '1px',
        bottom: '-1px',
        zIndex: '9',
      },
      fixed: {
        overflow: 'visible!important',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'SegoeUI',
        minHeight: '40px',
        '&$selected': {
          border: `1px solid ${colors.Tabs.tabLine}`,
          borderBottom: 'none',
        },
        '&$disabled': {
          opacity: '1!important',
        },
        textTransform: 'uppercase',
        fontSize: '10px!important',
        fontWeight: 'bold',
        lineHeight: '20px',
        color: '#63666A!important',
        letterSpacing: '0!important',
      },
      //labelContainer: {
      //padding: "0!important"
      //},
      textColorInherit: {
        opacity: '1!important',
      },
      disabled: {},
      selected: {},
    },
  },
});
