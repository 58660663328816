import styled, {keyframes} from "styled-components";
import {createMuiTheme} from "@material-ui/core";
import colors from "../../styles/Common.colors";

export const SidebarHead = styled.div`
    padding: 10px 10px 75px 12px;
    @media (max-width: 1920px) {
        padding: 10px 11px 50px 12px;
`;
export const SidebarLink = styled.a`
    padding: 20px 5px;
    box-shadow: 0px 1px 0px #F2F2F2, inset 0px 1px 0px #F2F2F2;
    cursor: pointer;
    transition:background .4s ease;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: ${props => props.open ? 'flex-start' : 'center'};
    &.open-menu{
        box-shadow: inset  0px 1px 1px #F2F2F2;
        justify-content: flex-start;
    }
    &:disabled{
        color: #F9F9F9!important;
        cursor: default;
        background-color: #F9F9F9;
        pointer-events: none
    }
`;
export const SidebarLinkIcon = styled.span`
    width: ${props => props.open ? 'auto' : '41px'};
    height: 41px;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
    @media (max-width: 1919px) {
        svg {
            width: 20px;
            height: 20px;
        }
    }
    @media (min-width: 1920px) {
        width: auto;
        height: 57px;
    }
`;
export const SidebarLinkTitle = styled.span`
    opacity: ${props => props.open ? 1 : 0};
    display: ${props => props.open ? 'block' : 'none'};
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    padding-left: 5px;
    font-weight: 500;
    transition: .4s ease;
    &.active {
        font-weight: bold;
        color: #FFFFFF;
    }
    @media (min-width: 1920px) {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        white-space: break-spaces;
        &.active {
            font-weight: bold;
            color: #FFFFFF;
        }

    }
`;
export const SidebarLinkTitleBig = styled(SidebarLinkTitle)`
    padding-left: 5px;
`;
export const SidebarMenuBtn = styled.div`
    padding: 20px 5px;
    display: flex;
    justify-content: ${props => props.open ? 'flex-start' : 'center'};
    @media (min-width: 1920px) {
        width: 70px;
        height: 90px;
        padding: 20px 10px;
        svg {
            width: 36px;
            height: 36px;
        }
    }
`;
export const SidebarLinkProfile = styled.div`
    padding: 20px 5px;
    margin-top: auto;
    box-shadow: 0px 1px 0px #F2F2F2, inset 0px 1px 0px #F2F2F2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${props => props.open ? 'flex-start' : 'center'};
`;
export const SidebarMenuButton = createMuiTheme({
    typography: {},
    overrides: {
        MuiIconButton: {
            root: {
                '@media (min-width: 1920px)': {
                    padding: "8px",
                }
            },
        },
        MuiButtonBase: {
            root: {
                //width: "100%",
                height: "51px",
                //border: "1px solid #D9D9D9",
                justifyContent: 'inherit',
            }
        }
    }
});
const blurry = keyframes`
    0% {
        filter: blur(0px);
        background: ${colors.Common.background};
    }

    100% {
        filter: blur(5px);
        background: none;
    }
`;
const opacity = keyframes`
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
`;
export const BluLogoMin = styled.span`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 70px;
    svg{
        animation: .2s ${opacity} ease;
    }
    
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        animation: .8s ${blurry} ease;
    }
    
`;
export const BluLogoMax = styled.span`
    position: relative;
    display: inline-block;
    height: 40px;
    svg{
        animation: .6s ${opacity} ease;
    }
    &:before{
        content: '';
        width: 100%;
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        animation: .6s ${blurry} ease;
    }
`;
export const AddProductSelectItemSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #63666A;    
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    &:hover{
        color: #fff;
        svg{
            path{
                fill: #fff;
            }
        }
    }
`;
export const MenuTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #63666A;
    text-transform: uppercase;
    padding: 11px 23px;
    outline: none;
`;

export const AddProductSelect = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                height: "auto",
                padding: "0",
                paddingTop: "0",
                paddingBottom: "0",
                borderTop: "1px solid #F2F2F2",
                fontFamily: "SegoeUI",
                '&:hover': {
                    backgroundColor: `${colors.Common.background}`
                }
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "15px",
                marginRight: "13px"
            }
        },
        MuiPopover: {
            paper: {
                maxWidth: "370px",
                borderRadius: 0
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "0px 3px 3px 0px",
                //minWidth: "250px!important",
                marginTop: "-1px"
            },
            elevation8: {
                boxShadow: "14px 0px 20px 0px rgba(0, 0, 0, 0.15)"
            }
        },
        MuiTypography: {
            root: {
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between"
            }
        },
        MuiTooltip: {
            popper: {
                background: "rgba(0, 0, 0, .7)",
                maxWidth: "340px",
                marginTop: "20px",
                borderRadius: "4px",
                marginRight: "-30px"
            },
            tooltip: {
                fontSize: "14px",
                lineHeight: "20px",
                color: "#fff",
                fontWeight: "normal",
                boxShadow: "none",
                padding: "5px 20px",
                textAlign: "center",
                position: "relative!important",
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                background: "none!important",
                opacity: "1!important",
                transform: "none!important",
                '&::before': {
                    content: '""',
                    display: "block",
                    width: "0",
                    height: "0",
                    borderWidth: "0 10px 17px 10px",
                    borderColor: "transparent transparent rgba(0, 0, 0, .7) transparent",
                    borderStyle: "solid",
                    position: "absolute",
                    top: "-31px",
                    left: "30px"
                }
            }
        }
    }
});

export const AddProductFull = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                height: "auto",
                padding: "0",
                paddingTop: "0",
                paddingBottom: "0",
                borderTop: "1px solid #F2F2F2",
                fontFamily: "SegoeUI",
                '&:hover': {
                    backgroundColor: `${colors.Common.background}`
                }
            }
        },
        MuiList: {
            padding: {
                paddingLeft: "60px",
                paddingTop: "0",
                paddingBottom: "22px"
            }
        },
        MuiListItem: {
            root: {
                paddingTop: "0",
                paddingBottom: "0",
                '&:hover': {
                    cursor: "pointer"
                },
                '&.Mui-disabled': {
                    cursor: "default",
                    pointerEvents: "none"
                }
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: "15px",
                marginRight: "13px"
            }
        },
        MuiPopover: {
            paper: {
                maxWidth: "210px"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "0px 3px 3px 0px",
                //minWidth: "250px!important",
                marginTop: "-1px"
            },
            elevation8: {
                boxShadow: "14px 0px 20px 0px rgba(0, 0, 0, 0.15)"
            }
        },
        MuiTypography: {
            root: {
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between"
            }
        },
        MuiTooltip: {
            popper: {
                background: "rgba(0, 0, 0, .7)",
                maxWidth: "340px",
                marginTop: "20px",
                borderRadius: "4px",
                marginRight: "-30px"
            },
            tooltip: {
                fontSize: "14px",
                lineHeight: "20px",
                color: "#fff",
                fontWeight: "normal",
                boxShadow: "none",
                padding: "5px 20px",
                textAlign: "center",
                position: "relative!important",
                wordBreak: "break-word",
                whiteSpace: "pre-line",
                background: "none!important",
                opacity: "1!important",
                transform: "none!important",
                '&::before': {
                    content: '""',
                    display: "block",
                    width: "0",
                    height: "0",
                    borderWidth: "0 10px 17px 10px",
                    borderColor: "transparent transparent rgba(0, 0, 0, .7) transparent",
                    borderStyle: "solid",
                    position: "absolute",
                    top: "-31px",
                    left: "30px"
                }
            }
        }
    }
});

export const SidebarLangSelect = createMuiTheme({
    typography: {},
    overrides: {
        MuiMenuItem: {
            root: {
                height: "auto",
                padding: "30px 0",
                paddingTop: "30px",
                paddingBottom: "30px",
                //borderTop: "1px solid #F2F2F2",
                '&:hover': {
                    backgroundColor: `${colors.Common.background}`
                }
            }
        },
        MuiSelect: {
            select: {
                padding: "30px 35px 30px 25px",
                color: "#63666A",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "21px",
                display: "flex",
                alignItems: "center"
            }
        },
        MuiList: {
            padding: {
                paddingTop: "0",
                paddingBottom: "0"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "2px",
                minWidth: "auto",
                marginTop: "-1px",
                left: "0!important"
            },
            elevation8: {
                boxShadow: "none"
            }
        },
        MuiTypography: {
            root: {
                flexGrow: "1",
                display: "flex",
                justifyContent: "space-between"
            }
        },
        MuiInput: {
            underline: {
                color: 'transparent',
                '&:after': {
                    borderBottom: "none!important"
                },
                '&:before': {
                    borderBottom: "none!important"
                },
                '&:hover:not($disabled):after': {
                    backgroundColor: 'transparent',
                    borderBottom: "none"
                },
                '&:hover:not($disabled):before': {
                    backgroundColor: 'transparent',
                    borderBottom: "none!important"
                }
            }
        }
    }
});