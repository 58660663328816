import styled from 'styled-components';

export const ProductRegistration = styled.a`
  padding-right: 49px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  transition: background 0.4s ease;
  display: inline-flex;
  align-items: center;
  &.open-menu {
    box-shadow: inset 0px 1px 1px #f2f2f2;
  }
  &:disabled {
    color: #f9f9f9 !important;
    cursor: default;
    background-color: #f9f9f9;
    pointer-events: none;
  }
`;

export const ProductRegistrationIcon = styled.span`
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
`;

export const ProductRegistrationTitle = styled.span`
  opacity: ${(props) => (props.open ? 1 : 0)};
  font-size: 14px;
  color: #4aa5e8;
  padding-right: 1px;
  font-weight: 400;
  transition: 0.4s ease;
  white-space: nowrap;
`;
