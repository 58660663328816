import { Reducer } from 'redux';
import {
  LOGIN_ACTION,
  LOGIN_FAILED_ACTION,
  LOGIN_SUCCEEDED_ACTION,
  LOGOUT_SUCCESS_ACTION,
  ACTIVE_SESSION_OPEN_MODAL_ACTION,
  ACTIVE_SESSION_CLOSE_MODAL_ACTION,
  ACTIVE_ELK_SESSION_OPEN_MODAL_ACTION,
  ACTIVE_ELK_SESSION_CLOSE_MODAL_ACTION,
} from './AuthentificationActionTypes.contants';
import { getCookie } from '../../../api/Client';
import { AccessibilityOutlined } from '@material-ui/icons';

const _initialState = {
  loading: false,
  isAuthentificated: false,
  user: getCookie('userInfo'),
  isActiveSessionOpenModal: false,
  activeSessionInfo: '',
  isActiveElkSessionOpenModal: false,
  activeElkSessionInfo: '',
};

export const authentificationReducer: Reducer = (
  state = _initialState,
  action,
) => {
  switch (action.type) {
    case LOGIN_ACTION: {
      return { ...state, loading: true };
    }
    case LOGIN_SUCCEEDED_ACTION:
      return {
        ...state,
        isAuthentificated: true,
        loading: false,
        user: action.userInfo,
      };
    case LOGIN_FAILED_ACTION: {
      return { ...state, loading: false };
    }
    case LOGOUT_SUCCESS_ACTION: {
      return { ...state, isAuthentificated: false, user: {} };
    }
    case ACTIVE_SESSION_OPEN_MODAL_ACTION: {
      return { ...state, isActiveSessionOpenModal: true, activeSessionInfo: action.info };
    }
    case ACTIVE_SESSION_CLOSE_MODAL_ACTION: {
      return { ...state, isActiveSessionOpenModal: false, activeSessionInfo: '' };
    }
    case ACTIVE_ELK_SESSION_OPEN_MODAL_ACTION: {
      return { ...state, isActiveElkSessionOpenModal: true, activeElkSessionInfo: action.info };
    }
    case ACTIVE_ELK_SESSION_CLOSE_MODAL_ACTION: {
      return { ...state, isActiveElkSessionOpenModal: false, activeElkSessionInfo: '' };
    }
    default:
      return { ...state };
  }
};
