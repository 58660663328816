import * as React from "react";
import * as PropTypes from 'prop-types';
import * as selectors from './ducks/Catalog.selectors';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from "react-i18next";
import { withCookies } from 'react-cookie';
import memoize from 'memoize-state';
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import {
  GridActions,
  GridActionsLeft,
  GridActionsRight,
  DefaultGridButton,
  GridTableContainer,
  GridEmpty,
  GridEmptyText,
  // ColumnCellInner,
  // ColumnHeaderProfile,
  WithoutSortingBtn,
  WithoutSortingTitle,
  ColumnHeaderPhoto,
  ColumnHeaderIsValid,
  ColumnHeader,
  SortingBtn,
  ColumnCellCheckHeader,
  ColumnCellCheckInner,
  CheckBoxGridThead,

} from '../../common/styles/Common.styled';
// import moment from "moment-timezone";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import _ from 'lodash';
import { config } from '../../config';
// import {
//     BrandFilter,
//     CategoryFilter,
//     ContentProviderFilter,
//     DateFilter,
//     GtinFilter,
//     NameFilter,
//     PackagesFilter,
//     PhotoFilter,
//     StatusFilter,
//     CategoryTnvedNameFilter,
//     PackingTypeFilter,
//     IsValidFilter,
//     IsExemplarFilter,
//   } from './filters';



// import {
//   ExportModal,
//   GridPageSize,
//   PhotoPopover,
//   SelectSwitchingGoods,

// } from './viewss';

import {
  CatalogGridContainer,
  GridActionsView,
  ResetFilterButton,
  GridTable,
  // ColumnCellProfile,
  // PointerName,
  ColumnCellPhoto,
  // ColumnCellStatus,
} from './Catalog.styled';


import { Pagination, Preloader } from '../../components';

import { Button, Checkbox, MuiThemeProvider } from '@material-ui/core';
import {
  IconFilteredSquareOrange,
  IconFilteredSquareGray,
  IconExport,
  IconGridEmpty,
  IconGridViewDisabled,
  IconGridView,
  IconCheck,
} from '../../common/icons/customIcons';
import TableGrid from "./views/TableGrid";
import ExportModal from "./views/ExportModal/ExportModal";
import PhotoPopover from './views/PhotoPopover/PhotoPopover'
//import GridPageSize from "./views/GridPageSize/GridPageSize";

import { findStatusObj, gtinDisplay, getSortField } from '../../common/utils/utils';
import CreatedCell from "./cell/CreatedCell";
import PackingPurpose from './cell/PackingPurpose';
import PhotoFilter from "./filters/PhotoFilter";
import IsValidFilter from "./filters/IsValidFilter";
import DateFilter from "./filters/DateFilter";
import PackingTypeFilter from "./filters/PackingTypeFilter";
import GtinFilter from "./filters/GtinFilter";
import GtinCell from "./cell/Gtin";
import NameFilter from "./filters/NameFilter";
import NameCell from "./cell/Name";
import BrandFilter from "./filters/BrandFilter";
import BrandCell from "./cell/Brand";
import CategoryFilter from "./filters/CategoryFilter";
import CategoryCell from "./cell/Category";
import CategoryTnvedNameFilter from "./filters/CategoryTnvedNameFilter";
import PackagesFilter from "./filters/PackagesFilter";
import PackagesCell from "./cell/Packages";
// import StatusFilter from "./filters/StatusFilter";
// import StatusCell from "./cell/Status";
// import SelectSwitchingGoods from "./views/SelectSwitchingGoods/SelectSwitchingGoods";

import {
  returnSimplifiedSetFields,
  sortColumns,
  getSelectedIds,
  getColumns,
} from './ducks/Catalog.utils';
import { loadCatalogData } from "./ducks/Catalog.actions";
import CheckboxCell from "./cell/Checkbox";
import CatalogGridActions from "./views/CatalogGridActions/CatalogGridActions";
import ColumnOrder from "./views/ColumnOrder/ColumnOrder";
import GridPageSize from "./views/GridPageSize/GridPageSize";

const FiltersInit = {
  category: null,
  gtin: null,
  packages: null,
  photo: null,
  status: null,
  created: {
    from: null,
    to: null,
  },
  publicationTime: {
    from: null,
    to: null,
  },
  brand: null,
  name: null,
  contentProvider: {
    name: null,
    inn: null,
  },
  categoryTnved: null,
  packingPurpose: null,
  isValid: null,
  isExemplar: null,
};

const SortInit = [
  { field: 'category', direction: '' },
  { field: 'name', direction: '' },
  { field: 'status', direction: '' },
  { field: 'created', direction: '' },
  { field: 'brand', direction: '' },
  { field: 'contentProvider', direction: '' },
  { field: 'publicationTime', direction: '' },
  { field: 'categoryTnved', direction: '' },
  { field: 'packingPurpose', direction: '' },
];

const SuccessedStateInit = {
  isOpen: false,
  message: '',
};

const ConfirmStateInit = {
  isOpen: false,
  title: '',
  message: '',
  funcAccept: null,
  funcDismiss: null,
  funcCancel: null,
  acceptText: '',
  dismissText: '',
};

const isNotActiveFilters = (filters) => _.isEqual(FiltersInit, filters);

const sizeConcat = _.flow([_.compact, _.size]);

const countActiveFilters = (filters) =>
  sizeConcat(
    _.map(filters, (i) => (_.isObjectLike(i) ? sizeConcat(_.valuesIn(i)) : i)),
  );

const mGenerateSelectDTO = memoize((selectDTOItems, data, status) => {
  const selectDTO = [];
  _.forEach(data, (i) => {
    if (i.goods && i.goods.status === status) {
      selectDTO.push({ id: i.goods.id, dto: 'goods' });
      return;
    }
    if (i.goods && i.draft && i.draft.status === status) {
      selectDTO.push({ id: i.goods.id, dto: 'draft' });
    }
  });
  return _.uniqBy(_.concat([], selectDTOItems, selectDTO), 'id');
});


class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // pageSize: _.get(this.props, 'filters.pageSize', 10),
      pageSize: localStorage.savedPageSizeCatalog ? _.toNumber(localStorage.getItem('savedPageSizeCatalog')) : 10,
      pageNum: _.get(this.props, 'filters.pageNum', 0),
      // filters: _.get(this.props, 'filters.filters', FiltersInit),
      filters: localStorage.savedFiltersCatalog ? _.assign({}, JSON.parse(localStorage.getItem('savedFiltersCatalog'))) : FiltersInit,
      sort: _.has(this.props.filters, 'sort')
        ? _.uniqBy(
          _.compact(_.concat([], this.props.filters.sort, SortInit)),
          'field',
        )
        : SortInit,
      columns: [],
      defaultColumns: [],
      checkboxColumn: {},
      actionColumn: {},
      orderedColumns: [],
      selected: [],
      gtin: 0,
      deleteIds: [],
      archiveIds: [],
      selectItemProduct: {},
      modal: {
        isOpenExportModal: false,
        isOpenTableAppearance: false,
        isOpenVersions: false,
        isOpenMassAction: false,
        isOpenRedirectModal: false,
        successed: SuccessedStateInit,
        confirm: ConfirmStateInit,
        isOpenSelectCertModal: false,
        isOpenArchiveCertModal: false,
        isOpenRemainderModal: null,
        isOpenImportModal: false,
        isOpenMassMediaModal: false,
      },
      selectDTOItems: [],
      certificate: {},
      params: null,
      anchorEl: null,
      isOpenList: false,
      open: false,
    };

    // this.accountType = _.get(this.props, 'user.accountType', 'producer');// было до 338 задачи: роль-строка, теперь массив.
    this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes', ['producer']));

    const commonField = [
      'photo',
      'created',
      'gtin',
      'name',
      'category',
      'packages',
      'brand',
      'categoryTnved',
      'publicationTime',
      'packingPurpose',
      'isValid',
      'isExemplar',
    ];

    // this.columnsGrid = _.includes(['producer', 'importer'], this.accountType)
    this.columnsGrid = _.includes(this.accountTypes, ('producer' || 'importer'))
      ? [...commonField, 'status']
      : [...commonField, 'contentProvider'];

    const paramsList = {
      ..._.pick(this.state, ['pageNum', 'pageSize', 'sort', 'filters']),
      fields: this.columnsGrid,
      // columns: this.columnsGrid,
    };
    this.props.onLoadCatalogData(paramsList);
    this.mGetSelectedIds = memoize(getSelectedIds);
    this.mGetStatusByIds = memoize(this.getStatusByIds);
    this.mGetStatusByIds = memoize(this.getStatusByIds);
    this.mGetRemainderByIds = memoize(this.getRemainderByIds);
    this.mCurrentSDI = memoize(this.currentSDI);
    this.mIsNotActiveFilters = _.memoize(isNotActiveFilters);
    this.mCountActiveFilters = _.memoize(countActiveFilters);
    //this.mIconSwitch = memoize(iconSwitch, { cacheSize: 5 });
    this.mDivisionDto = memoize(this.divisionDto);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_.isNull(prevState.filters.status)) {
      const selectDTOItems = mGenerateSelectDTO(
        prevState.selectDTOItems,
        nextProps.data,
        prevState.filters.status,
      );
      if (!_.isEqual(selectDTOItems, prevState.selectDTOItems)) {
        return { selectDTOItems: selectDTOItems };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.locale, this.props.locale)) {
      this.updateColumns();
    }
  }

  componentDidMount() {
    this.updateColumns();
    const { state } = this.props.location;
    let usedFilters = false;
    // * Переделан этот блок, т.к. приводил к нескольким вызовам на сервер за данными, проверка на локалстораж теперь в конструкторе
    // let savedFilters = localStorage.getItem('savedFiltersCatalog');
    // let savedSort = localStorage.getItem('savedSort');
    // const savedPageSize = localStorage.getItem('savedPageSizeCatalog');

    // if (savedFilters) {
    //   savedFilters = JSON.parse(savedFilters);
    //   this.setState(
    //     {
    //       pageNum: 0,
    //       filters: _.assign({}, this.state.filters, savedFilters),
    //       selectDTOItems: [],
    //     },
    //     this.changeGridParams,
    //   );
    // }

    // if (savedPageSize) {
    //   this.setState(
    //     {
    //       pageSize: _.toNumber(savedPageSize),
    //     },
    //     this.changeGridParams,
    //   );
    // }

    /**
     * а нужно ли сохранять и сетать сорт?
     */
    // if (savedSort) {
    //     savedSort = JSON.parse(savedSort)
    //     this.setState({
    //         sort: savedSort
    //     }, this.changeGridParams);
    // }

    /**
     * TODO:
     * относится к задаче eacnc-199
     * удалить при мерже задачи eacnc-245, т.к. больше не будет перехода со страницы статистики
     * на страницу грида с установкой фильтра
     */
    if (state?.brand || state?.category || state?.packingPurpose) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, this.props.location.state),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
      usedFilters = true;
    }

    /**
     * TODO:
     * относится к задаче eacnc-199
     * удалить при мерже задачи eacnc-245, т.к. больше не будет перехода со страницы статистики
     * на страницу грида с установкой фильтра
     */
    if (usedFilters) {
      this.props.clearHistoryState({
        ...this.props.location,
        state: {},
      });
    }
  }

  getStatusByIds = (selected) => {
    const obj = this.mDivisionDto(this.props.data);
    return _.map(
      selected,
      (i) => findStatusObj(_.find(obj[i.nameDto], { id: i.id }).status).id,
    );
  };

  getRemainderByIds = (selected) => {
    const obj = this.mDivisionDto(this.props.data);
    return _.map(
      selected,
      (i) => _.find(obj[i.nameDto], { id: i.id }).isRemainder,
    );
  };

  divisionDto = (data) => {
    return {
      draft: _.map(data, 'draft'),
      goods: _.map(data, 'goods'),
    };
  };

  currentSDI = (original, selectDTOItems) => {
    return _.defaultTo(_.find(selectDTOItems, { id: original.goods.id }), {
      dto: 'goods',
    });
  };

  onChangeTableAppearanceShow = () => {
    const columns = returnSimplifiedSetFields(this.state.columns);
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenTableAppearance: !this.state.modal.isOpenTableAppearance,
      },
      orderedColumns: columns,
    });
  };

  selectDataFromDTO = (original, selectDTOItems) => {
    if (_.isNull(original.goods) || _.isNull(original.draft)) {
      if (_.isNull(original.goods)) {
        return { dto: original.draft, nameDto: 'draft' };
      }
      if (_.isNull(original.draft)) {
        return { dto: original.goods, nameDto: 'goods' };
      }
    } else {
      const SDI = this.mCurrentSDI(original, selectDTOItems);
      return { dto: original[SDI.dto], nameDto: SDI.dto };
    }
  };

  getBuildParamsForList = () => {
    return {
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
      sort: getSortField(this.state.sort),
      filters: this.state.filters,
      fields: _.concat(
        getColumns(this.state.columns),
        'isRemainder',
        'isExemplar',
      ),
    };
  };

  changeGridParams = () => {
    const params = this.getBuildParamsForList();
    setTimeout(() => this.setState({ loading: false }), 200);
    this.props.onLoadCatalogData(params);
  };

  onFilterAccept = (name, value) => {
    // сетаем фильтры в localStorage
    let savedFilters;
    savedFilters = localStorage.getItem('savedFiltersCatalog');
    // если в localStorage есть ранее сохраненные фильтры,, то достаём строку и делаем объект
    if (savedFilters) {
      savedFilters = JSON.parse(savedFilters);
    }
    // если в сторадже ничего нет, то копируем изначальные фильтры.
    if (!savedFilters) {
      savedFilters = _.cloneDeep(FiltersInit);
    }
    savedFilters[name] = value;
    localStorage.setItem('savedFiltersCatalog', JSON.stringify(savedFilters));

    if (name && toString(value)) {
      this.setState(
        {
          pageNum: 0,
          filters: _.assign({}, this.state.filters, { [name]: value }),
          selectDTOItems: [],
        },
        this.changeGridParams,
      );
    }
  };

  onFilterDissmiss = (name) => {
    // удалить один из фильтров в localStorage
    let savedFilters = localStorage.getItem('savedFiltersCatalog');
    savedFilters = JSON.parse(savedFilters);

    let filters = _.cloneDeep(this.state.filters);

    if (name === 'created') {
      filters['created'] = { to: null, from: null };
      savedFilters['created'] = { to: null, from: null };
    } else if (name === 'publicationTime') {
      filters['publicationTime'] = { to: null, from: null };
      savedFilters['publicationTime'] = { to: null, from: null };
    } else if (name === 'contentProvider') {
      filters['contentProvider'] = { name: null, inn: null };
      savedFilters['contentProvider'] = { name: null, inn: null };
    } else {
      filters[name] = null;
      savedFilters[name] = null;
    }
    localStorage.setItem('savedFiltersCatalog', JSON.stringify(savedFilters));
    this.setState(
      (prevState) => ({
        ...prevState,
        filters: filters,
        selectDTOItems: [],
      }),
      this.changeGridParams,
    );
  };

  resetFilters = () => {
    // убить все фильтры в localStorage
    localStorage.removeItem('savedFiltersCatalog');
    this.setState(
      {
        filters: FiltersInit,
        selectDTOItems: [],
      },
      this.changeGridParams,
    );
  };

  onViewRemainder = (id, dtoName) => {
    this.setState({ modal: { ...this.state.modal, isOpenRemainderModal: id } });
  };

  onView = (id, dtoName) => {
    const page = `${config.urls.catalog}/${id}`
    this.props.goToPage(page);
  };

  onExportModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        isOpenExportModal: !this.state.modal.isOpenExportModal,
      },
    });
  };

  onChangeTableAppearance = (columns) => this.updateColumns(columns);

  toggleRow = (dto, nameDto) => {
    const item = { id: dto.id, nameDto: nameDto };
    let selected;
    if (_.find(this.state.selected, item)) {
      selected = _.reject(this.state.selected, item);
    } else {
      selected = _.concat(this.state.selected, item);
    }
    this.setState({ selected: selected });
  };

  toggleSelectAll = () => {
    let selected = [];
    if (
      !_.isEmpty(this.props.data) &&
      this.props.data.length !== this.state.selected.length
    ) {
      this.props.data.forEach((x) => {
        const item = this.selectDataFromDTO(x, this.state.selectDTOItems);
        selected.push({ id: item.dto.id, nameDto: item.nameDto });
      });
    }
    this.setState({ selected });
  };

  onPrint = (id) => {
    this.props.goToPage(`${config.urls.print}/catalog/${id}`);
  };

  onViewSite = (id, dtoName) => {
    const obj = this.mDivisionDto(_.cloneDeep(this.props.data));
    const item = _.find(obj[dtoName], { id: id });
    if (!!item.photo.url) {
      window.open(item.link, '_black');
    } else {
      this.handleRedirectModal();
    }
  };

  handleRedirectModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        isOpenRedirectModal: !prevState.modal.isOpenRedirectModal,
      },
    }));
  };

  getNoDataProps = () => ({ resetFilters: this.resetFilters });

  render() {
    const { t } = this.props;
    const { loading } = this.state;
    const IsNotActiveFilters = this.mIsNotActiveFilters(this.state.filters);
    const emptyTable = !this.props.totalObjects && IsNotActiveFilters;
    const disableActionsEmptyGrid = emptyTable || this.props.loading;

    return (
      <React.Fragment>
        <CatalogGridContainer>
          <GridActions>
            <GridActionsLeft>
              {/* {CountActiveFilters >= 2 && ( */}
              <GridActionsView style={{ paddingLeft: '20px' }}>
                <MuiThemeProvider theme={ResetFilterButton}>
                  <Button
                    onClick={this.resetFilters}
                    disabled={IsNotActiveFilters}
                    style={{ textTransform: 'none' }}>
                    {!IsNotActiveFilters ? <IconFilteredSquareOrange /> : <IconFilteredSquareGray />}
                    <div style={{ marginLeft: '15px' }}>
                      {t('Сбросить фильтры')}
                    </div>
                  </Button>
                </MuiThemeProvider>
              </GridActionsView>
              {/* )} */}
            </GridActionsLeft>
            <GridActionsRight>
              <MuiThemeProvider theme={DefaultGridButton}>
                <GridActionsView style={{ padding: '0 0 0 86px' }}>
                  <Button
                    onClick={this.onChangeTableAppearanceShow}
                    disabled={disableActionsEmptyGrid}
                    style={{
                      borderRight: '1px solid #979191',
                      borderRadius: '4px 0 0 4px',
                      padding: '0px 16px',
                    }}>
                    {disableActionsEmptyGrid ? (
                      <IconGridViewDisabled style={{ marginRight: '10px' }} />
                    ) : (
                      <IconGridView style={{ marginRight: '10px' }} />
                    )}
                    {t('Вид')}
                  </Button>
                  <Button onClick={this.onExportModal}>
                    <IconExport style={{ marginRight: '10px' }} />
                    {t('Экспорт')}
                  </Button>
                </GridActionsView>
              </MuiThemeProvider>
            </GridActionsRight>
          </GridActions>
          <GridTableContainer /*noData={!emptyTable && !this.props.data.length}*/ height="53vh" minHeight="10vh">
            {!!this.state.modal.isOpenExportModal && (
              <ExportModal
                handleClose={this.onExportModal}
                getBuildParamsForList={this.getBuildParamsForList}
                filters={this.state.filters}
                selected={this.state.selected}
              />
            )}
            {this.state.modal.isOpenTableAppearance && (
              <ColumnOrder
                onCancel={this.onChangeTableAppearanceShow}
                onSave={this.onChangeTableAppearance}
                columns={this.state.orderedColumns}
                defaultColumns={this.state.defaultColumns}
              />
            )}
            <GridTable className="table-grid--list">
              {emptyTable ? (
                <GridEmpty>
                  <IconGridEmpty />
                  <GridEmptyText>
                    <p>{t('Нет данных для отображения')}.</p>
                  </GridEmptyText>
                </GridEmpty>
              ) : (
                <div style={{ display: this.props.loading ? 'none' : 'block', height: '100%' }}>
                  <TableGrid
                    key={disableActionsEmptyGrid}
                    data={this.props.data}
                    columns={_.concat(
                      this.state.checkboxColumn,
                      this.state.columns,
                      this.state.actionColumn,
                    )}
                    defaultPageSize={this.state.pageSize}
                    className="-highlight"
                    page={this.state.pageNum}
                    pages={Math.ceil(
                      this.props.totalObjects / this.state.pageSize,
                    )}
                    pageSize={this.state.pageSize}
                    PaginationComponent={Pagination}
                    getPaginationProps={() => ({
                      totalObjects: this.props.totalObjects,
                      gridPageSize: (
                        <GridPageSize
                          disabled={disableActionsEmptyGrid}
                          activeValue={this.state.pageSize}
                          onChangePageSize={(pageSize) => {
                            localStorage.setItem('savedPageSizeCatalog', pageSize);
                            this.setState(
                              { pageNum: 0, loading: true },
                              () => setTimeout(() => this.setState({ pageSize }, this.changeGridParams), 1),
                            );
                          }}
                        />
                      ),
                    })}
                    onPageChange={(page) => {
                      this.setState({ pageNum: page }, this.changeGridParams);
                    }}
                    resizable={false}
                    getNoDataProps={this.getNoDataProps}
                    minRows={0}
                    manual
                    selectDataFromDTO={this.selectDataFromDTO}
                    selected={this.state.selected}
                    filters={this.state.filters}
                    selectDTOItems={this.state.selectDTOItems}
                  />
                </div>
              )}
            </GridTable>
          </GridTableContainer>
        </CatalogGridContainer>
        <Preloader isOpen={this.props.loading || loading} />
      </React.Fragment>
    )
  }

  updateColumns(orderedColumns) {
    let { cookies, locale, t } = this.props;
    let checkBoxColumn = {
      id: 'checkboxCatalog',
      accessor: '',
      fixed: 'left',
      sortable: false,
      maxWidth: 40,
      height: 55,
      Header: (x) => {
        return (
          <ColumnCellCheckHeader>
            <SortingBtn style={{ paddingLeft: '11px' }}>
              <ColumnCellCheckInner>
                <MuiThemeProvider theme={CheckBoxGridThead}>
                  <Checkbox
                    checked={
                      !_.isEmpty(this.props.data) &&
                      this.props.data.length === this.state.selected.length
                    }
                    onChange={this.toggleSelectAll}
                    checkedIcon={<IconCheck />}
                  />
                </MuiThemeProvider>
              </ColumnCellCheckInner>
            </SortingBtn>
          </ColumnCellCheckHeader>
        );
      },
      Cell: ({ original }) => {
        return (
          <CheckboxCell
            toggleRow={this.toggleRow}
            original={original}
            selected={this.state.selected}
          />
        );
      },
    };

    let defaultColumns = [
      {
        name: t('Фото'),
        show: true,
        accessor: 'photo',
        position: 0,
        sortable: false,
        minWidth: 80,
        style: { justifyContent: 'center' },
        Header: (x) => {
          return (
            <ColumnHeaderPhoto id="photoCatalogGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Фото')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <PhotoFilter
                parentId="photoCatalogGridFilter"
                currentValue={this.state.filters.photo}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeaderPhoto>
          );
        },
        Cell: ({ original }) => {
          return (
            <ColumnCellPhoto>
              {!_.isUndefined(original.dto.photo) && (
                <PhotoPopover
                  photo={original.dto.photo}
                  name={original.dto.name}
                />
              )}
            </ColumnCellPhoto>
          );
        },
      },
      {
        name: t('Степень заполненности'),
        show: true,
        accessor: 'isValid',
        position: 1,
        sortable: false,
        width: 40,
        style: { justifyContent: 'center' },
        Header: (x) => {
          return (
            <ColumnHeaderIsValid id="isValidGridFilter">
              <WithoutSortingBtn style={{ paddingLeft: '3px' }}>
                <IsValidFilter
                  parentId="isValidGridFilter"
                  currentValue={this.state.filters.isValid}
                  onAccept={this.onFilterAccept}
                  onDismiss={this.onFilterDissmiss}
                />
              </WithoutSortingBtn>
            </ColumnHeaderIsValid>
          );
        },
        Cell: ({ original }) => {
          return (
            <ColumnCellPhoto>
              {original.dto.isValid ? (
                <FiberManualRecordIcon
                  style={{ color: 'green', fontSize: '16px' }}
                />
              ) : (
                <FiberManualRecordIcon
                  style={{ color: 'red', fontSize: '16px' }}
                />
              )}
            </ColumnCellPhoto>
          );
        },
      },
      {
        name: t('Регистрация'),
        show: true,
        position: 1,
        accessor: 'created',
        sortable: false,
        minWidth: locale === 'kz' ? 100 : 134,
        Header: (x) => {
          return (
            <ColumnHeader id="dateGridFilter">
              {/* <SortingBtn>
                    {this.mIconSwitch(
                      _.find(this.state.sort, { field: 'created' }).direction
                      t('Регистрация'),
                      this.onSort('created'),
                    )}
                  </SortingBtn> */}
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Регистрация')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <DateFilter
                parentId="dateGridFilter"
                currentValue={this.state.filters.created}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                name={'created'}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CreatedCell value={original.dto.created} />;
        },
      },
      {
        name: t('Публикация'),
        show: true,
        position: 2,
        accessor: 'publicationTime',
        sortable: false,
        minWidth: locale === 'kz' ? 110 : 134,
        Header: (x) => {
          return (
            <ColumnHeader id="publicationTimeGridFilter">
              {/* <SortingBtn>
                {this.mIconSwitch(
                      _.find(this.state.sort, { field: 'publicationTime' })
                        .direction,
                      t('Публикация'),
                      this.onSort('publicationTime'),
                    )}
              </SortingBtn> */}
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Публикация')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <DateFilter
                parentId="publicationTimeGridFilter"
                currentValue={this.state.filters.publicationTime}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                name={'publicationTime'}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CreatedCell value={original.dto.publicationTime} />;
        },
      },
      {
        name: t('Назначение упаковки'),
        show: true,
        position: 3,
        accessor: 'packingPurpose',
        sortable: false,
        minWidth: locale === 'kz' ? 170 : 190,
        Header: (x) => {
          return (
            <ColumnHeader id="packingPurposeGridFilter">
              {/*<SortingBtn>*/}
              {/*  {this.mIconSwitch(*/}
              {/*    _.find(this.state.sort, { field: 'packingPurpose' })*/}
              {/*      .direction,*/}
              {/*    t('Назначение упаковки'),*/}
              {/*    this.onSort('packingPurpose'),*/}
              {/*  )}*/}
              {/*</SortingBtn>*/}
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Назначение упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>

              <PackingTypeFilter
                parentId="packingPurposeGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.packingPurpose}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <PackingPurpose value={original.dto} t={t} />;
        },
      },
      {
        name: t('Код упаковки'),
        show: true,
        position: 4,
        accessor: 'gtin',
        sortable: false,
        minWidth: 150,
        Header: (x) => {
          return (
            <ColumnHeader id="gtinGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Код упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <GtinFilter
                parentId="gtinGridFilter"
                currentValue={this.state.filters.gtin}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return (
            <GtinCell
              value={gtinDisplay(original.dto.gtin)}
              fillWithZeros={true}
              isClipped={false}
            />
          ); //флаг для того чтобы дополнять нулями ячейку. или лучше сделать проверку внутри utils?????
        },
      },
      {
        name: t('Торговое наименование товара'),
        show: true,
        position: 5,
        accessor: 'name',
        sortable: false,
        minWidth: 520,
        maxWidth: 520,
        Header: (x) => {
          return (
            <ColumnHeader id="nameGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Торговое наименование товара')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <NameFilter
                parentId="nameGridFilter"
                currentValue={this.state.filters.name}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return (
            <NameCell
              original={original}
              onView={this.onView}
              onViewRemainder={this.onViewRemainder}
            />
          );
        },
      },
      {
        name: t('Товарный знак'),
        show: true,
        position: 6,
        accessor: 'brand',
        sortable: false,
        minWidth: 160,
        Header: (x) => {
          return (
            <ColumnHeader id="brandGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Товарный знак')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <BrandFilter
                parentId="brandGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.brand}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <BrandCell value={original.dto.brand} />;
        },
      },
      {
        name: t('Классификация'),
        show: true,
        position: 7,
        accessor: 'category',
        sortable: false,
        minWidth: 160,
        maxWidth: 160,
        Header: (x) => {
          return (
            <ColumnHeader id="categoryGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Классификация')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>

              <CategoryFilter
                parentId="categoryGridFilter"
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
                currentValue={this.state.filters.category}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <CategoryCell value={original.dto.category} />;
        },
      },
      {
        name: t('Код ТНВЭД'),
        show: true,
        position: 8,
        accessor: 'categoryTnved',
        sortable: false,
        minWidth: 130,
        Header: (x) => {
          return (
            <ColumnHeader id="categoryTnvedGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Код ТНВЭД')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <CategoryTnvedNameFilter
                parentId="categoryTnvedGridFilter"
                currentValue={this.state.filters.categoryTnved}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <GtinCell value={original.dto.categoryTnved} isClipped />;
        },
      },
      {
        name: t('Вложенные упаковки'),
        show: true,
        position: 9,
        accessor: 'packages',
        sortable: false,
        minWidth: 180,
        Header: (x) => {
          return (
            <ColumnHeader id="packagesGridFilter">
              <WithoutSortingBtn>
                <WithoutSortingTitle left={'15px'}>
                  {t('Вложенные упаковки')}
                </WithoutSortingTitle>
              </WithoutSortingBtn>
              <PackagesFilter
                parentId="packagesGridFilter"
                currentValue={this.state.filters.packages}
                onAccept={this.onFilterAccept}
                onDismiss={this.onFilterDissmiss}
              />
            </ColumnHeader>
          );
        },
        Cell: ({ original }) => {
          return <PackagesCell packages={original.dto.packages} />;
          // return <GtinCell value={gtinDisplay(original.dto.packages)} />
        },
      },
      // {
      //   name: t('Статус'),
      //   show: true,
      //   position: 10,
      //   accessor: 'status',
      //   sortable: false,
      //   minWidth: 240,
      //   Header: (x) => {
      //     return (
      //       <ColumnHeader id="statusGridFilter">
      //         <WithoutSortingBtn>
      //           <WithoutSortingTitle left={'15px'}>
      //           {t('Статус')}
      //         </WithoutSortingTitle>
      //         </WithoutSortingBtn>

      //         <StatusFilter
      //           parentId="statusGridFilter"
      //           onAccept={this.onFilterAccept}
      //           onDismiss={this.onFilterDissmiss}
      //           currentValue={this.state.filters.status}
      //         />
      //       </ColumnHeader>
      //     );
      //   },
      //   Cell: ({ original }) => {
      //     return (
      //       <ColumnCellStatus>
      //         <SelectSwitchingGoods
      //           onChange={this.handleChangeDTOSelect}
      //           original={original}
      //         />
      //         <StatusCell original={original} onVersion={this.onVersion} />
      //       </ColumnCellStatus>
      //     );
      //   },
      // },
      // {
      //   name: t('Поставщик контента'),
      //   show: true,
      //   position: 9,
      //   accessor: 'contentProvider',
      //   sortable: false,
      //   minWidth: 290,
      //   Header: (x) => {
      //     return (
      //       <ColumnHeader id="contentProviderGridFilter">
      //         <WithoutSortingBtn>
      //           {t('Поставщик контента')}
      //         </WithoutSortingBtn>

      //         <ContentProviderFilter
      //           parentId="contentProviderGridFilter"
      //           onAccept={this.onFilterAccept}
      //           onDismiss={this.onFilterDissmiss}
      //           currentName={this.state.filters.contentProvider.name}
      //           currentInn={_.join(
      //             this.state.filters.contentProvider.inn,
      //             '\n',
      //           )}
      //         />
      //       </ColumnHeader>
      //     );
      //   },
      //   Cell: ({ original }) => {
      //     return <ContentProviderCell original={original} />;
      //   },
      // },
    ];

    const { userInfo } = this.props;
    const isEklUser = _.get(userInfo, 'isELK', false);
    const isELKInfo = this.props.info?.user?.isELK || this.props.user?.isElkUser;

    let actionsColumn = {
      accessor: 'actions',
      Header: '',
      sortable: false,
      filterable: false,
      fixed: 'right',
      width: 0,
      Cell: ({ original }) => {
        return (
          <div>
            {original.dto.status !== 'archive' && !original.dto.isRemainder && (
              <CatalogGridActions
                // accountType={this.accountType}
                // onViewSite={this.onViewSite}
                onView={this.onView}
                onPrint={this.onPrint}
                isCatalogAction={true}
                original={original}
              // isEklUser={isEklUser || isELKInfo}
              />
            )}
          </div>
        );
      },
    };

    let columns = _.cloneDeep(defaultColumns);
    if (!orderedColumns) {
      let savedColumns = cookies.get('columnsCatalog');
      if (!!savedColumns) {
        // if (this.accountType === savedColumns.accountType) {
        if (Array.isArray(savedColumns.accountType) && (this.accountTypes.join(',') === savedColumns.accountType.join(','))) {
          columns = sortColumns(columns, savedColumns.columns);
        }
      }
    }
    columns = sortColumns(columns, orderedColumns);
    columns = _.filter(_.compact(columns), (i) =>
      _.includes(this.columnsGrid, i.accessor),
    );
    defaultColumns = _.filter(_.compact(defaultColumns), (i) =>
      _.includes(this.columnsGrid, i.accessor),
    );
    defaultColumns = returnSimplifiedSetFields(defaultColumns);

    cookies.set(
      'columnsCatalog',
      JSON.stringify({ accountType: _.cloneDeep(this.accountTypes), columns: columns }),
    );
    this.setState(
      {
        modal: {
          ...this.state.modal,
          isOpenTableAppearance: false,
        },
        checkboxColumn: checkBoxColumn,
        actionColumn: actionsColumn,
        columns: columns,
        defaultColumns: defaultColumns,
      },
      () => {
        if (orderedColumns) {
          this.changeGridParams();
        }
      },
    );
  }
}

Catalog.propTypes = {
  onLoadCatalogData: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  // onSendForModerationProduct: PropTypes.func.isRequired,
  // onPublishProduct: PropTypes.func.isRequired,
  // onDraftOpen: PropTypes.func.isRequired,
  // onDeleteProducts: PropTypes.func.isRequired,
  // throwNotification: PropTypes.func.isRequired,
  // clearAlert: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  totalObjects: PropTypes.number.isRequired,
  // notification: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  certificate: PropTypes.object,
  filters: PropTypes.object,
  locale: PropTypes.string.isRequired,
  // clearHistoryState: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  data: selectors.data(state),
  totalObjects: selectors.totalObjects(state),
  filters: selectors.filters(state),
  notification: selectors.alert(state),
  user: selectors.user(state),
  certificate: selectors.certificate(state),
  certificates: selectors.certificates(state),
  locale: selectors.locale(state),
  userInfo: state.profileReducer.info.user,
  location: state.router.location,
  usercompany: state.profileStatusReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    onLoadCatalogData: (p) => loadCatalogData(p.pageNum, p.pageSize, p.sort, p.filters, p.fields),
    goToPage: (page) => push(page),
  }, dispatch);
};

export default compose(
  withCookies,
  withTranslation(),
  connect(memoize(mapStateToProps), mapDispatchToProps),
)(Catalog)