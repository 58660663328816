import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  PackInfoItem,
  PackInfoItemMax,
} from '../../../view/Product/Product.styled';
import {
  GridBlockA,
  GridItemSmall,
  GridItemSmallA,
  GridItemSmallB,
} from '../../../../../common/styles/Common.styled';
import { CustomInput } from '../../../../../components';

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

class MultipleAndTransportPackageInputItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  render() {
    const { t, data } = this.props;

    const returnPackageType = (type) => {
      switch (type) {
        case 'consumer':
          return t('Потребительская упаковка');
        case 'transport':
          return t('Транспортная упаковка');
        case 'group':
          return t('Групповая упаковка');
        default:
          return;
      }
    };

    return (
      <React.Fragment>
        <PackInfoItem>
          <PackInfoItemMax>
            <GridBlockA>
              <GridItemSmall>
                <CustomInput
                  name="packingPurpose"
                  disabled
                  label={t('Назначение упаковки')}
                  value={returnPackageType(
                    getDefaultTo(data, 'packingPurpose'),
                  )}
                  type="text"
                  style={{ maxWidth: '40%' }}
                />
              </GridItemSmall>
              <GridItemSmallA>
                <CustomInput
                  name="gtin"
                  disabled
                  label={t('Код вложенной упаковки')}
                  value={getDefaultTo(data, 'gtin')}
                  type="text"
                />
              </GridItemSmallA>
              <GridItemSmallB>
                <CustomInput
                  name="count"
                  disabled
                  label={t('Количество вложений')}
                  value={getDefaultTo(data, 'count')}
                  type="text"
                />
              </GridItemSmallB>
            </GridBlockA>
          </PackInfoItemMax>
        </PackInfoItem>
      </React.Fragment>
    );
  }
}

MultipleAndTransportPackageInputItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default compose(withTranslation())(MultipleAndTransportPackageInputItem);
