import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _, { defaultTo, isEqual, isEmpty } from 'lodash';
import { Element } from 'react-scroll';
import {
  FormControlLabel,
  Checkbox,
  MuiThemeProvider,
  Button,
} from '@material-ui/core';
import { CustomInput, SimpleTooltip } from '../../../../components';
import {
  IconError,
  IconCheck,
  IconPlus,
  IconDeleteSmall,
  IconInfoTooltip,
} from '../../../../common/icons/customIcons';
import { ConsumerItem } from '../../../product/view/itemElement';
import { ProductButton } from '../../../../common/styles/Common.styled';
import {
  PopupProductContainer,
  PopupProductInn,
  PopupProductName,
  PopupProductCountry,
  PopupProductGcp,
} from './styled/PopupNewProduct.styled';
import { MediaItemSideDelete } from '../itemElement/MediaItem/MediaItem.styled';

const findItemIndex = (attributes, item, index) => {
  let findIndex;
  let i = 0;
  for (let k = 0; k < attributes.length; k++) {
    if (attributes[k].id === item.id) {
      if (i === index) {
        findIndex = k;
        break;
      }
      i++;
    }
  }
  return findIndex;
};

class PopupNewProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      innValidation: {
        process: false,
        successed: false,
      },
      data: {
        attrGroup: [
          {
            id: 25,
            name: 'Идентификация Участников Оборота Товаров',
            attributes: [
              {
                dataQualifier: null,
                description: null,
                disabled: false,
                // надо как то разобраться с это фигней.
                // disabled: !this.props.manually,
                fieldType: 'text',
                groupId: 25,
                gtin: '',
                gtinRequired: false,
                id: 15677,
                isFromGs1: false,
                isGtinAffect: true,
                isNtinAffect: true,
                isPresetOnly: false,
                markedProductRequired: false,
                modalView: null,
                multiplicity: false,
                name: this.props.t('Юридический адрес'),
                preset: [],
                presetError: null,
                required: true,
                sellProductRequired: false,
                sortOrder: 5,
                type: 'r',
                uId: null,
                url: null,
                value: null,
                valueId: null,
                valueTitle: null,
                valueType: null,
                // valuesTypePreset: [
                //   { id: 'RU', text: 'RU' },
                //   { id: 'BY', text: 'BY' },
                //   { id: 'UA', text: 'UA' },
                //   { id: 'PL', text: 'PL' },
                //   { id: 'IT', text: 'IT' },
                //   { id: 'DE', text: 'DE' },
                //   { id: 'FR', text: 'FR' },
                //   { id: 'RO', text: 'RO' },
                //   { id: 'GB', text: 'GB' },
                //   { id: 'FI', text: 'FI' },
                //   { id: 'TR', text: 'TR' },
                //   { id: 'SL', text: 'SL' },
                //   { id: 'SE', text: 'SE' },
                //   { id: 'CH', text: 'CH' },
                //   { id: 'CN', text: 'CN' },
                //   { id: 'HU', text: 'HU' },
                //   { id: 'ES', text: 'ES' },
                //   { id: 'CZ', text: 'CZ' },
                //   { id: 'BE', text: 'BE' },
                //   { id: 'NL', text: 'NL' },
                //   { id: 'AT', text: 'AT' },
                //   { id: 'LT', text: 'LT' },
                //   { id: 'EE', text: 'EE' },
                //   { id: 'BG', text: 'BG' },
                //   { id: 'BR', text: 'BR' },
                //   { id: 'US', text: 'US' },
                //   { id: 'ID', text: 'ID' },
                //   { id: 'GR', text: 'GR' },
                //   { id: 'IE', text: 'IE' },
                //   { id: 'TH', text: 'TH' },
                //   { id: 'JP', text: 'JP' },
                //   { id: 'SI', text: 'SI' },
                //   { id: 'SG', text: 'SG' },
                //   { id: 'MY', text: 'MY' },
                //   { id: 'AU', text: 'AU' },
                //   { id: 'IL', text: 'IL' },
                //   { id: 'HR', text: 'HR' },
                //   { id: 'GE', text: 'GE' },
                //   { id: 'RS', text: 'RS' },
                //   { id: 'LK', text: 'LK' },
                //   { id: 'DK', text: 'DK' },
                //   { id: 'MD', text: 'MD' },
                //   { id: 'MX', text: 'MX' },
                //   { id: 'SK', text: 'SK' },
                //   { id: 'AM', text: 'AM' },
                //   { id: 'IN', text: 'IN' },
                //   { id: 'EG', text: 'EG' },
                //   { id: 'LV', text: 'LV' },
                //   { id: 'PK', text: 'PK' },
                //   { id: 'VN', text: 'VN' },
                //   { id: 'PT', text: 'PT' },
                //   { id: 'TT', text: 'TT' },
                //   { id: 'GT', text: 'GT' },
                //   { id: 'CU', text: 'CU' },
                //   { id: 'AE', text: 'AE' },
                //   { id: 'CL', text: 'CL' },
                //   { id: 'ZA', text: 'ZA' },
                //   { id: 'NZ', text: 'NZ' },
                //   { id: 'CI', text: 'CI' },
                //   { id: 'AR', text: 'AR' },
                //   { id: 'MA', text: 'MA' },
                //   { id: 'KR', text: 'KR' },
                //   { id: 'UZ', text: 'UZ' },
                //   { id: 'KG', text: 'KG' },
                //   { id: 'KZ', text: 'KZ' },
                // ],
                valuesTypePreset: [],
                _attruid: 'attruid_13',
                modalType: 'createConsumerPackage',
                // defaultValueAddress: this.props.data.direction.address || '',
                // defaultValueCountry: this.props.data.direction.country || ''
                defaultValueCountry: 'KZ',
              },
            ],
          },
        ],
      },
      moderateErrors: [],
      popupElement: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(this.props.data.inn, prevProps.data.inn)) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, inn: false },
        // innValidation: {...prevState.innValidation, message: '', successed: false}
      }));
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const valuesTypePreset = prevState.data.attrGroup[0].attributes[0].valuesTypePreset
    if (nextProps.countries !== prevState.data.attrGroup[0].attributes[0].valuesTypePreset) {
      return valuesTypePreset.splice(0, valuesTypePreset.length, ...nextProps.countries)
    }
    return null;
  }

  deleteItem = () => {
    this.props.deleteItem(this.props.typeId);
  };

  gcpIsValid = (gpc) => {
    return _.includes([4, 5, 6, 7, 8, 9], _.size(_.toString(gpc)));
  };

  innIsValid = (inn) => {
    return _.includes([3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14], _.size(_.toString(inn)));
  };

  handleChange = (name, typeId) => (value) => {
    typeId = this.props.typeId;

    if (name === 'inn' || name === 'gcp' || name === 'gln') {
      value = value.replace(/[^[0-9]/, '');
      // TODO: исправить.
      //костыль
      if (name === 'inn' && value.length > 0) {
        this.setState({
          innValidation: {
            successed: true,
          },
        });
      }
    }
    this.props.updateItem(this.props.data.id, { [name]: value }, typeId);
  };

  handlePaste = (name, typeId) => (value) => {
    this.props.updateItem(this.props.data.id, { [name]: value }, typeId);
  };

  updateData = (selection, data) => {
    if (selection === 'identifier') {
      _.forEach(data, (pack) => {
        _.forEach(['length', 'width', 'height', 'weight'], (i) => {
          if (!_.isNil(pack[i])) {
            pack[i] = Number(pack[i]);
          }
        });
      });
    }
    this.setState((prevState) => ({
      data: { ...prevState.data, [selection]: data },
    }));
  };

  updateItem = (items, groupIndex, index) => {
    let attrGroup = _.cloneDeep(this.state.data.attrGroup);
    let attributes = attrGroup[groupIndex].attributes;
    const updateItem = items[index];

    this.handleChange('direction')({
      address: updateItem.value,
      country: updateItem.valueType,
    });

    const updatedIndex = findItemIndex(attributes, items[index], index);
    if (!_.isUndefined(updatedIndex)) {
      _.set(
        attrGroup,
        `[${groupIndex}].attributes.[${updatedIndex}]`,
        updateItem,
      );
      this.updateData('attrGroup', attrGroup);
    }
  };

  buildBlocks = (firstPackageGroups) => {
    const jsxBlocks = [];
    _.forEach(firstPackageGroups, (attribute, attributeIndex) => {
      const elements = [];
      const ids = [];
      _.forEach(attribute.attributes, (element) => {
        if (!ids.includes(element.id)) {
          ids.push(element.id);
          elements.push(
            this.buildElement(element, attribute.attributes, attributeIndex),
          );
        }
      });

      jsxBlocks.push(
        <div key={`block_group_${attributeIndex}`}>{elements}</div>,
      );
    });

    return jsxBlocks;
  };

  buildElement = (element, elements, attributeIndex) => {
    const { onValidate, productData, data, disabledGtin } = this.props;
    const { moderateErrors, popupElement, errors } = this.state;

    let props = {
      groupIndex: attributeIndex,
      updateItem: this.updateItem,
      onValidate: onValidate,
      showPopupSearch: () => { },
      closePopupSearch: () => { },
      popupElement: popupElement,
      errors: errors,
      moderateErrors: moderateErrors,
      preview: false,
      hasPublishedGood: _.get(productData, 'hasPublishedGood', false),
      defaultValueAddress: data.direction.address || '',
      defaultValueCountry: data.direction.country || '',
      editableAddressBlock: disabledGtin ? false : data.editable,
    };

    if (element.multiplicity) {
      props = {
        ...props,
        multiplicity: true,
        items: _.filter(elements, { id: element.id }),
        addItem: this.addItem,
        deleteItem: this.deleteItem,
      };
    } else {
      props = {
        ...props,
        multiplicity: false,
        items: [element],
        addItem: () => { },
        deleteItem: () => { },
      };
    }

    return (
      <Element key={`element_${element._attruid}`} name={`id_${element.id}`}>
        <ConsumerItem {...props} />
      </Element>
    );
  };

  render() {
    const {
      data,
      t,
      disabledGtin,
      addItem,
      isHasAddItemButton,
      typeId,
      blockType,
      isInnBusy,
      isGcpInnBusy,
      sendSubaccount,
    } = this.props;
    const { id } = data;

    const fields = this.buildBlocks(this.state.data.attrGroup);

    const checkboxDisabled = () => {
      let result = false;
      const { data } = this.props;

      //if (!this.state.innValidation.successed) return true;

      if (
        isEmpty(data.inn) ||
        isEmpty(data.name) ||
        isEmpty(data.direction.address) ||
        isEmpty(data.direction.country)
      ) {
        result = true;
      } else {
        if (data.id === 'manufacturerId_' && isEmpty(data.gcp)) {
          result = true;
        }
        if (
          data.id !== 'manufacturerId_' &&
          this.state.innValidation.successed
        ) {
          result = false;
        }
      }
      return result;
    };

    const onCheckChebox = (flag, data) => {
      if (flag) {
        return sendSubaccount(data);
      }

      return;
    };

    const udplicateUserInn = t(
      'Ввод информации о зарегистрированной компании осуществляется путем выбора из справочника',
    );
    const innIsValid =
      (data.inn.length >= 3 && data.inn.length < 15 ) && (data.editable ? !isGcpInnBusy : true);
    const nameIsValid = !!data.name;

    return (
      <div>
        <PopupProductContainer>
          <PopupProductInn>
            <CustomInput
              id={`inn_${id}`}
              name="inn"
              required
              label={t('Налоговый идентификатор')}
              onChange={this.handleChange('inn', typeId)}
              onPaste={this.handlePaste('inn', typeId)}
              value={defaultTo(data.inn, '')}
              valid={innIsValid}
              error={data.inn ? !this.innIsValid(data.inn) : false}
              maxLength={14}
              type="text"
              disabled={!data.editable || disabledGtin}
              style={{}}
              t={t}
            />
            {data.editable && isGcpInnBusy
              ? SimpleTooltip(udplicateUserInn, <IconError />)
              : data.editable && isInnBusy 
              ? SimpleTooltip(udplicateUserInn,<IconInfoTooltip />)
              : null}
          </PopupProductInn>

          <PopupProductName>
            <CustomInput
              id={`name_${id}`}
              name="name"
              required
              label={t('Наименование компании')}
              onChange={this.handleChange('name', typeId)}
              value={defaultTo(data.name, '')}
              valid={nameIsValid}
              type="text"
              disabled={!data.editable || disabledGtin}
            />
          </PopupProductName>

          <PopupProductGcp>
            <CustomInput
              id={`gcp_${id}`}
              name="gcp"
              required={blockType === 'manufacturer'}
              label={t('Префикс GCP')}
              onChange={this.handleChange('gcp', typeId)}
              onPaste={this.handlePaste('gcp', typeId)}
              value={defaultTo(data.gcp, '')}
              valid={this.gcpIsValid(defaultTo(data.gcp, ''))}
              error={data.gcp ? !this.gcpIsValid(data.gcp) : false}
              type="text"
              maxLength={9}
              disabled={!data.editable || disabledGtin}
              t={t}
            />
            {/**
             * Пояснение к костылю.
             *
             * Требования от заказчика - т.к. GCP всегда должно быть обязательным, то нужно подсвечивать это поле. Но т.к.
             * по всему проекту у disabled полей нет подсветки, а тут надо, то приходится его лепить в ручную.
             *
             * Пример:
             * Пользователь выбрал Производителя из выпадающего списка - поля на редактирование закрыты, но у выбранной сущности
             * отсутствует GCP. В таком случае надо подсвечивать это поле как required, сигнализируя о том что в форме ошибка.
             */}
            {!this.gcpIsValid(defaultTo(data.gcp, '')) &&
              blockType === 'manufacturer' &&
              (!data.editable || disabledGtin) && (
                <div
                  style={{
                    minHeight: '50px',
                    maxHight: '50px',
                    marginTop: '17px',
                    borderLeft: '4px solid #FF4B4B',
                    position: 'absolute',
                    left: ' 1px',
                    top: '0px',
                    borderRadius: '3px 0 0 3px',
                  }}
                />
              )}
          </PopupProductGcp>

          <PopupProductCountry>{fields}</PopupProductCountry>

          {/* <FormControlLabel
            control={
              <Checkbox
                disabled={
                  isInnBusy ||
                  checkboxDisabled() ||
                  (data.gcp && !this.gcpIsValid(data.gcp))
                }
                onChange={(event) =>
                  onCheckChebox(event.target.checked, this.props.data)
                }
                checkedIcon={<IconCheck />}
              />
            }
            label={t('Сохранить в субаккаунты')}
          /> */}

          {isHasAddItemButton && blockType !== 'importer' && (
            <MuiThemeProvider theme={ProductButton}>
              <Button
                color={'secondary'}
                onClick={addItem}
                disabled={disabledGtin}>
                <IconPlus style={{ marginRight: '15px' }} />
                {t('Добавить')}
              </Button>
            </MuiThemeProvider>
          )}
          {typeId !== 0 && (
            <MediaItemSideDelete>
              <MuiThemeProvider theme={ProductButton}>
                <Button
                  color={'primary'}
                  onClick={this.deleteItem}
                  disabled={!data.isActiveSelect || disabledGtin}>
                  <IconDeleteSmall style={{ marginRight: '15px' }} />
                  {t('Удалить')}
                </Button>
              </MuiThemeProvider>
            </MediaItemSideDelete>
          )}
        </PopupProductContainer>
      </div>
    );
  }
}

PopupNewProductItem.propTypes = {
  deleteItem: PropTypes.func,
  updateItem: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isInnBusy: PropTypes.bool.isRequired,
  isGcpInnBusy: PropTypes.bool.isRequired,
  isHasAddItemButton: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
});

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(PopupNewProductItem);
