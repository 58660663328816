import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const CheckboxTitle = styled.h5`
  font-size: 16px;
  color: #52535a;
  font-weight: 500;
  margin: 0 0 15px;
`;
export const checkboxInput = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '16px 45px 16px 15px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderLeft: '4px solid #FF4B4B',
          borderRadius: '3px',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
      inputMultiline: {
        padding: '0!important',
      },
      multiline: {
        minHeight: '51px',
        padding: '10px 9px',
      },
      error: {},
    },
    MuiInputBase: {
      root: {
        width: '100%',
        marginTop: '0!important',
      },
      input: {
        boxSizing: 'border-box',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#52535A',
        fontSize: '14px',
        lineHeight: '20px',
      },
      formControl: {
        transform: 'translate(15px, 18px) scale(1)',
        color: '#52535A',
      },
      shrink: {
        display: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#52535A',
        '&$focused': {
          color: '#52535A',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
