import * as React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  MuiThemeProvider,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { CustomInput } from '../../../../components';
import {
  IconLogoDropDown,
  IconMinus,
  IconPlus,
  IconDropDownAuto,
} from '../../../../common/icons/customIcons';
import {
  defaultTheme,
  ProductButton,
  defaultSelectTheme,
} from '../../../../common/styles/Common.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';

const getDefaultTo = (obj, path, defaultValue = '') =>
  _.defaultTo(_.get(obj, path, defaultValue), defaultValue);

/**
 * TODO:
 * полностью переписать валидацию на что-то более осмысленное.
 */

class MultipleAndTransportItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: true,
      selectIsDuplicated: false,
      inputIsDuplicated: false,
      gtinIsAlreadyInUse: false,
      gtinLengthIsValid: false,
      gtinChecksumIsValid: false,
    };
  }

  isError = () => {
    const {
      selectIsDuplicated,
      inputIsDuplicated,
      gtinIsAlreadyInUse,
      gtinLengthIsValid,
    } = this.state;

    return (
      selectIsDuplicated ||
      inputIsDuplicated ||
      gtinIsAlreadyInUse ||
      !gtinLengthIsValid
    );
  };

  /**
   *
   * @param {*} value зачение инпута, если точнее value.path у опшена.
   * @param {*} name имя поля
   * @param {*} type тип поля. registered only в текущем случае.
   * @param {*} index индекс поля в группе(не используется пока что)
   * @param {*} selectedOptions nestedUnregisteredArray, в котором будем искать совпадения с текущим
   *  выбором в автокомплите.
   */
  handleChangeRegisteredCode = (
    value = '',
    name,
    type,
    index,
    selectedOptions,
  ) => {
    let formHasErrors;

    if (value === this.props.data.nestedPackagingCode) {
      return;
    }

    if (_.some(selectedOptions, ['nestedPackagingCode', value])) {
      formHasErrors = true;
      this.setState({ errors: true, selectIsDuplicated: true });
    } else {
      formHasErrors = false;
      this.setState({ errors: false, selectIsDuplicated: false });
    }

    this.props.updateItem(
      this.props.data.id,
      { nestedPackagingCode: value },
      formHasErrors,
      type,
    );
    return;
  };

  /**
   *
   * @param {*} name имя поля
   * @param {*} type тип поля registered/unregistered, т.к. теперь понимать, в какой массив писать значения. в arrayData
   *   или в nestedUnregisteredData принадлежащие к nestedUnregisteredArray, для групповой упаковки.
   *   Транспортная упаковка пишется в nestedArray
   * @param {*} selectedOptions уже записанные значения из:
   *   nestedArray - для транспортной упаковки
   *   nestedUnregisteredArray - для групповой упаковки
   */

  handleChange = (name, type, index, selectedOptions) => (value) => {
    let formHasErrors;
    let selectIsDuplicated;

    if (name === 'nestedPackagingCode' && type === 'transport') {
      if (value.target.value === this.props.data.nestedPackagingCode) {
        return;
      }
      if (
        _.some(selectedOptions, ['nestedPackagingCode', value.target.value])
      ) {
        formHasErrors = true;
        selectIsDuplicated = true;
        this.setState({ errors: true, selectIsDuplicated });
      } else {
        formHasErrors = false;
        selectIsDuplicated = false;
        this.setState({ errors: false, selectIsDuplicated });
      }
      /**
       * _.every принимает 0 и false как отсутствие value, поэтому в selectIsDuplicated:
       * "1" - это true
       * "0" - это false
       * жутковато.
       */
      this.props.updateItem(
        this.props.data.id,
        {
          nestedPackagingCode: value.target.value,
          selectIsDuplicated: selectIsDuplicated ? '1' : '0',
        },
        formHasErrors,
        type,
      );
      return;
    }

    /**
     * TODO: переписать на нормальный вариант.
     */
    if (name === 'nestedPackagingCode' && type === 'unregistered') {
      value = value.replace(/[^[0-9]/, '');

      let lengthIsValid;
      let alreadyExist;
      let duplicatedCode;
      let checksumIsValid;

      /**
       * validation steps:
       * 1) Проверить что длинна кода соответствует стандарту
       * 2) Проверить что нет уже созданных упаковок с таким кодом
       * 3) Проверить что в этом уже нет такого кода
       * 4) спросить у бэка правильное ли контрольное число - пока что нет ендпоинта
       */

      if (value.length && _.includes([8, 12, 13], _.size(_.toString(value)))) {
        lengthIsValid = true;
        this.setState({ errors: false, gtinLengthIsValid: true });
      }

      if (value.length && !_.includes([8, 12, 13], _.size(_.toString(value)))) {
        lengthIsValid = false;
        this.setState({ errors: true, gtinLengthIsValid: false });
      }

      if (
        value.length &&
        _.includes(this.props.validationList, _.toString(value))
      ) {
        alreadyExist = true;
        this.setState({ errors: true, gtinIsAlreadyInUse: true });
      }

      if (
        value.length &&
        !_.includes(this.props.validationList, _.toString(value))
      ) {
        alreadyExist = false;
        this.setState({ errors: false, gtinIsAlreadyInUse: false });
      }

      if (
        value.length &&
        _.some(selectedOptions, ['nestedPackagingCode', value])
      ) {
        duplicatedCode = true;
        this.setState({ errors: true, inputIsDuplicated: true });
      }

      if (
        value.length &&
        !_.some(selectedOptions, ['nestedPackagingCode', value])
      ) {
        duplicatedCode = false;
        this.setState({ errors: false, inputIsDuplicated: false });
      }

      if (value.length && lengthIsValid && !alreadyExist && !duplicatedCode) {
        this.props.validateUnregistredGtin(
          { gtin: _.toString(value) },
          (errors, successed, response) => {
            if (successed) {
              checksumIsValid = true;
              this.setState({ errors: false, gtinChecksumIsValid: true });
              const isError =
                !lengthIsValid ||
                alreadyExist ||
                duplicatedCode ||
                !checksumIsValid;

              this.props.updateItem(
                this.props.data.id,
                { [name]: value },
                isError,
                type,
              );
              return;
            }

            if (errors) {
              checksumIsValid = false;
              this.setState({ errors: true, gtinChecksumIsValid: false });
              const isError =
                !lengthIsValid ||
                alreadyExist ||
                duplicatedCode ||
                !checksumIsValid;

              this.props.updateItem(
                this.props.data.id,
                { [name]: value },
                isError,
                type,
              );
              return;
            }
          },
        );
      }

      const isError =
        !!value.length &&
        (!lengthIsValid || alreadyExist || duplicatedCode || !checksumIsValid);
      this.props.updateItem(
        this.props.data.id,
        { [name]: value },
        isError,
        type,
      );
      return;
    }

    if (name === 'count') {
      value = value.replace(/[^[0-9]/, '');
      value = value === '0' ? null : value;
      formHasErrors = value === null;
    }

    this.props.updateItem(
      this.props.data.id,
      { [name]: value },
      formHasErrors,
      type,
    );
  };

  handlePaste = (name, typeId) => (value) => {
    this.props.updateItem(this.props.data.id, { [name]: value }, typeId);
  };

  render() {
    const {
      data,
      t,
      selectOptions,
      selectedOptions,
      index,
      groupAndTransportButtonsDisabled,
      lockAllFields,
      type,
      groupRegisteredAddDisabled,
      groupUnregisteredAddDisabled,
      typeId,
    } = this.props;
    const { errors, selectIsDuplicated } = this.state;

    const genereateUnregGringLabel = () => {
      let label = t('Код вложенной упаковки');
      if (!data.nestedPackagingCode) {
        return (label = t('Укажите код вложенной упаковки'));
      }
      if (data.nestedPackagingCode) {
        if (!this.state.gtinLengthIsValid) {
          return (label = t('Укажите корректный код упаковки'));
        }
        if (this.state.inputIsDuplicated) {
          return (label = t(
            'Нельзя выбрать два одинаковых кода вложенной упаковки',
          ));
        }
        if (this.state.gtinIsAlreadyInUse) {
          return (label = t(
            'Зарегистрированные коды необходимо выбирать из справочника, а не указывать вручную',
          ));
        }
        if (!this.state.gtinChecksumIsValid) {
          return (label = t('Укажите корректный код упаковки'));
        }
      }

      return label;
    };

    const getOptions = () => {
      return _.map(selectOptions, (item, index) => {
        return {
          id: index,
          path: item,
          title: item.padStart(14, '0'),
        };
      });
    };

    return (
      <>
        {type === 'groupReg' && (
          <>
            {/**
             *  Блок для "Вложенные зарегистрированные товары"
             */}
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <MuiThemeProvider theme={CertSelectAuto}>
                  <Autocomplete
                    options={getOptions()}
                    autoHighlight
                    onChange={(event, item) =>
                      this.handleChangeRegisteredCode(
                        item && item.path,
                        'nestedPackagingCode',
                        'registered',
                        index,
                        selectedOptions,
                      )
                    }
                    getOptionLabel={(item) => item.title}
                    popupIcon={<IconDropDownAuto />}
                    disabled={lockAllFields}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          !selectIsDuplicated
                            ? t('Выберите код вложенной упаковки')
                            : t(
                              'Нельзя выбрать два одинаковых кода вложенной упаковки',
                            )
                        }
                        variant="standard"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                        error={selectIsDuplicated}
                      />
                    )}
                    noOptionsText={t('Нет совпадений')}
                  />
                </MuiThemeProvider>
              </FormControl>
            </MuiThemeProvider>
            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                name="count"
                type="text"
                valid={!!data.count}
                dirty={true}
                label={t('Укажите количество вложенных упаковок')}
                onChange={this.handleChange('count', 'registered', index)}
                onPaste={this.handlePaste('count', typeId)}
                value={getDefaultTo(data, 'count')}
                disabled={lockAllFields || !data.nestedPackagingCode}
                required={!!data.nestedPackagingCode}
              />
            </MuiThemeProvider>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MuiThemeProvider theme={ProductButton}>
                <Button
                  color={'secondary'}
                  disabled={groupRegisteredAddDisabled || lockAllFields}
                  onClick={() => this.props.addItem('registered')}
                  name="addButton">
                  <IconPlus style={{ marginRight: '15px' }} />
                  {t('Добавить')}
                </Button>
              </MuiThemeProvider>
              {index > 0 && (
                <MuiThemeProvider theme={ProductButton}>
                  <Button
                    color={'secondary'}
                    onClick={() => this.props.deleteItem(data.id, 'registered')}
                    name="deleteButton"
                    disabled={lockAllFields}>
                    <IconMinus style={{ marginRight: '15px' }} />
                    {t('Удалить')}
                  </Button>
                </MuiThemeProvider>
              )}
            </div>
          </>
        )}

        {type === 'groupUnreg' && (
          <>
            {/**
             *  Блок для "Вложенные незарегистрированные товары"
             */}
            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                name="nestedPackagingCode"
                type="text"
                // required
                valid={
                  !!data.nestedPackagingCode &&
                  this.state.gtinLengthIsValid &&
                  !this.state.inputIsDuplicated &&
                  !this.state.gtinIsAlreadyInUse &&
                  this.state.gtinChecksumIsValid
                }
                // valid={!!data.nestedPackagingCode && (!this.state.inputIsDuplicated || !this.state.gtinIsAlreadyInUse)}
                error={
                  !!data.nestedPackagingCode
                    ? !this.state.gtinLengthIsValid ||
                    this.state.inputIsDuplicated ||
                    this.state.gtinIsAlreadyInUse ||
                    !this.state.gtinChecksumIsValid
                    : false
                }
                // error={!!data.nestedPackagingCode && (this.state.inputIsDuplicated || this.state.gtinIsAlreadyInUse)}
                dirty={true}
                label={genereateUnregGringLabel()}
                onChange={this.handleChange(
                  'nestedPackagingCode',
                  'unregistered',
                  index,
                  selectedOptions,
                )}
                value={getDefaultTo(data, 'nestedPackagingCode')}
                disabled={lockAllFields}
                required={!!data.nestedPackagingCode}
              />
            </MuiThemeProvider>
            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                name="count"
                type="text"
                required={!!data.nestedPackagingCode}
                valid={!!data.count}
                dirty={true}
                label={t('Укажите количество вложенных упаковок')}
                onChange={this.handleChange('count', 'unregistered', index)}
                onPaste={this.handlePaste('count', typeId)}
                value={getDefaultTo(data, 'count')}
                disabled={lockAllFields || !data.nestedPackagingCode}
              />
            </MuiThemeProvider>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MuiThemeProvider theme={ProductButton}>
                <Button
                  color={'secondary'}
                  disabled={groupUnregisteredAddDisabled || lockAllFields}
                  onClick={() => this.props.addItem('unregistered')}
                  name="addButton">
                  <IconPlus style={{ marginRight: '15px' }} />
                  {t('Добавить')}
                </Button>
              </MuiThemeProvider>
              {index > 0 && (
                <MuiThemeProvider theme={ProductButton}>
                  <Button
                    color={'secondary'}
                    onClick={() =>
                      this.props.deleteItem(data.id, 'unregistered')
                    }
                    name="deleteButton"
                    disabled={lockAllFields}>
                    <IconMinus style={{ marginRight: '15px' }} />
                    {t('Удалить')}
                  </Button>
                </MuiThemeProvider>
              )}
            </div>
          </>
        )}
        {type === 'transport' && (
          <>
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="gtin-select"
                  disabled
                  error={selectIsDuplicated}>
                  {!selectIsDuplicated
                    ? t('Выберите код вложенной упаковки')
                    : t(
                      'Нельзя выбрать два одинаковых кода вложенной упаковки',
                    )}
                </InputLabel>
                <Select
                  required
                  name="nestedPackagingCode"
                  value={getDefaultTo(data, 'nestedPackagingCode')}
                  valid={_.toString(!errors)}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  inputProps={{ id: 'gtin-select' }}
                  IconComponent={IconLogoDropDown}
                  onChange={this.handleChange(
                    'nestedPackagingCode',
                    'transport',
                    index,
                    selectedOptions,
                  )}
                  disabled={lockAllFields}>
                  {_.map(selectOptions, (item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item.padStart(14, '0')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MuiThemeProvider>

            <MuiThemeProvider theme={defaultTheme}>
              <CustomInput
                name="count"
                type="text"
                required
                valid={!!data.count}
                dirty={true}
                label={t('Укажите количество вложенных упаковок')}
                onChange={this.handleChange('count', 'transport')}
                onPaste={this.handlePaste('count', typeId)}
                value={getDefaultTo(data, 'count')}
                disabled={lockAllFields}
              />
            </MuiThemeProvider>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MuiThemeProvider theme={ProductButton}>
                <Button
                  color={'secondary'}
                  disabled={groupAndTransportButtonsDisabled || lockAllFields}
                  onClick={this.props.addItem}
                  name="addButton">
                  <IconPlus style={{ marginRight: '15px' }} />
                  {t('Добавить')}
                </Button>
              </MuiThemeProvider>
              {index > 0 && (
                <MuiThemeProvider theme={ProductButton}>
                  <Button
                    color={'secondary'}
                    onClick={() =>
                      this.props.deleteItem(data.id, 'transportAndGroup')
                    }
                    name="deleteButton"
                    disabled={lockAllFields}>
                    <IconMinus style={{ marginRight: '15px' }} />
                    {t('Удалить')}
                  </Button>
                </MuiThemeProvider>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

MultipleAndTransportItem.propTypes = {
  updateItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  selectOptions: PropTypes.array.isRequired,
};

export default compose(withTranslation())(MultipleAndTransportItem);
