import { ActionCreator } from 'redux';
import {
  DELETE_TRADE_MARK_ACTION,
  DELETE_TRADE_MARK_ACTION_SUCCEEDED,
  DELETE_TRADE_MARK_ACTION_FAILED,
  LOAD_TRADE_MARKS_ACTION,
  LOAD_TRADE_MARKS_FAILED_ACTION,
  LOAD_TRADE_MARKS_SUCCEEDED_ACTION,
  ADD_TRADE_MARK_ACTION,
  ADD_TRADE_MARK_ACTION_FAILED,
  ADD_TRADE_MARK_ACTION_SUCCEEDED,
  UPDATE_TRADE_MARK_ACTION,
  UPDATE_TRADE_MARK_ACTION_SUCCEEDED,
  UPDATE_TRADE_MARK_ACTION_FAILED,
  ADD_TRADE_MARK_FILE_ACTION,
  ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED,
  ADD_TRADE_MARK_FILE_ACTION_FAILED,
  GET_TRADE_MARK_DOCUMENTS_ACTION,
  GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
  GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
  DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
} from './TradeMarks.constants';

export const loadTradeMarks: ActionCreator = (params) => ({
  type: LOAD_TRADE_MARKS_ACTION,
  params: params,
});

export const loadTradeMarksSucceeded: ActionCreator = (data, totalObjects) => ({
  type: LOAD_TRADE_MARKS_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadTradeMarksFailed: ActionCreator = () => ({
  type: LOAD_TRADE_MARKS_FAILED_ACTION,
});

export const deleteTradeMark: ActionCreator = (id, cbk = () => null) => ({
  type: DELETE_TRADE_MARK_ACTION,
  id: id,
  cbk: cbk,
});

export const deleteTradeMarkSucceeded: ActionCreator = () => ({
  type: DELETE_TRADE_MARK_ACTION_SUCCEEDED,
});

export const deleteTradeMarkFailed: ActionCreator = () => ({
  type: DELETE_TRADE_MARK_ACTION_FAILED,
});

export const addTradeMark: ActionCreator = (data, cbk = () => null) => ({
  type: ADD_TRADE_MARK_ACTION,
  data,
  cbk,
});

export const addTradeMarkSucceeded: ActionCreator = () => ({
  type: ADD_TRADE_MARK_ACTION_SUCCEEDED,
});

export const addTradeMarkFailed: ActionCreator = () => ({
  type: ADD_TRADE_MARK_ACTION_FAILED,
});

export const updateTradeMark: ActionCreator = (data, id, cbk = () => null) => ({
  type: UPDATE_TRADE_MARK_ACTION,
  data,
  id,
  cbk,
});

export const updateTradeMarkSucceeded: ActionCreator = () => ({
  type: UPDATE_TRADE_MARK_ACTION_SUCCEEDED,
});

export const updateTradeMarkFailed: ActionCreator = () => ({
  type: UPDATE_TRADE_MARK_ACTION_FAILED,
});

export const addTradeMarkFile: ActionCreator = (data, cbk = () => null) => ({
  type: ADD_TRADE_MARK_FILE_ACTION,
  data,
  cbk,
});

export const addTradeMarkFileSucceeded: ActionCreator = () => ({
  type: ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED,
});

export const addTradeMarkFileFailed: ActionCreator = () => ({
  type: ADD_TRADE_MARK_FILE_ACTION_FAILED,
});

export const getTradeMarkDocuments: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: GET_TRADE_MARK_DOCUMENTS_ACTION,
  data,
  cbk,
});

export const getTradeMarkDocumentsSucceeded: ActionCreator = () => ({
  type: GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
});

export const getTradeMarkDocumentsFailed: ActionCreator = () => ({
  type: GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
});

export const deleteTradeMarkDocuments: ActionCreator = (
  data,
  cbk = () => null,
) => ({
  type: DELETE_TRADE_MARK_DOCUMENTS_ACTION,
  data,
  cbk,
});

export const deleteTradeMarkDocumentsSucceeded: ActionCreator = () => ({
  type: DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED,
});

export const deleteTradeMarkDocumentsFailed: ActionCreator = () => ({
  type: DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED,
});
