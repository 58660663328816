import { ActionCreator } from 'redux';

export const THROW_NOTIFICATION = 'THROW_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const throwNotification: ActionCreator = (
  message,
  type,
  timeout = 5000,
) => ({
  type: THROW_NOTIFICATION,
  data: { message, type, timeout },
});

export const clearNotification: ActionCreator = () => ({
  type: THROW_NOTIFICATION,
  data: { message: '', type: '' },
});

export const deleteNotification: ActionCreator = () => ({
  type: DELETE_NOTIFICATION,
});
