import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ColumnCellInner } from '../../../../common/styles/Common.styled';
import { ColumnHeaderDate } from '../ProductsGrid.styled';
import { Tooltip } from '@material-ui/core';
import { clippedValue } from '../../../../common/utils/utils';

export default class GtinCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { fillWithZeros, value, isClipped } = this.props;

    return (
      <ColumnHeaderDate>
        <ColumnCellInner>
          {isClipped ? (
            <Tooltip arrow placement="bottom-start" title={value}>
              <div>{clippedValue(value)}</div>
            </Tooltip>
          ) : fillWithZeros ? (
            value.padStart(14, '0')
          ) : (
            value
          )}
        </ColumnCellInner>
      </ColumnHeaderDate>
    );
  }
}

GtinCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
