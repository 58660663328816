import { ActionCreator } from 'redux';
import {
  BROWSER_CHECK,
  GET_CERTIFICATES,
  GET_CERTS_FAILED,
  LOAD_CERTS,
  REG_REQUEST,
} from './RegistrationActionTypes';

export const loadCerts: ActionCreator = () => {
  return { type: LOAD_CERTS };
};

export const getCertsSuccessed: ActionCreator = (certificates) => ({
  type: GET_CERTIFICATES,
  certificates: certificates,
});

export const getCertsFailed: ActionCreator = () => ({ type: GET_CERTS_FAILED });

export const regRequest: ActionCreator = (data) => ({
  type: REG_REQUEST,
  data: data,
});

export const browserCheck: ActionCreator = () => ({ type: BROWSER_CHECK });
