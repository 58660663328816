import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const ProductDivider = styled.div`
  height: 40px;
`;
export const ProductTypeSelectField = styled.div`
  display: flex;
  width: 100%;
`;
export const IconEditWrapper = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;
export const ProductTypeSelectLeft = styled.div`
  min-width: 60px;
  flex-shrink: 0;
`;
export const ProductTypeSelectRight = styled.div`
  flex-grow: 1;
`;
export const IconSearchHolder = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-3px);
  left: 75px;
`;
export const customInputTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderLeft: 'none',
        borderRadius: '0px 3px 3px 0px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '23px 55px 6px 19px',
        lineHeight: '21px',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderRadius: '0px 3px 3px 0px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        height: 'auto',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      //inputType: {
      //height: "auto"
      //},
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const customInputThemeSearch = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderLeft: 'none',
        borderRadius: '0px 3px 3px 0px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '21px 55px 6px',
        lineHeight: '21px',
        fontFamily: 'SegoeUI',
        '&:required': {
          borderRadius: '0px 3px 3px 0px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(55px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(55px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      input: {
        fontWeight: '600',
        height: 'auto',
        color: '#52535A',
        fontFamily: 'SegoeUI',
      },
      multiline: {
        padding: '0',
      },
      root: {
        '&$disabled': {
          backgroundColor: '#F9F9F9',
          color: '#52535A!important',
        },
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const customInputRight = createMuiTheme({
  typography: {},
  overrides: {
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderLeft: 'none',
        borderRadius: '0 3px 3px 0',
        marginTop: '16px!important',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
          borderLeft: 'none',
        },
      },
      input: {
        padding: '23px 110px 6px 19px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        lineHeight: '21px',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&:required': {
          borderRadius: '0 3px 3px 0',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          '&:disabled': {
            borderLeft: 'none',
          },
        },
      },
      error: {},
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
      },
      disabled: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '10px 20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '500',
        whiteSpace: 'normal',
        lineHeight: '1.22',
        height: 'auto',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
      },
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
        pointerEvents: 'none',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '0',
        minWidth: '15px',
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        //marginTop: "9px",
        right: '35px',
        '&:hover': {
          background: 'none!important',
        },
      },
      text: {
        padding: '3px',
      },
    },
  },
});
export const customSelectTheme = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        '&$error': {
          border: '1px solid #FF4B4B!important',
        },
      },
      input: {},
      error: {},
      underline: {
        color: 'transparent',
        '&:after': {
          borderBottom: 'none!important',
        },
        '&:before': {
          borderBottom: 'none!important',
        },
        '&:hover:not($disabled):after': {
          backgroundColor: 'transparent',
          borderBottom: 'none',
        },
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
          borderBottom: 'none!important',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        marginTop: '16px!important',
        fontFamily: 'SegoeUI',
        '&$disabled': {
          backgroundColor: '#F9F9F9',
        },
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
      },
      input: {
        padding: '23px 55px 6px 19px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '600',
        height: 'auto',
        fontFamily: 'SegoeUI',
        '&::placeholder': {
          color: '#52535A',
          opacity: '1',
          fontWeight: '500',
          transform: 'translateY(-10px)',
        },
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
        },
      },
      disabled: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
          '&$disabled': {
            color: '#868686!important',
          },
        },
        '&$error': {
          color: '#FF4B4B',
        },
      },
      disabled: {},
      error: {},
      asterisk: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        padding: '10px 20px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '16px',
        color: '#52535A',
        fontWeight: '500',
        whiteSpace: 'normal',
        lineHeight: '1.22',
        height: 'auto',
      },
    },
    MuiPaper: {
      root: {
        maxHeight: '300px!important',
        overflowY: 'scroll',
      },
      rounded: {
        borderRadius: '0 0 3px 3px',
      },
      elevation8: {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, .15)',
      },
    },
    MuiInputAdornment: {
      root: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: '20px',
        maxHeight: '100%',
      },
    },
  },
});
