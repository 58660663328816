import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { get, isNil, isString, size, isArray, isEmpty } from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  CHANGE_ACCESS_COMPANY_ACTION,
  DELETE_SUB_ACCOUNT_ACTION,
  LOAD_SUB_ACCOUNT_ACTION,
  SEND_SUB_ACCOUNT_TO_ASSIGN_ACTION,
  VALIDATION_INN_SUB_COMPANY_ACTION,
  SAVE_SUBACCOUNT_LETTER_ACTION,
  GRANT_KM_ORDER_ACTION,
  REVOKE_KM_ORDER_ACTION,
  ADD_LETTER_INFORMATION,
  GET_GCP_GLN_LETTERS,
  DELETE_GCP_GLN_LETTER,
  UPDATE_SUBACCOUNT_ACTION,
  SEND_SUBACCOUNT_GCP,
  
} from './SubAccount.constants';
import { throwNotification } from '../../../../common/structure';
import {
  loadSubAccountFailed,
  loadSubAccountSucceeded,
  saveSubAccountLetterSucceeded,
  loadSubAccountsForGoodsCreation,
  updateSubAccountSucceeded,
  updateSubAccountFailed,
} from './SubAccount.actions';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

const URL = '/subaccounts';

export function* loadSubAccount(action) {
  const url =
    action.params && action.params.query
      ? URL + '?' + action.params.query
      : URL;
  const fromProfile = !!(action.params && action.params.query);

  try {
    const response = yield call(client().get, url);    
    if (isString(response)) {
      yield put(push('/500'));      
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadSubAccountFailed());
    } else {
      if (fromProfile) {
        yield put(
          loadSubAccountSucceeded(response || [], response.totalObjects || response.length || 0),
        );
      } else {
        yield put(
          loadSubAccountsForGoodsCreation(
            response || [],
            response.totalObjects || response.length || 0,
          ),
        );
      }
    }
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка при загрузке субаккаунтов!'), 'error'),
    );
    yield put(loadSubAccountFailed());
  }
}

export function* deleteSubAccount(action) {
  const errT = i18n.t('Ошибка при удалении субаккаунтов!');
  try {
    const response = yield call(
      client().delete,
      `/subaccounts/${action.id}/bind`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: errT });
    } else {
      action.cbk(null, i18n.t('Успешно'));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* updateSubAccount(action) {
  const errT = i18n.t('Произошла ошибка при обновлении Субаккаунта!');

  try {
    const response = yield call(client().put,
      `/subaccounts/${action.id}/update`,
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
       action.cbk({ error: [errT] });
    }else {
      const message =
        size(action.data) === 1
          ? i18n.t('Субаккаунт успешно обновлен', {
            companyName: action.data[0].name,
          })
          : i18n.t('Субаккаунты успешно обновлены');

      yield put(throwNotification(message, 'info'));
      action.cbk(null);    }
        
  } catch (e) {
    yield put(throwNotification(errT, 'error'));   
    action.cbk({ error: [errT] });
  }
}

export function* sendSubAccountGCP(action) {
  const errT = i18n.t('Произошла ошибка при добавлении GCP компании!');

  try {
    const response = yield call(client().post,
      `/subaccounts/${action.id}/gcp`,
      action.data,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
       action.cbk({ error: [errT] });
    }else {
      const message =
        size(action.data) === 1
          ? i18n.t('GCP компании успешно добавлен', {
            companyName: action.data[0].name,
          })
          : i18n.t('GCP компании успешно добавлены');

      yield put(throwNotification(message, 'info'));
      action.cbk(null);    }
        
  } catch (e) {
    yield put(throwNotification(errT, 'error'));   
    action.cbk({ error: [errT] });
  }
}

export function* sendSubAccount(action) {
  const errT = i18n.t('Ошибка при отправке заявки на привязку субаккаунта!');
  try {
    const formData = {};
    Object.keys(action.data).map((value) => {
      return action.data[value] && (formData[value] = action.data[value]);
    });

    const response = yield call(client().post, URL, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });

    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: [errT] });
    } else {
      const message =
        size(action.data) === 1
          ? i18n.t('Субаккаунт успешно привязан', {
            companyName: action.data[0].name,
          })
          : i18n.t('Субаккаунты успешно привязаны');

      yield put(throwNotification(message, 'info'));
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: [errT] });
  }
}

export function* validateInnSubAccount(action) {
  const errT = i18n.t('Неизвестная ошибка при валидации налогового идентификатора!');
  try {
    const response = yield call(client().post, `/accounts/validate-inn`, {
      inn: action.inn,
    });
    if (response.error) {
      const errors = get(response, 'error.response.data.errors');
      if (!isNil(errors)) {
        yield put(throwNotification(errors[0], 'errorRed'));
        if (errors[0].includes(i18n.t('не зарегистрирован'))) {
          errors[0] = errors[0].substring(0, errors[0].indexOf('.'));
        }
        action.cbk({ error: errors });
      } else {
        yield handleErrorNotification(response.error);
        action.cbk({ error: [errT] });
      }
    } else {
      action.cbk(null, response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: [errT] });
  }
}

export function* changeAccessCompany(action) {
  const errT = i18n.t('Ошибка смены доступа «Привязанных компании»!');
  try {
    const response = yield call(client().put, URL, action.data);
    if (isString(response)) {
      yield put(throwNotification(errT, 'error'));
      return action.cbk({ error: true });
    }

    if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: true });
    } else {
      action.cbk(null);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: true });
  }
}

export function* watchSubAccount() {
  yield takeEvery(LOAD_SUB_ACCOUNT_ACTION, loadSubAccount);
}

export function* watchSubAccountDelete() {
  yield takeEvery(DELETE_SUB_ACCOUNT_ACTION, deleteSubAccount);
}

export function* watchSendSubAccount() {
  yield takeEvery(SEND_SUB_ACCOUNT_TO_ASSIGN_ACTION, sendSubAccount);
}

export function* watchValidateInnSubAccount() {
  yield takeEvery(VALIDATION_INN_SUB_COMPANY_ACTION, validateInnSubAccount);
}

export function* watchChangeAccessCompany() {
  yield takeEvery(CHANGE_ACCESS_COMPANY_ACTION, changeAccessCompany);
}

export function* watchSaveSubAccountLetter() {
  yield takeEvery(SAVE_SUBACCOUNT_LETTER_ACTION, saveSubAccountLetter);
}

export function* saveSubAccountLetter(action) {
  try {
    const formData = new FormData();
    let id;
    const data = { ...action.data };
    data.fileImage = action.data.fileImage[0];

    formData.append('files[]', action.data.fileImage[0]);

    const identificator = yield addLetterInformation(data);

    if (isArray(identificator) && !isEmpty(identificator)) {
      id = identificator[0];

      const response = yield call(
        client().post,
        `/subaccounts/${data.subAccountId}/gcp-gln-confirmation-mails/${id}/upload`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        },
      );

      if (response.error) {
        yield handleErrorNotification(response.error, i18n.t('Ошибка'));
        if (action.cbk) {
          action.cbk(response.error);
        }
      } else {
        if (action.cbk) {
          action.cbk(null);
        } else {
          yield put(saveSubAccountLetterSucceeded(response));
          yield put(throwNotification(i18n.t('Изменения сохранены'), 'info'));
        }
      }
    }
  } catch (e) {
    yield put(throwNotification(i18n.t('Ошибка загрузки'), 'error'));
  }
}

export function* grantKmOrderAction(action) {
  const errT = i18n.t('Ошибка при предастовлении прав!');
  const errT304 = i18n.t('У субаккаунта уже есть такие права');
  try {
    const response = yield call(
      client().post,
      `/subaccounts/${action.data}/bind`,
      { CanOrderMarkedCode: true },
    );
    if (response.error && response.error.response.status === 304) {
      yield handleErrorNotification(response.error, errT304);
      action.cbk({ error: errT304 });
    } else if (response.error) {
      yield handleErrorNotification(response.error);
      action.cbk({ error: errT });
    } else {
      action.cbk(null, i18n.t('Права для КМ успешно предоставлены'));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* revokeKmOrderAction(action) {
  const errT = i18n.t('Ошибка при отмене прав!');
  try {
    const response = yield call(
      client().post,
      `/subaccounts/${action.data}/bind`,
      { CanOrderMarkedCode: false },
    );
    if (response.error) {
      yield handleErrorNotification(response.error, errT);
      action.cbk({ error: errT });
    } else {
      action.cbk(null, i18n.t('Права для КМ успешно отозваны'));
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
    action.cbk({ error: errT });
  }
}

export function* addLetterInformation(action) {
  const errT = i18n.t('Ошибка при добавлении информации о письме');
  const { subAccountId, GLN, GCP } = action;
  try {
    const response = yield call(
      client().post,
      `/subaccounts/${subAccountId}/gcp-gln-confirmation-mails`,
      { GCP, GLN },
    );
    return response;
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
  }
}

export function* getGcpGlnLetters(action) {
  const errT = i18n.t('Произошла ошибка при попытке получения писем');
  try {
    const response = yield call(
      client().get,
      `/subaccounts/${action.data}/gcp-gln-confirmation-mails`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error, errT);
    } else {
      action.cbk(response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
  }
}

export function* deleteGcpGlnLetter(action) {
  const errT = i18n.t('Произошла ошибка при попытке удаления письма');
  const { data } = action;

  try {
    const response = yield call(
      client().delete,
      `/subaccounts/${data.SubAccountId}/gcp-gln-confirmation-mails/${data.SubAccountCodeConfirmationMailId}`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error, errT);
    } else {
      action.cbk(response);
    }
  } catch (e) {
    yield put(throwNotification(errT, 'error'));
  }
}

export function* watchGrantKmOrderAction() {
  yield takeEvery(GRANT_KM_ORDER_ACTION, grantKmOrderAction);
}

export function* watchRevokeKmOrderAction() {
  yield takeEvery(REVOKE_KM_ORDER_ACTION, revokeKmOrderAction);
}

export function* watchAddLetterInformation() {
  yield takeEvery(ADD_LETTER_INFORMATION, addLetterInformation);
}
export function* watchGetGcpGlnLetters() {
  yield takeEvery(GET_GCP_GLN_LETTERS, getGcpGlnLetters);
}

export function* watchDeleteGcpGlnLetter() {
  yield takeEvery(DELETE_GCP_GLN_LETTER, deleteGcpGlnLetter);
}
export function* watchUpdateSubAccount() {
  yield takeEvery(UPDATE_SUBACCOUNT_ACTION, updateSubAccount);
}
export function* watchSendSubAccountGCP() {
  yield takeEvery(SEND_SUBACCOUNT_GCP, sendSubAccountGCP);
}
export default function* subAccountSaga() {
  yield all([
    watchSubAccount(),
    watchSubAccountDelete(),
    watchValidateInnSubAccount(),
    watchSendSubAccount(),
    watchChangeAccessCompany(),
    watchSaveSubAccountLetter(),
    watchGrantKmOrderAction(),
    watchRevokeKmOrderAction(),
    watchAddLetterInformation(),
    watchGetGcpGlnLetters(),
    watchDeleteGcpGlnLetter(),
    watchUpdateSubAccount(),
    watchSendSubAccountGCP(),
  ]);
}
