import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { StatusBlock } from '../../../../../common/styles/Common.styled';
import {
  TableIconInValid,
  TableIconValid,
} from '../../../../../common/icons/customIcons';

export default class StatusCell extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.accessAllowed, nextProps.accessAllowed);
  }

  render() {
    const { accessAllowed, t } = this.props;
    return accessAllowed ? (
      <StatusBlock className="valid">
        <TableIconValid className="icon" />
        {t('Предоставлен')}
      </StatusBlock>
    ) : (
      <StatusBlock className="invalid">
        <TableIconInValid className="icon" />
        {t('Не предоставлен')}
      </StatusBlock>
    );
  }
}

StatusCell.propTypes = {
  t: PropTypes.func.isRequired,
  accessAllowed: PropTypes.bool.isRequired,
};
