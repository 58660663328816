import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const ModDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        maxHeight: '320px',
        minWidth: '420px',
        maxWidth: '420px',
        boxShadow: '0px 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '50px 40px 50px',
        whiteSpace: 'pre-line',
        '&:first-child': {
          paddingTop: '50px',
        },
      },
    },
  },
});
export const DialogTextMod = styled.div`
  font-size: 16px;
  color: #52535a;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  line-height: 30px;
`;
