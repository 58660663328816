import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { forEach, get, isEqual, some } from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
} from '@material-ui/core';
import {
  IconArrowBlue,
  IconClose,
  IconNoImg,
} from '../../../../common/icons/customIcons';
import {
  ColumnCellInner,
  DefaultGridButton,
  DialogTitleContent,
  DialogTitlePhotoStyled,
} from '../../../../common/styles/Common.styled';
import { PhotoDialog } from './PhotoPopover.styled';

class PhotoPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!isEqual(this.state, nextState)];
    const nameProps = ['photo', 'name'];
    forEach(nameProps, (i) =>
      equal.push(!isEqual(this.props[i], nextProps[i])),
    );
    return some(equal);
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const { name, photo, showName, t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const previewUrl = get(photo, 'previewUrl');

    return (
      <ColumnCellInner>
        {!previewUrl ? (
          <div>
            <IconNoImg />
          </div>
        ) : (
          <React.Fragment>
            <img
              alt={name}
              height="auto"
              width="100%"
              src={previewUrl}
              onClick={this.handleClick}
            />
            <MuiThemeProvider theme={PhotoDialog}>
              <Dialog id="simple-popper" open={open} onClose={this.handleClose}>
                <DialogTitle id="alert-dialog-title">
                  {showName ? (
                    <DialogTitleContent>
                      <MuiThemeProvider theme={DefaultGridButton}>
                        <Button
                          className="backChanges"
                          onClick={this.handleClose}>
                          <IconArrowBlue style={{ marginRight: '10px' }} />
                          {t('Список изменений')}
                        </Button>
                      </MuiThemeProvider>
                      <DialogTitlePhotoStyled>{name}</DialogTitlePhotoStyled>
                    </DialogTitleContent>
                  ) : (
                    <Button className="backEmpty" onClick={this.handleClose}>
                      <IconClose />
                    </Button>
                  )}
                </DialogTitle>
                <DialogContent>
                  <img alt={name} height="" width="100%" src={photo.url} />
                </DialogContent>
              </Dialog>
            </MuiThemeProvider>
          </React.Fragment>
        )}
      </ColumnCellInner>
    );
  }
}

PhotoPopover.propTypes = {
  photo: PropTypes.any,
  name: PropTypes.string,
  showName: PropTypes.bool,
};

PhotoPopover.defaultProps = {
  showName: false,
};

export default withTranslation()(PhotoPopover);
