import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Tooltip,
} from '@material-ui/core';
import { IconClose, ModSucceed } from '../../common/icons/customIcons';
import { ModDialogSucceed } from './SuccessedModal.styled';
import { DialogIcon, DialogText } from '../../common/styles/Common.styled';
import { MoreHoriz } from '@material-ui/icons';
class SuccessedModal extends React.Component {
  render() {
    const { t, message, tooltip } = this.props;
    return (
      <MuiThemeProvider theme={ModDialogSucceed}>
        <Dialog open>
          <DialogTitle>
            <Button onClick={this.props.handleClose}>
              <IconClose />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogText>
              <DialogIcon>
                <ModSucceed />
              </DialogIcon>
              <span>{message}</span>
              {!!tooltip && (
                <Tooltip 
                  arrow
                  placement="bottom-end"
                  title={this.props.tooltip}>
                  <MoreHoriz
                    style={{ cursor: 'pointer', height: '15px' }}
                    tabIndex="1"
                  />
                </Tooltip>
              )}
            </DialogText>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

SuccessedModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default SuccessedModal;
