import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import _ from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import {
    MuiThemeProvider,
    FormControl,
    TextField,
    InputLabel,
    Select,
  } from '@material-ui/core';
import {
    IconDropDownAuto, IconError, IconLogoDropDown,
} from '../../../../common/icons/customIcons';
import { defaultSelectTheme, defaultTheme, InputContainer, InputIcon } from '../../../../common/styles/Common.styled';
import { CertSelectAuto } from '../../../../components/certificateSelect/CertificateSelect.styled';
import { CustomInput, SimpleTooltip } from '../../../../components';


class PopupNewTechnicalUnmarkedCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    }

    render() {
        const {
            t,
            data, 
          } = this.props;

        /* Должно быть от 1 до 100 */
        const getCorrectCount = (value) => {
          return value = value.replace(/[^[0-9]/, '')
        };

        const isCorrectInterval = data.count > 100 || data.count < 0

        return (
          <div>
            {/* <MuiThemeProvider theme={CertSelectAuto}>
                <Autocomplete
                    //options={activeKzDictionary}
                    autoHighlight
                    onChange={(_, item) => this.handleChange('CatId', (item ? item.id : ''))}
                    getOptionLabel={(item) => item.name}
                    popupIcon={<IconDropDownAuto />}
                    //disabled={!this.isReadySubmitGroup()}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t('Код государственного классификатора')}
                        variant="standard"
                        fullWidth
                        inputProps={{
                        ...params.inputProps,
                        autoComplete: 'disabled',
                        }}
                    />
                    )}
                    noOptionsText={t('Нет совпадений')}
                />
            </MuiThemeProvider> */}
            <MuiThemeProvider theme={defaultSelectTheme}>
              <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="gtin-select"
                  disabled
                  error={false}>
                  {t('Выберите код государственного классификатора')}
                </InputLabel>
                <Select
                  required
                  name="nestedPackagingCode"
                  //value={getDefaultTo(data, 'nestedPackagingCode')}
                  valid={true}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  inputProps={{ id: 'gtin-select' }}
                  IconComponent={IconLogoDropDown}
                  // onChange={this.handleChange(
                  //   'nestedPackagingCode',
                  //   'transport',
                  //   index,
                  //   selectedOptions,
                  // )}
                  onChange={(_, item) => this.props.handleChange('CatId', (item ? item.id : ''))}
                  disabled={false}>
                  {/* {_.map(selectOptions, (item, index) => (
                    <MenuItem key={`${item}_${index}`} value={item}>
                      {item.padStart(14, '0')}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </MuiThemeProvider>
            <MuiThemeProvider theme={defaultTheme}>
                  <InputContainer>
                    <CustomInput
                      id="technicalCount"
                      name="count"
                      type="text"
                      disabled={!data.CatId}
                      label={t('Укажите необходимое количество технических карточек для этой классификации')}
                      onUpdate={() => {}}
                      onChange={(value) => this.props.handleChange('count', getCorrectCount(value))}
                      value={data.count}
                      error={isCorrectInterval}
                    />
                    {isCorrectInterval ? (
                      <InputIcon style={{marginRight: '20px', position: 'absolute', top: '5px'}}>
                        {SimpleTooltip(t('Максимальное количество 100 '),<IconError />)}
                      </InputIcon>
                    ) : null}
                  </InputContainer>
                </MuiThemeProvider>
          </div>
        )
    }
}

PopupNewTechnicalUnmarkedCard.propTypes = {
  
};
  
  export default compose(withTranslation())(PopupNewTechnicalUnmarkedCard);