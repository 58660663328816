import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { client } from '../../../../api/Client';
import i18n from '../../../../i18n';
import {
  LOAD_GS1_DATA_ACTION,
  LOAD_GS1_INFO_ACTION,
  SAVE_GS1_ACTION,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION,
  LOAD_GS1_OPF_DICTIONARY_ACTION,
} from './GS1ActionTypes.constants';
import {
  loadGS1DataFailed,
  loadGS1DataSucceded,
  loadGS1InfoFailed,
  loadGS1InfoSucceded,
  saveGS1Failed,
  saveGS1Succeded,
  loadActivityTypesSucceeded,
  loadActivityTypesFailed,
  loadCompanyTypesSucceeded,
  loadCompanyTypesFailed,
  loadMainProductTypesSucceeded,
  loadMainProductTypesFailed,
  loadGS1OpfDictionaryFailed,
  loadGS1OpfDictionarySucceeded,
} from './GS1.actions';
import { throwNotification } from '../../../../common/structure';
import { handleErrorNotification } from '../../../ducks/HandleErrors';

/**
 * TODO:
 * отрефакторить и прибраться.
 */

export function* loadGS1Info() {
  try {
    const response = yield call(client().get, '/gs1/gcp-gln');
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGS1InfoFailed());
    } else {
      if (!!response.gcps) {
        const emptyGln = {
          gln: '',
        };
        _.forEach(response.gcps, (gcp) => {
          gcp.glns.unshift(emptyGln);
        });
      }
      yield put(
        loadGS1InfoSucceded({
          gcps: response,
        }),
      );
    }
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка получения данных GS1'), 'error'),
    );
    yield put(loadGS1InfoFailed());
  }
}

export function* loadGS1Data() {
  try {
    const response = yield call(client().get, '/gs1/form');
    if (_.isString(response)) {
      yield put(push('/500'));
      return;
    }
    if (response.error) {
      if (_.get(response, 'error.response.status') === 500) {
        yield put(push('/500'));
      } else {
        yield handleErrorNotification(response.error);
        yield put(loadGS1DataFailed());
      }
    } else {
      _.forEach(response.contacts, (i) => {
        if (!_.has(i, 'id')) {
          _.set(i, 'id', _.uniqueId('contacts_uniqueId_'));
        }
      });
      yield put(loadGS1DataSucceded(response));
    }
  } catch (e) {
    yield put(
      throwNotification(i18n.t('Ошибка получения данных GS1'), 'error'),
    );
    yield put(loadGS1DataFailed());
  }
}

// export function* saveGS1Applicant(action) {
//     const errT = i18n.t('Ошибка при сохранении вкладки заявителя!');

//     try {
//         const request = _.assign({}, action.data, {applicant: action.applicant});
//         const response = yield call(client().post, '/gs1/form', request);
//         if (response.error) {
//             yield handleErrorNotification(response.error, errT);
//             action.callback(response.error);
//             yield put(saveGS1ApplicantFailed());
//         } else {
//             action.callback();
//             yield put(saveGS1ApplicantSucceded(request.applicant));
//         }
//     } catch (e) {
//         action.callback(e);
//         yield put(throwNotification(errT, 'error'));
//         yield put(saveGS1ApplicantFailed());
//     }
// }

// export function* saveGS1BankingDetails(action) {
//     const errT = i18n.t('Ошибка при сохранении вкладки банковских реквизитов!');
//     try {
//         const request = _.assign({}, action.data, {bankDetails: action.bankDetails});
//         const response = yield call(client().post, '/gs1/form', request);
//         if (response.error) {
//             yield handleErrorNotification(response.error, errT);
//             action.callback(response.error);
//             yield put(saveGS1BankingDetailsFailed());
//         } else {
//             action.callback();
//             yield put(saveGS1BankingDetailsSucceded(request.bankDetails));
//         }
//     } catch (e) {
//         yield put(throwNotification(errT, 'error'));
//         yield put(saveGS1BankingDetailsFailed());
//     }
// }

// export function* saveGS1Addresses(action) {
//     const errT = i18n.t('Ошибка при сохранении вкладки адресов!');

//     try {
//         const request = _.assign({}, action.data, {addresses: action.addresses});
//         const response = yield call(client().post, '/gs1/form', request);
//         if (response.error) {
//             yield handleErrorNotification(response.error, errT);
//             action.callback(response.error);
//             yield put(saveGS1AddressesFailed());
//         } else {
//             action.callback();
//             yield put(saveGS1AddressesSucceded(request.addresses, action.originAddresses));
//         }
//     } catch (e) {
//         yield put(throwNotification(errT, 'error'));
//         yield put(saveGS1AddressesFailed());
//     }

// }

// export function* saveGS1CompanyInformation(action) {
//     const errT = i18n.t('Ошибка при сохранении вкладки данных о предприятии!');

//     try {
//         const request = _.assign({}, action.data, {companyDetails: action.companyDetails});
//         const response = yield call(client().post, '/gs1/form', request);
//         if (response.error) {
//             yield handleErrorNotification(response.error, errT);
//             action.callback(response.error);
//             yield put(saveGS1CompanyInformationFailed());
//         } else {
//             action.callback();
//             yield put(saveGS1CompanyInformationSucceded(request.companyDetails));
//         }
//     } catch (e) {
//         yield put(throwNotification(errT, 'error'));
//         yield put(saveGS1CompanyInformationFailed());
//     }
// }

// export function* saveGS1Contacts(action) {
//     const errT = i18n.t('Ошибка при сохранении вкладки контактов!');

//     try {
//         const request = _.assign({}, action.data, {contacts: action.contacts});
//         let response = yield call(client().post, '/gs1/form', request);
//         if (response.error) {
//             yield handleErrorNotification(response.error, errT);
//             action.callback(response.error);
//             yield put(saveGS1ContactsFailed());
//         } else {
//             action.callback();
//             yield put(saveGS1ContactsSucceded(request.contacts));
//         }
//     } catch (e) {
//         yield put(throwNotification(errT, 'error'));
//         yield put(saveGS1ContactsFailed());
//     }
// }

export function* saveGS1(action) {
  const errT = i18n.t('Ошибка при сохранении GS1!');
  try {
    const savedData = yield call(client().post, `gs1/form`, action.data);
    if (savedData.error) {
      yield handleErrorNotification(savedData.error);
      yield put(saveGS1Failed());
      action.callback(savedData.error);
      return;
    }

    const xml = yield call(client().get, `gs1/sign-form`);
    if (xml.error) {
      yield handleErrorNotification(xml.error);
      yield put(saveGS1Failed());
      return;
    }

    const postXml = yield call(client().post, `/gs1/sign-form`, {
      signedXML: xml.XMLForSign,
    });
    if (postXml.error) {
      yield handleErrorNotification(postXml.error);
      yield put(saveGS1Failed());
      action.callback(postXml.error);
      return;
    }
    action.callback(null);
    yield put(saveGS1Succeded());
  } catch (e) {
    yield put(saveGS1Failed());
    yield put(throwNotification(errT, `error`));
    action.callback(e);
  }
  // try {
  //     const message = yield call(client().get, '/gs1/sign-form');
  //     if (message.error) {
  //         yield handleErrorNotification(message.error);
  //         action.callback(message.error);
  //         yield put(saveGS1Failed());
  //         return;
  //     }

  //     const signature = yield call(signXML, action.certificate, message.XMLForSign, true);
  //     const response = yield call(client().post, '/gs1/sign-form', {signedXML: signature});
  //     if (response.error) {
  //         yield handleErrorNotification(response.error);
  //         action.callback(response.error);
  //         yield put(saveGS1Failed());
  //     } else {
  //         action.callback(null);
  //         yield put(saveGS1Succeded(response));
  //     }
  // } catch (e) {
  //     action.callback(e);
  //     yield put(throwNotification(handlerCryptoMessage(e, i18n.t('Ошибка при сохранении GS1!')), 'error'));
  //     yield put(saveGS1Failed());
  // }
}

export function* loadActivityTypes() {
  const errT = i18n.t('Ошибка загрузки справочника "Вид деятельности"');
  try {
    const response = yield call(
      client().get,
      `/dictionaries/gs1/activity-type`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadActivityTypesFailed());
    } else {
      if (!_.isArray(response)) {
        yield put(throwNotification(errT, `error`));
        yield put(loadActivityTypesFailed());
      }
      yield put(
        loadActivityTypesSucceeded(_.isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadActivityTypesFailed());
  }
}

export function* loadCompanyTypes() {
  const errT = i18n.t('Ошибка загрузки справочника "Тип предприятия"');
  try {
    const response = yield call(client().get, `/dictionaries/gs1/company-type`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadCompanyTypesFailed());
    } else {
      if (!_.isArray(response)) {
        yield put(throwNotification(errT, `error`));
        yield put(loadCompanyTypesFailed());
      }
      yield put(loadCompanyTypesSucceeded(_.isArray(response) ? response : []));
    }
  } catch (e) {
    yield put(loadCompanyTypesFailed());
  }
}
export function* loadMainProductTypes() {
  const errT = i18n.t('Ошибка загрузки справочника "Основной вид продукции"');
  try {
    const response = yield call(
      client().get,
      `/dictionaries/gs1/main-product-type`,
    );
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadMainProductTypesFailed());
    } else {
      if (!_.isArray(response)) {
        yield put(throwNotification(errT, `error`));
        yield put(loadMainProductTypesFailed());
      }
      yield put(
        loadMainProductTypesSucceeded(_.isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadMainProductTypesFailed());
  }
}

export function* loadGS1OpfDictionary() {
  const errT = i18n.t(
    'Ошибка загрузки справочника "Организационно-правовые формы"',
  );

  try {
    const response = yield call(client().get, `/dictionaries/gs1/opf-alt`);
    if (response.error) {
      yield handleErrorNotification(response.error);
      yield put(loadGS1OpfDictionaryFailed());
    } else {
      if (!_.isArray(response)) {
        yield put(throwNotification(errT, `error`));
        yield put(loadGS1OpfDictionaryFailed());
      }
      yield put(
        loadGS1OpfDictionarySucceeded(_.isArray(response) ? response : []),
      );
    }
  } catch (e) {
    yield put(loadGS1OpfDictionaryFailed());
  }
}

export function* watchSaveGS1() {
  yield takeEvery(SAVE_GS1_ACTION, saveGS1);
}

export function* watchGS1Info() {
  yield takeEvery(LOAD_GS1_INFO_ACTION, loadGS1Info);
}

export function* watchGS1Data() {
  yield takeEvery(LOAD_GS1_DATA_ACTION, loadGS1Data);
}

// export function* watchSaveGS1Applicant() {
//     yield takeEvery(SAVE_GS1_APPLICANT_ACTION, saveGS1Applicant);
// }

// export function* watchSaveGS1BankingDetails() {
//     yield takeEvery(SAVE_GS1_BANKINGDETAILS_ACTION, saveGS1BankingDetails);
// }

// export function* watchSaveGS1Addresses() {
//     yield takeEvery(SAVE_GS1_ADDRESSES_ACTION, saveGS1Addresses);
// }

// export function* watchSaveGS1CompanyInformation() {
//     yield takeEvery(SAVE_GS1_COMPANYINFORMATION_ACTION, saveGS1CompanyInformation);
// }

// export function* watchSaveGS1Contacts() {
//     yield takeEvery(SAVE_GS1_CONTACTS_ACTION, saveGS1Contacts);
// }

export function* watchLoadActivityTypes() {
  yield takeEvery(LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION, loadActivityTypes);
}

export function* watchLoadCompanyTypes() {
  yield takeEvery(LOAD_COMPANY_TYPES_DICTIONARY_ACTION, loadCompanyTypes);
}

export function* watchLoadMainProductTypes() {
  yield takeEvery(
    LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION,
    loadMainProductTypes,
  );
}

export function* watchLoadGS1OpfDictionary() {
  yield takeEvery(LOAD_GS1_OPF_DICTIONARY_ACTION, loadGS1OpfDictionary);
}

export default function* gs1Saga() {
  yield all([
    watchGS1Info(),
    watchGS1Data(),
    // watchSaveGS1Applicant(),
    // watchSaveGS1BankingDetails(),
    // watchSaveGS1Addresses(),
    // watchSaveGS1CompanyInformation(),
    // watchSaveGS1Contacts(),
    watchSaveGS1(),
    watchLoadActivityTypes(),
    watchLoadCompanyTypes(),
    watchLoadMainProductTypes(),
    watchLoadGS1OpfDictionary(),
  ]);
}
