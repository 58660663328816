import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push } from 'connected-react-router';
import memoize from 'memoize-state';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import qs from 'qs';
import { Preloader } from '../../../../components';
import PopupNewProduct from '../PopupNewProduct';
import ProductEdit from '../ProductEdit';
import {
  clearProductData,
  cleatModerateErrors,
  loadCardItem,
  loadCatalogCardItem,
  loadModerateErrors,
} from '../../ducks/Product.actions';
import {
  deleteNotification,
  throwNotification,
} from '../../../../common/structure';
import { config, isELK } from '../../../../config';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: _.toNumber(this.props.match.params.productId),
      modalOpen: !_.toNumber(this.props.match.params.productId),
      preview: !!this.props.match.params.dto,
      moderateErrors: this.props.moderateErrors,
      pathname: this.props.history.location.pathname,
      status: '',
    };
    this.isExit = false;
  }

  componentDidMount() {
    const { match, productData, onLoadCardItem, onLoadCatalogCardItem } = this.props;
    const dto = _.defaultTo(match.params.dto, 'draft');
    if (_.isEmpty(productData) && this.state.id && !this.state.pathname.startsWith('/catalog')) {
      onLoadCardItem(this.state.id, dto);
    } else if (_.isEmpty(productData) && this.state.id && this.state.pathname.startsWith('/catalog')) {
      onLoadCatalogCardItem(this.state.id);
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const preview = !!nextProps.match.params.dto;
    const pathname = isELK
      ? window.location.pathname
      : nextProps.history.location.pathname;
    const id = _.toNumber(nextProps.match.params.productId);
    let state = {};

    if (
      !_.includes([prevState.pathname, '/login'], pathname) ||
      (!id && !prevState.id && _.isEmpty(nextProps.productData))
    ) {
      state = _.assign({}, state, {
        pathname: pathname,
        id: NaN,
        modalOpen: pathname === config.urls.productNew,
        preview: false,
        moderateErrors: [],
      });
    }

    if (id) {
      if (prevState.preview !== preview) {
        state = _.assign({}, state, { preview: preview, id: id });
      }
      if (prevState.id !== id) {
        state = _.assign({}, state, { id: id });
      }
    }

    if (
      _.isEmpty(prevState.moderateErrors) &&
      !_.isEmpty(nextProps.moderateErrors)
    ) {
      state = _.assign({}, state, { moderateErrors: nextProps.moderateErrors });
    }

    return !_.isEmpty(state) ? { ...state } : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isExit) return;
    const { t } = this.props;
    const filled =
      _.isEmpty(prevProps.productData) && !_.isEmpty(this.props.productData);
    if (filled && this.state.id) {
      if (
        this.props.productData.status === 'require_changes' &&
        !this.state.preview
      ) {
        this.setState({ modalOpen: true, status: 'require_changes' });
      }
      if (this.props.productData.status === 'errors') {
        // this.props.onLoadModerateErrors(this.state.id);
        this.props.onLoadModerateErrors(this.props.productData.draftId);
      }
    } else if (filled) {
      this.setState({ modalOpen: false });
    }

    if (
      _.isEmpty(prevProps.moderateErrors) &&
      !_.isEmpty(this.props.moderateErrors)
    ) {
      this.props.throwNotification(
        `${t('В заполненной карточке товара имеются ошибки')}.
                    ${t('Поля с ошибками подсвечены и имеют подсказки')}.
                    ${t(
          'Исправьте все неправильно заполненные поля и отправьте повторно карточку товара на модерацию',
        )}.`,
        `error`,
      );
    }

    if (!!this.state.id && prevState.id !== this.state.id) {
      this.setState({ modalOpen: false });
      const { match, onLoadCardItem } = this.props;
      const dto = _.defaultTo(match.params.dto, 'draft');

      // TODO если бэк будет тут возвращаеть неправильный entityType то будут двойные запрос
      const id =
        _.get(this.props, 'productData.entityType') === 'goods'
          ? _.get(this.props, 'productData.goodId')
          : _.get(this.props, 'productData.draftId');

      if (id !== this.state.id) {
        onLoadCardItem(this.state.id, dto);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearModerateErrors();
    if (
      (!this.props.productData.status ||
        this.props.productData.status === 'errors') &&
      this.props.CatId
    ) {
      this.props.deleteNotification();
    }

    if (!_.isEmpty(this.props.productData)) {
      this.props.clearProductData();
    }
  }

  handleClose = (onlyClose) => {
    if (this.state.modalOpen && !onlyClose) {
      this.props.clearModerateErrors();
    }
    this.setState({ modalOpen: !this.state.modalOpen, status: '' });

    if (onlyClose) {
      if (
        _.isEmpty(this.props.productData) ||
        this.props.productData.status === 'require_changes'
      ) {
        this.isExit = true;
      }
      if (this.state.pathname.startsWith('/catalog')) {
        this.props.goToPage(config.urls.catalog); //===================
      } else this.props.goToPage(config.urls.products);

    }
  };

  loadDraftFromRequiredChanges = (draftId) => {
    const dto = _.defaultTo(this.props.match.params.dto, 'draft');
    this.props.onLoadCardItem(draftId, dto);
  };

  getModalType = () => {
    const modalTypeParse = qs.parse(_.get(this.props, 'location.search'), {
      ignoreQueryPrefix: true,
    });
    const modalType = _.get(modalTypeParse, 'type', 'unit');
    const allowedTypesModal = ['unit', 'group', 'transport'];
    if (this.props.isElkUser) {
      if (this.props.isTechGtinEnabled) allowedTypesModal.push('technical');
      if (this.props.isTechUnmarkedGtinEnabled) allowedTypesModal.push('technicalUnmarked');
      if (this.props.isKitEnabled) allowedTypesModal.push('kit');
    }
    return _.includes(allowedTypesModal, modalType) ? modalType : 'unit';
  };

  renderPopupNewProduct = () => {
    if (!this.state.modalOpen) return null;

    return (
      <PopupNewProduct
        handleClose={this.handleClose}
        productId={_.toNumber(this.state.id)}
        status={this.state.status}
        loadDraftFromRequiredChanges={this.loadDraftFromRequiredChanges}
        modalType={this.getModalType()}
      />
    );
  };

  renderPreloader = () => {
    if (
      this.props.loadingIsData ||
      (_.isEmpty(this.props.productData) && this.props.loading)
    ) {
      return <Preloader isOpen />;
    }

    return null;
  };

  renderProductEdit = () => {
    if (
      !this.props.loadingIsData &&
      !_.isEmpty(this.props.productData) &&
      !this.state.modalOpen
    ) {
      return <ProductEdit preview={this.state.preview} isCatalogCard={this.state.pathname.startsWith('/catalog')} />;
    }

    return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.renderPopupNewProduct()}
        {this.renderPreloader()}
        {this.renderProductEdit()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
  loading: !!state.productReducer.loading || !!state.catalogReducer.loading,
  loadingIsData: !!state.productReducer.loadingIsData,
  //isElkUser: !!state.authentificationReducer.user.isElkUser,
  isElkUser: true,
  isKitEnabled: !!state.authentificationReducer.user.isKitEnabled,
  //isTechGtinEnabled: !!state.authentificationReducer.user.isTechGtinEnabled,
  isTechGtinEnabled: true,
  isTechUnmarkedGtinEnabled: true,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadModerateErrors: (id) => loadModerateErrors(id),
      clearModerateErrors: () => cleatModerateErrors(),
      clearProductData: () => clearProductData(),
      onLoadCardItem: (id, dtoName) => loadCardItem(id, dtoName),
      onLoadCatalogCardItem: (id) => loadCatalogCardItem(id),
      throwNotification: (message, type) => throwNotification(message, type),
      deleteNotification: () => deleteNotification(),
      goToPage: (page) => push(page),
    },
    dispatch,
  );
};

Product.propTypes = {
  goToPage: PropTypes.func.isRequired,
  onLoadCardItem: PropTypes.func.isRequired,
  onLoadCatalogCardItem: PropTypes.func,
  onLoadModerateErrors: PropTypes.func.isRequired,
  throwNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  clearProductData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  moderateErrors: PropTypes.array.isRequired,
  isElkUser: PropTypes.bool.isRequired,
  isKitEnabled: PropTypes.bool.isRequired,
  isTechGtinEnabled: PropTypes.bool.isRequired,
  productData: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
      dto: PropTypes.any,
    }),
  }),
};

export default compose(
  withTranslation(),
  connect(memoize(mapStateToProps), mapDispatchToProps),
)(Product);
