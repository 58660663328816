import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';
import styled from 'styled-components';

export const CertSelectDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paper: {
        minWidth: '640px',
        maxWidth: '640px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, .15)',
        borderRadius: '4px',
        padding: '0',
      },
      paperWidthSm: {
        maxWidth: '640px',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
      },
      text: {
        padding: '3px 15px',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        marginRight: '20px',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
        minWidth: '200px',
        textTransform: 'none',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#52535A',
        },
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
      },
      textSecondary: {
        color: '#52535A',
        background: '#FFF',
        border: '1px solid #868686',
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '200px',
        height: '50px',
        fontWeight: '600',
        '&:hover': {
          color: '#000',
          background: '#FFF!important',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        fontSize: '18px;',
        color: '#63666A',
        overflow: 'hidden',
        minHeight: '60px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '40px 40px',
        margin: '0',
        justifyContent: 'space-between',
      },
    },
  },
});

export const DialogQuestion = styled.div`
  padding: 0 40px 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid #f7f7f7;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #63666a;
  a {
    font-size: 16px;
    color: #4aa5e8;
  }
`;
export const DialogSelect = styled.div`
  padding: 0 40px;
`;
export const DialogSelectAddLetter = styled.div`
  width: 100%;
  padding: 0;
`;

export const DialogRadioGroup = styled.div`
  padding: 20px 40px 0;
`;
export const DialogBtnsHolder = styled.div`
  margin-top: 20px;
  button {
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
`;
export const DialogWarning = styled.div`
  color: #63666a;
  font-size: 10px;
  font-weight: 400;
`;

export const DialogBtnHolder = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
        transition: 'all .4s ease',
      },
      text: {
        padding: '3px 35px',
        fontSize: '12px',
        fontWeight: '600',
      },
      textPrimary: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        color: `${colors.Buttons.buttonPrimary.color}`,
        minWidth: 'auto',
        textTransform: 'none',
        height: 'auto',
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
          boxShadow: `0px 10px 10px -4px ${colors.Buttons.buttonPrimary.boxShadow}`,
          border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
        },
        '&.active': {
          backgroundColor: '#B1B2B4 ',
          borderColor: '#B1B2B4',
          '&:hover': {
            boxShadow: '0px 10px 10px -4px rgba(177, 178, 180, .4)',
          },
        },
      },
      textSecondary: {
        border: '1px solid #868686',
        textTransform: 'none',
        minWidth: 'auto',
        height: 'auto',
        color: '#63666A',
        opacity: '.5',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#63666A',
          border: '1px solid #868686',
          opacity: '1',
        },
        '&.active': {
          backgroundColor: '#B1B2B4 ',
          borderColor: '#B1B2B4',
          opacity: '1',
          color: '#fff',
          '&:hover': {
            boxShadow: '0px 10px 10px -4px rgba(177, 178, 180, .4)',
          },
        },
      },
    },
  },
});
