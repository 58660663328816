import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import { CustomInput, Preloader } from '../../../../components';
import { throwNotification } from '../../../../common/structure';
import {
  Button,
  FormControl,
  MuiThemeProvider,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  IconLogoDropDown,
  IconEye,
  IconUpload,
} from '../../../../common/icons/customIcons';
import { defaultTheme } from './SiteProfile.styled';
import {
  loadSiteProfile,
  saveSiteProfile,
  loadMarketFields,
} from '../../ducks/SiteProfile/SiteProfile.actions';
import {
  PageWrapper,
  ColumnsWrapper,
  ColumnItem,
  InputRowItem,
  InputLabelItem,
  PredicateWrapper,
  SaveButtonWrapper,
} from './SiteProfile.styled';
import './SiteProfile.css';

const allowedFileTypes = ['image/jpg', 'image/jpeg'];

function fileIsIncorrectFiletype(file) {
  return file.size < 1 || allowedFileTypes.indexOf(file.type) === -1;
}

class SiteProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      edited: false,
      errorDescription: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !Object.keys(prevState.formData).length &&
      !_.isEqual(nextProps.formData, prevState.formData)
    ) {
      return { formData: nextProps.formData };
    }

    return null;
  }

  componentDidMount() {
    this.props.loadMarketFields();
    this.props.loadSiteProfile();
  }

  handleChange = (name, value = null) => {
    if (name === 'isVisible') {
      return this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          isVisible: prevState.formData.isVisible
            ? !prevState.formData.isVisible
            : true,
        },
        edited: true,
      }));
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      edited: true,
    }));
  };

  handleChangeImg = (name, event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const throwError = _.flow([this.props.t, this.props.throwError]);

    /**
     * ????
     */
    if (fileIsIncorrectFiletype(file)) {
      throwError('Неподдерживаемый формат изображения');
      return;
    }

    /**
     * ?????
     */
    if (file.size / 1024 / 1024 > 15) {
      throwError('Файл не может превышать размер в 15 мб');
      return;
    }

    let data = { fileImage: [] };
    data.fileImage.push(file);

    if (file) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: {
            fileName: file.name,
            preview: URL.createObjectURL(file),
            data,
          },
        },
        edited: true,
      }));
    }
  };

  onSave = (e) => {
    e.preventDefault();
    const {
      isVisible,
      deleteLogo,
      deleteMicroLogo,
      seoName,
      ...data
    } = _.cloneDeep(this.state.formData);

    if (data.description.length > 1400) {
      return;
    }

    const { t, throwMessage } = this.props;
    const logo =
      typeof data.logo === 'string' ? null : data.logo?.data?.fileImage;
    const microLogo =
      typeof data.microLogo === 'string'
        ? null
        : data.microLogo?.data?.fileImage;

    const finalObject = { ...data };
    finalObject.logo = logo;
    finalObject.microLogo = microLogo;

    const result = this.props.saveSiteProfile(finalObject);
    this.setState({ edited: false, errorDescription: null },
          () => throwMessage(t('Информация о компании для публичного сайта успешно обновлена')));
    return result;
  };

  handleChangeUrl = (value) => this.handleChange('url', value);

  handleChangeVisible = (value) => this.handleChange('isVisible', value);

  handleChangeMicroLogo = (event) => this.handleChangeImg('microLogo', event);

  handleChangeLogo = (event) => this.handleChangeImg('logo', event);

  handleChangeMarketField = (event) =>
    this.handleChange('marketField', event.target.value);

  handleChangeSeoName = (value) => this.handleChange('seoName', value);

  handleChangeDescription = (value) => {
    this.handleChange('description', value);
    if (!value || value.length < 1400) {
      this.setState({
        errorDescription: null,
      });
    } else {
      this.setState({
        errorDescription: 'Максимальное количество символов 1400!',
      });
    }
  };

  handleOpen = (link) => {
    return () => {
      if (link) {
        window.open(link, '_blank');
      }
    };
  };

  handleOpenCatalog = () => {
    const { formData } = this.props
    window.open(formData.redirectUrl, '_blank');
  };

  render() {
    const { t, marketFields, loading } = this.props;
    const { edited, formData, errorDescription } = this.state;
    const title =
      t(
        `Раздел предназначен для подачи информации о компании для публичного сайта`,
      ) +
      `. ` +
      t(
        `Информация о компании начнёт отображаться на сайте с момента публикации первого товара`,
      ) +
      `.`;

    // const microLogo =
    //   typeof formData?.microLogo === 'string'
    //     ? formData?.microLogo
    //     : formData?.microLogo?.fileName;
    // const microLogoPreview =
    //   typeof formData?.microLogo === 'string'
    //     ? formData?.microLogo
    //     : formData?.microLogo?.preview;
    const logo =
      typeof formData?.logo === 'string'
        ? formData?.logo
        : formData?.logo?.fileName;
    const logoPreview =
      typeof formData?.logo === 'string'
        ? formData?.logo
        : formData?.logo?.preview;
    const error = Boolean(errorDescription);

    return (
      <>
        {loading && <Preloader isOpen />}
        <PageWrapper>
          <div style={{ fontWeight: '600' }}>{title}</div>
          <form onSubmit={this.onSave}>
            <ColumnsWrapper>
              {/**
               * первая колонка
               */}
              <ColumnItem>
                <InputRowItem>
                  <InputLabelItem>{t(`Cсылка на сайт`)}</InputLabelItem>

                  <MuiThemeProvider theme={defaultTheme}>
                    <CustomInput
                      className="SiteProfile-input"
                      name="url"
                      cleanButtonStyles={{ marginTop: -16 }}
                      value={_.defaultTo(this.state.formData.url, '')}
                      onChange={this.handleChangeUrl}
                    />
                  </MuiThemeProvider>
                </InputRowItem>
                {/* <CheckBoxItem>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name='isVisible'
                                                checked={_.defaultTo(this.state.formData.isVisible, false)}
                                                onChange={this.handleChangeVisible}
                                                checkedIcon={<IconCheck />}
                                            />
                                        }
                                        label={t(`Отображать на сайте`)}
                                    />
                                </CheckBoxItem> */}
                {/*<InputRowItem>
                  <InputLabelItem>{t(`Метка для карты`)}</InputLabelItem>
                  <MuiThemeProvider theme={defaultTheme}>
                    <CustomInput
                      name="microLogo"
                      value={_.defaultTo(microLogo, '')}
                      // onChange={(value) => this.handleChange('microLogo', value)}
                      placeholder={t('PIN 12x12')}
                      disabled
                    />
                    <Button
                      onClick={this.handleOpen(microLogoPreview)}
                      component="label"
                      disabled={false}>
                      <IconEye />
                    </Button>
                    <Button component="label" disabled={false}>
                      <IconUpload />
                      {/* {t('Выбрать файл')} */}
                      {/*<input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={this.handleChangeMicroLogo}
                      />
                    </Button>
                  </MuiThemeProvider>
                      </InputRowItem>*/}
                <InputRowItem>
                  <InputLabelItem>{t(`Логотип`)}</InputLabelItem>
                  <MuiThemeProvider theme={defaultTheme}>
                    <CustomInput
                      name="logo"
                      value={_.defaultTo(logo, '')}
                      // onChange={(value) => this.handleChange('lago', value)}
                      placeholder={t('Логотип компании')}
                      disabled
                    />
                    <Button
                      onClick={this.handleOpen(logoPreview)}
                      component="label"
                      disabled={false}>
                      <IconEye />
                    </Button>
                    <Button component="label" disabled={false}>
                      <IconUpload />
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={this.handleChangeLogo}
                      />
                    </Button>
                  </MuiThemeProvider>
                </InputRowItem>
                <InputRowItem>
                  <InputLabelItem>{t(`Категория`)}</InputLabelItem>
                  <FormControl style={{ width: '400px' }}>
                    <MuiThemeProvider theme={defaultTheme}>
                      <Select
                        value={_.defaultTo(this.state.formData.marketField, '')}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                        IconComponent={IconLogoDropDown}
                        onChange={this.handleChangeMarketField}>
                        {_.map(marketFields, (i) => (
                          <MenuItem key={i.id} value={i.id}>
                            {t(i.text)}
                          </MenuItem>
                        ))}
                      </Select>
                    </MuiThemeProvider>
                  </FormControl>
                </InputRowItem>
                <InputRowItem>
                  <InputLabelItem>{t(`Просмотр на сайте`)}</InputLabelItem>
                  <MuiThemeProvider theme={defaultTheme}>
                    <PredicateWrapper>/merchant/</PredicateWrapper>
                    <CustomInput
                      disabled
                      hideCleanButton
                      name="seoName"
                      value={_.defaultTo(this.state.formData.seoName, '')}
                      onChange={this.handleChangeSeoName}
                    />
                    <Button
                      onClick={this.handleOpenCatalog}
                      component="label"
                      disabled={false}>
                      <IconEye />
                    </Button>
                  </MuiThemeProvider>
                </InputRowItem>
              </ColumnItem>
              {/**
               * вторая колонка
               */}
              <ColumnItem>
                <InputRowItem>
                  <InputLabelItem>{t(`Описание`)}</InputLabelItem>
                  <MuiThemeProvider theme={defaultTheme}>
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        className="SiteProfile-textarea"
                        name="description"
                        value={_.defaultTo(this.state.formData.description, '')}
                        onChange={this.handleChangeDescription}
                        multiline
                        rows={4}
                        rowsMax={20}
                        error={error}
                      />
                      {error && (
                        <Typography style={{ marginTop: 5 }} color="error">
                          {errorDescription}
                        </Typography>
                      )}
                    </div>
                  </MuiThemeProvider>
                </InputRowItem>
                <SaveButtonWrapper>
                  <Button type="submit" disabled={!edited}>
                    {t('Сохранить изменения')}
                  </Button>
                </SaveButtonWrapper>
              </ColumnItem>
            </ColumnsWrapper>
          </form>
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: !!state.siteProfileReducer.loading,
  marketFields: state.siteProfileReducer.marketFields,
  formData: state.siteProfileReducer.formData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadMarketFields: () => loadMarketFields(),
      loadSiteProfile: () => loadSiteProfile(),
      saveSiteProfile: (data) => saveSiteProfile(data),
      throwError: (message) => throwNotification(message, 'error'),
      throwMessage: (message) => throwNotification(message, 'success'),
    },
    dispatch,
  );
};

SiteProfile.propTypes = {};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(SiteProfile);
