import 'core-js/stable';
import * as React from 'react';
import { render } from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './stores';
import Routes from './routes';
import { register } from './serviceWorker';
import i18n from './i18n';

const store = configureStore({});

i18n
  .init({
    react: {
      useSuspense: false,
    },
  })
  .then(() =>
    render(
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <Routes />
        </ConnectedRouter>
      </Provider>,
      document.getElementById('root'),
    ),
  );
register();
