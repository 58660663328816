import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../common/styles/Common.colors';

export const PickerIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 43px;
  cursor: pointer;
`;
export const PickerDate = createMuiTheme({
  typography: {},
  overrides: {
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
        '&$focused': {
          border: `1px solid ${colors.Input.border}`,
          color: '#868686',
        },
        '&$error': {
          border: '1px solid #FF4B4B',
        },
      },
      input: {
        padding: '23px 19px 6px',
        lineHeight: '21px',
        '&:required': {
          //borderLeft: "2px solid #FF4B4B"
        },
      },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#868686',
        fontSize: '16px',
        lineHeight: '16px',
        '&$disabled': {
          zIndex: '1',
        },
      },
      formControl: {
        transform: 'translate(19px, 35px) scale(1)',
      },
      shrink: {
        fontSize: '12px',
        transform: 'translate(19px, 20px) scale(1)',
        color: '#868686',
      },
      filled: {
        color: '#868686',
      },
      disabled: {},
    },
    MuiInputBase: {
      root: {},
      input: {
        fontWeight: '600',
        color: '#52535A',
        height: 'auto',
        '&:required': {
          borderRadius: '3px',
          borderLeft: '4px solid #FF4B4B',
          "&[valid='false']": {
            borderLeft: `4px solid #FF4B4B`,
          },
          "&[valid='true']": {
            borderLeft: `4px solid #60E84A`,
          },
        },
        //"&[valid='true']": {
        //borderLeft: `2px solid #60E84A`,
        //},
        //"&[valid='false']": {
        //borderLeft: `2px solid #FF4B4B`,
        //},
        '&$disabled': {
          cursor: 'pointer',
          borderLeft: 'none',
        },
      },
      multiline: {
        padding: '0',
      },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        color: '#868686',
        '&$focused': {
          color: '#868686!important',
        },
      },
      asterisk: {
        display: 'none',
      },
    },
  },
});
export const DefaultPickerPopover = createMuiTheme({
  typography: {},
  overrides: {
    MuiPopover: {
      paper: {
        maxHeight: '500px',
        minWidth: '330px',
        maxWidth: '330px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '0px 0px 3px 3px',
        padding: '25px 15px',
        boxSizing: 'border-box',
        marginLeft: '0',
        marginTop: '0',
      },
    },
    MuiButton: {
      root: {
        background: `${colors.Buttons.buttonPrimary.background}`,
        borderRadius: '3px',
        '&$disabled': {
          color: `${colors.Buttons.buttonPrimary.color}`,
          opacity: '0.5',
        },
        '&:hover': {
          backgroundColor: `${colors.Buttons.buttonPrimary.background}!important`,
          color: `${colors.Buttons.buttonPrimary.color}!important`,
        },
      },
      text: {
        padding: '8px 35px',
        fontWeight: '600',
        textTransform: 'capitalize',
        color: `${colors.Buttons.buttonPrimary.color}`,
        fontSize: '16px',
      },
      disabled: {},
    },
    MuiFormControlLabel: {
      root: {
        minWidth: '120px',
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#52535A!important',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #D9D9D9',
        borderRadius: '3px',
      },
      input: {
        padding: '15px 9px',
        lineHeight: '21px',
        boxSizing: 'border-box',
      },
    },
    MuiInputBase: {
      input: {
        boxSizing: 'border-box',
      },
    },
  },
});
export const PickerBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        minWidth: '25px',
        minHeight: '25px',
        maxHeight: '45px',
        marginLeft: 'auto',
      },
      text: {
        padding: '4px',
      },
    },
  },
});
export const PickerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    color: #52535a;
    font-size: 14px;
    margin: 0;
    line-height: 20px;
  }
  .backEmpty {
    background: none;
  }
`;
export const PickerContent = styled.div`
  display: flex;
  padding: 20px 0;
  position: relative;
`;
export const PickerBtns = styled.div`
  display: flex;
  justify-content: space-between;
  .backEmpty {
    background: none;
    color: #52535a !important;
  }
`;
export const DatePickerBox = styled.div`
  width: 100%;
  .rdrCalendarWrapper {
    width: 100%;
  }
  .rdrMonthAndYearPickers {
    justify-content: flex-start;
  }
  .rdrMonths {
    padding-top: 20px;
  }
  .rdrMonth {
    padding: 0;
    box-sizing: border-box;
    width: 100% !important;
  }
  .rdrWeekDays {
    display: none;
  }
  .rdrMonthPicker,
  .rdrYearPicker {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    min-width: 130px;
    select {
      width: 100%;
    }
  }
  .rdrDay {
    font-size: 14px;
    color: #52535a;
    font-weight: 600 !important;
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrSelected {
      background: ${colors.Common.background};
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border-radius: 4px;
      z-index: 2;
      border-color: ${colors.Common.background};
    }
    .rdrDayStartPreview {
      border-radius: 4px 0 0 4px;
    }
    .rdrDayEndPreview {
      border-radius: 0 4px 4px 0;
    }

    .rdrDayStartPreview.rdrDayEndPreview {
      border-radius: 4px;
    }
    .rdrDayNumber {
      background: #f9f9f9;
      font-weight: 600 !important;
      height: 100%;
      left: 0;
      width: 100%;
      top: 0;
      border: 1px solid #fff;
    }

    .rdrDayNumber span {
      color: #52535a !important;
      z-index: 3;
    }
  }
  .rdrDayPassive .rdrDayNumber span {
    color: #d9d9d9 !important;
  }

  .rdrDayHovered .rdrDayNumber {
    span: after {
      display: none !important;
      background: #52535a !important;
    }
  }
  .rdrDayHovered .rdrDayNumber:after {
    display: none !important;
  }
`;
