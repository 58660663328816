import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { ColumnCellInner } from '../../../../common/styles/Common.styled';
import { ColumnCell } from '../ProductsGrid.styled';
import { Tooltip } from '@material-ui/core';
import { clippedString } from '../../../../common/utils/utils';

const styleDiv = {
  width: 'auto',
  maxWidth: 280, 
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export default class LongName extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value } = this.props;
    const clippedValue = value ? clippedString(value, 100) : '—';
    

    return (
      <ColumnCell>
        <ColumnCellInner>
        <Tooltip arrow placement="bottom-start" title={value}>
            <div style={styleDiv}>{value || '—'}</div>
          </Tooltip>
        </ColumnCellInner>
      </ColumnCell>
    );
  }
}

LongName.propTypes = {
  value: PropTypes.string,
};
