import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const ProfileMain = styled.div`
  display: flex;
`;
export const ProfileLeft = styled.div`
  min-width: 220px;
  @media (max-width: 1200px) {
    min-width: 120px;
  }
`;
export const ProfileCenter = styled.div`
  min-width: 220px;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 130px;
  @media (max-width: 1200px) {
    padding-right: 30px;
  }
`;
export const ProfileRight = styled.div`
  min-width: 220px;
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 70px;
  margin-top: ${props => props.certificate ? '30px' : '-10px'};
  padding-top: 10px;
  @media (max-width: 1200px) {
    padding-right: 30px;
  }
`;
export const ProfileLoadCert = styled.div`
  margin-bottom: 20px;
`;
export const ProfileH3 = styled.h3`
  color: #52535a;
  font-size: 16px;
  margin: 0 0 5px;
  line-height: 21px;
`;

export const PasswordErrorsWarning = styled.div`
  color: red;
  font-size: 12px;
`;

export const ConfirmEmailButton = createMuiTheme({
  typography: {},
  overrides: {
    MuiButton: {
      root: {
        fontSize: '10px',
        textTransform: 'uppercase',
        borderRadius: '0 3px 3px 0',
        border: '1px solid #D9D9D9',
        borderLeft: 'none',
        height: '52px',
        minWidth: '101px',
        fontFamily: 'SegoeUI',
        transition: '.4s ease',
        fontWeight: 'bold',
        marginTop: '16px',
        color: '#4AA5E8',
        transform: 'translateX(-1px)',
      },
      text: {
        padding: '9px 20px',
      },
      label: {
        lineHeight: '19px',
      },
    },
  },
});
