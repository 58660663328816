import { ActionCreator } from 'redux';
import {
  LOAD_PARENT_COMPANY_ACTION,
  LOAD_PARENT_COMPANY_FAILED_ACTION,
  LOAD_PARENT_COMPANY_SUCCEEDED_ACTION,
} from './ParentCompany.constants';

export const loadParentCompany: ActionCreator = (params) => ({
  type: LOAD_PARENT_COMPANY_ACTION,
  params: params,
});

export const loadParentCompanySucceeded: ActionCreator = (
  data,
  totalObjects,
) => ({
  type: LOAD_PARENT_COMPANY_SUCCEEDED_ACTION,
  data: data,
  totalObjects: totalObjects,
});

export const loadParentCompanyFailed: ActionCreator = () => ({
  type: LOAD_PARENT_COMPANY_FAILED_ACTION,
});
