import * as React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import {
  defaultSelectTheme,
} from '../../../../common/styles/Common.styled';
import {
  NewProductDialogNext,
  ButtonCancelTheme,
} from './GcpModal.styled';
import { Preloader } from '../../../../components';
import { createGtin } from '../../ducks/Product.actions';

const packagingTypes = [
  { modalType: 'unit', packagingPurpose: 'consumer', title: 'Потребительская упаковка' },
  { modalType: 'transport', packagingPurpose: 'transport', title: 'Транспортная упаковка' },
  { modalType: 'group', packagingPurpose: 'group', title: 'Групповая упаковка' },
];


class GcpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packaging: _.find(packagingTypes, { 'modalType': this.props.modalType }) || {},
      typeGtin: 'ean-13',
      gcp: this.props.modalType === 'unit' ?
        this.props.manufacturerFields[0].gcp
        : (this.props.accountGcpList.length === 1 ?
          _.get(this.props, 'accountGcpList[0]', '')
          : ''
        ),
      loading: false,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const data = {
      packingPurpose: this.state.packaging.packagingPurpose,
    }
    if (this.state.packaging.packagingPurpose !== 'transport') {
      data.gcpPrefix = this.state.gcp
    }
    if (this.state.packaging.packagingPurpose === 'group') {
      data.gtinList =
        _.compact(
          _.map(this.props.groupPackageFields.arrayData, 'nestedPackagingCode')
            .concat(
              _.compact(
                _.map(this.props.groupPackageFields.nestedUnregisteredData, 'nestedPackagingCode'))
            )
        );
    }
    if (this.state.packaging.packagingPurpose === 'transport') {
      data.gtinList = _.compact(_.map(this.props.nestedArray, 'nestedPackagingCode'));
      data.gtinType = this.state.typeGtin;
      if (this.state.typeGtin !== 'itf-14') {
        data.gcpPrefix = this.state.gcp
      }
    }

    this.props.createGtin(
      data,
      () => {
        this.setState({ loading: false });
        this.props.handleClose();
      },
    )
  }

  render() {
    const { t, accountGcpList } = this.props;
    const disabled = true;
    const disabledTypeGtin =
      this.state.packaging.packagingPurpose !== 'transport'
        ? true
        : (_.compact(_.map(this.props.nestedArray, 'nestedPackagingCode')).length > 1
          ? true
          : false);
    const disabledGcp = this.state.packaging.packagingPurpose === 'consumer'
      ? true : false;

    return (
      <MuiThemeProvider theme={NewProductDialogNext}>
        <Dialog open>
          <Preloader
            isOpen={this.state.loading}
            text={t('Генерация нового кода товара')}
          />
          <DialogTitle>
            {t('Выбор префикса GCP для генерации нового GTIN')}
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth={true} disabled>
              <MuiThemeProvider theme={defaultSelectTheme}>
                <InputLabel id="packaging-select-label">
                  {t('Назначение упаковки')}
                </InputLabel>
                <Select
                  required
                  labelId="packaging-select-label"
                  id="packaging-select"
                  value={this.state.packaging.packagingPurpose}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  className={cx({ 'disabled-item': disabled })}
                  onChange={(event) =>
                    this.handleChange('packaging', event.target.value)
                  }>
                  <MenuItem value={this.state.packaging.packagingPurpose}>
                    {t(this.state.packaging.title)}
                  </MenuItem>
                </Select>
              </MuiThemeProvider>
            </FormControl>

            <FormControl fullWidth={true} disabled={disabledTypeGtin}>
              <MuiThemeProvider theme={defaultSelectTheme}>
                <InputLabel id="type-gtin-select-label">
                  {t('Тип кода')}
                </InputLabel>
                <Select
                  required
                  labelId="type-gtin-select-label"
                  id="type-gtin-select"
                  value={this.state.typeGtin}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  className={cx({ 'disabled-item': disabled })}
                  onChange={(event) =>
                    this.handleChange('typeGtin', event.target.value)
                  }>
                  <MenuItem value="ean-13">GTIN-13</MenuItem>
                  <MenuItem value="itf-14">ITF-14</MenuItem>
                </Select>
              </MuiThemeProvider>
            </FormControl>

            {this.state.typeGtin !== 'itf-14' &&
              <FormControl fullWidth={true} disabled={disabledGcp}>
                <MuiThemeProvider theme={defaultSelectTheme}>
                  <InputLabel id="gcp-select-label">
                    {t('Префикс GCP для генерации')}
                  </InputLabel>
                  <Select
                    required
                    labelId="gcp-select-label"
                    id="gcp-select"
                    value={this.state.gcp}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                    className={cx({ 'disabled-item': !!this.state.gcp })}
                    onChange={(event) =>
                      this.handleChange('gcp', event.target.value)
                    }>
                    {this.state.packaging.packagingPurpose !== 'consumer' ?
                      accountGcpList.map((item) => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                      ))
                      : <MenuItem value={this.state.gcp}>{this.state.gcp}</MenuItem>
                    }
                  </Select>
                </MuiThemeProvider>
              </FormControl>
            }
          </DialogContent>
          <DialogActions>
            <MuiThemeProvider theme={ButtonCancelTheme}>
              <Button onClick={this.props.handleClose}>
                {t('Отмена')}
              </Button>
            </MuiThemeProvider>
            <Button
              onClick={this.handleSubmit}
              disabled={!this.state.packaging.packagingPurpose ||
                !this.state.typeGtin || (!this.state.gcp && this.state.typeGtin !== 'itf-14')}
            >
              {t('Подтвердить')}
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createGtin: (data, cbk) => createGtin(data, cbk),
    },
    dispatch,
  );
};

GcpModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  createGtin: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(GcpModal);
