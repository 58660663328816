import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import ProductView from './ProductView';
import { clearProductData, loadCardItem, loadCatalogCardItem } from '../ducks/Product.actions';
import { setFiltersGrid } from '../../products/ducks/Products.actions';

class ProductPrint extends ProductView {
  componentDidMount() {
    this.eventListenerOnScroll();
    const { match, productData, onLoadCardItem, onLoadCatalogCardItem } = this.props;
    const id = _.toNumber(match.params.productId);
    const dto = match.params.dto;
    const catalog = match.params.catalog; // флаг, загрузилась ли страница по клику на Печать из Каталога, для загрузки данных с другого эндпоинта
    if (_.isEmpty(productData) && id && dto) {
      onLoadCardItem(id, dto);
    } else if (_.isEmpty(productData) && id && catalog) {
      onLoadCatalogCardItem(id);
    }

  }
}

const mapStateToProps = (state) => ({
  loading: !!state.productReducer.loading,
  productData: state.productReducer.data,
  images: state.productReducer.data.images,
  buttonViewSite:
    _.includes(
      ['published', 'published_notsigned'],
      _.get(state.productReducer, 'data.status'),
    ) && !!_.get(state.productReducer, 'data.isVisible', true),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLoadCardItem: (id, dtoName) => loadCardItem(id, dtoName),
      clearProductData: () => clearProductData(),
      goToPage: (page) => push(page),
      setFiltersGrid: () => setFiltersGrid(),
      // onDownloadProduct: (params, cbk) => downloadProduct(params, cbk),
      onLoadCatalogCardItem: (id) => loadCatalogCardItem(id),
    },
    dispatch,
  );
};

ProductPrint.propTypes = {
  setFiltersGrid: PropTypes.func.isRequired,
  onLoadCardItem: PropTypes.func.isRequired,
  onLoadCatalogCardItem: PropTypes.func,
  goToPage: PropTypes.func.isRequired,
  clearProductData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonViewSite: PropTypes.bool.isRequired,
  productData: PropTypes.object,
  images: PropTypes.array,
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
    }),
  }),
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductPrint);
