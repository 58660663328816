import styled from 'styled-components';

export const AddLetterFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const RadioInputsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  .inputClass {
    height: auto;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const ImportDialogMinInner = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 3px 3px 3px 21px;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  span.exportSpan {
    color: #ded7d7;
  }
  &:nth-of-type(1) {
    border-bottom: 1px solid #f2f2f2;
  }
`;
