import styled from 'styled-components';

export const SugIcon = styled.div`
  display: flex;
  margin-left: 5px;
`;
export const SugName = styled.div`
  margin-right: 120px;
  width: 280px;
  margin-left: 20px;
  white-space: normal;
  line-height: 1;
`;
export const SugCat = styled.div`
  color: #868686;
  margin-left: auto;
  font-weight: 400;
  padding-right: 130px;
`;
