import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import colors from '../../../../../common/styles/Common.colors';

export const SubItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;
export const SubItemName = styled.div`
  position: relative;
  flex-grow: 1;
  flex-basis: 50%;
`;
export const SubItemInn = styled.div`
  position: relative;
  flex-basis: 50%;
  max-width: 240px;
  margin-right: 20px;
`;
export const SubItemCountry = styled.div`
  position: relative;
  flex-basis: 100%;
`;
export const SubItemGcp = styled.div`
  position: relative;
  flex-basis: 47%;
  margin-right: 20px;
`;
export const SubItemGln = styled.div`
  position: relative;
  flex-basis: 47%;
`;
export const SubItemEmail = styled.div`
  position: relative;
  flex-basis: 47%;
  margin-right: 20px;
`;
export const SubItemPhone = styled.div`
  position: relative;
  flex-basis: 47%;
`;
export const SubItemDelete = styled.div`
  margin-left: 20px;
`;
export const AttachBtn = createMuiTheme({
  typography: {},
  overrides: {
    MuiButtonBase: {
      root: {
        transition: '.4s ease',
        borderRadius: '3px',
        padding: '12px 35px',
      },
    },
    MuiButton: {
      root: {
        minWidth: '70px',
        height: '50px',
        marginTop: '16px',
      },
      textPrimary: {
        backgroundColor: `${colors.Buttons.buttonPrimary.background}`,
        border: `1px solid ${colors.Buttons.buttonPrimary.background}`,
      },
      textSecondary: {
        background: 'none',
        border: '1px solid #868686',
        '&:hover': {
          border: '1px solid #000',
          backgroundColor: '#fff',
        },
      },
    },
    MuiTooltip: {
      popper: {
        background: 'rgba(0, 0, 0, .7)',
        maxWidth: '340px',
        marginTop: '20px',
        borderRadius: '4px',
        marginRight: '-30px',
      },
      tooltip: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#fff',
        fontWeight: 'normal',
        opacity: '1!important',
        transform: 'none!important',
        background: 'none!important',
        boxShadow: 'none',
        padding: '5px 20px',
        textAlign: 'center',
        position: 'relative!important',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        '&::before': {
          content: '""',
          display: 'block',
          width: '0',
          height: '0',
          borderWidth: '0 10px 17px 10px',
          borderColor: 'transparent transparent rgba(0, 0, 0, .5) transparent',
          borderStyle: 'solid',
          position: 'absolute',
          top: '-31px',
          right: '30px',
        },
      },
    },
  },
});
