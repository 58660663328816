import styled from 'styled-components';

export const Header = styled.div``;
export const RegCheck = styled.div`
  padding: 40px 0;
  font-size: 16px;
  color: #52535a;
`;
export const RegForm = styled.div`
  padding: 0 150px 40px;
  overflow: hidden;
  @media (max-width: 1440px) {
    padding: 0 110px 40px;
  }
  @media (max-width: 1024px) {
    padding: 0 80px 40px;
  }
`;
export const RegInfo = styled.div`
  padding-top: 25px;
`;
export const RegInfoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #52535a;
  font-size: 14px;
  h3 {
    margin: 0;
    font-size: 20px;
    color: #63666a;
    font-weight: 600;
    @media (max-width: 1024px) {
      flex-shrink: 0;
    }
  }
  p {
    @media (max-width: 1024px) {
      text-align: right;
    }
  }
`;
export const RegSuccess = styled.div`
  padding: 56px 208px 65px;
  color: #52535a;
  font-size: 16px;
  text-align: center;
  a {
    color: #4aa5e8;
    margin-top: 38px;
    display: inline-block;
    text-decoration: none;
  }
`;
export const RegSuccessH2 = styled.h2`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 57px;
`;
export const SelectCont = styled.div`
  padding: 40px 150px;
  overflow: hidden;
  @media (max-width: 1440px) {
    padding: 40px 110px;
  }
  @media (max-width: 1024px) {
    padding: 40px 80px;
  }
  @media (max-width: 700px) {
    padding: 40px 20px;
  }
`;
export const SelectContMin = styled.div`
  padding: 0 150px;
  overflow: hidden;

  @media (max-width: 1440px) {
    padding: 0 110px;
  }
  @media (max-width: 1024px) {
    padding: 0 80px;
  }
`;
