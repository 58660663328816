import styled from 'styled-components';
import { ProductViewMdiaItem } from '../../../../common/styles/Common.styled';

export const ProductViewTitle = styled.h3`
  font-size: 32px;
  color: #63666a;
  margin: 0 0 40px;
  font-weight: 600;
`;
export const ProductViewTitleMin = styled.h4`
  font-size: 16px;
  color: #63666a;
  margin: 0 0 10px;
  font-weight: bold;
  text-transform: uppercase;
`;
export const ProductViewButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 40px;
  margin: 0;
`;
export const ProductViewColumn = styled.div`
  margin-bottom: 30px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &:nth-of-type(1) {
    margin-bottom: 30px;
  }
`;
export const ProductViewColumnMin = styled.div`
  color: #000000;
  font-size: 14px;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
  display: flex;
`;
export const ProductColumnName = styled.div`
  color: #63666a;
  font-size: 14px;
  font-weight: 600;
  flex-basis: 310px;
  flex-shrink: 0;
`;
export const ProductColumnValue = styled.div`
  font-weight: 500;
  padding-left: 20px;
  flex-grow: 1;
  word-break: break-word;
`;
export const ProductPrintMediaItem = styled(ProductViewMdiaItem)`
  cursor: auto;
`;
export const ProductPrintMain = styled.div`
  box-shadow: 0 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 0 0 4px 4px;
  background: #fff;
  @media (max-width: 1200px) {
    padding: 70px 20px 100px;
  }
  display: flex;
  padding: 40px;
`;
export const ProductPrintLeft = styled.div`
  flex-basis: 20%;
  flex-shrink: 0;
  button {
    width: 100%;
    margin-top: 20px;
  }
`;
export const ProductPrintimgHolder = styled.div`
  max-width: 270px;
  padding: 30px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  text-align: center;
`;
export const ProductPrintRight = styled.div`
  flex-grow: 1;
  padding-left: 80px;
`;
export const ProductViewContainer = styled.div`
  padding: 30px;
  position: relative;
`;
