import styled from 'styled-components';
import { AdresTitleH3 } from '../../../../../../common/styles/Common.styled';

export const AdresMainBlock = styled.div`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 40px 0;
  transition: 0.4s ease;
`;
export const AddressBlock = styled.div`  
  padding: 20px 0;
`;
export const AdresTitleH3Green = styled(AdresTitleH3)`
  color: green;
`;
export const AdresTitleH3Error = styled(AdresTitleH3)`
  color: #ff4b4b;
`;
