import styled from 'styled-components';

export const SliderWrapper = styled.div`
  div.carousel-track {
    height: 20vh !important;
    div:focus {
      outline: none;
    }
  }
`;

export const SliderImage = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div:focus {
    outline: none;
  }
`;

export const DialogTitleContent = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const PreloaderWrapper = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
`;

export const ImageContentWrapper = styled.div`
  max-height: 60vh;
  display: flex;
  flex-direction: column;
`;

export const ImageActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px 0;
`;

export const DeleteImageButton = styled.div`
  display: flex;
  cursor: pointer;
  color: red;
  font-size: 16px;
`;

export const NoImagesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 60px 0;
`;
