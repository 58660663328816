import styled from 'styled-components';

export const CertViewBlock = styled.div`
  margin-bottom: 15px;
`;
export const CertViewBlockIcon = styled.div`
  padding: 15px 0 0 40px;
  position: relative;
`;
export const CertIcon = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
`;
export const CertOwner = styled.span`
  color: #868686;
  display: block;
  margin-bottom: 5px;
`;
export const CertName = styled.span`
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
`;
export const CertViewMainBlock = styled.div`
    background: #F9F9F9;
    padding: 16px 30px 25px;
    margin-bottom 10px;
`;
