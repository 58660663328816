export const LOAD_TRADE_MARKS_ACTION = 'LOAD_TRADE_MARKS_ACTION';
export const LOAD_TRADE_MARKS_SUCCEEDED_ACTION = 'LOAD_TRADE_MARKS_SUCCEEDED';
export const LOAD_TRADE_MARKS_FAILED_ACTION = 'LOAD_TRADE_MARKS_FAILED';

export const DELETE_TRADE_MARK_ACTION = 'DELETE_TRADE_MARK';
export const DELETE_TRADE_MARK_ACTION_SUCCEEDED =
  'DELETE_TRADE_MARK_ACTION_SUCCEEDED';
export const DELETE_TRADE_MARK_ACTION_FAILED =
  'DELETE_TRADE_MARK_ACTION_FAILED';

export const ADD_TRADE_MARK_ACTION = 'ADD_TRADE_MARK_ACTION';
export const ADD_TRADE_MARK_ACTION_SUCCEEDED =
  'ADD_TRADE_MARK_ACTION_SUCCEEDED';
export const ADD_TRADE_MARK_ACTION_FAILED = 'ADD_TRADE_MARK_ACTION_FAILED';

export const UPDATE_TRADE_MARK_ACTION = 'UPDATE_TRADE_MARK_ACTION';
export const UPDATE_TRADE_MARK_ACTION_SUCCEEDED =
  'UPDATE_TRADE_MARK_ACTION_SUCCEEDED';
export const UPDATE_TRADE_MARK_ACTION_FAILED =
  'UPDATE_TRADE_MARK_ACTION_FAILED';

export const ADD_TRADE_MARK_FILE_ACTION = 'ADD_TRADE_MARK_FILE_ACTION';
export const ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED =
  'ADD_TRADE_MARK_FILE_ACTION_SUCCEEDED';
export const ADD_TRADE_MARK_FILE_ACTION_FAILED =
  'ADD_TRADE_MARK_FILE_ACTION_FAILED';

export const GET_TRADE_MARK_DOCUMENTS_ACTION =
  'GET_TRADE_MARK_DOCUMENTS_ACTION';
export const GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED =
  'GET_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED';
export const GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED =
  'GET_TRADE_MARK_DOCUMENTS_ACTION_FAILED';

export const DELETE_TRADE_MARK_DOCUMENTS_ACTION =
  'DELETE_TRADE_MARK_DOCUMENTS_ACTION';
export const DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED =
  'DELETE_TRADE_MARK_DOCUMENTS_ACTION_SUCCEEDED';
export const DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED =
  'DELETE_TRADE_MARK_DOCUMENTS_ACTION_FAILED';
