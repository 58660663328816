import * as React from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { concat, find, isEmpty, isEqual } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiThemeProvider,
  Radio,
} from '@material-ui/core';
import EmptyResultComponent from '../EmptyResultComponent';
import {
  ProductSearchCount,
  ProductSearchDialog,
  ProductSearchTable,
  ProductSearchTableCell,
  ProductSearchTableRadio,
} from './PopupSearchModal.styled';
import { ProductSearchBtns } from '../Product/Product.styled';
import {
  CheckBoxDefault,
  DefaultGridButton,
  DialogTitleContent,
  DialogTitlePhotoStyled,
} from '../../../../common/styles/Common.styled';
import {
  IconArrowBlue,
  IconClose,
  RadioChecked,
  RadioIcon,
} from '../../../../common/icons/customIcons';
import { clearCertificateSearch } from '../../ducks/Product.actions';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class PopupSearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      checkboxColumn: {},
      selected: {},
      searchData: [],
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!isEqual(nextProps.searchResults, state.searchData)) {
      return {
        searchData: nextProps.searchResults,
        selected: {},
      };
    }
    return null;
  }

  resetForm = () => {
    this.props.resetForm();
    this.handleClose(false);
  };

  getNoDataProps = () => ({
    resetForm: this.resetForm,
  });

  componentDidMount() {
    this.createColumns();
  }

  searchStateCertificate = () => {
    this.props.searchStateCertificate(this.state.data);
  };

  toggleRow = (documentNumber) => {
    const item = { id: documentNumber };
    this.setState({ selected: item });
  };

  chooseCertificate = () => {
    const selected = this.state.selected;
    const item = find(this.state.searchData, { documentNumber: selected.id });
    this.props.chooseCertificate(item);
    this.setState({ selected: {} });
    this.props.clearCertificateSearch();
  };

  handleClose = (closeSearchParameters) => {
    this.props.clearCertificateSearch();
    this.props.handleClose(closeSearchParameters);
  };

  render() {
    const { t } = this.props;

    return (
      <MuiThemeProvider theme={ProductSearchDialog}>
        <Dialog open>
          <DialogTitle>
            <DialogTitleContent>
              <MuiThemeProvider theme={DefaultGridButton}>
                <Button
                  className="backChanges"
                  onClick={() => this.handleClose(false)}>
                  <IconArrowBlue style={{ marginRight: '10px' }} />
                  {t('Новый поиск')}
                </Button>
              </MuiThemeProvider>
              <DialogTitlePhotoStyled>
                {t('Свидетельство о государственной регистрации')}
              </DialogTitlePhotoStyled>
              <Button onClick={() => this.handleClose(true)}>
                <IconClose />
              </Button>
            </DialogTitleContent>
          </DialogTitle>
          <DialogContent>
            <div>
              <ProductSearchCount>
                Найдено записей: {this.state.searchData.length}
              </ProductSearchCount>
              <ProductSearchTable>
                <ReactTableFixedColumns
                  data={this.state.searchData}
                  columns={concat(
                    this.state.checkboxColumn,
                    this.state.columns,
                  )}
                  className="-highlight"
                  resizable={false}
                  pageSize={this.state.searchData.length}
                  NoDataComponent={EmptyResultComponent}
                  getNoDataProps={this.getNoDataProps}
                  showPagination={false}
                  minRows={0}
                />
              </ProductSearchTable>
            </div>
          </DialogContent>
          <DialogActions>
            {!isEmpty(this.state.searchData) && (
              <ProductSearchBtns>
                <Button
                  color={'primary'}
                  disabled={isEmpty(this.state.selected)}
                  onClick={this.chooseCertificate}>
                  {t('Выбрать')}
                </Button>
              </ProductSearchBtns>
            )}
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  createColumns = () => {
    const { t } = this.props;

    let checkBoxColumn = {
      id: 'checkbox',
      accessor: '',
      fixed: 'left',
      sortable: false,
      maxWidth: 38,
      Header: (x) => {
        return '';
      },
      Cell: ({ original }) => {
        const checked =
          !isEmpty(this.state.selected) &&
          this.state.selected.id === original.documentNumber;
        return (
          <ProductSearchTableRadio>
            <MuiThemeProvider theme={CheckBoxDefault}>
              <Radio
                icon={<RadioIcon />}
                checkedIcon={<RadioChecked />}
                checked={!!checked}
                onChange={() => this.toggleRow(original.documentNumber)}
              />
            </MuiThemeProvider>
          </ProductSearchTableRadio>
        );
      },
    };

    let columns = [
      {
        name: t('Номер свидетельства'),
        show: true,
        accessor: 'documentNumber',
        position: 0,
        sortable: false,
        maxWidth: 140,
        style: { justifyContent: 'center' },
        Header: (x) => {
          return (
            <ProductSearchTableCell id="documentNumber">
              {t('Номер свидетельства')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.documentNumber}
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Статус документа'),
        show: true,
        position: 1,
        accessor: 'status',
        sortable: false,
        width: 130,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="status">
              {t('Статус документа')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.statusName}
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Типографский номер бланка'),
        show: true,
        position: 2,
        accessor: 'serialNumber',
        sortable: false,
        minWidth: 120,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="serialNumber">
              {t('Типографский номер бланка')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.serialNumber}
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Дата оформления документа'),
        show: true,
        position: 3,
        accessor: 'documentDate',
        sortable: false,
        maxWidth: 140,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="documentDate">
              {t('Дата оформления документа')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              <Moment format="DD.MM.YYYY">{original.documentDate}</Moment>
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Категория продукции'),
        show: true,
        position: 4,
        accessor: 'productGroupName',
        sortable: false,
        minWidth: 190,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="productGroupName">
              {t('Категория продукции')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.productGroupName}
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Наименование изготовителя'),
        show: true,
        position: 5,
        accessor: 'makerName',
        sortable: false,
        maxWidth: 180,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="makerName">
              {t('Наименование изготовителя')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.makerName}
            </ProductSearchTableCell>
          );
        },
      },
      {
        name: t('Наименование получателя'),
        show: true,
        position: 6,
        accessor: 'receiverName',
        sortable: false,
        minWidth: 150,
        Header: (x) => {
          return (
            <ProductSearchTableCell id="receiverName">
              {t('Наименование получателя')}
            </ProductSearchTableCell>
          );
        },
        Cell: ({ original }) => {
          return (
            <ProductSearchTableCell>
              {original.receiverName}
            </ProductSearchTableCell>
          );
        },
      },
    ];
    this.setState({ checkboxColumn: checkBoxColumn, columns: columns });
  };
}

const mapStateToProps = (state) => ({
  searchResults: state.productReducer.searchData,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearCertificateSearch: () => clearCertificateSearch(),
    },
    dispatch,
  );
};

PopupSearchModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  clearCertificateSearch: PropTypes.func.isRequired,
  chooseCertificate: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(PopupSearchModal);
