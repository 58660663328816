import { all, call, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { client } from '../../api/Client';
import i18n from '../../i18n';
import { throwNotification } from '../../common/structure';
import { VALIDATE_TEXT_ACTION } from './utils.actions';

export function* validateText(actions) {
  const errT = i18n.t('Ошибка валидации текстового поля');
  const field = 'text';

  try {
    const response = yield call(
      client().post,
      '/validation/censure',
      JSON.stringify({ [field]: actions.text }),
    );
    if (
      _.isString(response) ||
      _.get(response, 'error.response.status') === 500
    ) {
      actions.cbk({ successed: false, error: errT });
      yield put(throwNotification(errT, 'errorRed'));
      return;
    }

    const validationError = _.get(
      response,
      `error.response.data.validationErrors.${field}`,
      null,
    );
    if (validationError) {
      actions.cbk({ successed: true, error: validationError });
    } else {
      actions.cbk({ successed: true, error: null });
    }
  } catch (e) {
    yield put(throwNotification(errT, 'errorRed'));
    actions.cbk({ successed: false, error: errT });
  }
}

export function* watchValidateText() {
  yield takeEvery(VALIDATE_TEXT_ACTION, validateText);
}

export default function* utilsSaga() {
  yield all([watchValidateText()]);
}
