import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import * as selectors from './ducks/Authentification.selectors';
import { login, remindPassword } from './ducks/Authentification.actions';
import AuthentificationLoginView from './views/AuthentificationLogin.view';
import { HeaderLanguageSelection } from '../../components';
import { isKZ } from '../../config';
import {
  Content,
  Header,
  LoginFormContAuth,
  StyledH1,
} from '../../common/styles/Common.styled';
import SvgLogo from '../../common/icons/logo';
import { LogoKz, LogoRusKz } from '../../common/icons/customIcons';

// const domain = !isLocalhost
//     ? `${window.location.protocol}//${window.location.hostname.split('.').slice(1).join('.')}`
//     : '/';

class Authentification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginMode: true,
      isHowToOpen: false,
      emailValid: true,
    };
  }

  onChangeMode = () => {
    if (this.props.loading) return;
    this.setState({ loginMode: !this.state.loginMode });
  };

  render() {
    const { t, locale } = this.props;
    const logoKZ = locale === 'kz' ? <LogoKz /> : <LogoRusKz />;

    return (
      <div>
        <Header>
          <HeaderLanguageSelection />
          <span>{isKZ ? logoKZ : <SvgLogo />}</span>
          <StyledH1>{t('Вход в личный кабинет')}</StyledH1>
        </Header>
        <Content>
          <LoginFormContAuth>
            <AuthentificationLoginView
              loading={this.props.loading}
              onChange={this.handleChange}
              onLogin={this.props.onLogin}
              onRemindPassword={this.props.onRemindPassword}
              isActiveSessionOpenModal={this.props.isActiveSessionOpenModal} //449
              activeSessionInfo={this.props.activeSessionInfo}
            />
          </LoginFormContAuth>
        </Content>
        {/**
         * скрыто до лучших времён.
         */}
        {/* <Footer>
                    {t('Впервые в системе?')}<br />
                    <Link to="/registration">{t('Зарегистрируйтесь')}</Link> {t('или узнайте')} <a rel="noopener noreferrer" href={domain}>
                        {t('больше на сайте эксперимента')}</a>.
                </Footer> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: selectors.loading(state),
  locale: selectors.locale(state),
  isActiveSessionOpenModal: selectors.isActiveSessionOpenModal(state), //449
  activeSessionInfo: selectors.activeSessionInfo(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onLogin: (data) => login(data),
      onRemindPassword: (data) => remindPassword(data),
    },
    dispatch,
  );
};

Authentification.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onRemindPassword: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isActiveSessionOpenModal: PropTypes.bool.isRequired,
  activeSessionInfo: PropTypes.string.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(Authentification);
