import _ from 'lodash';
import * as moment from 'moment';

const getDefaultTo = (obj, path) => _.defaultTo(_.get(obj, path, ''), '');

export const parseAddresses = function (data) {
  return _.map(data, (item) => {
    item.guid = item.aoguid;
    item.text = item.officialName;
    return item;
  });
};

export const parseHouses = function (data) {
  return _.map(data, (item) => {
    item.guid = item.aoguid;
    item.text = item.fullName;
    return item;
  });
};

export const parseRoom = function (data) {
  return _.map(data, (item) => {
    item.guid = item.roomGuid;
    item.text = !!item.flatNum ? item.flatNum : '';
    return item;
  });
};

export const convertAddress = function (data, addressType, id) {
  return {
    id: id,
    addressType: addressType,
    index: { value: { guid: '', text: data.postalCode }, visible: true },
    locationCountry: { value: { id: data.countryId, text: '' }, visible: true },
    region: {
      value: {
        guid: data.regionGuid,
        text: data.region,
        regionCode: data.regionCode,
      },
      visible: true,
      level: 1,
    },
    area: {
      value: { guid: data.areaGuid, text: data.area, areaCode: data.areaCode },
      visible: true,
      level: 3,
    },
    city: {
      value: { guid: data.cityGuid, text: data.city, cityCode: data.cityCode },
      visible: true,
      level: 4,
    },
    locality: {
      value: {
        guid: data.localityGuid,
        text: data.locality,
        localityCode: data.localityCode,
      },
      visible: true,
      level: 6,
    },
    street: {
      value: {
        guid: data.streetGuid,
        text: data.street,
        streetCode: data.streetCode,
      },
      visible: true,
      level: 7,
    },
    house: {
      value: { guid: data.buildingGuid, text: data.building },
      visible: true,
    },
    room: {
      value: {
        guid: getDefaultTo(data, 'roomGuid'),
        text: getDefaultTo(data, 'room'),
      },
      visible: true,
      level: 1,
    },
    mailbox: {
      value: { guid: '', text: getDefaultTo(data, 'mailbox') },
      visible: true,
      level: 1,
    },
    fullAddress: { value: { guid: '', text: data.fullAddress }, visible: true },
  };
};

export const getMonth = (date) => moment(date).locale('ru').format('MMMM YYYY');
