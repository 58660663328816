export const LOAD_SUB_PROFILES_ACTION = 'LOAD_SUB_PROFILES';
export const LOAD_SUB_PROFILES_SUCCEEDED_ACTION = 'LOAD_SUB_PROFILES_SUCCEEDED';
export const LOAD_SUB_PROFILES_FAILED_ACTION = 'LOAD_SUB_PROFILES_FAILED';

export const ADD_SUB_PROFILE_ACTION = 'ADD_SUB_PROFILE_ACTION';
export const ADD_SUB_PROFILE_SUCCEEDED_ACTION =
  'ADD_SUB_PROFILE_SUCCEEDED_ACTION';
export const ADD_SUB_PROFILE_FAILED_ACTION = 'ADD_SUB_PROFILE_FAILED_ACTION';

export const EDIT_SUB_PROFILE_ACTION = 'EDIT_SUB_PROFILE_ACTION';
export const EDIT_SUB_PROFILE_SUCCEEDED_ACTION =
  'EDIT_SUB_PROFILE_SUCCEEDED_ACTION';
export const EDIT_SUB_PROFILE_FAILED_ACTION = 'EDIT_SUB_PROFILE_FAILED_ACTION';

export const BLOCK_SUB_PROFILE_ACTION = 'BLOCK_SUB_PROFILE_ACTION';
export const BLOCK_SUB_PROFILE_SUCCEEDED_ACTION =
  'BLOCK_SUB_PROFILE_SUCCEEDED_ACTION';
export const BLOCK_SUB_PROFILE_FAILED_ACTION =
  'BLOCK_SUB_PROFILE_FAILED_ACTION';

export const UNBLOCK_SUB_PROFILE_ACTION = 'UNBLOCK_SUB_PROFILE_ACTION';
export const UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION =
  'UNBLOCK_SUB_PROFILE_SUCCEEDED_ACTION';
export const UNBLOCK_SUB_PROFILE_FAILED_ACTION =
  'UNBLOCK_SUB_PROFILE_FAILED_ACTION';
