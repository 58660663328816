import * as React from "react";
import { Badge } from '@material-ui/core';
import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from "react-i18next";
import memoize from 'memoize-state';
import cx from "classnames";
import _ from "lodash";
import Moment from 'react-moment';
import download from 'downloadjs';
import { config, isKZ } from "../../../../config";
import {
    Button,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    MuiThemeProvider,
    Typography,
    FormControl,
    Select,
    Tooltip
} from "@material-ui/core";
import {
    AlertBtn,
    HeaderCellTitle,
    HeaderMain,
    HeaderMainCellAlert,
    HeaderMainCellLogin,
    HeaderMainCellSearch,
    HeaderMainH1,
    HeaderMainInner,
    HeaderMainMenu,
    HeaderMenuSelect,
    HeaderSearchIcon,
    LoginButton,
    ButtonAlertIcon,
    LoginName,
    LoginInfo,
    LoginUserIcon,
    LogoutIcon,
    MenuItemDot,
    MenuItemLink,
    MenuItemSpan,
    NotBlockMin,
    NotContent,
    NotifBtn,
    NotPaper,
    NotTitle,
    SearchInput,
    HeaderLangSelect,
    HeaderLangBtn,
    HeaderLogout
} from "./Header.styled";
import {
    IconAlert,
    IconCatalog,
    IconHeaderMenu,
    IconLogout,
    IconMarked,
    IconNewAlert,
    IconSearch,
    IconSupport,
    IconLoginUser,
} from "../../../icons/customIcons";
import { HeaderAutocomplete } from "../../../../components";
import NotificationModal from "../NotificationModal/NotificationModal";
import { logout } from "../../../../pages/authentication/ducks/Authentification.actions";
import { cleanNotification, loadNotification, markAsReadNotification } from "../../../catalog/Catalog.action";
import { setLocale } from "../../../../pages/profile/ducks/Profile/Profile.actions";
import { client } from '../../../../api/Client';
import { throwNotification } from '../../../../common/structure';

const sortNotifications = memoize((notifications) => _.orderBy(_.takeRight(notifications, 1000), ['date'], ['desc']));
const hasNewNotification = _.memoize((notifications) => !_.isEmpty(_.filter(notifications, { isRead: false })));
const filteredNotifications = ((notifications) =>_.filter(notifications, { isRead: false }));
const openxmlFormats =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: {
                bell: null,
                userInfo: null,
                elk: null
            },
            notificationModal: false,
            hasNewNotification: false,
        };
        // this.accountType = _.get(this.props, 'user.accountType'); // было до 338 задачи: роль-строка, теперь массив. 
        this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes'));
        this.markirovkaDomain = config.markirovkaDomain;
    }

    componentDidMount() {
        this.props.loadNotification();
        this.interval = setInterval(this.props.loadNotification, 60000);
        const savedLang = localStorage.getItem('savedLanguage');

        if (savedLang) {
            this.props.setLocale(savedLang);
        }
    }

    componentDidUpdate() {
        const h = hasNewNotification(this.props.notifications);
        if ((this.state.hasNewNotification !== h) || (h.length > 0)) {
            this.setState({ hasNewNotification: h });
            // if (h.length > 0) {
            //     this.setState({ hasNewNotification: h });
            // }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleClick = item => event => {
        let anchorEl = this.state.anchorEl;
        anchorEl[item] = event.currentTarget;
        if (item === 'bell' && _.isEmpty(this.props.notifications)) {
            this.props.loadNotification();
        }
        this.setState({ anchorEl: anchorEl });
    };

    markAsRead = () => {
        const unReadIds = _.map(_.filter(this.props.notifications, { isRead: false }), 'id');
        if (!_.isEmpty(unReadIds)) {
            this.props.markAsReadNotification(unReadIds);
        }
    };

    handleCloseBell = () => {
        this.handleClose();
        this.markAsRead();
    };

    handleClose = () => {
        this.setState({ anchorEl: { bell: null, userInfo: null } });
    };


    handleShowAllNotification = () => {
        this.setState({
            notificationModal: !this.state.notificationModal,
            anchorEl: { ...this.state.anchorEl, bell: null }
        });
        this.markAsRead();
        this.props.cleanNotifications();
    };

    pageProfile = () => {
        this.handleClose();
        this.props.goToPage('/profile');
    };

    onLogout = () => {
        localStorage.removeItem('savedFilters');
        localStorage.removeItem('savedPageSize');
        // localStorage.removeItem('savedSort');
        localStorage.removeItem('savedFiltersCatalog');
        localStorage.removeItem('savedPageSizeCatalog');
        localStorage.removeItem('savedFiltersCompanies');
        localStorage.removeItem('savedPageSizeCompanies');
        this.handleClose();
        this.props.logout();
    };

    getTitle = (pathname) => {
        const { t } = this.props;
        const goodsUrl = new RegExp(`${config.urls.product}/goods`);
        const draftUrl = new RegExp(`${config.urls.product}/draft`);
        const dataProfile = new RegExp(`${config.urls.profile}/data`);
        const productsProfile = new RegExp(`${config.urls.profile}/products`);
        const accountsProfile = new RegExp(`${config.urls.profile}/accounts`);
        switch (true) {
            case pathname === config.urls.products:
            case goodsUrl.test(pathname):
            case draftUrl.test(pathname): return t('Товары');
            case pathname === config.urls.productNew: return t('Добавить товар');
            case pathname === "/profile":
            case dataProfile.test(pathname):
            case productsProfile.test(pathname):
            case accountsProfile.test(pathname): return t('Профиль');            
            case pathname === config.urls.catalog: return t('Каталог');
            case pathname === config.urls.companies: return t('Компании');
            default: return "";
        }
    };

    onLangChange = (e) => {
        localStorage.setItem('savedLanguage', e.target.value);
        this.props.setLocale(e.target.value);
    };


    exportIfReport = (e) => {
        if (e.target.classList.contains('download-csv-report')) {
            e.preventDefault();
            const { t } = this.props;
            const message = 'Не удалось скачать отчет';
            const link = e.target.getAttribute('href');
            if (e.currentTarget.getAttribute('id') === "render-props-menu-bell") {
                this.handleCloseBell();
            }
            client()
                .get(link, { responseType: 'arraybuffer' })
                .then((res) => {
                    if (!res.error) {
                        const blob = new Blob([res], { type: openxmlFormats });
                        return download(blob, `${t('Oтчет по импорту')}.xlsx`);
                    } else {
                        const errorObj = JSON.parse(String.fromCharCode.apply(
                            null, new Uint8Array(res.error.response.data))
                        );
                        if (res.error && !_.isEmpty(_.get(errorObj, 'errors'))) {
                            this.props.throwError(t(errorObj.errors[0]));
                        } else this.props.throwError(t(message));
                    }
                })
                .catch((err) => this.props.throwError(t(message)));
        }
    }

    cancelReportLink = (e) => {
        if (e.target.classList.contains('download-csv-report')) {
            e.preventDefault();
        }
    }

    render() {
        const { user, t, locale, profile, open } = this.props;
        const anchorElELK = this.state.anchorEl.elk;
        const anchorElBell = this.state.anchorEl.bell;
        // const anchorElUserInfo = this.state.anchorEl.userInfo;
        const openELK = Boolean(anchorElELK);
        const openBell = Boolean(anchorElBell);
        // const openUserInfo = Boolean(anchorElUserInfo);
        const notifications = sortNotifications(this.props.notifications);
        const notificationsCount = filteredNotifications(this.props.notifications);
        const HeaderLangBtnStyle = { margin: 'auto', fontWeight: "500" }

        return (
            <HeaderMain>
                <HeaderMainInner open={open}>
                    {!!user.isElkUser &&
                        <HeaderMainMenu>
                            <MuiThemeProvider theme={AlertBtn}>
                                <IconButton
                                    aria-label="More"
                                    aria-controls="menu-elk"
                                    aria-haspopup="true"
                                    aria-owns={openELK ? 'menu-elk' : undefined}
                                    onClick={this.handleClick('elk')}
                                    color={"secondary"}
                                >
                                    <IconHeaderMenu />
                                </IconButton>
                            </MuiThemeProvider>
                            <MuiThemeProvider theme={HeaderMenuSelect}>
                                <Menu id="menu-elk"
                                    anchorEl={anchorElELK}
                                    open={openELK}
                                    onClose={this.handleClose}
                                    disableAutoFocusItem={true}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    getContentAnchorEl={null}
                                >
                                    <MenuItem disableGutters={true}>
                                        <MenuItemLink href={`${this.markirovkaDomain}/dashboard`}>
                                            <MenuItemSpan>
                                                <ListItemIcon>
                                                    <IconMarked />
                                                </ListItemIcon>
                                                <Typography variant="inherit">{t('Маркировка товаров')}</Typography>
                                            </MenuItemSpan>
                                        </MenuItemLink>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleClose} disableGutters={true} disabled>
                                        <MenuItemSpan>
                                            <ListItemIcon>
                                                <IconCatalog />
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                                {t('Каталог')}
                                                <MenuItemDot>•</MenuItemDot>
                                            </Typography>
                                        </MenuItemSpan>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleClose} disableGutters={true}>
                                        <MenuItemLink href={`${this.markirovkaDomain}/dashboard`}>
                                            <MenuItemSpan>
                                                <ListItemIcon>
                                                    <IconSupport />
                                                </ListItemIcon>
                                                <Typography variant="inherit">{t('Техподдержка')}</Typography>
                                            </MenuItemSpan>
                                        </MenuItemLink>
                                    </MenuItem>
                                </Menu>
                            </MuiThemeProvider>
                        </HeaderMainMenu>
                    }


                    <HeaderCellTitle>
                        <HeaderMainH1>
                            {this.getTitle(this.props.pathname)}
                        </HeaderMainH1>
                    </HeaderCellTitle>

                    {this.accountTypes && this.accountTypes.some(elem => elem === ('producer' || 'importer')) &&
                        <HeaderMainCellSearch>
                            <MuiThemeProvider theme={SearchInput}>
                                <HeaderAutocomplete />
                            </MuiThemeProvider>
                            <HeaderSearchIcon>
                                <IconSearch />
                            </HeaderSearchIcon>
                        </HeaderMainCellSearch>
                    }

                    <HeaderMainCellAlert>
                        <MuiThemeProvider theme={AlertBtn}>
                            <IconButton
                                id="buttonBell"
                                aria-owns={openBell ? 'render-props-menu-bell' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClick('bell')}
                            >
                                <ButtonAlertIcon newAlert={this.state.hasNewNotification}>
                                {this.state.hasNewNotification ? (
                                    <Badge badgeContent={notificationsCount.length} color="secondary">
                                    <IconAlert />
                                    </Badge>
                                ) : (
                                    <IconAlert />
                                )}
                                    
                                    
                                    {/* {this.state.hasNewNotification ? <IconNewAlert /> : <IconAlert />} */}
                                        {/* {notifications.length > 0 && ( 
                                                <Badge badgeContent={notifications.length} color="secondary">
                                                    <IconAlert />
                                                </Badge>
                                            )} */}
                                
                                </ButtonAlertIcon>
                            </IconButton>
                        </MuiThemeProvider>
                        <MuiThemeProvider theme={NotPaper}>
                            <Menu id="render-props-menu-bell"
                                anchorEl={anchorElBell}
                                open={openBell}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                getContentAnchorEl={null}
                                onClose={this.handleCloseBell}
                                onClick={this.exportIfReport}
                                onContextMenu={this.cancelReportLink}
                            >
                                {_.map(notificationsCount, (item, index) =>
                                    <NotBlockMin className={cx({ "bordered": !item.isRead })} key={`notif_${index}`}>
                                        <NotTitle>
                                            {item.section} • <Moment format="DD.MM.YYYY">{item.date}</Moment>
                                        </NotTitle>
                                        <NotContent dangerouslySetInnerHTML={{ __html: item.text }} />
                                    </NotBlockMin>
                                )}

                                <div>
                                    <MuiThemeProvider theme={NotifBtn}>
                                        <Button onClick={this.handleShowAllNotification}>
                                            {t('Посмотреть все уведомления')}
                                        </Button>
                                    </MuiThemeProvider>
                                </div>
                            </Menu>
                        </MuiThemeProvider>
                    </HeaderMainCellAlert>

                    <HeaderLangBtn>
                        {isKZ &&
                            <MuiThemeProvider theme={HeaderLangSelect}>
                                <FormControl fullWidth={true} style={{ height: '100%' }}>
                                    <Select
                                        value={locale}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                            //transformOrigin: { vertical: "bottom", horizontal: "left" }
                                        }}
                                        IconComponent={'none'}
                                        onChange={this.onLangChange}
                                    >
                                        <MenuItem value="kz">
                                            <span style={HeaderLangBtnStyle}>
                                                {'KZ'}
                                            </span>
                                        </MenuItem>
                                        <MenuItem value="ru">
                                            <span style={HeaderLangBtnStyle}>
                                                {'RU'}
                                            </span>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </MuiThemeProvider>
                        }
                    </HeaderLangBtn>

                    <HeaderMainCellLogin>
                        <MuiThemeProvider theme={LoginButton}>
                            <Tooltip
                                arrow={true}
                                placement="bottom-end"
                                title={t(`${profile.company.nameShort}`)}
                            >
                                <LoginUserIcon><IconLoginUser /></LoginUserIcon>
                            </Tooltip>
                            <LoginInfo>
                                <LoginName>{user.firstName}{' '}{user.lastName}</LoginName>
                            </LoginInfo>
                        </MuiThemeProvider>
                    </HeaderMainCellLogin>

                    <HeaderLogout>
                        <MuiThemeProvider theme={AlertBtn} >
                            <LogoutIcon onClick={this.onLogout}><IconLogout /></LogoutIcon>
                        </MuiThemeProvider>
                    </HeaderLogout>
                </HeaderMainInner>

                {this.state.notificationModal &&
                    <NotificationModal
                        handleClose={this.handleShowAllNotification}
                        exportIfReport={this.exportIfReport}
                        cancelReportLink={this.cancelReportLink}
                    />
                }
            </HeaderMain>
        );
    }
}

Header.propTypes = {
    user: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
    goToPage: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    markAsReadNotification: PropTypes.func.isRequired,
    setLocale: PropTypes.func.isRequired,
    throwError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.authentificationReducer.user,
    pathname: state.router.location.pathname,
    notifications: state.catalogReducer.notifications,
    locale: state.profileReducer.locale,
    profile: state.profileReducer.info,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        goToPage: (page) => push(page),
        logout: () => logout(),
        loadNotification: () => loadNotification(),
        cleanNotifications: () => cleanNotification(),
        markAsReadNotification: (ids) => markAsReadNotification(ids),
        setLocale: (locale) => setLocale(locale),
        throwError: (message) => throwNotification(message, 'error'),
    }, dispatch);
};

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Header)
