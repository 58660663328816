import { createGlobalStyle } from 'styled-components';
import colors from './styles/Common.colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    min-height:100vh;
    font-family: "SegoeUI";
    overflow-x: hidden;
    font-size: 14px;
    color: #52535A;
    box-sizing: border-box;
    font-weight: normal;
  }
  input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        color: #52535A!important;
        transition: background-color 5000s ease-in-out 0s;
    }
    button, input, h3{
        font-family: "SegoeUI"!important;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {        
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
    ::-webkit-scrollbar-track {
        background-color:#F2F2F2;
    }
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background-color: ${colors.Scroll.background};
    }
    ::-webkit-scrollbar-thumb:hover{
        background-color: ${colors.Scroll.background};
    }
    ::-webkit-scrollbar{
        width: 4px;
        height: 10px;
    }
`;
