import * as React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  getBrowserInfo,
  getCryptoProInfo,
  getOSInfo,
  isCurrentOkWithRequired,
} from './SystemRequirements.utils';
import SystemRequirementsView from './SystemRequirements.view';
import SidebarBrowserContent from './SidebarBrowserContent/SidebarBrowserContent';
import SidebarPluginContent from './SidebarPluginContent/SidebarPluginContent';
import {
  Content,
  Footer,
  Header,
  StyledH1,
} from '../../common/styles/Common.styled';
import { browserCheck } from '../registration/ducks/Registration.actions';
import SvgLogo from '../../common/icons/logo';
import LogoKZ from '../../common/icons/logo-kz.png';
import { config, isKZ } from '../../config';

class BrowserCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkPressed: false,
      os: {
        checked: false,
        compatible: null,
      },
      browser: {
        checked: false,
        compatible: null,
      },
      cryptopro: {
        checked: false,
        compatibleCades: null,
        compatibleCsp: null,
      },

      sidebarOpen: {
        browserContent: false,
        pluginContent: false,
      },
    };
  }

  init() {
    getOSInfo()
      .then((osVersion) => {
        this.setState({
          os: {
            platform: osVersion.platform,
            compatible:
              false === osVersion.required
                ? false
                : true === osVersion.required
                ? true
                : isCurrentOkWithRequired(
                    osVersion.required,
                    osVersion.current,
                  ),
            checked: true,
          },
        });
      })
      .catch(() => {
        this.setState({
          os: {
            checked: true,
            compatible: false,
          },
        });
      });

    getBrowserInfo()
      .then((info) => {
        this.setState({
          browser: {
            detect: info.detect,
            compatible:
              false === info.required
                ? false
                : true === info.required
                ? true
                : isCurrentOkWithRequired(info.required, info.current),
            checked: true,
          },
        });
      })
      .catch(() => {
        this.setState({
          browser: {
            checked: true,
            compatible: true,
          },
        });
      });

    getCryptoProInfo()
      .then((info) => {
        this.setState({
          cryptopro: {
            cadesVersion: info.cadesVersion,
            cspVersion: info.cspVersion,
            compatibleCades: isCurrentOkWithRequired(
              info.requiredCades,
              info.currentCades,
            ),
            compatibleCsp: isCurrentOkWithRequired(
              info.requiredCsp,
              info.currentCsp,
            ),
            checked: true,
          },
        });
      })
      .catch(() => {
        this.setState({
          cryptopro: {
            checked: true,
            compatibleCades: false,
            compatibleCsp: false,
          },
        });
      });
  }

  onClick = () => {
    if (!this.state.checkPressed) {
      this.init();
      return this.setState({ checkPressed: true });
    }

    if (!this.isContinueDisabled()) {
      this.props.browserCheck();
      this.props.goToPage('/registration');
    }
  };

  onInstallPlugin = () => {
    this.setState((prevState) => ({
      sidebarOpen: {
        pluginContent: !prevState.sidebarOpen.pluginContent,
        browserContent: false,
      },
    }));
  };

  onSelectBrowser = () => {
    this.setState((prevState) => ({
      sidebarOpen: {
        browserContent: !prevState.sidebarOpen.browserContent,
        pluginContent: false,
      },
    }));
  };

  isContinueDisabled = () => {
    const { compatible: compatibleOs, checked: checkedOs } = this.state.os;
    const {
      compatible: compatibleBrowser,
      checked: checkedBrowser,
    } = this.state.browser;
    const {
      compatibleCades,
      compatibleCsp,
      checked: checkedCryptopro,
    } = this.state.cryptopro;

    return (
      (checkedBrowser && !compatibleBrowser) ||
      (checkedOs && !compatibleOs) ||
      (checkedCryptopro && !compatibleCades) ||
      (checkedCryptopro && !compatibleCsp)
    );
  };

  render() {
    const { t } = this.props;
    const fnUp = _.flow([t, _.upperCase]);
    const { os, browser, cryptopro, checkPressed } = this.state;
    const { compatible: compatibleOs, checked: checkedOs } = os;
    const { compatible: compatibleBrowser, checked: checkedBrowser } = browser;
    const {
      compatibleCades,
      compatibleCsp,
      checked: checkedCryptopro,
    } = cryptopro;

    const disabled = checkPressed && this.isContinueDisabled();

    return (
      <div>
        <Header>
          <span>
            {isKZ ? (
              <img
                width={400}
                src={LogoKZ}
                alt={t('Национальный каталог товаров')}
              />
            ) : (
              <SvgLogo />
            )}
          </span>
          <StyledH1>{fnUp('Проверка готовности браузера')}</StyledH1>
        </Header>
        <Content>
          <SystemRequirementsView
            checkedBrowser={checkedBrowser}
            checkedCryptopro={checkedCryptopro}
            checkedOs={checkedOs}
            checkPressed={checkPressed}
            compatibleBrowser={compatibleBrowser}
            compatibleCades={compatibleCades}
            compatibleCsp={compatibleCsp}
            compatibleOs={compatibleOs}
            continueDisabled={disabled}
            onClick={this.onClick}
            onInstallPlugin={this.onInstallPlugin}
            onSelectBrowser={this.onSelectBrowser}
          />

          {this.state.sidebarOpen.pluginContent && (
            <SidebarPluginContent onClose={this.onInstallPlugin} />
          )}
          {this.state.sidebarOpen.browserContent && (
            <SidebarBrowserContent onClose={this.onSelectBrowser} />
          )}
        </Content>
        <Footer>
          <div
            dangerouslySetInnerHTML={{
              __html: `${_.replace(
                t(
                  'Государственная информационная система мониторинга за оборотом товаров, подлежащих обязательной маркировке средствами идентификации',
                ),
                '\n',
                '<br />',
              )}.`,
            }}
          />

          <br />
          <div
            dangerouslySetInnerHTML={{
              __html: `${t(
                'При возникновении проблем с регистрацией или авторизацией направьте письмо на email',
                {
                  email: `<a href="mailto:${config.supportEmail}">${config.supportEmail}</a>`,
                },
              )}.`,
            }}
          />
        </Footer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      goToPage: (page) => push(page),
      browserCheck: () => browserCheck(),
    },
    dispatch,
  );
};

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps),
)(BrowserCheck);
