import * as React from 'react';
import * as PropTypes from 'prop-types';
import { assign } from 'lodash';
import { AlertPopup } from './Alert.styled';
import { IconCloseSmall } from '../../common/icons/customIcons';

/**
 *  Пример использования:
 *   this.props.alert.error("К сожалению, ваш сертификат просрочен. Выберите другой.", {
                timeout: 2000 ,
                onOpen: () => { console.log('hey') }, // callback that will be executed after this alert open
                onClose: () => { console.log('closed') } // callback that will be executed after this alert is removed
            });
 */
class Alert extends React.Component {
  render() {
    let { style, options } = this.props;
    if (options.containerStyle) {
      if (options.containerStyle.backgroundColor) {
        assign(style, {
          background: options.containerStyle.backgroundColor,
          opacity: 0.5,
        });
      }
    }
    return (
      <AlertPopup style={style}>
        {this.props.message}
        {!options.deleteCloseButton && (
          <button onClick={this.props.close}>
            <IconCloseSmall />
          </button>
        )}
      </AlertPopup>
    );
  }
}

Alert.propTypes = {
  options: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  close: PropTypes.func.isRequired,
};

export default Alert;
