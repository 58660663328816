import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  defaultTo,
  find,
  includes,
  isEqual,
  isNull,
  map,
  reject,
  replace,
  trim,
} from 'lodash';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Popover,
  Select,
} from '@material-ui/core';
import {
  DefaultFilterPopover,
  defaultFilterSelect,
  FilterBtn,
  FilterBtns,
  FilterButton,
  FilterContent,
  FilterTitle,
} from './Filters.styled';
import {
  IconClose,
  IconLogoDropDown,
  IconFilterWhite,
  IconFilteredSquareOrange,
} from '../../../../common/icons/customIcons';
import { PackingTypes } from '../../../../common/constants/Statuses';

class PackingTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      packingPurpose: defaultTo(
        find(PackingTypes, { value: this.props.currentValue }),
        '',
      ),
      currentValue: this.props.currentValue,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (isNull(nextProps.currentValue) && !isNull(state.currentValue)) {
      return {
        anchorEl: null,
        packingPurpose: '',
        currentValue: nextProps.currentValue,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  onValueChange = (event) => {
    this.setState({ packingPurpose: event.target.value });
  };

  handleClick = (event) => {
    const element = document.getElementById(this.props.parentId);
    this.setState({ anchorEl: !!element ? element : event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccept = () => {
    const value = this.state.packingPurpose.value;
    this.setState({ anchorEl: null, currentValue: value });
    this.props.onAccept('packingPurpose', value);
  };

  handleDissmiss = () => {
    this.setState({ anchorEl: null, packingPurpose: '' });
    this.props.onDismiss('packingPurpose');
  };

  render() {
    const { t } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <MuiThemeProvider theme={FilterBtn}>
          <FilterButton>
            <Button
              aria-owns={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}>
              {isNull(this.props.currentValue) ? (
                <IconFilterWhite />
              ) : (
                <IconFilteredSquareOrange />
              )}
            </Button>
          </FilterButton>
        </MuiThemeProvider>

        <MuiThemeProvider theme={DefaultFilterPopover}>
          <Popover
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <FilterTitle>
              <h6>{t('Назначение упаковки')}</h6>
              <MuiThemeProvider theme={FilterBtn}>
                <Button className="backEmpty" onClick={this.handleClose}>
                  <IconClose />
                </Button>
              </MuiThemeProvider>
            </FilterTitle>

            <FilterContent>
              <MuiThemeProvider theme={defaultFilterSelect}>
                <FormControl fullWidth={true}>
                  {!this.state.packingPurpose && (
                    <InputLabel htmlFor="select-multiple-chip">
                      {t('Выберите упаковку')} …{' '}
                    </InputLabel>
                  )}
                  <Select
                    value={this.state.packingPurpose}
                    input={<Input id="select-multiple-chip" />}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    IconComponent={IconLogoDropDown}
                    onChange={this.onValueChange}>
                    {map(
                      reject(PackingTypes, (o) =>
                        includes(['published_notsigned'], o.value),
                      ),
                      (packingPurpose) => (
                        <MenuItem
                          key={packingPurpose.id}
                          value={packingPurpose}>
                          {t(
                            trim(
                              replace(packingPurpose.title, /\([^)]*\)/g, ''),
                            ),
                          )}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </MuiThemeProvider>
            </FilterContent>
            <FilterBtns>
              <Button
                disabled={!this.state.packingPurpose}
                onClick={this.handleAccept}>
                {t('Применить')}
              </Button>
              <Button
                disabled={!this.state.packingPurpose}
                className="backEmpty"
                onClick={this.handleDissmiss}>
                {t('Сбросить')}
              </Button>
            </FilterBtns>
          </Popover>
        </MuiThemeProvider>
      </div>
    );
  }
}

PackingTypeFilter.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  currentValue: PropTypes.string,
  parentId: PropTypes.string.isRequired,
};

export default withTranslation()(PackingTypeFilter);
