import styled from 'styled-components';
import { NotBlockMin } from '../Header/Header.styled';
import { createMuiTheme } from '@material-ui/core';

export const NotDialogBlockMin = styled(NotBlockMin)`
  padding: 10px 40px;
`;
export const NotDialogContent = styled.div`
  font-size: 12px;
  color: #63666a;
  font-weight: 600;
  line-height: 20px;
`;
export const NotDialogTitle = styled.h3`
  font-size: 20px;
  color: #52535a;
  font-weight: 600;
  margin: 0;
  transform: translateX(-85px);
  text-align: center;
`;
export const NotifMonth = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 8px;
  color: #fff;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 15px;
  top: calc(100% + 10px);
  z-index: 10;
  opacity: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 100px;
  text-align: center;
`;
export const NotNoData = styled.span`
  color: #ff4b4b;
`;
export const NotifLoader = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  top: 57px;
  margin: 0 auto;
  left: 0;
  border-radius: 0 0 100px 100px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const NotAllDialog = createMuiTheme({
  typography: {},
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minHeight: '568px',
        minWidth: '749px',
        maxWidth: '749px',
        boxShadow: '0 10px 60px rgba(41, 49, 53, .15);',
        borderRadius: '4px',
        padding: '0',
        boxSizing: 'border-box',
        position: 'relative',
      },
    },
    MuiTypography: {
      root: {
        width: '100%',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        '&:first-child': {
          paddingTop: '0',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '14px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F2F2F2',
        position: 'relative',
        zIndex: '9',
      },
    },
    MuiButton: {
      root: {
        background: 'none',
        borderRadius: '3px',
        minWidth: '30px',
        height: '30px',
        position: 'absolute',
        top: '15px',
        right: '15px',
      },
      text: {
        padding: '3px',
      },
      textPrimary: {
        background: 'none',
        color: '#4AA5E8',
        width: 'auto',
        padding: '0',
        height: '20px',
        fontSize: '13px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        transition: '.4s ease',
        position: 'static',
        '&:hover': {
          backgroundColor: 'transparent',
          color: '#0088ec',
        },
      },
    },
    MuiTouchRipple: {
      root: {
        display: 'none',
      },
    },
  },
});
