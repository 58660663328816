import { ActionCreator } from 'redux';
import {
  CLEAR_GS1_DATA_ACTION,
  CLEAR_GS1_INFO_ACTION,
  LOAD_GS1_DATA_ACTION,
  LOAD_GS1_DATA_FAILED_ACTION,
  LOAD_GS1_DATA_SUCCEEDED_ACTION,
  LOAD_GS1_INFO_ACTION,
  LOAD_GS1_INFO_FAILED_ACTION,
  LOAD_GS1_INFO_SUCCEEDED_ACTION,
  SAVE_GS1_ACTION,
  SAVE_GS1_ADDRESSES_ACTION,
  SAVE_GS1_ADDRESSES_FAILED_ACTION,
  SAVE_GS1_ADDRESSES_SUCCEEDED_ACTION,
  SAVE_GS1_APPLICANT_ACTION,
  SAVE_GS1_APPLICANT_FAILED_ACTION,
  SAVE_GS1_APPLICANT_SUCCEEDED_ACTION,
  SAVE_GS1_BANKINGDETAILS_ACTION,
  SAVE_GS1_BANKINGDETAILS_FAILED_ACTION,
  SAVE_GS1_BANKINGDETAILS_SUCCEEDED_ACTION,
  SAVE_GS1_COMPANYINFORMATION_ACTION,
  SAVE_GS1_COMPANYINFORMATION_FAILED_ACTION,
  SAVE_GS1_COMPANYINFORMATION_SUCCEEDED_ACTION,
  SAVE_GS1_CONTACTS_ACTION,
  SAVE_GS1_CONTACTS_FAILED_ACTION,
  SAVE_GS1_CONTACTS_SUCCEEDED_ACTION,
  SAVE_GS1_FAILED_ACTION,
  SAVE_GS1_SUCCEEDED_ACTION,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED,
  LOAD_GS1_OPF_DICTIONARY_ACTION,
  LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED,
  LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED,
} from './GS1ActionTypes.constants';

/**
 * TODO:
 * отрефакторить и прибраться.
 */

// LOAD

export const loadGS1Info: ActionCreator = () => ({
  type: LOAD_GS1_INFO_ACTION,
});

export const loadGS1InfoSucceded: ActionCreator = (info) => ({
  type: LOAD_GS1_INFO_SUCCEEDED_ACTION,
  info: info,
});

export const loadGS1InfoFailed: ActionCreator = () => ({
  type: LOAD_GS1_INFO_FAILED_ACTION,
});

export const loadGS1Data: ActionCreator = () => ({
  type: LOAD_GS1_DATA_ACTION,
});

export const loadGS1DataSucceded: ActionCreator = (data) => ({
  type: LOAD_GS1_DATA_SUCCEEDED_ACTION,
  data: data,
});

export const loadGS1DataFailed: ActionCreator = () => ({
  type: LOAD_GS1_DATA_FAILED_ACTION,
});

// SAVE

export const saveGS1Applicant: ActionCreator = (data, applicant, callback) => ({
  type: SAVE_GS1_APPLICANT_ACTION,
  data: data,
  applicant: applicant.applicant,
  callback: callback,
});

export const saveGS1ApplicantSucceded: ActionCreator = (applicant) => ({
  type: SAVE_GS1_APPLICANT_SUCCEEDED_ACTION,
  applicant: applicant,
});

export const saveGS1ApplicantFailed: ActionCreator = () => ({
  type: SAVE_GS1_APPLICANT_FAILED_ACTION,
});

export const saveGS1BankingDetails: ActionCreator = (
  data,
  bankingDetails,
  callback,
) => ({
  type: SAVE_GS1_BANKINGDETAILS_ACTION,
  data: data,
  bankDetails: bankingDetails,
  callback: callback,
});

export const saveGS1BankingDetailsSucceded: ActionCreator = (
  bankingDetails,
) => ({
  type: SAVE_GS1_BANKINGDETAILS_SUCCEEDED_ACTION,
  bankDetails: bankingDetails,
});

export const saveGS1BankingDetailsFailed: ActionCreator = () => ({
  type: SAVE_GS1_BANKINGDETAILS_FAILED_ACTION,
});

export const saveGS1Addresses: ActionCreator = (
  data,
  convertedAddresses,
  originAddresses,
  callback,
) => ({
  type: SAVE_GS1_ADDRESSES_ACTION,
  data: data,
  addresses: convertedAddresses,
  originAddresses: originAddresses,
  callback: callback,
});

export const saveGS1AddressesSucceded: ActionCreator = (
  convertedAddresses,
  originAddresses,
) => ({
  type: SAVE_GS1_ADDRESSES_SUCCEEDED_ACTION,
  addresses: convertedAddresses,
  originAddresses: originAddresses,
});

export const saveGS1AddressesFailed: ActionCreator = () => ({
  type: SAVE_GS1_ADDRESSES_FAILED_ACTION,
});

export const saveGS1CompanyInformation: ActionCreator = (
  data,
  companyDetails,
  callback,
) => ({
  type: SAVE_GS1_COMPANYINFORMATION_ACTION,
  data: data,
  companyDetails: companyDetails,
  callback: callback,
});

export const saveGS1CompanyInformationSucceded: ActionCreator = (
  companyDetails,
) => ({
  type: SAVE_GS1_COMPANYINFORMATION_SUCCEEDED_ACTION,
  companyDetails: companyDetails,
});

export const saveGS1CompanyInformationFailed: ActionCreator = () => ({
  type: SAVE_GS1_COMPANYINFORMATION_FAILED_ACTION,
});

export const saveGS1Contacts: ActionCreator = (data, contacts, callback) => ({
  type: SAVE_GS1_CONTACTS_ACTION,
  data: data,
  contacts: contacts,
  callback: callback,
});

export const saveGS1ContactsSucceded: ActionCreator = (contacts) => ({
  type: SAVE_GS1_CONTACTS_SUCCEEDED_ACTION,
  contacts: contacts,
});

export const saveGS1ContactsFailed: ActionCreator = () => ({
  type: SAVE_GS1_CONTACTS_FAILED_ACTION,
});

export const saveGS1: ActionCreator = (data, certificate, callback) => ({
  type: SAVE_GS1_ACTION,
  data: data,
  certificate: certificate,
  callback: callback,
});

export const saveGS1Succeded: ActionCreator = () => ({
  type: SAVE_GS1_SUCCEEDED_ACTION,
});

export const saveGS1Failed: ActionCreator = (errors) => ({
  type: SAVE_GS1_FAILED_ACTION,
  errors: errors,
});

export const clearGS1Data: ActionCreator = () => ({
  type: CLEAR_GS1_DATA_ACTION,
});

export const clearGS1Info: ActionCreator = () => ({
  type: CLEAR_GS1_INFO_ACTION,
});

export const loadActivityTypes: ActionCreator = () => ({
  type: LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION,
});

export const loadActivityTypesSucceeded: ActionCreator = (data) => ({
  type: LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadActivityTypesFailed: ActionCreator = () => ({
  type: LOAD_ACTIVITY_TYPES_DICTIONARY_ACTION_FAILED,
});

export const loadCompanyTypes: ActionCreator = () => ({
  type: LOAD_COMPANY_TYPES_DICTIONARY_ACTION,
});

export const loadCompanyTypesSucceeded: ActionCreator = (data) => ({
  type: LOAD_COMPANY_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadCompanyTypesFailed: ActionCreator = () => ({
  type: LOAD_COMPANY_TYPES_DICTIONARY_ACTION_FAILED,
});

export const loadMainProductTypes: ActionCreator = () => ({
  type: LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION,
});

export const loadMainProductTypesSucceeded: ActionCreator = (data) => ({
  type: LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_SUCCEEDED,
  data,
});

export const loadMainProductTypesFailed: ActionCreator = () => ({
  type: LOAD_MAIN_PRODUCT_TYPES_DICTIONARY_ACTION_FAILED,
});

export const loadGS1OpfDictionary: ActionCreator = () => ({
  type: LOAD_GS1_OPF_DICTIONARY_ACTION,
});

export const loadGS1OpfDictionarySucceeded: ActionCreator = (data) => ({
  type: LOAD_GS1_OPF_DICTIONARY_ACTION_SUCCEEDED,
  data,
});
export const loadGS1OpfDictionaryFailed: ActionCreator = () => ({
  type: LOAD_GS1_OPF_DICTIONARY_ACTION_FAILED,
});
