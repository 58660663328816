import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, MuiThemeProvider } from '@material-ui/core';
import { IconMenu } from '../../../../../common/icons/customIcons';
import { GridActionsSelect } from '../../../../products/views/ProductsGridActions/ProductsGridActionSelect.styled';
import { ButtonActionCont } from '../../../../../common/styles/Common.styled';

class GridActionsItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.state, nextState);
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      onDelete,
      original,
      t,
      onOpenPreview,
      onEdit,
      onFileUpload,
    } = this.props;
    const actions = [
      { id: 0, label: t('Просмотр'), onClick: onOpenPreview, disabled: false },
      { id: 1, label: t('Удалить'), onClick: onDelete, disabled: false },
      { id: 2, label: t('Редактировать'), onClick: onEdit, disabled: false },
      {
        id: 3,
        label: t('Загрузить документы и изображения'),
        onClick: onFileUpload,
        disabled: false,
      },
    ];

    return (
      <div>
        <ButtonActionCont>
          <Button
            onClick={this.handleClick}
            aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true">
            <IconMenu />
          </Button>
        </ButtonActionCont>

        <MuiThemeProvider theme={GridActionsSelect}>
          <Menu
            open={!!this.state.anchorEl}
            anchorEl={this.state.anchorEl}
            onClose={this.handleClose}
            id="simple-menu"
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {_.map(actions, (action) => (
              <MenuItem
                key={`m_${original.id}_${action.id}`}
                disableGutters={true}
                onClick={this.handleClose}
                disabled={action.disabled}>
                <Button onClick={() => action.onClick(original)}>
                  {t(action.label)}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </MuiThemeProvider>
      </div>
    );
  }
}

GridActionsItems.propTypes = {
  onDelete: PropTypes.func.isRequired,
  original: PropTypes.object.isRequired,
};

export default withTranslation()(GridActionsItems);
