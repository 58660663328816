import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core';
import { SubAccount } from '../index';
import { SmallTabsNew, SubAccountsCont } from './SubAccounts.styled';

class SubAccounts extends React.Component {
  constructor(props) {
    super(props);
    // this.accountType = _.get(this.props, 'user.accountType');// было до 338 задачи: роль-строка, теперь массив
    this.accountTypes = _.cloneDeep(_.get(this.props, 'user.accountTypes'));
    // this.disableSubAccount = _.includes(['net', 'csp'], this.accountType);
    this.disableSubAccount = _.includes(this.accountTypes, ('net' || 'csp'));
    this.state = {
      value: _.toNumber(this.disableSubAccount),
    };
  }

  handleChangeTab = (event, value) => this.setState({ value });

  render() {
    const { loader, t } = this.props;
    const fnUp = _.flow([t, _.upperCase]);

    return (
      <SubAccountsCont>
        <MuiThemeProvider theme={SmallTabsNew}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            variant="fullWidth">
            {!this.disableSubAccount && (
              <Tab
                disabled={loader}
                value={0}
                label={fnUp('Привязанные компании')}
              />
            )}
            <Tab
              disabled={loader}
              value={1}
              label={fnUp('Родительские компании')}
            />
          </Tabs>
        </MuiThemeProvider>
        {this.state.value === 0 && <SubAccount />}
        {/* {this.state.value === 1 && <ParentCompany/>} */}
      </SubAccountsCont>
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.gs1Reducer.loader,
  user: state.authentificationReducer.user,
  profile: state.profileReducer.info,
});

SubAccounts.propTypes = {
  loader: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(SubAccounts);
