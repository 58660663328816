import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Element } from 'react-scroll';
import { MediaItem } from './itemElement';
import MediaContentView from './MediaContentView';
import { Button, MuiThemeProvider } from '@material-ui/core';
import _ from 'lodash';
import { ProfileDivider } from '../../profile/Profile.styled';
import { IconDropDownGrey, IconPlus } from '../../../common/icons/customIcons';
import {
  AddressButton,
  AdresFields,
  AdresTitle,
  AdresTitleH3,
  CompanyBlock,
  PackageTitleHolder,
  ProductTabBtn,
} from '../../../common/styles/Common.styled';

const _getErrorsAdapter = (moderateErrors, images, flagField) => {
  let errors = {};
  const imageErrors = _.filter(moderateErrors, { type: 'image' });
  if (!imageErrors) return errors;
  _.forEach(imageErrors, (item) => {
    const { type, gtin, url, comment } = item.data;
    const image = _.find(images, { type, gtin, url });
    if (!!image) {
      errors[image[flagField]] = comment;
    }
  });
  return errors;
};

const getErrors = (...args) => _getErrorsAdapter(...args, 'id');
const getPreviewErrors = (...args) => _getErrorsAdapter(...args, 'url');

class MediaContent extends React.Component {
  constructor(props) {
    super(props);
    const images = this.initialImages(this.props.productData.images);

    this.state = {
      data: {
        images: images,
      },
      errors:
        _.isNil(this.props.moderateErrors) ||
          _.isEmpty(this.props.productData.images)
          ? {}
          : getErrors(this.props.moderateErrors, images),
      contentShow: true,
    };

    this.checkRequiredFields(this.state.data.images);
    this.props.updateData('images', this.state.data.images);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (_.isEmpty(prevState.errors) && !_.isEmpty(nextProps.moderateErrors)) {
      return {
        errors: getErrors(nextProps.moderateErrors, prevState.data.images),
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    const s = _.size(this.props.packaging) < _.size(prevProps.packaging);
    // проверка на то изменился ли какой-либо GTIN из упаковки?
    const equal = _.isEqualWith(
      this.props.packaging,
      prevProps.packaging,
      (obj, other) => {
        return _.reduce(
          obj,
          (sum, value, key) => {
            return sum || value.gtin !== _.get(other, `${[key]}.gtin`);
          },
          false,
        );
      },
    );

    let images = this.state.data.images;
    if (s || equal) {
      images = this.initialImages(images);
      this.setState((prevState) => ({ data: { ...prevState.data, images } }));
    }
    this.checkRequiredFields(images);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const equal = [!_.isEqual(this.state, nextState)];
    const nameProps = ['packaging', 'preview', 'locale', 'moderateErrors'];
    _.forEach(nameProps, (i) =>
      equal.push(!_.isEqual(this.props[i], nextProps[i])),
    );
    return _.some(equal);
  }

  generateImages = (images, packaging) => {
    const gtinTradeUnit = _.defaultTo(
      _.find(packaging, { level: 'trade-unit' }),
      { gtin: '' },
    ).gtin;

    // костыли
    const { type } = this.props;
    const isNotConsumer = _.includes(['group', 'transport'], type);
    const isGroup = type === 'group';
    const gtinInnerPack = _.defaultTo(
      _.find(packaging, { level: 'inner-pack' }),
      { gtin: '' },
    ).gtin;
    const gtinBoxPack = _.defaultTo(_.find(packaging, { level: 'box' }), {
      gtin: '',
    }).gtin;

    return _.map(images, (image) => {
      const gtin = image.gtin;
      const packageMedia = _.defaultTo(_.find(packaging, { gtin: gtin }), {
        gtin: isNotConsumer
          ? isGroup
            ? gtinInnerPack
            : gtinBoxPack
          : gtinTradeUnit,
      });
      return {
        id: image.id || _.uniqueId('imagesId_'),
        type: image.type,
        url: image.url,
        gtin: packageMedia.gtin,
        disabled: !!image.disabled,
        hasWatermark: image.hasWatermark,
      };
    });
  };

  /**
   * EACNC-256
   *
   * @param {*} isFirst флаг, который отвечает за выставляемый type. В случае, если инициализируется начальное поле, то передаётся true и всегда ставится
   *      лицевая сторона "A1N1". Все последующие поля добавленные в ручную не должны иметь типа(приравниваем к пустой строке)
   */
  getInitItem = (packaging, isFirst) => {
    const gtinTradeUnit = _.defaultTo(
      _.find(packaging, { level: 'trade-unit' }),
      { gtin: '' },
    ).gtin;

    // костыли
    const { type } = this.props;
    const isNotConsumer = _.includes(['group', 'transport'], type);
    const isGroup = type === 'group';
    const gtinInnerPack = _.defaultTo(
      _.find(packaging, { level: 'inner-pack' }),
      { gtin: '' },
    ).gtin;
    const gtinBoxPack = _.defaultTo(_.find(packaging, { level: 'box' }), {
      gtin: '',
    }).gtin;
    const imageType = isFirst ? 'A1N1' : '';

    return {
      id: _.uniqueId('imagesId_'),
      type: imageType,
      gtin: isNotConsumer
        ? isGroup
          ? gtinInnerPack
          : gtinBoxPack
        : gtinTradeUnit,
      url: '',
      disabled: false,
    };
  };

  initialImages = (images) => {
    return _.isEmpty(images)
      ? [this.getInitItem(this.props.packaging, true)]
      : this.generateImages(images, this.props.packaging);
  };

  onClickHide = () => {
    this.setState({ contentShow: !this.state.contentShow });
  };

  addItem = () => {
    const images = _.cloneDeep(this.state.data.images);
    images.push(this.getInitItem(this.props.packaging, false));
    this.setState({ data: { images } }, this.updateData);
  };

  deleteItem = (id) => {
    const images = _.reject(this.state.data.images, { id: id });
    this.setState({ data: { images } }, this.updateData);
  };

  updateItem = (id, fieldData) => {
    let images = _.cloneDeep(this.state.data.images);
    _.forEach(images, (v, k) => {
      if (v.id === id) images[k] = _.assign({}, v, fieldData);
    });
    this.setState({ data: { images } }, this.updateData);
  };

  updateData = () => this.props.updateData('images', this.state.data.images);

  checkRequiredFields = (images) => {
    let errors = {};
    _.forEach(images, (item) => {
      const error = _.isEmpty(item.gtin) && !!item.url;
      if (error) {
        errors[item.id] = error;
      }
    });
    this.props.handleErrors('images', errors, null);
  };

  render() {
    const { t, type } = this.props;
    const countItems = _.size(this.state.data.images);
    const canAddNew = _.every(
      this.state.data.images,
      (item) => !_.isEmpty(item.type) && !!item.url,
    );

    return (
      <CompanyBlock>
        <AdresTitle>
          <PackageTitleHolder>
            <MuiThemeProvider theme={ProductTabBtn}>
              <Button onClick={this.onClickHide}>
                {this.state.contentShow ? (
                  <IconDropDownGrey />
                ) : (
                  <IconDropDownGrey style={{ transform: 'rotate(180deg)' }} />
                )}
              </Button>
              <AdresTitleH3>{t('Фотоконтент')}</AdresTitleH3>
            </MuiThemeProvider>
          </PackageTitleHolder>
        </AdresTitle>
        <AdresFields>
          {this.state.contentShow && (
            <React.Fragment>
              {this.props.preview ? (
                <MediaContentView
                  images={this.props.productData.images}
                  identifier={this.props.productData.identifier}
                  errors={getPreviewErrors(
                    this.props.moderateErrors,
                    this.props.productData.images,
                  )}
                />
              ) : (
                <React.Fragment>
                  {_.map(this.state.data.images, (value, index) => (
                    <Element key={value.id} name={`id_${value.id}`}>
                      <MediaItem
                        allowDelete={countItems > 1 && index > 0}
                        key={value.id}
                        data={value}
                        updateItem={this.updateItem}
                        deleteItem={this.deleteItem}
                        packaging={this.props.packaging}
                        error={this.state.errors[value.id]}
                        status={this.props.productData.status}
                        disableImg={value.disabled}
                        type={type}
                        images={this.state.data.images}
                        isCatalogCard={this.props.isCatalogCard}
                      />
                    </Element>
                  ))}
                  <ProfileDivider />
                  <MuiThemeProvider theme={AddressButton}>
                    <Button
                      color={'secondary'}
                      onClick={this.addItem}
                      disabled={!canAddNew || this.props.isCatalogCard}>
                      <IconPlus style={{ marginRight: '15px' }} />
                      {t('Добавить')}
                    </Button>
                  </MuiThemeProvider>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </AdresFields>
      </CompanyBlock>
    );
  }
}

const mapStateToProps = (state) => ({
  productData: state.productReducer.data,
  moderateErrors: state.productReducer.moderateErrors,
  locale: state.profileReducer.locale,
});

MediaContent.propTypes = {
  handleErrors: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  productData: PropTypes.object.isRequired,
  moderateErrors: PropTypes.array.isRequired,
  packaging: PropTypes.array.isRequired,
  preview: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps),
)(MediaContent);
