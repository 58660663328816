export const loading = (state) => state.authentificationReducer.loading;
export const locale = (state) => state.profileReducer.locale;
export const isActiveSessionOpenModal = (state) =>
  state.authentificationReducer.isActiveSessionOpenModal;
export const activeSessionInfo = (state) =>
  state.authentificationReducer.activeSessionInfo;
export const isActiveElkSessionOpenModal = (state) =>
  state.authentificationReducer.isActiveElkSessionOpenModal;
export const activeElkSessionInfo = (state) =>
  state.authentificationReducer.activeElkSessionInfo;  
