import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';

export const CatalogGridContainer = styled.div`
  padding: 20px;
  background: #fcfcfc;
  position: relative;
  

  @media (max-width: 1919px) {
    height: calc(100% - 70px);
  }

  @media (min-width: 600px) {
    height: calc(100% - 64px);
  }
`;

export const GridActionsView = styled.div`
  padding: 0 30px 0 0;
  flex-shrink: 0;
  @media (max-width: 1399px) {
    padding: 0 10px 0 0;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    padding: 0 25px 0 0;
  }
  display: flex;
  justify-content: space-between;
  //min-width: 300px
`;

export const ResetFilterButton = createMuiTheme({
    typography: {},
    overrides: {
      MuiButton: {
        label: {
          color: '#868686',
          fontSize: '14px',
          fontWeight: 'bold',
          //textTransform: "uppercase"
        },
      },
    },
});

export const GridTable = styled.div`
  box-shadow: 0px 1px 8px rgba(41, 49, 53, 0.1);
  border-radius: 4px;
`;

export const ColumnCell = styled.div`
  position: relative;
  font-size: 14px;
  color: #52535a;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
`;

export const ColumnCellProfile = styled(ColumnCell)`
  font-size: 12px;
  color: #63666a;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
`;

export const PointerName = styled.span`
  cursor: pointer;
  color: #4AA5E8;
  white-space: nowrap;
  width: -webkit-fill-available;
  transition: 0.4s ease;
  &:hover {
    color: #63666a;
  }
`;

export const ColumnCellPhoto = styled(ColumnCell)`
  padding: 10px;
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: #fff;
  }
  img {
    transition: transform 0.4s ease;
    &:hover {
      transform: scale(1.5);
    }
  }
`;

export const ColumnCellStatus = styled.div`
  position: relative;
  font-size: 14px;
  color: #52535a;
  width: 100%;
  height: 100%;
`;

export const ColumnHeaderDate = styled(ColumnCell)`
  padding: 10px;
`;

export const ColumnHeaderPackage = styled(ColumnCell)`
  padding: 10px;
`;

export const PackageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const PackageLine = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.9;
  transition: color 0.4s ease;
  &:hover {
    color: #000;
  }
  span {
    padding-left: 15px;
  }
`;

export const NameCount = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  span {
    padding-left: 2px;
    display: flex;
    align-items: center;
  }
`;

export const VersionBlock = styled.div`
  cursor: pointer;
  font-size: 10px;
  color: #b8b8b8;
  font-weight: bold;
  display: flex;
  margin-left: 15px;
  span {
    padding-left: 4px;
  }
`;