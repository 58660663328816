import * as React from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import lottie from 'lottie-web';
import { animationData } from './Preloader.constans';
import { Text, WrapImg, WrapPreloader } from './Preloader.styled';

class Preloader extends React.Component {
  constructor(props) {
    super(props);
    this.itemUid = _.uniqueId('lottie_');
  }

  componentDidMount() {
    const params = {
      container: document.getElementById(this.itemUid),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };
    lottie.loadAnimation(params);
  }

  render() {
    const { isOpen, text, fullHeight } = this.props;
    return (
      <WrapPreloader isOpen={isOpen} fullHeight={fullHeight}>
        <WrapImg id={this.itemUid} />
        <Text>{text}</Text>
      </WrapPreloader>
    );
  }
}

Preloader.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  fullHeight: PropTypes.bool,
};

Preloader.defaultProps = {
  isOpen: false,
  text: 'Загрузка',
  fullHeight: true,
};

export default Preloader;
