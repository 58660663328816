import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MuiThemeProvider, Typography } from '@material-ui/core';
import {
  BrowserRequirements,
  CryptographyPluginRequirements,
} from './SystemRequirements.constants';
import {
  Block,
  BlockInfo,
  BlockTitle,
  BrowserCheckContent,
  BrowserCheckHeader,
  BrowserCheckLine,
  BrowserCheckRow,
  IconContainer,
} from './SystemRequirements.styled';
import { CheckMark, Error } from '../../common/icons/customIcons';
import {
  DefaultGridButton,
  LoginFormContAuth,
} from '../../common/styles/Common.styled';
import Button from '@material-ui/core/Button/Button';

class SystemRequirementsView extends React.Component {
  render() {
    const {
      checkedBrowser,
      checkedCryptopro,
      checkedOs,
      checkPressed,
      compatibleBrowser,
      compatibleCades,
      compatibleCsp,
      compatibleOs,
      continueDisabled,
      onClick,
      onInstallPlugin,
      onSelectBrowser,
      t,
    } = this.props;

    return (
      <LoginFormContAuth>
        <BrowserCheckHeader>
          <Typography variant="body1" align="center">
            {t(
              'Для работы в личном кабинете Вам понадобится электронная подпись и программы, обеспечивающие защиту информации',
            )}
          </Typography>
        </BrowserCheckHeader>
        <BrowserCheckContent>
          <BrowserCheckRow>
            <Block>
              <BrowserCheckLine>
                <IconContainer
                  className={
                    compatibleOs ? 'valid' : checkedOs ? 'inValid' : ''
                  }
                  valid={compatibleOs}
                  checked={checkedOs}>
                  {compatibleOs ? <CheckMark /> : checkedOs ? <Error /> : '1'}
                </IconContainer>
              </BrowserCheckLine>

              <BlockTitle>{t('Операционная система')}</BlockTitle>
              <BlockInfo error={!compatibleOs && checkedOs}>
                {`ОС Windows 7 ${t('или новее')} / Mac OS X 10.8 ${t(
                  'или новее',
                )}`}
              </BlockInfo>
              <BlockInfo>
                {checkPressed && !checkedOs && `${t('Идет определение')} ...`}
              </BlockInfo>
            </Block>

            <Block error={!compatibleBrowser && checkedBrowser}>
              <BrowserCheckLine>
                <IconContainer
                  className={
                    compatibleBrowser
                      ? 'valid'
                      : checkedBrowser
                      ? 'inValid'
                      : ''
                  }
                  valid={compatibleBrowser}
                  checked={checkedBrowser}>
                  {compatibleBrowser ? (
                    <CheckMark />
                  ) : checkedBrowser ? (
                    <Error />
                  ) : (
                    '2'
                  )}
                </IconContainer>

                {!compatibleBrowser && checkedBrowser && (
                  <MuiThemeProvider theme={DefaultGridButton}>
                    <Button onClick={onSelectBrowser}>
                      {t('Выбрать браузер')}
                    </Button>
                  </MuiThemeProvider>
                )}
              </BrowserCheckLine>

              <BlockTitle>{t('Браузер')}</BlockTitle>
              <BlockInfo error={!compatibleBrowser && checkedBrowser}>
                {!compatibleBrowser && checkedBrowser ? (
                  <React.Fragment>
                    {t(
                      'Вы используете браузер, который не поддерживает шифрование защищенных соединений',
                    )}
                    . &nbsp;
                    {t(
                      'Пожалуйста, обновите ваш браузер для регистрации в системе',
                    )}
                    .
                  </React.Fragment>
                ) : (
                  <BrowserRequirements />
                )}
              </BlockInfo>
              <BlockInfo>
                {checkPressed &&
                  !checkedBrowser &&
                  `${t('Идет определение')} ...`}
              </BlockInfo>
            </Block>
          </BrowserCheckRow>
          <BrowserCheckRow>
            <Block error={!compatibleCades && checkedCryptopro}>
              <BrowserCheckLine>
                <IconContainer
                  className={
                    compatibleCades
                      ? 'valid'
                      : checkedCryptopro
                      ? 'inValid'
                      : ''
                  }
                  valid={compatibleCades}
                  checked={checkedCryptopro}>
                  {compatibleCades ? (
                    <CheckMark />
                  ) : checkedCryptopro ? (
                    <Error />
                  ) : (
                    '3'
                  )}
                </IconContainer>

                {!compatibleCades && checkedCryptopro && (
                  <MuiThemeProvider theme={DefaultGridButton}>
                    <Button onClick={onInstallPlugin}>
                      {t('Установить плагин')}
                    </Button>
                  </MuiThemeProvider>
                )}
              </BrowserCheckLine>

              <BlockTitle>{t('Криптографический плагин')}</BlockTitle>
              <BlockInfo error={!compatibleCades && checkedCryptopro}>
                {!compatibleCades && checkedCryptopro ? (
                  <React.Fragment>
                    {t(
                      'У вас не установлен плагин КриптоПро или Рутокен для браузера',
                    )}
                    . &nbsp;
                    {t(
                      'Для регистрации в личном кабинете и использовании КЭП вы должны установить плагин',
                    )}
                  </React.Fragment>
                ) : (
                  <CryptographyPluginRequirements />
                )}
              </BlockInfo>
              <BlockInfo>
                {checkPressed &&
                  !checkedCryptopro &&
                  `${t('Идет определение')} ...`}
              </BlockInfo>
            </Block>

            <Block>
              <BrowserCheckLine>
                <IconContainer
                  className={
                    compatibleCsp ? 'valid' : checkedCryptopro ? 'inValid' : ''
                  }
                  valid={compatibleCsp}
                  checked={checkedCryptopro}>
                  {compatibleCsp ? (
                    <CheckMark />
                  ) : checkedCryptopro ? (
                    <Error />
                  ) : (
                    '4'
                  )}
                </IconContainer>
              </BrowserCheckLine>

              <BlockTitle>{t('Криптографическое ПО')}</BlockTitle>
              <BlockInfo error={!compatibleCsp && checkedCryptopro}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'На вашем компьютере должна быть установлена программа КриптоПро или Рутокен или VipNet',
                      {
                        cryptopro: `<a rel="noopener noreferrer" target="_blank" href='https://www.cryptopro.ru'>${t(
                          'КриптоПро',
                        )}</a>`,
                        cryptoproVersion: '3.6.7',
                        rutoken: `<a rel="noopener noreferrer" target="_blank" href='https://www.rutoken.ru'>${t(
                          'Рутокен',
                        )}</a>`,
                        vipnet: `<a rel="noopener noreferrer" target="_blank" href='https://infotecs.ru/downloads/besplatnye-produkty'>${t(
                          'VipNet',
                        )}</a>`,
                      },
                    ),
                  }}
                />
              </BlockInfo>
              <BlockInfo>
                {checkPressed &&
                  !checkedCryptopro &&
                  `${t('Идет определение')} ...`}
              </BlockInfo>
            </Block>
          </BrowserCheckRow>

          <Button onClick={onClick} disabled={continueDisabled}>
            {checkPressed ? t('Продолжить') : t('Проверить')}
          </Button>
        </BrowserCheckContent>
      </LoginFormContAuth>
    );
  }
}

SystemRequirementsView.propTypes = {
  onClick: PropTypes.func.isRequired,
  onInstallPlugin: PropTypes.func.isRequired,
  onSelectBrowser: PropTypes.func.isRequired,
  checkPressed: PropTypes.bool,
  checkedBrowser: PropTypes.bool,
  checkedCryptopro: PropTypes.bool,
  checkedOs: PropTypes.bool,
  compatibleBrowser: PropTypes.bool,
  compatibleCades: PropTypes.bool,
  compatibleCsp: PropTypes.bool,
  compatibleOs: PropTypes.bool,
  continueDisabled: PropTypes.bool,
};

export default withTranslation()(SystemRequirementsView);
