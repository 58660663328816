import * as React from 'react';
import * as PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from '@material-ui/core';
import { Autocomplete } from '../../../../../components';
import { ProfileDivider } from '../../../Profile.styled';
import { IconLogoDropDown } from '../../../../../common/icons/customIcons';

import {
  loadDictionaryGcpProducts,
  loadDictionaryObtainingMethods,
  loadDictionarySectors,
} from '../../../../../common/catalog/Catalog.action';
import {
  GridBlockA,
  GridItemA,
  GridItemB,
  ProfileAutocomplete,
} from '../../../../../common/styles/Common.styled';
import { activityTypes } from '../../../../../common/constants/Constants';

function renderSuggestion(suggestion, { query, isHighlighted }) {
  return (
    <MenuItem disableGutters={true} selected={isHighlighted} component="div">
      {suggestion.text}
    </MenuItem>
  );
}

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // удалить потом.
      suggestions: this.props.mainProductGCPs,
    };
  }

  componentDidMount() {
    if (_.isEmpty(this.props.mainProductGCPs)) {
      this.props.loadProductsByGPC();
    }
    if (_.isEmpty(this.props.enterpriseSectors)) {
      this.props.loadSectors();
    }
    if (_.isEmpty(this.props.obtainingDocumentsMethods)) {
      this.props.loadMethodOfObtaining();
    }
  }

  handleChange = (name, value) => {
    const { handleStateChange } = this.props;
    switch (name) {
      case 'enterpriseSector':
      case 'obtainingDocumentsMethod':
      case 'mainProductGCP':
      case 'accountType': {
        handleStateChange(
          'companyDetails',
          name,
          _.toString(value),
          !!!_.defaultTo(value, ''),
        );
        break;
      }
      default:
        break;
    }
  };

  handleSuggestionsFetchRequested = (name, value) => {
    const inputValue = _.toLower(_.deburr(_.trim(value)));
    if (inputValue.length === 0) {
      this.setState((prevState) => ({
        data: { ...prevState.data, mainProductGCP: '' },
        suggestions: this.props.mainProductGCPs,
      }));
    } else {
      let suggestions = _.filter(this.props.mainProductGCPs, (suggestion) => {
        return suggestion.text.toLowerCase().includes(inputValue);
      });
      this.setState({ suggestions: suggestions });
    }
  };

  getSuggestionValue = (value, name) => {
    this.handleChange(name, value.id);
    return value.text;
  };

  render() {
    const { t } = this.props;
    const {
      accountType,
      enterpriseSector,
      mainProductGCP,
      obtainingDocumentsMethod,
    } = this.props.companyDetails;
    // const mainProductGCP = defaultTo(find(this.props.mainProductGCPs, { id: this.state.data.mainProductGCP }), { text: '' });

    return (
      <React.Fragment>
        <h1>Данные о предприятии</h1>
        <React.Fragment>
          <GridBlockA>
            <GridItemA>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="activity-select">
                  {t('Вид деятельности')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(!!_.defaultTo(accountType, ''))}
                  value={_.defaultTo(accountType, '')}
                  inputProps={{ id: 'activity-select' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange('accountType', event.target.value)
                  }>
                  {_.map(activityTypes, (type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {t(type.text)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItemA>
            <GridItemB>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="select-obtainingDocumentsMethod">
                  {t('Способ получения документов')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(
                    !!_.defaultTo(obtainingDocumentsMethod, ''),
                  )}
                  value={_.defaultTo(obtainingDocumentsMethod, '')}
                  inputProps={{ id: 'select-obtainingDocumentsMethod' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange(
                      'obtainingDocumentsMethod',
                      event.target.value,
                    )
                  }>
                  {_.map(this.props.obtainingDocumentsMethods, (p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItemB>
          </GridBlockA>
          <GridBlockA>
            <GridItemA>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor="select-multiple-enterpriseSector">
                  {t('Сектор предприятия')}
                </InputLabel>
                <Select
                  required
                  valid={_.toString(!!_.defaultTo(enterpriseSector, ''))}
                  value={_.defaultTo(enterpriseSector, '')}
                  inputProps={{ id: 'select-multiple-enterpriseSector' }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                  IconComponent={IconLogoDropDown}
                  onChange={(event) =>
                    this.handleChange('enterpriseSector', event.target.value)
                  }>
                  {_.map(this.props.enterpriseSectors, (p) => (
                    <MenuItem key={p.id} value={p.id}>
                      {p.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItemA>
            <GridItemB>
              <FormControl fullWidth={true}>
                <MuiThemeProvider theme={ProfileAutocomplete}>
                  <Autocomplete
                    name="mainProductGCP"
                    suggestions={this.state.suggestions.slice(0, 200)}
                    placeholder={t('Основная продукция по GPC')}
                    required
                    valid={!!mainProductGCP} // ???????
                    handleSuggestionsFetchRequested={(value) =>
                      this.handleSuggestionsFetchRequested(
                        'mainProductGCP',
                        value,
                      )
                    }
                    handleSuggestionsClearRequested={() =>
                      this.handleSuggestionsFetchRequested('mainProductGCP')
                    }
                    renderSuggestion={renderSuggestion}
                    getSuggestionValue={(value) =>
                      this.getSuggestionValue(value, 'mainProductGCP')
                    }
                    initValue={mainProductGCP} // ???????
                    forceSetValue={!_.isEmpty(mainProductGCP)}
                  />
                </MuiThemeProvider>
              </FormControl>
            </GridItemB>
          </GridBlockA>
        </React.Fragment>
        <ProfileDivider />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  // accountTypes: state.catalogReducer.dictionaryOrgProfiles,
  mainProductGCPs: state.catalogReducer.dictionaryGcpProducts,
  // knownFroms: state.catalogReducer.dictionaryKnowledge,
  // partisipationInGovernmentProjects: state.catalogReducer.dictionaryProjects,
  // implementationMethods: state.catalogReducer.dictionarySales,
  // distributionChannels: state.catalogReducer.dictionaryChannels,
  enterpriseSectors: state.catalogReducer.dictionarySectors,
  obtainingDocumentsMethods: state.catalogReducer.dictionaryObtainingMethods,
  // providers: state.catalogReducer.dictionaryEdoProviders,
  // data: state.gs1Reducer.data
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // loadProfiles: () => loadDictionaryOrgProfiles(),
      loadProductsByGPC: () => loadDictionaryGcpProducts(),
      // loadKnowledge: () => loadDictionaryKnowledge(),
      // loadStateProject: () => loadDictionaryProjects(),
      // loadSales: () => loadDictionarySales(),
      // loadChannels: () => loadDictionaryChannels(),
      loadSectors: () => loadDictionarySectors(),
      loadMethodOfObtaining: () => loadDictionaryObtainingMethods(),
      // loadEdoprovider: () => loadDictionaryEdoProviders(),
      // saveGS1CompanyInformation: (data, info, cbk) => saveGS1CompanyInformation(data, info, cbk)
    },
    dispatch,
  );
};

CompanyDetails.propTypes = {
  // switchTab: PropTypes.func.isRequired,
  // distributionChannels: PropTypes.array,
  // companyDetails: PropTypes.object,
  // providers: PropTypes.array,
  // knownFroms: PropTypes.array,
  // loadChannels: PropTypes.func.isRequired,
  // loadEdoprovider: PropTypes.func.isRequired,
  // loadKnowledge: PropTypes.func.isRequired,
  loadMethodOfObtaining: PropTypes.func.isRequired,
  loadProductsByGPC: PropTypes.func.isRequired,
  // loadProfiles: PropTypes.func.isRequired,
  // loadSales: PropTypes.func.isRequired,
  loadSectors: PropTypes.func.isRequired,
  // loadStateProject: PropTypes.func.isRequired,
  // obtainingDocumentsMethods: PropTypes.array,
  // partisipationInGovernmentProjects: PropTypes.array,
  // mainProductGCPs: PropTypes.array,
  // accountTypes: PropTypes.array,
  // saveGS1CompanyInformation: PropTypes.func.isRequired,
  // // blockNextTab: PropTypes.func.isRequired,
  // enterpriseSectors: PropTypes.array,
  // implementationMethods: PropTypes.array,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(CompanyDetails);
